<template>
  <div>
    <b-table
      class="table is-fullwidth"
      :data="providers"
      paginated
      default-sort="name"
      :current-page="meta.current_page"
      :per-page="meta.per_page"
      :hoverable="true"
      backend-pagination
      :total="meta.total_count"
      @page-change="onPageChange"
    >
      <template slot-scope="data">
        <b-table-column label="Id">
          {{ data.row.id }}
        </b-table-column>
        <b-table-column label="Status" field="status">
          {{ statusName(data.row.guest_status) }}
        </b-table-column>
        <b-table-column label="Name" field="name">
          {{ data.row.name }}
        </b-table-column>
        <b-table-column label="Avatar" field="avatar">
          <div class="container">
            <img
              @error="handleImageError"
              alt="avatar image"
              v-if="data.row.avatar_url"
              id="avatar"
              :src="data.row.avatar_url"
            />
          </div>
        </b-table-column>
        <b-table-column field="address" label="Location">{{
          data.row.address.locale.country.name +
            ", " +
            data.row.address.locale.city.name
        }}</b-table-column>
        <b-table-column label="Creator" field="creator">{{
          data.row.user.first_name + " " + data.row.user.last_name
        }}</b-table-column>
        <b-table-column label="Phone number" field="phone">
          {{ data.row.phone }}
        </b-table-column>
        <b-table-column id="actions">
          <div class="buttons is-right">
            <router-link :to="'/providers/edit/' + data.row.id">
              <b-button class="is-primary is-outlined">{{
                isMyProvider(data.row) ? "view/edit" : "view"
              }}</b-button>
            </router-link>
            <b-button
              v-if="isMyProvider(data.row)"
              class="is-danger is-outlined"
              @click="confirmDelete(data.row)"
              >delete</b-button
            >
          </div>
        </b-table-column>
      </template>
      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>
              <b-icon icon="emoticon-sad" size="is-large"> </b-icon>
            </p>
            <p>Nothing here.</p>
          </div>
        </section>
      </template>
    </b-table>
  </div>
</template>

<script>
import { DELETE_PROVIDER_BY_ADMIN } from "../../../_graphql/Mutations/mutations";

export default {
  data() {
    return {
      guestStatuses: [
        {
          name: "Draft",
          value: "DRAFT"
        },
        {
          name: "Ready for review",
          value: "READY_FOR_REVIEW"
        },
        {
          name: "Needs corrections",
          value: "NEEDS_CORRECTIONS"
        },
        {
          name: "Reviewed",
          value: "REVIEWED"
        },
        {
          name: "Invited",
          value: "INVITED"
        },
        {
          name: "Demo scheduled",
          value: "DEMO_SCHEDULED"
        },
        {
          name: "Rejected",
          value: "REJECTED"
        }
      ]
    };
  },
  props: ["providers", "meta", "userRole", "meUser"],
  computed: {},
  methods: {
    statusName(value) {
      let status;
      if (this.guestStatuses) {
        status = this.guestStatuses.find(s => s.value == value);
      }

      return status ? status.name : value;
    },
    isMyProvider(data) {
      return (
        !this.userRole.isOperator ||
        (this.userRole.isOperator && data.user.auth_id == this.meUser.user._id)
      );
    },
    handleImageError(event) {
      event.target.style = "  box-shadow: unset";
    },
    onPageChange(page) {
      const offset = this.meta.per_page * (page - 1);
      this.$store.state.currentGuestProvidersPage = page;
      this.$emit("pageChanged", offset);
    },
    confirmDelete(data) {
      this.$buefy.dialog.prompt({
        title: "Deleting provider " + data.name,
        message: `Enter reason for deleting this provider`,
        inputAttrs: {
          placeholder: "Describe the reason for deleting provider",
          type: "textarea",
          min: 10,
          maxlength: 300
        },
        trapFocus: true,
        onConfirm: value =>
          this.deleteGuestProvider({ id: data.id, reason: value })
      });
    },
    async deleteGuestProvider(data) {
      const mutation = DELETE_PROVIDER_BY_ADMIN;
      const variables = {
        id: parseInt(data.id),
        reason: data.reason
      };
      this.$store.commit("provider/collection/deleteModel", variables);
      await this.$store.dispatch("provider/collection/delete", mutation);
      this.$emit("deletedProvider");
    }
  }
};
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 769px) {
  #actions {
    width: 30% !important;
  }
}
.container {
  width: 100px;
  height: 100px;
}
#avatar {
  width: 100px;
  height: 100px;
  object-fit: contain;
  border-radius: 50%;
  text-align: center;
}
img {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.09);
}
</style>
