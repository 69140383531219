<template>
  <section>
    <b-modal
      :active.sync="isActive"
      has-modal-card
      trap-focus
      :on-cancel="closeModal"
    >
      <div class="modal-card">
        <header class="modal-card-head">
          <b-notification type="is-light" :closable="false">
            <div>Currently avaliable minutes:</div>
            <div>
              <span class="free has-text-primary"
                >{{ (freeSeconds / 60).toFixed(2) }}
              </span>
              subscription included minutes;
            </div>
            <div>
              <span class="paid has-text-primary">{{
                (paidSeconds / 60).toFixed(2)
              }}</span>
              extra minutes;
            </div>
          </b-notification>
        </header>
        <div v-if="paymentProducts" class="modal-card-body is-multiline">
          <p class="modal-card-title has-text-primary">Extra minutes packs</p>
          <div
            class="pack"
            v-for="item in paymentProducts.data[0].product_items"
            :key="item.id"
          >
            <div class="title is-4">{{ item.units }} minutes</div>
            <div class="subtitle">for</div>
            <div class="title is-4 price">€{{ item.price }}</div>
            <b-button @click="buyMinutes(item.id)" class="is-primary"
              >Buy now</b-button
            >
          </div>
        </div>
        <footer class="modal-card-foot">
          <div class="has-text-left">*Relevant taxes (VAT) will be charged in addition to the above fees.</div>
          <b-button @click="closeModal">Cancel</b-button>
        </footer>
      </div>
    </b-modal>
  </section>
</template>

<script>
import { PAYMENT_PRODUCTS } from "../../../_graphql/Queries/queries";
import { CREATE_PROVIDER_PAYMENT } from "../../../_graphql/Mutations/mutations";

export default {
  name: "BuyMinutesModal",
  data() {
    return {
      isActive: false
    };
  },
  props: ["providerId", "freeSeconds", "paidSeconds", "stripe"],
  methods: {
    openModal() {
      this.isActive = true;
    },
    closeModal() {
      this.isActive = false;
    },
    buyMinutes(itemId) {
      this.$apollo
        .mutate({
          mutation: CREATE_PROVIDER_PAYMENT,
          variables: {
            input: {
              provider_id: this.providerId,
              payment_product_item_id: itemId
            }
          }
        })
        .then(({ data }) => {
          if (data.createProviderPayment) {
            let sessionId = data.createProviderPayment.session_id;
            if (this.stripe) {
              this.stripe
                .redirectToCheckout({
                  sessionId: sessionId
                })
                .then(function(result) {
                  console.log(result);
                })
                .catch(function(error) {
                  this.$buefy.toast.open({
                    message: error,
                    type: "is-danger"
                  });
                });
            } else {
              this.$buefy.toast.open({
                message: "Something went wrong",
                type: "is-danger"
              });
            }
          }
        });
    }
  },
  mounted() {},
  apollo: {
    paymentProducts: {
      query: PAYMENT_PRODUCTS,
      variables() {
        return {
          query: "name_key:VIDEO_CREDITS"
        };
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-card-body {
  display: flex;
  justify-content: space-evenly;
  flex-flow: wrap;

  .subtitle {
    margin-top: 0 !important;
  }
  .price {
    margin-top: 0 !important;
  }

  .pack {
    border: 2px solid #0ba1d1;
    border-radius: 10px;
    padding: 1rem;
    margin: 0.5rem;
    box-shadow: 0 4px 6px 0 rgb(0 0 0 / 6%);
  }
}

.modal-card-head {
  align-items: flex-start;
}

.modal-card-foot {
  border-top: 1px solid whitesmoke !important;
  justify-content: space-between;
}

.currentMinutes {
  text-align: left;
  margin-left: 1rem;
}

.modal-card-title {
  width: 100%;
  margin-bottom: 1rem;
}

.notification {
  width: 100%;
  background-color: #e6eff2 !important;
}
</style>
