<template>
  <div class="card">
    <header class="card-header">
      <div class="card-header-title">
        <p class=" has-text-primary">
          <b-icon icon="account-multiple" custom-size="default" />
          Main Practitioners
        </p>
        <div v-if="showBtns" class="buttons">
          <b-button class="is-primary" @click="openModal">Add</b-button>
        </div>
      </div>
    </header>
    <div class="card-content">
      <div
        class="columns is-multiline"
        v-for="practitioner in practitioners"
        :key="practitioner.id"
      >
        <b-tooltip
          v-if="!isSubscribed"
          class="column"
          label="This main practitioner will not be displayed due to the limits of your subscription plan. Upgrade to display."
          position="is-bottom"
          multilined
        >
          <template class="column">
            <div class="column is-12 is-4-fullhd">
              <figure class="container">
                <img
                  class="basic-plan-avatar"
                  :src="practitioner.avatar_url"
                  @error="imageLoadError"
                />
              </figure>
            </div>
            <div class="column is-12 is-8-fullhd has-text-left">
              <p class="title is-5">
                <span class="has-text-primary practitioner-name">{{
                  practitioner.name
                }}</span>
                {{ practitioner.degree }}
              </p>
              <p class="subtitle is-6">{{ practitioner.specialty }}</p>
              <div class="description scrollable">
                {{ practitioner.description }}
              </div>
            </div>
          </template>
        </b-tooltip>
        <template v-if="isSubscribed" class="column">
          <div class="column is-12 is-4-fullhd">
            <figure class="container">
              <img
                class=""
                :src="practitioner.avatar_url"
                @error="imageLoadError"
              />
            </figure>
          </div>
          <div class="column is-12 is-8-fullhd has-text-left">
            <p class="title is-5">
              <span class="has-text-primary practitioner-name">{{
                practitioner.name
              }}</span>
              {{ practitioner.degree }}
            </p>
            <p class="subtitle is-6">{{ practitioner.specialty }}</p>
            <div class="description scrollable">
              {{ practitioner.description }}
            </div>
          </div>
        </template>
        <div class="column is-12 has-text-right">
          <div v-if="showBtns" class="buttons is-left actions">
            <b-button
              :disabled="!isSubscribed"
              class="is-primary"
              @click="openEditModal(practitioner)"
              >Edit</b-button
            >
            <b-button
              :disabled="!isSubscribed"
              class="is-primary"
              @click="confirmDelete(practitioner)"
              >Delete</b-button
            >
          </div>
          <hr />
        </div>
      </div>
    </div>
    <PractitionerModal
      :isSubscribed="isSubscribed"
      ref="practitionerModal"
      @createdPractitioner="createdPractitioner"
    ></PractitionerModal>
    <EditPractitionerModal
      :isSubscribed="isSubscribed"
      :data="selectedPractitioner"
      ref="editPractitionerModal"
      @updatedPractitioner="createdPractitioner"
    />
  </div>
</template>

<script>
import PractitionerModal from "./PractitionerModal";
import { DELETE_PRACTITIONER } from "../../../_graphql/Mutations/mutations";
import RouteParamsGetters from "../../../mixins/RouteParamsGetters";
import EditPractitionerModal from "./EditPractitionerModal";

export default {
  data() {
    return {
      defaultAvatar:
        "https://icons.iconarchive.com/icons/papirus-team/papirus-status/512/avatar-default-icon.png",
      selectedPractitioner: {}
    };
  },
  props: {
    practitioners: {
      type: Array,
      required: true
    },
    userRole: {
      default: ""
    },
    allowUpdate: {
      default: false
    },
    subscriptionPlan: {
      default: ""
    },
    isSubscribed: {
      default: false
    }
  },
  components: {
    PractitionerModal,
    EditPractitionerModal
  },
  mixins: [RouteParamsGetters],
  methods: {
    openModal() {
      this.$refs.practitionerModal.open();
    },
    openEditModal(data) {
      this.selectedPractitioner = data;
      this.$refs.editPractitionerModal.open();
    },
    imageLoadError(event) {
      event.target.src = this.defaultAvatar;
    },
    createdPractitioner() {
      this.$emit("createdPractitioner");
    },
    confirmDelete(item) {
      this.$buefy.dialog.confirm({
        title: "Deleting ",
        message:
          "Are you sure you want to <b>delete</b> " +
          item.name +
          "? This action cannot be undone.",
        confirmText: "Delete",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.deletePractitioner(item)
      });
    },
    async deletePractitioner(item) {
      const mutation = DELETE_PRACTITIONER;
      const variables = {
        id: parseInt(item.id)
      };
      this.$store.commit("provider/collection/deleteModel", variables);
      await this.$store.dispatch("provider/collection/delete", mutation);
      this.$emit("deletedPractitioner");
    }
  },
  computed: {
    showBtns() {
      return this.allowUpdate;
    }
  }
};
</script>

<style lang="scss" scoped>
.card-header-title {
  justify-content: space-between;
}

.card-content {
  max-height: 36rem;
  overflow-y: auto;

  .actions {
    padding-top: 0;
  }

  .description {
    max-height: 8.5rem;
    max-width: 100%;
    overflow: auto;
    overflow-y: scroll;
    word-break: break-word;
    white-space: pre-line;
  }

  hr {
    margin: 0.5rem 0;
  }
}

.practitioner-name {
  margin-right: 0.5rem;
}

.container {
  width: 160px;
  height: 160px;
  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: contain;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.09);
    border-radius: 50%;
  }
}

.basic-plan-avatar {
  filter: blur(4px) grayscale(85%);
  -webkit-filter: blur(4px) grayscale(85%);
}

.b-tooltip {
  display: block;
}
</style>
