<script>
export default {
  name: "LocalStateData",
  props: ["data"],
  computed: {
    getParams() {
      return this.data ? this.data : this.$route.params.data;
    }
  },
  created() {
    this.localData = Object.assign({}, this.getParams);
  },
  data() {
    return {
      localData: {}
    };
  },
  methods: {
    resetLocalData() {
      this.localData = Object.assign({}, this.getParams);
    }
  },
  watch: {
    data(newData) {
      this.localData = Object.assign({}, newData);
    },
    "$route.params.data"(newData) {
      this.localData = Object.assign({}, newData);
    }
  }
};
</script>
