<script>
export default {
  name: "SubscribeForRefetch",
  data() {
    return {
      mutationSubscribe: null
    };
  },
  created() {
    this.mutationSubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === "refetchComponent") {
        if (state.refetch.componentName === "areas") {
          this.$apollo.queries.areas.refetch();
        } else if (state.refetch.componentName === "areaTypes") {
          this.$apollo.queries.area.refetch();
        } else if (state.refetch.componentName === "areaTypeCat") {
          this.$apollo.queries.aData.refetch();
        } else if (state.refetch.componentName === "treatments") {
          this.$apollo.queries.treatments.refetch();
        }
      }
    });
  },
  destroyed() {
    this.mutationSubscribe();
  }
};
</script>

<style scoped></style>
