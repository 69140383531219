<template>
  <section>
    <b-modal
      :active.sync="isActive"
      has-modal-card
      trap-focus
      :on-cancel="close"
    >
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title has-text-weight-bold">
            Edit Practitioner {{ data.name }}
          </p>
        </header>
        <section class="modal-card-body">
          <ValidationObserver ref="editPractitioner">
            <b-field horizontal label="Name" label-for="name">
              <div class="control">
                <ValidationProvider
                  rules="max:50"
                  v-slot="{ errors }"
                  name="Name"
                >
                  <b-input
                    :disabled="!isSubscribed"
                    id="name"
                    placeholder="Name"
                    v-model="localData.name"
                    :value="name"
                  />
                  <span
                    v-for="error in errors"
                    :key="error.id"
                    class="has-text-danger help"
                    >{{ error }}</span
                  >
                </ValidationProvider>
              </div>
            </b-field>
            <b-field
              label="Avatar"
              label-for="avatar"
              class="avatar-field"
              horizontal
            >
              <div class="control">
                <b-button
                  :disabled="!isSubscribed"
                  @click="addImage('practitionerAvatar')"
                  class="is-primary"
                >
                  Add image
                </b-button>
              </div>
              <figure
                class="container is-pulled-right"
                v-if="localData.avatar_url"
              >
                <img class="" :src="localData.avatar_url" />
              </figure>
            </b-field>
            <b-field label="Degree" label-for="degree" horizontal>
              <div class="control">
                <ValidationProvider
                  rules="max:250"
                  v-slot="{ errors }"
                  name="Degree"
                >
                  <b-input
                    :disabled="!isSubscribed"
                    placeholder="Degree"
                    id="degree"
                    v-model="localData.degree"
                    type="text"
                    maxlength="250"
                    pattern="[a-zA-Z0-9\s,]*"
                    message="This field can contain up to 500 symbols"
                  />
                  <span
                    v-for="error in errors"
                    :key="error.id"
                    class="has-text-danger help"
                    >{{ error }}</span
                  >
                </ValidationProvider>
              </div>
            </b-field>

            <b-field label="Specialties" label-for="specialty" horizontal>
              <div class="control">
                <ValidationProvider
                  rules="max:500"
                  v-slot="{ errors }"
                  name="Specialties"
                >
                  <b-input
                    :disabled="!isSubscribed"
                    placeholder="Specialty"
                    id="specialty"
                    v-model="localData.specialty"
                    type="text"
                    maxlength="500"
                    pattern="[a-zA-Z0-9\s,]*"
                    message="This field can contain up to 500 symbols"
                  />
                  <span
                    v-for="error in errors"
                    :key="error.id"
                    class="has-text-danger help"
                    >{{ error }}</span
                  >
                </ValidationProvider>
              </div>
            </b-field>
            <b-field label="Description" label-for="desecription" horizontal>
              <div class="control">
                <ValidationProvider
                  rules="max:1000"
                  v-slot="{ errors }"
                  name="Description"
                >
                  <b-input
                    :disabled="!isSubscribed"
                    placeholder="Description"
                    id="description"
                    v-model="localData.description"
                    type="textarea"
                    maxlength="1000"
                    pattern="[a-zA-Z0-9]*"
                    message="This field can contain up to 500 symbols"
                  />
                  <span
                    v-for="error in errors"
                    :key="error.id"
                    class="has-text-danger help"
                    >{{ error }}</span
                  >
                </ValidationProvider>
              </div>
            </b-field>
          </ValidationObserver>
        </section>
        <footer class="modal-card-foot">
          <button class="button" type="button" @click="close">
            Close
          </button>
          <button :disabled="!isSubscribed" class="button is-primary" @click="update">Update</button>
        </footer>
      </div>
    </b-modal>
    <AddImage ref="addImage" @saveImage="saveImage"></AddImage>
  </section>
</template>

<script>
import ValidationErrors from "../../../mixins/ValidationErrors";
import { UPDATE_PRACTITIONER } from "../../../_graphql/Mutations/mutations";
import LocalStateData from "../../../mixins/LocalStateData";
import AddImage from "./AddImage";

export default {
  components: {
    AddImage
  },
  props: {
    isSubscribed: {
      default: false
    }
  },
  methods: {
    addImage(type) {
      if (this.isSubscribed) {
        this.$refs.addImage.openModal(type);
      }
    },
    saveImage(data) {
      this.localData.avatar_url = data.image.url;
    },
    async update() {
      if (!this.isSubscribed) {
        return;
      }
      this.$refs.editPractitioner.validate().then(async success => {
        if (!success) {
          this.refName = "editPractitioner";
          this.handleErrors();
          return;
        }
        const mutation = UPDATE_PRACTITIONER;
        const variables = {
          id: parseInt(this.data.id),
          input: {
            name: this.localData.name,
            avatar_url: this.localData.avatar_url,
            specialty: this.localData.specialty,
            description: this.localData.description,
            degree: this.localData.degree
          }
        };
        const payload = {
          mutation: mutation,
          queryName: "updateProvider"
        };

        this.$store.commit("provider/collection/editModel", variables);
        await this.$store.dispatch("provider/collection/update", payload);
        this.close();
        this.$emit("updatedPractitioner");
      });
    },
    close() {
      this.isActive = false;
      this.$refs.editPractitioner.reset();
      this.resetLocalData();
    },
    open() {
      this.isActive = true;
    }
  },
  mixins: [ValidationErrors, LocalStateData],
  data() {
    return {
      isActive: false,
      name: "",
      description: "",
      specialty: "",
      avatar_url: "",
      degree: ""
    };
  }
};
</script>
<style lang="scss" scoped>
.avatar-field {
  margin: 34px 0 !important;

  .container {
    width: 160px;
    height: 160px;
    img {
      height: 100%;
      width: 100%;
      display: block;
      object-fit: contain;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
        0 3px 10px 0 rgba(0, 0, 0, 0.09);
      border-radius: 50%;
    }
  }
}
</style>
