<template>
  <card-component>
    <h2 v-if="form.slug === 'footer'">For All pages</h2>
    <h2 v-if="form.slug === 'get-started-providers'">For pages: medical-professionals/articles/list</h2>
    <h2 v-if="form.slug === 'get-started-patients'">For pages: patients/app, patients/blog/list, patients/solutions, contact, our-mission</h2>
    <ValidationObserver ref="componentFormValidator" class="userForm">
      <div class="columns is-variable is-7">
        <div class="column is-9">
          <div class="columns is-variable is-5 is-multiline is-mobile">
            <ValidationProvider
              class="column is-6"
              rules="min:2"
              v-slot="{ errors, valid }"
              name="Title"
            >
              <b-field
                :type="{ 'is-danger': errors[0], 'is-primary': valid }"
                label="Title"
              >
                <b-input trim v-model="form.title" />
              </b-field>
              <span
                v-for="error in errors"
                :key="error.id"
                class="has-text-danger help"
                >{{ error }}</span
              >
            </ValidationProvider>
            <ValidationProvider
              class="column is-6"
              rules="required|min:2"
              v-slot="{ errors, valid }"
              name="Slug"
            >
              <b-field
                :type="{ 'is-danger': errors[0], 'is-primary': valid }"
                label="Slug"
              >
                <b-input
                  placeholder="example-slug-name"
                  trim
                  v-model="form.slug"
                />
              </b-field>
              <span
                v-for="error in errors"
                :key="error.id"
                class="has-text-danger help"
                >{{ error }}</span
              >
            </ValidationProvider>
            <ValidationProvider
              class="column is-6"
              rules="min:2"
              v-slot="{ errors, valid }"
              name="Page"
            >
              <b-field
                :type="{ 'is-danger': errors[0], 'is-primary': valid }"
                label="Page"
              >
                <b-input trim v-model="form.page" />
              </b-field>
              <span
                v-for="error in errors"
                :key="error.id"
                class="has-text-danger help"
                >{{ error }}</span
              >
            </ValidationProvider>
          </div>
        </div>
      </div>
      <!--      <div class="columns is-variable is-5 is-multiline is-mobile">-->
      <!--        <ValidationProvider-->
      <!--          class="column is-12"-->
      <!--          rules=""-->
      <!--          v-slot="{ errors, valid }"-->
      <!--          name="Content"-->
      <!--        >-->
      <!--          <b-field-->
      <!--            :type="{ 'is-danger': errors[0], 'is-primary': valid }"-->
      <!--            label="Content"-->
      <!--          >-->
      <!--            <b-input v-model="form.content" type="textarea"></b-input>-->
      <!--          </b-field>-->
      <!--          <span-->
      <!--            v-for="error in errors"-->
      <!--            :key="error.id"-->
      <!--            class="has-text-danger help"-->
      <!--            >{{ error }}</span-->
      <!--          >-->
      <!--        </ValidationProvider>-->
      <!--      </div>-->
      <!--      <div class="columns is-variable is-5 is-multiline is-mobile">-->
      <!--        <ValidationProvider-->
      <!--          class="column is-12"-->
      <!--          rules=""-->
      <!--          v-slot="{ errors, valid }"-->
      <!--          name="CMS keywords"-->
      <!--        >-->
      <!--          <b-field-->
      <!--            :type="{ 'is-danger': errors[0], 'is-primary': valid }"-->
      <!--            label="CMS keywords (For developers only)"-->
      <!--          >-->
      <!--            <b-input v-model="form.cms_keywords" trim></b-input>-->
      <!--          </b-field>-->
      <!--          <span-->
      <!--            v-for="error in errors"-->
      <!--            :key="error.id"-->
      <!--            class="has-text-danger help"-->
      <!--            >{{ error }}</span-->
      <!--          >-->
      <!--        </ValidationProvider>-->
      <!--      </div>-->
      <div
        class="columns is-variable is-5 is-multiline is-mobile"
        v-if="!!contentFromKeywords.length"
      >
        <div
          v-for="(key, index) in contentFromKeywords"
          :key="index"
          class="column is-12"
        >
          <!--          <ValidationProvider-->
          <!--            class="column is-12"-->
          <!--            rules=""-->
          <!--            v-if="key.includes(']')"-->
          <!--            v-slot="{ errors, valid }"-->
          <!--            :name="key.slice(key.indexOf('['))"-->
          <!--          >-->
          <!--            <b-field-->
          <!--              :type="{ 'is-danger': errors[0], 'is-primary': valid }"-->
          <!--              :label="key"-->
          <!--            >-->
          <!--              <b-input-->
          <!--                v-model="form.content_json[key]"-->
          <!--                type="textarea"-->
          <!--              ></b-input>-->
          <!--            </b-field>-->
          <!--            <span-->
          <!--              v-for="error in errors"-->
          <!--              :key="error.id"-->
          <!--              class="has-text-danger help"-->
          <!--              >{{ error }}</span-->
          <!--            >-->
          <!--          </ValidationProvider>-->
          <ValidationProvider
            class="column is-12"
            rules=""
            v-slot="{ errors, valid }"
            :name="key"
          >
            <b-field
              :type="{ 'is-danger': errors[0], 'is-primary': valid }"
              :label="key"
            >
              <b-input
                v-model="form.content_json[key]"
                type="textarea"
              ></b-input>
            </b-field>
            <span
              v-for="error in errors"
              :key="error.id"
              class="has-text-danger help"
              >{{ error }}</span
            >
          </ValidationProvider>
        </div>
      </div>
    </ValidationObserver>
    <div class="level">
      <!-- TODO: Refactor not not rely on level-left -->
      <div class="level-left">
        <div class="level-item"></div>
      </div>
      <div class="level-right">
        <div class="level-item componentFormSubmit">
          <b-button
            class="is-primary"
            :loading="submittingForm"
            @click="submitForm"
          >
            Save
          </b-button>
        </div>
      </div>
    </div>
  </card-component>
</template>

<script>
import CardComponent from "../../base/components/CardComponent";
import ValidationErrors from "../../../mixins/ValidationErrors";

export default {
  name: "ComponentForm",
  mixins: [ValidationErrors],
  components: { CardComponent },
  props: {
    editModel: {
      type: Object,
      default: null,
      required: false
    },
    resetSubmitSwitch: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      submittingForm: false,
      form: {
        page: "",
        slug: "",
        title: "",
        content: "",
        cms_keywords: "",
        content_json: {}
      }
    };
  },
  computed: {
    contentFromKeywords() {
      return (
        this.form.cms_keywords &&
        !!this.form.cms_keywords.length &&
        this.form.cms_keywords.split(" ")
      );
    }
  },
  watch: {
    resetSubmitSwitch() {
      this.submittingForm = false;
    }
  },
  created() {
    this.populateEditForm();
  },
  methods: {
    populateEditForm() {
      if (this.editModel) {
        this.form.page = this.editModel.page;
        this.form.slug = this.editModel.slug;
        this.form.title = this.editModel.title;
        this.form.content = this.editModel.content;
        this.form.cms_keywords = this.editModel.cms_keywords || "";
        this.form.content_json = this.editModel.content_json
          ? JSON.parse(this.editModel.content_json)
          : {};
      }
    },
    submitForm() {
      this.$refs.componentFormValidator.validate().then(success => {
        if (!success) {
          this.refName = "componentFormValidator";
          this.handleErrors();
          return;
        }
        this.$emit("formSubmit", this.form);
        this.$apollo.getClient().resetStore(); // to clear allComponents table so it can be refetched with new/edited components. should be refactored to refetch only allComponents
        this.submittingForm = true;
      });
    }
  }
};
</script>

<style>
.componentFormSubmit button {
  margin-top: 40px;
  font-size: 1.15rem;
  width: 120px;
}
h2 {
  text-align: left;
  font-weight: bold !important;
  margin-bottom: 10px !important;
}
</style>
