<script>
import {CREATE_PROVIDER_SUBSCRIPTION, UPDATE_PROVIDER_SUBSCRIPTION} from "@/_graphql/Mutations/mutations";
import { GET_CUSTOMER_PORTAL_URL, SUBSCRIPTION_PLANS } from "@/_graphql/Queries/queries";

export default {
  name: "PlanSubscriptionsHelper",
  data() {
    return {
      stripe: null,
      subscriptionPlans: []
    };
  },
  created() {
    this.PRODUCT_TYPE_BASIC = "Basic";

    this.PLAN_INTERVAL_MONTHLY = "Monthly";
    this.PLAN_INTERVAL_YEARLY = "Yearly";
  },
  computed: {
    getSubscriptionPlans() {
      return this.subscriptionPlans
    }
  },
  mounted() {
    this.stripe = window.Stripe(process.env.VUE_APP_STRIPE_PK);
  },
  methods: {
    create(planId) {
      const variables = {
        input: {
          sub_plan_id: planId
        }
      };

      this.mutate(CREATE_PROVIDER_SUBSCRIPTION, variables)
        .then(({ data: { createProviderSubscription } }) => {
          if (
              createProviderSubscription &&
              createProviderSubscription.hasOwnProperty("session_id") &&
              createProviderSubscription.session_id
          ) {
            this.stripe
              .redirectToCheckout({
                sessionId: createProviderSubscription.session_id
              })
              .then(function(result) {
                console.log(result);
              })
              .catch(function(error) {
                console.error(error);
              });
          }
        })
        .catch(error => {
          this.$buefy.toast.open({
            message: `${error}`,
            type: "is-danger"
          });
        });
    },
    updateSubscriptionCheckout(planId, providerSubscription) {
      const variables = {
        id: parseInt(providerSubscription.id),
        input: {
          sub_plan_id: planId
        }
      };

      this.mutate(UPDATE_PROVIDER_SUBSCRIPTION, variables)
          .then(({ data: { updateProviderSubscription } }) => {
            if (
                updateProviderSubscription &&
                updateProviderSubscription.hasOwnProperty("session_id") &&
                updateProviderSubscription.session_id
            ) {
              this.stripe
                  .redirectToCheckout({
                    sessionId: updateProviderSubscription.session_id
                  })
                  .then(function(result) {
                    console.log(result);
                  })
                  .catch(function(error) {
                    console.error(error);
                  });
            }
          })
          .catch(error => {
            this.$buefy.toast.open({
              message: `${error}`,
              type: "is-danger"
            });
          });
    },
    getCustomerPortalUrl(providerId, planId = null) {
      const filterData = {
        provider_id: providerId
      }

      if (planId) {
        filterData['sub_plan_id'] = planId;
      }

      return this.$apollo
        .query({
          query: GET_CUSTOMER_PORTAL_URL,
          variables: {
            input: {
              filter: filterData
            }
          },
          update: data => data
        })
        .catch(error => {
          return this.$buefy.toast.open({
            message: `${error}`,
            type: "is-danger"
          });
        });
    },
    getCurrentPlan(providerSubscription) {
      return providerSubscription && this.providerSubscription.is_subscribed ? providerSubscription.sub_product_name : this.PRODUCT_TYPE_BASIC;
    },
    getPlanIds(planName = null) {
      if (!planName) {
        return this.getSubscriptionPlans.map(plan => parseInt(plan.id));
      }

      return this.getSubscriptionPlans.filter(plan => plan.sub_product_name === planName).map(plan => parseInt(plan.id));
    },
    getPlanId(planName, isMonthly) {
      const interval = isMonthly ? this.PLAN_INTERVAL_MONTHLY : this.PLAN_INTERVAL_YEARLY;
      const plan = !planName ? null : this.getSubscriptionPlans.find(plan => plan.sub_product_name === planName && plan.interval === interval);

      if (!plan) {
        return null;
      }

      return parseInt(plan.id);
    }
  },
  apollo: {
    subscriptionPlans: {
      query: SUBSCRIPTION_PLANS,
      result({ data: { subscriptionPlans: { data } } }) {
        this.subscriptionPlans = data.length > 0 ? data : null;
      }
    }
  },
};
</script>



