import { apolloProvider } from "../../vue-apollo.js";
import { ToastProgrammatic as Toast } from "buefy";

export default function({ NEW_MODEL }) {
  return Object.assign(
    {},
    {
      namespaced: true,
      state: {
        defaultNewModel: Object.assign({}, NEW_MODEL),
        newModel: Object.assign({}, NEW_MODEL),
        editModel: Object.assign({}, NEW_MODEL),
        deleteModel: {},
        items: [],
        mutation: "",
        query: "",
        providerId: null,
        queryError: null
      },
      mutations: {
        setItems(state, payload) {
          state.items = payload;
        },
        resetNewModel(state) {
          state.newModel = Object.assign({}, state.defaultNewModel);
        },
        resetEditModel(state) {
          state.editModel = state.defaultNewModel;
        },
        resetDeleteModel(state) {
          state.deleteModel = {};
        },
        resetMutation(state) {
          state.mutation = "";
        },
        newModel(state, model) {
          state.newModel = Object.assign({}, model);
        },
        editModel(state, model) {
          state.editModel = Object.assign({}, model);
        },
        deleteModel(state, model) {
          state.deleteModel = Object.assign({}, model);
        },
        setQueryError(state, value) {
          state.queryError = value;
        }
      },
      getters: {
        items(state) {
          return state.items;
        },
        newModel(state) {
          return Object.assign({}, state.newModel);
        },
        editModel(state) {
          return Object.assign({}, state.editModel);
        },
        providerId(state) {
          return state.providerId;
        },
        queryError(state) {
          return state.queryError;
        }
      },
      actions: {
        async getAllItems({ state }, data) {
          state.items = await apolloProvider().defaultClient.query(data);
        },
        getProviderId({ state }, id) {
          state.providerId = id;
        },
        async setItems({ state, commit }) {
          const { data } = await apolloProvider().defaultClient.query({
            query: state.query
          });
          commit("setItems", data);
        },
        async create({ state, commit, dispatch }, payload) {
          // Sets up createdModel
          state.mutation = payload.mutation;
          state.query = payload.query;
          const createdModel = Object.assign({}, state.newModel);
          // Resets state.newModel()
          commit("resetNewModel");

          await apolloProvider()
            .defaultClient.mutate({
              mutation: state.mutation,
              variables: createdModel,
              update: ({ data }) => {
                return data;
              },
              error: ({ error }) => {
                commit("setQueryError", error);
                return Toast.open({
                  message: error,
                  type: "is-danger"
                });
              }
            })
            .catch(error => {
              commit("setQueryError", error);
              return Toast.open({
                message: `${error}`,
                type: "is-danger"
              });
            })
            .then(data => {
              if (data.data[payload.queryName]) {
                Toast.open({
                  message: "Created successfully!",
                  type: "is-success"
                });
                dispatch("setItems", data);
                commit("resetMutation");
              }
            });
        },
        async update({ state, commit }, payload) {
          // Sets up updatedModel
          const updatedModel = Object.assign({}, state.editModel);

          state.mutation = payload.mutation;
          // Resets state.editModel()
          commit("resetEditModel");

          await apolloProvider()
            .defaultClient.mutate({
              mutation: state.mutation,
              variables: updatedModel,
              update: ({ data }) => {
                return data;
              },
              error: ({ error }) => {
                Toast.open({
                  message: error,
                  type: "is-danger"
                });
                return error;
              }
            })
            .catch(error => {
              return Toast.open({
                message: `${error}`,
                type: "is-danger"
              });
            })
            .then(data => {
              if (data.data[payload.queryName]) {
                Toast.open({
                  message: "Updated successfully!",
                  type: "is-success"
                });
              }
              commit("resetMutation");
            });
        },
        async delete({ state, commit }, payload) {
          // Sets up updatedModel
          const deletedModel = Object.assign({}, state.deleteModel);

          state.mutation = payload;
          // Resets state.editModel()
          commit("resetDeleteModel");

          await apolloProvider()
            .defaultClient.mutate({
              mutation: state.mutation,
              variables: deletedModel,
              update: ({ data }) => {
                return data;
              },
              error: ({ error }) => {
                Toast.open({
                  message: error,
                  type: "is-danger"
                });
                return error;
              }
            })
            .then(() => {
              Toast.open({
                message: "Deleted successfully!",
                type: "is-success"
              });
              commit("resetMutation");
            })
            .catch(error => {
              Toast.open({
                message: `${error}`,
                type: "is-danger"
              });
            });
        },
        destroy({ state, commit }, modelId) {
          const items = state.items.filter(m => m.id !== modelId);
          commit("items", items);
        }
      }
    }
  );
}
