<script>
import _ from "lodash";

export default {
  data() {
    return {
      refName: ""
    };
  },
  methods: {
    handleErrors() {
      let error;
      let errors = _.map(this.$refs[this.refName].errors, value => {
        return value;
      });
      error = _.flattenDeep(errors);
      if (error.length > 0) {
        this.$buefy.toast.open({
          message: error[0],
          type: "is-danger",
          position: "is-top"
        });
        return;
      }
    }
  }
};
</script>
