<template>
  <div>
    <b-table
      :data="myJourneyProvidersData.data"
      striped
      hoverable
      :loading="loading"
      paginated
      backend-pagination
      :total="myJourneyProvidersData.meta.total_count"
      :per-page="myJourneyProvidersData.meta.per_page"
      :current-page="myJourneyProvidersData.meta.current_page"
      @page-change="onPageChange"
      :row-class="row => (row.seen ? '' : 'unread')"
    >
      <template slot-scope="props">
        <b-table-column
          field="direct-match"
          label="Direct match"
          id="direct-match-column"
        >
          <b-tooltip
            label="When you see the direct link icon, it means these people are connecting only with you and not other Providers."
          >
            <span v-if="getDirectMatch(props)">
              <img
                src="@/assets/img/direct-match.svg"
                class="icon icon-direct-match"
              />
            </span>
          </b-tooltip>
        </b-table-column>
        <b-table-column field="first_name" label="First Name">
          <span>
            {{ getFirstName(props) }}
          </span>
        </b-table-column>
        <b-table-column field="last_name" label="Last Name">
          <span>
            {{ getLastName(props) }}
          </span>
        </b-table-column>
        <b-table-column field="gender" label="Gender">
          <span>
            {{ getGender(props) }}
          </span>
        </b-table-column>
        <b-table-column field="areas" label="Areas">
          <span>
            {{ getAreas(props) }}
          </span>
        </b-table-column>
        <b-table-column
          field="journey_creation_date"
          label="Journey created on"
        >
          <span>
            {{ getJourneyCreationDate(props) }}
          </span>
        </b-table-column>
        <b-table-column field="status" label="Status">
          <span>
            {{ props.row.status }}
          </span>
        </b-table-column>
        <b-table-column field="messageStatus">
          <span v-if="props.row.seen">
            <b-tooltip label="Mark as unread">
              <img
                @click="updateMessageStatus('unread', props.row.id)"
                src="@/assets/img/read.png"
                class="icon icon-message-status"
                alt="Read message icon"
              />
            </b-tooltip>
          </span>
          <span v-else>
            <b-tooltip label="Mark as read">
              <img
                @click="updateMessageStatus('read', props.row.id)"
                src="@/assets/img/unread.png"
                class="icon icon-message-status"
                alt="Unread message icon"
              />
            </b-tooltip>
          </span>
        </b-table-column>
        <b-table-column field="actions" label="Actions">
          <div class="buttons is-left">
            <router-link
              class="button is-primary"
              :to="'/messages/journey/' + props.row.id"
            >
              View &raquo;
            </router-link>
            <button
              v-if="status !== 'ARCHIVED'"
              :disabled="
                getConversationId(props.row) == null || status == 'ARCHIVED'
              "
              class="button is-danger"
              @click="confirmArchive(props.row)"
            >
              Archive &raquo;
            </button>
            <button
              v-if="status !== 'ARCHIVED'"
              :disabled="
                getConversationId(props.row) == null || status == 'ARCHIVED'
              "
              class="button is-warning"
              @click="openFlagModal(props.row.id)"
            >
              Flag &raquo;
            </button>
          </div>
        </b-table-column>
      </template>
    </b-table>
    <FlagJourneyModal ref="flagJourneyModal" />
  </div>
</template>

<script>
import _ from "lodash";
import FlagJourneyModal from "./FlagJourneyModal";
import LoadingState from "../../../mixins/LoadingState";
import queries from "../queries";
import { mutate } from "../../../vue-apollo";
import { UPDATE_JOURNEY_PROVIDER } from "../../../_graphql/Mutations/mutations";

export default {
  name: "ProvidersListTable",
  components: {
    FlagJourneyModal
  },
  mixins: [LoadingState],
  data() {
    return {
      status: this.$route.params.status == "active" ? "OPEN" : "ARCHIVED",
      queryParams: {
        limit: 25,
        offset: 0,
        filter: [
          `jp.status:${
            this.$route.params.status == "active" ? "OPEN" : "ARCHIVED"
          }`
        ],
        order: ["seen:ASC,last_replay:DESC"]
      },
      myJourneyProvidersData: {
        data: [],
        meta: {}
      }
    };
  },
  props: ["filterParams"],
  computed: {
    formattedQueryParams() {
      return {
        limit: this.queryParams.limit,
        offset: this.queryParams.offset,
        query: this.queryParams.filter.join(","), // The backend property should be called filter instead of query
        order: this.queryParams.order.join(",")
      };
    }
  },
  created() {
    this.$apollo.queries.myJourneyProviders.refetch();
  },
  watch: {
    filterParams(newVal) {
      if (newVal !== "" && newVal.length >= 3) {
        this.formattedQueryParams.query =
          this.queryParams.filter.join(",") + newVal;
      } else {
        this.formattedQueryParams.query = this.queryParams.filter.join(",");
      }
      this.$apollo.queries.myJourneyProviders.refetch();
    }
  },
  methods: {
    confirmArchive(data) {
      this.$buefy.dialog.confirm({
        title: "Archive conversation",
        message:
          "Are you sure you want to <b>archive</b> this conversation? This action cannot be undone.",
        confirmText: "Yes, archive",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => {
          const id = this.getConversationId(data);
          mutate(queries.ARCHIVE_CONVERSATION, { id }, result => {
            if (result) {
              const archiveConversation = result.archiveConversation;
              if (archiveConversation.success) {
                this.$buefy.toast.open({
                  message: "Conversation archived successfully.",
                  type: "is-success"
                });
                this.$apollo.queries.myJourneyProviders.refetch();
              } else if (archiveConversation.errors.length) {
                this.$buefy.toast.open({
                  message: archiveConversation.errors.join(", "),
                  type: "is-danger"
                });
              }
            }
          });
        }
      });
    },
    getConversationId(props) {
      return props.conversation_id;
    },
    getDirectMatch(props) {
      return _.get(_.clone(props.row), "direct_match");
    },
    getFirstName(props) {
      return _.get(_.clone(props.row), "journey.user.authUser.firstName");
    },
    getLastName(props) {
      return _.get(_.clone(props.row), "journey.user.authUser.lastName");
    },
    getGender(props) {
      return _.get(_.clone(props.row), "journey.gender");
    },
    getAreas(props) {
      const areas = _.get(_.clone(props.row), "journey.areas");
      return areas ? areas.map(area => area.name).join(", ") : "-";
    },
    getJourneyCreationDate(props) {
      return _.get(_.clone(props.row), "journey.date_added");
    },
    openFlagModal(id) {
      this.$refs.flagJourneyModal.openModal(id);
    },
    onPageChange(page) {
      this.queryParams.offset =
        this.myJourneyProvidersData.meta.per_page * (page - 1);
      if (this.filterParams !== "" && this.filterParams.length >= 3) {
        this.formattedQueryParams.query =
          this.queryParams.filter.join(",") + this.filterParams;
      }
      this.$apollo.queries.myJourneyProviders.refetch();
    },
    updateMessageStatus(status, id) {
      this.$apollo
        .mutate({
          mutation: UPDATE_JOURNEY_PROVIDER,
          variables: {
            id: parseInt(id),
            input: {
              seen: status == "read"
            }
          }
        })
        .then(({ data: updateJourneyProviders }) => {
          if (updateJourneyProviders) {
            window.location.reload();
          }
        });
    }
  },
  apollo: {
    myJourneyProviders: {
      query: queries.MY_JOURNEY_PROVIDERS,
      variables() {
        return {
          input: this.formattedQueryParams
        };
      },
      result({ data }) {
        const myJourneyProviders = data.myJourneyProviders;
        if (myJourneyProviders) {
          if (myJourneyProviders.errors.length) {
            this.$buefy.toast.open({
              message: myJourneyProviders.errors.join(", "),
              type: "is-danger"
            });
          } else {
            this.myJourneyProvidersData = myJourneyProviders;
          }
        }
      }
    }
  }
};
</script>

<style>
.table {
  min-height: 200px;
}
</style>
<style lang="scss" scoped>
#direct-match-column {
  text-align: center;
}
.icon-direct-match {
  width: 3rem;
  height: 2rem;
}
.icon-message-status {
  min-width: 1.5rem;
  height: 100%;

  &:hover {
    cursor: pointer;
  }
}
.unread span {
  font-weight: 900;
}
.b-tooltip:after {
  width: 180px;
  white-space: inherit;
}
</style>
