<template>
  <section class="section">
    <div class="is-title-bar">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <h1 class="title">
              {{ isAdmin ? "Providers" : "Profile" }}
            </h1>
          </div>
        </div>
        <div v-if="isAdmin" class="level-right">
          <div class="level-item">
            <b-button @click="exportProviders" class="is-primary"
              >Export</b-button
            >
          </div>
        </div>
        <div v-if="showCreateButton" class="level-right">
          <div class="level-item">
            <router-link to="/providers/new">
              <b-button class="is-primary">
                Create Provider
              </b-button>
            </router-link>
          </div>
        </div>
      </div>
      <notification v-if="isVendor" type="light">
        <template v-slot:icon>
          <img src="@/assets/img/icon-5.png" class="icon is-large" />
        </template>
        <template v-slot:message>
          Below you can view and edit your full profile. Click the Create
          Profile button the first time after joining MeTime. On the MeTime app,
          users will select their preferences and preferred location, upload
          photos and submit them to match with Providers. Once you match with
          MeTime users, they will appear in your Active Messages page.
        </template>
      </notification>
      <notification v-if="isVendor" type="light">
        <template v-slot:icon>
          <img src="@/assets/img/icon-4.png" class="icon is-large" />
        </template>
        <template v-slot:message>
          Your profile is your chance to showcase your uniqueness. MeTime users
          will want to know who you are, your expertise, and all that you do.
          You’ll have the chance to upload photos in your profile too. Premium
          subscribers can add up to 10 colour photos, videos, main practitioners
          and highlighted treatments.
        </template>
      </notification>
      <notification v-if="isVendor" type="light">
        <template v-slot:icon>
          <img src="@/assets/img/icon-6.png" class="icon is-large" />
        </template>
        <template v-slot:message>
          In your Messages panel you can review matches, start a chat, and give
          recommendations to users over chat. You can make an offer and schedule
          appointments using the chat functions too. MeTime users will be able
          to view your profile at anytime during the chat to get more
          information about you and your practice.
        </template>
      </notification>
    </div>
    <div v-if="isAdmin" class="level is-mobile">
      <div class="level-left">
        <div class="level-item">
          <DropdownFilter
            input-placeholder="Min 3 chars"
            title="Filters"
            :items="filterDropdownItems"
            :filters="filters"
            :inputFilters="inputFilters"
            @toggleFilter="toggleFilter"
          />
        </div>
      </div>
    </div>
    <ProvidersTable
      v-if="(providers || myProviders) && meUser.user"
      :providers="isAdmin ? providers.data : myProviders.data"
      @deletedProvider="deletedProvider"
      :meta="providers ? providers.meta : myProviders.meta"
      @pageChanged="onPageChange"
    ></ProvidersTable>
  </section>
</template>

<script>
import {
  PROVIDERS,
  MY_PROVIDERS,
  EXPORT_PROVIDERS
} from "../../../_graphql/Queries/queries";
import { mapGetters } from "vuex";
import ProvidersTable from "../components/ProvidersTable";
import DropdownFilter from "../../base/components/DropdownFilter";
import Notification from "../../base/components/Notification";
import _ from "lodash";

export default {
  name: "providersTable",
  data() {
    return {
      data: [],
      filterDropdownItems: [
        {
          name: "is_subscribed",
          title: "Subscription",
          type: "boolean",
          options: [
            {
              value: "true",
              title: "Subscribed"
            },
            {
              value: "false",
              title: "non Subscribed"
            }
          ]
        },
        {
          name: "subscription_plan",
          title: "Subscription plan",
          type: "options",
          options: [
            {
              value: "Basic",
              title: "Basic"
            },
            {
              value: "Premium",
              title: "Premium"
            },
            {
              value: "Pro",
              title: "Pro"
            }
          ]
        },
        {
          name: "status",
          title: "Status",
          type: "options",
          options: [
            {
              value: "1",
              title: "Approved"
            },
            {
              value: "2",
              title: "Disapproved"
            },
            {
              value: "0",
              title: "Pending"
            }
          ]
        },
        {
          name: "co.name",
          title: "Country",
          type: "input"
        },
        {
          name: "c.name",
          title: "City",
          type: "input"
        }
      ],
      filters: {
        "co.name": "",
        "c.name": "",
        status: "",
        is_subscribed: "",
        subscription_plan: ""
      },
      activeSortOptions: {
        "co.name": "",
        "c.name": ""
      },
      inputFilters: {
        country: ""
      },
      queryParams: {
        limit: 25,
        offset: 0,
        filter: {},
        query: "is_guest:0",
        order: "p.id:desc"
      }
    };
  },
  beforeCreate() {
    this.$apollo.provider.defaultClient.cache.reset();
  },
  computed: {
    ...mapGetters({
      meUser: "meUser",
      currentPage: "currentAllProvidersPage"
    }),
    getQueryParams() {
      return {
        offset: this.currentPage
          ? 25 * (this.currentPage - 1)
          : this.queryParams.offset,
        limit: this.queryParams.limit,
        filter: this.queryParams.filter,
        query: this.queryParams.query,
        order: this.queryParams.order
      };
    },
    isVendor() {
      return this.meUser && this.meUser.user.role == "VENDOR";
    },
    isAdmin() {
      return this.meUser && this.meUser.user.role == "ADMINISTRATOR";
    },
    showCreateButton() {
      return (
        this.isVendor && this.myProviders && this.myProviders.data.length == 0
      );
    }
  },
  components: {
    ProvidersTable,
    DropdownFilter,
    Notification
  },
  watch: {
    filters: {
      handler(newVal) {
        let newFilter = {};
        let queryFilter = "is_guest:0";
        Object.keys(newVal).forEach(item => {
          if (newVal[item] != "") {
            if (item == "is_subscribed") {
              newFilter.is_subscribed = newVal[item] == "true";
            } else if (item == "status") {
              newFilter.status = parseInt(newVal[item]);
            } else if (item == "co.name") {
              if (newVal[item].length >= 3) {
                queryFilter += ",co.name%" + newVal[item];
              }
            } else if (item == "c.name") {
              if (newVal[item].length >= 3) {
                queryFilter += ",c.name%" + newVal[item];
              }
            } else {
              newFilter[item] = newVal[item];
            }
          }
        });
        this.queryParams.filter = newFilter;
        this.queryParams.query = queryFilter;
      },
      deep: true
    }
  },
  apollo: {
    providers: {
      query: PROVIDERS,
      variables() {
        return {
          input: this.getQueryParams
        };
      },
      update: data => data.providers,
      skip() {
        return !this.isAdmin;
      }
    },
    myProviders: {
      query: MY_PROVIDERS,
      variables() {
        return {
          input: {
            offset: this.getQueryParams.offset,
            limit: this.getQueryParams.limit
          }
        };
      },
      result(data) {
        const myProviders = _.get(data, "data.myProviders.data", []);
        this.$store.commit("setMyProviders", myProviders);
      },
      skip() {
        return !this.isVendor;
      }
    }
  },
  mounted() {
    if (this.isAdmin) {
      this.$apollo.queries.providers.refetch();
    }
    if (this.isVendor) {
      this.$apollo.queries.myProviders.refetch();
    }
  },
  methods: {
    onPageChange(offset) {
      this.queryParams.offset = offset;
    },
    toggleFilter(payload) {
      const filterName = payload.filterName;
      const value = payload.optionValue;
      this.filters[filterName] =
        this.filters[filterName] != ""
          ? this.filters[filterName] == value
            ? ""
            : value
          : value;
    },
    async exportProviders() {
      if (this.providers.data.length == 0) {
        return this.$buefy.toast.open({
          message: "There is no data in the table",
          type: "is-danger",
          duration: 3000
        });
      }
      await this.$apollo
        .query({
          query: EXPORT_PROVIDERS,

          variables: {
            input: {
              limit: this.getQueryParams.limit,
              offset: this.getQueryParams.offset,
              query: this.getQueryParams.query,
              order: this.getQueryParams.order,
              filter: this.getQueryParams.filter
            }
          },
          update: data => data
        })
        .catch(error => {
          return this.$buefy.toast.open({
            message: `${error}`,
            type: "is-danger"
          });
        })
        .then(({ data: { exportProviders } }) => {
          if (exportProviders) {
            this.exportLink = exportProviders;
            const link = document.createElement("a");
            link.setAttribute("href", this.exportLink);
            link.click();
          }
        });
    },
    deletedProvider() {
      if (this.isAdmin) {
        this.$apollo.queries.providers.refetch();
      }
      if (this.isVendor) {
        this.$apollo.queries.myProviders.refetch();
        this.$store.commit("setMyProviders", []);
      }
    }
  }
};
</script>
