<template>
  <div>
    <hr
      class="is-divider column is-12-desktop"
      style="border-top:1px solid #bebebe;"
    />
    <span class="has-text-left is-clearfix columns">
      <div class="column is-8-desktop has-text-right">
        Discount:
      </div>
      <span class="control has-icons-right column is-4-desktop has-text-right">
        <ValidationProvider
          :rules="{
            digitsRegex: /^(\d+(?:[\.\,]\d{0,2})?)$/
          }"
          v-slot="{ errors, valid }"
          name="Discount"
          ref="discount"
        >
          <input
            :class="{ 'is-danger': !valid }"
            v-model="discount"
            step="any"
            type="text"
            :min="0"
            :max="sumWithoutDiscount"
            class="input"
            @input="validateDiscount"
            v-on:blur="handleBlur"
          />
          <span
            v-for="error in errors"
            :key="error.id"
            class="has-text-danger help"
            >{{ error }}</span
          >
        </ValidationProvider>
        <span class="icon is-small is-right">
          {{ currency.symbol }}
        </span>
      </span>
      <hr />
      <br />
    </span>
    <h2 class="column is-12-desktop title has-text-right">
      Total: {{ totalSum }} {{ currency.symbol }}
    </h2>
    <button
      @click="handleSendOffer"
      class="button is-primary is-fullwidth is-centered"
      :disabled="disableSendBtn"
    >
      Send &raquo;
    </button>
  </div>
</template>
<script>
import { createNamespacedHelpers } from "vuex";

const { mapActions, mapGetters } = createNamespacedHelpers("messages");

export default {
  name: "offerSummary",
  props: ["selectedTreatments", "journeyProviderId", "currency"],
  data() {
    return {
      discount: 0,
      isDiscountValid: true
    };
  },
  methods: {
    ...mapActions(["sendMessage"]),
    handleSendOffer() {
      this.$refs.discount.validate().then(valid => {
        if (
          this.isValid > this.sumWithoutDiscount ||
          !valid ||
          this.selectedTreatments.length == 0 ||
          this.invalidTreatment ||
          this.discount < 0 ||
          !this.isDiscountValid ||
          this.totalSum < 0
        ) {
          return;
        }
        if (this.firstMessage) {
          this.$emit("startedConversation", {
            message: "offer",
            meta: this.gatherOfferData()
          });
        } else {
          this.sendMessage({
            message: "offer",
            journeyProviderId: this.journeyProviderId,
            conversationId: this.conversationId,
            meta: this.gatherOfferData(),
            readBy: [
              this.$store.state.meUser ? this.$store.state.meUser.user._id : ""
            ]
          });

          this.$emit("close");
          this.discount = 0;
        }
      });
    },
    gatherOfferData() {
      if (this.discount < 0 || this.discount == null) {
        return {
          type: "OFFER",
          state: "PENDING",
          treatments: this.selectedTreatments.map(treatment => {
            return {
              name: treatment.name,
              price: `${treatment.price} ${this.currency.symbol}`
            };
          }),
          total: `${this.totalSum} ${this.currency.symbol}`
        };
      }

      return {
        type: "OFFER",
        state: "PENDING",
        treatments: this.selectedTreatments.map(treatment => {
          return {
            name: treatment.name,
            price: `${treatment.price} ${this.currency.symbol}`
          };
        }),
        discount: `${this.discount} ${this.currency.symbol}`,
        total: `${this.totalSum} ${this.currency.symbol}`
      };
    },
    validateDiscount() {
      let regex = new RegExp("^([0-9]+(?:[.,][0-9]{0,2})?)$");
      if (!regex.test(String(this.discount))) {
        this.isDiscountValid = false;
      } else {
        this.isDiscountValid = true;
      }
    },
    handleBlur(){
      // reset default value to 0
      if(this.discount === ''){
         this.discount = 0
        this.validateDiscount()
      }
    }
  },
  computed: {
    ...mapGetters(["conversationId"]),
    invalidTreatment() {
      return this.selectedTreatments.find(treatment => {
        return (
          (!parseFloat(treatment.price) && treatment.price != 0) ||
          treatment.price < 0
        );
      });
    },
    disableSendBtn() {
      return (
        this.isValid > this.sumWithoutDiscount ||
        this.totalSum === "NaN" ||
        this.selectedTreatments.length == 0 ||
        this.invalidTreatment ||
        this.discount < 0 ||
        !this.isDiscountValid ||
        this.totalSum < 0
      );
    },
    totalSum() {
      if (this.selectedTreatments.length == 0) return 0;
      const sum = this.selectedTreatments
        .filter(treatment => treatment.price > 0)
        .map(treatment => treatment.price)
        .reduce((accumulator, price) => accumulator + parseFloat(price), 0);

      let discount = String(this.discount).includes(",")
        ? parseFloat(String(this.discount).replace(",", "."))
        : this.discount;

      return discount >= 0
        ? parseFloat(sum - discount).toFixed(2)
        : parseFloat(sum).toFixed(2);
    },
    isValid() {
      return this.discount;
    },
    sumWithoutDiscount() {
      const sum = this.selectedTreatments
        .filter(treatment => treatment.price > 0)
        .map(treatment => treatment.price)
        .reduce((accumulator, price) => accumulator + parseFloat(price), 0);

      return sum;
    },
    firstMessage() {
      return this.conversationId === 0;
    }
  }
};
</script>
<style scoped>
.icon {
  top: 0.7em !important;
  margin-right: 0.4em;
}
</style>
