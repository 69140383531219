<template>
  <div class="card">
    <header class="card-header">
      <p class="card-header-title has-text-primary">
        <b-icon icon="account-edit" custom-size="default" />
        General Info
      </p>
    </header>
    <div class="card-content has-tooltips">
      <ValidationObserver ref="updateProvider">
        <div class="columns is-multiline is-mobile">
          <div class="column is-12">
            <label for="description-input" class="label has-text-left"
              >Cover image & Avatar
              <b-tooltip
                animated
                multilined
                class="tooltip"
                :label="tooltips.coverImage"
                position="is-top"
                ><b-icon icon="information-outline" type="is-primary"
              /></b-tooltip>
            </label>

            <ProviderProfileImages
              :info="provider"
              :allow-update="allowUpdate"
              :user-role="userRole"
              @addedAvatar="$emit('updatedInfo')"
            ></ProviderProfileImages>
          </div>
          <div
            class="column is-12-mobile is-12-tablet is-12-desktop is-6-fullhd"
          >
            <ValidationProvider
              rules="required"
              v-slot="{ errors, valid }"
              name="Name"
            >
              <div class="field">
                <label for="name-input" class="label has-text-left"
                  >Name
                  <b-tooltip
                    animated
                    multilined
                    :label="tooltips.name"
                    position="is-top"
                  >
                    <b-icon icon="information-outline" type="is-primary" />
                  </b-tooltip>
                </label>
                <div class="control has-icons-left">
                  <input
                    :class="{ 'is-danger': errors[0], 'is-primary': valid }"
                    class="input"
                    :disabled="disableFields"
                    autocomplete="no"
                    trim
                    id="name-input"
                    @focusout="checkForChanges('name')"
                    v-model="name"
                  />
                  <span class="icon is-small is-left">
                    <i class="mdi mdi-account"></i>
                  </span>
                </div>
                <span
                  v-for="error in errors"
                  :key="error.id"
                  class="has-text-danger help"
                  >{{ error }}</span
                >
              </div>
            </ValidationProvider>
          </div>
          <div
            class="column is-12-mobile is-12-tablet is-12-desktop is-6-fullhd"
          >
            <ValidationProvider
              rules="required|email"
              v-slot="{ errors, valid }"
              name="Email"
            >
              <div class="field" id="fieldset-email">
                <label for="email-input" class="label has-text-left"
                  >Email</label
                >
                <div class="control">
                  <input
                    class="input"
                    :class="{ 'is-danger': errors[0], 'is-primary': valid }"
                    :disabled="disableFields"
                    trim
                    icon="email"
                    id="email-input"
                    placeholder="Email"
                    v-model="email"
                    @focusout="checkForChanges('email')"
                  />
                </div>
                <span
                  v-for="error in errors"
                  :key="error.id"
                  class="has-text-danger help"
                  >{{ error }}</span
                >
              </div>
            </ValidationProvider>
          </div>
          <div
            class="column is-12-mobile is-12-tablet is-12-desktop is-6-fullhd"
          >
            <ValidationProvider
                rules="required|whole_number|min_years:0|min:0|max_years:99"
              v-slot="{ errors, valid }"
              name="Experience"
            >
              <div class="field" id="fieldset-experience">
                <label class="label has-text-left"
                  >Experience
                  <b-tooltip
                    animated
                    multilined
                    class="tooltip"
                    :label="tooltips.experience"
                    position="is-top"
                    ><b-icon icon="information-outline" type="is-primary"
                  /></b-tooltip>
                </label>
                <span class="custom-experience-style">
                  <b-field  :type="{ 'is-danger': errors[0], 'is-primary': valid }" class="control experience-control">
                    <b-input
                      id="experience-input"
                      :disabled="disableFields"
                      expanded
                      min="0"
                      max="99"
                      type="number"
                      validation-message=' '
                      v-model="experience"
                      placeholder="Provider's experience"
                      @blur="checkForChanges('experience')"
                    />
                  </b-field>
                  <p class="control">
                    <button
                      class="button is-static is-offset-1-desktop is-outlined"
                      :class="
                        errors[0] ? 'is-danger' : valid ? 'is-primary' : ''
                      "
                    >
                      years
                    </button>
                  </p>
                </span>
                <span
                  v-for="error in errors"
                  :key="error.id"
                  class="has-text-danger help"
                  >{{ error }}</span
                >
              </div>
            </ValidationProvider>
          </div>
          <div
            class="column is-12-mobile is-12-tablet is-12-desktop is-6-fullhd"
          >
            <ValidationProvider
              rules=""
              v-slot="{ errors, valid }"
              name="Clinic"
            >
              <div class="field" id="fieldset-clinic">
                <label class="label has-text-left">Clinic
                  <b-tooltip
                      animated
                      multilined
                      class="tooltip"
                      :label="tooltips.clinic"
                      position="is-top"
                  ><b-icon icon="information-outline" type="is-primary"
                  /></b-tooltip></label>
                <div class="control">
                  <input
                    id="clinic-input"
                    class="input"
                    :class="{ 'is-danger': errors[0], 'is-primary': valid }"
                    :disabled="disableFields"
                    expanded
                    v-model="clinic"
                    placeholder="Clinic"
                    @focusout="checkForChanges('clinic')"
                  />
                </div>
                <span
                  v-for="error in errors"
                  :key="error.id"
                  class="has-text-danger help"
                  >{{ error }}</span
                >
              </div>
            </ValidationProvider>
          </div>
          <div class="column is-12">
            <ValidationProvider
              rules="required"
              v-slot="{ errors, valid }"
              name="Description"
            >
              <div class="field" id="fieldset-description">
                <label for="description-input" class="label has-text-left"
                  >Description
                  <b-tooltip
                    animated
                    multilined
                    class="tooltip"
                    :label="tooltips.description"
                    position="is-top"
                    ><b-icon icon="information-outline" type="is-primary"
                  /></b-tooltip>
                </label>
                <div class="control">
                  <textarea
                    class="textarea"
                    :class="{ 'is-danger': errors[0], 'is-primary': valid }"
                    :disabled="disableFields"
                    trim
                    rows="10"
                    id="description-input"
                    placeholder="Add Provider's description here"
                    v-model="description"
                    @focusout="checkForChanges('description')"
                  />
                </div>
                <span
                  v-for="error in errors"
                  :key="error.id"
                  class="has-text-danger help"
                  >{{ error }}</span
                >
              </div>
            </ValidationProvider>
          </div>
          <div
            class="column is-12-mobile is-12-tablet is-12-desktop is-6-fullhd new-info-box"
          >
            <label class="label has-text-left"
              >Education & Degree
              <b-tooltip
                animated
                multilined
                class="tooltip"
                :label="tooltips.educations"
                position="is-top"
                ><b-icon icon="information-outline" type="is-primary"
              /></b-tooltip>
            </label>
            <AddNewInfo
              :data="educationsInfo"
              :allowUpdate="allowUpdate"
              @addItem="addItem"
              @deletedItem="$emit('updatedInfo')"
            ></AddNewInfo>
          </div>
          <div
            class="column is-12-mobile is-12-tablet is-12-desktop is-6-fullhd new-info-box"
          >
            <span class="box-title">
              <label class="label has-text-left"
                >Trainings & Memberships
              </label>
              <b-tooltip
                animated
                multilined
                class="tooltip"
                :label="tooltips.trainings"
                position="is-top"
                ><b-icon icon="information-outline" type="is-primary"
              /></b-tooltip>
            </span>

            <AddNewInfo
              :data="trainingsInfo"
              :allowUpdate="allowUpdate"
              @addItem="addItem"
              @deletedItem="$emit('updatedInfo')"
            ></AddNewInfo>
          </div>

          <div
            class="column is-12-mobile is-12-tablet is-12-desktop is-6-fullhd new-info-box"
          >
            <span class="box-title">
              <label class="label has-text-left"
                >Awards, Certificates & Publications
              </label>
              <b-tooltip
                animated
                multilined
                class="tooltip"
                :label="tooltips.awards"
                position="is-top"
                ><b-icon icon="information-outline" type="is-primary"
              /></b-tooltip>
            </span>

            <AddNewInfo
              :data="awardsInfo"
              :allowUpdate="allowUpdate"
              @addItem="addItem"
              @deletedItem="$emit('updatedInfo')"
            ></AddNewInfo>
          </div>

          <div
            class="column is-12-mobile is-12-tablet is-12-desktop is-6-fullhd new-info-box"
          >
            <label class="label has-text-left">
              Insurances<b-tooltip
                animated
                multilined
                class="tooltip"
                :label="tooltips.insurances"
                position="is-top"
                ><b-icon icon="information-outline" type="is-primary"
              /></b-tooltip>
            </label>
            <AddNewInfo
              :data="insurancesInfo"
              :allowUpdate="allowUpdate"
              @addItem="addItem"
              @deletedItem="$emit('updatedInfo')"
            ></AddNewInfo>
          </div>

          <div
            class="column is-12-mobile is-12-tablet is-12-desktop is-6-fullhd new-info-box"
          >
            <label class="label has-text-left">Hospital Privileges </label>
            <AddNewInfo
              :data="privilegesInfo"
              :allowUpdate="allowUpdate"
              @addItem="addItem"
              @deletedItem="$emit('updatedInfo')"
            ></AddNewInfo>
          </div>
        </div>
      </ValidationObserver>
      <div v-if="!disableFields">
        <div class="buttons is-right">
          <b-button class="is-primary" @click="saveInfo">Save</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<!-- // // // //  -->

<script>
import ValidationErrors from "../../../mixins/ValidationErrors";
import {
  UPDATE_PROVIDER,
  DELETE_PROVIDER_EDUCATION,
  DELETE_PROVIDER_TRAINING,
  DELETE_PROVIDER_AWARD,
  DELETE_PROVIDER_INSURANCE,
  DELETE_PROVIDER_PRIVILEGE,
  UPDATE_PROVIDER_AWARD,
  UPDATE_PROVIDER_TRAINING,
  UPDATE_PROVIDER_EDUCATION,
  UPDATE_PROVIDER_INSURANCE,
  UPDATE_PROVIDER_PRIVILEGE
} from "../../../_graphql/Mutations/mutations";
import ProviderProfileImages from "./ProviderProfileImages";
import AddNewInfo from "./AddNewInfo";
export default {
  name: "ProviderForm",
  data() {
    return {
      isActive: true,
      name: "",
      email: "",
      experience: null,
      clinic: "",
      description: "",
      degree: "",
      education: null,
      training: null,
      award: null,
      insurance: null,
      privilege: null,
      tooltips: {
        coverImage: "Add cover and avatar images",
        avatar: "Add a photo here",
        name: "Name of Practitioner or name of Clinic",
        experience: "How many years of experience do you have in this field",
        clinic: "Enter the name of the clinic or practice you work at if it is different from Name above.  The clinic name will appear separately in your profile.",
        description:
          " Write a brief description of your clinic or practice that highlights the most relevant and attractive features for patients or clients",
        educations:
          " Where did you go to University or receive your education? What degree(s) did you earn? List your education and degree(s) here, e.g. MD, FRCS, FACS…",
        trainings:
          "Where did you receive your most relevant professional training? For memberships you can list your professional societies and affiliations.",
        awards: "List your accomplishments here",
        memberships: "Your professional societies and affiliations",
        insurances:
          " Do you take health insurance at your practice?  If so, list the companies",
        matchInfo:
          "Turn off matching if you don’t want to appear in matches of people using the app. This won’t affect existing matches and chats and does not prevent people matching with you when you share your unique MeTime URL"
      }
    };
  },
  props: {
    isValid: {
      required: true
    },
    userRole: {
      default: ""
    },
    provider: {
      required: false
    },
    allowUpdate: {
      default: false
    }
  },

  components: { ProviderProfileImages, AddNewInfo },
  created() {
    if (this.provider) {
      this.name = this.provider.name;
      this.email = this.provider.email;
      this.address = this.provider.address.address;
      this.zip = this.provider.address.zip;
      this.clinic = this.provider.clinic || "";
      this.experience = parseInt(this.provider.experience);
      this.description = this.provider.description;
    }
  },
  computed: {
    isVendor() {
      return this.userRole && this.userRole == "VENDOR";
    },
    isOperator() {
      return this.userRole && this.userRole == "OPERATOR";
    },
    disableFields() {
      return !this.allowUpdate;
    },
    educationsInfo() {
      return {
        itemType: "educations",
        mutations: {
          edit: {
            name: "updateProviderEducation",
            mutation: UPDATE_PROVIDER_EDUCATION
          },
          delete: {
            name: "deleteProviderEducation",
            mutation: DELETE_PROVIDER_EDUCATION
          }
        },
        items: this.provider.educations
      };
    },
    trainingsInfo() {
      return {
        itemType: "trainings",
        mutations: {
          edit: {
            name: "updateProviderTraining",
            mutation: UPDATE_PROVIDER_TRAINING
          },
          delete: {
            name: "deleteProviderTraining",
            mutation: DELETE_PROVIDER_TRAINING
          }
        },
        items: this.provider.trainings
      };
    },
    awardsInfo() {
      return {
        itemType: "awards",
        mutations: {
          edit: {
            name: "updateProviderAward",
            mutation: UPDATE_PROVIDER_AWARD
          },
          delete: {
            name: "deleteProviderAward",
            mutation: DELETE_PROVIDER_AWARD
          }
        },
        items: this.provider.awards
      };
    },
    insurancesInfo() {
      return {
        itemType: "insurances",
        mutations: {
          edit: {
            name: "updateProviderInsurance",
            mutation: UPDATE_PROVIDER_INSURANCE
          },
          delete: {
            name: "deleteProviderInsurance",
            mutation: DELETE_PROVIDER_INSURANCE
          }
        },
        items: this.provider.insurances
      };
    },
    privilegesInfo() {
      return {
        itemType: "privileges",
        mutations: {
          edit: {
            name: "updateProviderPrivilege",
            mutation: UPDATE_PROVIDER_PRIVILEGE
          },
          delete: {
            name: "deleteProviderPrivilege",
            mutation: DELETE_PROVIDER_PRIVILEGE
          }
        },
        items: this.provider.privileges
      };
    }
  },
  methods: {
    checkForChanges(value) {
      if (this[value] !== this.provider[value]) {
        this.$emit("editedField");
      }
    },
    addItem(data) {
      if (data.type == "educations") {
        this.education = { name: data.value };
      }
      if (data.type == "trainings") {
        this.training = { name: data.value };
      }
      if (data.type == "awards") {
        this.award = { name: data.value };
      }
      if (data.type == "insurances") {
        this.insurance = { name: data.value };
      }
      if (data.type == "privileges") {
        this.privilege = { name: data.value };
      }

      this.handleUpdate();
    },
    saveInfo() {
      this.$refs.updateProvider.validate().then(async success => {
        if (!success) {
          this.refName = "updateProvider";
          this.handleErrors();
          return;
        }
        await this.handleUpdate();
      });
    },
    async handleUpdate() {
      const providerId = parseInt(this.$route.params.providerId);
      const mutation = UPDATE_PROVIDER;
      const payload = {
        mutation: mutation,
        queryName: "updateProvider"
      };
      const variables = {
        id: providerId,
        input: {
          name: this.name,
          email: this.email,
          phone: this.phone,
          experience: parseInt(this.experience),
          clinic: this.clinic,
          description: this.description,
          educations: this.education,
          trainings: this.training,
          awards: this.award,
          insurances: this.insurance,
          privileges: this.privilege
        }
      };

      this.$store.commit("provider/collection/editModel", variables);
      await this.$store.dispatch("provider/collection/update", payload);
      this.$emit("updatedInfo");
      this.$emit("savedChanges");
      this.clearData();
    },
    clearData() {
      this.education = null;
      this.training = null;
      this.award = null;
      this.insurance = null;
      this.privilege = null;
    }
  },
  mixins: [ValidationErrors]
};
</script>

<style lang="scss" scoped>
.helpText {
  margin-left: 4.625rem;
  margin-right: 1.75rem;
  padding-bottom: 1rem;
  text-align: justify;
}

.b-tooltip {
  display: flex;
  margin-left: 0.5rem;
  margin-bottom: 2px;

  &after {
    word-break: break-word;
  }
}

.label {
  display: flex;
  margin-bottom: 0.5em !important;
}
.control {
  margin-bottom: 1rem;
}

.custom-experience-style {
  display: flex;
  .button {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
  .experience-control {
    margin-right: -1px;
    width: 100%;
    #experience-input {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }
  }
}
.switch-column {
  display: flex;
  margin-top: 2rem;
}

.new-info-box {
  display: flex;
  flex-direction: column;
  .box {
    flex: 1;
  }

  .box-title {
    display: flex;
  }

  @media (max-width: 576px) {
    .box-title {
      .label {
        max-width: 160px;
      }
    }
  }
}

.experience-input-style {
  border-color: #0ba1d1!important;
}
</style>
