<template>
  <ValidationObserver ref="userFormValidator" class="userForm">
    <div class="tile is-ancestor">
      <section class="tile is-parent is-6 is-vertical">
        <div class="columns" style="flex-wrap: wrap">
          <ValidationProvider
            class="is-5 column"
            rules="required|min:2"
            v-slot="{ errors, valid }"
            name="First Name"
          >
            <b-field
              :type="{ 'is-danger': errors[0], 'is-primary': valid }"
              label="First Name"
            >
              <b-input trim v-model="form.firstName" />
            </b-field>
            <span
              v-for="error in errors"
              :key="error.id"
              class="has-text-danger help"
              >{{ error }}</span
            >
          </ValidationProvider>
          <ValidationProvider
            class="is-5 is-offset-1 column"
            rules="required|min:2"
            v-slot="{ errors, valid }"
            name="Last Name"
          >
            <b-field
              :type="{ 'is-danger': errors[0], 'is-primary': valid }"
              label="Last Name"
            >
              <b-input trim v-model="form.lastName" />
            </b-field>
            <span
              v-for="error in errors"
              :key="error.id"
              class="has-text-danger help"
              >{{ error }}</span
            >
          </ValidationProvider>
          <ValidationProvider
            class="is-5 column"
            rules="required|email"
            v-slot="{ errors, valid }"
            name="Email"
          >
            <b-field
              :type="{ 'is-danger': errors[0], 'is-primary': valid }"
              label="Email"
            >
              <b-input trim v-model="form.email" />
            </b-field>
            <span
              v-for="error in errors"
              :key="error.id"
              class="has-text-danger help"
              >{{ error }}</span
            >
          </ValidationProvider>
          <ValidationProvider
            class="is-5 is-offset-1 column"
            :rules="{
              required: !editModel,
              passRegex: /^(?=.*[A-Za-z!@#$%^&*])(?=.*\d)[A-Za-z!@#$%^&*\d]{8,}$/
            }"
            v-slot="{ errors, valid }"
            name="Password"
          >
            <b-field
              :type="{ 'is-danger': errors[0], 'is-primary': valid }"
              :label="editModel ? 'New Password' : 'Password'"
            >
              <b-input
                type="password"
                password-reveal
                trim
                v-model="form.password"
              />
            </b-field>
            <span
              v-for="error in errors"
              :key="error.id"
              class="has-text-danger help"
              >{{ error }}</span
            >
          </ValidationProvider>
        </div>
      </section>
      <div class="tile is-parent is-3 is-vertical">
        <div class="columns" style="flex-wrap: wrap">
          <ValidationProvider
            class="is-10 column"
            v-slot="{ errors, valid }"
            name="Country"
          >
            <b-field
              :type="{ 'is-danger': errors[0], 'is-primary': valid }"
              label="Country"
            >
              <b-input trim v-model="form.country" />
            </b-field>
            <span
              v-for="error in errors"
              :key="error.id"
              class="has-text-danger help"
              >{{ error }}</span
            >
          </ValidationProvider>
          <ValidationProvider
            class="is-10 column"
            v-slot="{ errors, valid }"
            name="City"
          >
            <b-field
              :type="{ 'is-danger': errors[0], 'is-primary': valid }"
              label="City"
            >
              <b-input trim v-model="form.city" />
            </b-field>
            <span
              v-for="error in errors"
              :key="error.id"
              class="has-text-danger help"
              >{{ error }}</span
            >
          </ValidationProvider>
        </div>
      </div>
      <div
        class="tile is-3 is-parent is-vertical"
        id="avatar"
        style="height: 200px; width: 200px"
      >
        <label class="label" style>Profile Avatar</label>
        <img
          class="avatarImage"
          :src="
            this.form.avatar
              ? this.form.avatar
              : this.origin + '/img/icons/blank_avatar.png'
          "
        />
        <div class="upload-btn-wrapper">
          <button class="button is-light avatar-button plus">
            <b-icon icon="plus" size="is-default" />
          </button>
          <input
            ref="avatarInput"
            type="file"
            title="Upload profile picture"
            @input="uploadImage"
            name="avatar"
          />
        </div>
        <button
          v-if="this.form.avatar"
          @click="removeAvatar"
          class="button is-light avatar-button trash-can"
        >
          <b-icon icon="trash-alt" pack="fas" size="is-small" />
        </button>
      </div>
    </div>
    <div class="tile is-ancestor">
      <section class="tile is-parent is-6 is-vertical">
        <div class="columns" style="flex-wrap: wrap">
          <ValidationProvider
            class="is-5 column"
            v-slot="{ errors, valid }"
            name="Role"
          >
            <b-field
              label="Role"
              :type="{
                'is-danger': errors[0],
                'is-primary': valid
              }"
            >
              <b-select v-model="form.role" expanded>
                <option
                  v-for="role in roles"
                  :value="role.value"
                  :key="role.title"
                  >{{ role.title }}</option
                >
              </b-select>
            </b-field>
            <span
              v-for="error in errors"
              :key="error.id"
              class="has-text-danger help"
              >{{ error }}</span
            >
          </ValidationProvider>
          <ValidationProvider
            class="is-5 is-offset-1 column"
            v-slot="{ errors, valid }"
            name="Age"
          >
            <b-field
              label="Age"
              :type="{
                'is-danger': errors[0],
                'is-primary': valid
              }"
            >
              <b-select v-model="form.age" expanded>
                <option :value="null">None</option>
                <option v-for="number in 100" :value="number" :key="number">
                  {{ number }}
                </option>
              </b-select>
            </b-field>
            <span
              v-for="error in errors"
              :key="error.id"
              class="has-text-danger help"
              >{{ error }}</span
            >
          </ValidationProvider>
        </div>
      </section>
      <div class="tile is-parent is-3 is-vertical">
        <div class="columns" style="flex-wrap: wrap">
          <ValidationProvider
            class="is-10 column"
            v-slot="{ errors, valid }"
            name="Gender"
          >
            <b-field
              label="Gender"
              :type="{
                'is-danger': errors[0],
                'is-primary': valid
              }"
            >
              <b-select v-model="form.gender" expanded>
                <option
                  v-for="gender in genders"
                  :value="gender.value"
                  :key="gender.title"
                  >{{ gender.title }}</option
                >
              </b-select>
            </b-field>
            <span
              v-for="error in errors"
              :key="error.id"
              class="has-text-danger help"
              >{{ error }}</span
            >
          </ValidationProvider>
        </div>
      </div>
    </div>
    <div class="columns userFormSubmit">
      <div class="column is-offset-9">
        <b-button
          class="is-primary"
          :loading="formSubmitTimeout"
          @click="submitForm"
          >Save</b-button
        >
      </div>
    </div>
  </ValidationObserver>
</template>
<script>
import _ from "lodash";
import ValidationErrors from "../../../mixins/ValidationErrors";

import { UPLOAD_IMAGE } from "../../../_graphql/Mutations/mutations";

export default {
  mixins: [ValidationErrors],
  props: {
    resetSwitch: {
      type: Boolean,
      default: false,
      required: false
    },
    editModel: {
      type: Object,
      default: null,
      required: false
    }
  },
  data() {
    return {
      form: {
        _id: null,
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        country: null,
        city: null,
        avatar: null,
        role: "VENDOR",
        age: null,
        gender: null,
        clinics: []
      },
      roles: [
        {
          title: "Buyer",
          value: "BUYER"
        },
        {
          title: "Vendor",
          value: "VENDOR"
        },
        {
          title: "Operator",
          value: "OPERATOR"
        },
        {
          title: "Moderator",
          value: "MODERATOR"
        },
        {
          title: "Administrator",
          value: "ADMINISTRATOR"
        }
      ],
      genders: [
        {
          title: "None",
          value: null
        },
        {
          title: "Male",
          value: "MALE"
        },
        {
          title: "Female",
          value: "FEMALE"
        }
      ],
      origin: "",
      emptyForm: null,
      formSubmitTimeout: false
    };
  },
  watch: {
    resetSwitch() {
      this.form = _.clone(this.emptyForm);
      this.$refs.userFormValidator.reset();
    }
  },
  created() {
    this.emptyForm = _.clone(this.form);
  },
  mounted() {
    this.origin = location.origin;
    this.populateEditForm();
  },
  methods: {
    submitForm() {
      this.$refs.userFormValidator.validate().then(success => {
        if (!success) {
          this.refName = "userFormValidator";
          this.handleErrors();
          return;
        }
        this.$emit("formSubmit", this.form);
        this.$apollo.getClient().resetStore(); // to clear allUsers table so it can be refetched with new/edited user. should be refactored to refetch only allUsers
        this.formSubmitTimeout = true;
        setTimeout(() => {
          this.formSubmitTimeout = false;
        }, 4500);
      });
    },
    uploadImage(event) {
      let files = event.target.files;
      let file = files.length > 0 ? files[0] : null;
      if (!file) {
        return;
      }
      if (
        file.type !== "image/png" &&
        file.type !== "image/jpg" &&
        file.type !== "image/jpeg"
      ) {
        this.$refs["avatarInput"].value = "";
        return this.$buefy.toast.open({
          message: "Allowed image formats are .jpg, .jpeg and .png!",
          type: "is-warning"
        });
      }
      let config = {
        header: {
          "Content-Type": "image/*"
        }
      };
      this.$apollo.mutate({
        mutation: UPLOAD_IMAGE,
        variables: {
          file: file
        },
        context: config,
        update: (cache, { data: { resize } }) => {
          this.form.avatar = resize.data.url;
        }
      });
    },
    removeAvatar() {
      this.$buefy.dialog.confirm({
        title: "Confirm Removal",
        message: "Are you sure you want to <b>remove</b> this picture?",
        confirmText: "Remove",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => {
          this.form.avatar = null;
          this.$refs["avatarInput"].value = "";
        }
      });
    },
    populateEditForm() {
      if (this.editModel) {
        this.form._id = this.editModel._id;
        this.form.firstName = this.editModel.firstName;
        this.form.lastName = this.editModel.lastName;
        this.form.email = this.editModel.email;
        this.form.avatar = this.editModel.avatar;
        this.form.role = this.editModel.role;
        this.form.age = this.editModel.age;
        this.form.city = this.editModel.city;
        this.form.country = this.editModel.country;
        this.form.gender =
          this.editModel.gender == "NONE" ? null : this.editModel.gender;
      }
    }
  }
};
</script>

<style lang="scss">
@import "bulma/sass/utilities/_all";
@import "bulma/sass/grid/columns";

.userForm {
  label {
    text-align: left;
  }
  .label {
    color: unset !important;
  }
  div.control span.icon.is-clickable {
    color: #7a7a7a !important;
  }
  .input.is-danger:hover,
  .input.is-danger:focus {
    border-color: #ff3860;
  }
  .select select {
    border-color: #dbdbdb;
  }
  .select select {
    &:hover,
    &:active,
    &:focus {
      border-color: #b5b5b5;
    }
  }
  .select select:focus {
    border-color: none;
  }
  .input.is-primary,
  span.select.is-primary select {
    &:focus,
    &:active,
    &:hover {
      border-color: #01a7e9;
      box-shadow: 0 0 0 0.125em rgba(11, 161, 209, 0.25) !important;
    }
  }
  .input.is-danger,
  span.select.is-danger select {
    &:focus,
    &:active,
    &:hover {
      border-color: #ff1f4b;
      box-shadow: 0 0 0 0.125em rgba(255, 56, 96, 0.25) !important;
    }
  }
  span.column {
    min-height: 110px;
    padding-bottom: 0;
  }
  #avatar {
    img {
      border-radius: 13px;
      width: 250px;
      height: 250px;
      min-width: 250px;
      min-height: 250px;
      object-fit: contain;
      background: white;
      border: 1px solid lightgray;
    }
  }
  .upload-btn-wrapper input[type="file"] {
    position: absolute;
    width: 36px;
    height: 56px;
    left: 0;
    top: -18px;
    opacity: 0;
  }
  button.avatar-button {
    position: relative;
    display: -webkit-inline-box;
    width: 36px;
    height: 36px;
    padding: 4px;
    border: 0;
    border-radius: 50%;
    span {
      color: white;
      border-radius: 50%;
      background: #01a7e9;
    }
    span.icon {
      width: 28px;
      height: 28px;
    }
  }
  button.trash-can {
    left: 170px;
    bottom: 58px;
    height: 36px;
    width: 36px;
    span.icon {
      background: #ff1f4b;
      i:before {
        font-size: 20px;
      }
    }
  }
  .upload-btn-wrapper {
    position: relative;
    left: 228px;
    bottom: 22px;
    width: 36px;
    height: 36px;
  }
  input[type="file"] {
    cursor: pointer;
  }
  .userFormSubmit {
    button {
      font-size: 1.15rem;
      width: 120px;
    }
  }
  @include tablet {
    .userFormSubmit {
      margin-top: 100px;
    }
  }
  @include until($tablet) {
    #avatar {
      margin: auto;
      margin-bottom: 70px;
      height: 250px;
      width: 250px;
    }
    .upload-btn-wrapper {
      bottom: 26px;
    }
    button.trash-can {
      left: 75px;
      bottom: 62px;
    }
  }
}
</style>
