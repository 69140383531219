<template>
  <section class="section">
    <title-bar class="edit-article-title"
      >Edit Solution
      <span
        v-if="solution"
        class="has-text-primary has-text-weight-normal is-size-5 is-draft"
      >
        {{
          solution && solution.is_published
            ? " (published)"
            : " (not published)"
        }}
      </span>
    </title-bar>
    <ValidationObserver ref="editSolutionForm">
      <review-section
        v-if="solution"
        ref="reviewSection"
        :statuses="reviewStatuses"
        :user-role="userRole"
        :query-info="solution"
        @editedField="setUnsavedChanges('reviewSection')"
        type="Solution"
      ></review-section>
      <card-component
        title="Solution Info"
        custom-title-styles="has-text-primary"
      >
        <solution-form
          v-if="solution"
          :disable-fields="disableFields"
          :form="solutionForm"
          :publish-validation="isRequired"
          @editedField="setUnsavedChanges($event, 'solutionForm')"
          ref="solutionForm"
          @categoryChanged="categoryChanged"
        ></solution-form>
      </card-component>
      <card-component
        title="Search & Reference"
        custom-title-styles="has-text-primary"
      >
        <reference
          :current-solution-slug="solution.slug"
          :disable-fields="disableFields"
          v-if="solution"
          :form="referenceForm"
          @editedField="setUnsavedChanges($event, 'referenceForm')"
          :category="solutionForm.category"
          :publish-validation="isRequired"
          :is-published="solution.is_published"
          @deletedKeyword="deleteKeyword"
        ></reference>
      </card-component>
    </ValidationObserver>
    <div v-if="solution" class="buttons is-right solution-action-buttons">
      <b-tooltip
        v-if="userRole && userRole !== 'OPERATOR'"
        animated
        multilined
        class="tooltip"
        type="is-warning"
        label="If this article is currently published, your changes will go live immediately"
        position="is-top"
        size="is-small"
      >
        <b-button :disabled="disableFields" @click="onSave" class="is-primary"
          >Save changes</b-button
        >
      </b-tooltip>
      <b-button
        v-else
        class="is-primary"
        :disabled="disableFields"
        @click="onSave"
        >Save changes</b-button
      >
      <b-button
        v-if="userRole !== 'OPERATOR'"
        @click="handlePublish"
        class="is-primary"
        >{{
          solution && solution.is_published ? "Unpublish" : "Publish"
        }}</b-button
      >
    </div>
    <has-changes-modal
      v-if="solution"
      :is_published="solution.is_published"
      ref="hasChangesModal"
      @saveChanges="onPublishConfirm()"
      @discardChanges="discardChanges()"
    ></has-changes-modal>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import ReviewSection from "../../providers/components/ReviewSection";
import CardComponent from "../../base/components/CardComponent";
import TitleBar from "../../base/components/TitleBar";
import SolutionForm from "../components/SolutionForm";
import Reference from "../components/Reference";
import { SOLUTION } from "../../../_graphql/Queries/queries";
import { UPDATE_SOLUTION } from "../../../_graphql/Mutations/mutations";
import ValidationErrors from "../../../mixins/ValidationErrors";
import hasChangesModal from "../components/hasChangesModal";

export default {
  data() {
    return {
      hasChanges: {
        reviewSection: false,
        solutionForm: false,
        reference: false
      },
      isRequired: false,
      isMySolution: false,
      is_published: false,
      adminStatuses: [
        {
          name: "Draft",
          value: "DRAFT"
        },
        {
          name: "Ready for review",
          value: "READY_FOR_REVIEW"
        },
        {
          name: "Needs corrections",
          value: "NEEDS_CORRECTIONS"
        },
        {
          name: "Reviewed",
          value: "REVIEWED"
        }
      ],
      operatorStatuses: [
        {
          name: "Draft",
          value: "DRAFT"
        },
        {
          name: "Ready for review",
          value: "READY_FOR_REVIEW"
        }
      ],
      solutionForm: {
        title: "",
        meta_title: "",
        slug: "",
        category: null,
        subcategory: {
          name: ""
        },
        description: "",
        meta_description: "",
        content: "",
        read_time: null,
        cover_thumb_url: "",
        cover_url: "",
        coverImageDropFiles: null,
        thumbnailDropFiles: null
      },
      referenceForm: {
        related_solutions: [],
        treatments: [],
        products: [],
        technologies: [],
        specialties: [],
        keywords: []
      }
    };
  },
  mixins: [ValidationErrors],
  components: {
    ReviewSection,
    CardComponent,
    TitleBar,
    SolutionForm,
    Reference,
    hasChangesModal
  },
  computed: {
    ...mapGetters({
      userRole: "userRole",
      meUser: "meUser"
    }),
    reviewStatuses() {
      return {
        adminStatuses: this.adminStatuses,
        moderatorStatuses: this.adminStatuses,
        operatorStatuses: this.operatorStatuses
      };
    },
    allowUpdate() {
      return (
        this.userRole !== "OPERATOR" ||
        (this.userRole == "OPERATOR" &&
          this.isMySolution &&
          !this.solution.is_published &&
          this.solution.status !== "REVIEWED")
      );
    },
    disableFields() {
      return (
        this.userRole == "OPERATOR" &&
        (this.solution.is_published ||
          !this.isMySolution ||
          this.solution.status == "REVIEWED")
      );
    },
    hasEmptyFields() {
      let hasEmptyField = false;
      if (
        this.solution.meta_title == "" ||
        this.solution.slug == "" ||
        this.solution.description == "" ||
        this.solution.meta_description == "" ||
        this.solution.content == "" ||
        this.solution.read_time == null ||
        this.solution.cover_thumb_url == "" ||
        this.solution.cover_url == "" ||
        this.solution.keywords.length == 0
      ) {
        hasEmptyField = true;
      }
      if (
        this.solution.treatments.length == 0 &&
        this.solution.technologies.length == 0 &&
        this.solution.products == 0 &&
        this.solution.specialties.length == 0
      ) {
        hasEmptyField = true;
      }
      return hasEmptyField;
    }
  },
  methods: {
    setUnsavedChanges(item, section) {
      if (item == "category" || item == "subcategory") {
        if (this[section][item] !== this.solution.categories[item]) {
          this.hasChanges[section] = true;
        }
      } else if (section == "reference") {
        if (item == "keywords") {
          const solutionKeywords = this.solution.keywords.map(keyword => {
            return keyword.slug;
          });
          if (this[section][item] !== solutionKeywords) {
            this.hasChanges[section] = true;
          }
        } else {
          const items = this.solution[item].map(item => {
            return item.id;
          });
          if (this[section][item] !== items) {
            this.hasChanges[section] = true;
          }
        }
      } else if (item == "reviewSection") {
        this.hasChanges[item] = true;
      } else if (this[section][item] !== this.solution[item]) {
        this.hasChanges[section] = true;
      }
    },
    clearUnsavedChangesState() {
      for (const section in this.hasChanges) {
        this.hasChanges[section] = false;
      }
    },
    populateEditForm(data) {
      let category = {
        id: data.categories.category.id,
        slug: data.categories.category.slug,
        name: data.categories.category.name
      };
      this.solutionForm.title = data.title;
      this.solutionForm.meta_title = data.meta_title;
      this.solutionForm.slug = data.slug;
      this.solutionForm.category = category;
      this.solutionForm.subcategory.name = data.categories.subcategory.name;
      this.solutionForm.description = data.description;
      this.solutionForm.meta_description = data.meta_description;
      this.solutionForm.content = data.content || "";
      this.solutionForm.read_time = data.read_time;
      this.solutionForm.thumbnailDropFiles = [data.cover_thumb_url];
      this.solutionForm.coverImageDropFiles = [data.cover_url];
      this.solutionForm.cover_url = data.cover_url;
      this.solutionForm.cover_thumb_url = data.cover_thumb_url;
      this.referenceForm.treatments = data.treatments.map(treatment => {
        return treatment.id;
      });
      this.referenceForm.technologies = data.technologies.map(technology => {
        return technology.id;
      });
      this.referenceForm.products = data.products.map(product => {
        return product.id;
      });
      this.referenceForm.specialties = data.specialties.map(specialty => {
        return specialty.id;
      });
      this.referenceForm.keywords = data.keywords.map(keyword => {
        return keyword.slug;
      });
      if (data.related_solutions) {
        this.referenceForm.related_solutions = data.related_solutions.map(
          solution => {
            return solution.id;
          }
        );
      }
    },
    onPublish() {
      this.$apollo
        .mutate({
          mutation: UPDATE_SOLUTION,
          variables: {
            id: parseInt(this.$route.params.id),
            input: {
              is_published: this.is_published
            }
          }
        })
        .then(({ data: updateSolution }) => {
          if (updateSolution) {
            this.$buefy.toast.open({
              message: "Solution updated successfully",

              type: "is-success"
            });
            this.$apollo.queries.solution.refetch();
          }
        });
    },
    deleteKeyword(keyword) {
      this.referenceForm.keywords = this.referenceForm.keywords.filter(item => {
        return item !== keyword.text;
      });
    },
    categoryChanged() {
      this.$refs.editSolutionForm.reset();
    },

    async onSave() {
      if (this.allowUpdate && !this.disableFields) {
        if (this.solution.is_published) {
          this.isRequired = true;
        } else {
          this.isRequired = false;
        }
        this.is_published = this.solution.is_published;
        await this.$refs.editSolutionForm.reset();

        this.$refs.editSolutionForm.validate().then(valid => {
          if (!valid) {
            this.refName = "editSolutionForm";
            this.handleErrors();
            return;
          }
          if (
            this.solution.is_published &&
            this.referenceForm.keywords.length == 0
          ) {
            return this.$buefy.toast.open({
              message: "Keywords field is required",
              type: "is-danger"
            });
          }
          if (
            this.solution.is_published &&
            this.referenceForm.treatments.length == 0 &&
            this.referenceForm.products.length == 0 &&
            this.referenceForm.technologies.length == 0 &&
            this.referenceForm.specialties.length == 0
          ) {
            return this.$buefy.toast.open({
              message:
                "You must populate at least one treatment, product, technology or provider type",
              type: "is-danger"
            });
          }

          this.saveChanges();
        });
      }
    },
    saveChanges() {
      let variables = {
        id: parseInt(this.$route.params.id),
        input: {
          title: this.solutionForm.title,
          category_id: this.solutionForm.category.id,
          subcategory: this.solutionForm.subcategory.name,
          cover_url: this.solutionForm.cover_url,
          cover_thumb_url: this.solutionForm.cover_thumb_url,
          meta_title: this.solutionForm.meta_title,
          slug: this.solutionForm.slug,
          description: this.solutionForm.description,
          meta_description: this.solutionForm.meta_description,
          content: this.solutionForm.content,
          read_time: parseInt(this.solutionForm.read_time),
          keywords: this.referenceForm.keywords,
          related_solutions_ids: this.referenceForm.related_solutions,
          treatments_ids: this.referenceForm.treatments,
          products_ids: this.referenceForm.products,
          technologies_ids: this.referenceForm.technologies,
          specialties_ids: this.referenceForm.specialties,
          is_published: this.is_published,
          note: this.$refs.reviewSection.note
        }
      };

      if (this.$refs.reviewSection.newStatus) {
        variables.input.status = this.$refs.reviewSection.newStatus;
      }

      this.$apollo
        .mutate({
          mutation: UPDATE_SOLUTION,
          variables: variables
        })
        .then(({ data: { updateSolution } }) => {
          if (updateSolution) {
            this.$buefy.toast.open({
              message: "Solution updated successfully",
              type: "is-success"
            });
            this.$apollo.queries.solution.refetch();
            this.clearUnsavedChangesState();
          }
        });
    },
    handlePublish() {
      if (this.allowUpdate && !this.disableFields) {
        if (
          Object.keys(this.hasChanges).find(section => {
            return this.hasChanges[section] == true;
          })
        ) {
          this.$refs.hasChangesModal.openModal();
        } else {
          this.discardChanges();
        }
      }
    },
    async discardChanges() {
      if (!this.solution.is_published) {
        this.isRequired = true;
      } else {
        this.isRequired = false;
      }
      this.is_published = !this.solution.is_published;

      await this.$refs.editSolutionForm.reset();
      if (this.hasEmptyFields && this.is_published) {
        return this.$buefy.toast.open({
          message: "Please fill all required fields",
          type: "is-danger"
        });
      }
      this.publishSolution();
      this.clearUnsavedChangesState();
    },
    async onPublishConfirm() {
      if (!this.solution.is_published) {
        this.isRequired = true;
      } else {
        this.isRequired = false;
      }
      this.is_published = !this.solution.is_published;

      await this.$refs.editSolutionForm.reset();

      this.$refs.editSolutionForm.validate().then(valid => {
        if (!valid) {
          this.refName = "editSolutionForm";
          this.handleErrors();
          return;
        }
        if (
          !this.solution.is_published &&
          this.referenceForm.keywords.length == 0
        ) {
          return this.$buefy.toast.open({
            message: "Keywords field is required",
            type: "is-danger"
          });
        }
        if (
          !this.solution.is_published &&
          this.referenceForm.treatments.length == 0 &&
          this.referenceForm.products.length == 0 &&
          this.referenceForm.technologies.length == 0 &&
          this.referenceForm.specialties.length == 0
        ) {
          return this.$buefy.toast.open({
            message:
              "You must populate at least one treatment, product, technology or provider type",
            type: "is-danger"
          });
        }
        this.saveChanges();
      });
    },
    publishSolution() {
      this.$refs.editSolutionForm.validate().then(valid => {
        if (!valid) {
          this.refName = "editSolutionForm";
          this.handleErrors();
          return;
        }
        if (
          !this.solution.is_published &&
          this.referenceForm.keywords.length == 0
        ) {
          return this.$buefy.toast.open({
            message: "Keywords field is required",
            type: "is-danger"
          });
        }
        if (
          !this.solution.is_published &&
          this.referenceForm.treatments.length == 0 &&
          this.referenceForm.products.length == 0 &&
          this.referenceForm.technologies.length == 0 &&
          this.referenceForm.specialties.length == 0
        ) {
          return this.$buefy.toast.open({
            message:
              "You must populate at least one treatment, product, technology or provider type",
            type: "is-danger"
          });
        }
        this.$apollo
          .mutate({
            mutation: UPDATE_SOLUTION,
            variables: {
              id: parseInt(this.$route.params.id),
              input: {
                is_published: this.is_published
              }
            }
          })
          .then(({ data: updateSolution }) => {
            if (
              updateSolution &&
              updateSolution.is_published == this.is_published
            ) {
              this.$buefy.toast.open({
                message: "Solution updated successfully",

                type: "is-success"
              });
              this.$apollo.queries.solution.refetch();
            }
          });
      });
    }
  },
  apollo: {
    solution: {
      query: SOLUTION,
      variables() {
        return {
          id: this.$route.params.id
        };
      },
      skip() {
        return !this.$route.params.id;
      },
      result({ data: { solution } }) {
        if (solution) {
          this.populateEditForm(solution);
          if (this.meUser && solution.user.auth_id == this.meUser.user._id) {
            this.isMySolution = true;
          }
          if (solution.is_published) {
            this.isRequired = true;
          }
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.solution-action-buttons {
  padding-bottom: 3rem;
  margin-top: 1.5rem;
}
</style>
