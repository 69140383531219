import ActiveJourneyProviders from "./views/ActiveJourneyProviders";
import ArchivedJourneyProviders from "./views/ArchivedJourneyProviders";
import JourneyProviderDetails from "./views/JourneyProviderDetails";
import videoCallWindow from "./components/videoCallWindow";
import SuccessPaidMinutes from "./views/SuccessPaidMinutes";
import { Role } from "../../_helpers/roles";

const activeJourneyProvidersRoute = {
  path: "/messages/:status(active)",
  name: "activeJourneyProviders",
  component: ActiveJourneyProviders,
  meta: { authorize: [Role.Admin, Role.Vendor] }
};

const archivedJourneyProvidersRoute = {
  path: "/messages/:status(archived)",
  name: "archivedJourneyProviders",
  component: ArchivedJourneyProviders,
  meta: { authorize: [Role.Admin, Role.Vendor] }
};

const JourneyProviderDetailsRoute = {
  path: "/messages/journey/:journeyProviderId",
  name: "journeyProviderDetails",
  component: JourneyProviderDetails,
  meta: { authorize: [Role.Vendor, Role.Admin] }
};

const videoCallRoute = {
  path: "/messages/journey/:journeyProviderId/videoCall",
  name: "videoCall",
  component: videoCallWindow,
  meta: { authorize: [Role.Vendor] }
};

const successPaidMinutesRoute = {
  path: "/payments_success",
  name: "successPaidMinutes",
  component: SuccessPaidMinutes,
  meta: { authorize: [Role.Vendor, Role.Admin] }
};

export default [
  activeJourneyProvidersRoute,
  archivedJourneyProvidersRoute,
  JourneyProviderDetailsRoute,
  videoCallRoute,
  successPaidMinutesRoute
];
