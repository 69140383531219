<template>
  <span>
    <ValidationObserver ref="validateOffer">
      <div
        ref="offer"
        id="offer-maker"
        class="offer-maker"
        v-show="offerMakerIsOpen && !appointmentMakerIsOpen"
      >
        <!--<button class="button is-primary is-fullwidth" @click="$emit('close')">
        <i class="fa fa-times-circle" style="margin:5px;"></i>
        Close Offer panel
      </button>-->
        <br />
        <div class="treatments-chooser">
          <div class="columns">
            <div class="column is-7">
              <div class="bordered">
                <h4 class="subtitle">
                  <i class="fa fa-search" />
                  Search
                </h4>
                <input
                  v-model="filterString"
                  placeholder="Find a treatment from here"
                  class="input"
                  type="text"
                />
              </div>
              <treatments-list
                type="regular"
                title="Your listed treatments"
                subtitle="Add treatments to your offer list to create an offer."
                :sorted-provider-treatments="sortedProviderTreatments"
                @select="selectTreatment"
              />

              <div class="bordered is-clearfix columns is-multiline">
                <h3 class="subtitle has-text-left column is-full">
                  Create a new custom treatment
                  <br />
                  <sub
                    >You can create custom treatments, and add them to your
                    offer.</sub
                  >
                </h3>
                <div class="column is-10">
                  <ValidationProvider
                    rules="max:110"
                    v-slot="{ errors, valid }"
                    name="Custom treatment"
                  >
                    <b-field :type="{ 'is-danger': errors[0] }">
                      <b-input
                        class="is-primary"
                        placeholder="Enter the treatment name here"
                        @keyup.enter="createCustomTreatment"
                        v-model="newTreatmentName"
                        maxlength="110"
                      />
                    </b-field>
                    <span
                      v-for="error in errors"
                      :key="error.id"
                      class="has-text-danger help"
                      >{{ error }}</span
                    >
                  </ValidationProvider>
                </div>
                <div class="column is-2">
                  <button
                    @click="createCustomTreatment"
                    class="button is-primary"
                  >
                    create &downarrow;
                  </button>
                </div>
              </div>
              <treatments-list
                type="custom"
                title="Your custom treatments"
                subtitle=""
                :sorted-provider-treatments="sortedCustomTreatments"
                @select="selectTreatment"
                @remove="removeTreatmentFromList"
              />
            </div>
            <div class="column is-5">
              <h3 class="subtitle">
                Your offer:
                <div class="select is-small">
                  <select v-model="selectedCurrency">
                    <option
                      :key="key"
                      :value="currency"
                      v-for="(currency, key) in currencies"
                    >
                      {{ currency.label }}
                    </option>
                  </select>
                </div>
                <br />
                <sub
                  >Hit send once you're happy with your offer to send it.</sub
                >
              </h3>
              <br />

              <span class="selectedTreatments">
                <div
                  class="bordered"
                  :key="treatment.id"
                  v-for="treatment in sortedSelectedTreatments"
                >
                  <div class="columns">
                    <span class="column is-8-desktop">
                      {{ treatment.name }}
                    </span>
                    <div class="column is-3-desktop control has-icons-right">
                      <ValidationProvider
                        rules="required|min_value:0"
                        v-slot="{ errors, valid }"
                        name="Price"
                      >
                        <input
                          v-model="treatment.price"
                          class="input"
                          :class="{ 'is-danger': errors[0] }"
                          type="number"
                          :min="0"
                        />
                        <span class="icon is-small is-right">
                          {{ selectedCurrency.symbol }}
                          <!--<i :class="['fa', selectedCurrency.icon]"></i>-->
                        </span>
                        <span
                          v-for="error in errors"
                          :key="error.id"
                          class="has-text-danger help"
                          >{{ error }}</span
                        >
                      </ValidationProvider>
                    </div>
                    <span class="column is-1-desktop">
                      <button
                        @click="removeFromOffer(treatment.id)"
                        class="button"
                      >
                        <i class="fa fa-trash"></i>
                      </button>
                    </span>
                  </div>
                </div>
              </span>
              <offer-summary
                :journey-provider-id="journeyProviderId"
                :selected-treatments="sortedSelectedTreatments"
                :currency="selectedCurrency"
                @close="onClose"
                @startedConversation="startedConversation"
              />
            </div>
          </div>
        </div>
      </div>
    </ValidationObserver>
  </span>
</template>
<script>
import _ from "lodash";
import OfferSummary from "./offerSummary";
import TreatmentsList from "./treatmentsList";
import ValidationErrors from "../../../mixins/ValidationErrors";
export default {
  components: { TreatmentsList, OfferSummary },
  data() {
    return {
      filterString: "",
      selectedCurrency: { icon: "fa-euro-sign", symbol: "€", label: "EUR" },
      currencies: [
        { icon: "fa-euro-sign", symbol: "€", label: "EUR" },
        { icon: "fa-dollar-sign", symbol: "$", label: "USD" },
        { icon: "fa-pound-sign", symbol: "£", label: "GBP" }
      ],
      selectedTreatments: [],
      customTreatments: [],
      newTreatmentName: ""
    };
  },
  mixins: [ValidationErrors],
  computed: {
    sortedCustomTreatments() {
      return _.sortBy(this.filterTreatments(this.customTreatments), "name");
    },
    sortedProviderTreatments() {
      return !_.isEmpty(this.provider) && this.provider.treatments.length > 0
        ? _.sortBy(this.filterTreatments(this.provider.treatments), "name")
        : [];
    },
    sortedSelectedTreatments() {
      return _.sortBy(this.selectedTreatments, "name");
    }
  },
  props: [
    "offerMakerIsOpen",
    "appointmentMakerIsOpen",
    "provider",
    "journeyProviderId"
  ],
  name: "offer-maker",
  methods: {
    startedConversation(data) {
      this.$emit("startedConversation", data);
    },
    filterTreatments(treatments) {
      if (this.filterString.trim().toLowerCase() === "") {
        return treatments;
      }

      const filterString = this.filterString.trim().toLowerCase();

      return treatments.filter(
        treatment =>
          treatment.name
            .trim()
            .toLowerCase()
            .indexOf(filterString) !== -1
      );
    },
    gatherOfferData() {
      return {
        type: "OFFER",
        state: "PENDING",
        treatments: [
          {
            name: "Fillers",
            price: "500 €"
          },
          {
            name: "Submandibular gland resection",
            price: "700 €"
          },
          {
            name: "Radiofrequency external skin tightening",
            price: "1000 €"
          }
        ],
        discount: this.discount + " €",
        total: this.total + " €"
      };
    },
    createCustomTreatment() {
      this.$refs.validateOffer.validate().then(success => {
        if (!success) {
          this.refName = "validateOffer";
          this.handleErrors();
          return;
        }

        this.customTreatments.push({
          name: this.newTreatmentName,
          id: Math.random(),
          type: "custom"
        });

        this.newTreatmentName = "";
      });
    },
    removeTreatmentFromList(treatment, origin = false) {
      const _origin = origin ? origin : treatment.origin;
      switch (_origin) {
        case "regular": {
          this.provider.treatments = this.provider.treatments.filter(
            _treatment => _treatment.id !== treatment.id
          );
          break;
        }
        case "custom": {
          this.customTreatments = this.customTreatments.filter(
            _treatment => _treatment.id !== treatment.id
          );
          break;
        }
      }
    },
    selectTreatment(treatment, origin) {
      treatment.origin = origin;
      this.selectedTreatments.push(treatment);

      this.removeTreatmentFromList(treatment);
    },
    removeFromOffer(treatmentId) {
      const selectedTreatment = this.selectedTreatments.find(
        treatment => treatment.id === treatmentId
      );

      switch (selectedTreatment.origin) {
        case "regular": {
          this.provider.treatments.push(selectedTreatment);
          break;
        }
        case "custom": {
          this.customTreatments.push(selectedTreatment);
          break;
        }
      }

      this.selectedTreatments = this.selectedTreatments.filter(
        treatment => treatment.id !== treatmentId
      );
    },
    onClose() {
      this.$emit("close");
      this.selectedTreatments.forEach(selectedTreatment => {
        selectedTreatment.price = null;
        this.removeFromOffer(selectedTreatment.id);
      });
    }
  }
};
</script>
<style lang="scss">
.offer-maker {
  .bordered {
    text-align: left;
    border-radius: 4px;
    margin: 10px 0 0 20px;
    padding: 10px;
  }
  .icon {
    top: 0.6em !important;
    margin-right: 0.5em;

    &.has-text-danger {
      top: 0 !important;
    }
  }

  .your-offer {
    border-radius: 16px;
    background: none #f2f2f2;
  }

  .selectedTreatments {
    word-break: break-word;
  }
}
</style>
