<template>
  <div>
    <b-table
      class="table is-fullwidth payment-table"
      paginated
      :data="tableData.data"
      :current-page="meta.current_page"
      :per-page="meta.per_page"
      backend-pagination
      :total="meta.total_count"
      default-sort="name"
      :hoverable="true"
      :opened-detailed="defaultOpenedDetails"
      detailed
      detail-key="id"
      :show-detail-icon="showDetailIcon"
      @page-change="onPageChange"
    >
      <template slot-scope="data">
        <b-table-column
          centered
          class="column_width"
          label="Date"
          field="date_added"
        >
          {{ displayReadableDate(data.row.date_added) }}
        </b-table-column>
        <b-table-column
          centered
          label="Payment Status"
          field="status"
          :class="determineStatusClass(data.row.status)"
        >
          <template v-slot:header="{ column }">
            <b-tooltip :label="getAdditionalInfo(column.field)" append-to-body>
              <span>
                {{ column.label }}
                <b-icon icon="information" size="is-small"> </b-icon>
              </span>
            </b-tooltip>
          </template>
          <template>
            {{ getReadableStatusName(data.row.status) }}
          </template>
        </b-table-column>
        <b-table-column
          centered
          label="Provider"
          field="provider"
          v-if="isAdmin"
          class="provider_width column_text_wrap"
        >
          {{ data.row.provider_name }}
        </b-table-column>
        <b-table-column
          centered
          label="Customer"
          field="customer"
          v-if="isVendor"
        >
          {{
            `${data.row.buyer_first_name || ""} ${data.row.buyer_last_name ||
              ""}`
          }}
        </b-table-column>
        <b-table-column
          centered
          label="Subject"
          field="subject"
          class="subject_width column_text_wrap"
        >
          <template v-slot:header="{ column }">
            <b-tooltip :label="getAdditionalInfo(column.field)" append-to-body>
              <span>
                {{ column.label }}
                <b-icon icon="information" size="is-small"> </b-icon>
              </span>
            </b-tooltip>
          </template>
          <template>
            {{ data.row.description }}
          </template>
        </b-table-column>
        <b-table-column numeric label="Amount" field="amount_paid">
          {{ getAmount(data.row.amount, data.row.currency) }}
        </b-table-column>
        <b-table-column
          centered
          label="Payout Status"
          field="payout_status"
          :class="determineStatusClass(data.row.payout_status)"
        >
          <template v-slot:header="{ column }">
            <b-tooltip
              multilined
              :label="getAdditionalInfo(column.field)"
              append-to-body
            >
              <span>
                {{ column.label }}
                <b-icon icon="information" size="is-small"> </b-icon>
              </span>
            </b-tooltip>
          </template>
          <template>
            {{ getReadableStatusName(data.row.payout_status) }}
          </template>
        </b-table-column>
      </template>
      <template slot="detail" slot-scope="data">
        <section class="section is-paddingless">
          <div class="container">
            <div class="columns">
              <div class="column is-0"></div>
              <div class="column">
                <b-field label="Date">
                  {{ displayReadableDate(data.row.date_added) }}
                </b-field>
                <b-field label="Provider" v-if="isAdmin">
                  {{ data.row.provider_name }}
                </b-field>
                <b-field label="Customer">
                  {{
                    `${data.row.buyer_first_name || ""} ${data.row
                      .buyer_last_name || ""}`
                  }}
                  <br />
                  {{ data.row.buyer_email }}
                </b-field>
                <b-field label="Subject" class="subject_width column_text_wrap">
                  {{ data.row.description }}
                </b-field>
              </div>
              <div class="column">
                <b-field label="Amount paid" field="amount_paid">
                  {{ getAmount(data.row.amount, data.row.currency) }}
                </b-field>
                <b-field label="Processing and handling" field="fees">
                  {{
                    getProcessingFee(
                      data.row.fee_amount,
                      data.row.fee_percentage,
                      data.row.currency
                    )
                  }}
                </b-field>
                <b-field
                  label="Payment Status"
                  field="payment_status"
                  :class="determineStatusClass(data.row.status)"
                >
                  {{ getReadableStatusName(data.row.status) }}
                </b-field>
                <b-field
                  label="Reference"
                  field="reference"
                  class="column_width"
                >
                  {{ data.row.payin_id }}
                </b-field>
              </div>
              <div class="column">
                <b-field label="Net payment" field="net_payment">
                  {{
                    getNetPayment(
                      data.row.amount,
                      data.row.fee_amount,
                      data.row.currency
                    )
                  }}
                </b-field>
                <b-field
                  label="Payout status"
                  field="status"
                  :class="determineStatusClass(data.row.payout_status)"
                >
                  {{ getReadableStatusName(data.row.payout_status) }}
                </b-field>
              </div>
              <div class="column" v-if="isAdmin">
                <b-field label="Actions">
                  <div class="buttons" id="actions">
                    <b-button
                      class="is-fullwidth"
                      size="is-default"
                      v-if="
                        ![null, CANCELLED, PAID].includes(
                          data.row.payout_status
                        )
                      "
                      @click="processPayment(data.row.id, PAID)"
                    >
                      Payout
                    </b-button>
                    <b-button
                      class="is-fullwidth"
                      size="is-default"
                      v-if="[ON_HOLD].includes(data.row.payout_status)"
                      @click="processPayment(data.row.id, DELAYED)"
                    >
                      Delay
                    </b-button>
                    <b-button
                      class="is-fullwidth"
                      size="is-default"
                      v-if="
                        ![null, CANCELLED, PAID].includes(
                          data.row.payout_status
                        )
                      "
                      @click="openCancelDialog(data.row.id, CANCELLED)"
                    >
                      Cancel
                    </b-button>
                  </div>
                </b-field>
              </div>
              <div class="column is-0"></div>
            </div>
          </div>
        </section>
      </template>
      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>
              <b-icon icon="emoticon-sad" size="is-large"> </b-icon>
            </p>
            <p>Nothing here.</p>
          </div>
        </section>
      </template>
    </b-table>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import { UPDATE_PAYMENT } from "@/_graphql/Mutations/mutations";
import { SYSTEM_SETTING } from "../../../_graphql/Queries/queries";

export default {
  data() {
    return {
      defaultOpenedDetails: [],
      systemSetting: {},
      showDetailIcon: true
    };
  },
  apollo: {
    systemSetting: {
      query: SYSTEM_SETTING,
      update: data => data.systemSetting,
      variables() {
        return {
          id: 8
        };
      }
    }
  },
  created() {
    //default holding period
    this.HOLDING_PERIOD = 10;

    //payment statuses
    this.PENDING = "PENDING";
    this.SUCCEEDED = "SUCCEEDED";
    this.FAILED = "FAILED";

    //payout statuses
    this.ON_HOLD = "ON HOLD";
    this.PAYABLE = "PAYABLE";
    this.PAID = "PAID";
    this.DELAYED = "DELAYED";
    this.CANCELLED = "CANCELLED";

    //tooltip info
    this.PAYMENT_STATUS_INFO =
      "This indicates whether your customer’s card payment has been successfully processed or not.";
    this.SUBJECT_INFO =
      "This indicates what the payment was for, and entered by you when requesting payment.";
    this.PAYOUT_STATUS_INFO = holdingPeriod =>
      `Once a payment has been successfully processed, they become Pending. After ${holdingPeriod} days they become Payable and will be paid to your account. When the payment has been transferred to your account, the Paid status appears.`;
  },
  props: {
    tableData: {
      default: Array
    },
    meta: {
      default: {}
    }
  },
  computed: {
    ...mapGetters({
      userRole: "userRole"
    }),
    isVendor() {
      return this.userRole && this.userRole == "VENDOR";
    },
    isAdmin() {
      return this.userRole && this.userRole == "ADMINISTRATOR";
    }
  },
  methods: {
    getStatusChangeMessage(newStatus) {
      switch (newStatus) {
        case this.DELAYED:
          return "Payment delayed.";
        case this.CANCELLED:
          return "Payout cancelled";
        default:
          return "Payment processed successfully";
      }
    },
    getAdditionalInfo(field) {
      const holdingPeriod = this.systemSetting
        ? this.systemSetting.value
        : this.HOLDING_PERIOD;

      switch (field) {
        case "status":
          return this.PAYMENT_STATUS_INFO;
        case "subject":
          return this.SUBJECT_INFO;
        case "payout_status":
          return this.PAYOUT_STATUS_INFO(holdingPeriod);
        default:
          return "";
      }
    },
    onPageChange(page) {
      const offset = this.meta.per_page * (page - 1);
      this.$store.state.currentAllProvidersPage = page;
      this.$emit("pageChanged", offset);
    },
    determineStatusClass(status) {
      switch (status) {
        case this.PENDING:
        case this.ON_HOLD:
          return "has-text-dark";
        case this.FAILED:
        case this.CANCELLED:
          return "has-text-danger";
        case this.DELAYED:
          return "delayed_status";
        case this.SUCCEEDED:
        case this.PAYABLE:
        case this.PAID:
          return "has-text-success";
        default:
          return "has-text-dark";
      }
    },
    getReadableStatusName(status) {
      if (status === null) {
        return "-";
      }

      if (status === this.PENDING) {
        return "Incomplete";
      }

      if (status === this.ON_HOLD) {
        return "Pending";
      }

      return status.charAt(0) + status.slice(1).toLowerCase();
    },
    getAmount(amount, currency) {
      return this.getCurrencySymbol(currency) + amount.toFixed(2);
    },
    getProcessingFee(amount, feePercentage, currency) {
      return (
        this.getCurrencySymbol(currency) +
        amount.toFixed(2) +
        ` (${feePercentage}%)`
      );
    },
    getNetPayment(amount, feeAmount, currency) {
      return this.getCurrencySymbol(currency) + (amount - feeAmount).toFixed(2);
    },
    getCurrencySymbol(currency) {
      let symbol = "";
      switch (currency.toUpperCase()) {
        case "EUR":
          symbol = "€";
          break;
        case "GBP":
          symbol = "£";
          break;
        case "USD":
          symbol = "$";
          break;
        default:
          symbol = "€";
          break;
      }

      return symbol;
    },
    displayReadableDate(dateAdded, format = "YYYY-MM-DD HH:mm:ss") {
      return moment(dateAdded).format(format);
    },
    openCancelDialog(paymentId, status) {
      this.$buefy.dialog.confirm({
        title: "Confirm Cancellation ",
        message: `Are you sure you want to <b>cancel</b> this payout?`,
        confirmText: "Confirm",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.processPayment(paymentId, status)
      });
    },
    processPayment(paymentId, status) {
      this.$apollo
        .mutate({
          mutation: UPDATE_PAYMENT,
          variables: {
            id: parseInt(paymentId),
            input: {
              payout_status: status
            }
          }
        })
        .catch(error => {
          return error;
        })
        .then(data => {
          const updatePayment = data.data ? data.data.updatePayment : false;
          if (updatePayment) {
            this.$buefy.toast.open({
              message: this.getStatusChangeMessage(updatePayment.payout_status),
              type: "is-success",
              duration: 1500
            });
          } else {
            this.$buefy.toast.open({
              message: "Something went wrong.",
              type: "is-danger",
              duration: 2000
            });
            setTimeout(() => {
              this.resetSubmitSwitch = !this.resetSubmitSwitch;
            }, 4500);
          }
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.payment-table {
  .column_width {
    white-space: nowrap;
  }

  .provider_width {
    max-width: 150px;
  }

  .subject_width {
    max-width: 250px;
  }

  .column_text_wrap {
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
  }

  #actions {
    display: inline-grid;
  }

  .delayed_status {
    color: #ff9800;
  }
}
</style>
