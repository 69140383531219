<template>
  <section>
    <b-modal
      :active.sync="isComponentModalActive"
      has-modal-card
      trap-focus
      aria-role="dialog"
      aria-modal
      :on-cancel="closeModal"
    >
      <div class="modal-card">
        <section class="modal-card-body has-text-left">
          <b-field>
            <div class="title is-5 has-text-weight-normal">
              Would you like to save your changes before
              <span class="has-text-weight-bold">{{
                is_published ? " unpublishing" : "publishing"
              }}</span>
              ?
            </div>
          </b-field>
        </section>
        <footer class="modal-card-foot">
          <!--          <b-button class="is-primary is-outlined" @click="closeModal">-->
          <!--            Cancel-->
          <!--          </b-button>-->
          <b-button class="is-primary is-outlined" @click="closeModal"
            >Cancel</b-button
          >
          <div>
            <b-button
              class="is-primary is-outlined"
              @click="
                () => {
                  $emit('discardChanges');
                  closeModal();
                }
              "
              >Discard changes</b-button
            >
            <b-button
              class="is-primary"
              @click="
                () => {
                  $emit('saveChanges');
                  closeModal();
                }
              "
              >Save changes</b-button
            >
          </div>
        </footer>
      </div>
    </b-modal>
  </section>
</template>

<script>
export default {
  props: ["is_published"],
  methods: {
    closeModal() {
      this.isComponentModalActive = false;
    },
    openModal() {
      this.isComponentModalActive = true;
    }
  },
  data() {
    return {
      isComponentModalActive: false
    };
  }
};
</script>

<style scoped>
.modal-card-foot {
  justify-content: space-between;
}
</style>
