import { render, staticRenderFns } from "./InvitationEmailTemplate.vue?vue&type=template&id=07ca059f&scoped=true&"
import script from "./InvitationEmailTemplate.vue?vue&type=script&lang=js&"
export * from "./InvitationEmailTemplate.vue?vue&type=script&lang=js&"
import style0 from "./InvitationEmailTemplate.vue?vue&type=style&index=0&id=07ca059f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07ca059f",
  null
  
)

export default component.exports