<template>
  <div
    v-if="provider && currentPlan !== PRODUCT_TYPE_PRO"
    class="plan-shortcut-section has-text-centered"
  >
    <div class="plan-shortcut-top-btn">
      <div class="plan-shortcut-arrow-box">
        <b-icon
          custom-class="plan-shortcut-arrow"
          type="is-white"
          icon="arrow-right"
          size="is-medium"
        >
        </b-icon>
      </div>
    </div>

    <div class="plan-shortcut">
      <div class="plan-shortcut-header">
        <span class="plan-shortcut-title">{{ currentPlan }}</span>
        <div class="has-text-primary plan-shortcut-subtitle">current plan</div>
      </div>

      <div class="plan-shortcut-description">
        Set {{ getPromotionPlanType() }} account to explore more features
      </div>
    </div>
    <a class="button plan-shortcut-upgrade-btn is-primary" href="/subscriptions"
      >Upgrade</a
    >
  </div>
</template>

<script>
import PlanSubscriptionsHelper from "@/mixins/PlanSubscriptionsHelper";
import { PROVIDER_SUBSCRIPTIONS } from "@/_graphql/Queries/queries";

export default {
  components: {},
  mixins: [PlanSubscriptionsHelper],
  data() {
    return {
      isMonthly: false,
      customerPortalLink: null,
      providerSubscriptions: null
    };
  },
  props: {
    currentPlan: {
      type: String,
      required: true
    },
    provider: {
      type: Object
    }
  },
  created() {
    this.PRODUCT_TYPE_BASIC = "Basic";
    this.PRODUCT_TYPE_PREMIUM = "Premium";
    this.PRODUCT_TYPE_PRO = "Pro";
  },
  methods: {
    getPromotionPlanType() {
      return this.currentPlan === this.PRODUCT_TYPE_BASIC
        ? this.PRODUCT_TYPE_PREMIUM
        : this.PRODUCT_TYPE_PRO;
    },
    upgradePlan() {
      if (this.currentPlan === this.PRODUCT_TYPE_BASIC) {
        const premiumPlanId = this.getPlanId(this.PRODUCT_TYPE_PREMIUM, true);
        if (
          this.providerSubscription &&
          (this.providerSubscription.session_id ||
            !this.providerSubscription.is_subscribed)
        ) {
          this.updateSubscriptionCheckout(
            premiumPlanId,
            this.providerSubscription
          );
        } else {
          this.create(premiumPlanId);
        }
      } else {
        const proPlanId = this.getPlanId(this.PRODUCT_TYPE_PRO, true);
        this.getCustomerPortalUrl(
          this.providerSubscription.provider_id,
          proPlanId
        ).then(({ data: { getCustomerPortalUrl } }) => {
          if (getCustomerPortalUrl) {
            this.customerPortalLink =
              getCustomerPortalUrl.data.customer_portal_url;

            const link = document.createElement("a");
            link.setAttribute("href", this.customerPortalLink);
            link.click();
          }
        });
      }
    }
  },
  apollo: {
    providerSubscriptions: {
      query: PROVIDER_SUBSCRIPTIONS,
      result({
        data: {
          providerSubscriptions: { data }
        }
      }) {
        this.providerSubscription = data.length > 0 ? data[0] : null;
      }
    }
  }
};
</script>

<style>
.plan-shortcut-section {
  background-color: #e6eff2;
  border-radius: 3px;
  position: absolute;
  width: 160px;
  height: 175px;
  margin: 20px;
  display: inline-block;
  bottom: 0;
}

.plan-shortcut-top-btn {
  position: absolute;
  left: 0;
  right: 0;
  top: -14%;
}

.plan-shortcut {
  margin-top: 30px;
}

.plan-shortcut-header {
  font-weight: 700;
  font-size: 15px;
  top: 25px;
  position: inherit;
}

.plan-shortcut-title {
  text-transform: uppercase;
}

.plan-shortcut-subtitle {
  font-size: 12px;
}

.plan-shortcut-description {
  font-size: 14px;
}

.plan-shortcut-upgrade-btn {
  margin-top: 10px;
}

.plan-shortcut-arrow-box {
  width: 45px;
  margin: 0 auto;
  height: 45px;
  background-color: #0ba1d1;
  border-radius: 3px;
}

.plan-shortcut-arrow {
  height: 40px;
}
</style>
