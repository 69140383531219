var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"userForm"},[_c('div',{staticClass:"columns is-variable is-12"},[_c('div',{staticClass:"column is-12"},[_c('div',{staticClass:"columns is-variable is-12 is-multiline is-mobile"},[_c('ValidationProvider',{staticClass:"column is-12-mobile is-6-tablet",attrs:{"name":"Related category"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"id":"fieldset-specialty","label":'Related ' + (_vm.category ? _vm.category.name : 'Solutions'),"message":"","type":{ 'is-primary': valid, 'is-danger': errors[0] }}},[_c('div',{staticClass:"menu-list-container",class:errors[0] ? 'is-danger' : 'is-primary'},[(_vm.solutions)?_c('ul',{staticClass:"menu-list scrollable"},[(_vm.solutions.data.length == 0)?_c('li',{staticStyle:{"display":"flex","justify-content":"center","align-items":"center"}},[_c('b-icon',{staticStyle:{"margin-right":"0.25rem"},attrs:{"icon":"close-circle-outline"}}),_vm._v(" There are no solutions related to this category ")],1):_vm._e(),_vm._l((_vm.solutions.data),function(solution){return _c('span',{key:solution.id},[(_vm.currentSolutionSlug !== solution.slug)?_c('li',[_c('b-checkbox',{attrs:{"disabled":_vm.disableFields,"native-value":parseInt(solution.id)},on:{"input":function($event){return _vm.checkForChanges('related_solutions')}},model:{value:(_vm.form.related_solutions),callback:function ($$v) {_vm.$set(_vm.form, "related_solutions", $$v)},expression:"form.related_solutions"}},[_vm._v(" "+_vm._s(solution.title)+" ")])],1):_vm._e()])})],2):_c('div',{staticClass:"menu-list has-text-centered"},[_vm._v(" Select category from \"Solution Info\" section to see all related solutions to that category ")])])]),_vm._l((errors),function(error){return _c('span',{key:error.id,staticClass:"has-text-danger help"},[_vm._v(_vm._s(error))])})]}}])}),_c('ValidationProvider',{staticClass:"column is-12-mobile is-6-tablet",attrs:{"name":"Keywords"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"id":"keywords-tags-field","type":{
              'is-primary': errors.length == 0,
              'is-danger': errors[0] || _vm.invalidKeywords
            },"label":"Keywords (used for searching)"}},[_c('div',{staticClass:"box keywords-box",class:{ 'invalid-keywords': _vm.invalidKeywords }},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body "},[_c('vue-tags-input',{staticClass:"vue-tags-input scrollable ",attrs:{"disabled":_vm.disableFields,"placeholder":"Add keyword","avoid-adding-duplicates":true,"allow-edit-tags":false,"add-from-paste":true,"autocomplete-items":_vm.filteredKeywords,"delete-on-backspace":true,"tags":_vm.keywords,"separators":_vm.separators,"add-on-key":[13, ' '],"disable":_vm.disableFields,"validation":_vm.keywordsValidation},on:{"tags-changed":function($event){return _vm.checkForChanges('keywords')},"before-adding-tag":_vm.addKeyword,"before-deleting-tag":_vm.deleteKeyword},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}})],1)])])]),(_vm.invalidKeywords)?_c('span',{staticClass:"has-text-danger help"},[_vm._v("The Keywords field is required!")]):_vm._e(),(!_vm.keywordMatchRegex)?_c('span',{staticClass:"has-text-danger help"},[_vm._v("Keywords can only contain numbers and/or Latin letters.")]):_vm._e()]}}])})],1),_c('div',{staticClass:"columns is-variable is-12 is-multiline is-mobile"},[(_vm.treatments)?_c('ValidationProvider',{staticClass:"column is-12-mobile is-6-tablet is-3-desktop",attrs:{"name":"Treatments"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
            var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Treatments","message":"","type":{
              'is-primary': errors.length == 0,
              'is-danger': errors[0] || _vm.invalidCriteria
            }}},[_c('div',{staticClass:"menu-list-container",class:errors[0] ? 'is-danger' : 'is-primary'},[_c('ul',{staticClass:"menu-list scrollable"},_vm._l((_vm.treatments.data),function(treatment){return _c('li',{key:treatment.id},[_c('b-checkbox',{attrs:{"disabled":_vm.disableFields,"native-value":parseInt(treatment.id)},on:{"input":function($event){return _vm.checkForChanges('treatments')}},model:{value:(_vm.form.treatments),callback:function ($$v) {_vm.$set(_vm.form, "treatments", $$v)},expression:"form.treatments"}},[_vm._v(" "+_vm._s(treatment.name)+" ")])],1)}),0)])]),_vm._l((errors),function(error){return _c('span',{key:error.id,staticClass:"has-text-danger help"},[_vm._v(_vm._s(error))])})]}}],null,false,2181672576)}):_vm._e(),(_vm.products)?_c('ValidationProvider',{staticClass:"column is-12-mobile is-6-tablet is-3-desktop",attrs:{"name":"Products"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
            var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Products","message":"","type":{
              'is-primary': errors.length == 0,
              'is-danger': errors[0] || _vm.invalidCriteria
            }}},[_c('div',{staticClass:"menu-list-container",class:errors[0] ? 'is-danger' : 'is-primary'},[_c('ul',{staticClass:"menu-list scrollable"},_vm._l((_vm.products.data),function(product){return _c('li',{key:product.id},[_c('b-checkbox',{attrs:{"disabled":_vm.disableFields,"native-value":parseInt(product.id)},on:{"input":function($event){return _vm.checkForChanges('products')}},model:{value:(_vm.form.products),callback:function ($$v) {_vm.$set(_vm.form, "products", $$v)},expression:"form.products"}},[_vm._v(" "+_vm._s(product.name)+" ")])],1)}),0)])]),_vm._l((errors),function(error){return _c('span',{key:error.id,staticClass:"has-text-danger help"},[_vm._v(_vm._s(error))])})]}}],null,false,124798400)}):_vm._e(),(_vm.technologies)?_c('ValidationProvider',{staticClass:"column is-12-mobile is-6-tablet is-3-desktop",attrs:{"name":"Technologies"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
            var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Technologies","message":"","type":{
              'is-primary': errors.length == 0,
              'is-danger': errors[0] || _vm.invalidCriteria
            }}},[_c('div',{staticClass:"menu-list-container",class:errors[0] ? 'is-danger' : 'is-primary'},[_c('ul',{staticClass:"menu-list scrollable"},_vm._l((_vm.technologies.data),function(technology){return _c('li',{key:technology.id},[_c('b-checkbox',{attrs:{"disabled":_vm.disableFields,"native-value":parseInt(technology.id)},on:{"input":function($event){return _vm.checkForChanges('technologies')}},model:{value:(_vm.form.technologies),callback:function ($$v) {_vm.$set(_vm.form, "technologies", $$v)},expression:"form.technologies"}},[_vm._v(" "+_vm._s(technology.name)+" ")])],1)}),0)])]),_vm._l((errors),function(error){return _c('span',{key:error.id,staticClass:"has-text-danger help"},[_vm._v(_vm._s(error))])})]}}],null,false,612736000)}):_vm._e(),(_vm.specialties)?_c('ValidationProvider',{staticClass:"column is-12-mobile is-6-tablet is-3-desktop",attrs:{"name":"Provider Types"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
            var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Provider Types","message":"","type":{
              'is-primary': errors.length == 0,
              'is-danger': errors[0] || _vm.invalidCriteria
            }}},[_c('div',{staticClass:"menu-list-container",class:errors[0] ? 'is-danger' : 'is-primary'},[_c('ul',{staticClass:"menu-list scrollable"},_vm._l((_vm.specialties.data),function(specialty){return _c('li',{key:specialty.id},[_c('b-checkbox',{attrs:{"disabled":_vm.disableFields,"native-value":parseInt(specialty.id)},on:{"input":function($event){return _vm.checkForChanges('specialties')}},model:{value:(_vm.form.specialties),callback:function ($$v) {_vm.$set(_vm.form, "specialties", $$v)},expression:"form.specialties"}},[_vm._v(" "+_vm._s(specialty.name)+" ")])],1)}),0)])]),_vm._l((errors),function(error){return _c('span',{key:error.id,staticClass:"has-text-danger help"},[_vm._v(_vm._s(error))])})]}}],null,false,1899788000)}):_vm._e()],1),(_vm.invalidCriteria)?_c('span',{staticClass:"has-text-danger help"},[_vm._v("You must populate at least one treatment, product, technology or provider type")]):_vm._e(),_c('div',{staticClass:"columns is-variable is-12 is-multiline is-mobile"},[_c('div',{staticClass:"buttons column is-12"},[_c('b-button',{staticClass:"is-primary is-fullwidth",attrs:{"id":"providersSearchBtn"},on:{"click":_vm.solutionProvidersResults}},[_vm._v("See which providers match your criteria")]),(_vm.providersResultsMessage)?_c('span',{staticClass:"has-text-danger help"},[_vm._v("Currently there are no providers that match these criteria.")]):_vm._e()],1)])])]),_c('related-providers',{ref:"solutionProvidersResults",on:{"pageChanged":_vm.onPageChange}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }