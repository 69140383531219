<template>
  <section>
    <div class="level">
      <div class="level-left"></div>
      <div class="level-right">
        <div class="level-item">
          <b-button class="is-primary" @click="addTreatment">
            Add/Remove Treatments
          </b-button>
        </div>
      </div>
    </div>
    <data-table
      v-if="treatments.areaTypeCategoryTreatments"
      :data="treatments.areaTypeCategoryTreatments.data"
      class="has-table"
    ></data-table>
    <add-treatments
      ref="addTreatmentModal"
      :selectedTreatmentsData="treatments.areaTypeCategoryTreatments.data"
      @onUpdate="onUpdate"
    />
  </section>
</template>

<script>
import dataTable from "../components/DataTableAreaTypeTreatments";
import addTreatments from "../components/addTreatments";
import SubscribeForRefetch from "../../../mixins/SubscribeForRefetch";
import {
  AREA_TYPE_CATEGORY_TREATMENTS,
  AREA_TYPE_TREATMENTS
} from "../../../_graphql/Queries/queries";
export default {
  name: "viewAllTreatments",
  props: ["data"],
  components: { dataTable, addTreatments },
  computed: {
    getId() {
      return this.getAreaTypeCatId
        ? `area_type_category_id:${this.getAreaTypeCatId}`
        : `area_type_id:${this.getAreaTypeId}`;
    }
  },
  methods: {
    addTreatment() {
      this.$refs.addTreatmentModal.openModal();
    },
    onUpdate() {
      this.$apollo.queries.treatments.refetch();
    }
  },
  data() {
    return {
      variables: {},
      treatments: {
        areaTypeCategoryTreatments: {
          data: []
        },
        area: {},
        areaType: {},
        areaTypeCategory: {}
      }
    };
  },
  apollo: {
    treatments: {
      query() {
        return this.getAreaTypeCatId
          ? AREA_TYPE_CATEGORY_TREATMENTS
          : AREA_TYPE_TREATMENTS;
      },
      variables() {
        return {
          input: {
            query: this.getId,
            order: "id:asc"
          },
          areaId: this.getAreaId,
          areaTypeId: this.getAreaTypeId,
          areaTypeCatId: this.getAreaTypeCatId
        };
      },
      update: data => data
    }
  },
  mixins: [SubscribeForRefetch],
  watch: {
    treatments(newVal) {
      let breadcrumbs = [
        {
          name: "Areas",
          link: "/areas"
        },
        {
          name: newVal.area ? newVal.area.name : "",
          link: "/areas/" + this.getAreaId
        },
        {
          name: newVal.areaType ? newVal.areaType.name : "",
          link: "/areas/" + this.getAreaId + "/" + newVal.areaType.id
        },
        {
          name: newVal.areaTypeCategory ? newVal.areaTypeCategory.name : ""
        }
      ];
      this.$store.commit("setBreadcrumbs", breadcrumbs);
    },
    deep: true
  }
};
</script>

<style scoped></style>
