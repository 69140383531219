import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import provider from "../modules/providers/store";
import messages from "../modules/messages/store";
import jwt_decode from "jwt-decode";

// VuexPersistence
const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: ["messages"]
  //reducer: (state) => ({ messages: state.messages })
});

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    meUser: {},
    update: 0,
    currentAllProvidersPage: null,
    currentGuestProvidersPage: null,
    currentSolutionsPage: null,
    currentProvidersApprovalPage: null,
    currentSubcategoriesPage: null,
    currentAreasPage: null,
    currentCategoriesPage: null,
    refetch: {
      componentName: "",
      apolloQuery: ""
    },
    /* NavBar */
    isNavBarVisible: true,

    /* FooterBar */
    // isFooterBarVisible: true,

    /* Aside */
    isAsideVisible: true,
    isAsideMobileExpanded: false,
    /* Treatments */
    treatments: {
      name: "",
      treatmentCategoryId: "",
      description: ""
    },
    /* Treatments Categories */
    treatmentCategories: {
      name: "",
      categoryIds: []
    },
    loading: true,
    breadcrumbs: [],
    myProviders: []
  },
  getters: {
    refetchComponent: state => {
      return state.update;
    },
    isLoading: state => {
      return state.loading;
    },
    currentAllProvidersPage: state => {
      return state.currentAllProvidersPage;
    },
    currentGuestProvidersPage: state => {
      return state.currentGuestProvidersPage;
    },
    currentSolutionsPage: state => {
      return state.currentSolutionsPage;
    },
    currentProvidersApprovalPage: state => {
      return state.currentProvidersApprovalPage;
    },
    currentAreasPage: state => {
      return state.currentAreasPage;
    },
    currentCategoriesPage: state => {
      return state.currentCategoriesPage;
    },
    currentSubcategoriesPage: state => {
      return state.currentSubcategoriesPage;
    },
    breadcrumbs: state => {
      return state.breadcrumbs;
    },
    meUser: state => {
      const token = localStorage.getItem("AUTH_TOKEN");
      if (token) {
        state.meUser = jwt_decode(token);
      }
      return state.meUser;
    },
    userRole: state => {
      if (state.meUser.user) {
        return state.meUser.user.role;
      }
      return "";
    },
    myProviders: state => {
      return state.myProviders
    }
  },
  mutations: {
    setUser(state, payload) {
      state.meUser = payload;
    },
    refetchComponent(state, payload) {
      state.refetch.componentName = payload.componentName;
      state.refetch.apolloQuery = payload.apolloQuery;
      state.update++;
    },
    /* A fit-them-all commit */
    basic(state, payload) {
      state[payload.key] = payload.value;
    },

    /* User */
    user(state, payload) {
      if (payload.name) {
        state.userName = payload.name;
      }
      if (payload.email) {
        state.userEmail = payload.email;
      }
      if (payload.avatar) {
        state.userAvatar = payload.avatar;
      }
    },

    /* Aside Mobile */
    asideMobileStateToggle(state, payload = null) {
      const htmlClassName = "has-aside-mobile-expanded";

      let isShow;

      if (payload !== null) {
        isShow = payload;
      } else {
        isShow = !state.isAsideMobileExpanded;
      }

      if (isShow) {
        document.documentElement.classList.add(htmlClassName);
      } else {
        document.documentElement.classList.remove(htmlClassName);
      }

      state.isAsideMobileExpanded = isShow;
    },
    setBreadcrumbs(state, breadcrumbs) {
      state.breadcrumbs = breadcrumbs;
    },
    popBreadcrumbs(state) {
      state.breadcrumbs.pop();
    },
    replaceBreadcrumbs(state, payload) {
      const index = state.breadcrumbs.findIndex(breadcrumb => {
        return breadcrumb.text === payload.find;
      });

      if (index) {
        state.breadcrumbs.splice(index, 1, payload.replace);
      }
    },
    emptyBreadcrumbs(state) {
      state.breadcrumbs = [];
    },
    setLoading(state, payload = null) {
      state.loading = payload;
    },
    setMyProviders(state, payload) {
      state.myProviders = payload
    }
  },
  actions: {
    async logoutUser({ commit }) {
      commit("setUser", {});
    }
  },
  modules: { provider, messages },
  plugins: [vuexLocal.plugin]
});
