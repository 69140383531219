import gql from "graphql-tag";

const SEND_MESSAGE = gql`
  mutation createMessage($data: MessageInput!) {
    createMessage(data: $data) {
      code
      success
      errors
      data {
        fromId
        payload
        readBy
        metadata
        status
        createdDate
        updatedDate
      }
    }
  }
`;

const MESSAGE_SUBSCRIPTION = gql`
  subscription ConversationMessages {
    conversationMessages {
      code
      success
      errors
      data {
        id
        fromId
        fromUser {
          firstName
          lastName
        }
        payload
        conversationId
        readBy
        metadata
        status
        createdDate
        updatedDate
      }
    }
  }
`;

const UPDATE_MESSAGE = gql`
  mutation updateMessage($id: ID!, $data: MessageUpdateInput!) {
    updateMessage(id: $id, data: $data) {
      code
    }
  }
`;

const DELETE_MESSAGE = gql`
  mutation deleteMessage($id: ID!) {
    deleteMessage(id: $id) {
      code
      success
      errors
    }
  }
`;

const JOURNEY_PROVIDER = gql`
  query journeyProvider($id: ID!) {
    journeyProvider(id: $id) {
      conversation_id
      status
      id
      provider {
        id
        uuid
        name
        treatments {
          id
          description
          name
        }
        settings {
          id
          allow_payments
          allow_videocalls
        }
        videocall_free_seconds
        videocall_paid_seconds
      }
      journey {
        id
        address {
          country
          city
        }
        name
        photos {
          url
          thumb_url
        }
        specialties {
          name
        }
        answers {
          options {
            name
          }
        }
        date_added
        areas {
          id
          area_types {
            id
            name
            categories {
              name
              id
              treatments {
                id
                name
              }
            }
            treatments {
              id
              name
            }
          }
          name
        }
        gender
        settings_values {
          value
          option {
            min
            max
            value
          }
          type
        }
        user {
          role
          authUser {
            firstName
            lastName
            age
            avatar
            username
            _id
          }
          auth_id
        }
      }
    }
  }
`;

const USER_UNREAD_MESSAGES = gql`
  query userUnreadMessages($pageParams: QueryParams) {
    userUnreadMessages(pageParams: $pageParams) {
      code
      success
      errors
      meta {
        total_pages
        current_page
        next_page
        per_page
        total_count
      }
      data {
        id
        fromId
        payload
        conversationId
        readBy
        metadata
        status
        createdDate
        updatedDate
      }
    }
  }
`;

const USER_UNREAD_MESSAGES_COUNT = gql`
  query userUnreadMessagesCount {
    userUnreadMessagesCount {
      code
      success
      errors
      meta {
        total_pages
        current_page
        next_page
        per_page
        total_count
      }
      data
    }
  }
`;

const GET_FROMUSER_FOR_MESSAGE = gql`
  query user($id: ID!) {
    user(id: $id) {
      user {
        _id
        firstName
        lastName
      }
    }
  }
`;

const VIDEO_CALL_SUBSCRIPTION = gql`
  subscription providerVideoCall(
    $buyer_id: ID!
    $provider_uuid: ID!
    $display_name: String!
  ) {
    providerVideoCall(
      buyer_id: $buyer_id
      provider_uuid: $provider_uuid
      display_name: $display_name
    )
  }
`;

const PROVIDER_IS_CALLING = gql`
  subscription providerIsCalling($call_id: ID!) {
    providerIsCalling(call_id: $call_id)
  }
`;

export default {
  UPDATE_MESSAGE,
  DELETE_MESSAGE,
  SEND_MESSAGE,
  MESSAGE_SUBSCRIPTION,
  USER_UNREAD_MESSAGES,
  USER_UNREAD_MESSAGES_COUNT,
  GET_FROMUSER_FOR_MESSAGE,
  JOURNEY_PROVIDER,
  VIDEO_CALL_SUBSCRIPTION,
  PROVIDER_IS_CALLING
};
