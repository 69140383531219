var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('b-modal',{attrs:{"active":_vm.isActive,"has-modal-card":"","trap-focus":"","on-cancel":_vm.closeModal},on:{"update:active":function($event){_vm.isActive=$event}}},[_c('div',{staticClass:"modal-card"},[_c('header',{staticClass:"modal-card-head"},[_c('p',{staticClass:"modal-card-title"},[_vm._v("Journey's flags")])]),_c('section',{staticClass:"modal-card-body"},[_c('div',{},[(_vm.flagData.provider)?_c('div',[_c('strong',[_vm._v("Provider's name: ")]),_vm._v(_vm._s(_vm.flagData.provider.name)+" ")]):_vm._e(),(_vm.flagData.journey)?_c('div',[_c('strong',[_vm._v("Buyer's name: ")]),_vm._v(_vm._s(_vm.getBuyerName)+" ")]):_vm._e()]),_vm._l((_vm.flagData.flags),function(flag){return _c('div',{key:flag.id,staticClass:"flags card"},[(flag.resolved == 'OPEN')?_c('div',[_c('hr'),_c('div',{staticClass:"card-content has-text-left"},[_c('div',{},[_c('strong',[_vm._v("Date added:")]),_vm._v(" "+_vm._s(new Date(flag.date_added).toLocaleDateString())+" ")]),_c('div',{},[_c('strong',[_vm._v("Time added:")]),_vm._v(" "+_vm._s(new Date(flag.date_added).toLocaleTimeString())+" ")]),_c('div',{},[_c('strong',[_vm._v("Reported by:")]),_vm._v(" "+_vm._s(flag.reported_by == "BUYER" ? _vm.getBuyerName : _vm.flagData.provider.name)+" ")]),_c('div',{},[_c('strong',[_vm._v("Reason:")]),_vm._v(" "+_vm._s(flag.flag_reason.reason)+" ")])]),_c('div',{staticClass:"buttons is-right"},[_c('b-button',{staticClass:"is-primary",on:{"click":function($event){return _vm.confirm('DISMISS', flag.id)}}},[_vm._v(" Dismiss ")]),_c('b-dropdown',{attrs:{"aria-role":"list"}},[_c('button',{staticClass:"button is-warning",attrs:{"slot":"trigger"},slot:"trigger"},[_c('span',[_vm._v("Warn »")])]),_c('b-dropdown-item',{staticClass:"userDropdown",attrs:{"aria-role":"listitem"},on:{"click":function($event){return _vm.confirm(
                      'WARN',
                      flag.id,
                      _vm.flagData.journey.user.id,
                      _vm.flagData.journey.user.authUser._id,
                      'BUYER',
                      flag.flag_reason
                    )}}},[_vm._v("Buyer")]),_c('b-dropdown-item',{staticClass:"userDropdown",attrs:{"aria-role":"listitem"},on:{"click":function($event){return _vm.confirm(
                      'WARN',
                      flag.id,
                      _vm.flagData.provider.id,
                      _vm.flagData.provider.creator_auth_id,
                      'VENDOR',
                      flag.flag_reason
                    )}}},[_vm._v("Vendor")])],1),_c('b-dropdown',{attrs:{"aria-role":"list"}},[_c('button',{staticClass:"button is-danger",attrs:{"slot":"trigger"},slot:"trigger"},[_c('span',[_vm._v("Ban »")])]),_c('b-dropdown-item',{staticClass:"userDropdown",attrs:{"aria-role":"listitem"},on:{"click":function($event){return _vm.confirm(
                      'BAN',
                      flag.id,
                      _vm.flagData.journey.user.id,
                      _vm.flagData.journey.user.authUser._id,
                      'BUYER',
                      flag.flag_reason
                    )}}},[_vm._v("Buyer")]),_c('b-dropdown-item',{staticClass:"userDropdown",attrs:{"aria-role":"listitem"},on:{"click":function($event){return _vm.confirm(
                      'BAN',
                      flag.id,
                      _vm.flagData.provider.id,
                      _vm.flagData.provider.creator_auth_id,
                      'VENDOR',
                      flag.flag_reason
                    )}}},[_vm._v("Vendor")])],1)],1)]):_vm._e()])})],2),_c('footer',{staticClass:"modal-card-foot"},[_c('button',{staticClass:"button",attrs:{"type":"button"},on:{"click":_vm.closeModal}},[_vm._v(" Close ")])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }