import { Role } from "../../_helpers/roles";
import FlaggedUsers from "./views/FlaggedUsers";

const flaggedUsers = {
  path: "/flagged",
  name: "flagged",
  component: FlaggedUsers,
  meta: { authorize: [Role.Admin] }
};

export default [flaggedUsers];
