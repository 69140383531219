<template>
  <div>
    <div v-if="showLabel">
      <span
        style="color:#0ba1d1;font-weight: bold;font-style:italic;opacity: 50%"
      >
        {{ formattedDateLabel }}
        <br />
      </span>
    </div>
    <span v-if="isWarning">
      <bubble-warning
        :isActive="isWarningPending"
        @accept="$emit('accept', message)"
        :data="message"
      />
    </span>

    <div v-else-if="!isVideoCall" class="bubble-holder is-clearfix">
      <div
        :class="[
          'bubble',
          isMine
            ? 'vendor has-text-left is-pulled-left'
            : 'user has-text-right is-pulled-right'
        ]"
      >
        <div class="bubble-header">
          <div v-if="isMine">
            {{ isMine.providerName }}
            <b-dropdown
              v-if="!isOffer && !isAppointment && !isPayment && !isPermission"
              class="is-pulled-right"
              aria-role="list"
            >
              <button class="triple-dot-btn" slot="trigger">
                <i class="fas fa-ellipsis-h"></i>
              </button>
              <b-dropdown-item
                aria-role="listitem"
                v-if="!isPhotos && !isFile"
                @click="handleMessageToUpdate"
                >Edit</b-dropdown-item
              >
              <b-dropdown-item
                aria-role="listitem"
                @click="handleMessageToDelete"
                >Delete</b-dropdown-item
              >
            </b-dropdown>

            <span
              :key="message.id"
              :message="message"
              :index="index"
              v-for="(message, index) in sort(conversation)"
            ></span>
          </div>
          {{ isMine ? providerName : userName }}
        </div>
        <div :class="['bubble-body', isOffer ? 'bubble-body-offer' : '']">
          <span v-if="isMessage" v-html="formattedMessage"></span>
          <span
            style="color:black;font-style: italic;opacity: 60%;padding: 5px;"
            v-if="isStatus === 'EDITED' && !isAppointment"
            >(edited)</span
          >
          <span v-if="isPhotos">
            <bubble-gallery :data="meta" />
          </span>
          <span v-if="isOffer">
            <bubble-offer :data="meta" />
          </span>
          <span v-if="isAppointment">
            <bubble-appointment :data="meta" :isMine="isMine" />
          </span>
          <span v-if="isFile">
            <bubble-file :isMine="isMine" :data="meta"></bubble-file>
          </span>
          <span v-if="isPayment">
            <bubble-payment :data="meta"></bubble-payment>
          </span>
          <span v-if="isPermission">
            <bubble-video-call-permission></bubble-video-call-permission>
          </span>
        </div>
        <div class="bubble-footer">
          <span
            class="is-pulled-right"
            style="font-style: italic; opacity: 50%;"
            v-if="isMessage || isOffer || isAppointment || isFile || isPhotos"
            v-html="formattedTime"
          ></span>
          <span v-if="isAppointment">
            <span class="status declined" v-if="meta.state === 'DENIED'"
              >{{ isMine ? "The customer has" : "You have" }} declined the
              appointment date.</span
            >
            <span class="status accepted" v-if="meta.state === 'ACCEPTED'"
              >{{ isMine ? "The customer has" : "You have" }} accepted the
              appointment date.
            </span>
            <span v-if="!isMine && meta.state === 'PENDING' && !embedded">
              <div class="columns">
                <div class="column is-6 has-text-left">
                  <button
                    @click="$emit('accept', message)"
                    class="button is-primary is-success"
                  >
                    Accept
                  </button>
                </div>
                <div class="column is-6 has-text-right">
                  <button
                    @click="$emit('declineAppointment', message)"
                    class="button is-primary is-warning"
                  >
                    Decline
                  </button>
                </div>
              </div>
            </span>
            <span v-else>
              <span
                style="color:#333;font-weight:bold;"
                v-if="meta.state === 'PENDING'"
                >The customer hasn't taken any actions.
              </span>
            </span>
          </span>
          <span v-if="isOffer">
            <span class="status declined" v-if="meta.state === 'DENIED'"
              >The customer declined the offer.
            </span>
            <span class="status accepted" v-if="meta.state === 'ACCEPTED'"
              >The customer accepted the offer.
            </span>
            <span
              style="color:#333;font-weight:bold;"
              v-if="meta.state === 'PENDING'"
              >The customer hasn't decided yet.
            </span>
          </span>
          <span v-if="isPayment">
            <span class="status declined" v-if="meta.state === 'DENIED'"
              >The user has denied the payment request.</span
            >
            <span class="status accepted" v-if="meta.state === 'ACCEPTED'"
              >The customer has completed the payment successfully.</span
            >
            <span
              style="color:#333;font-weight:bold;"
              v-if="meta.state === 'PENDING'"
              >The customer hasn't responded yet.
            </span>
          </span>
          <span v-if="isPermission">
            <span class="status declined" v-if="meta.state === 'DENIED'"
              >The customer declined the request.</span
            >
            <span class="status accepted" v-if="meta.state === 'ACCEPTED'"
              >The customer accepted the request.</span
            >
            <span
              style="color:#333;font-weight:bold;"
              v-if="meta.state === 'PENDING'"
              >The customer hasn't responded yet.
            </span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { createNamespacedHelpers } from "vuex";
import { mapGetters } from "vuex";
import bubbleGallery from "./bubbleGallery";
import bubbleFile from "./bubbleFiles";
import bubbleOffer from "./bubbleOffer";
import bubbleAppointment from "./bubbleAppointment";
import bubbleWarning from "./bubbleWarning";
import bubbleVideoCallPermission from "./bubbleVideoCallPermission";
import moment from "moment";
import _ from "lodash";

const { mapActions } = createNamespacedHelpers("messages");
import bubblePayment from "./bubblePayment";
export default {
  props: [
    "message",
    "index",
    "provider",
    "embedded",
    "conversation",
    "showLabel"
  ],
  components: {
    bubbleGallery,
    bubbleOffer,
    bubbleAppointment,
    bubbleWarning,
    bubbleFile,
    bubblePayment,
    bubbleVideoCallPermission
  },
  computed: {
    ...mapGetters(["meUser"]),
    isMine() {
      return this.message.fromId === this.meUser.user._id;
    },
    providerName() {
      return this.provider && this.provider.name;
    },
    userName() {
      const fromUser = this.message.fromUser;
      const firstName = fromUser.firstName ? fromUser.firstName : "";
      const lastName = fromUser.lastName ? fromUser.lastName : "";
      return firstName + " " + lastName;
    },
    meta() {
      return JSON.parse(this.message.metadata);
    },
    isMessage() {
      return !this.meta;
    },
    isPhotos() {
      return this.meta && this.meta.type === "PHOTOS";
    },
    isStatus() {
      return this.message.status;
    },
    isFile() {
      return this.meta && this.meta.type === "FILE";
    },
    isOffer() {
      return this.meta && this.meta.type === "OFFER";
    },
    isPayment() {
      return this.meta && this.meta.type === "PAYMENT";
    },
    isWarning() {
      return this.meta && this.meta.type === "WARNING";
    },
    isPermission() {
      return this.meta && this.meta.type === "VIDEO_CALL_PERMISSION";
    },
    isVideoCall() {
      return (
        this.meta &&
        (this.meta.type === "VIDEO_CALL_ACCEPTED" ||
          this.meta.type === "VIDEO_CALL_DENY")
      );
    },
    isWarningPending() {
      return (
        this.meta &&
        this.meta.type === "WARNING" &&
        this.meta.state == "PENDING" &&
        this.meta.forUserId == this.meUser.user._id
      );
    },
    isAppointment() {
      return this.meta && this.meta.type === "APPOINTMENT";
    },
    formattedMessage() {
      return this.linkify(this.message.payload);
    },
    formattedTime() {
      return moment
        .utc(this.message.createdDate, "YYYY-MM-DDTHH:mm:ss.sss")
        .local()
        .format("h:mm A");
    },
    formattedDateLabel() {
      let messageCreatedDate = moment.utc(this.message.createdDate);
      let daysAgo = moment
        .utc(messageCreatedDate)
        .local()
        .fromNow();

      let currentDate = moment();
      let daysBefore = moment
        .duration(currentDate.diff(messageCreatedDate))
        .asDays();
      if (daysBefore > 7) {
        const date = new Date(this.message.createdDate);
        return moment(date).format("YYYY/MM/DD");
      }

      return daysAgo;
    }
  },
  methods: {
    ...mapActions([
      "updateMessage",
      "deleteMessage",
      "getMessageValue",
      "loadPreviousMessages"
    ]),
    handleMessageToDelete() {
      this.message.status = "ARCHIVED";
      this.deleteMessage({
        id: this.message.id
      });
    },
    handleMessageToUpdate() {
      this.getMessageValue({
        id: this.message.id,
        payload: this.message.payload
      });
    },
    sort(conversation) {
      return _(conversation).sortBy("createdDate");
    },
    linkify(string) {
      // eslint-disable-next-line no-useless-escape
      const URLMatcher = /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/gim;
      //string = string.replace("www.", "http://");
      return string.replace(
        URLMatcher,
        match =>
          `<a class="bubble-link" target="_blank" href="${match}">${match}</a>`
      );
    }
  }
};
</script>
<style>
.bubble-footer .status {
  font-weight: bold;
  padding: 5px;
  border-radius: 3px;
}

.bubble-footer .status.accepted {
  color: darkgreen;
}

.bubble-footer .status.declined {
  color: red;
}
.bubble-link:hover {
  border-bottom: 3px solid #82cee7;
  color: #82cee7;
}
.bubble-link {
  color: #0ba1d1;
  transition: all ease 0.2s;
  font-weight: bold;
  border-bottom: 1px solid #0ba1d1;
}
</style>
<style scoped>
.link {
  color: #fff;
  font-weight: bold;
  border-bottom: 2px solid #fff;
}
.bubble-holder {
  width: 100%;
}

.bubble {
  padding: 3px;
}

.bubble,
.bubble-header,
.bubble-body,
.bubble-footer {
  padding: 8px;
  width: 75%;
  min-width: 400px;
  word-break: break-word;
}

.bubble-header {
  border-radius: 16px 16px 0px 0px;
  font-weight: bold;
}

.bubble-footer {
  border-radius: 0px 0px 16px 16px;
}

.bubble.vendor .bubble-body-offer {
  background: none #e7e7e7 !important;
}

.bubble.user .bubble-header,
.bubble.user .bubble-footer,
.bubble.user .bubble-body {
  background: none #f2f2f2;
  color: #555;
  float: right;
  padding: 15px;
  white-space: pre-line;
}

.bubble.vendor .bubble-header,
.bubble.vendor .bubble-footer,
.bubble.vendor .bubble-body {
  background: none #f2f2f2;
  color: #555;
  float: left;
  padding: 15px;
  white-space: pre-line;
}

.bubble.vendor .bubble-header {
  background: none #e7e7e7;
}

.bubble.user .bubble-header {
  background: none #82cee7;
  color: #fff;
  min-height: 54px;
}

.bubble.vendor .bubble-body {
  border-top: 1px solid #bebebe;
}

.bubble.user .bubble-body {
  border-top: 1px solid #67adc6;
  color: #555;
}

.bubble-title {
  font-weight: bold;
}

.triple-dot-btn {
  background-color: transparent;
  border: none;
  font-weight: bold;
  outline-style: none;
}
</style>
