<template>
  <section>
    <b-modal
      :active.sync="isComponentModalActive"
      has-modal-card
      trap-focus
      :on-cancel="close"
    >
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title has-text-weight-bold">
            Create Practitioner
          </p>
        </header>
        <section class="modal-card-body">
          <p v-if="!isSubscribed" class="basic-plan-description has-text-left">
            <a href="/subscriptions" target="_blank">Upgrade</a> your plan to add Main Practitioners.
          </p>
          <ValidationObserver ref="addPractitioner">
            <b-field horizontal label="Name" label-for="name">
              <div class="control">
                <ValidationProvider
                  rules="required|max:50"
                  v-slot="{ errors }"
                  name="Name"
                >
                  <b-input
                    :disabled="!isSubscribed"
                    id="name"
                    placeholder="Name"
                    v-model="name"
                    :value="name"
                  />
                  <span
                    v-for="error in errors"
                    :key="error.id"
                    class="has-text-danger help"
                    >{{ error }}</span
                  >
                </ValidationProvider>
              </div>
            </b-field>
            <b-field
              label="Avatar"
              label-for="avatar"
              class="avatar-field"
              horizontal
            >
              <div class="control">
                <b-button
                  :disabled="!isSubscribed"
                  @click="addImage('practitionerAvatar')"
                  class="is-primary"
                >
                  Add image
                </b-button>
              </div>
              <figure class="container is-pulled-right" v-if="avatar_url">
                <img class="" :src="avatar_url" />
              </figure>
            </b-field>
            <b-field label="Degree" label-for="degree" horizontal>
              <div class="control">
                <ValidationProvider
                  rules="max:250"
                  v-slot="{ errors }"
                  name="Degree"
                >
                  <b-input
                    :disabled="!isSubscribed"
                    placeholder="Degree"
                    id="degree"
                    v-model="degree"
                    type="text"
                    maxlength="250"
                    pattern="[a-zA-Z0-9\s,]*"
                    message="This field can contain up to 500 symbols"
                  />
                  <span
                    v-for="error in errors"
                    :key="error.id"
                    class="has-text-danger help"
                    >{{ error }}</span
                  >
                </ValidationProvider>
              </div>
            </b-field>
            <b-field label="Specialties" label-for="specialty" horizontal>
              <div class="control">
                <ValidationProvider
                  rules="required|max:500"
                  v-slot="{ errors }"
                  name="Specialty"
                >
                  <b-input
                    :disabled="!isSubscribed"
                    placeholder="Specialties"
                    id="specialty"
                    v-model="specialty"
                    type="text"
                    maxlength="500"
                    pattern="[a-zA-Z0-9\s,]*"
                    message="This field can contain up to 500 symbols"
                  />
                  <span
                    v-for="error in errors"
                    :key="error.id"
                    class="has-text-danger help"
                    >{{ error }}</span
                  >
                </ValidationProvider>
              </div>
            </b-field>
            <b-field label="Description" label-for="desecription" horizontal>
              <div class="control">
                <ValidationProvider
                  rules="required|max:1000"
                  v-slot="{ errors }"
                  name="Description"
                >
                  <b-input
                    :disabled="!isSubscribed"
                    placeholder="Description"
                    id="description"
                    v-model="description"
                    type="textarea"
                    maxlength="1000"
                    pattern="[a-zA-Z0-9]*"
                    message="This field can contain up to 500 symbols"
                  />
                  <span
                    v-for="error in errors"
                    :key="error.id"
                    class="has-text-danger help"
                    >{{ error }}</span
                  >
                </ValidationProvider>
              </div>
            </b-field>
          </ValidationObserver>
        </section>
        <footer class="modal-card-foot">
          <button class="button" type="button" @click="close">
            Close
          </button>
          <button :disabled="!isSubscribed" class="button is-primary" @click="create">Create</button>
        </footer>
      </div>
    </b-modal>
    <AddImage ref="addImage" @saveImage="saveImage"></AddImage>
  </section>
</template>

<script>
import ValidationErrors from "../../../mixins/ValidationErrors";
import { UPDATE_PROVIDER } from "../../../_graphql/Mutations/mutations";
import AddImage from "./AddImage";

export default {
  components: {
    AddImage
  },
  props: {
    isSubscribed: {
      default: false
    }
  },
  methods: {
    addImage(type) {
      if (this.isSubscribed) {
        this.$refs.addImage.openModal(type);
      }
    },
    saveImage(data) {
      this.avatar_url = data.image.url;
    },
    async create() {
      if (!this.isSubscribed) {
        return;
      }
      this.$refs.addPractitioner.validate().then(async success => {
        if (!success) {
          this.refName = "addPractitioner";
          this.handleErrors();
          return;
        }
        const providerId = parseInt(this.$route.params.providerId);
        const mutation = UPDATE_PROVIDER;
        const variables = {
          id: providerId,
          input: {
            practitioners: {
              name: this.name,
              avatar_url: this.avatar_url,
              specialty: this.specialty,
              description: this.description,
              degree: this.degree
            }
          }
        };
        const payload = {
          mutation: mutation,
          queryName: "updateProvider"
        };

        this.$store.commit("provider/collection/editModel", variables);
        await this.$store.dispatch("provider/collection/update", payload);
        this.close();
        this.$emit("createdPractitioner");
      });
    },
    close() {
      this.isComponentModalActive = false;
      this.$refs.addPractitioner.reset();
      this.name = "";
      this.description = "";
      this.specialty = "";
      this.avatar_url = "";
      this.degree = "";
    },
    open() {
      this.isComponentModalActive = true;
    }
  },
  mixins: [ValidationErrors],
  data() {
    return {
      isComponentModalActive: false,
      name: "",
      description: "",
      specialty: "",
      avatar_url: "",
      degree: ""
    };
  }
};
</script>

<style lang="scss" scoped>
.basic-plan-description {
  margin-left: 20px;
  margin-bottom: 25px;
}
.avatar-field {
  margin: 34px 0 !important;

  .container {
    width: 160px;
    height: 160px;
    img {
      display: block;
      object-fit: contain;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
        0 3px 10px 0 rgba(0, 0, 0, 0.09);
      border-radius: 50%;
      width: 100%;
      height: 100%;
    }
  }
}
</style>
