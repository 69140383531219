<template>
  <div class="wrapper">
    <ValidationProvider
      rules="required|max:60"
      v-slot="{ errors, valid }"
      name="Greeting"
    >
      <b-field :type="{ 'is-danger': errors[0] }">
        <b-input
          :readonly="!data.allowUpdate"
          type="text"
          placeholder="Greeting"
          v-model="greeting"
          maxlength="60"
        />
      </b-field>
      <span
        v-for="error in errors"
        :key="error.id"
        class="has-text-danger help"
        >{{ error }}</span
      >
    </ValidationProvider>
    <div>
      Just a reminder to log in to your MeTime admin panel so patients can
      connect with you through the app. Please check your profile first and then
      return here to approve. You can edit your profile to your liking once you
      log in.
    </div>
    <div class="links">
      <a :href="data.profile_url">VIEW YOUR METIME PROFILE</a>
    </div>
    <div>
      Once you log in to your own administration panel, you can edit your
      profile as you wish and start receiving matches. Here are some of the
      benefits of coming on board with MeTime:
    </div>
    <ol>
      <li>
        Appear in <strong>matches</strong> when any User of MeTime searches for
        aesthetic procedures
      </li>
      <li>
        Invite patients to match <strong>directly</strong> with you with your
        own unique link
      </li>
      <li>
        Review patient preferences and photos and start a <strong>chat</strong>
      </li>
      <li>Start <strong>video</strong> consultations through MeTime</li>
      <li>Make offers and <strong>book</strong> people in for procedures</li>
      <li>
        Request and take <strong>payments</strong> through secure payment portal
      </li>
    </ol>
    <div>
      MeTime Basic is <strong>free</strong>. To get started, simply approve and
      log in with your email and your own password.
    </div>
    <div class="links">
      <a :href="loginLink">APPROVE AND LOG-IN</a>
    </div>
    <div>
      Take a look around your own MeTime platform. Download the app to see how
      it works, but I would love to show you the fantastic features and full
      benefits of using MeTime. Simply reply to
      <a href="mailto:Rocio.v@metime.com" style="color: #0a85ad"
        >book a DEMO with me</a
      >
      . In the meantime, I’ve upgraded your account to
      <strong>MeTime Premium</strong> for a full month just so you can
      experience more features!
    </div>
    <div>
      If you’re not sure about anything or want to ask a question, please don’t
      hesitate to get back to me.
    </div>
    <div>Best wishes,</div>
    <div class="signature">
      <h1 class="name">Rocio Varela</h1>
      <div>
        <div>Business Support Manager | MeTime Corporation</div>
        <div>Phone: +34 626421059</div>
        <div>Land: +35312343709</div>
        <div>5 Fitzwilliam Square East, Dublin 2, Ireland</div>
      </div>
      <a href="https://metime.com/" class="logo-link">
        <img
          class="logo"
          src="https://api.metime.com/assets/email/email-template-logo.jpg"
          alt="MeTime logo"
        />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      greeting: ""
    };
  },
  props: ["data"],
  computed: {
    loginLink() {
      return process.env.VUE_APP_AUTH_URL
        ? process.env.VUE_APP_AUTH_URL
        : "https://id.metime.com/";
    }
  },
  mounted() {
    if (this.data) {
      this.greeting = `Hi ${this.data.name},`;
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  font-family: "Montserrat", DejaVu Sans, Verdana, "sans-serif";
  text-align: left;
  padding-left: 0;
  padding-right: 0;

  div {
    margin: 1rem 0;
  }

  ol {
    margin-left: 3rem !important;
  }

  .links {
    text-align: center;
    margin: 2rem 0;

    a {
      padding: 8px;
      color: #0a85ad;

      &:hover {
        color: #0a6f93;
      }
    }
  }

  .signature {
    color: #0a85ad;
    margin-top: 2.5rem;

    .name {
      font-weight: bold;
      font-size: 18px;
    }

    div {
      margin: 0;
    }

    .logo-link {
      &:hover {
        cursor: pointer;
      }

      .logo {
        width: 160px;
        height: 80px;
        object-fit: cover;
      }
    }
  }
}
</style>
