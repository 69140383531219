<template>
  <section class="section settings-section">
    <title-bar>
      Settings
    </title-bar>
    <ValidationObserver ref="settingsEmailValidator">
      <b-table
        v-for="data in uniqueCategories"
        :key="data.category"
        class="table is-fullwidth "
        :data="getSettingsByCategory(data.category)"
      >
        <template slot-scope="data">
          <b-table-column field="category" :label="data.row.category">
            <span style="font-weight: bold; margin-bottom: 40px;">
              {{ data.row.title ? data.row.title : "N/A" }}
            </span>
            <br />
            <span>
              {{ data.row.description ? data.row.description : "N/A" }}
            </span>
          </b-table-column>
          <b-table-column field="controls">
            <ValidationProvider
              :rules="{
                settingsRegex: '(?<=\\s|^)\\d+(?=\\s|$)',
                min: data.row.min,
                max: data.row.max
              }"
              v-slot="{ errors }"
            >
              <b-field :type="{ 'is-danger': errors[0] }">
                <b-input
                  v-model="data.row.value"
                  v-if="data.row.type === 'INTEGER'"
                  class="input-position"
                ></b-input>
              </b-field>
              <span
                v-for="error in errors"
                :key="error.id"
                class="has-text-danger help"
                >{{ error }}</span
              >
            </ValidationProvider>
            <ValidationProvider
              :rules="{
                settingsRegex: /^(\d+(?:[\.\,]\d{0,2})?)$/,
                min: data.row.min,
                max: data.row.max
              }"
              v-slot="{ errors }"
            >
              <b-field :type="{ 'is-danger': errors[0] }">
                <b-input
                  v-model="data.row.value"
                  v-if="data.row.type === 'DECIMAL'"
                  class="input-position"
                ></b-input>
              </b-field>
              <span
                v-for="error in errors"
                :key="error.id"
                class="has-text-danger help"
                >{{ error }}</span
              >
            </ValidationProvider>
            <b-input
              v-model="data.row.value"
              v-if="data.row.type === 'STRING'"
              class="input-position"
              id="text"
              type="text"
            ></b-input>
            <ValidationProvider rules="settingsEmail" v-slot="{ errors }">
              <b-field :type="{ 'is-danger': errors[0] }">
                <b-input
                  class="input-position"
                  type="text"
                  trim
                  v-model="data.row.value"
                  v-if="data.row.type === 'EMAIL'"
                />
              </b-field>
              <span
                v-for="error in errors"
                :key="error.id"
                class="has-text-danger help"
                >{{ error }}</span
              >
            </ValidationProvider>
            <b-checkbox
              class="input-position"
              v-model="data.row.value"
              v-if="data.row.type === 'BOOLEAN'"
            />
          </b-table-column>

          <b-table-column field="actions" label="">
            <div class="buttons is-right">
              <b-button
                class="input-position is-primary is-outlined"
                @click="
                  updateSettings(data.row.id, data.row.value, data.row.type)
                "
                :disabled="
                  ['INTEGER', 'DECIMAL'].includes(data.row.type) &&
                    isCorrectValue(
                      data.row.min,
                      data.row.max,
                      data.row.value.replace(/,/g, '.')
                    )
                "
                >Save</b-button
              >
            </div>
          </b-table-column>
        </template>
        <template slot="empty">
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <p>
                <b-icon icon="information" size="is-large"></b-icon>
              </p>
              <p>No settings in database.</p>
            </div>
          </section>
        </template>
      </b-table>
      <div class="has-background-white">
        <h2
          class="matching-title has-text-primary has-text-left custom-padding"
        >
          Matching
        </h2>
        <div>
          <div class="columns custom-padding">
            <div
              class="column is-four-fifths has-text-black has-text-left custom-padding"
            >
              Configure the matching algorithm. Provider types (Specialties)
              will appear in the matching results based on these parameters
            </div>
            <div class="column has-text-right">
              <button
                class="button has-background-white has-text-primary has-text-right is-primary is-outlined"
                @click="saveMatchingSettings(areas.data)"
              >
                Save
              </button>
            </div>
          </div>

          <b-tabs
            :position="atRight ? 'is-right' : ''"
            :size="size"
            vertical
            :expanded="expanded"
            class="has-background-white"
          >
            <b-tab-item
              :label="specialty.name + ' ' + specialty.id"
              v-for="specialty in specialties.data"
              :key="specialty.id"
              v-model="specialty.id"
            >
              <section class="column is-full">
                <div
                  class="is-flex is-align-items-end"
                  v-for="(area, index) of areas.data"
                  :key="index"
                >
                  <b-checkbox
                    :value="area.specialties.includes(specialty.id)"
                    @input="checkItem(area.specialties, specialty.id, 'area')"
                  />
                  <b-collapse
                    class="card column"
                    animation="slide"
                    :open="isOpen === area + index + specialty.id"
                    @open="isOpen = area + index + specialty.id"
                  >
                    <template #trigger="props">
                      <div class="is-flex columns">
                        <div class="card-header w-100" role="button">
                          <p class="card-header-title has-background-white p-0">
                            {{ area.name }}
                          </p>
                          <a class="card-header-icon has-background-white p-0">
                            <b-icon
                              :icon="props.open ? 'menu-up' : 'menu-down'"
                            >
                            </b-icon>
                          </a>
                        </div>
                      </div>
                    </template>
                    <div class="card-content">
                      <div class="content">
                        <div class="has-text-left">
                          <div
                            v-for="(types, indexType) in area.area_types"
                            :key="types.id"
                          >
                            <div
                              v-if="types.categories.length < 2"
                              class="is-flex"
                            >
                              <b-checkbox
                                :value="
                                  types.specialties.includes(specialty.id)
                                "
                                @input="
                                  checkItem(
                                    types.specialties,
                                    specialty.id,
                                    'areaType',
                                    area
                                  )
                                "
                              />

                              {{ types.name }}
                            </div>
                            <div
                              v-else
                              class="is-flex is-align-items-end custom-check"
                            >
                              <b-checkbox
                                :value="
                                  types.specialties.includes(specialty.id)
                                "
                                @input="
                                  checkItem(
                                    types.specialties,
                                    specialty.id,
                                    'areaType',
                                    area
                                  )
                                "
                              />
                              <div class="column is-flex is-two-thirds p-0">
                                <b-collapse
                                  class="card column is-full p-0"
                                  animation="slide"
                                  :open="
                                    isOpenAreaTypeCategory ==
                                      indexType + types.id
                                  "
                                  @open="
                                    isOpenAreaTypeCategory =
                                      indexType + types.id
                                  "
                                >
                                  <template #trigger="props">
                                    <div class="card-header" role="button">
                                      <p
                                        class="card-header-title mb-0 has-background-white p-0"
                                      >
                                        {{ types.name }}
                                      </p>
                                      <a
                                        class="card-header-icon has-background-white p-0"
                                      >
                                        <b-icon
                                          :icon="
                                            props.open ? 'menu-up' : 'menu-down'
                                          "
                                        >
                                        </b-icon>
                                      </a>
                                    </div>
                                  </template>
                                  <div class="card-content">
                                    <div class="content">
                                      <div
                                        v-for="(areaTypeCategory,
                                        indexType) in types.categories"
                                        :key="indexType"
                                        class="is-flex"
                                      >
                                        <b-checkbox
                                          :value="
                                            areaTypeCategory.specialties.includes(
                                              specialty.id
                                            )
                                          "
                                          @input="
                                            checkItem(
                                              areaTypeCategory.specialties,
                                              specialty.id,
                                              'areaTypeCategory',
                                              types
                                            )
                                          "
                                        />
                                        {{ areaTypeCategory.name }}
                                      </div>
                                    </div>
                                  </div>
                                </b-collapse>
                              </div>
                              <button
                                :id="'btnTypeId' + specialty.id + types.id"
                                class="has-background-white check-all has-text-primary"
                                @click="
                                  checkAllItems(
                                    'areaTypeCategory',
                                    specialty,
                                    types,
                                    area.id
                                  )
                                "
                              >
                                Check all
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-collapse>
                  <div>
                    <button
                      class="has-background-white check-all has-text-primary"
                      :id="'btnId' + specialty.id + area.id"
                      @click="checkAllItems('areaType', specialty, area)"
                    >
                      Check all
                    </button>
                  </div>
                </div>
              </section>
            </b-tab-item>
          </b-tabs>
        </div>
      </div>
    </ValidationObserver>
  </section>
</template>

<script>
import TitleBar from "../../base/components/TitleBar";
import { SYSTEM_SETTINGS } from "../../../_graphql/Queries/queries";
import { ALL_AREAS } from "../../../_graphql/Queries/queries";
import { SPECIALITIES } from "../../../_graphql/Queries/queries";
import { CREATE_AREAS_SPECIALTIES } from "../../../_graphql/Mutations/mutations";
import { UPDATE_SYSTEM_SETTING } from "@/_graphql/Mutations/mutations";
import ValidationErrors from "../../../mixins/ValidationErrors";
import _ from "lodash";

export default {
  name: "Settings",
  mixins: [ValidationErrors],
  components: { TitleBar },
  data() {
    return {
      btnName: "Check all",
      activeTab: 0,
      areas: ALL_AREAS,
      specialties: SPECIALITIES,
      expanded: false,
      atRight: false,
      size: null,
      isOpen: null,
      isOpenAreaTypeCategory: null,
      areaSettings: {
        area_id: [],
        specialty_id: []
      },
      areaTypeSettings: {
        area_type_id: [],
        specialty_id: []
      },
      areaCategorySettings: {
        area_type_category_id: [],
        specialty_id: []
      },
      columnsVisible: {
        name: { title: "Name", display: true }
      },
      showDetailIcon: true
    };
  },
  computed: {
    uniqueCategories() {
      return _.uniqBy(this.systemSettings?.data, "category");
    }
  },
  methods: {
    checkItem(items, specialtyId, type, area) {
      if (type === "area") {
        if (!items.includes(specialtyId)) {
          items.push(specialtyId);
        } else {
          items.splice(items.indexOf(specialtyId), 1);
        }
      }
      if (type === "areaType") {
        if (!items.includes(specialtyId)) {
          items.push(specialtyId);
        } else {
          items.splice(items.indexOf(specialtyId), 1);
        }
      }
      if (type === "areaTypeCategory") {
        if (!items.includes(specialtyId)) {
          items.push(specialtyId);
        } else {
          items.splice(items.indexOf(specialtyId), 1);
        }
      }
      let tempItems = [];
      if (type === "areaType") {
        area.area_types.forEach(types => {

          if (types.specialties.includes(specialtyId)) {
            tempItems.push(types.specialties.includes(specialtyId));
          } else {
            tempItems.push(types.specialties.includes(specialtyId));
          }
        });
        if (tempItems.includes(false)) {
          document.getElementById("btnId" + specialtyId + area.id).innerText =
            "Check all";
        } else {
          document.getElementById("btnId" + specialtyId + area.id).innerText =
            "Uncheck all";
        }
      }
      if (type === "areaTypeCategory") {
        area.categories.forEach(types => {

          if (types.specialties.includes(specialtyId)) {
            tempItems.push(types.specialties.includes(specialtyId));
          } else {
            tempItems.push(types.specialties.includes(specialtyId));
          }
        });
        if (tempItems.includes(false)) {
          document.getElementById(
            "btnTypeId" + specialtyId + area.id
          ).innerText = "Check all";
        } else {
          document.getElementById(
            "btnTypeId" + specialtyId + area.id
          ).innerText = "Uncheck all";
        }
      }
    },
    checkAllItems(type, item, items2) {
      if (type === "areaType") {
        if (
          document.getElementById("btnId" + item.id + items2.id).innerText ===
          "Check all"
        ) {
          items2.area_types.filter(itemsId => {
            if (!itemsId.specialties.includes(item.id)) {
              itemsId.specialties.push(item.id);
            }
            document.getElementById("btnId" + item.id + items2.id).innerText =
              "Uncheck all";
          });
        } else {
          items2.area_types.filter(itemsId => {
            itemsId.specialties.splice(itemsId.specialties.indexOf(item.id), 1);
          });
          document.getElementById("btnId" + item.id + items2.id).innerText =
            "Check all";
        }
      }
      if (type === "areaTypeCategory") {
        if (
          document.getElementById("btnTypeId" + item.id + items2.id)
            .innerText === "Check all"
        ) {
          items2.categories.filter(itemsId => {
            if (!itemsId.specialties.includes(item.id)) {
              itemsId.specialties.push(item.id);
            }
            document.getElementById(
              "btnTypeId" + item.id + items2.id
            ).innerText = "Uncheck all";
          });
        } else {
          items2.categories.filter(itemsId => {
            itemsId.specialties.splice(itemsId.specialties.indexOf(item.id), 1);
          });
          document.getElementById("btnTypeId" + item.id + items2.id).innerText =
            "Check all";
        }
      }
    },
    saveMatchingSettings(all) {
      const area_id = [],
        area_specialty_id = [],
        area_type_id = [],
        area_type_specialty_id = [],
        area_type_category_id = [],
        area_type_category_specialty_id = [];

      all.forEach(area => {
        area.specialties.forEach(specialtiesItem => {
          area_id.push(Number(area.id));
          area_specialty_id.push(Number(specialtiesItem));
        });

        area.area_types.forEach(areaType => {
          areaType.specialties.forEach(i => {
            area_type_id.push(Number(areaType.id));
            area_type_specialty_id.push(
              Number(i)
            );
          });
          areaType.categories.forEach(areaTypeCategories => {
              areaTypeCategories.specialties.forEach(v => {
                area_type_category_id.push(
                Number(areaTypeCategories.id)
              );
              area_type_category_specialty_id.push(
                Number(v)
              );
              })              
          });
        });
      });

      this.$apollo
        .mutate({
          mutation: CREATE_AREAS_SPECIALTIES,
          variables: {
            input: {
              area: {
                area_id: area_id,
                specialty_id: area_specialty_id,
              },
              area_type: {
                area_type_id: area_type_id,
                specialty_id: area_type_specialty_id,
              },
              area_type_category: {
                area_type_category_id: area_type_category_id,
                specialty_id: area_type_category_specialty_id,
              }
            }
          }
        })
        .catch(error => {
          return error;
        })
        .then(data => {
          if (data.data.createAreasSpecialties.data.success) {
            this.$buefy.toast.open({
              message: "Matching settings updated successfully.",
              type: "is-success",
              duration: 1500
            });
          }
        });
    },
  
    getSettingsByCategory(category) {
      let data = this.systemSettings.data.filter(
        settings => settings.category == category
      );

      data.forEach(entry => {
        if (entry.type === "BOOLEAN") {
          let temp = parseInt(entry.value);
          if (temp == 1) {
            entry.value = true;
          } else if (temp == 0) {
            entry.value = false;
          }
        }
      });

      return data;
    },
    isCorrectValue(min, max, value) {
      return !(value >= min && value <= max);
    },
    updateSettings(id, value, type) {
      this.$refs.settingsEmailValidator.validate().then(success => {
        if (!success) {
          this.refName = "settingsEmailValidator";
          this.handleErrors();
          return;
        }

        const mutation = UPDATE_SYSTEM_SETTING;
        let variables;
        if (type === "BOOLEAN") {
          variables = {
            id: parseInt(id),
            value: (value ? 1 : 0).toString()
          };
        } else if (type === "DECIMAL") {
          variables = {
            id: parseInt(id),
            value: value.replace(/,/g, ".")
          };
        } else {
          variables = {
            id: parseInt(id),
            value: value
          };
        }

        this.$apollo.provider.defaultClient
          .mutate({
            mutation: mutation,
            variables: variables
          })
          .catch(error => {
            return error;
          })
          .then(data => {
            const updateSetting = data.data
              ? data.data.updateSystemSetting
              : false;
            if (updateSetting) {
              this.$buefy.toast.open({
                message: "Settings updated successfully.",
                type: "is-success",
                duration: 1500
              });
            } else {
              this.$buefy.toast.open({
                message: "Something went wrong.",
                type: "is-danger",
                duration: 2000
              });
              setTimeout(() => {
                this.resetSubmitSwitch = !this.resetSubmitSwitch;
              }, 4500);
            }
          });
      });
    }
  },
  apollo: {
    systemSettings: {
      query: SYSTEM_SETTINGS
    },
    specialties: {
      query: SPECIALITIES,
      variables: {
        data: {
          id: "id:dsc",
          name
        }
      }
    },
    areas: {
      query: ALL_AREAS,
      variables: {
        input: {
          order: "id:asc"
        }
      },
      result({ data }) {
        this.areas = data.areas;
      }
    }
  }
};
</script>
<style lang="scss">
.settings-section {
  tr:nth-child(even) {
    background-color: #eeee;
  }
  th {
    width: 40%;
  }
  .input-position {
    margin-top: 20px;
    margin-bottom: 30px;
  }
}
.is-align-items-end {
  align-items: end;
}
.is-justify-content-space-between {
  justify-content: space-between;
}
.card-header-title {
  margin-bottom: 0px !important;
}
.card-header {
  border-bottom: unset !important;
}
.w-100 {
  width: 100%;
}
.p-0 {
  padding: 0px !important;
}
.custom-check {
  margin-top: 2px;
}
.check-all {
  cursor: pointer;
  border: unset;
  font-weight: bold;
  &:focus {
    outline: none;
  }
}
.matching-title {
  background-color: #e6eff2;
  font-weight: 600;
}
.custom-padding {
  padding: 0.5em 1em;
}
.tab-content {
  padding-top: 0 !important;
}
</style>
