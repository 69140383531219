<template>
  <div class="plan-features-wrapper">
    <div
        v-for="(info, index) in plan"
        :key="index"
        class="has-text-left"
    >
      <div>
        <img class="icon" src="@/assets/img/done.svg" alt="" />
        <span :class="index === 0 ? 'feature-text has-text-weight-bold' : 'feature-text'">{{ info }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PlanInfo',
  props: {
    plan: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>

.icon {
  vertical-align: middle;
}

.feature-text {
  vertical-align: middle;
  margin-left: 5px;
}

</style>
