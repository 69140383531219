import Vue from "vue";

import {
  required,
  max,
  min,
  min_value,
  max_value,
  integer,
  email,
  alpha_num,
  ext,
  confirmed,
  regex,
  between,
  is
} from "vee-validate/dist/rules";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
extend("required", {
  ...required,
  message: "The {_field_} field is required!"
});

extend("max", {
  ...max,
  message: "Max length of {_field_} field is {length} characters."
});

extend("min", {
  ...min,
  message: "Min length of {_field_} field is {length} characters."
});

extend("integer", {
  ...integer,
  message: "The {_field_} field must be an integer"
});

extend("email", {
  ...email,
  message: "The {_field_} field is not a valid email address"
});

extend("settingsEmail", {
  ...email,
  message: "The Email field is not a valid email address"
});

extend("alpha_num", {
  ...alpha_num,
  message: "The {_field_} field can contain only numbers and letters"
});

extend("ext", {
  ...ext
});

extend("confirmed", {
  ...confirmed,
  message: "The {_field_} field must match the New Password field" // hardcoded for new password...
});

extend("passRegex", {
  ...regex,
  message: "Password must contain at least 8 characters including 1 number and 1 letter"
});

extend("digitsRegex", {
  ...regex,
  message: "Discount must contain only correctly written digits"
});

extend("min_value", {
  ...min_value
});

extend("max_value", {
  ...max_value
});

extend("settingsRegex", {
  ...regex,
  message: "This field must contain only correctly written digits"
});

extend("regex", {
  ...regex
});
extend("between", {
  ...between
});
extend("valid_phone", {
  ...is,
  message: "The provided phone number is not valid"
});
extend("max_years", {
  ...max_value,
  message: "Years of {_field_} should be from 0 to 99."
});
extend("min_years", {
  ...min_value,
  message: "Years of {_field_} should be from 0 to 99."
});
extend("whole_number", {
  ...integer,
  message: "The {_field_} field must be a whole number"
});

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
