<template>
  <div class="card">
    <header class="card-header">
      <div class="card-header-title">
        <p class="has-text-primary">
          <b-icon icon="video" custom-size="default" />
          Videos
        </p>
        <b-field v-if="showBtns">
          <b-button class="is-primary" @click="addVideo">Add</b-button>
        </b-field>
      </div>
    </header>
    <div class="card-content">
      <div>
        <p v-if="!isSubscribed" class="video-description has-text-left">
          Add videos to enhance your profile. Unlock up to 5 display videos with
          <a href="/subscriptions" target="_blank">Premium</a> and 10 with
          <a href="/subscriptions" target="_blank">Pro</a>. Simply drag the
          videos to reorder them.
        </p>
        <p
          v-if="isSubscribed && subscriptionPlan === 'Premium'"
          class="video-description has-text-left"
        >
          Upload up to 10 videos to enhance your profile. Unlock 5 more display
          videos with <a href="/subscriptions" target="_blank">Pro</a>. Simply
          drag the videos to reorder them.
        </p>
        <p
          v-if="isSubscribed && subscriptionPlan === 'Pro'"
          class="video-description has-text-left"
        >
          Upload and display up to 20 videos. Simply drag the videos to reorder
          them.
        </p>
        <ValidationObserver ref="orderProviderVideos">
          <b-field v-if="providerVideos && providerVideos.length > 0">
            <draggable
              :list="providerVideos"
              class="columns is-multiline list-group"
              ghost-class="ghost"
              @start="dragging = true"
              @end="
                () => {
                  dragging = false;
                  saveOrder();
                }
              "
            >
              <div
                v-for="(video, index) in providerVideos"
                :key="video.id"
                class="list-group-item column is-12-mobile is-6-tablet is-4-desktop"
              >
                <div>
                  <figure v-if="index < getMaxAssetsNumber(subscriptionPlan)">
                    <iframe
                      frameborder="0"
                      @error="errorImageHandler"
                      :src="video.url"
                      class="has-ratio"
                      allowfullscreen
                    />
                  </figure>
                  <div v-else>
                    <b-tooltip
                      class="video-img"
                      label="This video will not be displayed due to the limits of your subscription plan. Upgrade to display."
                      multilined
                      position="is-left"
                    >
                      <div
                        class="video-img-cover-section"
                        :style="`background-image: url(${video.image_url});`"
                      ></div>
                    </b-tooltip>
                  </div>
                  <a
                    v-if="showBtns"
                    class="is-aligned-center"
                    @click.prevent="confirmDelete(video)"
                    ><b-icon class="" icon="delete"
                  /></a>
                </div>
              </div>
            </draggable>
          </b-field>
        </ValidationObserver>
      </div>
    </div>
    <AddVideoModal
      ref="addVideoModal"
      @addedVideo="addedVideo"
      :videoss="videoss"
    />
  </div>
</template>

<script>
import {
  DELETE_PROVIDER_VIDEO,
  UPDATE_PROVIDER
} from "../../../_graphql/Mutations/mutations";
import draggable from "vuedraggable";

import AddVideoModal from "./AddVideoModal";
export default {
  data() {
    return {
      videoss: [],
      providerVideos: [],
      videosList: [],
      defaultImage: "https://www.youtube.com/embed/YE7VzlLtp-4?showinfo=0",
      maxNumberVideos: 10,
      dragging: false
    };
  },
  props: {
    videos: {
      default: () => [],
      type: Array
    },
    userRole: {
      default: ""
    },
    allowUpdate: {
      default: false
    },
    subscriptionPlan: {
      default: ""
    },
    isSubscribed: {
      default: false
    }
  },
  components: {
    AddVideoModal,
    draggable
  },
  computed: {
    showBtns() {
      return this.allowUpdate;
    },
    getProviderVideos: {
      get: function() {
        return this.videos
          .map(video => video)
          .sort((a, b) => a.position - b.position);
      },
      set: function(newVal) {
        this.videosList = newVal;
      }
    }
  },
  watch: {
    getProviderVideos: {
      handler: function(newVal) {
        this.providerVideos = newVal;
      },
      immediate: true
    }
  },
  methods: {
    async saveOrder() {
      this.$refs.orderProviderVideos.validate().then(async success => {
        if (!success) {
          this.refName = "orderProviderVideos";
          this.handleErrors();
          return;
        }

        const newPositions = this.providerVideos.map((video, index) => {
          return {
            url: video.url,
            id: video.id,
            position: video.position !== index + 1 ? index + 1 : video.position
          };
        });

        const mutation = UPDATE_PROVIDER;
        const variables = {
          id: parseInt(this.$route.params.providerId),
          input: {
            videos: newPositions
          }
        };

        const payload = {
          mutation: mutation,
          queryName: "updateProvider"
        };
        this.$store.commit("provider/collection/editModel", variables);
        await this.$store.dispatch("provider/collection/update", payload);
        this.$emit("addedVideo");
        this.$emit("savedChanges");
      });
    },
    addVideo() {
      if (this.videos.length >= this.maxNumberVideos) {
        return this.$buefy.toast.open({
          message: `You can add up to ${this.maxNumberVideos} videos!`,
          type: "is-warning"
        });
      }
      this.$refs.addVideoModal.openModal();
    },
    addedVideo() {
      this.$emit("addedVideo");
    },
    getMaxAssetsNumber(plan) {
      if (plan === "Pro") {
        return this.maxNumberVideos;
      }

      if (plan === "Premium") {
        return 5;
      }

      return 0;
    },
    confirmDelete(video) {
      this.$buefy.dialog.confirm({
        title: "Deleting ",
        message:
          "Are you sure you want to <b>delete</b> this video?" +
          " This action cannot be undone.",
        confirmText: "Delete",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.deleteVideo(video)
      });
    },
    async deleteVideo(video) {
      const mutation = DELETE_PROVIDER_VIDEO;
      const variables = {
        id: parseInt(video.id)
      };
      this.$store.commit("provider/collection/deleteModel", variables);
      await this.$store.dispatch("provider/collection/delete", mutation);
      this.$emit("deletedVideo");
    },
    errorImageHandler(event) {
      event.target.src = this.defaultImage;
    }
  }
};
</script>

<style lang="scss" scoped>
.order-btn {
  margin-right: 10px;
}
.video-description {
  margin-bottom: 20px;
}
.video-img {
  height: 247px;
  width: 100%;
}
.video-img-cover-section {
  width: 100%;
  height: 240px;
  position: relative;
  background-color: unset;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  opacity: 0.95;
  filter: blur(4px) grayscale(85%);
  -webkit-filter: blur(4px) grayscale(85%);
}
.info-icon-position {
  height: 247px;
  width: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.card-header-title {
  justify-content: space-between;
}
iframe {
  width: 100%;
  height: 240px;
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.list-group {
  min-height: 100px;
}
.list-group-item {
  cursor: move;
}
.list-group-item i {
  cursor: pointer;
}
.ghost {
  opacity: 0.5;
  background: #e6eff2;
}
</style>
