<script>
export default {
  name: "LoadingState",
  computed: {
    loading() {
      return this.$apollo.loading;
    }
  },
  watch: {
    loading(newVal) {
      this.$store.commit("setLoading", newVal);
    }
  }
};
</script>
