<template>
  <div ref="chartdiv" id="chartdiv"></div>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";
export default {
  props: {
    countriesData: {
      default: []
    }
  },
  mounted() {
    let chart = am4core.create(this.$refs.chartdiv, am4maps.MapChart);

    // Set map definition
    chart.geodata = am4geodata_worldLow;
    chart.logo.visible = false;

    // Set projection
    chart.projection = new am4maps.projections.Miller();

    // Create map polygon series
    let polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());
    polygonSeries.data = this.countriesData;
    // Exclude Antartica
    polygonSeries.exclude = ["AQ"];

    // Make map load polygon (like country names) data from GeoJSON
    polygonSeries.useGeodata = true;

    // Configure series
    let polygonTemplate = polygonSeries.mapPolygons.template;

    polygonTemplate.tooltipText = "{name} : {value}";
    polygonTemplate.fill = am4core.color("rgba(230,239,242,0.7)");
    polygonSeries.heatRules.push({
      property: "fill",
      target: polygonSeries.mapPolygons.template,
      min: am4core.color("#bed1d8"),
      max: am4core.color("#5958c3")
    });
    // Create hover state and set alternative fill color
    let hs = polygonTemplate.states.create("hover");
    hs.properties.fill = am4core.color("rgba(0,225,194,0.89)");

    let label = chart.createChild(am4core.Label);
    label.text = "Data updates in every 10 min.";
    label.fontSize = 10;
    label.align = "right";
    label.paddingTop = 360;

    // Setting map's initial zoom
    chart.homeZoomLevel = 5;
    chart.homeGeoPoint = {
      latitude: 52,
      longitude: 11
    };
  }
};
</script>
<style lang="scss" scoped>
#chartdiv {
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.09);
}
</style>
