<template>
  <section>
    <b-modal
      :active.sync="isActive"
      has-modal-card
      trap-focus
      :on-cancel="close"
    >
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title has-text-weight-bold">
            {{ (link.id ? "Update " : "Add ") + link.label + " Link" }}
          </p>
        </header>
        <section class="modal-card-body">
          <ValidationObserver ref="validateLink">
            <b-field label="URL Address" label-for="url">
              <div class="control">
                <ValidationProvider
                  rules=""
                  v-slot="{ errors }"
                  name="Url Address"
                >
                  <b-input
                    id="name"
                    placeholder="Url address"
                    v-model="value"
                  />
                  <span
                    v-for="error in errors"
                    :key="error.id"
                    class="has-text-danger help"
                    >{{ error }}</span
                  >
                </ValidationProvider>
              </div>
            </b-field>
          </ValidationObserver>
        </section>
        <footer class="modal-card-foot">
          <button class="button" type="button" @click="close">
            Close
          </button>
          <button class="button is-primary" @click="update">
            {{ link.id ? "Save" : "Add" }}
          </button>
        </footer>
      </div>
    </b-modal>
  </section>
</template>

<script>
import {
  UPDATE_PROVIDER,
  UPDATE_PROVIDER_SOCIAL
} from "../../../_graphql/Mutations/mutations";
import ValidationErrors from "../../../mixins/ValidationErrors";

export default {
  data() {
    return {
      isActive: false,
      value: "",
      link: {}
    };
  },
  mixins: [ValidationErrors],
  methods: {
    openModal(item) {
      this.isActive = true;
      this.link = item;
      if (item.value) {
        this.value = item.value;
      }
    },
    close() {
      this.isActive = false;
      this.$refs.validateLink.reset();
      this.link = {};
      this.value = "";
    },
    update() {
      if (!this.value) {
        return this.$buefy.toast.open({
          message: "URL Address is required",
          type: "is-danger"
        });
      } else {
        if (!this.value.startsWith("http")) {
          this.value = "http://" + this.value;
        }
      }
      let mutation = this.link.id ? UPDATE_PROVIDER_SOCIAL : UPDATE_PROVIDER;
      let variables;
      if (this.link.id) {
        variables = {
          id: this.link.id,
          input: {
            name: this.value,
            type: this.link.type
          }
        };
      } else {
        variables = {
          id: parseInt(this.$route.params.providerId),
          input: {
            socials: {
              name: this.value,
              type: this.link.type
            }
          }
        };
      }

      this.$apollo
        .mutate({
          mutation: mutation,
          variables: variables,
          error: ({ error }) => {
            this.value = this.link.value || "";
            return this.$buefy.toast.open({
              message: error,
              type: "is-danger"
            });
          }
        })
        .catch(error => {
          this.value = this.link.value || "";
          return this.$buefy.toast.open({
            message: error,
            type: "is-danger"
          });
        })
        .then(({ data }) => {
          if (data.updateProvider || data.updateProviderSocial) {
            this.close();
            this.$emit("socialLinkUpdate");
            this.$buefy.toast.open({
              message: this.link.id
                ? "Link updated successfully"
                : "Link created successfully",
              type: "is-success"
            });
          }
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-card-foot {
  display: flex;
  justify-content: flex-end !important;
}
</style>
