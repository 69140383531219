<template>
  <card-component title="Change Email" icon="email">
    <div class="card-message" v-if="me.user.role != 'VENDOR'">
      Your current email is <strong>{{ this.me.user.email }}</strong
      >.
      <br />
      To change your email, please contact technical support.
    </div>
    <div class="card-message" v-else-if="me.provider != 'LOCAL'">
      Your current email is <strong>{{ this.me.user.email }}</strong
      >.
      <br />
      Your MeTime account is signed up through
      {{ me.provider[0] + me.provider.slice(1).toLowerCase() }}.
      <br />
      To change your email, please
      <strong><a href="https://id.metime.com/signup">register</a></strong> with
      a different one.
    </div>
    <ValidationObserver ref="changeEmailFormValidator" class="userForm" v-else>
      <b-notification
        :active.sync="emailSent"
        aria-close-label="Close notification"
        type="is-primary"
      >
        A confirmation email has been sent to <strong>{{ newEmail }}</strong
        >.
        <br />
        Please check your email to confirm the change.
      </b-notification>
      <div class="card-message">
        Your current email is <strong>{{ this.me.user.email }}</strong
        >. If you would like to change it, please fill out your password and new
        email below.
      </div>
      <div class="tile is-ancestor">
        <section class="tile is-parent is-9 is-vertical">
          <div class="columns" style="flex-wrap: wrap;">
            <ValidationProvider
              class="is-11 column"
              rules="required|min:2"
              v-slot="{ errors, valid }"
              name="Password"
            >
              <b-field
                :type="{ 'is-danger': errors[0], 'is-primary': valid }"
                label="Password"
              >
                <b-input
                  trim
                  type="password"
                  password-reveal
                  v-model="form.password"
                />
              </b-field>
              <span
                v-for="error in errors"
                :key="error.id"
                class="has-text-danger help"
                >{{ error }}</span
              >
            </ValidationProvider>
            <ValidationProvider
              class="is-11 column"
              rules="required|min:2|email"
              v-slot="{ errors, valid }"
              name="New Email"
            >
              <b-field
                :type="{ 'is-danger': errors[0], 'is-primary': valid }"
                label="New Email"
              >
                <b-input trim v-model="form.email" />
              </b-field>
              <span
                v-for="error in errors"
                :key="error.id"
                class="has-text-danger help"
                >{{ error }}</span
              >
            </ValidationProvider>
          </div>
        </section>
      </div>
      <div class="level" style="padding-top:20px">
        <div class="level-left">
          <div class="level-item">
            <b-button
              class="is-primary"
              :loading="sendingForm"
              @click="submitForm"
              >Submit</b-button
            >
          </div>
        </div>
      </div>
    </ValidationObserver>
  </card-component>
</template>

<script>
import _ from "lodash";
import CardComponent from "../../base/components/CardComponent";
import { CHANGE_EMAIL } from "../../../_graphql/Mutations/mutations";
import ValidationErrors from "../../../mixins/ValidationErrors";

export default {
  name: "EmailChangeForm",
  mixins: [ValidationErrors],
  components: {
    CardComponent
  },
  props: {
    me: Object
  },
  data() {
    return {
      sendingForm: false,
      form: {
        password: "",
        email: ""
      },
      emptyForm: null,
      emailSent: false,
      newEmail: ""
    };
  },
  created() {
    this.emptyForm = _.clone(this.form);
  },
  methods: {
    submitForm() {
      this.$refs.changeEmailFormValidator.validate().then(success => {
        if (!success) {
          this.refName = "changeEmailFormValidator";
          this.handleErrors();
          return;
        }
        const variables = {
          currentPassword: this.form.password,
          email: this.form.email
        };
        this.sendingForm = true;
        this.$apollo.provider.defaultClient
          .mutate({
            mutation: CHANGE_EMAIL,
            variables: variables
          })
          .catch(error => {
            return this.$buefy.toast.open({
              message: `${error}`,
              type: "is-danger",
              duration: 3000
            });
          })
          .then(data => {
            let response = data.data.changeEmail;
            if (response.success) {
              this.$buefy.toast.open({
                message:
                  "Success. Please check your new email to confirm the change.",
                type: "is-success",
                duration: 3000
              });
              this.emailSent = true;
              this.newEmail = this.form.email;
              this.resetForm();
            } else {
              this.$buefy.toast.open({
                message: response.errors.join(", "),
                type: "is-danger",
                duration: 3000
              });
            }
          });
        this.sendingForm = false;
      });
    },
    resetForm() {
      this.form = _.clone(this.emptyForm);
      this.$refs.changeEmailFormValidator.reset();
    }
  }
};
</script>

<style scoped></style>
