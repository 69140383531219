<template>
  <div>
    <b-table
      class="table is-fullwidth"
      :data="providers"
      :paginated="paginated"
      :per-page="perPage"
      default-sort="name"
      :current-page="currentPage || 1"
      :hoverable="true"
      @page-change="onPageChange"
    >
      <template slot-scope="data">
        <b-table-column label="Nr.">
          {{ data.row.id }}
        </b-table-column>
        <b-table-column label="Name" field="name">
          <router-link :to="'/providers/edit/' + data.row.id">
            {{ data.row.name }}
          </router-link>
        </b-table-column>
        <b-table-column label="Avatar" field="avatar">
          <div class="container">
            <img
              alt="Avatar image"
              v-if="data.row.avatar_url"
              id="avatar"
              :src="data.row.avatar_url"
              @error="handleImageError"
            />
          </div>
        </b-table-column>
        <b-table-column label="Country" field="country">
          {{ data.row.address.locale.country.name }}
        </b-table-column>
        <b-table-column label="City" field="city">
          {{ data.row.address.locale.city.name }}
        </b-table-column>
        <b-table-column label="Address" field="address">
          {{ data.row.address.address }}
        </b-table-column>
        <b-table-column id="actions">
          <div class="buttons is-right">
            <b-button class=" is-success" @click="approve(data.row.id)"
              >approve</b-button
            >
            <b-button class="is-danger" @click="confirmDisapprove(data.row)"
              >disapprove</b-button
            >
          </div>
        </b-table-column>
      </template>
      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>
              <b-icon icon="emoticon-sad" size="is-large"> </b-icon>
            </p>
            <p>Nothing here.</p>
          </div>
        </section>
      </template>
    </b-table>
  </div>
</template>

<script>
import {
  APPROVE_PROVIDER,
  DISAPPROVE_PROVIDER
} from "../../../_graphql/Mutations/mutations";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      perPage: 15,
      paginated: false,
      selectedItem: {}
    };
  },
  props: {
    providers: {
      default: Array
    }
  },
  computed: {
    ...mapGetters({
      currentPage: "currentProvidersApprovalPage"
    })
  },
  methods: {
    onPageChange(page) {
      this.$store.state.currentProvidersApprovalPage = page;
    },
    handleImageError(event) {
      event.target.style = "  box-shadow: unset";
    },
    approve(id) {
      this.$apollo
        .mutate({
          mutation: APPROVE_PROVIDER,
          variables: {
            id: parseInt(id)
          },
          error: ({ error }) => {
            this.$buefy.toast.open({
              message: error,
              type: "is-danger"
            });
            return error;
          }
        })
        .then(data => {
          if (data.data.approveProvider) {
            this.$buefy.toast.open({
              message: "Successfully approved!",
              type: "is-success"
            });
            this.$emit("approvedProvider");
          }
        })
        .catch(error => {
          return this.$buefy.toast.open({
            message: `${error}`,
            type: "is-danger"
          });
        });
    },
    confirmDisapprove(data) {
      this.$buefy.dialog.prompt({
        message: `What's the reason for provider's disapproval?`,
        inputAttrs: {
          placeholder: "Describe the reason for disapproval",
          min: 10,
          maxlength: 300
        },
        trapFocus: true,
        onConfirm: value => this.disapprove({ id: data.id, reason: value })
      });
    },
    disapprove(data) {
      if (!data.reason) {
        return this.$buefy.toast.open({
          message: "Reason for provider's disapproval is required!",
          type: "is-danger"
        });
      }
      this.$apollo
        .mutate({
          mutation: DISAPPROVE_PROVIDER,
          variables: {
            id: parseInt(data.id),
            reason: data.reason
          },
          error: ({ error }) => {
            this.$buefy.toast.open({
              message: error,
              type: "is-danger"
            });
            return error;
          }
        })
        .then(data => {
          if (data.data.disapproveProvider) {
            this.$buefy.toast.open({
              message: "Successfully disapproved!",
              type: "is-success"
            });
            this.$emit("disapprovedProvider");
          }
        })
        .catch(error => {
          this.$buefy.toast.open({
            message: `${error}`,
            type: "is-danger"
          });
        });
    }
  },
  mounted() {
    if (this.providers && this.providers.length > this.perPage) {
      this.paginated = true;
    }
  }
};
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 769px) {
  #actions {
    width: 40% !important;
  }
}
.container {
  width: 100px;
  height: 100px;
}
#avatar {
  width: 100px;
  height: 100px;
  object-fit: contain;
  border-radius: 50%;
  text-align: center;
}
img {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.09);
}
</style>
