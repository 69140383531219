import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import { apolloProvider } from "./vue-apollo";
import VueApollo from "vue-apollo";
import Breadcrumbs from "./modules/treatments/components/Breadcrumbs";
require("./validationRules");
import VueTelInput from "vue-tel-input";
import { Datetime } from "vue-datetime";
// You need a specific loader for CSS files
import "vue-datetime/dist/vue-datetime.css";
/* Styles */
import "./scss/main.scss";
import "@mdi/font/css/materialdesignicons.css";
Vue.component("Breadcrumbs", Breadcrumbs);
import VueTagsInput from "@johmun/vue-tags-input";
import VueIntercom from "vue-intercom";
import Hotjar from "vue-hotjar";

/* Core */
import Buefy from "buefy";

/* Vue. Component in recursion */
import AsideMenuList from "./modules/base/components/AsideMenuList";
import GraphQLHelper from "./mixins/GraphQLHelper";
import ApolloLoader from "./modules/base/components/ApolloLoader";
import RouteParamsGetters from "./mixins/RouteParamsGetters";
import CKEditor from "@ckeditor/ckeditor5-vue";

// import AgoraRTC from "agora-rtc-sdk";
// Vue.use(AgoraRTC);

/* Collapse mobile aside menu on route change */
router.afterEach(() => {
  store.commit("asideMobileStateToggle", false);
});
Vue.config.productionTip = false;
Vue.mixin(GraphQLHelper);
Vue.mixin(RouteParamsGetters);
Vue.component("AsideMenuList", AsideMenuList);
Vue.component("ApolloLoader", ApolloLoader);
Vue.use(Buefy);
Vue.use(VueApollo);
Vue.use(VueTelInput);
Vue.use(Datetime);
Vue.use(CKEditor);
Vue.use(VueTagsInput);
Vue.use(VueIntercom, { appId: process.env.VUE_APP_INTERCOM_ID });
Vue.use(Hotjar, {
  id: "2430749" // Hotjar Site ID
});

new Vue({
  router,
  store,
  apolloProvider,
  render: h => h(App)
}).$mount("#app");
