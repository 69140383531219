<template>
  <div class="treatments-holder">
    <h5 class="subtitle">
      {{ title }}
      <br /><sub>{{ subtitle }}</sub>
    </h5>
    <!--<div class="bordered" v-if="type === 'regular'">
      <h4 class="subtitle">
        <i class="fa fa-search" />
        Search
      </h4>
      <input
        v-model="filterString"
        placeholder="Find a treatment from here"
        class="input"
        type="text"
      />
    </div>-->
    <div class="columns is-multiline">
      <div
        :class="[type === 'custom' ? 'is-11' : 'is-5']"
        class="bordered is-clearfix treatment-item column is-left"
        :key="treatment.id"
        v-for="treatment in sortedProviderTreatments"
      >
        <div class="columns">
          <div class="column treatment-name is-9">
            {{ treatment.name }}
          </div>
          <div class="column has-text-right is-centered is-clearfix is-3">
            <button
              @click="$emit('select', treatment, type)"
              class="button is-primary"
              style="margin-right:5px;"
            >
              add &raquo;
            </button>
            <button
              @click="$emit('remove', treatment, 'custom')"
              class="button"
              v-if="type === 'custom'"
              style="margin-right:5px"
            >
              <i class="fa fa-trash"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "treatments-list",
  props: ["sortedProviderTreatments", "title", "subtitle", "type"]
};
</script>
<style scoped>
.treatment-name {
  word-break: break-word;
}
.treatments-holder {
  max-height: 500px;
  min-height: 230px;
  overflow-y: scroll;
  overflow-x: hidden;
  margin: 10px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px 0;
}
.treatment-item {
  /*width: 46% !important;*/
  align-items: flex-start;
  box-shadow: 0px 0px 2px 1px #0ba1d1;
}
.treatment-item .columns {
  width: 100%;
}
</style>
