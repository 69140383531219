<template>
  <section class="section">
    <div class="card-content">
      <div class="is-title-bar">
        <div class="level">
          <div class="level-left">
            <div class="level-item">
              <h1 class="title">
                Areas
              </h1>
            </div>
          </div>
        </div>
      </div>
      <data-table
        v-if="areas"
        :data="areas.data"
        class="has-table"
        :current-page="currentPage"
        pageName="currentAreasPage"
      ></data-table>
    </div>
  </section>
</template>

<script>
import DataTable from "../components/DataTable";
import { ALL_AREAS } from "../../../_graphql/Queries/queries";
import LoadingState from "../../../mixins/LoadingState";
import SubscribeForRefetch from "../../../mixins/SubscribeForRefetch";
import { mapGetters } from "vuex";

export default {
  apollo: {
    areas: {
      query: ALL_AREAS,
      variables: {
        input: {
          order: "id:asc"
        },
        update: data => data.areas
      }
    }
  },
  computed: {
    ...mapGetters({
      currentPage: "currentAreasPage"
    })
  },
  mixins: [LoadingState, SubscribeForRefetch],
  data() {
    return {
      all: ALL_AREAS,
      isActive: false
    };
  },
  components: {
    DataTable
  }
};
</script>
