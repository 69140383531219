<template>
  <section class="section">
    <div v-if="showNewPayment">
      <div class="is-title-bar">
        <div class="level">
          <div class="level-left">
            <div class="level-item">
              <h1 class="title">Payouts</h1>
            </div>
          </div>
        </div>
      </div>
      <EnablePayment v-if="showEnablePayment"></EnablePayment>
      <PaymentConfigure v-if="showPaymentConfigure"></PaymentConfigure>
      <VisitDashboard v-if="showVisitDashboard"></VisitDashboard>
    </div>
    <div>
      <div class="is-title-bar">
        <div class="level">
          <div class="level-left">
            <div class="level-item">
              <h1 class="title">Payments</h1>
            </div>
          </div>
          <div class="level-right">
            <div class="level-item">
              <b-button @click="exportPayments" class="is-primary"
                >Export</b-button
              >
            </div>
          </div>
        </div>
      </div>
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <DropdownFilter
              input-placeholder="Min 3 chars"
              class="custom-dropdown"
              title="Filters"
              :items="
                filterDropdownItems.filter(
                  item =>
                    (isVendor && item.name !== 'pr.name') ||
                    (isAdmin && item.name !== 'customer_name')
                )
              "
              :filters="filters"
              :inputFilters="inputFilters"
              @toggleFilter="toggleFilter"
            />
          </div>
        </div>
      </div>
      <PaymentsTable
        :table-data="payments"
        v-if="payments"
        :meta="payments.meta"
        @pageChanged="onPageChange"
      ></PaymentsTable>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
import { mapGetters } from "vuex";
import EnablePayment from "../components/EnablePayment";
import PaymentConfigure from "../components/PaymentConfigure";
import VisitDashboard from "../components/VisitDashboard";
import PlanStripeConnectHelper from "@/mixins/PlanStripeConnectHelper";
import PaymentsTable from "../components/PaymentsTable";
import {
  EXPORT_PAYMENTS_FILE,
  PAYMENTS,
  MY_PROVIDERS
} from "../../../_graphql/Queries/queries";
import DropdownFilter from "../../base/components/DropdownFilter";

export default {
  name: "payments",
  mixins: [PlanStripeConnectHelper],
  components: {
    EnablePayment,
    PaymentConfigure,
    VisitDashboard,
    PaymentsTable,
    DropdownFilter
  },
  data() {
    return {
      filterDropdownItems: [
        {
          name: "pr.name",
          title: "Provider",
          type: "input"
        },
        {
          name: "customer_name",
          title: "Customer Name",
          type: "input"
        },
        {
          name: "p.status",
          title: "Payment Status",
          type: "options",
          options: [
            {
              value: "PENDING",
              title: "Incomplete"
            },
            {
              value: "SUCCEEDED",
              title: "Succeeded"
            },
            {
              value: "FAILED",
              title: "Failed"
            }
          ]
        },
        {
          name: "p.payout_status",
          title: "Payout Status",
          type: "options",
          options: [
            {
              value: null,
              title: "(None)"
            },
            {
              value: "ON HOLD",
              title: "Pending"
            },
            {
              value: "DELAYED",
              title: "Delayed"
            },
            {
              value: "PAID",
              title: "Paid"
            },
            {
              value: "PAYABLE",
              title: "Payable"
            },
            {
              value: "CANCELLED",
              title: "Cancelled"
            }
          ]
        },
        {
          name: "p.description",
          title: "Subject",
          type: "input"
        },
        {
          name: "p.payin_id",
          title: "Reference",
          type: "input"
        }
      ],
      inputFilters: {
        "pr.name": "",
        customer_name: "",
        "p.description": "",
        "p.payin_id": ""
      },
      filters: {
        "pr.name": "",
        customer_name: "",
        "p.status": "",
        "p.payout_status": "",
        "p.description": "",
        "p.payin_id": ""
      },
      queryParams: {
        limit: 25,
        offset: 0,
        query: "",
        filter: {},
        order: ["p.id:desc"]
      },
      showEnablePayment: false,
      showPaymentConfigure: false,
      showVisitDashboard: false,
      providerPayment: null,
      showNewPayment: false
    };
  },
  created() {
    this.$apollo
      .query({
        query: MY_PROVIDERS
      })
      .then(data => {
        const myProviders = data.data.myProviders.data;
        if (myProviders.length == 0 || this.meUser.user.role != "VENDOR") {
          this.showNewPayment = false;
        }
        else {
          this.getStripeConnectUrl(myProviders[0]["id"], 0, false).then(
            ({ data: { getStripeConnectUrl } }) => {
              if (getStripeConnectUrl) {
                this.stripeConnectLink =
                  getStripeConnectUrl.data.stripe_connect_url;
                if (
                  getStripeConnectUrl.data.stripe_connect_url == "stripe_invalid"
                ) {
                  this.showNewPayment = false;
                } else {
                  this.showNewPayment = true;
                }
              }
            }
          );
          this.getStripeConnectStatus(myProviders[0]["id"]).then(
            ({ data: { providerStripeConnect } }) => {
              console.log(providerStripeConnect);
              if (providerStripeConnect == null) {
                this.showEnablePayment = true;
                this.showPaymentConfigure = false;
                this.showVisitDashboard = false;
                this.create(myProviders[0]["id"]);
              } else {
                this.providerPayment = providerStripeConnect;
                if (
                  this.providerPayment.connect_id &&
                  this.providerPayment.payouts_enabled &&
                  this.providerPayment.details_submitted
                ) {
                  this.showEnablePayment = false;
                  this.showPaymentConfigure = false;
                  this.showVisitDashboard = true;
                } else if (
                  this.providerPayment.connect_id &&
                  !this.providerPayment.payouts_enabled &&
                  !this.providerPayment.details_submitted
                ) {
                  this.showEnablePayment = true;
                  this.showPaymentConfigure = false;
                  this.showVisitDashboard = false;
                }else if (
                  this.providerPayment.connect_id &&
                  !this.providerPayment.payouts_enabled
                ) {
                  this.showEnablePayment = false;
                  this.showPaymentConfigure = true;
                  this.showVisitDashboard = false;
                } else if (
                  this.providerPayment.connect_id &&
                  !this.providerPayment.details_submitted
                ) {
                  this.showEnablePayment = false;
                  this.showPaymentConfigure = true;
                  this.showVisitDashboard = false;
                } else {
                  this.showEnablePayment = true;
                  this.showPaymentConfigure = false;
                  this.showVisitDashboard = false;
                }
              }
            }
          );
        }
      });
  },
  computed: {
    ...mapState(["meUser"]),
    ...mapGetters(["myProviders"]),
    getQueryParams() {
      return {
        limit: this.queryParams.limit,
        offset: this.queryParams.offset,
        order: this.queryParams.order.join(","),
        query: this.queryParams.query,
        filter: this.queryParams.filter
      };
    },
    ...mapGetters({
      userRole: "userRole"
    }),
    isVendor() {
      return this.userRole && this.userRole == "VENDOR";
    },
    isAdmin() {
      return this.userRole && this.userRole == "ADMINISTRATOR";
    }
  },
  mounted() {},
  watch: {
    filters: {
      handler(newVal) {
        let newFilter = {};
        let queryFilter = [];
        Object.keys(newVal).forEach(item => {
          if (newVal[item] !== "") {
            if (item === "customer_name") {
              if (newVal[item].length >= 3) {
                newFilter.customer_name = newVal[item];
              }
            } else if (item === "p.payin_id") {
              if (newVal[item].length >= 3) {
                queryFilter.push("p.payin_id%" + newVal[item]);
              }
            } else if (item === "p.status") {
              queryFilter.push("p.status:" + newVal[item]);
            } else if (item === "p.payout_status") {
              queryFilter.push("p.payout_status:" + newVal[item]);
            } else if (item === "p.description") {
              if (newVal[item].length >= 3) {
                queryFilter.push("p.description%" + newVal[item]);
              }
            } else if (item === "p.user_email") {
              if (newVal[item].length >= 3) {
                queryFilter.push("p.user_email%" + newVal[item]);
              }
            } else if (item === "pr.name") {
              if (newVal[item].length >= 3) {
                queryFilter.push("pr.name%" + newVal[item]);
              }
            }
          }
        });

        this.queryParams.filter = newFilter;
        this.queryParams.query = queryFilter.join(",");
      },
      deep: true
    }
  },
  methods: {
    onPageChange(offset) {
      this.queryParams.offset = offset;
    },
    toggleFilter(payload) {
      const filterName = payload.filterName;
      const value = payload.optionValue;
      this.filters[filterName] =
        this.filters[filterName] != ""
          ? this.filters[filterName] == value
            ? ""
            : value
          : value;
    },
    async exportPayments() {
      if (this.payments.data.length == 0) {
        return this.$buefy.toast.open({
          message: "There is no data in the table",
          type: "is-danger",
          duration: 3000
        });
      }
      await this.$apollo
        .query({
          query: EXPORT_PAYMENTS_FILE,
          variables: {
            input: {
              query: this.getQueryParams.query
            }
          },
          update: data => data
        })
        .catch(error => {
          return this.$buefy.toast.open({
            message: `${error}`,
            type: "is-danger"
          });
        })
        .then(({ data: { exportPaymentsFile } }) => {
          if (exportPaymentsFile) {
            this.exportLink = exportPaymentsFile.data.url;
            const link = document.createElement("a");
            link.setAttribute("href", this.exportLink);
            link.click();
          }
        });
    }
  },
  apollo: {
    payments: {
      query: PAYMENTS,
      update: data => data.payments,
      variables() {
        return {
          input: this.getQueryParams
        };
      }
    }
  }
};
</script>
