<template>
  <div>
    <div>
      <img src="../../../assets/img/success_paid_icon.png" alt="Success icon" />
    </div>
    <h1 class="title has-text-primary" v-if="providerPayment">
      You bought {{ providerPayment.payment_product_item.units }} minutes
      successfully!
    </h1>
    <router-link class="button is-primary" to="/messages/active"
      >Continue to Inbox</router-link
    >
  </div>
</template>

<script>
import { PROVIDER_PAYMENT } from "../../../_graphql/Queries/queries";

export default {
  data() {
    return {
      session_id: null
    };
  },
  mounted() {
    if (this.$route.query.session_id) {
      this.session_id = this.$route.query.session_id;
    }
  },
  apollo: {
    providerPayment: {
      query: PROVIDER_PAYMENT,
      variables() {
        return {
          session_id: this.session_id
        };
      },
      skip() {
        return !this.session_id;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
img {
  width: 10rem;
  margin-top: 3rem;
}

h1 {
  margin-top: 2rem;
}

.button {
  margin-top: 3rem;
}
</style>
