var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"userFormValidator",staticClass:"userForm"},[_c('div',{staticClass:"tile is-ancestor"},[_c('section',{staticClass:"tile is-parent is-6 is-vertical"},[_c('div',{staticClass:"columns",staticStyle:{"flex-wrap":"wrap"}},[_c('ValidationProvider',{staticClass:"is-5 column",attrs:{"rules":"required|min:2","name":"First Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"type":{ 'is-danger': errors[0], 'is-primary': valid },"label":"First Name"}},[_c('b-input',{attrs:{"trim":""},model:{value:(_vm.form.firstName),callback:function ($$v) {_vm.$set(_vm.form, "firstName", $$v)},expression:"form.firstName"}})],1),_vm._l((errors),function(error){return _c('span',{key:error.id,staticClass:"has-text-danger help"},[_vm._v(_vm._s(error))])})]}}])}),_c('ValidationProvider',{staticClass:"is-5 is-offset-1 column",attrs:{"rules":"required|min:2","name":"Last Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"type":{ 'is-danger': errors[0], 'is-primary': valid },"label":"Last Name"}},[_c('b-input',{attrs:{"trim":""},model:{value:(_vm.form.lastName),callback:function ($$v) {_vm.$set(_vm.form, "lastName", $$v)},expression:"form.lastName"}})],1),_vm._l((errors),function(error){return _c('span',{key:error.id,staticClass:"has-text-danger help"},[_vm._v(_vm._s(error))])})]}}])}),_c('ValidationProvider',{staticClass:"is-5 column",attrs:{"rules":"required|email","name":"Email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"type":{ 'is-danger': errors[0], 'is-primary': valid },"label":"Email"}},[_c('b-input',{attrs:{"trim":""},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1),_vm._l((errors),function(error){return _c('span',{key:error.id,staticClass:"has-text-danger help"},[_vm._v(_vm._s(error))])})]}}])}),_c('ValidationProvider',{staticClass:"is-5 is-offset-1 column",attrs:{"rules":{
            required: !_vm.editModel,
            passRegex: /^(?=.*[A-Za-z!@#$%^&*])(?=.*\d)[A-Za-z!@#$%^&*\d]{8,}$/
          },"name":"Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
          var valid = ref.valid;
return [_c('b-field',{attrs:{"type":{ 'is-danger': errors[0], 'is-primary': valid },"label":_vm.editModel ? 'New Password' : 'Password'}},[_c('b-input',{attrs:{"type":"password","password-reveal":"","trim":""},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})],1),_vm._l((errors),function(error){return _c('span',{key:error.id,staticClass:"has-text-danger help"},[_vm._v(_vm._s(error))])})]}}])})],1)]),_c('div',{staticClass:"tile is-parent is-3 is-vertical"},[_c('div',{staticClass:"columns",staticStyle:{"flex-wrap":"wrap"}},[_c('ValidationProvider',{staticClass:"is-10 column",attrs:{"name":"Country"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
          var valid = ref.valid;
return [_c('b-field',{attrs:{"type":{ 'is-danger': errors[0], 'is-primary': valid },"label":"Country"}},[_c('b-input',{attrs:{"trim":""},model:{value:(_vm.form.country),callback:function ($$v) {_vm.$set(_vm.form, "country", $$v)},expression:"form.country"}})],1),_vm._l((errors),function(error){return _c('span',{key:error.id,staticClass:"has-text-danger help"},[_vm._v(_vm._s(error))])})]}}])}),_c('ValidationProvider',{staticClass:"is-10 column",attrs:{"name":"City"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
          var valid = ref.valid;
return [_c('b-field',{attrs:{"type":{ 'is-danger': errors[0], 'is-primary': valid },"label":"City"}},[_c('b-input',{attrs:{"trim":""},model:{value:(_vm.form.city),callback:function ($$v) {_vm.$set(_vm.form, "city", $$v)},expression:"form.city"}})],1),_vm._l((errors),function(error){return _c('span',{key:error.id,staticClass:"has-text-danger help"},[_vm._v(_vm._s(error))])})]}}])})],1)]),_c('div',{staticClass:"tile is-3 is-parent is-vertical",staticStyle:{"height":"200px","width":"200px"},attrs:{"id":"avatar"}},[_c('label',{staticClass:"label"},[_vm._v("Profile Avatar")]),_c('img',{staticClass:"avatarImage",attrs:{"src":this.form.avatar
            ? this.form.avatar
            : this.origin + '/img/icons/blank_avatar.png'}}),_c('div',{staticClass:"upload-btn-wrapper"},[_c('button',{staticClass:"button is-light avatar-button plus"},[_c('b-icon',{attrs:{"icon":"plus","size":"is-default"}})],1),_c('input',{ref:"avatarInput",attrs:{"type":"file","title":"Upload profile picture","name":"avatar"},on:{"input":_vm.uploadImage}})]),(this.form.avatar)?_c('button',{staticClass:"button is-light avatar-button trash-can",on:{"click":_vm.removeAvatar}},[_c('b-icon',{attrs:{"icon":"trash-alt","pack":"fas","size":"is-small"}})],1):_vm._e()])]),_c('div',{staticClass:"tile is-ancestor"},[_c('section',{staticClass:"tile is-parent is-6 is-vertical"},[_c('div',{staticClass:"columns",staticStyle:{"flex-wrap":"wrap"}},[_c('ValidationProvider',{staticClass:"is-5 column",attrs:{"name":"Role"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
            var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Role","type":{
              'is-danger': errors[0],
              'is-primary': valid
            }}},[_c('b-select',{attrs:{"expanded":""},model:{value:(_vm.form.role),callback:function ($$v) {_vm.$set(_vm.form, "role", $$v)},expression:"form.role"}},_vm._l((_vm.roles),function(role){return _c('option',{key:role.title,domProps:{"value":role.value}},[_vm._v(_vm._s(role.title))])}),0)],1),_vm._l((errors),function(error){return _c('span',{key:error.id,staticClass:"has-text-danger help"},[_vm._v(_vm._s(error))])})]}}])}),_c('ValidationProvider',{staticClass:"is-5 is-offset-1 column",attrs:{"name":"Age"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
            var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Age","type":{
              'is-danger': errors[0],
              'is-primary': valid
            }}},[_c('b-select',{attrs:{"expanded":""},model:{value:(_vm.form.age),callback:function ($$v) {_vm.$set(_vm.form, "age", $$v)},expression:"form.age"}},[_c('option',{domProps:{"value":null}},[_vm._v("None")]),_vm._l((100),function(number){return _c('option',{key:number,domProps:{"value":number}},[_vm._v(" "+_vm._s(number)+" ")])})],2)],1),_vm._l((errors),function(error){return _c('span',{key:error.id,staticClass:"has-text-danger help"},[_vm._v(_vm._s(error))])})]}}])})],1)]),_c('div',{staticClass:"tile is-parent is-3 is-vertical"},[_c('div',{staticClass:"columns",staticStyle:{"flex-wrap":"wrap"}},[_c('ValidationProvider',{staticClass:"is-10 column",attrs:{"name":"Gender"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
            var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Gender","type":{
              'is-danger': errors[0],
              'is-primary': valid
            }}},[_c('b-select',{attrs:{"expanded":""},model:{value:(_vm.form.gender),callback:function ($$v) {_vm.$set(_vm.form, "gender", $$v)},expression:"form.gender"}},_vm._l((_vm.genders),function(gender){return _c('option',{key:gender.title,domProps:{"value":gender.value}},[_vm._v(_vm._s(gender.title))])}),0)],1),_vm._l((errors),function(error){return _c('span',{key:error.id,staticClass:"has-text-danger help"},[_vm._v(_vm._s(error))])})]}}])})],1)])]),_c('div',{staticClass:"columns userFormSubmit"},[_c('div',{staticClass:"column is-offset-9"},[_c('b-button',{staticClass:"is-primary",attrs:{"loading":_vm.formSubmitTimeout},on:{"click":_vm.submitForm}},[_vm._v("Save")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }