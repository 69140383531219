import gql from "graphql-tag";

const USER = gql`
  query user($id: ID!) {
    user(id: $id) {
      code
      success
      errors
      user {
        _id
        firstName
        lastName
      }
    }
  }
`;

export default {
  USER
};
