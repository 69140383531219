<template>
  <section class="section">
    <div class="is-title-bar">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <h1 class="title">
              Solutions
            </h1>
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <router-link to="/solutions/create">
              <b-button class="is-primary">
                Add solution
              </b-button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="level is-mobile">
      <div class="level-left">
        <div class="level-item">
          <DropdownFilter
            input-placeholder="Min 3 chars"
            title="Filters"
            :items="basicFilterItems"
            :filters="filters"
            :inputFilters="inputFilters"
            @toggleFilter="toggleFilter"
          />
        </div>
      </div>
    </div>
    <solutions-table
      :solutions="solutions"
      :user-role="{ isOperator }"
      @deletedSolution="deletedSolution"
      @pageChanged="onPageChange"
    ></solutions-table>
  </section>
</template>

<script>
import DropdownFilter from "../../base/components/DropdownFilter";
import { SOLUTIONS } from "../../../_graphql/Queries/queries";
import { mapGetters } from "vuex";
import solutionsTable from "../components/solutionsTable";

export default {
  data() {
    return {
      basicFilterItems: [
        {
          name: "status",
          title: "Status",
          type: "options",
          options: [
            {
              value: "DRAFT",
              title: "Draft"
            },
            {
              value: "READY_FOR_REVIEW",
              title: "Ready for review"
            },
            {
              value: "NEEDS_CORRECTIONS",
              title: "Needs corrections"
            },
            {
              value: "REVIEWED",
              title: "Reviewed"
            }
          ]
        },
        {
          name: "live_status",
          title: "Live status",
          type: "options",
          options: [
            {
              value: "1",
              title: "Published"
            },
            {
              value: "0",
              title: "Not published"
            }
          ]
        },
        {
          name: "category",
          title: "Category",
          type: "options",
          options: [
            {
              value: "procedures",
              title: " Procedures"
            },
            {
              value: "technologies",
              title: " Technologies"
            },
            {
              value: "products",
              title: " Products"
            },
            {
              value: "provider-type",
              title: " Provider Type"
            }
          ]
        },
        {
          name: "title",
          title: "Title",
          type: "input"
        }
      ],
      filters: {
        status: "",
        live_status: "",
        category: "",
        title: ""
      },
      inputFilters: {
        title: ""
      },
      queryParams: {
        limit: 25,
        offset: 0,
        query: "",
        order: "s.date_added:desc"
      }
    };
  },
  components: {
    DropdownFilter,
    solutionsTable
  },
  computed: {
    ...mapGetters({
      meUser: "meUser",
      currentPage: "currentSolutionsPage"
    }),
    getQueryParams() {
      return {
        offset: this.currentPage
          ? 25 * (this.currentPage - 1)
          : this.queryParams.offset,
        limit: this.queryParams.limit,
        query: this.queryParams.query,
        order: this.queryParams.order
      };
    },
    isOperator() {
      return this.meUser && this.meUser.user.role == "OPERATOR";
    }
  },
  methods: {
    toggleFilter(payload) {
      const filterName = payload.filterName;
      const value = payload.optionValue;
      this.filters[filterName] =
        this.filters[filterName] != ""
          ? this.filters[filterName] == value
            ? ""
            : value
          : value;
    },
    deletedSolution() {
      this.$apollo.queries.solutions.refetch();
    },
    onPageChange(offset) {
      this.queryParams.offset = offset;
    }
  },
  watch: {
    filters: {
      handler(newVal) {
        let queryFilter = "";
        Object.keys(newVal).forEach(item => {
          if (newVal[item] != "") {
            if (item == "status") {
              queryFilter += ",s.status:" + newVal[item];
            } else if (item == "live_status") {
              queryFilter += ",s.is_published:" + newVal[item];
            } else if (item == "category") {
              queryFilter += ",sc.slug:" + newVal[item];
            } else if (item == "title") {
              if (newVal[item].length >= 3) {
                queryFilter += ",s.title%" + newVal[item];
              }
            }
          }
        });
        this.queryParams.query = queryFilter.startsWith(",")
          ? queryFilter.substring(1)
          : queryFilter;
      },
      deep: true
    }
  },
  mounted() {
    this.$apollo.queries.solutions.refetch();
  },
  apollo: {
    solutions: {
      query: SOLUTIONS,
      variables() {
        return {
          input: this.getQueryParams
        };
      }
    }
  }
};
</script>
