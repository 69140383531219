<template>
  <div></div>
</template>

<script>
import {ME, MY_PROVIDERS} from "../../../_graphql/Queries/queries";
import { TRANSFER_PROVIDER } from "../../../_graphql/Mutations/mutations";
import { onLogin, onLogout } from "../../../vue-apollo";
import _ from "lodash";

export default {
  data() {
    return {
      token: ""
    };
  },
  created() {
    onLogout(this.$apollo.provider.defaultClient, false, this.$store);
    if (this.$route.query.AUTH_TOKEN) {
      this.token = this.$route.query.AUTH_TOKEN;
      onLogin(this.$apollo.provider.defaultClient, this.token).then(() => {
        this.$apollo
          .query({
            query: ME
          })
          .then(result => {
            if (result && result.data.me.user !== null) {
              let userRole = result.data.me.user.role;
              this.$store.commit("setUser", result.data.me.user);
              let cookies = document.cookie.split(";");
              let transferToken = null;
              let errors = null;
              if (cookies) {
                cookies.forEach(cookie => {
                  let cookieData = cookie.split("=");
                  if (cookieData[0].includes("transfer_provider")) {
                    if (userRole == "VENDOR") {
                      transferToken = cookieData[1];
                      this.$apollo
                        .mutate({
                          mutation: TRANSFER_PROVIDER,
                          variables: {
                            token: transferToken
                          }
                        })
                        .catch(error => {
                          errors = error;
                          this.$buefy.toast.open({
                            message: `${error}`,
                            type: "is-danger",
                            duration: 3000
                          });
                        });
                    }
                    let date = new Date();
                    document.cookie =
                      "transfer_provider=; domain=.metime.com; expires=" +
                      date.toUTCString() +
                      "; path=/;";
                  }
                });
              }
              if (userRole === "VENDOR") {
                this.$apollo.query({
                  query: MY_PROVIDERS,
                }).then(data => {
                  const myProviders = _.get(data, 'data.myProviders.data', []);
                  this.$store.commit("setMyProviders", myProviders);
                })
              }
              setTimeout(
                () => {
                  userRole == "ADMINISTRATOR" || userRole == "VENDOR"
                    ? this.$router.push("/dashboard")
                    : this.$router.push("/providers/guest");
                },
                errors ? 4000 : 0
              );
            } else {
              onLogout(this.$apollo.provider.defaultClient, true, this.$store);
            }
          })
          .catch(error => {
            // Error
            this.$buefy.toast.open({
              message: error,
              type: "is-danger"
            });
            onLogout(this.$apollo.provider.defaultClient, true, this.$store);
            // We restore the initial user input
          });
      });
    } else {
      onLogout(this.$apollo.provider.defaultClient, true, this.$store);
    }
  }
};
</script>
