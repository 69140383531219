<template>
  <section class="section">
    <div class="is-title-bar">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <h1 class="title">
              Approve / Disapprove Providers
            </h1>
          </div>
        </div>
      </div>
    </div>
    <ProvidersApprovalTable
      v-if="providers"
      :providers="providers.data"
      @approvedProvider="updateProvidersList"
      @disapprovedProvider="updateProvidersList"
    ></ProvidersApprovalTable>
  </section>
</template>

<script>
import ProvidersApprovalTable from "../components/ProvidersApprovalTable";
import { PROVIDERS } from "../../../_graphql/Queries/queries";

export default {
  components: { ProvidersApprovalTable },
  apollo: {
    providers: {
      query: PROVIDERS,
      variables: {
        input: {
          query: "status:0,is_guest:0"
        }
      }
    }
  },
  methods: {
    updateProvidersList() {
      this.$apollo.queries.providers.refetch();
    }
  }
};
</script>
