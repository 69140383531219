<template>
  <div>
    <b-table
      v-if="solutions"
      :data="solutions.data"
      paginated
      backend-pagination
      :current-page="solutions.meta.current_page"
      :per-page="solutions.meta.per_page"
      :total="solutions.meta.total_count"
      :hoverable="true"
      @page-change="onPageChange"
      ><template slot-scope="data">
        <b-table-column label="Id">{{ data.row.id }}</b-table-column>
        <b-table-column label="Category">{{
          data.row.categories.category.name
        }}</b-table-column>
        <b-table-column label="Title">{{ data.row.title }}</b-table-column>
        <b-table-column label="Slug">{{ data.row.slug }}</b-table-column>
        <b-table-column label="Creator">{{
          data.row.user.first_name + " " + data.row.user.last_name
        }}</b-table-column>
        <b-table-column label="Created">{{
          data.row.date_added
        }}</b-table-column>
        <b-table-column label="Updated">{{
          data.row.published_date
        }}</b-table-column>
        <b-table-column label="Status">{{
          statusName(data.row.status)
        }}</b-table-column>
        <b-table-column label="Published">{{
          data.row.is_published ? "Published" : "Not published"
        }}</b-table-column>
        <b-table-column
          ><div class="buttons is-right">
            <router-link :to="'/solutions/edit/' + data.row.id">
              <b-button class="is-primary is-outlined">{{
                isMySolution(data.row) && allowEdit(data.row)
                  ? "View/Edit"
                  : "View"
              }}</b-button>
            </router-link>
            <b-button
              v-if="isMySolution(data.row) && allowEdit(data.row)"
              class="is-danger is-outlined"
              @click="confirmDelete(data.row)"
              >Delete</b-button
            >
          </div></b-table-column
        >
      </template>
      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>
              <b-icon icon="emoticon-sad" size="is-large"> </b-icon>
            </p>
            <p>Nothing here.</p>
          </div>
        </section>
      </template>
    </b-table>
  </div>
</template>

<script>
import { DELETE_SOLUTION } from "../../../_graphql/Mutations/mutations";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      statuses: [
        {
          value: "DRAFT",
          name: "Draft"
        },
        {
          value: "READY_FOR_REVIEW",
          name: "Ready for review"
        },
        {
          value: "NEEDS_CORRECTIONS",
          name: "Needs corrections"
        },
        {
          value: "REVIEWED",
          name: "Reviewed"
        }
      ]
    };
  },
  props: ["solutions", "userRole"],
  computed: {
    ...mapGetters(["meUser"])
  },
  methods: {
    statusName(value) {
      let status = this.statuses.find(s => s.value == value);
      return status.name;
    },
    isMySolution(data) {
      return (
        !this.userRole.isOperator ||
        (this.userRole.isOperator &&
          this.meUser &&
          data.user.auth_id == this.meUser.user._id)
      );
    },
    allowEdit(data) {
      console.log(this.userRole.isOperator);
      return (
        this.userRole &&
        (!this.userRole.isOperator ||
          (this.userRole.isOperator && data.status !== "REVIEWED"))
      );
    },
    confirmDelete(item) {
      this.$buefy.dialog.confirm({
        title: "Deleting ",
        message:
          "Are you sure you want to <b>delete</b> this solution?" +
          " This action cannot be undone.",
        confirmText: "Delete",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.deleteSolution(item)
      });
    },
    deleteSolution(item) {
      this.$apollo
        .mutate({
          mutation: DELETE_SOLUTION,
          variables: {
            id: parseInt(item.id)
          }
        })
        .then(({ data: { deleteSolution } }) => {
          if (deleteSolution) {
            this.$buefy.toast.open({
              message: "Solution deleted successfully",
              type: "is-success"
            });
            this.$emit("deletedSolution");
          }
        });
    },
    onPageChange(page) {
      const offset = this.solutions.meta.per_page * (page - 1);
      this.$store.state.currentGuestProvidersPage = page;
      this.$emit("pageChanged", offset);
    }
  }
};
</script>
