<template>
  <div class="card">
    <header class="card-header">
      <p class="card-header-title has-text-primary">
        <b-icon icon="settings" />Settings
      </p>
    </header>
    <div class="columns is-multiline card-content">
      <span v-if="paymentsValue == '0'">
        <b-switch
          @input="updatePayments"
          :value="allow_payments"
          class="card-content switchClass has-text-left"
        >
          <strong>Allow this provider to request payments</strong>
        </b-switch>
        <p class="helpText">
          Turn on this option to enable requesting payments for this particular
          provider when the global payment request option is switched off.
        </p>
      </span>
      <span v-if="videocallsValue == '0'">
        <b-switch
          @input="updateVideocalls"
          :value="allow_videocalls"
          class="card-content switchClass has-text-left"
        >
          <strong> Allow this provider to request video calls</strong>
        </b-switch>
        <p class="helpText">
          Turn on this option to enable video calls for this particular provider
          when the global video call option is switched off.
        </p>
      </span>
    </div>
  </div>
</template>

<script>
import { UPDATE_PROVIDER_SETTINGS } from "../../../_graphql/Mutations/mutations";
export default {
  props: ["provider", "paymentsValue", "videocallsValue"],
  computed: {
    allow_payments() {
      return this.provider && this.provider.settings.allow_payments;
    },
    allow_videocalls() {
      return this.provider && this.provider.settings.allow_videocalls;
    }
  },
  methods: {
    updateVideocalls(value) {
      if (this.provider) {
        let variables = {
          id: this.provider.settings.id,
          input: {
            allow_videocalls: value
          }
        };
        this.updateProviderSettings(variables);
      }
    },
    updatePayments(value) {
      if (this.provider) {
        let variables = {
          id: this.provider.settings.id,
          input: {
            allow_payments: value
          }
        };
        this.updateProviderSettings(variables);
      }
    },
    updateProviderSettings(data) {
      this.$apollo.mutate({
        mutation: UPDATE_PROVIDER_SETTINGS,
        variables: data,
        update: (cache, { data }) => {
          if (data.updateProviderSettings) {
            this.$emit("updatedProviderSettings");
            this.$buefy.toast.open({
              message: "Provider settings updated successfully",
              type: "is-success"
            });
          }
        },
        error: ({ error }) => {
          this.$buefy.toast.open({
            message: error,
            type: "is-danger"
          });
        }
      });
    }
  }
};
</script>

<style scoped>
.switchClass {
  display: flex;
  padding-bottom: 0.5rem;
}
.helpText {
  margin-left: 4.625rem;
  margin-right: 1.75rem;
  padding-bottom: 1rem;
  text-align: justify;
}
</style>
