<template>
  <div class="card technologies">
    <header class="card-header has-background-customBackground">
      <div class="card-header-title">
        <p class="has-text-primary">
          <b-icon icon="account-multiple" custom-size="default" />
          Technologies
          <b-tooltip
              animated
              multilined
              class="tooltip"
              label="People seek specific treatments! List the technologies and devices available at your clinic. Suggestions appear as you type in each category. Select from the list or add your own new ones."
              position="is-top"
          ><b-icon icon="information-outline" type="is-primary"
          /></b-tooltip>
        </p>
      </div>
    </header>
    <div class="card-content has-text-left">
      <div
          v-if="technologyCategories.length > 0"
          class="card-content has-text-left"
      >
        <div
            class="tag-input"
            v-for="(category, index) in technologyCategories"
            :key="index"
        >
          <div>
            {{ category.name }}
          </div>
          <TagsInput
              :allowEditTags="allowUpdate"
              :categoryId="parseInt(category.id)"
              :placeholder="`Add new ${category.name === 'Other' ? 'technologies' : category.name}`"
              :itemTags="providerTechnologies[category.id] ? providerTechnologies[category.id].technologies : []"
              :autocompleteList="systemTechnologiesByCategory[category.id] ? systemTechnologiesByCategory[category.id].technologies : []"
              :updateItem="update"
              :createItem="create"
              :deleteItem="deleteTechnology"
          />
          <hr class="solid">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {TECHNOLOGIES, TECHNOLOGY_CATEGORIES} from "../../../_graphql/Queries/queries";
import {
  CREATE_TECHNOLOGY,
  DELETE_PROVIDER_TECHNOLOGY,
  DELETE_TECHNOLOGY,
  UPDATE_PROVIDER,
  UPDATE_TECHNOLOGY
} from "@/_graphql/Mutations/mutations";
import TagsInput from "@/modules/providers/components/TagsInput";

export default {
  data() {
    return {
      showDetailIcon: true,
      technologies: [],
      technologyCategories: [],
      systemTechnologiesByCategory: [],
    };
  },
  props: {
    allowUpdate: {
      default: false
    },
    provider: {
      required: false
    }
  },
  components: { TagsInput },
  computed: {
    providerTechnologies() {
      return this.groupByCategoryId(this.provider.technologies);
    },
    technology_ids() {
      if (this.provider) {
        return this.provider.technologies.map(technology => {
          return parseInt(technology.id);
        });
      }
      return [];
    },
    getFilter() {
      return {
        filter: {
          provider_id: this.provider.id
        }
      };
    }
  },
  methods: {
    async create(technology, categoryId, addTag) {
      const providerId = parseInt(this.$route.params.providerId);

      if (technology && technology.hasOwnProperty('provider_id') && technology.provider_id == null) {
        this.updateProviderTechnologies(
            providerId,
            parseInt(technology.id)
        ).then(() => {
          addTag();
          this.$emit("updatedTechnologies");
        });

        return;
      }

      const variables = {
        input: {
          provider_id: providerId,
          name: technology.text,
          technology_categories_id: categoryId
        }
      };

      await this.mutate(CREATE_TECHNOLOGY, variables)
        .then(({ data }) => {

          if (!data.createTechnology) {
            return;
          }

          addTag();
          this.$emit("updatedTechnologies");
          this.$buefy.toast.open({
            message: "Updated successfully!",
            type: "is-success"
          });
        })
        .catch(error => {
          this.$buefy.toast.open({
            message: `${error}`,
            type: "is-danger"
          });
        });
    },
    async updateProviderTechnologies(providerId, technologyId) {
      const createdTechnology = {
        technologies: [technologyId]
      };

      const payload = {
        mutation: UPDATE_PROVIDER,
        queryName: "updateProvider"
      };
      const variables = {
        id: providerId,
        input: createdTechnology
      };

      this.$store.commit("provider/collection/editModel", variables);
      await this.$store.dispatch("provider/collection/update", payload);
      this.$emit("updatedTechnologies")
    },
    async update(item, categoryId) {
      const payload = {
        mutation: UPDATE_TECHNOLOGY,
        queryName: "updateTechnology"
      };

      const variables = {
        id: parseInt(item.technology_id),
        input: {
          name: item.name,
          technology_categories_id: categoryId
        }
      };
      this.$store.commit("provider/collection/editModel", variables);
      await this.$store.dispatch("provider/collection/update", payload);
      this.$emit("updatedTechnologies");
    },
    deleteTechnology(item) {
      if (item.isSystemOwned) {
        const providerTechnologyId = parseInt(item.id);
        this.deleteSystemTechnology(providerTechnologyId);
      } else {
        this.deleteCustomTechnology(item);
      }
    },
    async deleteSystemTechnology(providerTechnologyId) {
      const mutation = DELETE_PROVIDER_TECHNOLOGY;
      const variables = {
        id: providerTechnologyId
      };
      this.$store.commit("provider/collection/deleteModel", variables);
      await this.$store.dispatch("provider/collection/delete", mutation);
    },
    async deleteCustomTechnology(item) {
      const mutation = DELETE_TECHNOLOGY;
      const variables = {
        id: parseInt(item.technology_id)
      };
      this.$store.commit("provider/collection/deleteModel", variables);
      await this.$store.dispatch("provider/collection/delete", mutation);
    },
    groupByCategoryId(technologies) {
      return technologies.reduce((objectsByKeyValue, obj) => {
        const value = obj["technology_categories_id"];

        objectsByKeyValue[value] = {
          id: obj.technology_categories_id,
          technologies: (objectsByKeyValue[value]
                  ? objectsByKeyValue[value].technologies
                  : []
          ).concat(obj)
        };

        return objectsByKeyValue;
      }, []);
    },
  },
  apollo: {
    technologies: {
      query: TECHNOLOGIES,
      update: data => data.technologies,
      variables() {
        return {
          input: this.getFilter
        };
      },
      result(result) {
        const systemTechnologies = result.data.technologies.data.filter(technology => technology.provider_id === null)
        this.systemTechnologiesByCategory = this.groupByCategoryId(systemTechnologies);
      }
    },
    technologyCategories: {
      query: TECHNOLOGY_CATEGORIES,
      variables: {
        update: data => data.technologyCategories
      },
      result(result) {
        const sortedArray = [
          'Skin rejuvenation & tightening',
          'Lasers & lights',
          'Body contouring devices',
          'Liposuction technologies',
          'Hair restoration technologies',
          'Imaging systems',
          'Dental',
          'Other'
        ];

        this.technologyCategories = result.data.technologyCategories.data.map(category => {
          if (category.name === 'Lasers & Lights') {
            category.name = 'Lasers & lights';
            return category;
          }
          return category;
        }).sort((a, b) => {
          return sortedArray.indexOf(a.name) - sortedArray.indexOf(b.name);
        });
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.technologies {
  .card-header-title {
    justify-content: space-between;
  }

  .b-tooltip {
    vertical-align: middle;
  }

  .custom-icon {
    width: 34px;
  }
}
</style>
