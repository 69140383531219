<template>
  <div class="wrapper">
    <ValidationProvider
      rules="required|max:60"
      v-slot="{ errors, valid }"
      name="Greeting"
    >
      <b-field :type="{ 'is-danger': errors[0] }">
        <b-input
          :readonly="!data.allowUpdate"
          type="text"
          placeholder="Greeting"
          v-model="greeting"
          maxlength="60"
        />
      </b-field>
      <span
        v-for="error in errors"
        :key="error.id"
        class="has-text-danger help"
        >{{ error }}</span
      >
    </ValidationProvider>
    <div>
      I’m happy to let you know that we have prepared your Profile for the
      MeTime listing at <a href="https://metime.com/">www.metime.com</a>. This
      profile will not be published on the website and you will not be available
      to match with patients until you <strong>approve</strong>. But you are
      free to approve your profile and be listed or reject it. Simply click the
      link below to review our suggested profile for you. You can easily edit
      the details on your profile later.
    </div>
    <div class="links">
      <a :href="data.profile_url">VIEW MY PROFILE</a>
    </div>
    <div>
      Listing your profile on MeTime and registering as a Provider is
      <strong>free</strong>. Click the button below to approve and
      <strong>sign in</strong> to your own dashboard where you can access your
      profile. You will also then be available to match with patients who use
      the MeTime app.
    </div>
    <div class="links">
      <a :href="loginLink">APPROVE AND LOG-IN</a>
    </div>

    <div>
      We’re determined to help you get the most out of the incredible MeTime
      features. When you start using MeTime you will be able to review patient
      requests and photos, start a chat or video consult with them, make
      recommendations, request payments and arrange bookings.
    </div>
    <div>
      Once you’re on board,
      <a href="mailto:Rocio.v@metime.com" style="color: #0a85ad"
        >book a DEMO with me</a
      >
      so I can go through all of the features of MeTime in a little more detail
      and show you how you can interact remotely and effectively with new and
      existing patients.
    </div>
    <div>
      If you have any questions in the meantime, please let me know.
    </div>
    <div>Regards,</div>
    <div class="signature">
      <h1 class="name">Rocio Varela</h1>
      <div>
        <div>Business Support Manager | MeTime Corporation</div>
        <div>Phone: +34 626421059</div>
        <div>Land: +35312343709</div>
        <div>5 Fitzwilliam Square East, Dublin 2, Ireland</div>
      </div>
      <a href="https://metime.com/" class="logo-link">
        <img
          class="logo"
          src="https://api.metime.com/assets/email/email-template-logo.jpg"
          alt="MeTime logo"
        />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      greeting: ""
    };
  },
  props: ["data", "allowUpdate"],
  computed: {
    loginLink() {
      return process.env.VUE_APP_AUTH_URL
        ? process.env.VUE_APP_AUTH_URL
        : "https://id.metime.com/";
    }
  },
  mounted() {
    if (this.data) {
      this.greeting = `Hi ${this.data.name},`;
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  font-family: "Montserrat", DejaVu Sans, Verdana, "sans-serif";
  text-align: left;
  padding-left: 0;
  padding-right: 0;

  div {
    margin: 1rem 0;
  }

  ol {
    margin-left: 3rem !important;
  }

  .links {
    text-align: center;
    margin: 2rem 0;

    a {
      padding: 8px;
      color: #0a85ad;

      &:hover {
        color: #0a6f93;
      }
    }
  }

  .signature {
    color: #0a85ad;
    margin-top: 2.5rem;

    .name {
      font-weight: bold;
      font-size: 18px;
    }

    div {
      margin: 0;
    }

    .logo-link {
      &:hover {
        cursor: pointer;
      }

      .logo {
        width: 160px;
        height: 80px;
        object-fit: cover;
      }
    }
  }
}
</style>
