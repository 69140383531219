<template>
  <div class="custom-window-style">
    <div v-if="hasVideocallSeconds">
      <div v-if="showCallingWindow">
        <div v-if="videoCallPermission && videoCallPermission.allowed">
          <b-field v-if="journeyProvider">
            <img
              v-if="hasBuyerAvatar"
              :src="journeyProvider.journey.user.authUser.avatar"
              class="is-rounded calling-avatar"
            />
            <b-icon v-else class="fas fa-user has-text-primary"></b-icon>
          </b-field>
          <b-field class="custom-field-style">
            <div class="title">{{ user }}</div>
          </b-field>
          <b-field class="custom-field-style">
            <div class="subtitle" :class="{ loading: !isCanceled }">
              {{ callingMessage }}
            </div>
          </b-field>
          <b-field class="custom-field-style">
            <b-button
              icon-right="phone-hangup"
              class="is-danger"
              size="is-medium"
              :disabled="disableBtn"
              @click="closeVideoCall"
            >
            </b-button>
          </b-field>
        </div>
        <div v-else-if="videoCallPermission && !videoCallPermission.allowed">
          You don't have permission to make a video call
        </div>
      </div>
      <div v-else>
        <div class="header">
          <div v-if="journeyProvider">{{ videocallTime }}</div>
        </div>
        <div id="remote-buyer" :style="`height: ${videoBoxHeight}px;`">
          <div>
            <b-notification
              id="browser-info"
              aria-close-label="Close notification"
              type="is-primary"
            >
              If you're using Firefox or Safari you might not be able to hear
              the customer talking. If you have this problem, check your
              browser's Auto-Play settings
            </b-notification>
          </div>

          <span
            v-if="showRemoteBuyerInfo"
            style="position: absolute; z-index: 5; align-items: center; background-color: rgba(255, 255, 255, 0.8); border-radius: 15px; padding-right: 1rem; bottom: 120px; margin-left: 1rem; box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.04);"
            class="is-flex"
          >
            <b-icon
              :icon="remoteBuyerIcon"
              style="width: 3rem !important;; height: 3rem !important;"
            ></b-icon>
            {{ remoteBuyerName }}</span
          >
          <div v-if="buyerMutedVideo" class="muted-video">
            <b-field v-if="journeyProvider">
              <img
                v-if="hasBuyerAvatar"
                :src="journeyProvider.journey.user.authUser.avatar"
                class="is-rounded"
                id="buyer-avatar"
              />
              <div v-else id="buyer-initials" class="is-uppercase">
                {{ buyerInitials }}
              </div>
            </b-field>
          </div>
        </div>
        <div id="me"></div>
        <div id="remote-users"></div>
        <div class="footer">
          <b-button
            class="is-danger"
            size="is-large"
            icon-right="phone"
            @click="leaveChannel"
          ></b-button>
          <b-button
            class="is-primary"
            size="is-large"
            :icon-right="videoIcon"
            @click="handleCamera"
          ></b-button>
          <b-button
            class="is-primary"
            size="is-large"
            :icon-right="micIcon"
            @click="handleMic"
          ></b-button>
          <b-button
            v-if="showInviteBtn"
            class="is-primary"
            size="is-large"
            icon-right="account-plus"
          ></b-button>
          <b-button
            class="is-primary"
            size="is-large"
            :icon-right="showMeIcon"
            @click="handleProviderVisibility"
          ></b-button>
        </div>
      </div>
    </div>
    <div v-else-if="journeyProvider">
      <h1 class="has-text-centered has-text-primary title is-3">
        It seems you have run out of minutes
      </h1>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapMutations } = createNamespacedHelpers("messages");
import queries from "../queries";
import { VIDEO_CALL_PERMISSION } from "../../../_graphql/Queries/queries";
import AgoraRTC from "agora-rtc-sdk";
import moment from "moment";

export default {
  data() {
    return {
      subscribeToProviderIsCalling: false,
      showInviteBtn: false,
      showCallingWindow: true,
      callingMessage: "",
      isCanceled: false,
      disableBtn: false,
      user: "",
      appId: "",
      channelId: "",
      token: "",
      callId: "",
      remoteBuyer: null,
      streamList: [],
      client: null,
      localStream: null,
      isVideoOn: true,
      isMicOn: true,
      showMe: true,
      videoBoxHeight: null,
      remoteBuyerIcon: "microphone",
      remoteBuyerName: "",
      showRemoteBuyerInfo: false,
      buyerMutedVideo: false,
      videocallTimer: null,
      videocallTime: null,
      hasVideocallSeconds: false
    };
  },
  watch: {
    isVideoCallAccepted(val) {
      if (val) {
        this.showCallingWindow = false;
      }
    }
  },
  computed: {
    ...mapGetters(["isVideoCallAccepted"]),
    hasBuyerAvatar() {
      return (
        this.journeyProvider &&
        this.journeyProvider.journey &&
        this.journeyProvider.journey.user.authUser.avatar
      );
    },
    videoIcon() {
      return this.isVideoOn ? "video" : "video-off";
    },
    micIcon() {
      return this.isMicOn ? "microphone" : "microphone-off";
    },
    showMeIcon() {
      return this.showMe ? "eye" : "eye-off";
    },
    buyerInitials() {
      return (
        this.journeyProvider.journey.user.authUser.firstName[0] +
        this.journeyProvider.journey.user.authUser.lastName[0]
      );
    }
  },
  mounted() {
    this.videoBoxHeight = window.innerHeight - 150;
    this.$nextTick(() => {
      window.addEventListener("resize", () => {
        this.videoBoxHeight = window.innerHeight - 150;
      });
    });
    setTimeout(() => {
      if (!this.isVideoCallAccepted) {
        this.closeVideoCall();
      }
    }, 40000);

    window.addEventListener("beforeunload", () => {
      this.closeVideoCall();
    });
  },
  methods: {
    ...mapMutations(["setVideoCallStatus"]),
    closeVideoCall() {
      this.setVideoCallStatus(false);
      this.callingMessage = "Call ended";
      this.isCanceled = true;
      this.disableBtn = true;
      if (this.$apollo.subscriptions.providerVideoCall) {
        this.$apollo.subscriptions.providerVideoCall.stop();
      }
      if (this.$apollo.subscriptions.providerIsCalling) {
        this.$apollo.subscriptions.providerIsCalling.stop();
      }
      window.clearInterval(this.videocallTimer);

      setTimeout(() => {
        window.close();
      }, 3000);
    },
    videoCallAccepted(meta) {
      if (meta) {
        this.callId = meta.callId;
        this.appId = meta.appId;
        this.channelId = meta.channelId;
        this.token = meta.token;
        this.startVideoCall();
      }
    },
    startVideoCall() {
      this.client = AgoraRTC.createClient({
        mode: "rtc",
        codec: "vp8"
      });
      this.client.init(this.appId);

      // Join a channel
      this.client.join(
        this.token,
        this.channelId,
        1,
        () => {
          this.subscribeToProviderIsCalling = true;
          let currentTimer = new Date();
          this.videocallTimer = setInterval(() => {
            this.videocallTime = moment(new Date() - currentTimer).format(
              "mm:ss"
            );
          }, 1000);

          // Create a local stream
          this.localStream = AgoraRTC.createStream({
            audio: true,
            video: true
          });

          // Initialize the local stream
          this.localStream.init(() => {
            this.localStream.play("me");
            this.client.publish(this.localStream, this.handleError);
          }, this.handleError);
        },
        this.handleError
      );

      // Subscribe to the remote stream when it is published
      this.client.on("stream-added", evt => {
        this.client.subscribe(evt.stream, this.handleError);
      });

      // Play the remote stream when it is subscribed
      this.client.on("stream-subscribed", evt => {
        let stream = evt.stream;
        let streamId = String(stream.getId());
        // this.streamList.push(stream);

        if (streamId == "2") {
          this.remoteBuyer = document.getElementById("remote-buyer");
          if (stream.hasAudio()) {
            this.remoteBuyerIcon = "microphone";
          } else {
            this.remoteBuyerIcon = "microphone-off";
          }

          this.remoteBuyerName = this.journeyProvider.journey.user.authUser.firstName;

          this.addVideoStream(streamId);
          stream.play(streamId);
          this.showRemoteBuyerInfo = true;
        }
      });

      // Occurs when the local stream is unpublished
      this.client.on("stream-unpublished", evt => {
        this.removeCorrespondingView(evt);
      });

      // Occurs when the remote stream is removed.
      // Remove the corresponding view when a remote user unpublishes.
      this.client.on("stream-removed", evt => {
        this.removeCorrespondingView(evt);
      });

      this.client.on("mute-audio", () => {
        this.remoteBuyerIcon = "microphone-off";
      });

      this.client.on("unmute-audio", () => {
        this.remoteBuyerIcon = "microphone";
      });

      this.client.on("mute-video", evt => {
        this.hideVideoStream(evt.uid);
        this.buyerMutedVideo = true;
      });

      this.client.on("unmute-video", evt => {
        this.buyerMutedVideo = false;
        this.showVideoStream(evt.uid);
      });

      // Remove the corresponding view when a remote user leaves the channel.
      this.client.on("peer-leave", evt => {
        this.leaveChannel();

        this.removeCorrespondingView(evt);
        this.showRemoteBuyerInfo = false;
        this.remoteBuyerName = "";
      });
    },
    handleError(err) {
      this.$buefy.toast.open({
        message: err,
        type: "is-danger"
      });
    },
    // Add video streams to the container.
    addVideoStream(elementId) {
      if (elementId == "2") {
        // Creates a new div for every stream
        let streamDiv = document.createElement("div");
        // Assigns the elementId to the div.
        streamDiv.id = elementId;
        streamDiv.setAttribute(
          "style",
          "transform: rotateY(180deg); position: relative; width: 100%;"
        );
        // Adds the div to the container.
        this.remoteBuyer.appendChild(streamDiv);
      }
    },
    leaveChannel() {
      // Leave channel
      this.client.unpublish(this.localStream, err => {
        console.log(err);
      });
      this.client.leave(
        () => {
          // Stop playing the local stream
          if (this.localStream.isPlaying()) {
            this.localStream.stop();
          }

          // Close the local stream
          this.localStream.close();
          this.client = null;
          this.localStream = null;
          this.showCallingWindow = true;
          this.closeVideoCall();
        },
        err => {
          console.log("Channel leave failed. ", err);
        }
      );
    },
    // Remove the video stream from the container.
    removeVideoStream(elementId) {
      let remoteDiv = document.getElementById(elementId);

      if (remoteDiv) {
        remoteDiv.parentNode.removeChild(remoteDiv);
      }
    },
    hideVideoStream(elementId) {
      let remoteDiv = document.getElementById(elementId);

      if (remoteDiv) {
        remoteDiv.style.display = "none";
      }
    },
    showVideoStream(elementId) {
      let remoteDiv = document.getElementById(elementId);

      if (remoteDiv) {
        remoteDiv.style.display = "block";
      }
    },
    removeCorrespondingView(evt) {
      let stream = evt.stream;
      let streamId = String(stream.getId());
      stream.close();
      this.removeVideoStream(streamId);
    },
    handleCamera() {
      let meDiv = document.getElementById("me");

      if (this.localStream.isVideoOn()) {
        this.localStream.disableVideo();
        this.isVideoOn = false;
        this.showMe = false;
        meDiv.style.visibility = "hidden";
      } else {
        this.localStream.enableVideo();
        this.isVideoOn = true;
        this.showMe = true;
        meDiv.style.visibility = "visible";
      }
    },
    handleMic() {
      if (this.localStream && this.localStream.isAudioOn()) {
        this.localStream.disableAudio();
        this.isMicOn = false;
      } else {
        this.localStream.enableAudio();
        this.isMicOn = true;
      }
    },
    handleProviderVisibility() {
      let meDiv = document.getElementById("me");
      if (meDiv && meDiv.style.visibility == "hidden") {
        meDiv.style.visibility = "visible";
        this.showMe = true;
      } else {
        meDiv.style.visibility = "hidden";
        this.showMe = false;
      }
    }
  },
  beforeDestroy() {
    if (this.$apollo.subscriptions.providerVideoCall) {
      this.$apollo.subscriptions.providerVideoCall.stop();
    }
    if (this.$apollo.subscriptions.providerIsCalling) {
      this.$apollo.subscriptions.providerIsCalling.stop();
    }
  },
  apollo: {
    journeyProvider: {
      query: queries.JOURNEY_PROVIDER,
      variables() {
        return {
          id: this.$route.params.journeyProviderId
        };
      },
      skip() {
        return !this.$route;
      },
      result() {
        this.callingMessage = "Calling";
        this.user =
          this.journeyProvider.journey.user.authUser.firstName +
          " " +
          this.journeyProvider.journey.user.authUser.lastName;
        let totalSeconds =
          this.journeyProvider.provider.videocall_free_seconds +
          this.journeyProvider.provider.videocall_paid_seconds;
        this.hasVideocallSeconds = totalSeconds > 0;
      }
    },
    videoCallPermission: {
      query: VIDEO_CALL_PERMISSION,
      variables() {
        return {
          buyer_id: this.journeyProvider.journey.user.authUser._id,
          provider_id: this.journeyProvider.provider.id
        };
      },
      skip() {
        return !this.journeyProvider;
      }
    },
    $subscribe: {
      conversationMessages: {
        query: queries.MESSAGE_SUBSCRIPTION,
        variables() {
          return {};
        },
        result({ data }) {
          let incomingMessage = data.conversationMessages.data[0];
          let meta = JSON.parse(incomingMessage.metadata);
          if (meta && meta.type == "VIDEO_CALL_ACCEPTED") {
            this.setVideoCallStatus(true);
            this.showCallingWindow = false;
            this.videoCallAccepted(meta);
          }
          if (meta && meta.type == "VIDEO_CALL_DENY") {
            this.closeVideoCall();
          }
        }
      },
      providerVideoCall: {
        query: queries.VIDEO_CALL_SUBSCRIPTION,
        variables() {
          return {
            buyer_id: this.journeyProvider.journey.user.authUser._id,
            provider_uuid: this.journeyProvider.provider.uuid,
            display_name: this.journeyProvider.provider.name
          };
        },
        skip() {
          return (
            !this.journeyProvider ||
            !this.journeyProvider.journey ||
            !this.journeyProvider.provider ||
            !this.videoCallPermission ||
            (this.videoCallPermission && !this.videoCallPermission.allowed) ||
            !this.hasVideocallSeconds
          );
        }
      },
      providerIsCalling: {
        query: queries.PROVIDER_IS_CALLING,
        variables() {
          return {
            call_id: this.callId
          };
        },
        skip() {
          return !this.subscribeToProviderIsCalling;
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@media screen and (min-width: 1024px) {
  .custom-window-style {
    margin-left: -12.5rem;
  }
}

@media (min-height: 600px) {
  .icon {
    width: 6rem !important;
    height: 6rem !important;
  }
}
@media (max-height: 600px) {
  img {
    max-height: 210px;
    max-width: 210px;
  }
}

img {
  width: 20rem;
  height: 20rem;
}

.icon {
  width: 4rem;
  height: 4rem;
}

.custom-field-style {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.loading {
  word-break: break-all;
}

.loading:after {
  display: inline-block;
  animation: dotty steps(1, end) 1s infinite;
  content: "";
}

@keyframes dotty {
  0% {
    content: "";
  }
  25% {
    content: ".";
  }
  50% {
    content: "..";
  }
  75% {
    content: "...";
  }
  100% {
    content: "";
  }
}

.modal-card-foot {
  justify-content: center;
}

.is-rounded {
  border-radius: 50%;
}

.header {
  height: 50px;
  margin-top: -3.25rem !important;
  background-color: #e7f0f5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer {
  height: 100px;
  background-color: #e7f0f5;
  padding-left: 1rem;
  display: flex;
  align-items: center;

  .button {
    width: 65px;
    height: 65px;
    border-radius: 50%;
    margin-right: 1rem;
  }
}

#remote-buyer {
  .muted-video {
    width: 100%;

    .field {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;

      #buyer-avatar {
        width: 20rem;
        height: 20rem;
        object-fit: cover;
        border-radius: 50%;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
          0 6px 20px 0 rgba(0, 0, 0, 0.09);
      }

      #buyer-initials {
        width: 20rem;
        height: 20rem;
        background-color: #0191c6;
        color: #e7f0f5;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 48px;
        border-radius: 50%;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
          0 6px 20px 0 rgba(0, 0, 0, 0.09);
      }
    }
  }
}

@media (max-height: 600px) {
  #remote-buyer {
    .muted-video {
      img {
        max-height: 210px;
        max-width: 210px;
      }
    }
  }
}

.calling-avatar {
  object-fit: cover;
}

#browser-info {
  position: absolute;
  width: 100%;
  z-index: 5;
}
</style>

<style lang="scss">
.custom-window-style {
  #me {
    position: relative !important;
    width: 100%;
    margin: 0 auto;
    display: block;

    div {
      position: absolute !important;
      width: 280px !important;
      height: 210px !important;
      right: 0 !important;
      bottom: 0 !important;
    }
  }
  #me video {
    position: relative !important;
  }
  #remote-buyer {
    display: flex;
    min-height: 200px !important;
  }
  #remote-buyer video {
    width: auto !important;
    position: relative !important;
    object-fit: contain !important;
  }
}
</style>
