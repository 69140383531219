import login from "./components/login";
import Subscriptions from "./views/Subscriptions";
import { Role } from "../../_helpers/roles";

const loginRoute = {
  path: "/authenticate",
  name: "login",
  component: login
};

const Homepage = {
  path: "",
  name: "homepage",
  redirect: "/dashboard"
};

const SubscriptionsPage = {
  path: "/subscriptions",
  name: "subscriptions",
  component: Subscriptions,
  meta: { authorize: [Role.Vendor] }
};

export default [loginRoute, Homepage, SubscriptionsPage];
