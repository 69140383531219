<template>
  <card-component title="Change Password" icon="lock">
    <div class="card-message" v-if="me.user.role != 'VENDOR'">
      To change your password, please contact technical support.
    </div>
    <div class="card-message" v-else-if="me.provider != 'LOCAL'">
      Your MeTime account is signed up through
      {{ me.provider[0] + me.provider.slice(1).toLowerCase() }}.
      <br />
      To change your password, please
      <strong><a href="https://id.metime.com/signup">register</a></strong> with
      a different email.
    </div>
    <ValidationObserver
      ref="changePasswordFormValidator"
      class="userForm"
      v-else
    >
      <div class="tile is-ancestor">
        <section class="tile is-parent is-9 is-vertical">
          <div class="columns" style="flex-wrap: wrap;">
            <ValidationProvider
              class="is-11 column"
              rules="required|min:2"
              v-slot="{ errors, valid }"
              name="Current Password"
            >
              <b-field
                :type="{ 'is-danger': errors[0], 'is-primary': valid }"
                label="Current Password"
              >
                <b-input
                  type="password"
                  password-reveal
                  trim
                  v-model="form.currentPassword"
                />
              </b-field>
              <span
                v-for="error in errors"
                :key="error.id"
                class="has-text-danger help"
                >{{ error }}</span
              >
            </ValidationProvider>
            <ValidationProvider
              class="is-11 column"
              :rules="{
                required: true,
                passRegex: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/
              }"
              v-slot="{ errors, valid }"
              name="New Password"
            >
              <b-field
                :type="{ 'is-danger': errors[0], 'is-primary': valid }"
                label="New Password"
              >
                <b-input
                  type="password"
                  password-reveal
                  trim
                  v-model="form.newPassword"
                />
              </b-field>
              <span
                v-for="error in errors"
                :key="error.id"
                class="has-text-danger help"
                >{{ error }}</span
              >
            </ValidationProvider>
            <ValidationProvider
              class="is-11 column"
              rules="required|confirmed:New Password"
              v-slot="{ errors, valid }"
              name="Confirm New Password"
            >
              <b-field
                :type="{ 'is-danger': errors[0], 'is-primary': valid }"
                label="Confirm New Password"
              >
                <b-input
                  type="password"
                  password-reveal
                  trim
                  v-model="form.confirmNewPassword"
                />
              </b-field>
              <span
                v-for="error in errors"
                :key="error.id"
                class="has-text-danger help"
                >{{ error }}</span
              >
            </ValidationProvider>
          </div>
        </section>
      </div>
      <div class="level" style="padding-top:20px">
        <div class="level-left">
          <div class="level-item">
            <b-button
              class="is-primary"
              :loading="sendingForm"
              @click="submitForm"
              >Submit</b-button
            >
          </div>
        </div>
      </div>
    </ValidationObserver>
  </card-component>
</template>

<script>
import _ from "lodash";
import CardComponent from "../../base/components/CardComponent";
import { CHANGE_PASSWORD } from "../../../_graphql/Mutations/mutations";
import ValidationErrors from "../../../mixins/ValidationErrors";

export default {
  name: "EmailChangeForm",
  mixins: [ValidationErrors],
  components: {
    CardComponent
  },
  props: {
    me: Object
  },
  data() {
    return {
      sendingForm: false,
      form: {
        currentPassword: "",
        newPassword: "",
        confirmNewPassword: ""
      },
      emptyForm: null
    };
  },
  created() {
    this.emptyForm = _.clone(this.form);
  },
  methods: {
    submitForm() {
      this.$refs.changePasswordFormValidator.validate().then(success => {
        if (!success) {
          this.refName = "changePasswordFormValidator";
          this.handleErrors();
          return;
        }
        const mutation = CHANGE_PASSWORD;
        const variables = {
          currentPassword: this.form.currentPassword,
          newPassword: this.form.newPassword
        };
        this.sendingForm = true;
        this.$apollo.provider.defaultClient
          .mutate({
            mutation: mutation,
            variables: variables
          })
          .catch(error => {
            return this.$buefy.toast.open({
              message: `${error}`,
              type: "is-danger",
              duration: 3000
            });
          })
          .then(data => {
            let response = data.data.changePassword;
            if (response.success) {
              this.$buefy.toast.open({
                message: "Password changed successfully.",
                type: "is-success",
                duration: 3000
              });
              this.resetForm();
            } else {
              this.$buefy.toast.open({
                message: response.errors.join(", "),
                type: "is-danger",
                duration: 3000
              });
            }
            this.sendingForm = false;
          });
        this.sendingForm = false;
      });
    },
    resetForm() {
      this.form = _.clone(this.emptyForm);
      this.$refs.changePasswordFormValidator.reset();
    }
  }
};
</script>

<style scoped></style>
