var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"userForm",attrs:{"id":"solution-form"}},[_c('div',{staticClass:"columns is-variable is-12"},[_c('div',{staticClass:"column is-12"},[_c('div',{staticClass:"columns is-variable is-12 is-multiline is-mobile"},[_c('ValidationProvider',{staticClass:"column is-12-mobile is-6-tablet",attrs:{"name":"Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"type":errors[0] ? 'is-danger' : 'is-primary',"label":"Title *"}},[_c('b-input',{attrs:{"trim":"","disabled":_vm.disableFields},on:{"blur":function($event){return _vm.checkForChanges('title')}},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}})],1),_vm._l((errors),function(error){return _c('span',{key:error.id,staticClass:"has-text-danger help"},[_vm._v(_vm._s(error))])})]}}])}),_c('ValidationProvider',{staticClass:"column is-12-mobile is-6-tablet",attrs:{"name":"Meta title","rules":{ required: _vm.publishValidation }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"type":{ 'is-danger': errors[0], 'is-primary': valid },"label":"Meta title"}},[_c('b-input',{attrs:{"trim":"","disabled":_vm.disableFields},on:{"blur":function($event){return _vm.checkForChanges('meta_title')}},model:{value:(_vm.form.meta_title),callback:function ($$v) {_vm.$set(_vm.form, "meta_title", $$v)},expression:"form.meta_title"}})],1),_vm._l((errors),function(error){return _c('span',{key:error.id,staticClass:"has-text-danger help"},[_vm._v(_vm._s(error))])})]}}])})],1),_c('div',{staticClass:"columns is-variable is-12 is-multiline is-mobile"},[_c('ValidationProvider',{staticClass:"column is-12-mobile is-6-tablet",attrs:{"name":"Slug","rules":{
            regex: /^[a-z0-9](-?[a-z0-9])*$/,
            required: _vm.publishValidation
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
          var valid = ref.valid;
return [_c('b-field',{attrs:{"type":{ 'is-danger': errors[0], 'is-primary': valid },"label":"Slug"}},[_c('b-input',{attrs:{"placeholder":"example-slug-name","trim":"","disabled":_vm.disableFields},on:{"blur":function($event){return _vm.checkForChanges('slug')}},model:{value:(_vm.form.slug),callback:function ($$v) {_vm.$set(_vm.form, "slug", $$v)},expression:"form.slug"}})],1),_vm._l((errors),function(error){return _c('span',{key:error.id,staticClass:"has-text-danger help"},[_vm._v(_vm._s(error))])})]}}])})],1),_c('div',{staticClass:"columns is-variable is-12 is-multiline is-mobile"},[(_vm.solutionsCategories)?_c('ValidationProvider',{staticClass:"column is-12-mobile is-6-tablet",attrs:{"name":"Category","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
          var valid = ref.valid;
return [_c('b-field',{attrs:{"type":errors[0] ? 'is-danger' : 'is-primary',"label":"Category *"}},[_c('b-select',{staticClass:"is-primary",attrs:{"expanded":"","disabled":_vm.disableFields},on:{"input":_vm.selectCategory,"blur":function($event){return _vm.checkForChanges('category')}},model:{value:(_vm.form.category.id),callback:function ($$v) {_vm.$set(_vm.form.category, "id", $$v)},expression:"form.category.id"}},_vm._l((_vm.solutionsCategories.data),function(category){return _c('option',{key:category.id,domProps:{"value":category.id}},[_vm._v(_vm._s(category.name))])}),0)],1),_vm._l((errors),function(error){return _c('span',{key:error.id,staticClass:"has-text-danger help"},[_vm._v(_vm._s(error))])})]}}],null,false,897002529)}):_vm._e(),_c('ValidationProvider',{staticClass:"column is-12-mobile is-6-tablet",attrs:{"name":"Subcategory","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
          var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Subcategory *","type":errors[0] ? 'is-danger' : 'is-primary'}},[_c('b-autocomplete',{attrs:{"type":"search","expanded":"","disabled":_vm.disableFields,"data":_vm.subcategories,"open-on-focus":true,"check-infinite-scroll":true},on:{"typing":_vm.handleInput,"select":_vm.suggestionClick,"blur":function($event){return _vm.checkForChanges('subcategory')}},model:{value:(_vm.form.subcategory.name),callback:function ($$v) {_vm.$set(_vm.form.subcategory, "name", $$v)},expression:"form.subcategory.name"}})],1),_vm._l((errors),function(error){return _c('span',{key:error.id,staticClass:"has-text-danger help"},[_vm._v(_vm._s(error))])})]}}])})],1),_c('div',{staticClass:"columns is-variable is-12 is-multiline is-mobile"},[_c('ValidationProvider',{staticClass:"column is-12-mobile is-6-tablet",attrs:{"name":"Description","rules":{ required: _vm.publishValidation }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
          var valid = ref.valid;
return [_c('b-field',{attrs:{"type":{ 'is-danger': errors[0], 'is-primary': valid },"label":"Description"}},[_c('b-input',{attrs:{"type":"textarea","disabled":_vm.disableFields},on:{"blur":function($event){return _vm.checkForChanges('description')}},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})],1),_vm._l((errors),function(error){return _c('span',{key:error.id,staticClass:"has-text-danger help"},[_vm._v(_vm._s(error))])})]}}])}),_c('ValidationProvider',{staticClass:"column is-12-mobile is-6-tablet",attrs:{"name":"Meta description","rules":{ required: _vm.publishValidation }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
          var valid = ref.valid;
return [_c('b-field',{attrs:{"type":{ 'is-danger': errors[0], 'is-primary': valid },"label":"Meta description"}},[_c('b-input',{attrs:{"type":"textarea","disabled":_vm.disableFields},on:{"blur":function($event){return _vm.checkForChanges('meta_description')}},model:{value:(_vm.form.meta_description),callback:function ($$v) {_vm.$set(_vm.form, "meta_description", $$v)},expression:"form.meta_description"}})],1),_vm._l((errors),function(error){return _c('span',{key:error.id,staticClass:"has-text-danger help"},[_vm._v(_vm._s(error))])})]}}])})],1),_c('div',{staticClass:"columns is-variable is-12 is-multiline is-mobile"},[_c('ValidationProvider',{staticClass:"column is-12",staticStyle:{"padding-bottom":"20px"},attrs:{"name":"Content","rules":{ required: _vm.publishValidation }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
          var valid = ref.valid;
return [_c('b-field',{staticClass:"content",attrs:{"type":{ 'is-danger': errors[0], 'is-primary': valid },"label":"Content"}},[_c('ckeditor',{attrs:{"disabled":_vm.disableFields,"editor":_vm.editor,"config":_vm.editorConfig},on:{"input":_vm.fixSideTextHeight,"ready":_vm.fixSideTextHeight,"blur":function($event){return _vm.checkForChanges('content')}},model:{value:(_vm.form.content),callback:function ($$v) {_vm.$set(_vm.form, "content", $$v)},expression:"form.content"}})],1),_vm._l((errors),function(error){return _c('span',{key:error.id,staticClass:"has-text-danger help"},[_vm._v(_vm._s(error))])})]}}])})],1),_c('div',{staticClass:"columns is-variable is-12 is-multiline is-mobile",attrs:{"id":"read-time-column"}},[_c('ValidationProvider',{staticClass:"column is-12-mobile is-6-desktop",attrs:{"name":"Time to read","rules":{
            min_value: 1,
            required: _vm.publishValidation,
            integer: true
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
          var valid = ref.valid;
return [_c('b-field',{staticClass:"is-horizontal",attrs:{"type":{ 'is-danger': errors[0], 'is-primary': valid },"label":"Time to read (in minutes)","id":"read-time-field"}},[_c('b-input',{attrs:{"id":"read_time","type":"number","disabled":_vm.disableFields},on:{"blur":function($event){return _vm.checkForChanges('read_time')}},model:{value:(_vm.form.read_time),callback:function ($$v) {_vm.$set(_vm.form, "read_time", $$v)},expression:"form.read_time"}})],1),_vm._l((errors),function(error){return _c('span',{key:error.id,staticClass:"has-text-danger help read-time-help"},[_vm._v(_vm._s(error))])})]}}])})],1),_c('div',{staticClass:"columns is-variable is-12 is-multiline is-mobile"},[_c('ValidationProvider',{staticClass:"column is-12-mobile is-6-desktop is-5-fullhd",attrs:{"name":"Cover image","rules":{ required: _vm.publishValidation }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
          var valid = ref.valid;
return [_c('div',{staticClass:"field",attrs:{"id":"cover-image-field"}},[_c('label',{staticClass:"label has-text-left"},[_c('span',[_vm._v("Cover image")]),_c('b-button',{staticClass:"is-primary",attrs:{"disabled":_vm.disableFields},on:{"click":function($event){return _vm.uploadImage('solution_cover_image')}}},[_vm._v("Upload image")])],1),_c('div',{staticClass:"control"},[_c('b-field',[_c('b-upload',{attrs:{"disabled":_vm.disableFields,"drag-drop":"","expanded":""},on:{"input":function($event){return _vm.droppedImage($event, 'solution_cover_image')}},model:{value:(_vm.form.coverImageDropFiles),callback:function ($$v) {_vm.$set(_vm.form, "coverImageDropFiles", $$v)},expression:"form.coverImageDropFiles"}},[(_vm.form.cover_url)?_c('img',{staticStyle:{"width":"100%","object-fit":"contain"},attrs:{"src":_vm.form.cover_url,"alt":"Cover image"}}):_vm._e(),_c('section',{staticClass:"section"},[_c('div',{staticClass:"content has-text-centered"},[_c('p',[_c('b-icon',{attrs:{"icon":"upload","size":"is-large"}})],1),_c('p',[_vm._v("Drop your file here or click to upload")])])])])],1)],1),_vm._l((errors),function(error){return _c('span',{key:error.id,staticClass:"has-text-danger help"},[_vm._v(_vm._s(error))])})],2)]}}])}),_c('ValidationProvider',{staticClass:"column is-12-mobile is-6-desktop is-offset-2-fullhd is-5-fullhd",attrs:{"name":"Thumbnail image","rules":{ required: _vm.publishValidation }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
          var valid = ref.valid;
return [_c('div',{staticClass:"field",attrs:{"id":"thumbnail-field"}},[_c('label',{staticClass:"label has-text-left"},[_c('span',[_vm._v("Thumbnail image")]),_c('b-button',{staticClass:"is-primary",attrs:{"disabled":_vm.disableFields},on:{"click":function($event){return _vm.uploadImage('solution_thumbnail_image')}}},[_vm._v("Upload image")])],1),_c('div',{staticClass:"control"},[_c('b-field',[_c('b-upload',{attrs:{"drag-drop":"","expanded":"","disabled":_vm.disableFields},on:{"input":function($event){return _vm.droppedImage($event, 'solution_thumbnail_image')}},model:{value:(_vm.form.thumbnailDropFiles),callback:function ($$v) {_vm.$set(_vm.form, "thumbnailDropFiles", $$v)},expression:"form.thumbnailDropFiles"}},[(_vm.form.cover_thumb_url)?_c('img',{staticStyle:{"width":"100%","object-fit":"contain"},attrs:{"src":_vm.form.cover_thumb_url,"alt":"Thumbnail"}}):_vm._e(),_c('section',{staticClass:"section"},[_c('div',{staticClass:"content has-text-centered"},[_c('p',[_c('b-icon',{attrs:{"icon":"upload","size":"is-large"}})],1),_c('p',[_vm._v("Drop your file here or click to upload")])])])])],1)],1),_vm._l((errors),function(error){return _c('span',{key:error.id,staticClass:"has-text-danger help"},[_vm._v(_vm._s(error))])})],2)]}}])})],1)])]),_c('add-image',{ref:"addImage",on:{"saveImage":_vm.saveImage}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }