<template>
  <div class="chat-window">
    <div
      :style="'max-height: ' + windowHeight + 'px;'"
      class="tile is-parent parent-window"
      style="padding:0;"
    >
      <div class="card tile is-child">
        <header
          :class="['card-header', journeyPanelIsOpen ? 'header-expanded' : '']"
        >
          <p
            v-if="!journeyPanelIsOpen"
            class="card-header-title has-text-primary"
          >
            <span class="icon"
              ><i class="mdi mdi-inbox-arrow-up default"></i
            ></span>
            Message details
            <br />
          </p>
          <journey-details v-if="journeyPanelIsOpen" :journey="journey" />
          <span class="is-pulled-right">
            <button
              v-if="!journeyPanelIsOpen"
              @click="journeyPanelIsOpen = !journeyPanelIsOpen"
              class="button is-primary journey-overlay-btn"
            >
              View Journey &dtrif;
            </button>
            <button
              v-if="journeyPanelIsOpen"
              @click="journeyPanelIsOpen = !journeyPanelIsOpen"
              class="button is-primary journey-overlay-btn"
            >
              <b> Close Journey &utrif;</b>
            </button>
          </span>
        </header>
        <div class="card-content">
          <div
            :style="'max-height: ' + windowHeight + 'px;'"
            class="columns is-multiline is-mobile"
          >
            <div
              :style="'max-height: ' + columnHeight + 'px;'"
              ref="chat-holder"
              :class="[
                'column is-full-mobile is-half-tablet is-12-desktop chat-holder level',
                embedded ? 'chat-holder-large' : 'chat-holder-small'
              ]"
            >
              <b-notification
                class="moreMessages"
                :closable="false"
                v-if="!oldestMessageReached"
              >
                <div v-if="failedLoadingPrevious">
                  Failed to load previous messages.
                  <button
                    class="is-primary button"
                    @click="getPreviousMessages()"
                  >
                    Retry
                  </button>
                </div>
                <b-loading
                  :is-full-page="false"
                  :active="loadingMore"
                ></b-loading>
              </b-notification>
              <div>
                <span
                  style="color:#0ba1d1;font-weight: bold;font-style:italic;opacity: 50%"
                  v-html="formattedDateLabel"
                >
                  <br />
                </span>
              </div>
              <chat-bubble
                :key="message.id"
                :index="index"
                :conversation="conversation"
                v-for="(message, index) in sortedConversation"
                :showLabel="shouldShowDateLabel(index - 1)"
                :provider="provider"
                :message="message"
                :embedded="embedded"
                @accept="accept"
                @declineAppointment="declineAppointment"
              />
              <article
                v-if="conversation.length == 0"
                class="message is-primary"
              >
                <div class="message-body">
                  <i class="fa fa-info-circle" />
                  You haven't started a conversation with this client.
                  <br />
                  Write in the text area below to start one!
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions, mapMutations } = createNamespacedHelpers(
  "messages"
);
import chatBubble from "./chatBubble";
import queries from "../queries";
import _ from "lodash";
import JourneyDetails from "./journeyDetails";

import { mutate } from "../../../vue-apollo";
import { UPDATE_JOURNEY_PROVIDER } from "../../../_graphql/Mutations/mutations";
import moment from "moment";

export default {
  components: { JourneyDetails, chatBubble },
  props: ["provider", "journey", "embedded"],
  data() {
    return {
      journeyPanelIsOpen: false,
      conversationSort: [],
      loadingMore: false,
      failedLoadingPrevious: false,
      oldestMessageReached: false,
      initialScroll: true,
      windowHeight: null,
      columnHeight: null
    };
  },
  created() {
    this.chatWindowHeight();
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.chatWindowHeight);
    });
    const chatHolder = this.$refs["chat-holder"];
    chatHolder.addEventListener("scroll", async () => {
      if (
        !this.loadingMore &&
        !this.oldestMessageReached &&
        chatHolder.scrollTop === 0
      ) {
        await this.getPreviousMessages();
      }
    });
  },
  methods: {
    ...mapActions(["updateMessage", "loadPreviousMessages"]),
    ...mapMutations([
      "updateConversation",
      "setConversation",
      "setVideoCallStatus",
      "setIsCalling",
      "setVideoUrl"
    ]),
    chatWindowHeight() {
      let height = window.innerHeight;
      this.windowHeight = height - 382;
      this.columnHeight = this.windowHeight - 34;
    },
    accept(message) {
      let meta = JSON.parse(message.metadata);
      meta.state = "ACCEPTED";
      message.metadata = JSON.stringify(meta);
      this.updateMessage(message);
    },
    declineAppointment(message) {
      let meta = JSON.parse(message.metadata);
      meta.state = "DENIED";
      message.metadata = JSON.stringify(meta);
      this.updateMessage(message);
    },
    sort(conversation) {
      return _(conversation).sortBy("createdDate");
    },
    shouldShowDateLabel(index) {
      if (
        this.sortedConversation.length > index + 1 &&
        this.sortedConversation[index]?.createdDate !== undefined
      ) {
        let previousMessage = moment
          .utc(this.sortedConversation[index].createdDate)
          .local();

        let currentMessage = moment
          .utc(this.sortedConversation[index + 1].createdDate)
          .local();

        let durationMinutes = currentMessage.diff(previousMessage, "minutes");

        if (durationMinutes > 15) {
          return true;
        }
      } else if (
        this.sortedConversation.length - 1 === index &&
        this.sortedConversation[index].createdDate !== undefined
      ) {
        return true;
      }

      return false;
    },
    async getPreviousMessages() {
      const chatHolder = this.$refs["chat-holder"];
      const preScrollHeight = chatHolder.scrollHeight;
      this.loadingMore = true;
      this.failedLoadingPrevious = false;
      const result = await this.loadPreviousMessages(this.oldestMessageDate);
      chatHolder.style.scrollBehavior = "unset";
      chatHolder.scrollTop = chatHolder.scrollHeight - preScrollHeight;
      chatHolder.style.scrollBehavior = "smooth";
      if (!result) {
        this.failedLoadingPrevious = true;
      }
      this.loadingMore = false;
    }
  },
  watch: {
    conversation(data) {
      this.conversationSort = this.sort(data);

      // TODO: Remove this once "conversation" is fixed to load at proper time (before render)
      if (this.initialScroll) {
        const chatHolder = this.$refs["chat-holder"];
        this.$nextTick(() => {
          chatHolder.style.scrollBehavior = "unset";
          chatHolder.scrollTop = chatHolder.scrollHeight;
          chatHolder.style.scrollBehavior = "smooth";
          this.initialScroll = false;
        });
      }
    },
    conversationMeta(metaData) {
      if (metaData.current_page == metaData.next_page) {
        this.oldestMessageReached = true;
      }
    }
  },
  computed: {
    ...mapGetters([
      "conversation",
      "conversationMeta",
      "conversationId",
      "journeyProviders"
    ]),
    oldestMessageDate() {
      return this.conversation[this.conversation.length - 1].createdDate;
    },
    sortedConversation() {
      return _.orderBy(this.conversation, ["createdDate"], ["asc"]);
    },
    formattedDateLabel() {
      if (this.sortedConversation.length < 1) {
        return "";
      }
      let messageCreatedDate = moment.utc(
        this.sortedConversation[0].createdDate
      );
      let daysAgo = moment
        .utc(messageCreatedDate)
        .local()
        .fromNow();

      let currentDate = moment();
      let daysBefore = moment
        .duration(currentDate.diff(messageCreatedDate))
        .asDays();

      if (daysBefore > 7) {
        const date = new Date(this.sortedConversation[0].createdDate);
        return moment(date).format("DD.MM.YYYY");
      }

      return daysAgo;
    }
  },
  apollo: {
    $subscribe: {
      conversationMessages: {
        query: queries.MESSAGE_SUBSCRIPTION,
        variables() {
          return {};
        },
        async result({ data }) {
          let incomingMessage = data.conversationMessages.data[0];
          let meta = JSON.parse(incomingMessage.metadata);
          if (
            meta &&
            meta.type == "VIDEO_CALL_PERMISSION" &&
            meta.state == "ACCEPTED"
          ) {
            this.setIsCalling("firstCall");
            this.$emit("permissionStatusUpdated", "permissionAccepted");
          }
          if (
            meta &&
            meta.type == "VIDEO_CALL_PERMISSION" &&
            meta.state == "DENIED"
          ) {
            this.$emit("permissionStatusUpdated", "permissionDenied");
          }
          if (incomingMessage.conversationId != this.conversationId) {
            if (
              !meta ||
              (meta &&
                meta !== "VIDEO_CALL_ACCEPTED" &&
                meta !== "VIDEO_CALL_DENY")
            ) {
              return;
            }
          }
          if (this.conversation.length > 0) {
            if (incomingMessage.fromId) {
              let getUser = await this.$apollo.query({
                query: queries.GET_FROMUSER_FOR_MESSAGE,
                variables: {
                  id: incomingMessage.fromId
                }
              });
              incomingMessage.fromUser.firstName =
                getUser.data.user.user.firstName;
              incomingMessage.fromUser.lastName =
                getUser.data.user.user.lastName;
            }
            const vendorId = this.$store.getters.meUser.user._id;
            if (!incomingMessage.readBy.includes(vendorId)) {
              incomingMessage.readBy.push(vendorId);
              this.$apollo.mutate({
                mutation: queries.UPDATE_MESSAGE,
                variables: {
                  id: incomingMessage.id,
                  data: {
                    readBy: incomingMessage.readBy
                  }
                }
              });
            }
          }

          mutate(
            UPDATE_JOURNEY_PROVIDER,
            {
              id: parseInt(this.$route.params.journeyProviderId),
              input: { seen: true }
            },
            () => {}
          );

          const chatHolder = this.$refs["chat-holder"];
          this.updateConversation(incomingMessage);
          this.setConversation(
            this.conversation.filter(message => message.status !== "ARCHIVED")
          );

          chatHolder.scrollTop = chatHolder.scrollHeight;
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.header-expanded.card-header {
  background: none #fff;
}
.card .card-header {
  position: fixed;
  width: 84%;
  margin-left: 30px;
  z-index: 5;
  box-shadow: 0px 5px 5px #cecece;
  border-bottom: 1px solid #999;
  border-radius: 0px 0px 5px 5px;
  min-height: 70px;
  transition: all ease 0.2s;
}
.header-expanded {
  min-height: 300px;
}

.chat-holder {
  padding-top: 60px;
  overflow-y: scroll;
  scroll-behavior: smooth;
  scrollbar-color: #fff;
}

.chat-holder::-webkit-scrollbar-track {
  background: none #fff;
}

.chat-holder::-webkit-scrollbar {
  background: none #333;
  width: 12px;
  border-radius: 10px;
}

.chat-holder::-webkit-scrollbar-thumb {
  background: none #82cee7;
  border-radius: 4px;
}

.journey-overlay-btn {
  margin: 12px 6px 0 0px;
  position: absolute;
  right: 0;
  min-height: 45px;
  border-radius: 5px;
}
</style>
<style lang="scss">
article.notification.moreMessages {
  margin-top: 15px;
  margin-bottom: 10px;
  min-height: 75px;
  display: inline-flex;
  div.media-content div {
    display: inline-flex !important;
    align-items: center !important;
    button {
      margin-left: 10px;
    }
  }
  div.media-content div.loading-overlay {
    min-height: 50px;
    z-index: 1;
  }
}
</style>
