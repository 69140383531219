<template>
  <section>
    <b-modal
      :active.sync="isActive"
      has-modal-card
      trap-focus
      :on-cancel="close"
    >
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title has-text-weight-bold">Edit profile url</p>
        </header>
        <section class="modal-card-body">
          <span
            >Your profile will be accessed directly using the URL below. URL
            should be clearly named using your clinic name separated with
            hyphens. Examples: <br />
            https://metime.com/provider/clinic-name <br />
            https://metime.com/provider/firstname-lastname <br />
            URL shouldn't contain special characters (e.g. " , ! @ # $ % & * /)
            except for dashes , as they make URLs less inviting for users to
            click or share.
          </span>
          <ValidationObserver ref="profileUrl">
            <ValidationProvider
              :rules="{ regex: /^[A-Za-z0-9\-]*$/, required: true }"
              v-slot="{ errors }"
              name="Profile url"
            >
              <div class="field">
                <label for="url" class="label has-text-left"
                  >https://metime.com/provider/</label
                >
                <input
                  id="url"
                  type="text"
                  class="input"
                  :class="error ? 'is-danger' : 'is-primary'"
                  v-model="url"
                />
              </div>
              <span
                v-for="error in errors"
                :key="error.id"
                class="has-text-danger help has-text-right"
                >{{ error }}</span
              >
            </ValidationProvider>
          </ValidationObserver>
        </section>
        <footer class="modal-card-foot">
          <button class="button" type="button" @click="close">
            Cancel
          </button>
          <button @click="saveUrl" class="button is-primary">Save</button>
        </footer>
      </div>
    </b-modal>
  </section>
</template>

<script>
// import {
//   UPDATE_PROVIDER,
//   CREATE_PRODUCT
// } from "../../../_graphql/Mutations/mutations";
import { PROFILE_URLS } from "../../../_graphql/Queries/queries";
import { UPDATE_PROVIDER } from "../../../_graphql/Mutations/mutations";
import ValidationErrors from "../../../mixins/ValidationErrors";

export default {
  data() {
    return {
      isActive: false,
      url: "",
      error: ""
    };
  },
  mixins: [ValidationErrors],
  props: ["provider"],
  methods: {
    openModal() {
      this.isActive = true;
      if (this.provider) {
        this.url = this.provider.profile_url;
      }
    },
    close() {
      this.isActive = false;
    },
    saveUrl() {
      this.$refs.profileUrl.validate().then(success => {
        if (!success) {
          this.refName = "profileUrl";
          this.handleErrors();
          return;
        } else {
          this.$apollo
            .query({
              query: PROFILE_URLS,
              variables() {
                return {
                  input: {
                    query: "profile_url:" + this.url
                  }
                };
              }
            })
            .then(({ data: { providers }, errors}) => {
              if (providers && providers.data.length > 0) {
                providers.data.forEach(provider => {
                  if (
                    provider.profile_url == this.url &&
                    provider.profile_url !== this.provider.profile_url
                  ) {
                    this.error = "This url address is already taken";
                    return this.$buefy.toast.open({
                      message: errors,
                      type: "is-danger"
                    });
                  } else {
                    this.error = "";
                  }
                });
              } else {
                return this.error = errors;
              }

              this.$apollo.mutate({
                mutation: UPDATE_PROVIDER,
                variables: {
                  id: parseInt(this.provider.id),
                  input: {
                    profile_url: this.url
                  }
                },
                update: (cache, { data }) => {
                  if (data.updateProvider) {
                    this.close();
                    this.$emit("providerProfileUpdated");
                    this.$buefy.toast.open({
                      message: "Profile url updated successfully",
                      type: "is-success"
                    });
                  }
                },
                error: ({ error }) => {
                  this.$buefy.toast.open({
                    message: error,
                    type: "is-danger",
                    duration: 2000
                  });
                }
              });
            });
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-card-body {
  text-align: left;
}
.field {
  display: flex;
  align-items: baseline;
}
</style>
