<template>
  <div v-if="open">
    <slot />
  </div>
</template>

<script>
export default {
  name: "WindowPortal",
  model: {
    prop: "open",
    event: "close"
  },
  props: {
    open: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      windowRef: null
    };
  },
  watch: {
    open(newOpen) {
      if (newOpen) {
        this.openPortal();
      } else {
        this.closePortal();
      }
    }
  },
  methods: {
    openPortal() {
      this.windowRef = window.open(
        "",
        "",
        "width=400,height=600,left=700,top=175"
      );
      this.windowRef.document.title = "Preview";
      this.windowRef.document.body.appendChild(this.$el);
      this.applyStyles(this.windowRef.document);
      this.windowRef.addEventListener("beforeunload", this.closePortal);
    },
    closePortal() {
      if (this.windowRef) {
        this.windowRef.close();
        this.windowRef = null;
        this.$emit("close");
      }
    },
    applyStyles(targetDoc) {
      let style = document.createElement("link");
      style.type = "text/css";
      style.rel = "stylesheet";
      style.href = location.origin + "/preview/css/style.css";
      targetDoc.head.appendChild(style);

      let jquery = this.htmlToElement(
        // eslint-disable-next-line no-useless-escape
        '<script src="https://code.jquery.com/jquery-3.4.1.slim.min.js" integrity="sha384-J6qa4849blE2+poT4WnyKhv5vZF5SrPo0iEjwBvKU7imGFAV0wwj1yYfoRSJoZ+n" crossorigin="anonymous"><\/script>'
      );
      let popper = this.htmlToElement(
        // eslint-disable-next-line no-useless-escape
        '<script src="https://cdn.jsdelivr.net/npm/popper.js@1.16.0/dist/umd/popper.min.js" integrity="sha384-Q6E9RHvbIyZFJoft+2mJbHaEWldlvI9IOYy5n3zV9zzTtmI3UksdQRVvoxMfooAo" crossorigin="anonymous"><\/script>'
      );
      let bootstrap = this.htmlToElement(
        // eslint-disable-next-line no-useless-escape
        '<script src="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/js/bootstrap.min.js" integrity="sha384-wfSDF2E50Y2D1uUdj0O3uMBJnjuUD4Ih7YwaYd1iqfktj0Uod8GCExl3Og8ifwB6" crossorigin="anonymous"><\/script>'
      );
      targetDoc.body.appendChild(jquery);
      targetDoc.body.appendChild(popper);
      targetDoc.body.appendChild(bootstrap);

      targetDoc.addEventListener(
        "mouseup",
        function(e) {
          if (e.target.getAttribute("class") == "pop-up__overlay-preview") {
            this.$emit("closePopup");
          }
        }.bind(this)
      );
    },
    htmlToElement(html) {
      let template = document.createElement("template");
      html = html.trim();
      template.innerHTML = html;
      return template.content.firstChild;
    }
  },
  mounted() {
    if (this.open) {
      this.openPortal();
    }
  },
  beforeDestroy() {
    if (this.windowRef) {
      this.closePortal();
    }
  }
};
</script>

<style></style>
