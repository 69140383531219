<template>
  <div class="card">
    <header class="card-header">
      <p class="card-header-title has-text-primary">
        <b-icon icon="account-search" custom-size="default" />
        Search and Matching
        <b-tooltip
          animated
          multilined
          class="tooltip"
          label="The information below (location, treatments & specialties) is used to match you with people looking for your services on the app."
          position="is-top"
          ><b-icon icon="information-outline" type="is-primary"
        /></b-tooltip>
      </p>
    </header>
    <div class="card-content search-content">
      <div class="columns is-multiline is-mobile">
        <div class="column is-full-mobile is-full">
          <ValidationProvider
            ref="countryValidator"
            rules="required"
            v-slot="{ errors, valid }"
            name="Country"
            :debounce="100"
          >
            <b-field
              id="fieldset-country"
              label="Country"
              :type="{ 'is-danger': errors[0], 'is-primary': valid }"
            >
              <b-autocomplete
                v-if="activeCountries"
                :disabled="disableFields"
                autocomplete="no"
                expanded
                icon="earth"
                placeholder="Select a country"
                v-model="countryInput"
                :data="filteredCountries"
                :open-on-focus="true"
                @typing="filterCountries"
                field="name"
                :keep-first="true"
                @select="
                  option => {
                    selectCountry(option);
                    country = option;
                  }
                "
              >
                <template slot="empty">No results found.</template>
              </b-autocomplete>
            </b-field>
            <span
              v-for="error in errors"
              :key="error.id"
              class="has-text-danger help"
              >{{ error }}</span
            >
          </ValidationProvider>
        </div>
        <div class="column is-full-mobile is-full">
          <ValidationProvider
            ref="cityValidator"
            rules="required"
            v-slot="{ errors, valid }"
            name="City"
          >
            <b-field
              id="fieldset-city"
              label="City"
              :type="{ 'is-danger': errors[0], 'is-primary': valid }"
            >
              <b-autocomplete
                :disabled="
                  disableFields || countryInput == '' || !isValidCountry
                "
                autocomplete="no"
                expanded
                icon="city"
                :placeholder="
                  countryInput == ''
                    ? 'Select a country first'
                    : 'Select a city'
                "
                v-model="cityInput"
                :data="filteredCities"
                :check-infinite-scroll="true"
                @infinite-scroll="getMoreCities"
                @focus="filterCities(cityInput || '')"
                @blur="checkForChanges('city_id')"
                @input="validateCity"
                :open-on-focus="true"
                @typing="filterCities"
                field="name"
                :keep-first="true"
                @select="
                  option => {
                    selectCity(option);
                    city = option;
                  }
                "
              >
                <template slot="empty">No results found.</template>
              </b-autocomplete>
            </b-field>
            <span
              v-for="error in errors"
              :key="error.id"
              class="has-text-danger help"
              >{{ error }}</span
            >
            <span
              v-if="!isValidCity & (cityInput !== '')"
              class="has-text-danger help"
              >Please choose the city that is closest to you from the
              list.</span
            >
          </ValidationProvider>
        </div>
        <div class="column is-full-mobile is-full">
          <ValidationProvider
            rules="required"
            v-slot="{ errors, valid }"
            name="Address"
          >
            <div class="field" id="fieldset-address">
              <label class="label has-text-left">Address</label>
              <div class="control">
                <input
                  type="text"
                  class="input"
                  :class="{ 'is-danger': errors[0], 'is-primary': valid }"
                  :disabled="disableFields"
                  trim
                  id="address-input"
                  placeholder="Address"
                  @focusout="checkForChanges('address')"
                  v-model="address"
                />
              </div>
            </div>
            <span
              v-for="error in errors"
              :key="error.id"
              class="has-text-danger help"
              >{{ error }}</span
            >
          </ValidationProvider>
        </div>
        <div class="column is-full-mobile is-full">
          <ValidationProvider
            rules="required"
            v-slot="{ errors, valid }"
            name="Zip Code"
          >
            <div class="field" id="fieldset-zipcode">
              <label class="label has-text-left">ZIP Code</label>
              <div class="control">
                <input
                  type="text"
                  class="input"
                  :class="{ 'is-danger': errors[0], 'is-primary': valid }"
                  :disabled="disableFields"
                  trim
                  id="zipcode-input"
                  placeholder="ZIP Code"
                  v-model="zip"
                  @focusout="checkForChanges('zip')"
                />
              </div>
            </div>
            <span
              v-for="error in errors"
              :key="error.id"
              class="has-text-danger help"
              >{{ error }}</span
            >
          </ValidationProvider>
        </div>
        <div class="column is-full-mobile is-full">
          <ValidationProvider
            rules="required"
            v-slot="{ errors, valid }"
            name="Treatments"
          >
            <b-field
              id="fieldset-treatment"
              label="Treatments"
              message=""
              :type="{ 'is-danger': errors[0], 'is-primary': valid }"
            >
              <div
                class="menu-list-container"
                :class="errors[0] ? 'is-danger' : 'is-primary'"
              >
                <ul class="menu-list scrollable">
                  <li v-for="treatment in treatments.data" :key="treatment.id">
                    <b-checkbox
                      :disabled="disableFields"
                      v-model="treatment_ids"
                      :native-value="parseInt(treatment.id)"
                      @input="checkForChanges('treatments')"
                    >
                      {{ treatment.id }}. {{ treatment.name }}
                    </b-checkbox>
                  </li>
                </ul>
              </div>
            </b-field>
            <span
              v-for="error in errors"
              :key="error.id"
              class="has-text-danger help"
              >{{ error }}</span
            >
          </ValidationProvider>
        </div>
        <div class="column is-full-mobile is-full">
          <ValidationProvider
            rules="required"
            v-slot="{ errors, valid }"
            name="Specialties"
          >
            <b-field
              id="fieldset-specialty"
              label="Specialties"
              message=""
              :type="{ 'is-danger': errors[0], 'is-primary': valid }"
            >
              <div
                class="menu-list-container"
                :class="errors[0] ? 'is-danger' : 'is-primary'"
              >
                <ul class="menu-list scrollable">
                  <li
                    style=""
                    v-for="specialty in specialties.data"
                    :key="specialty.id"
                  >
                    <b-checkbox
                      :disabled="disableFields"
                      v-model="specialty_ids"
                      :native-value="parseInt(specialty.id)"
                      @input="checkForChanges('specialties')"
                    >
                      {{ specialty.id }}. {{ specialty.name }}
                    </b-checkbox>
                  </li>
                </ul>
              </div>
            </b-field>
            <span
              v-for="error in errors"
              :key="error.id"
              class="has-text-danger help"
              >{{ error }}</span
            >
          </ValidationProvider>
        </div>
        <div v-if="!disableFields" class="column is-12">
          <div class="buttons is-right">
            <b-button class="is-primary" @click="saveInfo">Save</b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SPECIALITIES, TREATMENTS } from "../../../_graphql/Queries/queries";
import {
  ACTIVE_COUNTRIES,
  CITIES_BY_COUNTRY_ID
} from "../../../_graphql/Queries/queries";
import ValidationErrors from "../../../mixins/ValidationErrors";
import { UPDATE_PROVIDER } from "../../../_graphql/Mutations/mutations";
import LocationInput from "../../../mixins/LocationInput.vue";

export default {
  data() {
    return {
      specialties: [],
      treatments: [],
      activeCountries: [],
      citiesByCountryId: [],
      country: null,
      city: null,
      address: "",
      zip: "",
      treatment_ids: [],
      specialty_ids: [],
      isValidCity: true
    };
  },
  props: ["provider", "allowUpdate"],
  watch: {
    country() {
      this.selectCity(null);
      this.city = null;
    },
    countryInput() {
      if (this.countryInput == "" || !this.isValidCountry) {
        this.city = null;
        this.cityInput = "";
        this.selectCity(null);
        this.filteredCities = [];
      }
    }
  },
  computed: {
    disableFields() {
      return !this.allowUpdate;
    }
  },
  mixins: [ValidationErrors, LocationInput],
  created() {
    if (this.provider) {
      this.address = this.provider.address.address;
      this.zip = this.provider.address.zip;
      this.treatment_ids = this.provider.treatments.map(treatment =>
        parseInt(treatment.tId)
      );
      this.specialty_ids = this.provider.specialties.map(specialty =>
        parseInt(specialty.id)
      );
    }
  },
  methods: {
    checkForChanges(value) {
      if (value == "city_id") {
        if (this.city && this.provider.address.locale.city.id !== this.city.id) {
          this.$emit("editedField");
        }
      }
      if (value == "address") {
        if (this.provider.address.address !== this.address) {
          this.$emit("editedField");
        }
      }
      if (value == "zip") {
        if (this.provider.address.zip !== this.zip) {
          this.$emit("editedField");
        }
      }
      if (value == "treatments") {
        if (this.treatment_ids.length !== this.provider.treatments.length) {
          this.$emit("editedField");
        } else {
          this.treatment_ids.filter(treatment => {
            if (!this.provider.treatments.includes(treatment)) {
              this.$emit("editedField");
            }
          });
        }
      }
      if (value == "specialties") {
        if (this.specialty_ids.length !== this.provider.specialties.length) {
          this.$emit("editedField");
        } else {
          this.specialty_ids.filter(specialty => {
            if (!this.provider.specialties.includes(specialty)) {
              this.$emit("editedField");
            }
          });
        }
      }
    },
    validateCity(value) {
      let city = this.citiesByCountryId.find(c => {
        return c.name === value;
      });
      if (city) {
        this.isValidCity = true;
        this.city = city;
      } else {
        this.isValidCity = false;
        this.city = null;
      }
    },
    async saveInfo() {
      if (!this.isValidCity) {
        return this.$buefy.toast.open({
          message: "Invalid city",
          type: "is-danger"
        });
      }
      const providerId = parseInt(this.$route.params.providerId);
      const mutation = UPDATE_PROVIDER;
      const payload = {
        mutation: mutation,
        queryName: "updateProvider"
      };
      const variables = {
        id: providerId,
        input: {
          locale: {
            city_id: parseInt(this.city.id),
            address: this.address,
            zip: this.zip
          },
          treatments: this.treatment_ids,
          specialties: this.specialty_ids
        }
      };
      this.$store.commit("provider/collection/editModel", variables);
      await this.$store.dispatch("provider/collection/update", payload);
      this.$emit("updatedInfo");
      this.$emit("savedChanges");
    }
  },

  apollo: {
    specialties: {
      query: SPECIALITIES,
      variables: {
        input: {
          order: "id:asc"
        },
        update: data => data.specialties
      }
    },
    treatments: {
      query: TREATMENTS,
      variables: {
        update: data => data.treatments
      }
    },
    activeCountries: {
      query: ACTIVE_COUNTRIES,
      update(data) {
        return data.activeCountries.data;
      },
      result() {
        const country = this.activeCountries.find(
          c => c.id == this.provider.address.locale.country.id
        );
        this.selectCountry(country);
        this.country = country;
      }
    },
    citiesByCountryId: {
      query: CITIES_BY_COUNTRY_ID,
      variables() {
        return {
          country_id: parseInt(this.country.id)
        };
      },
      update(data) {
        return data.citiesByCountryId.data;
      },
      skip() {
        return !this.country;
      },
      result() {
        const city = this.citiesByCountryId.find(
          c => c.id == this.provider.address.locale.city.id
        );
        this.selectCity(city);
        this.city = city;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.menu-list {
  padding: 10px;
  height: 16rem;
  overflow: auto;
  text-align: left;
  overflow-y: scroll;
  .b-checkbox {
    align-items: flex-start;
  }
}

.label {
  text-align: left !important;
}
</style>
