<template>
  <section>
    <b-modal
      trap-focus
      has-modal-card
      :active.sync="isActive"
      :on-cancel="closeModal"
    >
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Flag Journey</p>
        </header>
        <section class="modal-card-body">
          <ValidationObserver ref="flagJourneyValidator">
            <ValidationProvider
              rules="required"
              v-slot="{ errors }"
              name="Reason"
            >
              <b-field horizontal label="Reason:" label-for="flagReason">
                <b-select
                  id="flagReason"
                  expanded
                  icon="flag"
                  placeholder="Select a reason"
                  v-model="reasonId"
                >
                  <option
                    :value="reason.id"
                    v-for="reason in flagReasonsData"
                    :key="reason.id"
                  >
                    {{ reason.reason }}
                  </option>
                </b-select>
              </b-field>
              <span
                v-for="error in errors"
                :key="error.id"
                class="has-text-danger help"
                >{{ error }}</span
              >
            </ValidationProvider>
          </ValidationObserver>
        </section>
        <footer class="modal-card-foot">
          <button class="button" @click="closeModal">
            Close
          </button>
          <button class="button is-primary" @click="submitForm">
            Flag
          </button>
        </footer>
      </div>
    </b-modal>
  </section>
</template>

<script>
import { FLAG_REASONS } from "../../../_graphql/Queries/queries";
import { FLAG_JOURNEY } from "../../../_graphql/Mutations/mutations";
import { mutate } from "../../../vue-apollo";

export default {
  data() {
    return {
      isActive: false,
      reasonId: null,
      journeyId: null,
      flagReasonsData: []
    };
  },
  methods: {
    openModal(id) {
      this.isActive = true;
      this.journeyId = parseInt(id);
    },
    closeModal() {
      this.isActive = false;
      this.reasonId = null;
      this.journeyId = null;
    },
    submitForm() {
      this.$refs.flagJourneyValidator.validate().then(valid => {
        valid;
        if (!valid) {
          return;
        }
        const input = {
          journey_provider_id: this.journeyId,
          flag_reason_id: parseInt(this.reasonId),
          reported_by: "VENDOR"
        };
        mutate(FLAG_JOURNEY, { input }, result => {
          if (result) {
            if (result.createJourneyProviderFlag) {
              this.closeModal();
              this.$buefy.toast.open({
                message: "Journey flagged and awaiting administrator review.",
                type: "is-info",
                duration: 5500
              });
            } else {
              this.$buefy.toast.open({
                message: "Something went wrong. Could not flag this Journey.",
                type: "is-danger"
              });
            }
          }
        });
      });
    }
  },
  apollo: {
    flagReasons: {
      query: FLAG_REASONS,
      variables() {
        return {
          input: {
            query: "role:VENDOR",
            order: "id:ASC"
          }
        };
      },
      result({ data }) {
        const flagReasons = data.flagReasons;
        if (flagReasons) {
          if (flagReasons.errors.length) {
            this.$buefy.toast.open({
              message: flagReasons.errors.join(", "),
              type: "is-danger"
            });
          } else {
            if (!flagReasons.data.length) {
              this.$buefy.toast.open({
                message: "No flag reasons available.",
                type: "is-info"
              });
            }
            this.flagReasonsData = flagReasons.data;
          }
        }
      }
    }
  }
};
</script>
