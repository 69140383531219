<template>
  <div class="columns is-multiline is-mobile">
    <div
      class="column is-full-mobile is-half-tablet is-offset-1-desktop is-4-desktop"
    >
      <ValidationProvider
        rules="required"
        v-slot="{ errors, valid }"
        name="Clinic or Provider Name"
      >
        <b-field
          :type="{ 'is-danger': errors[0], 'is-primary': valid }"
          id="fieldset-name"
          label="Clinic or Provider Name"
          message=""
        >
          <b-input
            autocomplete="no"
            trim
            id="name-input"
            placeholder="Clinic or Provider Name"
            v-model="model.name"
            icon="account"
          />
        </b-field>
        <span
          v-for="error in errors"
          :key="error.id"
          class="has-text-danger help"
          >{{ error }}</span
        >
      </ValidationProvider>
    </div>
    <div
      class="column is-full-mobile is-half-tablet is-offset-1-desktop is-4-desktop"
    >
      <ValidationProvider
        rules="required|email"
        v-slot="{ errors, valid }"
        name="Email"
      >
        <b-field
          id="fieldset-email"
          label="Email"
          :type="{ 'is-danger': errors[0], 'is-primary': valid }"
        >
          <b-input
            trim
            icon="email"
            id="email-input"
            placeholder="Email"
            autocomplete="offsmthrndm"
            type="search"
            v-model="model.email"
          />
        </b-field>
        <span
          v-for="error in errors"
          :key="error.id"
          class="has-text-danger help"
          >{{ error }}</span
        >
      </ValidationProvider>
    </div>
    <div
      class="column is-full-mobile is-half-tablet is-offset-1-desktop is-4-desktop"
    >
      <ValidationProvider
        ref="countryValidator"
        rules="required"
        v-slot="{ errors, valid }"
        name="Country"
        :debounce="100"
      >
        <b-field
          id="fieldset-country"
          label="Country"
          :type="{ 'is-danger': errors[0], 'is-primary': valid }"
        >
          <b-autocomplete
            v-if="activeCountries"
            autocomplete="offqwet_1234!"
            type="search"
            expanded
            icon="earth"
            placeholder="Select a country"
            v-model="countryInput"
            :data="filteredCountries"
            :open-on-focus="true"
            @typing="filterCountries"
            field="name"
            :keep-first="true"
            @select="
              option => {
                selectCountry(option);
                country = option;
                model.country = option;
              }
            "
          >
            <template slot="empty">No results found.</template>
          </b-autocomplete>
        </b-field>
        <span
          v-for="error in errors"
          :key="error.id"
          class="has-text-danger help"
          >{{ error }}</span
        >
      </ValidationProvider>
    </div>
    <div
      class="column is-full-mobile is-half-tablet is-offset-1-desktop is-4-desktop"
    >
      <ValidationProvider
        ref="cityValidator"
        rules="required"
        v-slot="{ errors, valid }"
        name="City"
        :debounce="100"
      >
        <b-field
          id="fieldset-city"
          label="City"
          :type="{ 'is-danger': errors[0], 'is-primary': valid }"
        >
          <b-autocomplete
            :disabled="countryInput == '' || !isValidCountry"
            autocomplete="offqwet_7865!"
            type="search"
            expanded
            icon="city"
            :placeholder="
              countryInput == '' ? 'Select a country first' : 'Select a city'
            "
            v-model="cityInput"
            :data="filteredCities"
            :open-on-focus="true"
            :check-infinite-scroll="true"
            @infinite-scroll="getMoreCities"
            @typing="filterCities"
            @input="validateCity"
            field="name"
            @focus="filterCities(cityInput || '')"
            :keep-first="true"
            @select="
              option => {
                selectCity(option);
                model.city = option;
                city = option;
              }
            "
          >
            <template slot="empty">No results found.</template>
          </b-autocomplete>
        </b-field>
        <span
          v-for="error in errors"
          :key="error.id"
          class="has-text-danger help"
          >{{ error }}</span
        >
        <span
          v-if="!isValidCity & (cityInput !== '')"
          class="has-text-danger help"
          >Please choose the city that is closest to you from the list.</span
        >
      </ValidationProvider>
    </div>
    <div
      class="column is-full-mobile is-half-tablet is-offset-1-desktop is-4-desktop"
    >
      <ValidationProvider
        rules="required|min:5"
        v-slot="{ errors, valid }"
        name="Address"
      >
        <b-field
          id="fieldset-address"
          label="Address"
          :type="{ 'is-danger': errors[0], 'is-primary': valid }"
        >
          <b-input
            trim
            autocomplete="offsmngrandm"
            id="address-input"
            placeholder="Address"
            type="search"
            v-model="model.address"
          />
        </b-field>
        <span
          v-for="error in errors"
          :key="error.id"
          class="has-text-danger help"
          >{{ error }}</span
        >
      </ValidationProvider>
    </div>
    <div
      class="column is-full-mobile is-half-tablet is-offset-1-desktop is-4-desktop"
    >
      <ValidationProvider
        rules="required"
        v-slot="{ errors, valid }"
        name="Zip Code"
      >
        <b-field
          id="fieldset-zipcode"
          label="ZIP Code"
          message=""
          :type="{ 'is-danger': errors[0], 'is-primary': valid }"
        >
          <b-input
            trim
            autocomplete="offsmthgrndm"
            id="zipcode-input"
            placeholder="ZIP Code"
            type="search"
            v-model="model.zip"
          />
        </b-field>
        <span
          v-for="error in errors"
          :key="error.id"
          class="has-text-danger help"
          >{{ error }}</span
        >
      </ValidationProvider>
    </div>
    <div
      class="column is-full-mobile is-half-tablet is-offset-1-desktop is-4-desktop"
    >
      <ValidationProvider
        :rules="{
          required: true,
          valid_phone: !isPhoneValid
        }"
        v-slot="{ errors, valid }"
        name="Phone"
      >
        <b-field id="fieldset-phone" label="Phone" message="">
          <vue-tel-input
            v-bind="phoneProps"
            id="phone-input"
            class="input"
            v-model="model.phone"
            type="tel"
            :validCharactersOnly="true"
            @validate="validatePhone"
            :class="{ 'is-danger': errors[0], 'is-primary': valid }"
          />
        </b-field>
        <span
          v-for="error in errors"
          :key="error.id"
          class="has-text-danger help"
          >{{ error }}</span
        >
      </ValidationProvider>
    </div>
    <div
      class="column is-full-mobile is-half-tablet is-offset-1-desktop is-4-desktop"
    >
      <ValidationProvider
        rules="required|whole_number|min_years:0|min:0|max_years:99"
        v-slot="{ errors, valid }"
        name="Experience"
      >
        <b-field
          id="fieldset-experience"
          label="Experience"
          :type="{ 'is-danger': errors[0], 'is-primary': valid }"
        >
          <div class="field has-addons">
            <b-input
              expanded
              min="0"
              max="99"
              type="number"
              v-model="model.experience"
              validation-message=" "
              placeholder="Provider's experience"
            />
            <p class="control">
              <a
                class="button is-static is-offset-1-desktop is-outlined"
                :class="errors[0] ? 'is-danger' : valid ? 'is-primary' : ''"
                >years</a
              >
            </p>
          </div>
        </b-field>
        <span
          v-for="error in errors"
          :key="error.id"
          class="has-text-danger help"
          >{{ error }}</span
        >
      </ValidationProvider>
    </div>
    <div
      class="column is-full-mobile is-half-tablet is-offset-1-desktop is-4-desktop"
    >
      <ValidationProvider
        rules="required"
        v-slot="{ errors, valid }"
        name="Treatments"
      >
        <b-field
          id="fieldset-treatment"
          label="Treatments"
          message=""
          :type="{ 'is-danger': errors[0], 'is-primary': valid }"
        >
          <div
            class="menu-list-container"
            :class="errors[0] ? 'is-danger' : valid ? 'is-primary' : ''"
          >
            <ul class="menu-list scrollable">
              <li
                style="text-align: left"
                v-for="treatment in treatments.data"
                :key="treatment.id"
              >
                <b-checkbox
                  v-model="model.treatment_ids"
                  :native-value="parseInt(treatment.id)"
                >
                  {{ treatment.id }}. {{ treatment.name }}
                </b-checkbox>
              </li>
            </ul>
          </div>
        </b-field>
        <span
          v-for="error in errors"
          :key="error.id"
          class="has-text-danger help"
          >{{ error }}</span
        >
      </ValidationProvider>
    </div>

    <div
      class="column is-full-mobile is-half-tablet is-offset-1-desktop is-4-desktop"
    >
      <ValidationProvider
        rules="required"
        v-slot="{ errors, valid }"
        name="Specialties"
      >
        <b-field
          id="fieldset-specialty"
          label="Specialties"
          message=""
          :type="{ 'is-danger': errors[0], 'is-primary': valid }"
        >
          <div
            class="menu-list-container"
            :class="errors[0] ? 'is-danger' : valid ? 'is-primary' : ''"
          >
            <ul class="menu-list">
              <li
                style=""
                v-for="specialty in specialties.data"
                :key="specialty.id"
              >
                <b-checkbox
                  v-model="model.specialty_ids"
                  :native-value="parseInt(specialty.id)"
                >
                  {{ specialty.id }}. {{ specialty.name }}
                </b-checkbox>
              </li>
            </ul>
          </div>
        </b-field>
        <span
          v-for="error in errors"
          :key="error.id"
          class="has-text-danger help"
          >{{ error }}</span
        >
      </ValidationProvider>
    </div>
    <div
      class="column is-full-mobile is-half-tablet is-offset-1-desktop is-4-desktop"
    >
      <ValidationProvider
        rules="required"
        v-slot="{ errors, valid }"
        name="Description"
      >
        <b-field
          id="fieldset-description"
          label="Description"
          message=""
          :type="{ 'is-danger': errors[0], 'is-primary': valid }"
        >
          <b-input
            trim
            type="textarea"
            id="description-input"
            placeholder="Add Provider's description here"
            v-model="model.description"
          />
        </b-field>
        <span
          v-for="error in errors"
          :key="error.id"
          class="has-text-danger help"
          >{{ error }}</span
        >
      </ValidationProvider>
    </div>
  </div>
</template>

<!-- // // // //  -->

<script>
import {
  ACTIVE_COUNTRIES,
  CITIES_BY_COUNTRY_ID,
  SPECIALITIES,
  TREATMENTS
} from "../../../_graphql/Queries/queries";
import ValidationErrors from "../../../mixins/ValidationErrors";
import LocationInput from "../../../mixins/LocationInput.vue";
import { mapGetters } from "vuex";

export default {
  name: "ProviderForm",
  data() {
    return {
      name: "",
      country_id: null,
      country: null,
      city: null,
      specialties: [],
      treatments: [],
      citiesByCountryId: [],
      isValidCity: true,
      updateModel: {
        name: "",
        email: "",
        phone: "",
        description: "",
        country_id: null,
        city_id: null,
        address: "",
        zip: "",
        experience: null,
        treatment_ids: [],
        specialty_ids: []
      },
      isPhoneValid: false,
      phoneProps: {
        mode: "international",
        placeholder: "Enter a phone number",
        dropdownOptions: {
          disabledDialCode: false
        },
        enabledCountryCode: false
      }
    };
  },
  props: {
    model: {
      required: true
    },
    isValid: {
      required: true
    },
    provider: {
      required: false
    }
  },
  computed: {
    ...mapGetters({
      userRole: "userRole"
    }),
    isAdmin() {
      return this.userRole && this.userRole == "ADMINISTRATOR";
    }
  },
  methods: {
    validatePhone({ valid }) {
      this.isPhoneValid = valid;
    },
    validateCity(value) {
      let searchCity = this.citiesByCountryId.find(searchCity => {
        return searchCity.name === value;
      });
      this.isValidCity = searchCity ? true : false;
      if (searchCity) {
        this.isValidCity = true;
        this.model.city = searchCity;
      } else {
        this.isValidCity = false;
        this.model.city = null;
      }
    }
  },
  created() {
    if (this.provider) {
      (this.updateModel.name = this.provider.name),
        (this.updateModel.email = this.provider.email),
        (this.updateModel.description = this.provider.description),
        (this.updateModel.phone = this.provider.phone),
        (this.updateModel.country_id = parseInt(
          this.provider.address.locale.country.id
        )),
        (this.updateModel.city_id = parseInt(
          this.provider.address.locale.city.id
        )),
        (this.updateModel.address = this.provider.address.address),
        (this.updateModel.zip = this.provider.address.zip),
        (this.updateModel.experience = parseInt(this.provider.experience)),
        (this.updateModel.treatment_ids = this.provider.treatments.map(
          treatment => parseInt(treatment.id)
        )),
        (this.updateModel.specialty_ids = this.provider.specialties.map(
          specialty => parseInt(specialty.id)
        ));
    }
  },
  watch: {
    countryInput() {
      if (this.countryInput == "" || !this.isValidCountry) {
        this.city = null;
        this.cityInput = "";
        this.selectCity(null);
        this.filteredCities = [];
      }
    }
  },
  mixins: [ValidationErrors, LocationInput],
  apollo: {
    activeCountries: {
      query: ACTIVE_COUNTRIES,
      update(data) {
        return data.activeCountries.data;
      },
      result() {
        this.selectCountry(null);
        this.country = null;
        this.filterCountries("");
      }
    },
    citiesByCountryId: {
      query: CITIES_BY_COUNTRY_ID,
      variables() {
        return {
          country_id: parseInt(this.model.country.id)
        };
      },
      update(data) {
        return data.citiesByCountryId.data;
      },
      skip() {
        return !this.country;
      },
      result() {
        this.selectCity(null);
        this.city = null;
      }
    },
    specialties: {
      query: SPECIALITIES,
      variables: {
        input: {
          order: "id:asc"
        },
        update: data => data.specialties
      }
    },
    treatments: {
      query: TREATMENTS,
      variables: {
        update: data => data.treatments
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.menu-list {
  padding: 10px;
  height: 25rem;
  overflow: auto;
  text-align: left;
  overflow-y: scroll;

  .b-checkbox {
    align-items: flex-start;
  }
}

.vue-tel-input {
  border: 1px solid #dbdbdb;
}
</style>
