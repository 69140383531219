var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('b-modal',{attrs:{"active":_vm.isComponentModalActive,"has-modal-card":"","trap-focus":"","on-cancel":_vm.close},on:{"update:active":function($event){_vm.isComponentModalActive=$event}}},[_c('div',{staticClass:"modal-card"},[_c('header',{staticClass:"modal-card-head"},[_c('p',{staticClass:"modal-card-title has-text-weight-bold"},[_vm._v(" Create Practitioner ")])]),_c('section',{staticClass:"modal-card-body"},[(!_vm.isSubscribed)?_c('p',{staticClass:"basic-plan-description has-text-left"},[_c('a',{attrs:{"href":"/subscriptions","target":"_blank"}},[_vm._v("Upgrade")]),_vm._v(" your plan to add Main Practitioners. ")]):_vm._e(),_c('ValidationObserver',{ref:"addPractitioner"},[_c('b-field',{attrs:{"horizontal":"","label":"Name","label-for":"name"}},[_c('div',{staticClass:"control"},[_c('ValidationProvider',{attrs:{"rules":"required|max:50","name":"Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"disabled":!_vm.isSubscribed,"id":"name","placeholder":"Name","value":_vm.name},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_vm._l((errors),function(error){return _c('span',{key:error.id,staticClass:"has-text-danger help"},[_vm._v(_vm._s(error))])})]}}])})],1)]),_c('b-field',{staticClass:"avatar-field",attrs:{"label":"Avatar","label-for":"avatar","horizontal":""}},[_c('div',{staticClass:"control"},[_c('b-button',{staticClass:"is-primary",attrs:{"disabled":!_vm.isSubscribed},on:{"click":function($event){return _vm.addImage('practitionerAvatar')}}},[_vm._v(" Add image ")])],1),(_vm.avatar_url)?_c('figure',{staticClass:"container is-pulled-right"},[_c('img',{attrs:{"src":_vm.avatar_url}})]):_vm._e()]),_c('b-field',{attrs:{"label":"Degree","label-for":"degree","horizontal":""}},[_c('div',{staticClass:"control"},[_c('ValidationProvider',{attrs:{"rules":"max:250","name":"Degree"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"disabled":!_vm.isSubscribed,"placeholder":"Degree","id":"degree","type":"text","maxlength":"250","pattern":"[a-zA-Z0-9\\s,]*","message":"This field can contain up to 500 symbols"},model:{value:(_vm.degree),callback:function ($$v) {_vm.degree=$$v},expression:"degree"}}),_vm._l((errors),function(error){return _c('span',{key:error.id,staticClass:"has-text-danger help"},[_vm._v(_vm._s(error))])})]}}])})],1)]),_c('b-field',{attrs:{"label":"Specialties","label-for":"specialty","horizontal":""}},[_c('div',{staticClass:"control"},[_c('ValidationProvider',{attrs:{"rules":"required|max:500","name":"Specialty"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"disabled":!_vm.isSubscribed,"placeholder":"Specialties","id":"specialty","type":"text","maxlength":"500","pattern":"[a-zA-Z0-9\\s,]*","message":"This field can contain up to 500 symbols"},model:{value:(_vm.specialty),callback:function ($$v) {_vm.specialty=$$v},expression:"specialty"}}),_vm._l((errors),function(error){return _c('span',{key:error.id,staticClass:"has-text-danger help"},[_vm._v(_vm._s(error))])})]}}])})],1)]),_c('b-field',{attrs:{"label":"Description","label-for":"desecription","horizontal":""}},[_c('div',{staticClass:"control"},[_c('ValidationProvider',{attrs:{"rules":"required|max:1000","name":"Description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"disabled":!_vm.isSubscribed,"placeholder":"Description","id":"description","type":"textarea","maxlength":"1000","pattern":"[a-zA-Z0-9]*","message":"This field can contain up to 500 symbols"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}),_vm._l((errors),function(error){return _c('span',{key:error.id,staticClass:"has-text-danger help"},[_vm._v(_vm._s(error))])})]}}])})],1)])],1)],1),_c('footer',{staticClass:"modal-card-foot"},[_c('button',{staticClass:"button",attrs:{"type":"button"},on:{"click":_vm.close}},[_vm._v(" Close ")]),_c('button',{staticClass:"button is-primary",attrs:{"disabled":!_vm.isSubscribed},on:{"click":_vm.create}},[_vm._v("Create")])])])]),_c('AddImage',{ref:"addImage",on:{"saveImage":_vm.saveImage}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }