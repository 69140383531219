import { Role } from "../../_helpers/roles";

import createSolution from "./views/createSolution";
import editSolution from "./views/editSolution";
import Solutions from "./views/Solutions";

const createSolutionRoute = {
  path: "/solutions/create",
  name: "createSolution",
  component: createSolution,
  meta: {
    authorize: [Role.Admin, Role.Operator, Role.Moderator]
  }
};

const editSolutionRoute = {
  path: "/solutions/edit/:id",
  name: "editSolution",
  component: editSolution,
  meta: {
    authorize: [Role.Admin, Role.Operator, Role.Moderator]
  }
};

const solutionsRoute = {
  path: "/solutions",
  name: "solutions",
  component: Solutions,
  meta: {
    authorize: [Role.Admin, Role.Operator, Role.Moderator]
  }
};

export default [createSolutionRoute, editSolutionRoute, solutionsRoute];
