import Users from "./views/Users";
import CreateUser from "./views/CreateUser";
import EditUser from "./views/EditUser";
// import UsersRegistrations from "./views/UsersRegistrations";
import { Role } from "../../_helpers/roles";

const usersRoute = {
  path: "/users",
  name: "users",
  component: Users,
  meta: { authorize: [Role.Admin] }
};

const createUserRoute = {
  path: "/users/create",
  name: "createUser",
  component: CreateUser,
  meta: { authorize: [Role.Admin] }
};

const editUserRoute = {
  path: "/users/edit/:id",
  name: "editUser",
  component: EditUser,
  meta: { authorize: [Role.Admin] }
};

export default [usersRoute, createUserRoute, editUserRoute];
