<template>
  <div>
    <div class="level">
      <div class="level-left"></div>
      <div class="level-right">
        <div class="level-item" v-if="categories">
          <router-link
            :to="{
              name: 'areaTypeCat.create',
              params: { id: this.getAreaTypeId }
            }"
          >
            <b-button class="is-primary">
              Create Subcategory
            </b-button>
          </router-link>
        </div>
      </div>
    </div>
    <data-table
      v-if="categories"
      :data="aData.areaType.categories"
      class="has-table"
      :currentPage="currentPage"
      pageName="currentSubcategoriesPage"
    ></data-table>
    <view-all-treatments v-else-if="treatments" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import dataTable from "../components/DataTable";
import { AREA_NAME_AND_AREA_CATEGORIES_TREATMENTS } from "../../../_graphql/Queries/queries";
import ViewAllTreatments from "./viewAllTreatments";

export default {
  name: "viewAllSubCategories",
  props: ["data"],
  components: { ViewAllTreatments, dataTable },
  data() {
    return {
      areaTypeData: {},
      aData: {
        areaType: {
          categories: []
        }
      }
    };
  },
  mounted() {},
  apollo: {
    aData: {
      query: AREA_NAME_AND_AREA_CATEGORIES_TREATMENTS,
      variables() {
        return {
          areaId: this.getAreaId,
          areaTypeId: this.getAreaTypeId,
          input: {
            order: "id:asc"
          }
        };
      },
      update: data => data
    }
  },
  computed: {
    ...mapGetters({
      currentPage: "currentSubcategoriesPage"
    }),
    //check if there are no tratments and categories inside area Type
    checkIsEmpty() {
      return (
        typeof this.areaTypeData.categories !== "undefined" &&
        this.areaTypeData.categories.length > 0 &&
        typeof this.areaTypeData.treatments !== "undefined" &&
        this.areaTypeData.treatments.length > 0
      );
    },
    categories() {
      return (
        typeof this.aData.areaType.categories !== "undefined" &&
        this.aData.areaType.categories.length > 0
      );
    },
    treatments() {
      return (
        typeof this.aData.areaType.treatments !== "undefined" &&
        this.aData.areaType.treatments.length > 0
      );
    }
  },
  watch: {
    aData(newVal) {
      this.$apollo.queries.aData.refetch();

      let breadcrumbs = [
        {
          name: "Areas",
          link: "/areas"
        },
        {
          name: newVal.area.name ? newVal.area.name : "",
          link: "/areas/" + this.getAreaId
        },
        {
          name: newVal.areaType.name
        }
      ];
      this.$store.commit("setBreadcrumbs", breadcrumbs);
    }
  }
};
</script>
