<template>
  <article :class="type">
    <div class="media">
      <div class="media-left">
        <slot name="icon"></slot>
      </div>
      <div class="media-content">
        <slot name="message" />
      </div>
      <!--      <b-icon-->
      <!--        icon="close-circle"-->
      <!--        custom-size="default"-->
      <!--        class="has-text-grey-light"-->
      <!--      />-->
      <button
        v-if="isClosable"
        @click="closeNote"
        type="button"
        class="delete"
      ></button>
    </div>
  </article>
</template>

<script>
export default {
  props: {
    type: {
      default: ""
    },
    isClosable: {
      default: false
    },
    name: {
      default: null
    }
  },
  methods: {
    closeNote() {
      this.$emit("closedNote", this.name);
    }
  }
};
</script>

<style lang="scss" scoped>
article {
  padding: 1.125rem 1.5rem;
  position: relative;
  margin-bottom: 1.5rem;

  .media {
    align-items: center;
    display: flex;
    .media-left {
      margin-right: 1rem;
    }

    .media-content {
      text-align: left;
      overflow-x: inherit;
    }
  }
}
.info {
  background-color: rgba(12, 157, 251, 0.65);
  color: white;
  strong {
    color: white;
  }
}
.primary {
  background-color: rgb(12, 130, 218);
  color: white;
  strong {
    color: white;
  }
}

.secondary {
  background-color: rgba(12, 141, 229, 0.79);
  color: white;
  strong {
    color: white;
  }
}

.light {
  background-color: #e6eff2;
  color: #0ca1d1;
  strong {
    color: #0ca1d1;
  }
}

.delete {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
}
</style>
