import EmptyRouterSubView from "./views/EmptyRouterSubView";
import EmptyRouterView from "./views/EmptyRouterView";
import AllTreatments from "./views/AllTreatments";
import viewAllAreas from "./views/viewAllAreas";
import viewAllCategories from "./views/viewAllCategories";
import viewAllSubCategories from "./views/viewAllSubCategories";
import viewAllTreatments from "./views/viewAllTreatments";
import SubcatTreatmentsWrapper from "./views/SubcatTreatmentsWrapper";
import createDialog from "./components/createDialog";
import editDialog from "./components/editDialog";
import { Role } from "../../_helpers/roles";

const Treatments = {
  path: "/treatments",
  component: EmptyRouterSubView,
  children: [
    {
      path: "",
      name: "treatments",
      component: AllTreatments,
      meta: { authorize: [Role.Admin] }
    },
    {
      path: "create",
      name: "treatments.create",
      components: {
        default: AllTreatments,
        dialog: createDialog
      },
      meta: { authorize: [Role.Admin] }
    },
    {
      path: "update",
      name: "treatments.update",
      components: {
        default: AllTreatments,
        dialog: editDialog
      },
      meta: { authorize: [Role.Admin] }
    }
  ]
};

const AREAS_TYPES_CATEGORIES_TREATMENTS = {
  path: "/areas",
  component: EmptyRouterView,
  children: [
    {
      path: "",
      name: "areas",
      component: viewAllAreas,
      meta: { authorize: [Role.Admin], title: "Areas", addTitle: "Area" }
    },
    {
      name: "areas.update",
      path: "update",
      components: {
        default: viewAllAreas,
        dialog: editDialog
      },
      meta: { authorize: [Role.Admin] }
    },
    {
      path: ":areaId",
      name: "areaTypes",
      component: viewAllCategories,
      meta: {
        authorize: [Role.Admin],
        title: "Categories",
        addTitle: "Category"
      }
    },
    {
      name: "areaTypes.update",
      path: ":areaId/update",
      components: {
        default: viewAllCategories,
        dialog: editDialog
      }
    },
    {
      name: "areaTypes.create",
      path: ":areaId/create",
      components: {
        default: viewAllCategories,
        dialog: createDialog
      },
      meta: { authorize: [Role.Admin] }
    },
    {
      path: "",
      component: SubcatTreatmentsWrapper,
      meta: { authorize: [Role.Admin] },
      children: [
        {
          path: ":areaId/:areaTypeId",
          name: "areaTypeCat",

          component: viewAllSubCategories,
          meta: {
            title: "Subcategories",
            addTitle: "Subcategory",
            authorize: [Role.Admin]
          }
        },
        {
          name: "areaTypeTreatment",
          path: ":areaId/:areaTypeId/:areaTypeCatId",
          component: viewAllTreatments,
          meta: {
            title: "Treatments",
            addTitle: "Treatment",
            authorize: [Role.Admin]
          }
        }
      ]
    },
    {
      name: "areaTypeCat.update",
      path: ":areaId/:areaTypeId/update",
      components: {
        default: viewAllSubCategories,
        dialog: editDialog
      },
      meta: {
        authorize: [Role.Admin]
      }
    },
    {
      name: "areaTypeCat.create",
      path: ":areaId/:areaTypeId/create",
      components: {
        default: viewAllSubCategories,
        dialog: createDialog
      },
      meta: {
        authorize: [Role.Admin]
      }
    }
  ]
};

export default [Treatments, AREAS_TYPES_CATEGORIES_TREATMENTS];
