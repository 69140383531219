<template>
  <div>
    {{
      isMine
        ? "This date and time is available"
        : "Is this date and time available?"
    }}
    - {{ formattedDate }}
    <br />
    <sub>(that's {{ formattedRelativeDate }})</sub>
  </div>
</template>
<script>
import moment from "moment";
export default {
  name: "bubble-appointment",
  props: ["data", "isMine"],
  computed: {
    formattedDate() {
      const date = new Date(this.data.date);
      return moment(date).format("MMMM Do YYYY, h:mm a");
    },
    formattedRelativeDate() {
      const date = new Date(this.data.date);
      return moment(date).fromNow();
    }
  }
};
</script>
