<template>
  <section>
    <b-modal
      :active.sync="isActive"
      has-modal-card
      :on-cancel="closeModal"
      class="requestPaymentStyles"
    >
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Payment request</p>
        </header>
        <section class="modal-card-body">
          <p v-if="!isSubscribed" class="upgrade-plan-message">
            <a href="/subscriptions" target="_blank">Upgrade</a> your plan to
            request payments.
          </p>
          <ValidationObserver ref="requestPaymentValidator">
            <b-field
              label="Request amount:"
              label-for="amount"
              class="has-text-left amountStyles"
            >
              <div class="control amountStyle">
                <ValidationProvider
                  :rules="{
                    required: true,
                    regex: /^(\d+(?:[\.\,]\d{0,2})?)$/
                  }"
                  v-slot="{ errors, valid }"
                  name="Amount"
                >
                  <input
                    :disabled="!isSubscribed"
                    @input="validateAmount"
                    class="input is-primary"
                    placeholder="Requested amount"
                    v-model="amount"
                    :class="{
                      'is-danger': errors[0] || !isAmountValid,
                      'is-primary': valid
                    }"
                  />
                  <span
                    v-for="error in errors"
                    :key="error.id"
                    class="has-text-danger help"
                    >{{ error }}</span
                  >
                  <span v-if="!isAmountValid" class="has-text-danger help"
                    >Amount value must be between 1 and 50 000</span
                  >
                </ValidationProvider>
              </div>
              <div class="control">
                <div class="select is-primary">
                  <select
                    :disabled="!isSubscribed"
                    v-model="currency"
                    name="currency"
                    id="currency"
                  >
                    <option value="EUR">EUR</option>
                    <option value="GBP">GBP</option>
                    <option value="USD">USD</option>
                  </select>
                </div>
              </div>
            </b-field>
            <b-field
              label="For:"
              label-for="reason"
              class="has-text-left reasonStyle"
              horizontal
            >
              <div class="control">
                <ValidationProvider
                  rules="required"
                  v-slot="{ errors, valid }"
                  name="Reason"
                >
                  <textarea
                    :disabled="!isSubscribed"
                    id="reason"
                    class="textarea is-primary"
                    placeholder="Reason for requested payment"
                    v-model="reason"
                    required
                    :class="{ 'is-danger': errors[0], 'is-primary': valid }"
                  ></textarea>
                  <span
                    v-for="error in errors"
                    :key="error.id"
                    class="has-text-danger help"
                    >{{ error }}</span
                  >
                </ValidationProvider>
              </div>
            </b-field>
            <b-field class="has-text-left">
              <b-checkbox
                :disabled="!isSubscribed"
                v-model="notifyProvider"
                type="is-primary"
              >
                Email me when payment has been made
              </b-checkbox>
            </b-field>
          </ValidationObserver>
        </section>
        <footer class="modal-card-foot">
          <button class="button" type="button" @click="closeModal">
            Cancel
          </button>
          <button
            :disabled="!isSubscribed"
            class="button is-primary"
            @click="sendRequest"
          >
            Send
          </button>
        </footer>
      </div>
    </b-modal>
  </section>
</template>

<script>
import ValidationErrors from "../../../mixins/ValidationErrors";
export default {
  data() {
    return {
      isActive: false,
      amount: null,
      currency: "EUR",
      reason: "",
      notifyProvider: true,
      isAmountValid: true
    };
  },
  props: ["isSubscribed"],
  computed: {
    paymentMetadata() {
      let amount = this.amount.includes(",")
        ? this.amount.replace(",", ".")
        : this.amount;
      if (amount.endsWith(".")) {
        amount = amount.slice(0, -1);
      }
      return {
        type: "PAYMENT",
        state: "PENDING",
        amount: amount,
        currency: this.currency,
        reason: this.reason,
        notifyProvider: this.notifyProvider
      };
    }
  },
  methods: {
    openModal() {
      this.isActive = true;
    },
    closeModal() {
      this.isActive = false;
      this.resetModal();
    },
    sendRequest() {
      if (!this.isSubscribed || !this.isAmountValid) {
        return;
      }

      this.$refs.requestPaymentValidator.validate().then(success => {
        if (!success) {
          this.refName = "requestPaymentValidator";
          this.handleErrors();
          return;
        }
        if (this.amount < 1 || this.amount > 50000) {
          return this.$buefy.toast.open({
            message: "The amount must be between 1 and 50 000",
            type: "is-danger"
          });
        }

        this.$emit("requestedPayment", this.paymentMetadata);
        this.closeModal();
        this.resetModal();
      });
    },
    resetModal() {
      this.amount = null;
      this.currency = "EUR";
      this.reason = "";
      this.notifyProvider = true;
    },
    validateAmount() {
      let amount = this.amount.includes(",")
        ? this.amount.replace(",", ".")
        : this.amount;
      if (amount < 1 || amount > 50000) {
        this.isAmountValid = false;
      } else this.isAmountValid = true;
    }
  },
  mixins: [ValidationErrors]
};
</script>

<style lang="scss" scoped>
.upgrade-plan-message {
  margin-bottom: 20px;
}
.modal-card-foot {
  display: flex;
  justify-content: flex-end;
}
</style>
