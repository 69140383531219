import { render, staticRenderFns } from "./PasswordUpdateForm.vue?vue&type=template&id=77802f66&scoped=true&"
import script from "./PasswordUpdateForm.vue?vue&type=script&lang=js&"
export * from "./PasswordUpdateForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77802f66",
  null
  
)

export default component.exports