<template>
  <div class="box">
    <div class="card">
      <div class="card-body">
        <TagsInput
          :avoidDuplicates="false"
          :allowEditTags="allowUpdate"
          :placeholder="`Add new`"
          :itemTags="data.items"
          :createItem="addItem"
          :updateItem="updateItem"
          :deleteItem="deleteItem"
        />
      </div>
    </div>
  </div>
</template>

<script>

import TagsInput from "@/modules/providers/components/TagsInput";

export default {
  data() {
    return {
      item: "",
      id: null,
    };
  },
  components: { TagsInput },
  props: ["data", "allowUpdate"],
  methods: {
    addItem(item, id, addTag) {
      if (!item) {
        return;
      }

      this.$emit("addItem", { type: this.data.itemType, value: item.text });
      addTag();
    },
    updateItem(item) {
      if (this.allowUpdate) {
        this.$apollo
          .mutate({
            mutation: this.data.mutations.edit.mutation,
            variables: {
              id: item.id,
              input: {
                name: item.name
              }
            },
            error: ({ error }) => {
              return this.$buefy.toast.open({
                message: error,
                type: "is-danger"
              });
            }
          })
          .catch(error => {
            return this.$buefy.toast.open({
              message: error,
              type: "is-danger"
            });
          })

          .then(({ data }) => {
            if (data[this.data.mutations.edit.name]) {
              this.$buefy.toast.open({
                message: "Updated successfully",
                type: "is-success"
              });
            }
          });
      }
    },
    deleteItem(item) {
      if (this.allowUpdate) {
        this.$apollo
          .mutate({
            mutation: this.data.mutations.delete.mutation,
            variables: {
              id: item.id
            },
            error: ({ error }) => {
              return this.$buefy.toast.open({
                message: error,
                type: "is-danger"
              });
            }
          })
          .catch(error => {
            return this.$buefy.toast.open({
              message: error,
              type: "is-danger"
            });
          })
          .then(({ data }) => {
            if (data[this.data.mutations.delete.name]) {
              this.$emit("deletedItem");
              this.$buefy.toast.open({
                message: "Deleted successfully",
                type: "is-success"
              });
            }
          });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.box {
  margin-bottom: 20px;
}
.card {
  /*max-height: 500px;*/
  /*overflow-y: scroll;*/

  .items-list {
    padding: 0 10px 10px;
    max-height: 13rem;
    overflow: auto;
    text-align: left;
    overflow-y: scroll;
    li {
      border-bottom: 1px solid whitesmoke;
      align-items: center;
      word-break: break-word;
      display: flex;
      justify-content: space-between;
    }
    .button {
      padding: 0.5rem;
    }
  }

  .add-section {
    display: flex;
    justify-content: space-between;

    .control {
      width: 100%;
    }

    .custom-icon {
      width: 34px;
    }

    .button {
      padding-right: 0;

      &:focus:not(:active) {
        box-shadow: none !important;
      }
    }
  }

  .button {
    border: none;
  }
  .buttons {
    min-width: 4rem;
  }

  .hr {
    width: 100%;
  }
}
</style>
