<template>
  <div>
    <div>
      <div class="cover-image-container">
        <div
          :style="{ backgroundImage: `url(${coverImageUrl})` }"
          class="cover-photo"
        />
      </div>
      <b-button
        class="upload-button has-text-primary"
        v-if="!isCoverImgChanged && allowUpdate"
        @click="openImageModal('cover_image')"
      >
        <b-icon size="is-default" icon="pencil" />
      </b-button>
      <div class="column is-12 custom" id="avatar-image">
        <img
          v-if="!isAvatarChanged"
          :src="avatarImageUrl"
          alt="Avatar image"
          class="provider-thumbnail"
        />
        <b-button
          class="upload-button has-text-primary"
          v-if="!isCoverImgChanged && allowUpdate"
          @click="openImageModal('avatar')"
        >
          <b-icon size="is-small" icon="pencil" />
        </b-button>
      </div>
      <div
        id="avatar-buttons"
        v-if="isAvatarChanged || isCoverImgChanged"
        class="buttons is-centered"
      >
        <b-button @click="addImage" class="is-primary ">Save changes</b-button>
        <b-button @click="cancelImage" class="is-primary is-outlined"
          >Cancel</b-button
        >
      </div>
    </div>
    <AddImage @saveImage="addImage" ref="addImage"></AddImage>
  </div>
</template>
<script>
import { UPDATE_PROVIDER } from "../../../_graphql/Mutations/mutations";
import { PROVIDER } from "../../../_graphql/Queries/queries";
import "vue-advanced-cropper/dist/style.css";
import AddImage from "./AddImage";
export default {
  data() {
    return {
      defaultCoverImage: require("../../../assets/img/cover-image.png"),
      defaultAvatarImage: require("../../../assets/img/avatar.png"),
      avatar_url: "",
      isAvatarChanged: false,
      coverImage: null,
      cover_image_url: "",
      isCoverImgChanged: false
    };
  },
  components: {
    AddImage
  },
  props: ["info", "userRole", "allowUpdate"],
  computed: {
    coverImageUrl() {
      if (!this.isCoverImgChanged) {
        return this.info.cover_image_url
          ? this.info.cover_image_url
          : this.defaultCoverImage;
      } else {
        return this.coverImage.url;
      }
    },
    avatarImageUrl() {
      return this.info.avatar_url
        ? this.info.avatar_url
        : this.defaultAvatarImage;
    }
  },
  methods: {
    openImageModal(type) {
      this.$refs.addImage.openModal(type);
    },
    cancelImage() {
      this.isAvatarChanged = false;
      this.coverImage = null;
      this.isCoverImgChanged = false;
    },
    async addImage(data) {
      const mutation = UPDATE_PROVIDER;
      const query = PROVIDER;
      let variables = {
        id: parseInt(this.$route.params.providerId),
        input: {}
      };
      if (data.type == "avatar") {
        variables.input = {
          avatar_url: data.image.url
        };
      }
      if (data.type == "cover_image") {
        variables.input = {
          cover_image_url: data.image.url
        };
      }
      const payload = {
        mutation: mutation,
        query: query,
        queryName: "updateProvider"
      };
      this.$store.commit("provider/collection/editModel", variables);
      await this.$store.dispatch("provider/collection/update", payload);
      this.$emit("addedAvatar");
      this.isAvatarChanged = false;
      this.isCoverImgChanged = false;
      this.coverImage = null;
    }
  }
};
</script>
<style lang="scss" scoped>
#avatar-buttons {
  margin-top: 1rem;
}
.cropper {
  width: 150px;
  height: 150px;
  margin-top: -85px;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.05);
  @media (max-width: 576px) {
    width: 120px;
    height: 120px;
  }
  @media (min-width: 769px) {
    width: 180px;
    height: 180px;
  }
}
.cover-image-container {
  position: relative;
  width: 100%;
  padding-top: 32.7%;
}

.upload-button {
  display: flex;
  margin-left: auto;
}
.upload {
  position: relative;
  bottom: 24px;
  right: 0;
  font-size: 18px;
  a {
    position: absolute;
  }
}
#avatar {
  display: flex;
  margin-bottom: 1rem;
  .provider-thumbnail {
    margin-top: 0;
  }
  img {
    object-fit: contain;
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.05);
  }
  .upload {
    top: 14rem;
    position: absolute;
    margin-left: 9rem;
    @media (max-width: 576px) {
      margin-left: 7.5rem;
      top: 12.5rem;
    }
    @media (min-width: 769px) {
      margin-left: 10.5rem;
      top: 16rem;
    }
  }
  .provider-thumbnail {
    border-radius: 50%;
    width: 150px;
    height: 150px;
    @media (max-width: 576px) {
      width: 120px;
      height: 120px;
    }
    @media (min-width: 769px) {
      width: 180px;
      height: 180px;
    }
  }
}
#avatar-image {
  img {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
    object-fit: contain;
    z-index: 10;
  }
  .upload {
    position: absolute;
    font-size: 18px;
    margin-left: 9.5rem;
    @media (max-width: 576px) {
      margin-left: 8rem;
    }
  }
  .upload-button {
    top: -10px;
    left: -10px;
    margin-left: 0;
  }
}
.cover-photo {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 16px;
  border-radius: 16px;
}
.custom {
  display: flex;
  padding: 0rem 1rem;
}
.provider-thumbnail {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  margin-top: -120px;
  @media (max-width: 576px) {
    width: 120px;
    height: 120px;
    margin-top: -85px;
  }
}
.upload-button {
  border: none;
  background-color: unset;
}
</style>
