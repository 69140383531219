<template>
  <div class="card products">
    <header class="card-header has-background-customBackground">
      <div class="card-header-title">
        <p class="has-text-primary">
          <b-icon icon="account-multiple" custom-size="default" />
          Products
          <b-tooltip
              animated
              multilined
              class="tooltip"
              label="People want to see what you offer! List the brand names of the products that you use or offer. These will appear on your profile. Suggestions appear as you type in each category.  Select from the list or add your own new ones."
              position="is-top"
          ><b-icon icon="information-outline" type="is-primary"
          /></b-tooltip>
        </p>
      </div>
    </header>
    <div class="card-content has-text-left">
      <div
        v-if="productCategories.length > 0"
        class="card-content has-text-left"
      >
        <div
          class="tag-input"
          v-for="(category, index) in productCategories"
          :key="index"
        >
          <div>
            {{ category.name }}
          </div>
          <TagsInput
            :allowEditTags="allowUpdate"
            :categoryId="parseInt(category.id)"
            :placeholder="`Add new ${category.name === 'Other' ? 'products' : category.name}`"
            :itemTags="providerProducts[category.id] ? providerProducts[category.id].products : []"
            :autocompleteList="systemProductsByCategory[category.id] ? systemProductsByCategory[category.id].products : []"
            :updateItem="update"
            :createItem="create"
            :deleteItem="deleteProduct"
          />
          <hr class="solid">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {PRODUCT_CATEGORIES, PRODUCTS} from "../../../_graphql/Queries/queries";
import {
  CREATE_PRODUCT,
  DELETE_PRODUCT,
  DELETE_PROVIDER_PRODUCT,
  UPDATE_PRODUCT,
  UPDATE_PROVIDER
} from "@/_graphql/Mutations/mutations";
import TagsInput from "@/modules/providers/components/TagsInput";

export default {
  data() {
    return {
      showDetailIcon: true,
      products: [],
      systemProductsByCategory: [],
      productCategories: []
    };
  },
  props: {
    allowUpdate: {
      default: false
    },
    provider: {
      required: false
    }
  },
  components: { TagsInput },
  computed: {
    providerProducts() {
      return this.groupByCategoryId(this.provider.products);
    },
    providerProductCategories() {
      return this.provider.products.map(prod => prod.product_categories_id);
    },
    product_ids() {
      if (this.provider) {
        return this.provider.products.map(product => {
          return parseInt(product.id);
        });
      }
      return [];
    },
    getFilter() {
      return {
        filter: {
          provider_id: this.provider.id
        }
      };
    }
  },
  methods: {
    async create(product, categoryId, addTag) {
      const providerId = parseInt(this.$route.params.providerId);

      if (product && product.hasOwnProperty('provider_id') && product.provider_id == null) {
        this.updateProviderProducts(
            providerId,
            parseInt(product.id)
        ).then(() => {
          addTag();
          this.$emit("updatedProducts");
        });

        return;
      }

      const variables = {
        input: {
          provider_id: providerId,
          name: product.text,
          product_categories_id: categoryId
        }
      };

      await this.mutate(CREATE_PRODUCT, variables)
        .then(({ data }) => {

          if (!data.createProduct) {
            return;
          }

          addTag();
          this.$emit("updatedProducts")
          this.$buefy.toast.open({
            message: "Updated successfully!",
            type: "is-success"
          });
        })
        .catch(error => {
          this.$buefy.toast.open({
            message: `${error}`,
            type: "is-danger"
          });
        });
    },
    async updateProviderProducts(providerId, productId) {
      const createdProduct = {
        products: [productId]
      };

      const payload = {
        mutation: UPDATE_PROVIDER,
        queryName: "updateProvider"
      };

      const variables = {
        id: providerId,
        input: createdProduct
      };

      this.$store.commit("provider/collection/editModel", variables);
      await this.$store.dispatch("provider/collection/update", payload);
      // this.$emit("updatedProducts");
    },
    deleteProduct(item) {
      if (item.isSystemOwned) {
        const providerProductId = parseInt(item.id);
        this.deleteSystemProduct(providerProductId);
      } else {
        this.deleteCustomProduct(item);
      }
    },
    async deleteSystemProduct(providerProductId) {
      const mutation = DELETE_PROVIDER_PRODUCT;
      const variables = {
        id: providerProductId
      };

      this.$store.commit("provider/collection/deleteModel", variables);
      await this.$store.dispatch("provider/collection/delete", mutation);
    },
    async deleteCustomProduct(item) {
      const mutation = DELETE_PRODUCT;
      const variables = {
        id: parseInt(item.product_id)
      };
      this.$store.commit("provider/collection/deleteModel", variables);
      await this.$store.dispatch("provider/collection/delete", mutation);
    },
    async update(item, categoryId) {
      const payload = {
        mutation: UPDATE_PRODUCT,
        queryName: "updateProduct"
      };

      const variables = {
        id: parseInt(item.product_id),
        input: {
          name: item.name,
          product_categories_id: categoryId
        }
      };
      this.$store.commit("provider/collection/editModel", variables);
      await this.$store.dispatch("provider/collection/update", payload);
      this.$emit("updatedProducts");
    },
    groupByCategoryId(products) {
      return products.reduce((objectsByKeyValue, obj) => {
        const value = obj["product_categories_id"];

        objectsByKeyValue[value] = {
          id: obj.product_categories_id,
          products: (objectsByKeyValue[value]
                  ? objectsByKeyValue[value].products
                  : []
          ).concat(obj)
        };

        return objectsByKeyValue;
      }, {});
    },
  },
  apollo: {
    products: {
      query: PRODUCTS,
      update: data => data.products,
      variables() {
        return {
          input: this.getFilter
        };
      },
      result(result) {
        const systemProducts = result.data.products.data.filter(product => product.provider_id === null)
        this.systemProductsByCategory = this.groupByCategoryId(systemProducts);
      }
    },
    productCategories: {
      query: PRODUCT_CATEGORIES,
      variables: {
        update: data => data.productCategories
      },
      result(result) {
        const sortedArray = ["Injectables", "Cosmeceuticals", "Implants", "Dental", "Other"];
        this.productCategories = result.data.productCategories.data.sort((a, b) => {
          return sortedArray.indexOf(a.name) - sortedArray.indexOf(b.name);
        });
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.products {
  .card-header-title {
    justify-content: space-between;
  }

  .b-tooltip {
    vertical-align: middle;
  }

  .custom-icon {
    width: 34px;
  }
}
</style>
