<template>
  <div></div>
</template>

<script>
export default {
  name: "bubble-warning",
  props: ["data", "isActive"],
  mounted() {
    if (this.isActive) {
      this.$buefy.dialog.alert({
        title: "WARNING MESSAGE",
        type: "is-warning",
        message: this.isInaccurate
          ? "Your profile or chat information was flagged as being inaccurate.  Please review your profile and ensure the details are correct in relation to the treatments offered at your practice and the specialties available. <br> Please note, if the information on your MeTime profile is not accurate, you risk losing your profile and will be unable to receive new matches. <br><br> The MeTime Team"
          : "Your chat on MeTime was flagged by a user as inappropriate. <br> Please note, inappropriate comments or requests with users canbe flagged and recorded on MeTime and lead to you losing yourprofile. <br><br> The MeTime Team",
        hasIcon: true,
        icon: "information",
        confirmText: "Accept",
        onConfirm: this.acceptWarning
      });
    }
  },
  computed: {
    isInaccurate() {
      let meta = JSON.parse(this.data.metadata);
      return meta.reason.id == 1 || meta.reason.id == 2;
    }
  },
  methods: {
    acceptWarning() {
      this.$emit("accept");
    }
  }
};
</script>
