/* eslint-disable */
<template>
  <aside
      v-show="isAsideVisible && meUser.user"
      class="aside scroll is-placed-left is-expanded"
  >
    <div class="image">
      <router-link to="/">
        <img id="logo" src="../../../assets/img/Logo.svg"/>
      </router-link>
    </div>
    <div
        v-show="isVendor && hasProviders"
        class="plan-preview has-text-centered"
    >
      <div class="plan-preview-box">{{ getProviderCurrentPlan() }} Plan</div>
    </div>
    <div class="menu is-menu-main">
      <aside-menu-list @menu-click="menuClick" :menu="modifyMenuList(menu)"/>
    </div>
    <SubscriptionPlanWidget
        v-if="isVendor && hasProviders"
        :currentPlan="getProviderCurrentPlan()"
        :provider="getProvider"
    />
  </aside>
</template>

<script>
import {mapState, mapGetters} from "vuex";
import SubscriptionPlanWidget from "@/modules/base/components/SubscriptionPlanWidget";

export default {
  name: "AsideMenu",
  props: {},
  components: {SubscriptionPlanWidget},
  data() {
    return {
      showBanner: true,
      adminMenu: [
        {
          to: "/dashboard",
          icon: "desktop-mac",
          label: "Dashboard"
        },
        {
          label: "Providers",
          subLabel: "Provider Submenus",
          icon: "view-list",
          menu: [
            {
              to: "/providers/approve",
              label: "Ready for Approval",
              submenu: true
            },
            {
              to: "/providers/list",
              label: "All Providers",
              submenu: true
            },
            {
              to: "/providers/guest",
              label: "Guest Providers",
              submenu: true
            }
          ]
        },
        {
          to: "/areas",
          label: "Areas",
          icon: "view-list"
        },
        {
          to: "/treatments",
          label: "Treatments",
          icon: "view-list"
        },
        {
          label: "Users",
          to: "/users",
          icon: "account-circle"
        },
        {
          label: "Flagged",
          to: "/flagged",
          icon: "flag"
        },

        {
          to: "/payments",
          label: "Payments",
          icon: "bank"
        },
        {
          label: "Administration",
          subLabel: "Administration Submenus",
          icon: "view-list",
          menu: [
            {
              to: "/settings",
              label: "Settings",
              submenu: true
            },
            {
              to: "/articles",
              label: "Articles",
              submenu: true
            },
            {
              to: "/pages",
              label: "Pages",
              submenu: true
            },
            {
              to: "/components",
              label: "Components",
              submenu: true
            },
            {
              to: "/solutions",
              label: "Solutions",
              submenu: true
            }
          ]
        }
      ],
      vendorMenu: [
        {
          to: "/dashboard",
          icon: "desktop-mac",
          label: "Dashboard"
        },
        {
          label: "Profile",
          icon: "view-list",
          to: "/providers/list"
        },
        {
          label: "Messages",
          icon: "message",
          updateMark: false,
          menu: [
            {
              href: "/messages/active",
              label: "Active"
            },
            {
              to: "/messages/archived",
              label: "Archived"
            }
          ]
        },
        {
          to: "/payments",
          label: "Payments",
          icon: "bank"
        },
        {
          label: "Subscriptions",
          icon: "credit-card",
          to: "/subscriptions"
        }
      ],
      operatorsMenu: [
        {
          label: "Providers",
          subLabel: "Provider Submenus",
          icon: "view-list",
          menu: [
            {
              to: "/providers/guest",
              label: "Guest Providers"
            }
          ]
        },
        {
          to: "/articles",
          icon: "view-list",
          label: "Articles"
        },
        {
          to: "/solutions",
          icon: "view-list",
          label: "Solutions"
        }
      ],
      moderatorsMenu: [
        {
          label: "Providers",
          subLabel: "Provider Submenus",
          icon: "view-list",
          menu: [
            {
              to: "/providers/guest",
              label: "Guest Providers"
            }
          ]
        },
        {
          to: "/solutions",
          icon: "view-list",
          label: "Solutions"
        }
      ],
      provider: null
    };
  },
  created() {
    this.PRODUCT_TYPE_BASIC = "Basic";
  },
  computed: {
    ...mapGetters(["meUser"]),
    ...mapState(["isAsideVisible"]),
    ...mapGetters(["userRole"]),
    ...mapGetters(["myProviders"]),
    isVendor() {
      return this.userRole && this.userRole === "VENDOR";
    },
    hasProviders() {
      return this.myProviders.length > 0;
    },
    getProvider() {
      return this.myProviders.length > 0 ? this.myProviders[0] : null;
    },
    menu() {
      let menu = [];
      if (this.meUser.user) {
        switch (this.meUser.user.role) {
          case "ADMINISTRATOR":
            menu = this.adminMenu;
            break;
          case "VENDOR":
            menu = this.vendorMenu;
            break;
          case "OPERATOR":
            menu = this.operatorsMenu;
            break;
          case "MODERATOR":
            menu = this.moderatorsMenu;
            break;
          default:
            menu = [];
            break;
        }
      }
      return menu;
    }
  },
  methods: {
    closeBanner() {
      this.showBanner = false
    },
    menuClick() {
      return {};
    },
    getProviderCurrentPlan() {
      const provider = this.getProvider;
      return provider && provider.is_subscribed
          ? provider.subscription_plan
          : this.PRODUCT_TYPE_BASIC;
    },
    modifyMenuList(menu) {
      if (this.isVendor && !this.hasProviders) {
        return menu.filter(menuItem => menuItem.label !== "Subscriptions");
      }

      return menu;
    }
  }
};
</script>
<style scoped>
.presentation-banner {
  width: 100%;
  max-width: 160px;
  margin: 0 auto;
  max-height: 360px;
  border-radius: 6px;
  overflow: hidden;
  height: 100%;
  position: absolute;
  display: flex;
  z-index: 100;
  right: 24px;
  bottom: 10px;
}

.presentation-banner-img {
  object-fit: cover;
}
</style>
<style>
@import "../../../../node_modules/bulma";

.plan-preview {
  margin: 0 auto;
  max-width: 150px;
}

.plan-preview-box {
  background-color: #e6eff2;
  width: 150px;
  height: 50px;
  border-radius: 3px;
  line-height: 50px;
  font-weight: 600;
}

#logo {
  max-width: 5.95rem;
}

.scroll {
  overflow: auto;
}
</style>
