<template>
  <section>
    <b-modal
        :active.sync="isActive"
        has-modal-card
        trap-focus
        :on-cancel="close"
    >
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Update Procedure {{ data.name }}</p>
        </header>
        <section class="modal-card-body">
          <ValidationObserver ref="editProcedure">
            <b-field horizontal label="Area">
              <div class="control">
                <ValidationProvider
                    rules="required|max:50"
                    v-slot="{ errors }"
                    name="Area"
                >
                  <b-select
                      id="area_id"
                      placeholder="Select area"
                      v-model="localData.procedure_area_id"
                      required
                      expanded
                  >
                    <option
                        v-for="proceduresArea in proceduresAreas.data"
                        :value="proceduresArea.id"
                        :key="proceduresArea.id"
                    >
                      {{ proceduresArea.name }}
                    </option>
                  </b-select>
                  <span
                      v-for="error in errors"
                      :key="error.id"
                      class="has-text-danger help"
                  >{{ error }}</span>
                </ValidationProvider>
              </div>
            </b-field>
            <b-field horizontal label="Name" label-for="name">
              <div class="control">
                <ValidationProvider
                    rules="required|max:50"
                    v-slot="{ errors }"
                    name="Name"
                >
                  <b-input
                      id="name"
                      placeholder="Name"
                      v-model="localData.name"
                      :value="localData.name"
                  />
                  <span
                      v-for="error in errors"
                      :key="error.id"
                      class="has-text-danger help"
                  >{{ error }}</span
                  >
                </ValidationProvider>
              </div>
            </b-field>
          </ValidationObserver>
        </section>
        <footer class="modal-card-foot">
          <button class="button" type="button" @click="close">
            Close
          </button>
          <button class="button is-primary" @click="update">Update</button>
        </footer>
      </div>
    </b-modal>
  </section>
</template>

<script>
import ValidationErrors from "../../../mixins/ValidationErrors";
import LocalStateData from "../../../mixins/LocalStateData";
import { UPDATE_PROCEDURE } from "../../../_graphql/Mutations/mutations";
import { PROCEDURES_AREAS } from "@/_graphql/Queries/queries";

export default {
  data() {
    return {
      isActive: false,
      name: "",
      area_id: "",
      proceduresAreas: [],
    };
  },
  apollo: {
    proceduresAreas: {
      query: PROCEDURES_AREAS,
      variables: {
        update: data => data.proceduresAreas
      }
    }
  },
  methods: {
    openModal() {
      this.isActive = true;
    },
    async update() {
      this.$refs.editProcedure.validate().then(async success => {
        if (!success) {
          this.refName = "editProcedure";
          this.handleErrors();
          return;
        }

        const payload = {
          mutation: UPDATE_PROCEDURE,
          queryName: "updateProvider"
        };
        const variables = {
          id: parseInt(this.data.id),
          input: {
            name: this.localData.name,
            procedures_areas_id: parseInt(this.localData.procedure_area_id),
          }
        };

        this.$store.commit("provider/collection/editModel", variables);
        await this.$store.dispatch("provider/collection/update", payload);
        this.close();
        this.$emit("updatedProcedures");
      });
    },
    close() {
      this.isActive = false;
      this.$refs.editProcedure.reset();
      this.resetLocalData();
    }
  },
  mixins: [ValidationErrors, LocalStateData],
};
</script>
