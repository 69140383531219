<script>
import _ from "lodash";

export default {
  name: "LocationInput",
  data() {
    return {
      countryInput: "",
      filteredCountries: [],
      countrySelected: false,
      cityInput: "",
      filteredCities: [],
      citySelected: false
    };
  },
  computed: {
    isValidCountry() {
      return this.activeCountries.find(
        c => c.name.toLowerCase() == this.countryInput.toLowerCase()
      );
    }
  },
  methods: {
    filterCountries: _.debounce(function(countryName) {
      this.selectCity("");
      this.filteredCountries = this.activeCountries.filter(c =>
        c.name.toLowerCase().startsWith(countryName.toLowerCase())
      );
    }, 150),
    selectCountry(country) {
      if (country) {
        this.countryInput = country.name;
        this.filterCountries(country.name, true);
        this.countrySelected = true;
      } else {
        this.countryInput = "";
        this.filterCountries("");
      }
    },
    getMoreCities() {
      let data = _.uniqBy(
        this.citiesByCountryId.filter(c => {
          return c.name.toLowerCase().startsWith(this.cityInput.toLowerCase());
        }),
        "name"
      );

      if (this.filteredCities.length == data.length) {
        return;
      }

      let list = this.filteredCities;
      const from = list.length;
      const to =
        data.length - list.length >= 500 ? list.length + 499 : data.length - 1;
      for (let i = from; i <= to; i++) {
        list.push(data[i]);
      }
      this.filteredCities = list;
    },
    filterCities: _.debounce(function(cityName) {
      this.filteredCities = [];
      setTimeout(() => {
        this.filteredCities = _.uniqBy(
          this.citiesByCountryId.filter(c => {
            return c.name.toLowerCase().startsWith(cityName.toLowerCase());
          }),
          "name"
        ).slice(0, 500);
      }, 5);
    }, 0),
    selectCity(city) {
      if (city) {
        this.cityInput = city.name;
        this.filterCities(city.name, true);
        this.citySelected = true;
      } else if(city === null) {
        this.cityInput = "";
        this.filterCities("");
        this.citySelected = false;
      }
    }
  }
};
</script>
