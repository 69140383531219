<template>
  <div class="card" id="review-section">
    <header class="card-header">
      <p class="card-header-title has-text-primary">
        <b-icon icon="account-card-details" custom-size="default" />
        Review
      </p>
    </header>
    <div class="columns is-multiline card-content">
      <div class="column has-text-left is-12-mobile is-12-tablet is-3-desktop">
        <b-field id="status" label="Current Status" type="is-primary">
          <div class="statusContent">
            <div v-if="currentStatus" class="guest_status">
              {{ currentStatus }}
            </div>
          </div>
        </b-field>
        <ValidationProvider
          v-slot="{ errors, valid }"
          name="Change status"
        >
          <b-field
            label="Change status"
            type="is-primary"
            class="change-status-field"
          >
            <b-select
              class="is-primary"
              v-model="newStatus"
              @blur="checkForChanges('newStatus')"
              placeholder="Select..."
            >
              <option
                :value="newStatus.value"
                v-for="(newStatus, index) in guestStatuses"
                :key="index"
              >
                {{ newStatus.name }}
              </option>
            </b-select>
          </b-field>
          <span
            v-for="error in errors"
            :key="error.id"
            class="has-text-danger help"
            >{{ error }}</span
          >
        </ValidationProvider>
      </div>
      <ValidationProvider
        class="column has-text-left is-12-mobile is-12-tablet is-9-desktop"
        v-slot="{ errors, valid }"
        name="Note"
      >
        <b-field label="Note" label-for="note" type="is-primary">
          <b-input
            id="note"
            type="textarea"
            rows="6"
            maxlength="65535"
            v-model="note"
            @blur="checkForChanges('note')"
          >
          </b-input>
        </b-field>
        <span
          v-for="error in errors"
          :key="error.id"
          class="has-text-danger help"
          >{{ error }}</span
        >
      </ValidationProvider>
      <b-field
        label=""
        v-if="showUpdateBtn"
        class="column is-12-mobile is-12-tablet is-12-desktop updateField"
      >
        <b-button @click="saveInfo" class="is-primary">
          Update
        </b-button>
      </b-field>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentStatus: "Draft",
      newStatus: null,
      note: ""
    };
  },
  props: [
    "queryInfo",
    "userRole",
    "statuses",
    "showUpdateBtn",
    "type"
  ],
  computed: {
    isVendor() {
      return this.userRole && this.userRole == "VENDOR";
    },
    isAdmin() {
      return this.userRole && this.userRole == "ADMINISTRATOR";
    },
    isOperator() {
      return this.userRole && this.userRole == "OPERATOR";
    },
    isModerator() {
      return this.userRole && this.userRole == "Moderator";
    },
    guestStatuses() {
      return this.isAdmin
        ? this.statuses.adminStatuses
        : this.isOperator
        ? this.statuses.operatorStatuses
        : this.statuses.moderatorStatuses;
    }
  },
  methods: {
    checkForChanges(value) {
      if (this.queryInfo) {
        if (this[value] !== this.queryInfo[value]) {
          this.$emit("editedField");
        }
      }
    },
    guestStatus(item) {
      let status;
      if (this.type && this.type == "Solution") {
        status = this.statuses.adminStatuses.find(s => s.value == item.status);
      } else {
        status = this.statuses.adminStatuses.find(
          s => s.value == item.guest_status
        );
      }

      this.currentStatus = status.name;
    },
    saveInfo() {
      if (this.note.length > 65535) {
        return this.$buefy.toast.open({
          message: "The maximum number of characters is 65 535",
          type: "is-danger"
        });
      }
      this.$emit("saveInfo", {
        guest_status: this.newStatus
          ? this.newStatus
          : this.queryInfo.guest_status,
        note: this.note
      });
    }
  },
  mounted() {
    if (this.queryInfo) {
      this.note = this.queryInfo.note ? this.queryInfo.note : "";
      this.guestStatus(this.queryInfo);
    }
  },
  watch: {
    queryInfo(newVal) {
      if (newVal) {
        this.note = newVal.note;
        if (this.type && this.type == "Solution") {
          this.currentStatus = this.statuses.adminStatuses.find(
            s => s.value == newVal.status
          ).name;
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.updateField {
  text-align: right;
}

.change-status-field {
  margin-top: 2rem;
}
.statusContent {
  display: flex;
  align-items: center;

  .guest_status {
    margin-right: 1rem;
    text-align: left;
  }
}
</style>
