<template>
  <section class="section">
    <form class="container is-fluid" @submit="createModel">
      <div class="is-title-bar">
        <div class="level-left">
          <div class="level-item">
            <h1 class="title has-text-secondary">
              Create Provider
            </h1>
          </div>
        </div>
      </div>
      <hr />
      <div class="card">
        <header class="card-header">
          <p class="card-header-title has-text-primary">
            <b-icon icon="ballot" custom-size="default" />
            Provider Info
          </p>
        </header>
        <div class="card-content">
          <ValidationObserver :ref="refName">
            <ProviderForm :model="model" :isValid="isValid" />
          </ValidationObserver>
          <div
            v-if="isAdmin"
            class="column is-full-mobile is-half-tablet is-offset-1-desktop is-4-desktop is_guest-checkbox"
          >
            <b-field>
              <b-checkbox id="is_guest" v-model="is_guest">
                Guest Provider
              </b-checkbox>
            </b-field>
          </div>
        </div>
      </div>

      <hr />

      <div class="buttons is-right">
        <button class="button is-primary" type="submit" :disabled="clicked">
          <i class="fa fa-fw fa-plus"></i>
          Create Provider
        </button>

        <button
          class="button is-primary is-outlined"
          @click.prevent="$router.go(-1)"
        >
          <i class="fa fa-fw fa-times"></i>
          Cancel
        </button>
      </div>
    </form>
  </section>
</template>

<!-- // // // //  -->

<script>
import { mapGetters } from "vuex";
import ProviderForm from "../components/ProviderForm";
import ValidationErrors from "../../../mixins/ValidationErrors";
import { CREATE_PROVIDER } from "../../../_graphql/Mutations/mutations";
import { MY_PROVIDERS, PROVIDERS } from "../../../_graphql/Queries/queries";

export default {
  name: "ProviderNewPage",
  metaInfo: {
    title: "Create Provider"
  },
  components: {
    ProviderForm
  },
  mixins: [ValidationErrors],
  created() {
    this.resetForm();
    if (this.isVendor) {
      this.$apollo
        .query({
          query: MY_PROVIDERS
        })

        .then(data => {
          if (this.isVendor && data.data.myProviders.data.length > 0) {
            this.$router.go(-1);
            this.$buefy.toast.open({
              message: "You can create only 1 Provider",
              type: "is-danger"
            });
          }
        });
    }
  },
  computed: {
    ...mapGetters({
      items: "provider/collection/items",
      userRole: "userRole",
      queryError: "queryError"
    }),
    isAdmin() {
      return this.userRole && this.userRole == "ADMINISTRATOR";
    },
    isVendor() {
      return this.userRole && this.userRole == "VENDOR";
    },
    isOperator() {
      return this.userRole && this.userRole == "OPERATOR";
    },
    queryError() {
      return this.$store.getters.queryError;
    }
  },
  data() {
    const model = this.$store.getters["provider/collection/newModel"];
    return {
      model,
      refName: "createProvider",
      isValid: true,
      clicked: false,
      is_guest: true
    };
  },
  watch: {
    items(newVal) {
      if (newVal) {
        if (this.is_guest) {
          this.$router.push("/providers/guest/");
        } else {
          this.$router.push("/providers/list/");
        }
      }
    },
    queryError(newVal) {
      if (newVal) {
        this.clicked = false;
      }
    }
  },
  methods: {
    async createModel(e) {

      this.clicked = true;
      e.preventDefault();
      this.$refs.createProvider.validate().then(async valid => {
        if (!valid) {
          this.clicked = false;
          this.$buefy.toast.open({
            message: "You have empty or incorrect field!",
            type: "is-danger"
          });
          return;
        }
        let is_guest = false;
        switch (this.userRole) {
          case "ADMINISTRATOR":
            is_guest = this.is_guest;
            break;
          case "VENDOR":
            is_guest = false;
            this.is_guest = false;
            break;
          case "OPERATOR":
            is_guest = true;
            this.is_guest = true;
            break;
            case "MODERATOR":
            is_guest = true;
            this.is_guest = true;
            break;
          default:
            is_guest = false;
            this.is_guest = false;
            break;
        }

        if (this.model.city == null) {
          this.clicked = false;
          return this.$buefy.toast.open({
            message: "Enter valid city!",
            type: "is-danger"
          });
        }

        const mutation = CREATE_PROVIDER;
        const query = this.isVendor ?  MY_PROVIDERS : PROVIDERS;
        const variables = {
          input: {
            locale: {
              city_id: parseInt(this.model.city.id),
              address: this.model.address,
              zip: this.model.zip
            },
            email: this.model.email,
            phone: this.model.phone,
            name: this.model.name,
            experience: parseInt(this.model.experience),
            description: this.model.description,
            specialties: this.model.specialty_ids,
            treatments: this.model.treatment_ids,
            is_guest: is_guest
          }
        };
        setTimeout(() => {
          this.clicked = false;
        }, 1000);

        const payload = {
          mutation: mutation,
          query: query,
          queryName: "createProvider"
        };
        this.$store.commit("provider/collection/newModel", variables);
        await this.$store.dispatch("provider/collection/create", payload);
      });
    },

    resetForm() {
      this.$store.commit("provider/collection/resetNewModel");
    }
  }
};
</script>

<style lang="scss" scoped>
.card-header {
  background-color: #e6eff2;
}

.is_guest-checkbox {
  text-align: left;
  padding-left: 0;
  margin-top: 2rem;
}
</style>
