<template>
  <div>
    You have made a request for video call permission
  </div>
</template>

<script>
export default {
  data() {
    return {};
  }
};
</script>
