import { apolloProvider } from "../../vue-apollo";
import queries from "./queries";
import { TREATMENTS, FLAG_REASONS } from "../../_graphql/Queries/queries";
import { FLAG_JOURNEY } from "../../_graphql/Mutations/mutations";
import _ from "lodash";

/********************************************************
 * QUERY HELPER
 */
async function query(gql, callback, variables = {}) {
  return await apolloProvider()
    .defaultClient.query({
      query: gql,
      variables: variables
    })
    .then(response => {
      return callback(response.data);
    });
}

/********************************************************
 * MUTATE HELPER
 */
async function mutate(gql, callback, variables = {}) {
  return apolloProvider()
    .defaultClient.mutate({
      mutation: gql,
      variables: variables
    })
    .then(response => {
      callback(response.data);
    });
}

export default {
  namespaced: true,
  /********************************************************
   * STATE
   */
  state: {
    conversationId: 0,
    providersLoading: true,
    conversation: [],
    conversationMeta: {},
    conversations: [],
    treatments: [],
    providers: [],
    journeyProviders: [],
    flagReasonsByVendor: [],
    messagesFilter: "OPEN",
    userUnreadMessages: [],
    usernames: null,
    messageValue: {},
    isVideoCallAccepted: false,
    isCalling: "",
    videoUrl: ""
  },
  /********************************************************
   * MUTATIONS
   */
  mutations: {
    setConversationId: (state, conversationId) =>
      (state.conversationId = conversationId),
    setConversation: (state, conversation) =>
      (state.conversation = conversation),
    setConversationMeta: (state, conversationMeta) =>
      (state.conversationMeta = conversationMeta),
    setConversations: (state, conversations) =>
      (state.conversations = conversations),
    setTreatments: (state, treatments) => (state.treatments = treatments),
    setMyProviders: (state, providers) => (state.providers = providers),
    setJourneyProviders: (state, journeyProviders) => {
      state.journeyProviders = journeyProviders.data;
      state.providersLoading = false;
    },
    setFlagReasonsByVendor: (state, flagReasons) => {
      state.flagReasonsByVendor = flagReasons;
    },
    setUserUnreadMessages: (state, userUnreadMessages) =>
      (state.userUnreadMessages = userUnreadMessages),
    updateConversation(state, message) {
      if (_.isEmpty(message.fromUser)) {
        query(
          queries.USER,
          data => {
            message.fromUser = data.user.user;
          },
          {
            id: message.fromId
          }
        );
      }

      const existingMessageIndex = state.conversation.findIndex(
        conversationMessage => conversationMessage.id === message.id
      );

      if (existingMessageIndex !== -1) {
        state.conversation.splice(existingMessageIndex, 1, message);
      } else {
        state.conversation.push(message);
        state.conversation = _.uniqBy(state.conversation, "id");
      }
    },
    setMessagesStatusFilter(state, status) {
      if (!status) {
        status = "OPEN";
      }

      state.messagesFilter = status;
    },
    setMarkUnreadMessages: (state, conversations) =>
      (state.conversations = conversations),
    initUsernames: state => {
      state.usernames = new Map();
    },
    addUsername: (state, userPayload) => {
      state.usernames.set(userPayload.id, {
        firstName: userPayload.firstName,
        lastName: userPayload.lastName
      });
    },
    setVideoCallStatus: (state, status) => {
      state.isVideoCallAccepted = status;
    },
    setIsCalling: (state, value) => {
      state.isCalling = value;
    },
    setVideoUrl: (state, value) => {
      state.videoUrl = value;
    }
  },

  /********************************************************
   * GETTERS
   */
  getters: {
    userMe: (state, getters, rootState, rootGetters) => {
      return rootGetters.userMe;
    },
    providersLoading: state => state.providersLoading,
    conversations: state => state.conversations,
    conversation: state => state.conversation,
    conversationMeta: state => state.conversationMeta,
    conversationId: state => state.conversationId,
    treatments: state => state.treatments,
    providers: state => state.providers,
    journeyProviders: state => state.journeyProviders,
    flagReasonsByVendor: state => state.flagReasonsByVendor,
    messagesFilter: state => state.messagesFilter,
    userUnreadMessages: state => state.userUnreadMessages,
    usernames: state => state.usernames,
    messageValue: state => state.messageValue,
    isVideoCallAccepted: state => state.isVideoCallAccepted,
    isCalling: state => state.isCalling,
    videoUrl: state => state.videoUrl
  },

  /********************************************************
   * ACTIONS
   */
  actions: {
    getMessageValue({ state }, data) {
      state.messageValue = data;
    },
    async getMyProviders({ commit, dispatch, state }) {
      return query(queries.MY_PROVIDERS, response => {
        commit("setMyProviders", response.myProviders.data);
        state.providers.forEach(provider =>
          dispatch("getProviderJourney", provider.id)
        );
      });
    },
    async getTreatments({ commit }) {
      query(TREATMENTS, response =>
        commit("setTreatments", response.treatments.data)
      );
    },
    async getFlagReasonsByVendor({ commit }) {
      const variables = {
        input: {
          query: `role:VENDOR`,
          order: `id:asc`
        }
      };

      return query(
        FLAG_REASONS,
        response => {
          commit("setFlagReasonsByVendor", {
            data: response.flagReasons.data
          });
        },
        variables
      );
    },
    async fetchConversationById({ commit }, conversationId) {
      await query(
        queries.CONVERSATION,
        (response =>
          commit("setConversation", response.conversationMessages.data),
        { conversationId })
      );
    },
    async markUnreadMessages({ rootGetters }, messages) {
      const vendorUserId = rootGetters.meUser.user._id;

      const unreadMessageIds = messages.filter(
        message => message.readBy.indexOf(vendorUserId) === -1
      );

      unreadMessageIds.forEach(message => {
        let userId = message.readBy.pop();
        return mutate(queries.UPDATE_MESSAGE, () => {}, {
          id: message.id,
          data: {
            readBy: [vendorUserId, userId]
          }
        });
      });
    },
    async fetchConversation({ commit, dispatch }, conversationId) {
      commit("initUsernames");
      await query(
        queries.CONVERSATION_WITH_MESSAGES,
        async response => {
          commit("setConversationId", conversationId);
          const messages = response.conversationMessages.data;
          for (let index = 0; index < messages.length; index++) {
            const message = messages[index];
            await dispatch("loadMessageUser", message);
          }
          commit("setConversation", response.conversationMessages.data);
          commit("setConversationMeta", response.conversationMessages.meta);
          dispatch("markUnreadMessages", response.conversationMessages.data);
        },
        { conversationId }
      );
    },
    async loadMessageUser({ state, commit }, message) {
      if (state.usernames.has(message.fromId)) {
        const messageFromUser = state.usernames.get(message.fromId);
        message.fromUser.firstName = messageFromUser.firstName;
        message.fromUser.lastName = messageFromUser.lastName;
      } else {
        await query(
          queries.GET_FROMUSER_FOR_MESSAGE,
          response => {
            const userPayload = {
              id: message.fromId,
              firstName: response.user.user.firstName,
              lastName: response.user.user.lastName
            };
            commit("addUsername", userPayload);
            message.fromUser.firstName = userPayload.firstName;
            message.fromUser.lastName = userPayload.lastName;
          },
          { id: message.fromId }
        );
      }
    },
    // why does this method fetch a single journeyProvider and overwrite all journeyProviders
    async fetchJourneyProvider({ commit }, journey_provider_id) {
      await query(
        queries.JOURNEY_PROVIDER,
        response => {
          commit("setJourneyProviders", {
            data: [response.journeyProvider]
          });
        },
        { id: journey_provider_id }
      );
    },
    async fetchConversations({ commit }) {
      await query(queries.CONVERSATION_WITH_USER, response =>
        commit("setConversations", response.conversationsWithUser.data)
      );
    },
    async getProviderJourney({ commit, state }, providerId) {
      const variables = {
        input: {
          query: `status:${state.messagesFilter},provider_id:${parseInt(
            providerId
          )}`
        }
      };

      return query(
        queries.JOURNEY_PROVIDERS,
        response => {
          commit("setJourneyProviders", {
            id: providerId,
            data: response.journeyProviders.data
          });
        },
        variables
      );
    },
    async loadPreviousMessages(
      { commit, getters, state, dispatch },
      createdDate
    ) {
      const variables = {
        conversationId: getters.conversationId,
        createdDate
      };

      return query(
        queries.CONVERSATION_MESSAGES_BEFORE,
        async response => {
          if (response.conversationMessagesBefore.errors.length > 0) {
            // TODO: Make endpoint return error and test out case again
            this.$buefy.toast.open({
              message: response.errors[0],
              type: "is-danger",
              duration: 3000
            });
          } else {
            const messages = response.conversationMessagesBefore.data;
            for (let index = 0; index < messages.length; index++) {
              const message = messages[index];
              await dispatch("loadMessageUser", message);
            }
            state.conversation.push(...messages);
            commit(
              "setConversationMeta",
              response.conversationMessagesBefore.meta
            );
            return response;
          }
        },
        variables
      ).catch(error => {
        error;
      });
    },
    async createConversation({ dispatch, commit, rootGetters }, params) {
      const payload = {
        data: {
          ownerId: params.clientId,
          type: "PROVIDER",
          participantIds: [params.clientId, rootGetters.meUser.user._id],
          journeyProviderId: params.journeyProviderId
        }
      };

      return mutate(
        queries.CREATE_CONVERSATION,
        response => {
          commit("setConversationId", response.createConversation.data[0].id);
          dispatch("getMyProviders");
          return dispatch("sendMessage", {
            message: params.message,
            journeyProviderId: params.journeyProviderId,
            conversationId: response.createConversation.data[0].id,
            readBy: [rootGetters.meUser.user._id],
            meta: params.meta
          });
        },
        payload
      );
    },
    async archiveConversation({ dispatch }, conversationId) {
      await mutate(
        queries.ARCHIVE_CONVERSATION,
        () => {
          dispatch("getMyProviders");
          dispatch("fetchConversation", conversationId);
        },
        {
          id: conversationId
        }
      );
    },
    async flagJourney({ commit, state }, vars) {
      await mutate(
        FLAG_JOURNEY,
        () => {
          commit("setJourneyProviders", {
            id: vars.journey_provider_id,
            data: state.journeyProviders
          });
        },
        { input: vars }
      );
    },
    async updateMessage({ dispatch, state }, message) {
      await mutate(
        queries.UPDATE_MESSAGE,
        () => dispatch("fetchConversation", state.conversationId),
        {
          id: message.id,
          data: {
            readBy: message.readBy,
            metadata: message.metadata,
            payload: message.payload
          }
        }
      );
    },
    async deleteMessage({ dispatch, state }, message) {
      await mutate(
        queries.DELETE_MESSAGE,
        () => dispatch("fetchConversation", state.conversationId),
        {
          id: message.id
        }
      );
    },
    async sendMessage({ dispatch, state, rootGetters }, payload) {
      const message = payload.message;
      const journeyProviderId = payload.journeyProviderId;
      const conversationId =
        payload.conversationId == 0
          ? state.conversationId
          : payload.conversationId;
      const metadata = !_.isEmpty(payload.meta) ? payload.meta : null;
      const readBy = payload.readBy;

      // TODO: See if fetchConversation can be avoided
      mutate(
        queries.SEND_MESSAGE,
        () => dispatch("fetchConversation", state.conversationId),
        {
          data: {
            payload: message,
            fromId: rootGetters.meUser.user._id,
            metadata: metadata ? JSON.stringify(metadata) : metadata,
            journeyProviderId: journeyProviderId,
            conversationId: conversationId,
            readBy: readBy
          }
        }
      );
    },

    async getUserUnreadMessages({ commit }) {
      return await query(queries.USER_UNREAD_MESSAGES, response =>
        commit("setUserUnreadMessages", response.userUnreadMessages.data)
      );
    }
  }
};
