<template>
  <div>
    <b-table
      class="table is-fullwidth"
      :data="data"
      :paginated="paginated"
      :per-page="perPage"
      default-sort="name"
      :current-page="currentPage || 1"
      :hoverable="true"
      @page-change="onPageChange"
    >
      <template slot-scope="data">
        <b-table-column label="Nr.">
          {{ data.row.id }}
        </b-table-column>
        <b-table-column id="name" label="Name" field="name">
          <router-link
            v-if="data.row.name"
            :to="getRoutePath + '/' + data.row.id"
            class="subtitle has-text-primary"
          >
            {{ data.row.name }}
          </router-link>
        </b-table-column>
        <b-table-column label="Icon set" field="iconSet">
          <div v-if="data.row.icon_set">
            {{ data.row.icon_set.name }}
          </div>
        </b-table-column>
        <b-table-column>
          <div class="buttons is-right">
            <router-link
              :to="{
                name: `${getRouteFirstName}.update`,
                params: { id: data.row.id, data: data.row }
              }"
            >
              <b-button class="is-primary is-outlined">edit</b-button>
            </router-link>
          </div>
        </b-table-column>
      </template>
      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>
              <b-icon icon="emoticon-sad" size="is-large"> </b-icon>
            </p>
            <p>Nothing here.</p>
          </div>
        </section>
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  name: "dataTable",
  data() {
    return {
      perPage: 15,
      paginated: true,
      // currentPage: 1,
      selectedItem: {}
    };
  },
  props: ["data", "currentPage", "pageName"],
  mounted() {
    if (this.data && this.data.length > this.perPage) {
      this.paginated = true;
    }
  },
  methods: {
    onPageChange(page) {
      this.$store.state[this.pageName] = page;
    }
  }
};
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 769px) {
  #name {
    width: 40%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
