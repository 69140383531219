<template>
  <section class="section provider-profile-section">
    <div class="is-title-bar">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <h1 class="title">
              Provider Profile
            </h1>
          </div>
        </div>
        <div v-if="provider" class="level-right">
          <div class="level-item">
            <a
              class="is-primary button"
              :href="getWebPreviewUrl"
              target="_blank"
            >
              Web Preview
            </a>
          </div>
          <div class="level-item">
            <b-button class="is-primary" @click="togglePreview">
              {{ openMobile ? "Close Preview" : "Mobile Preview" }}
            </b-button>
          </div>
        </div>
      </div>
      <b-notification
        v-if="provider && provider.status == 0 && isVendor && isNoteActive"
        icon-pack="mdi mdi-information mdi-48px"
        :closable="false"
        has-icon
        type="is-info"
        id="note"
      >
        Thank you for joining MeTime. Your registration is pending approval and
        you should receive email notification within 24 hours. You will not be
        able to interact with the functionalities of the MeTime platform until
        your registration is approved. If you can’t find the notification,
        please check your spam folder. If you have any queries, just email
        <a :href="'mailto:' + mailTo">{{ mailTo }}</a
        >.
      </b-notification>
      <notification
        @closedNote="closeNotification"
        name="matchInfo"
        type="light"
        is-closable="true"
        v-if="isVendor && isMatchNoteVisible"
      >
        <template v-slot:icon>
          <img class="icon is-large" src="@/assets/img/icon-9.png" />
        </template>
        <template v-slot:message>
          You will match with MeTime users looking for aesthetic providers based
          on location, treatments available, specialities and experience. Once
          you match and start a chat, users will be able to chat back and view
          your profile. Remember, users can only chat with you when you start
          the conversation.</template
        >
      </notification>
      <notification
        @closedNote="closeNotification"
        type="light"
        name="profileInfo"
        is-closable="true"
        v-if="isVendor && isProfileNoteVisible"
      >
        <template v-slot:icon>
          <img class="icon is-large" src="@/assets/img/icon-8.png" />
        </template>
        <template v-slot:message>
          Enter all the treatments available at your practice, and the type of
          practitioners providing their services. You can enter a description of
          your practice and add photos. Premium subscribers can add more photos
          to highlight their practice and work, videos, main practitioners and
          highlighted or branded treatments. Click the Preview button to see how
          your screen looks to users!</template
        >
      </notification>
      <notification
        @closedNote="closeNotification"
        type="light"
        name="flagInfo"
        is-closable="true"
        v-if="isVendor && isFlagNoteVisible"
      >
        <template v-slot:icon>
          <img class="icon is-large" src="@/assets/img/icon-7.png" />
        </template>
        <template v-slot:message>
          The information you include should be accurate. Misleading or false
          information may be flagged and risk losing your profile.</template
        >
      </notification>
    </div>
    <ValidationObserver :ref="refName">
      <div class="tile is-parent" v-if="isGuest && !isVendor">
        <div class="tile">
          <ReviewSection
            ref="reviewSection"
            :query-info="provider"
            :user-role="userRole"
            :statuses="reviewStatuses"
            :allowUpdate="allowUpdate"
            :show-update-btn="true"
            class="tile is-child"
            @saveInfo="saveReviewSectionInfo"
            @editedField="setUnsavedChanges('reviewSection')"
          ></ReviewSection>
        </div>
      </div>
      <div class="tile">
        <div class="tile is-parent is-6-desktop">
          <div class="tile">
            <UpdateProviderForm
              ref="updateProviderForm"
              v-if="provider"
              :provider="provider"
              class="tile is-child"
              :isValid="isValid"
              :allowUpdate="allowUpdate"
              :userRole="userRole"
              @updatedInfo="updateProviderInfo"
              @editedField="setUnsavedChanges('updateProviderForm')"
              @savedChanges="handleSavedChanges('updateProviderForm')"
            ></UpdateProviderForm>
          </div>
        </div>
        <div
          v-if="provider && !isGuest"
          id="invite-users"
          class="tile is-parent is-6-desktop is-vertical"
        >
          <div class="tile">
            <RelatedLinks
              :provider="provider"
              class="tile is-child"
            ></RelatedLinks>
          </div>
          <div class="tile visibility-section-tile">
            <VisibilitySection
              :provider="provider"
              class="tile is-child"
              @updatedInfo="updateProviderInfo"
            ></VisibilitySection>
          </div>
        </div>
        <div v-else-if="provider" class="tile is-parent">
          <div class="tile">
            <GuestSettingsSection
              v-if="provider && isGuest && (isAdmin || isModerator)"
              :provider="provider"
              class="tile is-child"
              :userRole="userRole"
            ></GuestSettingsSection>
          </div>
        </div>
      </div>
      <div class="tile custom-tile">
        <div class="tile is-parent ">
          <div class="tile">
            <SearchAndMatchingSection
              ref="searchAndMatchingSection"
              v-if="provider"
              :provider="provider"
              class="tile is-child"
              :allow-update="allowUpdate"
              @updatedInfo="updateProviderInfo"
              @editedField="setUnsavedChanges('searchAndMatchingSection')"
              @savedChanges="handleSavedChanges('searchAndMatchingSection')"
            ></SearchAndMatchingSection>
          </div>
        </div>
        <div class="tile is-parent  is-6-desktop">
          <div class="tile is-vertical">
            <NotificationsSection
              v-show="!isGuest"
              v-if="provider"
              class="tile is-child"
              :provider="provider"
              :allow-update="allowUpdate"
              @updatedInfo="updateProviderInfo"
              @editedField="setUnsavedChanges('notificationsSection')"
              @savedChanges="handleSavedChanges('notificationsSection')"
            ></NotificationsSection>
            <ContactSection
              ref="contactSection"
              v-if="provider"
              class="tile is-child"
              :provider="provider"
              :allow-update="allowUpdate"
              @updatedInfo="updateProviderInfo"
            ></ContactSection>
          </div>
        </div>
      </div>
      <div class="tile">
        <div class="tile is-parent is-6-desktop">
          <div class="tile ">
            <Procedures
              v-if="provider"
              :provider="provider"
              class="tile is-child"
              :isValid="isValid"
              :allowUpdate="allowUpdate"
              :areasProcedures="groupProceduresByAreaId()"
              @updatedProcedures="updateProviderInfo"
              @deletedProcedure="updateProviderInfo"
            ></Procedures>
          </div>
        </div>
        <div class="tile is-6-desktop is-parent">
          <div class="tile ">
            <Practitioners
              :userRole="userRole"
              class="tile is-child"
              :allowUpdate="allowUpdate"
              :practitioners="provider ? provider.main_practitioners : []"
              :subscriptionPlan="provider ? provider.subscription_plan : null"
              :isSubscribed="provider ? provider.is_subscribed : false"
              @createdPractitioner="updateProviderInfo"
              @deletedPractitioner="updateProviderInfo"
            ></Practitioners>
          </div>
        </div>
      </div>
      <div class="tile is-4-desktop">
        <div class="tile is-parent is-vertical">
          <Products
            v-if="provider"
            :provider="provider"
            class="tile is-child"
            :isValid="isValid"
            :allowUpdate="allowUpdate"
            @updatedProducts="updateProviderInfo"
            @deletedProduct="updateProviderInfo"
          ></Products>
        </div>
        <div class="tile is-parent is-vertical">
          <Technologies
            v-if="provider"
            :provider="provider"
            class="tile is-child"
            :isValid="isValid"
            :allowUpdate="allowUpdate"
            @updatedTechnologies="updateProviderInfo"
            @deletedTechnology="updateProviderInfo"
          ></Technologies>
        </div>
      </div>

      <div class="tile is-parent ">
        <div class="tile ">
          <RelatedPhotos
            v-if="provider"
            class="tile is-child"
            :photos="provider.photos"
            :allowUpdate="allowUpdate"
            :is_subscribed="provider.is_subscribed"
            :subscriptionPlan="provider ? provider.subscription_plan : null"
            @addedPhoto="updateProviderInfo"
            @deletedPhoto="updateProviderInfo"
            @savedChanges="handleSavedChanges('relatedPhotos')"
            :userRole="userRole"
          ></RelatedPhotos>
        </div>
      </div>
      <div class="tile is-parent">
        <div class="tile">
          <RelatedVideos
              class="tile is-child"
              :videos="provider ? provider.videos : []"
              :subscriptionPlan="provider ? provider.subscription_plan : null"
              :isSubscribed="provider ? provider.is_subscribed : false"
              :allowUpdate="allowUpdate"
              @addedVideo="updateProviderInfo"
              @deletedVideo="updateProviderInfo"
              @savedChanges="handleSavedChanges('relatedVideos')"
              :userRole="userRole"
          ></RelatedVideos>
        </div>
      </div>
      <div class="tile is-parent" v-if="isAdmin">
        <div class="tile">
          <provider-score-links />
        </div>
      </div>
      <div
        v-if="isAdmin && showSystemSettings"
        id="settings"
        class="tile is-parent"
      >
        <div class="tile">
          <RelatedSettings
            :provider="provider"
            :videocallsValue="videocallsValue"
            :paymentsValue="paymentsValue"
            class="tile is-child"
            @updatedProviderSettings="updatedProviderSettings"
          ></RelatedSettings>
        </div>
      </div>
      <div v-if="showDeleteBtn && provider" class="buttons is-right deleteBtn">
        <b-button class="is-danger" @click="confirmDeleteAdmin"
          >Delete Provider</b-button
        >
      </div>
      <div v-else-if="isVendor && provider" class="buttons is-right deleteBtn">
        <b-button class="is-danger" @click="confirmDelete"
          >Delete Provider</b-button
        >
      </div>
    </ValidationObserver>
    <WindowPortal v-model="openMobile" @closePopup="toggleClosePopupSwitch()">
      <ProviderPreview
        :provider="provider"
        :closePopupSwitch="closePopupSwitch"
      />
    </WindowPortal>
  </section>
</template>

<script>
import { PROVIDER, SYSTEM_SETTINGS } from "../../../_graphql/Queries/queries";
import RouteParamsGetters from "../../../mixins/RouteParamsGetters";
import Practitioners from "../components/RelatedPractitionersList";
import RelatedPhotos from "../components/RelatedPhotos";
import RelatedVideos from "../components/RelatedVideos";
import RelatedLinks from "../components/RelatedLinks";
import RelatedSettings from "../components/RelatedSettings";
import ProviderScoreLinks from "../components/ProviderScoreLinks";
import UpdateProviderForm from "../components/UpdateProviderForm";
import {
  DELETE_PROVIDER,
  DELETE_PROVIDER_BY_ADMIN,
  UPDATE_PROVIDER
} from "../../../_graphql/Mutations/mutations";
import { mapGetters } from "vuex";
import WindowPortal from "../components/WindowPortal";
import ProviderPreview from "../components/ProviderPreview";
import Notification from "../../base/components/Notification";
import ReviewSection from "../components/ReviewSection";
import SearchAndMatchingSection from "../components/SearchAndMatchingSection";
import ContactSection from "../components/ContactSection";
import GuestSettingsSection from "../components/GuestSettingsSection";
import Technologies from "../components/RelatedTechnologies";
import Products from "../components/RelatedProducts";
import Procedures from "../components/RelatedProcedures";
import VisibilitySection from "../components/VisibilitySection";
import NotificationsSection from "@/modules/providers/components/NotificationsSection";
import _ from "lodash";

export default {
  data() {
    return {
      hasChanges: {
        reviewSection: false,
        updateProviderForm: false,
        searchAndMatchingSection: false,
        notificationsSection: false,
        relatedPhotos: false,
        relatedVideos: false
      },
      isNoteActive: true,
      isMyProvider: false,
      isValid: true,
      refName: "editProvider",
      isMatchNoteVisible: true,
      isFlagNoteVisible: true,
      isProfileNoteVisible: true,
      mailTo: "support@metime.com",
      model: {
        name: "",
        email: "",
        phone: "",
        experience: null,
        country_id: null,
        city_id: null,
        address: "",
        zip: "",
        description: "",
        treatment_ids: [],
        specialty_ids: []
      },
      openMobile: false,
      closePopupSwitch: false,
      videocallsValue: null,
      paymentsValue: null,
      operatorStatuses: [
        {
          name: "Draft",
          value: "DRAFT"
        },
        {
          name: "Ready for review",
          value: "READY_FOR_REVIEW"
        }
      ],
      moderatorStatuses: [
        {
          name: "Needs corrections",
          value: "NEEDS_CORRECTIONS"
        },
        {
          name: "Reviewed",
          value: "REVIEWED"
        },
        {
          name: "Invited",
          value: "INVITED"
        },
        {
          name: "Demo scheduled",
          value: "DEMO_SCHEDULED"
        },
        {
          name: "Rejected",
          value: "REJECTED"
        }
      ],
      adminStatuses: [
        {
          name: "Draft",
          value: "DRAFT"
        },
        {
          name: "Ready for review",
          value: "READY_FOR_REVIEW"
        },
        {
          name: "Needs corrections",
          value: "NEEDS_CORRECTIONS"
        },
        {
          name: "Reviewed",
          value: "REVIEWED"
        },
        {
          name: "Invited",
          value: "INVITED"
        },
        {
          name: "Demo scheduled",
          value: "DEMO_SCHEDULED"
        },
        {
          name: "Rejected",
          value: "REJECTED"
        }
      ]
    };
  },
  mixins: [RouteParamsGetters],
  components: {
    NotificationsSection,
    UpdateProviderForm,
    Practitioners,
    RelatedPhotos,
    RelatedVideos,
    RelatedLinks,
    RelatedSettings,
    ProviderScoreLinks,
    WindowPortal,
    ProviderPreview,
    Notification,
    ReviewSection,
    SearchAndMatchingSection,
    ContactSection,
    GuestSettingsSection,
    Products,
    Technologies,
    Procedures,
    VisibilitySection
  },
  created() {
    this.resetForm();
    this.showNotification();
    window.addEventListener("beforeunload", e => {
      let hasChange = false;
      _.forEach(this.hasChanges, section => {
        if (section == true) {
          hasChange = true;
        }
      });
      if (hasChange) {
        e.preventDefault();
        e.returnValue = null;
      }
    });
  },
  beforeRouteLeave(to, from, next) {
    let hasChange = false;
    _.forEach(this.hasChanges, section => {
      if (section == true) {
        hasChange = true;
      }
    });
    if (hasChange) {
      next(false);
      let confirm = window.confirm("Changes you made may not be saved.");
      if (confirm) {
        this.clearUnsavedChangesState();
        next();
      }
    } else {
      next();
    }
  },
  apollo: {
    provider: {
      query: PROVIDER,
      variables() {
        return {
          id: this.getProviderId
        };
      },
      update: data => data.provider,
      result({ data: { provider } }) {
        if (provider && this.meUser && this.userRole) {
          this.isMyProvider =
            this.provider.user && provider.user.auth_id == this.meUser.user._id;

          if (
            this.userRole &&
            (this.userRole == "OPERATOR" || this.userRole == "MODERATOR") &&
            !provider.is_guest
          ) {
            this.$router.push("/*");
          }
          if (this.userRole == "VENDOR" && !this.isMyProvider) {
            this.$router.push("/*");
          }
        }
      }
    },
    systemSettings: {
      query: SYSTEM_SETTINGS,
      update: data => data.systemSettings,
      result({ data: { systemSettings } }) {
        if (systemSettings) {
          systemSettings.data.filter(setting => {
            if (setting.key_name == "ENABLE_VIDEO_CALLS") {
              this.videocallsValue = setting.value;
            }
            if (setting.key_name == "ENABLE_PAYMENTS_REQUESTS") {
              this.paymentsValue = setting.value;
            }
          });
        }
      },
      skip() {
        return !this.isAdmin;
      }
    }
  },
  computed: {
    ...mapGetters({
      userRole: "userRole",
      meUser: "meUser"
    }),
    isVendor() {
      return this.userRole && this.userRole == "VENDOR";
    },
    isAdmin() {
      return this.userRole && this.userRole == "ADMINISTRATOR";
    },
    isModerator() {
      return this.userRole && this.userRole == "MODERATOR";
    },
    isGuest() {
      return this.provider && this.provider.is_guest;
    },
    showSystemSettings() {
      return (
        this.videocallsValue &&
        this.paymentsValue &&
        (this.videocallsValue == "0" || this.paymentsValue == "0") &&
        !this.isGuest
      );
    },
    allowUpdate() {
      return (
        this.userRole !== "OPERATOR" ||
        (this.userRole == "OPERATOR" && this.isMyProvider)
      );
    },
    showDeleteBtn() {
      return this.allowUpdate && !this.isVendor;
    },
    getWebPreviewUrl() {
      if (this.provider) {
        const profileUrl = this.provider.profile_url;
        if (process.env.NODE_ENV === "production") {
          return `https://metime.com/provider/${profileUrl}?preview=true`;
        }
        if (
          process.env.NODE_ENV === "staging" ||
          window.location.href.includes("staging")
        ) {
          return `https://staging.metime.com/provider/${profileUrl}?preview=true`;
        }
        if (
          process.env.NODE_ENV === "development" ||
          window.location.href.includes("dev")
        ) {
          return `https://dev.metime.com/provider/${profileUrl}?preview=true`;
        }
      }

      return "";
    },
    reviewStatuses() {
      return {
        adminStatuses: this.adminStatuses,
        moderatorStatuses: this.moderatorStatuses,
        operatorStatuses: this.operatorStatuses
      };
    }
  },
  methods: {
    handleSavedChanges(section) {
      this.hasChanges[section] = false;
    },
    setUnsavedChanges(section) {
      this.hasChanges[section] = true;
    },
    clearUnsavedChangesState() {
      for (const section in this.hasChanges) {
        this.hasChanges[section] = false;
      }
    },
    showNotification() {
      let cookies = document.cookie.split(";");
      if (cookies) {
        cookies.forEach(cookie => {
          let cookieData = cookie.split("=");

          if (cookieData[0].includes("matchInfo")) {
            this.isMatchNoteVisible = false;
          }
          if (cookieData[0].includes("flagInfo")) {
            this.isFlagNoteVisible = false;
          }
          if (cookieData[0].includes("profileInfo")) {
            this.isProfileNoteVisible = false;
          }
        });
      }
    },
    groupProceduresByAreaId() {
      if (this.provider) {
        const proceduresAreasPositions = this.provider.procedures_areas_positions.reduce(
          (map, obj) => {
            map[obj.procedure_area_id] = obj;
            return map;
          },
          {}
        );

        return this.provider.procedures
          .reduce((objectsByKeyValue, obj) => {
            const value = obj["procedure_area_id"];

            const procedureAreaName = proceduresAreasPositions[
              obj.procedure_area_id
            ]
              ? proceduresAreasPositions[obj.procedure_area_id]
                  .procedure_area_name
              : "";
            const procedureAreaUrl = proceduresAreasPositions[
              obj.procedure_area_id
            ]
              ? proceduresAreasPositions[obj.procedure_area_id]
                  .procedure_area_icon_url
              : "";
            const procedureAreaPosition = proceduresAreasPositions[
              obj.procedure_area_id
            ]
              ? proceduresAreasPositions[obj.procedure_area_id].position
              : "";

            objectsByKeyValue[value] = {
              id: obj.procedure_area_id,
              name: procedureAreaName,
              position: procedureAreaPosition,
              url: procedureAreaUrl,
              procedures: (objectsByKeyValue[value]
                ? objectsByKeyValue[value].procedures
                : []
              ).concat(obj)
            };

            return objectsByKeyValue;
          }, [])
          .filter(item => item != null)
          .sort((a, b) => (a.position > b.position ? 1 : -1));
      }
      return [];
    },
    updatedProviderSettings() {
      this.$apollo.queries.provider.refetch();
    },
    toggleClosePopupSwitch() {
      this.closePopupSwitch = !this.closePopupSwitch;
    },
    updateInfo() {},
    updateProviderInfo() {
      this.$apollo.queries.provider.refetch();
    },
    resetForm() {
      this.$store.commit("provider/collection/resetEditModel");
    },
    confirmDeleteAdmin() {
      this.$buefy.dialog.prompt({
        title: "Deleting provider",
        message: `Enter reason for deleting this provider`,
        inputAttrs: {
          placeholder: "Describe the reason for deleting provider",
          type: "textarea",
          min: 10,
          maxlength: 300
        },
        trapFocus: true,
        onConfirm: value => this.deleteProviderAdmin({ reason: value })
      });
    },
    confirmDelete() {
      this.$buefy.dialog.confirm({
        title: "Deleting ",
        message:
          "Are you sure you want to <b>delete</b> this provider" +
          "? This action cannot be undone.",
        confirmText: "Delete",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.deleteProvider()
      });
    },
    async deleteProviderAdmin(data) {
      const mutation = DELETE_PROVIDER_BY_ADMIN;
      const variables = {
        id: parseInt(this.provider.id),
        reason: data.reason
      };
      this.$store.commit("provider/collection/deleteModel", variables);
      await this.$store.dispatch("provider/collection/delete", mutation);
      this.isGuest
        ? this.$router.push("/providers/guest")
        : this.$router.push("/providers/list");
    },
    async deleteProvider() {
      const mutation = DELETE_PROVIDER;
      const variables = {
        id: parseInt(this.provider.id)
      };
      this.$store.commit("provider/collection/deleteModel", variables);
      await this.$store.dispatch("provider/collection/delete", mutation);
      this.$router.push("/providers/list");
    },
    togglePreview() {
      this.updateProviderInfo();
      this.openMobile = !this.openMobile;
    },
    goToInviteUsers() {
      const id = "invite-users";
      const yOffset = -50;
      const element = document.getElementById(id);
      const y =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({ top: y, behavior: "smooth" });
    },
    closeNotification(name) {
      document.cookie = name + "=hidden";
      this.showNotification();
    },
    saveReviewSectionInfo(data) {
      let variables = {
        id: parseInt(this.provider.id),
        input: data
      };

      this.$apollo
        .mutate({
          mutation: UPDATE_PROVIDER,
          variables: variables
        })
        .catch(error => {
          this.$refs.reviewSection.newStatus = null;
          return this.$buefy.toast.open({
            message: error,
            type: "is-danger"
          });
        })
        .then(({ data: { updateProvider } }) => {
          if (updateProvider) {
            this.$buefy.toast.open({
              message: "Updated successfully",
              type: "is-success"
            });
            this.$refs.reviewSection.guestStatus(updateProvider);
            this.$refs.reviewSection.newStatus = null;
            this.updateProviderInfo();
            this.handleSavedChanges("reviewSection");
          }
        });
    }
  }
};
</script>
<style lang="scss" scoped>
#note {
  background-color: #e6eff2;
  color: #0ca1d1;
}

@media (max-width: 576px) {
  .button.is-primary {
    width: 9rem !important;
  }
}
.button.is-primary {
  width: 9rem !important;
}
.card-header {
  background-color: #e6eff2;
}

.deleteBtn {
  margin-top: 1rem;
  .button {
    margin-right: 0.6rem !important;
    width: 9rem;
  }
}

.is-title-bar {
  padding-left: 12px;
  padding-right: 12px;
}

.custom-tile {
  .tile:not(.is-child) {
    @media screen and (max-width: 1200px) {
      /*display: block !important;*/
    }
  }
}

.visibility-section-tile {
  margin-top: 1.5rem;
}

.icon {
  z-index: 2 !important;
}
</style>
