<template>
  <section class="section">
    <title-bar>
      Articles
      <router-link
        to="/articles/create"
        class="button is-primary"
        slot="button"
      >
        Create Article
      </router-link>
    </title-bar>

    <b-table
      v-if="articlesRaw"
      class="table is-fullwidth"
      :data="articlesRaw"
      :loading="false"
      paginated
      default-sort="created_at"
      default-sort-direction="desc"
      :per-page="100"
    >
      <template slot-scope="data">
        <b-table-column field="id" label="ID">{{ data.row.id }}</b-table-column>
        <b-table-column
          field="name"
          label="Name"
          class="custom-table-style"
          sortable
        >
          {{ data.row.name }}
        </b-table-column>
        <b-table-column
          field="slug"
          label="Slug"
          class="custom-table-style"
          sortable
        >
          {{ data.row.slug }}
        </b-table-column>
        <b-table-column field="created_at" label="Created" sortable>
          {{ new Date(data.row.created_at).toLocaleDateString() }}
        </b-table-column>
        <b-table-column field="updated_at" label="Updated" sortable>
          {{ new Date(data.row.updated_at).toLocaleDateString() }}
        </b-table-column>
        <b-table-column field="status" label="Status">
          {{ data.row.draft ? "draft" : "published" }}
        </b-table-column>

        <b-table-column>
          <div class="buttons is-right">
            <router-link
              :to="{
                name: 'editArticle',
                params: { id: data.row.id, data: data.row }
              }"
            >
              <b-button class="is-primary is-outlined">Edit</b-button>
            </router-link>
            <b-button
              class="is-danger is-outlined"
              @click="openDeleteDialog(data.row)"
              >Delete</b-button
            >
          </div>
        </b-table-column>
      </template>
      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>
              <b-icon icon="information" size="is-large"></b-icon>
            </p>
            <p>No articles in database.</p>
          </div>
        </section>
      </template>
    </b-table>
  </section>
</template>

<script>
import TitleBar from "../../base/components/TitleBar";
import { ARTICLES_RAW } from "../../../_graphql/Queries/queries";
import { DELETE_ARTICLE } from "../../../_graphql/Mutations/mutations";

export default {
  name: "Articles",
  components: { TitleBar },
  data() {
    return {};
  },
  methods: {
    openDeleteDialog(data) {
      const variables = {
        id: data.id
      };
      this.$buefy.dialog.confirm({
        title: "Confirm Deletion ",
        message: `Are you sure you want to <b>delete</b> ${data.name} ?`,
        confirmText: "Delete",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => {
          this.mutate(DELETE_ARTICLE, variables)
            .then(() => {
              this.$apollo.getClient().resetStore();
              this.$buefy.toast.open({
                message: "Article deleted successfully.",
                type: "is-success"
              });
            })
            .catch(error => {
              this.$buefy.toast.open({
                message: `${error}`,
                type: "is-danger"
              });
            });
        }
      });
    }
  },
  apollo: {
    articlesRaw: {
      query: ARTICLES_RAW
    }
  }
};
</script>

<style lang="scss" scoped>
.custom-table-style {
  word-break: break-all;
}
</style>
