<template>
  <section class="section">
    <title-bar>
      Profile
      <router-link
        v-if="isAdmin || isVendor"
        to="/"
        class="button is-primary"
        slot="button"
      >
        Dashboard
      </router-link>
    </title-bar>

    <tiles v-if="me">
      <profile-update-form class="tile is-child" :me="me" @update="updateMe" />
    </tiles>
    <tiles v-if="me">
      <password-update-form :me="me" />
      <email-change-form :me="me" />
    </tiles>
  </section>
</template>

<script>
import ValidationErrors from "../../../mixins/ValidationErrors";
import TitleBar from "../../base/components/TitleBar";
import Tiles from "../../base/components/Tiles";
import ProfileUpdateForm from "../components/ProfileUpdateForm";
import PasswordUpdateForm from "../components/PasswordUpdateForm";
import EmailChangeForm from "../components/EmailChangeForm";
import { ME } from "../../../_graphql/Queries/queries";
import { mapGetters } from "vuex";

export default {
  name: "Profile",
  components: {
    TitleBar,
    Tiles,
    ProfileUpdateForm,
    PasswordUpdateForm,
    EmailChangeForm
  },
  mixins: [ValidationErrors],
  data() {
    return {
      me: null
    };
  },
  computed: {
    ...mapGetters(["userRole"]),
    isAdmin() {
      return this.userRole && this.userRole == "ADMINISTRATOR";
    },
    isVendor() {
      return this.userRole && this.userRole == "VENDOR";
    }
  },
  methods: {
    updateMe(value) {
      this.me.user = value;
    }
  },
  apollo: {
    me: {
      query: ME,
      variables: {
        update: data => data.me
      }
    }
  }
};
</script>

<style>
.card-message {
  text-align: left;
  padding-bottom: 25px;
}
</style>
