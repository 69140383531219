import { render, staticRenderFns } from "./ActiveJourneyProviders.vue?vue&type=template&id=655e5f38&scoped=true&"
import script from "./ActiveJourneyProviders.vue?vue&type=script&lang=js&"
export * from "./ActiveJourneyProviders.vue?vue&type=script&lang=js&"
import style0 from "./ActiveJourneyProviders.vue?vue&type=style&index=0&id=655e5f38&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "655e5f38",
  null
  
)

export default component.exports