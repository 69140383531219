<template>
  <div class="card">
    <header class="card-header">
      <p class="card-header-title has-text-primary">
        <b-icon icon="monitor" custom-size="default" />
        Guest Profile Settings
      </p>
    </header>
    <div class="columns is-multiline card-content">
      <b-field class="column is-12" type="is-primary">
        <label class="title">Invitation Email</label>
        <span class="subtitle is-6"
          >Review and send the email to the guest providers</span
        >
        <div class="buttons is-multiline">
          <b-button
            @click="openEmailTemplate('invitation')"
            class="is-primary is-pulled-left"
            >View Invitation Email</b-button
          >
          <b-button
            @click="openEmailTemplate('reminder')"
            class="is-primary is-pulled-left"
            >View Reminder Email</b-button
          >
        </div>
      </b-field>
      <b-field class="column is-12" type="is-primary">
        <label class="title">Guest profile's URL</label>
        <span class="subtitle is-6"
          >Copy and paste the URL to access the profile directly from a
          browser.</span
        >
        <div class="buttons is-multiline">
          <b-button
            @click="copyUrl('guestProfile')"
            class="is-primary is-pulled-left"
            >Copy guest profile URL</b-button
          >
        </div>
      </b-field>

      <b-field class="column is-12" type="is-primary">
        <label class="title">Guest registration URL</label>
        <span class="subtitle is-6"
          >Copy and send the URL to the guest provider to use it to register on
          MeTime.</span
        >
        <div class="buttons is-multiline">
          <b-button
            @click="copyUrl('registration')"
            class="is-primary is-pulled-left"
            >Copy registration URL</b-button
          >
        </div>
      </b-field>
    </div>
    <EmailTemplate ref="emailTemplate"></EmailTemplate>
  </div>
</template>

<script>
import EmailTemplate from "./EmailTemplate";
export default {
  data() {
    return {};
  },
  props: ["provider", "userRole"],
  components: {
    EmailTemplate
  },
  computed: {
    registrationUrl() {
      return (
        (process.env && process.env.VUE_APP_AUTH_URL
          ? process.env.VUE_APP_AUTH_URL
          : "https://id.metime.com/") +
        "transfer/" +
        this.provider.transfer_uuid
      );
    },
    guestProfileUrl() {
      return "https://metime.com/provider/" + this.provider.profile_url;
    }
  },
  methods: {
    openEmailTemplate(value) {
      let data = {
        name: this.provider.name,
        email: this.provider.email,
        registrationUrl: this.registrationUrl,
        profileUrl: this.guestProfileUrl,
        providerId: this.provider.id
      };
      if (value == "invitation") {
        data.title = "Invitation Email";
        data.type = "invitation";
      } else {
        data.title = "Reminder Email";
        data.type = "reminder";
      }
      this.$refs.emailTemplate.openModal(data);
    },
    copyUrl(type) {
      let link, message;
      if (type == "guestProfile") {
        link = this.guestProfileUrl;
        message = "Guest profile URL has been copied to the clipboard";
      } else {
        link = this.registrationUrl;
        message = "Registration URL has been copied to the clipboard";
      }
      const elem = document.createElement("textarea");
      elem.value = link;
      document.body.appendChild(elem);
      elem.select();
      const copiedUrl = document.execCommand("copy");
      document.body.removeChild(elem);
      if (copiedUrl) {
        this.$buefy.toast.open({
          message: message,
          type: "is-success",
          duration: 2500
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.columns {
  flex-direction: column;

  .field {
    display: flex;
    flex-direction: column;
    text-align: left;

    .buttons {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .button {
        width: 11rem !important;
      }
    }
    .title {
      margin-bottom: 0.5rem !important;
      font-size: 1.125rem;
    }
    .subtitle {
      margin-bottom: 0.5rem !important;
      margin-top: 0 !important;
    }
  }
}
@media (max-width: 576px) {
  .button.is-primary {
    width: 11rem !important;
  }
}
</style>
