var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('card-component',[_c('ValidationObserver',{ref:"pageFormValidator",staticClass:"userForm pageForm"},[_c('div',{staticClass:"columns is-variable is-7"},[_c('div',{staticClass:"column is-9"},[_c('div',{staticClass:"columns is-variable is-5 is-multiline is-mobile"},[_c('ValidationProvider',{staticClass:"column is-6",attrs:{"rules":"required|min:2","name":"Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"type":{ 'is-danger': errors[0], 'is-primary': valid },"label":"Name"}},[_c('b-input',{attrs:{"trim":""},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_vm._l((errors),function(error){return _c('span',{key:error.id,staticClass:"has-text-danger help"},[_vm._v(_vm._s(error))])})]}}])}),_c('ValidationProvider',{staticClass:"column is-6-desktop is-6-tablet",attrs:{"rules":"min:2","name":"Title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"type":{ 'is-danger': errors[0], 'is-primary': valid },"label":"Title"}},[_c('b-input',{attrs:{"trim":""},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}})],1),_vm._l((errors),function(error){return _c('span',{key:error.id,staticClass:"has-text-danger help"},[_vm._v(_vm._s(error))])})]}}])}),_c('ValidationProvider',{staticClass:"column is-6-desktop is-6-tablet",attrs:{"rules":"","name":"Status"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"type":{ 'is-danger': errors[0], 'is-primary': valid },"label":"Status"}},[_c('b-switch',{staticClass:"is-pulled-left",model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}},[_vm._v(" "+_vm._s(_vm.form.status ? "Enabled" : "Disabled")+" ")])],1),_vm._l((errors),function(error){return _c('span',{key:error.id,staticClass:"has-text-danger help"},[_vm._v(_vm._s(error))])})]}}])})],1),_c('div',{staticClass:"columns is-variable is-5 is-multiline"},[_c('ValidationProvider',{staticClass:"column is-6",attrs:{"rules":"min:2","name":"Meta Keywords"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"type":{ 'is-danger': errors[0], 'is-primary': valid },"label":"Meta Keywords"}},[_c('b-taginput',{attrs:{"ellipsis":"","icon":"label","placeholder":"Add a keyword"},model:{value:(_vm.form.meta_keywords),callback:function ($$v) {_vm.$set(_vm.form, "meta_keywords", $$v)},expression:"form.meta_keywords"}})],1),_vm._l((errors),function(error){return _c('span',{key:error.id,staticClass:"has-text-danger help"},[_vm._v(_vm._s(error))])})]}}])})],1),_c('ValidationProvider',{staticClass:"column is-6",attrs:{"rules":"min:2","name":"Meta Title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"type":{ 'is-danger': errors[0], 'is-primary': valid },"label":"Meta Title"}},[_c('b-input',{attrs:{"trim":""},model:{value:(_vm.form.meta_title),callback:function ($$v) {_vm.$set(_vm.form, "meta_title", $$v)},expression:"form.meta_title"}})],1),_vm._l((errors),function(error){return _c('span',{key:error.id,staticClass:"has-text-danger help"},[_vm._v(_vm._s(error))])})]}}])}),_c('ValidationProvider',{staticClass:"column is-6",attrs:{"rules":"min:2","name":"Meta Description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"type":{ 'is-danger': errors[0], 'is-primary': valid },"label":"Meta Description"}},[_c('b-input',{attrs:{"trim":""},model:{value:(_vm.form.meta_description),callback:function ($$v) {_vm.$set(_vm.form, "meta_description", $$v)},expression:"form.meta_description"}})],1),_vm._l((errors),function(error){return _c('span',{key:error.id,staticClass:"has-text-danger help"},[_vm._v(_vm._s(error))])})]}}])})],1)]),(!!_vm.contentFromKeywords.length)?_c('div',{staticClass:"columns is-variable is-5 is-multiline is-mobile"},_vm._l((_vm.contentFromKeywords),function(key,index){return _c('div',{key:index,staticClass:"column is-12"},[(key.includes('[') && key.includes(']'))?_c('ValidationProvider',{staticClass:"column is-12",attrs:{"rules":"","name":key.slice(0, key.indexOf('['))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-button',{staticClass:"is-primary",on:{"click":function($event){return _vm.addNewItem(key)}}},[_vm._v(" Add "+_vm._s(key.slice(0, key.indexOf("[")))+" ")]),(_vm.form.content_json[key.slice(0, key.indexOf('['))])?_c('div',[_vm._l((_vm.form.content_json[
                key.slice(0, key.indexOf('['))
              ]),function(deepKey,index){return _c('div',{key:deepKey},[_vm._l((Object.keys(deepKey)),function(innerKey){return _c('b-field',{key:innerKey,attrs:{"type":{ 'is-danger': errors[0], 'is-primary': valid },"label":innerKey}},[(innerKey)?_c('b-input',{attrs:{"type":"textarea","trim":""},model:{value:(
                    _vm.form.content_json[key.slice(0, key.indexOf('['))][index][
                      innerKey
                    ]
                  ),callback:function ($$v) {_vm.$set(_vm.form.content_json[key.slice(0, key.indexOf('['))][index], 
                      innerKey
                    , $$v)},expression:"\n                    form.content_json[key.slice(0, key.indexOf('['))][index][\n                      innerKey\n                    ]\n                  "}}):_vm._e()],1)}),_c('b-button',{staticClass:"is-primary",on:{"click":function($event){return _vm.deleteItem(key, index)}}},[_vm._v(" Delete "+_vm._s(key.slice(0, key.indexOf("[")))+" - "+_vm._s(index + 1)+" ")])],2)}),_vm._l((errors),function(error){return _c('span',{key:error.id,staticClass:"has-text-danger help"},[_vm._v(_vm._s(error))])})],2):_vm._e()]}}],null,true)}):_c('ValidationProvider',{staticClass:"column is-12",attrs:{"rules":"","name":key},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
                    var valid = ref.valid;
return [_c('b-field',{attrs:{"type":{ 'is-danger': errors[0], 'is-primary': valid },"label":key}},[_c('b-input',{attrs:{"type":"textarea"},model:{value:(_vm.form.content_json[key]),callback:function ($$v) {_vm.$set(_vm.form.content_json, key, $$v)},expression:"form.content_json[key]"}})],1),_vm._l((errors),function(error){return _c('span',{key:error.id,staticClass:"has-text-danger help"},[_vm._v(_vm._s(error))])})]}}],null,true)})],1)}),0):_vm._e()]),_c('div',{staticClass:"level"},[_c('div',{staticClass:"level-left"},[_c('div',{staticClass:"level-item"})]),_c('div',{staticClass:"level-right"},[_c('div',{staticClass:"level-item pageFormSubmit"},[_c('b-button',{staticClass:"is-primary",attrs:{"loading":_vm.submittingForm},on:{"click":_vm.submitForm}},[_vm._v(" Save ")])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }