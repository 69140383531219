import Vue from "vue";
import VueApollo from "vue-apollo";
import store from "../src/store";
import {
  createApolloClient,
  restartWebsockets
} from "vue-cli-plugin-apollo/graphql-client";
import { onError } from "apollo-link-error";
// import { HttpLink } from "apollo-link-http";
import { createUploadLink } from "apollo-upload-client";
import { ApolloLink } from "apollo-link";
import { ToastProgrammatic as Toast } from "buefy";
// import { InMemoryCache } from "apollo-cache-inmemory";

// Install the vue plugin
Vue.use(VueApollo);

// Name of the localStorage item
const AUTH_TOKEN = "AUTH_TOKEN";

// eslint-disable-next-line no-unused-vars
const authRedirect = process.env.VUE_APP_AUTH_URL;
// Http endpoint
const httpEndpoint = process.env.VUE_APP_GRAPHQL_URL;
// const authRedirect =
//   process.env.VUE_APP_AUTH_URL || "https://id.dev.metime.com";
// const httpEndpoint =
//   process.env.VUE_APP_GRAPHQL_URL || "https://gate.dev.metime.com/graphql";

const httpLink = new createUploadLink({
  uri: httpEndpoint
});

// const uploadLink = createUploadLink({ uri: httpEndpoint });

// Files URL root
export const filesRoot =
  process.env.VUE_APP_FILES_ROOT ||
  httpEndpoint.substr(0, httpEndpoint.indexOf("/_graphql"));

Vue.prototype.$filesRoot = filesRoot;

const errorLink = onError(({ response, graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    for (let err of graphQLErrors) {
      switch (err.message) {
        case "Unauthorized":
          onLogout(this.$apollo.provider.defaultClient, true, store);
          break;
        default:
          Toast.open({
            message: err.message,
            type: "is-danger"
          });
          logError(err.message);
          break;
      }
    }
  }
  if (networkError) {
    switch (networkError.statusCode) {
      case 401:
        onLogout(this.$apollo.provider.defaultClient, true, store);
        break;
      default:
        Toast.open({
          message: networkError.message,
          type: "is-danger"
        });
        logError(networkError.message);
        break;
    }
  }
  // Try to stop error propagation once toasted and logged
  if (response) {
    response.errors = null;
  }
});

//const cache = new InMemoryCache();

// Config
const defaultOptions = {
  // You can use `https` for secure connection (recommended in production)
  // httpEndpoint,

  // You can use `wss` for secure connection (recommended in production)
  // Use `null` to disable subscriptions
  // THIS CODE WILL BE USED FOR SUBSCRIPTIONS
  wsEndpoint: process.env.VUE_APP_WEBSOCKET_URL,

  // LocalStorage token
  tokenName: AUTH_TOKEN,
  // Enable Automatic Query persisting with Apollo Engine
  persisting: false,
  // Use websockets for everything (no HTTP)
  // You need to pass a `wsEndpoint` for this to work
  websocketsOnly: false,
  // Is being rendered on the server?
  ssr: false,

  // Override default apollo link
  // note: don't override httpLink here, specify httpLink options in the
  // httpLinkOptions property of defaultOptions.

  // Override default cache
  //cache: cache,

  // Override the way the Authorization header is set
  // getAuth: (tokenName) => ...
  getAuth: tokenName => {
    // get the authentication token from local storage if it exists
    // return the headers to the context so httpLink can read them
    tokenName = localStorage.getItem(AUTH_TOKEN);
    if (tokenName) {
      return "Bearer " + tokenName;
    } else {
      return "";
    }
  },
  defaultHttpLink: false,
  link: ApolloLink.from([errorLink, httpLink])
  // errorLink.concat(httpLink)

  // Additional ApolloClient options
  // apollo: { ... }

  // Client local data (see apollo-link-state)
  // clientState: { resolvers: {}, defaults: {} }
};

// Call this in the Vue app file
export function apolloProvider(options = {}) {
  // Create apollo client
  const { apolloClient, wsClient } = createApolloClient({
    ...defaultOptions,
    ...options
  });

  // THIS CODE WILL BE USED FOR SUBSCRIPTIONS
  wsClient.connectionParams = () => {
    return {
      Authorization: localStorage.getItem(AUTH_TOKEN)
        ? localStorage.getItem(AUTH_TOKEN)
        : ""
    };
  };

  apolloClient.wsClient = wsClient;

  // Create vue apollo provider
  const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        // fetchPolicy: 'cache-and-network',
      }
    },
    // eslint-disable-next-line no-unused-vars
    errorHandler(error) {
      // console.log(
      //   "%cError",
      //   "background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;",
      //   error.message
      // );
    }
  });

  return apolloProvider;
}

// Manually call this when user log in
export async function onLogin(apolloClient, token) {
  if (typeof localStorage !== "undefined" && token) {
    localStorage.setItem(AUTH_TOKEN, token);
  }
  if (apolloClient.wsClient) {
    restartWebsockets(apolloClient.wsClient);
  }
  try {
    await apolloClient.resetStore();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log("%cError on cache reset (login)", "color: orange;", e.message);
  }
}

// Manually call this when user log out
export async function onLogout(
  apolloClient,
  redirect = true,
  vuexStore = store
) {
  if (typeof localStorage !== "undefined") {
    localStorage.removeItem(AUTH_TOKEN);
  }
  if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient);
  try {
    await apolloClient.clearStore();

    await vuexStore.dispatch("logoutUser");

    if (redirect) window.location = authRedirect;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log("%cError on cache reset (logout)", "color: orange;", e.message);
  }
}

// Query helper
export async function query(gql, variables = {}, callback) {
  return await apolloProvider()
    .defaultClient.query({
      query: gql,
      variables: variables
    })
    .catch(error => {
      return error;
    })
    .then(response => {
      return callback(response.data);
    });
}

// Mutate helper
export async function mutate(gql, variables = {}, callback) {
  return apolloProvider()
    .defaultClient.mutate({
      mutation: gql,
      variables: variables
    })
    .catch(error => {
      return error;
    })
    .then(response => {
      return callback(response.data);
    });
}

function logError(error) {
  console.log(
    "%cError",
    "background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;",
    error
  );
}
