<template>
  <card-component title="Basic Info" icon="account-circle">
    <ValidationObserver ref="userFormValidator" class="userForm">
      <div class="tile is-ancestor">
        <div id="avatar" class="tile is-3 is-parent is-vertical">
          <label class="label" style>Profile Avatar</label>
          <img
            class="avatarImage"
            :src="
              this.form.avatar
                ? this.form.avatar
                : this.origin + '/img/icons/blank_avatar.png'
            "
          />
          <div class="upload-btn-wrapper">
            <button class="button is-light avatar-button plus">
              <b-icon icon="plus" size="is-default" />
            </button>
            <input
              ref="avatarInput"
              type="file"
              title="Upload profile picture"
              @input="uploadImage"
              name="avatar"
            />
          </div>
          <button
            v-if="this.form.avatar"
            @click="removeAvatar"
            class="button is-light avatar-button trash-can"
          >
            <b-icon icon="trash-alt" pack="fas" size="is-small" />
          </button>
        </div>
        <section class="tile is-parent is-vertical">
          <div class="columns" style="flex-wrap: wrap;">
            <ValidationProvider
              class="is-11 is-offset-1 column"
              rules="required|min:2"
              v-slot="{ errors, valid }"
              name="First Name"
            >
              <b-field
                :type="{ 'is-danger': errors[0], 'is-primary': valid }"
                label="First Name"
              >
                <b-input trim v-model="form.firstName" />
              </b-field>
              <span
                v-for="error in errors"
                :key="error.id"
                class="has-text-danger help"
                >{{ error }}</span
              >
            </ValidationProvider>
            <ValidationProvider
              class="is-11 is-offset-1 column"
              rules="required|min:2"
              v-slot="{ errors, valid }"
              name="Last Name"
            >
              <b-field
                :type="{ 'is-danger': errors[0], 'is-primary': valid }"
                label="Last Name"
              >
                <b-input trim v-model="form.lastName" />
              </b-field>
              <span
                v-for="error in errors"
                :key="error.id"
                class="has-text-danger help"
                >{{ error }}</span
              >
            </ValidationProvider>
          </div>
        </section>
        <div class="tile is-parent is-vertical">
          <div class="columns" style="flex-wrap: wrap">
            <ValidationProvider
                    class="is-10 column"
                    v-slot="{ errors, valid }"
                    name="Country"
            >
              <b-field
                      :type="{ 'is-danger': errors[0], 'is-primary': valid }"
                      label="Country"
              >
                <b-input trim v-model="form.country" />
              </b-field>
              <span
                      v-for="error in errors"
                      :key="error.id"
                      class="has-text-danger help"
              >{{ error }}</span
              >
            </ValidationProvider>
            <ValidationProvider
                    class="is-10 column"
                    v-slot="{ errors, valid }"
                    name="City"
            >
              <b-field
                      :type="{ 'is-danger': errors[0], 'is-primary': valid }"
                      label="City"
              >
                <b-input trim v-model="form.city" />
              </b-field>
              <span
                      v-for="error in errors"
                      :key="error.id"
                      class="has-text-danger help"
              >{{ error }}</span>
            </ValidationProvider>
          </div>
        </div>
      </div>
      <div class="columns userFormSubmit">
        <div class="column is-offset-9">
          <b-button
            class="is-primary"
            :loading="savingProfile"
            @click="submitForm"
            >Save</b-button
          >
        </div>
      </div>
    </ValidationObserver>
  </card-component>
</template>

<script>
import CardComponent from "../../base/components/CardComponent";
import ValidationErrors from "../../../mixins/ValidationErrors";
import { UPLOAD_IMAGE, UPDATE_ME } from "../../../_graphql/Mutations/mutations";

export default {
  name: "ProfileUpdateForm",
  mixins: [ValidationErrors],
  components: {
    CardComponent
  },
  props: {
    me: Object
  },
  data() {
    return {
      form: {
        _id: null,
        firstName: "",
        lastName: "",
        country: null,
        city: null,
        avatar: null
      },
      activeCountries: [],
      citiesByCountryId: [],
      origin: "",
      savingProfile: false
    };
  },
  watch: {
    countryInput() {
      if (this.countryInput == "" || !this.isValidCountry) {
        this.form.city = "";
      }
    }
  },
  mounted() {
    this.origin = location.origin;
    this.populateEditForm();
  },
  methods: {
    submitForm() {
      this.$refs.userFormValidator.validate().then(success => {
        if (!success) {
          this.refName = "userFormValidator";
          this.handleErrors();
          return;
        }
        const variables = {
          id: this.form._id,
          input: {
            firstName: this.form.firstName,
            lastName: this.form.lastName,
            country: this.form.country ? this.form.country : null,
            city: this.form.city ? this.form.city : null,
            avatar: this.form.avatar
          }
        };
        this.savingProfile = true;
        this.$apollo.provider.defaultClient
          .mutate({
            mutation: UPDATE_ME,
            variables: variables
          })
          .catch(error => {
            return this.$buefy.toast.open({
              message: `${error}`,
              type: "is-danger",
              duration: 3000
            });
          })
          .then(data => {
            let updateMe = data.data.updateMe;
            if (updateMe.success) {
              this.$emit("update", updateMe.user);
              this.$buefy.toast.open({
                message: "Profile updated successfully.",
                type: "is-success",
                duration: 1500
              });
            } else {
              this.$buefy.toast.open({
                message: updateMe.errors.join(", "),
                type: "is-danger",
                duration: 3000
              });
            }
            this.savingProfile = false;
          });
      });
    },
    populateEditForm() {
      this.form._id = this.me.user._id;
      this.form.firstName = this.me.user.firstName;
      this.form.lastName = this.me.user.lastName;
      this.form.role = this.me.user.role;
      this.form.avatar = this.me.user.avatar;
      this.form.city = this.me.user.city;
      this.form.country = this.me.user.country;
    },
    uploadImage(event) {
      let files = event.target.files;
      let file = files.length > 0 ? files[0] : null;
      if (!file) {
        return;
      }
      if (
        file.type !== "image/png" &&
        file.type !== "image/jpg" &&
        file.type !== "image/jpeg"
      ) {
        this.$refs["avatarInput"].value = "";
        return this.$buefy.toast.open({
          message: "Allowed image formats are .jpg, .jpeg and .png!",
          type: "is-warning"
        });
      }
      let config = {
        header: {
          "Content-Type": "image/*"
        }
      };
      this.$apollo.mutate({
        mutation: UPLOAD_IMAGE,
        variables: {
          file: file
        },
        context: config,
        update: (cache, { data: { resize } }) => {
          this.form.avatar = resize.data.url;
        }
      });
    },
    removeAvatar() {
      this.$buefy.dialog.confirm({
        title: "Confirm Removal",
        message: "Are you sure you want to <b>remove</b> this picture?",
        confirmText: "Remove",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => {
          this.form.avatar = null;
          this.$refs["avatarInput"].value = "";
        }
      });
    }
  }
};
</script>
<style scoped lang="scss">
@import "bulma/sass/utilities/_all";
@import "bulma/sass/grid/columns";
#avatar {
  min-height: 285px;
  min-width: 285px;
  height: 310px;
}
@include tablet {
  .userFormSubmit {
    margin-top: 0px;
  }
}
</style>
