<template>
  <section class="section">
    <nav
      class="list-item breadcrumb"
      aria-label="breadcrumbs"
      v-if="!isLoading"
    >
      <breadcrumbs />
    </nav>
    <apollo-loader />
    <router-view name="dialog"></router-view>
    <router-view />
  </section>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "EmptyRouterView",
  computed: {
    ...mapGetters(["isLoading"])
  }
};
</script>

<style scoped></style>
