<template>
  <section class="section">
    <div class="is-title-bar">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <h1 class="title">
              Categories
            </h1>
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <router-link
              :to="{
                name: `${getRouteFirstName}.create`,
                params: { id: this.getAreaId }
              }"
            >
              <b-button class="is-primary">
                Create Category
              </b-button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <data-table
      :current-page="currentPage"
      pageName="currentCategoriesPage"
      :data="area.area_types"
      class="has-table"
    />
  </section>
</template>

<script>
import DataTable from "../components/DataTable";
import { ALL_AREA_TYPES } from "../../../_graphql/Queries/queries";
import LoadingState from "../../../mixins/LoadingState";
import SubscribeForRefetch from "../../../mixins/SubscribeForRefetch";
import { mapGetters } from "vuex";
export default {
  name: "categories",
  components: { DataTable },
  mixins: [LoadingState, SubscribeForRefetch],
  apollo: {
    area: {
      query: ALL_AREA_TYPES,
      variables() {
        return {
          id: this.getAreaId,
          input: {
            order: "id:asc"
          }
        };
      },
      update: data => data.area
    }
  },
  data() {
    return {
      parentId: null,
      area: {
        area_types: []
      }
    };
  },
  computed: {
    getAreaId() {
      return parseInt(this.$route.params.areaId);
    },
    ...mapGetters({
      currentPage: "currentCategoriesPage"
    })
  },
  watch: {
    area(newVal) {
      let breadcrumbs = [
        {
          name: "Areas",
          link: "/areas"
        },
        {
          name: newVal.name
        }
      ];
      this.$store.commit("setBreadcrumbs", breadcrumbs);
    }
  }
};
</script>
