<template
  ><section class="section">
    <title-bar>Create Solution</title-bar>
    <ValidationObserver ref="createSolutionForm">
      <review-section
        ref="reviewSection"
        :statuses="reviewStatuses"
        :user-role="userRole"
        :show-update-btn="false"
        :allow-update="true"
      ></review-section>
      <card-component
        title="Solution Info"
        custom-title-styles="has-text-primary"
        ><solution-form
          :form="solutionForm"
          :publish-validation="publishValidation"
          @categoryChanged="categoryChanged"
        ></solution-form
      ></card-component>
      <card-component
        title="Search & Reference"
        custom-title-styles="has-text-primary"
        ><reference
          :form="referenceForm"
          :category="solutionForm.category"
          :publish-validation="publishValidation"
          :is-published="is_published"
          @deletedKeyword="deleteKeyword"
        ></reference
      ></card-component>
    </ValidationObserver>
    <div class="buttons is-right solution-action-buttons">
      <b-button class="is-primary" @click="onSave"
        >Create Solution</b-button
      >
      <b-button
        v-if="userRole !== 'OPERATOR'"
        class="is-primary"
        @click="onPublish"
        >Publish</b-button
      >
    </div>
  </section></template
>

<script>
import { mapGetters } from "vuex";
import TitleBar from "../../base/components/TitleBar";
import CardComponent from "../../base/components/CardComponent";
import ReviewSection from "../../providers/components/ReviewSection";
import SolutionForm from "../components/SolutionForm";
import Reference from "../components/Reference";
import { CREATE_SOLUTION } from "../../../_graphql/Mutations/mutations";

export default {
  data() {
    return {
      publishValidation: false,
      adminStatuses: [
        {
          name: "Draft",
          value: "DRAFT"
        },
        {
          name: "Ready for review",
          value: "READY_FOR_REVIEW"
        },
        {
          name: "Needs corrections",
          value: "NEEDS_CORRECTIONS"
        },
        {
          name: "Reviewed",
          value: "REVIEWED"
        }
      ],
      operatorStatuses: [
        {
          name: "Draft",
          value: "DRAFT"
        },
        {
          name: "Ready for review",
          value: "READY_FOR_REVIEW"
        }
      ],
      solutionForm: {
        title: "",
        meta_title: "",
        slug: "",
        category: {
          id: null,
          slug: ""
        },
        subcategory: {
          name: ""
        },
        description: "",
        meta_description: "",
        content: "",
        read_time: null,
        cover_thumb_url: "",
        cover_url: "",
        coverImageDropFiles: null,
        thumbnailDropFiles: null
      },
      referenceForm: {
        related_solutions: [],
        treatments: [],
        products: [],
        technologies: [],
        specialties: [],
        keywords: []
      },
      is_published: false
    };
  },
  components: {
    TitleBar,
    ReviewSection,
    CardComponent,
    SolutionForm,
    Reference
  },
  computed: {
    ...mapGetters({
      userRole: "userRole"
    }),
    reviewStatuses() {
      return {
        adminStatuses: this.adminStatuses,
        moderatorStatuses: this.adminStatuses,
        operatorStatuses: this.operatorStatuses
      };
    }
  },
  methods: {
    deleteKeyword(tag) {
      this.referenceForm.keywords = this.referenceForm.keywords.filter(
        keyword => {
          return keyword !== tag.text;
        }
      );
    },
    async onSave() {
      this.publishValidation = false;
      this.is_published = false;

      await this.$refs.createSolutionForm.reset();
      this.createSolution();
    },
    createSolution() {
      this.$refs.createSolutionForm.validate().then(valid => {
        if (
          !valid ||
          (this.publishValidation && this.referenceForm.keywords.length == 0)
        ) {
          return this.$buefy.toast.open({
            message: "Please fill all required fields",
            type: "is-danger"
          });
        }

        if (
          this.publishValidation &&
          this.referenceForm.treatments.length == 0 &&
          this.referenceForm.products.length == 0 &&
          this.referenceForm.technologies.length == 0 &&
          this.referenceForm.specialties.length == 0
        ) {
          return this.$buefy.toast.open({
            message:
              "You must populate at least one treatment, product, technology or provider type",
            type: "is-danger"
          });
        }

        let variables = {
          input: {
            title: this.solutionForm.title,
            category_id: this.solutionForm.category.id,
            subcategory: this.solutionForm.subcategory.name,
            cover_url: this.solutionForm.cover_url,
            cover_thumb_url: this.solutionForm.cover_thumb_url,
            meta_title: this.solutionForm.meta_title,
            slug: this.solutionForm.slug,
            description: this.solutionForm.description,
            meta_description: this.solutionForm.meta_description,
            note: this.$refs.reviewSection.note,
            content: this.solutionForm.content,
            read_time: parseInt(this.solutionForm.read_time),
            keywords: this.referenceForm.keywords,
            related_solutions_ids: this.referenceForm.related_solutions,
            treatments_ids: this.referenceForm.treatments,
            products_ids: this.referenceForm.products,
            technologies_ids: this.referenceForm.technologies,
            specialties_ids: this.referenceForm.specialties,
            is_published: this.is_published,
            status: this.$refs.reviewSection.newStatus || "DRAFT"
          }
        };

        this.$apollo
          .mutate({
            mutation: CREATE_SOLUTION,
            variables: variables
          })
          .then(({ data: { createSolution } }) => {
            if (createSolution) {
              this.$buefy.toast.open({
                message: "Solution created successfully",
                type: "is-success"
              });
            }
            this.$router.push("/solutions/edit/" + createSolution.id);
          });
      });
    },
    async onPublish() {
      this.publishValidation = true;
      this.is_published = true;

      await this.$refs.createSolutionForm.reset();
      this.createSolution();
    },
    publishSolution() {
      this.publishValidation = true;

      this.is_published = true;
      this.createSolution();
    },
    categoryChanged() {
      this.$refs.createSolutionForm.reset();
    }
  }
};
</script>

<style lang="scss" scoped>
.solution-action-buttons {
  padding-bottom: 3rem;
  margin-top: 1.5rem;
}
</style>
