<template>
  <div class="files" :class="{ notMine: !isMine }">
    <a v-if="isImage" target="_blank" :href="data.file.url">
      <img alt="Photo" class="image is-thumb" :src="data.file.url"
    /></a>

    <a v-else-if="data" class="fileLink" :href="data.file.url" target="_blank">
      <b-icon :class="fileInfo" icon="file" size="is-medium"></b-icon>
      <div class="file">
        <span class="url">{{
          data.file.filename ? data.file.filename : "File"
        }}</span>
        <span class="size">{{ data.file.size | sizeInfo }} </span>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: ["data", "isMine"],
  filters: {
    sizeInfo(bytes) {
      let finalSize = null;
      let sizeType = "";
      if (bytes >= 1048576) {
        sizeType = "MB";
        finalSize = bytes / Math.pow(1024, 2);
      } else if (bytes < 1048576 && bytes >= 1024) {
        sizeType = "KB";
        finalSize = bytes / 1024;
      } else {
        sizeType = "B";
        finalSize = bytes;
      }
      return finalSize ? finalSize.toFixed(2) + " " + sizeType : "";
    }
  },
  computed: {
    isImage() {
      return (
        this.data &&
        (this.data.file.type == "image/jpeg" ||
          this.data.file.type == "image/png")
      );
    },
    fileInfo() {
      let type = "";
      switch (this.data.file.type) {
        case "application/pdf":
          type = "is-pdf";
          break;

        case "text/plain":
          type = "is-doc";
          break;

        case "image/jpeg":
          type = "is-jpeg";
          break;

        case "application/zip":
          type = "is-zip";
          break;

        case "image/png":
          type = "is-png";
          break;

        case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          type = "is-doc";
          break;

        case "application/msword":
          type = "is-doc";
          break;

        case "application/rar":
          type = "is-zip";
          break;

        case "application/x-rar-compressed":
          type = "is-zip";
          break;

        default:
          type = "is-primary";
          break;
      }
      return type;
    }
  }
};
</script>

<style lang="scss" scoped>
.image {
  cursor: pointer;
  max-width: 180px;
  max-height: 180px;
  border-radius: 5%;
}
.notMine {
  justify-content: flex-end;
}
.files {
  display: flex;
  align-items: center;
  .fileLink {
    display: flex;
    align-items: center;
    .file {
      display: flex;
      flex-direction: column;
      padding-left: 6px;
      .url {
        word-break: break-word;
      }
    }
  }
  .icon {
    border-radius: 50%;
    color: white;
    width: 48px;
    height: 48px;
    padding: 6px;
    vertical-align: middle;
  }
  .is-primary {
    background-color: #0ba1d1;
  }
  .is-pdf {
    background-color: #f18a6c;
  }
  .is-doc {
    background-color: #0fa1d1;
  }
  .is-zip {
    background-color: #707070;
  }
  .is-jpeg {
    background-color: deeppink;
  }
  .is-png {
    background-color: chocolate;
  }
}
</style>
