import Profile from "./views/Profile";
import { Role } from "../../_helpers/roles";

const profileRoute = {
  path: "/profile",
  name: "profile",
  component: Profile,
  meta: { authorize: [Role.Vendor, Role.Admin, Role.Operator, Role.Moderator] }
};

export default [profileRoute];
