import Vue from "vue";
import VueRouter from "vue-router";

import ProvidersRoutes from "../modules/providers/router";
import TreatmentsRoutes from "../modules/treatments/router";
import usersRoutes from "../modules/users/router";
import loginRoutes from "../modules/base/router";
import dashboardRoutes from "../modules/dashboard/router";
import messagesRoutes from "../modules/messages/router";
import profileRoutes from "../modules/profile/router";
import settingRoutes from "../modules/settings/router";
import articleRoutes from "../modules/articles/router";
import pageRoutes from "../modules/pages/router";
import componentRoutes from "../modules/components/router";
import flaggedRoutes from "../modules/flagged/router";
import paymentRoutes from "../modules/payments/router";
import solutionsRoute from "../modules/solutions/router";
import store from "../store";
import pageNotFound from "../modules/base/components/PageNotFound";
import { ME } from "../_graphql/Queries/queries";
import { onLogout, apolloProvider } from "../vue-apollo";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    ...ProvidersRoutes,
    ...TreatmentsRoutes,
    ...usersRoutes,
    ...loginRoutes,
    ...dashboardRoutes,
    ...messagesRoutes,
    ...profileRoutes,
    ...settingRoutes,
    ...articleRoutes,
    ...pageRoutes,
    ...componentRoutes,
    ...flaggedRoutes,
    ...paymentRoutes,
    ...solutionsRoute,
    { path: "*", component: pageNotFound }
  ]
});

router.beforeEach((to, from, next) => {
  const authUser = store.getters["meUser"];
  const { authorize } = to.meta;
  if (authorize) {
    if (!authUser.user) {
      // not logged in so redirect to login page with the return url
      return next({ path: "/authenticate" });
    }

    // check if route is restricted by role
    if (authorize.length && !authorize.includes(authUser.user.role)) {
      // role not authorised so redirect to pageNotFound
      return next({ path: "*" });
    }
    // check if user is deleted and logout before creating provider
    if (
      to.path === "/providers/list" ||
      to.path === "/providers/new" ||
      to.path === "/dashboard"
    ) {
      apolloProvider()
        .defaultClient.query({
          query: ME
        })
        .then(result => {
          if (result.data.me.user === null) {
            onLogout(apolloProvider().defaultClient, true);
          }
        });
    }
  }

  next();
});

export default router;
