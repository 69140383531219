import conversations from "./conversations";
import messsages from "./messsages";
import providers from "./providers";
import users from "./users";

export default {
  ...conversations,
  ...messsages,
  ...providers,
  ...users
};
