<template>
  <div class="card procedures">
    <header class="card-header has-background-customBackground">
      <div class="card-header-title">
        <p class="has-text-primary">
          <b-icon icon="account-multiple" custom-size="default" />
          Procedures
          <b-tooltip
            animated
            multilined
            class="tooltip"
            label="List the names of the procedures that you offer"
            position="is-top"
            ><b-icon icon="information-outline" type="is-primary"
          /></b-tooltip>
        </p>
        <div v-if="allowUpdate" class="buttons">
          <b-button @click="openOrderModal" class="is-primary product-buttons">
            Order
          </b-button>
        </div>
      </div>
    </header>
    <div
      v-if="areas.length > 0"
      class="card-content scrollable-procedures has-text-left scrollable"
    >
      <div
          class="tag-input"
          v-for="(area, index) in areas"
          :key="index"
      >
        <div>
          {{ area.name }}
        </div>
        <TagsInput
            :allowEditTags="allowUpdate"
            :categoryId="parseInt(area.id)"
            :placeholder="`Add new ${area.name} procedure`"
            :itemTags="providersProcedures[parseInt(area.id)] ? providersProcedures[parseInt(area.id)].procedures : []"
            :createItem="create"
            :updateItem="update"
            :deleteItem="deleteProcedure"
        />
        <hr class="solid">
      </div>

    </div>
    <div v-else>
      <div class="columns card-content is-centered">
        There are no procedures added
      </div>
    </div>
    <AddProcedureModal
      ref="addProcedure"
      @updatedProcedures="updatedProcedures"
    />
    <OrderProcedureAreasModal
      ref="orderProcedureArea"
      :areasProcedures="areasProcedures"
      :providerId="parseInt(this.provider.id)"
      @updatedProcedures="updatedProcedures"
    />
    <EditProcedureModal
      ref="editProcedure"
      :data="selectedProcedures"
      @updatedProcedures="updatedProcedures"
    />
  </div>
</template>

<script>
import AddProcedureModal from "./AddProcedureModal";
import EditProcedureModal from "./EditProcedureModal";
import {DELETE_PROCEDURE, UPDATE_PROCEDURE, UPDATE_PROVIDER} from "@/_graphql/Mutations/mutations";
import OrderProcedureAreasModal from "./OrderProcedureAreasModal";
import {PROCEDURES_AREAS} from "@/_graphql/Queries/queries";
import TagsInput from "@/modules/providers/components/TagsInput";

export default {
  data() {
    return {
      tag: [],
      procedure_ids: [],
      proceduresAreas: [],
      selectedProcedures: {},
      areas: []
    };
  },
  props: {
    allowUpdate: {
      default: false
    },
    areasProcedures: {
      required: false,
      default: Array
    },
    provider: {
      required: false,
      default: Object
    }
  },
  computed: {
    defaultOpenedDetails() {
      return this.areasProcedures.map(category => category.id);
    },
    providersProcedures() {
      return this.groupByAreaId(this.provider.procedures)
    },
    getProceduresAreas: {
      get: function () {
        return this.proceduresAreas;
      },
      set: function (newVal) {
        return newVal;
      }
    }
  },
  watch: {
    getProceduresAreas: {
      handler: function() {
        this.areas = this.getAreasAndProcedures();
      },
      immediate: true
    }
  },
  components: {
    AddProcedureModal,
    EditProcedureModal,
    OrderProcedureAreasModal,
    TagsInput
  },
  methods: {
    updatedProcedures() {
      this.$emit("updatedProcedures");
    },
    openModal() {
      this.$refs.addProcedure.openModal();
    },
    openOrderModal() {
      this.$refs.orderProcedureArea.openModal();
    },
    openEditModal(data) {
      this.selectedProcedures = data;
      this.$refs.editProcedure.openModal();
    },
    toggle(row) {
      this.$refs.table.toggleDetails(row);
    },
    async create(procedure, areaId, addTag) {
      const createdProcedure = {
        procedures: {
          name: procedure.text,
          procedures_areas_id: areaId,
        }
      };
      const providerId = parseInt(this.$route.params.providerId);
      const payload = {
        mutation: UPDATE_PROVIDER,
        queryName: "updateProvider"
      };

      const variables = {
        id: providerId,
        input: createdProcedure
      };

      this.$store.commit("provider/collection/editModel", variables);
      await this.$store.dispatch("provider/collection/update", payload);
      this.$emit("updatedProcedures");
      addTag();
    },
    async update(item, areaId) {
      const payload = {
        mutation: UPDATE_PROCEDURE,
        queryName: "updateProvidersProcedure"
      };
      const variables = {
        id: parseInt(item.id),
        input: {
          name: item.name,
          procedures_areas_id: areaId,
        }
      };

      this.$store.commit("provider/collection/editModel", variables);
      await this.$store.dispatch("provider/collection/update", payload);
      this.$emit("updatedProcedures");
    },
    async deleteProcedure(item) {
      const mutation = DELETE_PROCEDURE;
      const variables = {
        id: parseInt(item.id)
      };
      this.$store.commit("provider/collection/deleteModel", variables);
      await this.$store.dispatch("provider/collection/delete", mutation);
      this.updatedProcedures();
    },
    groupByAreaId(procedures) {
      return procedures.reduce((objectsByKeyValue, obj) => {
        const value = obj["procedure_area_id"];

        objectsByKeyValue[value] = {
          id: obj.procedure_area_id,
          procedures: (objectsByKeyValue[value]
                  ? objectsByKeyValue[value].procedures
                  : []
          ).concat(obj)
        };

        return objectsByKeyValue;
      }, {});
    },
    getAreasAndProcedures() {
      const areasPositions = this.areasProcedures.reduce((map, obj) => {
        map[obj.id] = obj;
        return map;
      }, {});

      if (this.proceduresAreas.data) {
        const sortedArray = [
          'Face & Neck',
          'Body',
          'Skin',
          'Intimate',
          'Hair',
          'Teeth',
        ];

        return this.proceduresAreas.data.reduce((map, obj) => {
          const position = areasPositions[obj.id] ? areasPositions[obj.id].position : 6

          map[obj.id] = {...obj, ...{position: position}};
          return map;
        }, []).filter(area => area != null).sort((a, b) => {
          return sortedArray.indexOf(a.name) - sortedArray.indexOf(b.name);
        });
      }

      return [];
    }
  },
  apollo: {
    proceduresAreas: {
      query: PROCEDURES_AREAS,
      update: data => data.proceduresAreas,

    }
  },
};
</script>

<style lang="scss" scoped>
.procedures {
  .tag-input {
    margin-top: 5px;
  }
  min-height: 600px;
  .scrollable-procedures {
    max-height: 500px;
    overflow-y: scroll;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .product-buttons {
    margin-top: 0px;
  }

  .procedures-buttons {
    margin-left: 15px;
  }

  .box-custom {
    margin-bottom: 5px;
    margin-top: 5px;
    padding-bottom: 10px;
    padding-top: 10px;
  }

  .button {
    border: none;
    height: auto;
  }

  .card-header-title {
    justify-content: space-between;
  }

  .b-tooltip {
    vertical-align: middle;
  }

  hr.solid {
    margin: auto;
    border-top: 1px solid #e6eff2;
    width: 100%;
  }
}
</style>
