<template>
  <nav
    v-show="isNavBarVisible && meUser.user"
    id="navbar-main"
    class="navbar is-fixed-top"
  >
    <div class="navbar-brand">
      <a
        class="navbar-item is-hidden-desktop"
        @click.prevent="menuToggleMobile"
      >
        <b-icon :icon="menuToggleMobileIcon" />
      </a>
    </div>
    <div class="navbar-brand is-right">
      <div class="navbar-item navbar-item-menu-toggle is-hidden-desktop">
        <a @click.prevent="menuNavBarToggle">
          <b-icon :icon="menuNavBarToggleIcon" custom-size="default" />
        </a>
      </div>
    </div>
    <div
      class="navbar-menu fadeIn animated faster"
      :class="{ 'is-active': isMenuNavBarActive }"
    >
      <div class="navbar-end">
        <nav-bar-menu class="has-divider is-hidden-desktop">
          <b-icon icon="menu" custom-size="default" />
          <span>Menu</span>
          <div slot="dropdown" class="navbar-dropdown">
            <a class="navbar-item" @click="goTo('/profile')">
              <b-icon icon="account" custom-size="default" />
              <span>My Profile</span>
            </a>
            <hr class="navbar-divider" />
            <a class="navbar-item" title="Log out" @click="logout">
              <b-icon icon="logout" custom-size="default" />
              <span>Log Out</span>
            </a>
          </div>
        </nav-bar-menu>
      </div>
      <div
        class="navbar-end is-hidden-touch is-hidden-mobile-only is-hidden-tablet-only "
      >
        <a class="navbar-item" title="My Profile" @click="goTo('/profile')">
          <b-icon icon="account" custom-size="default" />
          <span>My Profile</span>
        </a>
        <a class="navbar-item" title="Log out" @click="logout">
          <b-icon icon="logout" custom-size="default" />
          <span>Log out</span>
        </a>
      </div>
    </div>
  </nav>
</template>

<script>
import NavBarMenu from "./NavBarMenu";
import { mapState, mapGetters } from "vuex";
import { onLogout } from "../../../vue-apollo";

export default {
  name: "NavBar",
  components: {
    NavBarMenu
  },
  data() {
    return {
      isMenuNavBarActive: false
    };
  },
  computed: {
    menuNavBarToggleIcon() {
      return this.isMenuNavBarActive ? "close" : "dots-vertical";
    },
    menuToggleMobileIcon() {
      return this.isAsideMobileExpanded ? "backburger" : "forwardburger";
    },
    ...mapState(["isNavBarVisible", "isAsideMobileExpanded"]),
    ...mapGetters(["meUser"])
  },
  methods: {
    menuToggleMobile() {
      this.$store.commit("asideMobileStateToggle");
    },
    menuNavBarToggle() {
      this.isMenuNavBarActive = !this.isMenuNavBarActive;
    },
    logout() {
      this.$intercom.shutdown();
      onLogout(this.$apollo.provider.defaultClient, true, this.$store);
    },
    goTo(path) {
      if (this.$route.path != path) {
        this.$router.push(path);
      }
    }
  }
};
</script>
