var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('card-component',[_c('ValidationObserver',{ref:"articleFormValidator",staticClass:"userForm",attrs:{"id":"articleForm"}},[_c('div',{staticClass:"columns is-variable is-7"},[_c('div',{staticClass:"column is-9"},[_c('div',{staticClass:"columns is-variable is-5 is-multiline is-mobile"},[_c('ValidationProvider',{staticClass:"column is-6",attrs:{"rules":"required|min:2|max:200","name":"Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"type":{ 'is-danger': errors[0], 'is-primary': valid },"label":"Name"}},[_c('b-input',{attrs:{"trim":""},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_vm._l((errors),function(error){return _c('span',{key:error.id,staticClass:"has-text-danger help"},[_vm._v(_vm._s(error))])})]}}])}),_c('ValidationProvider',{staticClass:"column is-6",attrs:{"rules":"required|min:2|max:200","name":"Slug"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"type":{ 'is-danger': errors[0], 'is-primary': valid },"label":"Slug"}},[_c('b-input',{attrs:{"placeholder":"example-slug-name","trim":""},model:{value:(_vm.form.slug),callback:function ($$v) {_vm.$set(_vm.form, "slug", $$v)},expression:"form.slug"}})],1),_vm._l((errors),function(error){return _c('span',{key:error.id,staticClass:"has-text-danger help"},[_vm._v(_vm._s(error))])})]}}])}),_c('ValidationProvider',{staticClass:"column is-6-desktop is-6-tablet",attrs:{"rules":"required|min:2|max:200","name":"Title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"type":{ 'is-danger': errors[0], 'is-primary': valid },"label":"Title"}},[_c('b-input',{attrs:{"trim":""},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}})],1),_vm._l((errors),function(error){return _c('span',{key:error.id,staticClass:"has-text-danger help"},[_vm._v(_vm._s(error))])})]}}])})],1),_c('div',{staticClass:"columns is-variable is-5 is-multiline"},[_c('ValidationProvider',{staticClass:"column is-6",attrs:{"rules":"required|min:2","name":"Meta Keywords"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"type":{ 'is-danger': errors[0], 'is-primary': valid },"label":"Meta Keywords"}},[_c('b-taginput',{attrs:{"ellipsis":"","icon":"label","placeholder":"Add a keyword","maxlength":_vm.keywordsMaxLength,"before-adding":_vm.checkTagsLength},model:{value:(_vm.form.meta_keywords),callback:function ($$v) {_vm.$set(_vm.form, "meta_keywords", $$v)},expression:"form.meta_keywords"}})],1),_vm._l((errors),function(error){return _c('span',{key:error.id,staticClass:"has-text-danger help"},[_vm._v(_vm._s(error))])})]}}])}),_c('ValidationProvider',{staticClass:"column is-6",attrs:{"rules":"required|min:2|max:200","name":"Meta Title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"type":{ 'is-danger': errors[0], 'is-primary': valid },"label":"Meta Title"}},[_c('b-input',{attrs:{"trim":""},model:{value:(_vm.form.meta_title),callback:function ($$v) {_vm.$set(_vm.form, "meta_title", $$v)},expression:"form.meta_title"}})],1),_vm._l((errors),function(error){return _c('span',{key:error.id,staticClass:"has-text-danger help"},[_vm._v(_vm._s(error))])})]}}])}),_c('ValidationProvider',{staticClass:"column is-6",attrs:{"rules":"required|min:2|max:200","name":"Meta Description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"type":{ 'is-danger': errors[0], 'is-primary': valid },"label":"Meta Description"}},[_c('b-input',{attrs:{"trim":""},model:{value:(_vm.form.meta_description),callback:function ($$v) {_vm.$set(_vm.form, "meta_description", $$v)},expression:"form.meta_description"}})],1),_vm._l((errors),function(error){return _c('span',{key:error.id,staticClass:"has-text-danger help"},[_vm._v(_vm._s(error))])})]}}])}),_c('ValidationProvider',{staticClass:"column is-6",attrs:{"name":"Read time"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"type":{ 'is-danger': errors[0], 'is-primary': valid },"label":"Read time"}},[_c('b-input',{attrs:{"type":"number","trim":""},model:{value:(_vm.form.reading_time),callback:function ($$v) {_vm.$set(_vm.form, "reading_time", $$v)},expression:"form.reading_time"}})],1),_vm._l((errors),function(error){return _c('span',{key:error.id,staticClass:"has-text-danger help"},[_vm._v(_vm._s(error))])})]}}])})],1)]),_c('div',{staticClass:"column is-3"},[_c('div',{staticClass:"columns is-multiline"})])]),_c('div',{staticClass:"columns is-variable is-5 is-multiline is-mobile"},[_c('ValidationProvider',{staticClass:"column is-12",attrs:{"rules":"","name":"Blog type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"Blog type:","label-for":"blogType"}},[_c('b-select',{attrs:{"id":"blogType","expanded":"","placeholder":"Select a blog type"},model:{value:(_vm.form.blog_type),callback:function ($$v) {_vm.$set(_vm.form, "blog_type", $$v)},expression:"form.blog_type"}},[_c('option',{attrs:{"value":""}},[_vm._v(" patients ")]),_c('option',{attrs:{"value":"providers"}},[_vm._v(" providers ")])])],1),_vm._l((errors),function(error){return _c('span',{key:error.id,staticClass:"has-text-danger help"},[_vm._v(_vm._s(error))])})]}}])})],1),_c('div',{staticClass:"columns is-variable is-5 is-multiline is-mobile"},[_c('ValidationProvider',{staticClass:"column is-12",staticStyle:{"padding-bottom":"20px"},attrs:{"rules":"required","name":"Content"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"content",attrs:{"type":{ 'is-danger': errors[0], 'is-primary': valid },"label":"Content"}},[_c('ckeditor',{attrs:{"editor":_vm.editor,"config":_vm.editorConfig},on:{"input":_vm.fixSideTextHeight,"ready":_vm.fixSideTextHeight},model:{value:(_vm.form.content),callback:function ($$v) {_vm.$set(_vm.form, "content", $$v)},expression:"form.content"}})],1),_vm._l((errors),function(error){return _c('span',{key:error.id,staticClass:"has-text-danger help"},[_vm._v(_vm._s(error))])})]}}])}),_c('ValidationProvider',{staticClass:"column is-6",attrs:{"rules":_vm.prefill ? '' : 'required',"name":"Image"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"type":{ 'is-danger': errors[0], 'is-primary': valid },"label":"Image"}},[_c('picture-input',{ref:"pictureInput",attrs:{"id":"picture-input","prefill":_vm.prefill,"alertOnError":false,"width":"600","height":"300","margin":"0","crop":false,"accept":"image/jpeg, image/png, image/jpg, image/JPG","size":"20","button-class":"button is-primary","custom-strings":{
              upload: '<h1>Oops! Something went wrong.</h1>',
              drag: 'Drop your image here or click to upload',
              change: 'Change Image'
            }},on:{"error":_vm.notifyError,"change":_vm.onImageChange},model:{value:(_vm.form.image),callback:function ($$v) {_vm.$set(_vm.form, "image", $$v)},expression:"form.image"}})],1),_vm._l((errors),function(error){return _c('span',{key:error.id,staticClass:"has-text-danger help"},[_vm._v(_vm._s(error))])})]}}])}),_c('ValidationProvider',{staticClass:"column is-12",attrs:{"rules":"required","name":"Description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
            var valid = ref.valid;
return [_c('b-field',{attrs:{"type":{ 'is-danger': errors[0], 'is-primary': valid },"label":"Description"}},[_c('b-input',{attrs:{"maxlength":"500","type":"textarea"},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})],1),_vm._l((errors),function(error){return _c('span',{key:error.id,staticClass:"has-text-danger help"},[_vm._v(_vm._s(error))])})]}}])})],1)]),_c('div',{staticClass:"level"},[_c('div',{staticClass:"level-left"},[_c('div',{staticClass:"level-item"})]),_c('div',{staticClass:"level-right"},[_c('div',{staticClass:"level-item articleFormSubmit"},[_c('b-button',{staticClass:"is-primary",attrs:{"loading":_vm.submittingDraft},on:{"click":function($event){return _vm.submitForm(true)}}},[_vm._v(" Save draft ")])],1),_c('div',{staticClass:"level-item articleFormSubmit"},[_c('b-button',{staticClass:"is-primary",attrs:{"loading":_vm.submittingPublish},on:{"click":function($event){return _vm.submitForm(false)}}},[_vm._v(" Publish ")])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }