import Settings from "./views/Settings";
import { Role } from "../../_helpers/roles";

const settingsRoute = {
  path: "/settings",
  name: "settings",
  component: Settings,
  meta: { authorize: [Role.Admin] }
};

export default [settingsRoute];
