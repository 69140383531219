<template>
  <section>
    <b-modal
      :active.sync="isActive"
      has-modal-card
      trap-focus
      :on-cancel="closeModal"
    >
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Journey's flags</p>
        </header>
        <section class="modal-card-body">
          <div class="">
            <div v-if="flagData.provider">
              <strong>Provider's name: </strong>{{ flagData.provider.name }}
            </div>
            <div v-if="flagData.journey">
              <strong>Buyer's name: </strong>{{ getBuyerName }}
            </div>
          </div>
          <div :key="flag.id" v-for="flag in flagData.flags" class="flags card">
            <div v-if="flag.resolved == 'OPEN'">
              <hr />
              <div class="card-content has-text-left">
                <div class="">
                  <strong>Date added:</strong>
                  {{ new Date(flag.date_added).toLocaleDateString() }}
                </div>
                <div class="">
                  <strong>Time added:</strong>
                  {{ new Date(flag.date_added).toLocaleTimeString() }}
                </div>
                <div class="">
                  <strong>Reported by:</strong>
                  {{
                    flag.reported_by == "BUYER"
                      ? getBuyerName
                      : flagData.provider.name
                  }}
                </div>
                <div class="">
                  <strong>Reason:</strong> {{ flag.flag_reason.reason }}
                </div>
              </div>
              <div class="buttons is-right">
                <b-button
                  @click="confirm('DISMISS', flag.id)"
                  class="is-primary"
                >
                  Dismiss
                </b-button>
                <b-dropdown aria-role="list">
                  <button class="button is-warning" slot="trigger">
                    <span>Warn &raquo;</span>
                  </button>
                  <b-dropdown-item
                    @click="
                      confirm(
                        'WARN',
                        flag.id,
                        flagData.journey.user.id,
                        flagData.journey.user.authUser._id,
                        'BUYER',
                        flag.flag_reason
                      )
                    "
                    class="userDropdown"
                    aria-role="listitem"
                    >Buyer</b-dropdown-item
                  >
                  <b-dropdown-item
                    @click="
                      confirm(
                        'WARN',
                        flag.id,
                        flagData.provider.id,
                        flagData.provider.creator_auth_id,
                        'VENDOR',
                        flag.flag_reason
                      )
                    "
                    class="userDropdown"
                    aria-role="listitem"
                    >Vendor</b-dropdown-item
                  >
                </b-dropdown>
                <b-dropdown aria-role="list">
                  <button class="button is-danger" slot="trigger">
                    <span>Ban &raquo;</span>
                  </button>

                  <b-dropdown-item
                    @click="
                      confirm(
                        'BAN',
                        flag.id,
                        flagData.journey.user.id,
                        flagData.journey.user.authUser._id,
                        'BUYER',
                        flag.flag_reason
                      )
                    "
                    class="userDropdown"
                    aria-role="listitem"
                    >Buyer</b-dropdown-item
                  >
                  <b-dropdown-item
                    @click="
                      confirm(
                        'BAN',
                        flag.id,
                        flagData.provider.id,
                        flagData.provider.creator_auth_id,
                        'VENDOR',
                        flag.flag_reason
                      )
                    "
                    class="userDropdown"
                    aria-role="listitem"
                    >Vendor</b-dropdown-item
                  >
                </b-dropdown>
              </div>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot">
          <button class="button" type="button" @click="closeModal">
            Close
          </button>
        </footer>
      </div>
    </b-modal>
  </section>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("messages");

import {
  BAN_USER,
  UPDATE_JOURNEY_PROVIDER_FLAG
} from "../../../_graphql/Mutations/mutations";
import _ from "lodash";

export default {
  data() {
    return {
      isActive: false
    };
  },
  props: {
    flagData: {
      default: {}
    }
  },
  computed: {
    getBuyerName() {
      return (
        this.flagData.journey.user.authUser.firstName +
        " " +
        this.flagData.journey.user.authUser.lastName
      );
    }
  },
  methods: {
    ...mapActions(["sendMessage"]),
    openModal() {
      this.isActive = true;
    },
    closeModal() {
      this.isActive = false;
    },
    confirm(action, flagId, userId, userAuthId, userRole, flagReason) {
      this.$buefy.dialog.confirm({
        title: action,
        message: `Are you sure you want to <strong>${action}</strong> this ${
          userRole ? userRole : "flag"
        }?`,
        confirmText: action,
        type:
          action == "DISMISS"
            ? "is-primary"
            : action == "WARN"
            ? "is-warning"
            : "is-danger",
        hasIcon: true,
        onConfirm: () =>
          this.handleFlag(
            action,
            flagId,
            userId,
            userAuthId,
            userRole,
            flagReason
          )
      });
    },
    checkFlags() {
      if (!_.find(this.flagData.flags, ["resolved", "OPEN"])) {
        this.closeModal();
      }
    },
    refreshFlagsData(updatedFlag) {
      return _.map(this.flagData.flags, flag => {
        if (flag.id == updatedFlag.id) {
          flag.resolved = updatedFlag.resolved;
        }
        return flag;
      });
    },
    async handleFlag(status, flagId, userId, userAuthId, userRole, flagReason) {
      const variables = {
        id: parseInt(flagId),
        input: {
          resolved: status
        }
      };
      if (status == "BAN") {
        await this.$apollo
          .mutate({
            mutation: BAN_USER,
            variables: {
              id: userAuthId
            }
          })
          .catch(error => {
            return this.$buefy.toast.open({
              message: `${error}`,
              type: "is-danger",
              duration: 3000
            });
          });
      }
      if (status == "WARN") {
        const readById =
          userRole == "BUYER"
            ? this.flagData.provider.creator_auth_id
            : this.flagData.journey.user.authUser._id;
        this.sendMessage({
          message: "warning",
          journeyProviderId: parseInt(userId),
          conversationId: this.flagData.conversation_id,
          meta: {
            type: "WARNING",
            state: "PENDING",
            forUserId: userAuthId,
            userRole: userRole,
            reason: flagReason
          },
          readBy: [readById]
        });
      }

      this.$apollo
        .mutate({
          mutation: UPDATE_JOURNEY_PROVIDER_FLAG,
          variables: variables
        })
        .catch(error => {
          return this.$buefy.toast.open({
            message: `${error}`,
            type: "is-danger",
            duration: 3000
          });
        })
        .then(data => {
          if (data.data.updateJourneyProviderFlag) {
            this.$emit("updatedFlag");
            this.refreshFlagsData(data.data.updateJourneyProviderFlag);
            this.$buefy.toast.open({
              message:
                status == "DISMISS"
                  ? "Flag was dismissed successfully!"
                  : "Flag was banned successfully!",
              type: "is-success",
              duration: 2500
            });
            this.checkFlags();
          }
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.userDropdown {
  cursor: pointer !important;
}
.modal,
.modal-card {
  min-height: 600px !important;
}
</style>
