var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"columns is-multiline is-mobile",staticStyle:{"background":"none #fefefe","margin":"0"}},[_c('div',{staticClass:"column is-12"},[_c('div',{staticClass:"buttons"},[_c('b-upload',{staticClass:"file-upload-btn",attrs:{"disabled":!_vm.isSubscribed ||
            _vm.offerMakerIsOpen ||
            _vm.appointmentMakerIsOpen ||
            _vm.dropFiles != null},on:{"input":_vm.onFileUpload},model:{value:(_vm.dropFiles),callback:function ($$v) {_vm.dropFiles=$$v},expression:"dropFiles"}},[_c('a',{staticClass:"button",attrs:{"disabled":_vm.offerMakerIsOpen || _vm.appointmentMakerIsOpen || _vm.dropFiles != null},on:{"click":function($event){!_vm.isSubscribed && _vm.showUpgradePlanMessage(_vm.shareFileSubscriptionMsg)}}},[_c('img',{attrs:{"src":require("@/assets/img/file_upload_icon.svg")}})])]),(_vm.payoutIsNotCompleted)?_c('b-tooltip',{staticClass:"tooltip",attrs:{"animated":"","multilined":"","label":_vm.tooltips.payout,"position":"is-top","size":"is-small"}},[(_vm.isPaymentActive && _vm.showPaymentsButton)?_c('b-button',{staticClass:"action-btn",attrs:{"disabled":_vm.payoutIsNotCompleted || _vm.offerMakerIsOpen || _vm.uploadFileIsOpen},on:{"click":_vm.openPaymentRequest}},[_vm._v(" Request payment ")]):_vm._e()],1):_vm._e(),(_vm.isPaymentActive && _vm.showPaymentsButton && !_vm.payoutIsNotCompleted)?_c('b-button',{staticClass:"action-btn",attrs:{"disabled":_vm.payoutIsNotCompleted || _vm.offerMakerIsOpen || _vm.uploadFileIsOpen},on:{"click":_vm.openPaymentRequest}},[_vm._v(" Request payment ")]):_vm._e(),(!_vm.offerMakerIsOpen && _vm.messagesFilter !== '!OPEN')?_c('b-button',{staticClass:"action-btn",attrs:{"disabled":_vm.appointmentMakerIsOpen || _vm.uploadFileIsOpen},on:{"click":_vm.makeOffer}},[_vm._v("Make an offer")]):_vm._e(),(_vm.offerMakerIsOpen)?_c('b-button',{staticClass:"action-btn",attrs:{"disabled":_vm.appointmentMakerIsOpen || _vm.uploadFileIsOpen},on:{"click":function () {
            _vm.offerMakerIsOpen = !_vm.offerMakerIsOpen;
            _vm.appointmentMakerIsOpen = false;
          }}},[_vm._v("Cancel")]):_vm._e(),(!_vm.appointmentMakerIsOpen && _vm.messagesFilter !== '!OPEN')?_c('b-button',{staticClass:"action-btn",attrs:{"disabled":_vm.offerMakerIsOpen || _vm.uploadFileIsOpen},on:{"click":_vm.makeAppointment}},[_vm._v("Make an appointment")]):_vm._e(),(_vm.appointmentMakerIsOpen)?_c('b-button',{staticClass:"action-btn",on:{"click":function () {
            _vm.appointmentMakerIsOpen = !_vm.appointmentMakerIsOpen;
            _vm.offerMakerIsOpen = false;
            _vm.showAppointmentSendBtn = false;
          }}},[_vm._v("Cancel")]):_vm._e(),(_vm.showVideoButton)?_c('b-button',{staticClass:" action-btn",class:{ 'has-text-danger': _vm.totalSeconds <= 1800 },attrs:{"disabled":_vm.disableVideoBtn || _vm.totalSeconds == 0},on:{"click":function($event){_vm.isSubscribed
            ? _vm.handleVideoCall()
            : _vm.showUpgradePlanMessage(_vm.videoCallsSubscriptionMsg)}}},[(
            !(_vm.videoCallPermission && _vm.videoCallPermission.allowed) && !_vm.isMobile
          )?_c('b-tooltip',{staticClass:"tooltip",attrs:{"animated":"","multilined":"","label":_vm.tooltips.video,"position":"is-top","size":"is-small"}},[_c('b-icon',{attrs:{"icon":"video","type":'is-primary'}})],1):_vm._e(),(_vm.isMobile)?_c('b-icon',{attrs:{"icon":"video","type":'is-primary'}}):_vm._e(),(_vm.videoCallPermission && _vm.videoCallPermission.allowed)?_c('b-icon',{attrs:{"icon":"video","type":'is-success'}}):_vm._e(),_c('span',{attrs:{"id":"minutes"}},[_vm._v(" "+_vm._s(_vm.totalProviderMinutes)+" minutes")])],1):_vm._e(),(
          _vm.journeyProvider.provider &&
            _vm.journeyProvider.provider.is_subscribed &&
            _vm.showVideoButton
        )?_c('b-button',{staticClass:"button is-primary",on:{"click":_vm.openBuyMinutesModal}},[_vm._v("Buy more minutes")]):_vm._e()],1)]),_c('div',{staticClass:"columns is-multiline chatArea"},[_c('div',{staticClass:"column is-12"},[(_vm.isUpdated)?_c('span',{staticClass:"edit-message is-pulled-left"},[_vm._v("[edit message]")]):_vm._e(),(!_vm.dropFiles)?_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.answer),expression:"answer"}],staticClass:"chatTextarea",attrs:{"placeholder":_vm.messagesFilter == '!OPEN'
            ? 'This chat has been archived.'
            : 'Write your chat here...',"disabled":_vm.offerMakerIsOpen ||
            _vm.appointmentMakerIsOpen ||
            _vm.messagesFilter == '!OPEN'},domProps:{"value":(_vm.answer)},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey){ return null; }$event.preventDefault();},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }if(!$event.shiftKey){ return null; }if($event.ctrlKey||$event.altKey||$event.metaKey){ return null; }return _vm.newline.apply(null, arguments)}],"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey){ return null; }return _vm.handleSendMessage.apply(null, arguments)},"input":function($event){if($event.target.composing){ return; }_vm.answer=$event.target.value}}}):_vm._e(),(_vm.dropFiles)?_c('b-taglist',{staticClass:"taglist"},[_c('b-tag',{attrs:{"type":"is-light","size":"is-medium","closable":""},on:{"close":_vm.deleteDropFile}},[_vm._v(_vm._s(_vm.dropFiles.name)+" ")])],1):_vm._e()],1),_c('div',{staticClass:"column is-12 send"},[(!_vm.isUpdated)?_c('button',{staticClass:"button is-primary",attrs:{"disabled":_vm.offerMakerIsOpen ||
            _vm.appointmentMakerIsOpen ||
            _vm.messagesFilter == '!OPEN' ||
            _vm.uploadingFile},on:{"click":function($event){return _vm.handleSendMessage()}}},[_vm._v(" Send ")]):_vm._e(),(_vm.isUpdated)?_c('button',{staticClass:"button is-primary",attrs:{"disabled":this.answer.length === 0},on:{"click":_vm.handleUpdateMessage}},[_vm._v(" Update ")]):_vm._e()])]),_c('div',{staticClass:"column is-full-mobile is-half-tablet is-12-desktop"},[_c('offer-maker',{attrs:{"appointment-maker-is-open":_vm.appointmentMakerIsOpen,"offer-maker-is-open":_vm.offerMakerIsOpen,"provider":_vm.journeyProvider.provider,"journey-provider-id":parseInt(this.journeyProvider.id)},on:{"startedConversation":_vm.startConversation,"offer":_vm.handleSendMessage,"close":function($event){_vm.offerMakerIsOpen = !_vm.offerMakerIsOpen}}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.appointmentMakerIsOpen),expression:"appointmentMakerIsOpen"}],ref:"appointment",staticClass:"appointment-maker",attrs:{"id":"appointment-maker"}},[_c('br'),_c('br'),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-4 is-pulled-right has-text-left"},[_c('div',{staticClass:"subtitle"},[_vm._v(" Choose date and time: ")]),_c('datetime',{staticClass:"theme-metime",attrs:{"type":"datetime","input-class":"input appointment-calendar is-large","value-zone":"Europe/London","zone":"Europe/London","format":{
              year: 'numeric',
              month: 'long',
              day: 'numeric',
              hour: 'numeric',
              minute: '2-digit',
              timeZoneName: 'short'
            },"hidden-name":"Choose date and time","phrases":{ ok: 'OK', cancel: 'Cancel' },"hour-step":1,"minute-step":5,"min-datetime":_vm.minDatetime,"week-start":7,"use24-hour":"","auto":""},model:{value:(_vm.appointmentDate),callback:function ($$v) {_vm.appointmentDate=$$v},expression:"appointmentDate"}})],1),_c('div',{staticClass:"column is-6 is-pulled-left has-text-left"},[_c('br'),_c('br'),(_vm.showAppointmentSendBtn)?_c('span',[_c('button',{staticClass:"button is-primary is-large",on:{"click":_vm.handleSendAppointment}},[_vm._v(" Send » ")])]):_vm._e()])])])],1),_c('requestPayment',{ref:"paymentRequest",attrs:{"isSubscribed":_vm.isSubscribed},on:{"requestedPayment":_vm.handleSendPaymentRequest}}),(_vm.journeyProvider && _vm.journeyProvider.provider)?_c('buy-minutes-modal',{ref:"buyMinutesModal",attrs:{"provider-id":_vm.journeyProvider.provider.id,"free-seconds":_vm.videocall_free_seconds,"paid-seconds":_vm.videocall_paid_seconds,"stripe":_vm.stripe}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }