import gql from "graphql-tag";

const FLAG_REASONS = gql`
  query flagReasons($input: QueryParams) {
    flagReasons(input: $input) {
      code
      errors
      data {
        id
        reason
      }
    }
  }
`;

const ME = gql`
  query me {
    me {
      user {
        _id
        username
        email
        firstName
        lastName
        avatar
        role
        country
        city
      }
      provider
    }
  }
`;

const ICON_SETS = gql`
  query iconSets($input: QueryParams) {
    iconSets(input: $input) {
      data {
        id
        name
      }
    }
  }
`;

const ALL_AREAS = gql`
  query areas($input: QueryParams) {
    areas(input: $input) {
      data {
        name
        id
        specialties
        area_types {
          name
          id
          icon_set_id
          specialties
          categories {
            name
            id
            specialties
          }
        }
        icon_set_id
        icon_set {
          id
          name
        }
      }
    }
  }
`;

const AREA = gql`
  query area($id: ID) {
    area(id: $id) {
      id
      name
    }
  }
`;

const TREATMENTS = gql`
  query treatments($input: QueryParams) {
    treatments(input: $input) {
      data {
        id
        name
        description
      }
    }
  }
`;

const ALL_AREA_TYPES = gql`
  query area($id: ID) {
    area(id: $id) {
      id
      name
      area_types {
        id
        name
        icon_set_id
        icon_set {
          id
          name
        }
      }
    }
  }
`;
const AREA_TYPE_NAME = gql`
  query areaType($id: ID) {
    areaType(id: $id) {
      name
      id
    }
  }
`;
const AREA_CATEGORIES_TREATMENTS = gql`
  query areaType($id: ID) {
    areaType(id: $id) {
      id
      name
      categories {
        id
        name
        icon_set_id

        treatments {
          id
          name
          description
        }
      }
      treatments {
        id
        name
        description
      }
    }
  }
`;

const AREA_TYPE_CATEGORY_TREATMENTS = gql`
  query areaTypeCategoryTreatments(
    $input: QueryParams
    $areaId: ID
    $areaTypeId: ID
    $areaTypeCatId: ID
  ) {
    areaTypeCategoryTreatments(input: $input) {
      data {
        id
        name
        description
      }
    }
    area(id: $areaId) {
      name
      id
    }
    areaType(id: $areaTypeId) {
      name
      id
    }
    areaTypeCategory(id: $areaTypeCatId) {
      id
      name
    }
  }
`;

const AREA_TYPE_TREATMENTS = gql`
  query areaTypeCategoryTreatments(
    $input: QueryParams
    $areaId: ID
    $areaTypeId: ID
  ) {
    areaTypeCategoryTreatments(input: $input) {
      data {
        id
        name
        description
      }
    }
    area(id: $areaId) {
      name
      id
    }
    areaType(id: $areaTypeId) {
      name
      id
    }
  }
`;

const AREA_TYPE_CATEGORY_TREATMENT = gql`
  query areaTypeCategoryTreatment($id: ID) {
    areaTypeCategoryTreatment(id: $id) {
      id
      name
      description
    }
  }
`;

const AREA_NAME = gql`
  query area($id: ID) {
    area(id: $id) {
      name
      id
    }
  }
`;

const AREA_NAME_AND_AREA_CATEGORIES_TREATMENTS = gql`
  query area($areaId: ID, $areaTypeId: ID) {
    area(id: $areaId) {
      name
      id
    }
    areaType(id: $areaTypeId) {
      name
      id
      categories {
        id
        name
        icon_set_id
        icon_set {
          id
          name
        }
      }
      treatments {
        id
        name
        description
      }
    }
  }
`;

const ACTIVE_COUNTRIES = gql`
  query activeCountries {
    activeCountries {
      data {
        id
        name
      }
    }
  }
`;

const CITIES_BY_COUNTRY_ID = gql`
  query citiesByCountryId($country_id: Int!) {
    citiesByCountryId(country_id: $country_id) {
      data {
        id
        name
      }
    }
  }
`;

const SPECIALITIES = gql`
  query specialties($input: QueryParams) {
    specialties(input: $input) {
      data {
        id
        name
      }
    }
  }
`;

const PROVIDERS = gql`
  query providers($input: ProviderQueryParams) {
    providers(input: $input) {
      meta {
        total_pages
        current_page
        next_page
        per_page
        total_count
      }
      data {
        id
        name
        is_subscribed
        subscription_plan
        date_added
        guest_status
        is_guest
        is_public
        phone
        user {
          id
          first_name
          last_name
          role
          gender
          auth_id
        }

        address {
          address
          locale {
            country {
              name
              id
            }
            city {
              id
              name
            }
          }
        }
        avatar_url
      }
    }
  }
`;

const PROFILE_URLS = gql`
  query providers($input: ProviderQueryParams) {
    providers(input: $input) {
      data {
        id
        name
        profile_url
      }
    }
  }
`;

const MY_PROVIDERS = gql`
  query myProviders($input: QueryParams) {
    myProviders(input: $input) {
      meta {
        total_pages
        current_page
        next_page
        per_page
        total_count
      }
      data {
        id
        name
        phone
        date_added
        user {
          id
          first_name
          last_name
          role
          gender
        }
        is_public
        is_guest
        guest_status
        is_subscribed
        subscription_plan
        address {
          address
          locale {
            country {
              name
            }
            city {
              name
            }
          }
        }
        avatar_url
      }
    }
  }
`;

const PROVIDER = gql`
  query provider($id: ID!) {
    provider(id: $id) {
      id
      uuid
      date_added
      address {
        address
        locale {
          country {
            id
            name
          }
          city {
            id
            name
          }
        }
        zip
      }
      status
      profile_url
      email
      transfer_uuid
      phone
      is_subscribed
      subscription_plan
      is_matchable
      name
      cover_image_url
      avatar_url
      description
      experience
      clinic
      is_visible_rating
      rating
      photos {
        id
        width
        height
        url
        thumb_url
        position
      }
      user {
        id
        first_name
        last_name
        role
        gender
        auth_id
      }
      is_guest
      guest_status
      is_public
      note
      videos {
        id
        url
        image_url
        type
        position
      }
      main_practitioners {
        id
        name
        specialty
        avatar_url
        description
        degree
      }
      treatments {
        tId: id
        name
        description
      }
      specialties {
        id
        name
        description
      }
      technologies {
        id
        technology_id
        name
        provider_id
        technology_categories_id
      }
      settings {
        id
        allow_payments
        allow_videocalls
        new_message_notify
        new_match_notify
        new_direct_match_notify
      }
      educations {
        id
        name
      }
      trainings {
        id
        name
      }
      awards {
        id
        name
      }
      insurances {
        id
        name
      }
      privileges {
        id
        name
      }
      socials {
        id
        name
        type
      }
      products {
        id
        product_id
        name
        provider_id
        product_categories_id
      }
      procedures {
        id
        name
        procedure_area_id
      }
      procedures_areas_positions {
        position
        procedure_area_id
        procedure_area_name
        procedure_area_icon_url
      }
      videocall_free_seconds
      videocall_paid_seconds
    }
  }
`;

const USERS = gql`
  query allUsers($input: UserQueryParams) {
    allUsers(input: $input) {
      code
      success
      meta {
        total_pages
        current_page
        next_page
        per_page
        total_count
      }
      data {
        _id
        dateCreated
        role
        country
        city
        email
        firstName
        lastName
      }
    }
  }
`;

const USER = gql`
  query allUsers($input: UserQueryParams) {
    allUsers(input: $input) {
      code
      success
      data {
        _id
        firstName
        lastName
        email
        country
        city
        avatar
        role
        age
        gender
      }
    }
  }
`;

const ADMIN_STATISTICS = gql`
  query administratorStats {
    administratorStats {
      data {
        total_providers_per_country {
          id
          name
          value
        }
        total_providers {
          value
        }
        total_providers_per_month {
          name
          value
        }
        total_conversations_per_month {
          name
          active
          inactive
        }
        total_accept_offers_current_month {
          value
        }
        total_providers_current_month {
          value
        }
        total_accept_offers_per_month {
          name
          value
        }
        total_accept_offers {
          value
        }
        total_conversations {
          active
          inactive
        }
        total_leads_per_month {
          name
          value
        }
        total_leads {
          value
        }
      }
    }
  }
`;

const VENDOR_STATISTICS = gql`
  query vendorStats {
    vendorStats {
      data {
        total_conversations_per_month {
          name
          active
          inactive
        }
        total_conversations {
          active
          inactive
        }
        total_accept_offers {
          value
        }
        total_accept_offers_per_month {
          name
          value
        }
        total_leads_per_month {
          name
          value
        }
        total_leads {
          value
        }
      }
    }
  }
`;

const ARTICLES = gql`
  query articles {
    articles {
      id
      name
      created_at
      updated_at
      slug
      title
      content
      blog_type
      description
      img {
        id
        name
        hash
      }
    }
  }
`;

const ARTICLES_RAW = gql`
  query articlesRaw {
    articlesRaw {
      id
      name
      created_at
      updated_at
      slug
      title
      content
      description
      blog_type
      draft
      img {
        id
        name
        hash
      }
    }
  }
`;

const ARTICLE = gql`
  query article($id: ID!) {
    article(id: $id) {
      id
      name
      created_at
      updated_at
      slug
      title
      meta_keywords
      meta_description
      meta_title
      content
      description
      blog_type
      draft
      reading_time
      img {
        id
        name
        url
        hash
        ext
        mime
        hash
      }
    }
  }
`;

const PAGES = gql`
  query pages {
    pages {
      id
      title
      content
      slug
      meta_title
      meta_description
      meta_keywords
      status
      url
      created_at
      updated_at
      cms_keywords
      content_json
      name
    }
  }
`;

const PAGE = gql`
  query page($id: ID) {
    page(id: $id) {
      id
      title
      content
      slug
      meta_title
      meta_description
      meta_keywords
      status
      url
      created_at
      updated_at
      cms_keywords
      content_json
      name
    }
  }
`;

const COMPONENTS = gql`
  query components {
    components {
      id
      content
      page
      slug
      created_at
      updated_at
      title
      cms_keywords
      content_json
    }
  }
`;

const COMPONENT = gql`
  query component($id: ID) {
    component(id: $id) {
      id
      content
      page
      slug
      created_at
      updated_at
      title
      cms_keywords
      content_json
    }
  }
`;

const EXPORT_USERS_FILE = gql`
  query exportUsersFile($input: UserExportParams) {
    exportUsersFile(input: $input) {
      url
    }
  }
`;

const JOURNEY_PROVIDER_BY_FLAGS = gql`
  query journeyProviderByFlags($input: QueryParams) {
    journeyProviderByFlags(input: $input) {
      data {
        id
        conversation_id
        provider {
          id
          name
          creator_auth_id
        }
        journey {
          id
          name
          date_added
          user {
            id
            authUser {
              firstName
              lastName
              _id
            }
          }
        }
        flags {
          id
          journey_provider_id
          date_added
          flag_reason {
            id
            reason
          }
          reported_by
          resolved
        }
      }
    }
  }
`;

const GENERATE_PROVIDER_EXTERNAL_URL = gql`
  query generateProviderExternalUrl($id: ID!, $img_url: String!) {
    generateProviderExternalUrl(id: $id, img_url: $img_url) {
      shortLink
      previewLink
    }
  }
`;

const SYSTEM_SETTING = gql`
  query systemSetting($id: ID!) {
    systemSetting(id: $id) {
      id
      key_name
      value
      type
      comment
    }
  }
`;

const SYSTEM_SETTINGS = gql`
  query systemSettings($input: QueryParams) {
    systemSettings(input: $input) {
      data {
        id
        key_name
        value
        type
        comment
        category
        title
        description
        min
        max
      }
    }
  }
`;

const VIDEO_CALL_PERMISSION = gql`
  query videoCallPermission($buyer_id: ID!, $provider_id: ID!) {
    videoCallPermission(buyer_id: $buyer_id, provider_id: $provider_id) {
      allowed
    }
  }
`;

const PAYMENTS = gql`
  query payments($input: PaymentQueryParams) {
    payments(input: $input) {
      meta {
        total_pages
        current_page
        next_page
        per_page
        total_count
      }
      data {
        id
        payin_id
        provider_name
        buyer_email
        buyer_first_name
        buyer_last_name
        description
        currency
        amount
        fee_percentage
        fee_amount
        status
        payout_status
        date_added
      }
    }
  }
`;

const PAYMENT_PRODUCTS = gql`
  query paymentProducts($input: PaymentQueryParams) {
    paymentProducts(input: $input) {
      data {
        id
        name
        product_items {
          id
          name
          units
          price
        }
      }
    }
  }
`;

const EXPORT_PAYMENTS_FILE = gql`
  query exportPaymentsFile($input: PaymentsExportParams) {
    exportPaymentsFile(input: $input) {
      data {
        url
      }
    }
  }
`;

const EXPORT_PROVIDERS = gql`
  query exportProviders($input: ProviderQueryParams) {
    exportProviders(input: $input)
  }
`;

const PRODUCTS = gql`
  query products($input: ProductQueryParams) {
    products(input: $input) {
      data {
        id
        name
        provider_id
        product_categories_id
      }
    }
  }
`;

const PRODUCT_CATEGORIES = gql`
  query productCategories($input: QueryParams) {
    productCategories(input: $input) {
      data {
        id
        name
      }
    }
  }
`;

const TECHNOLOGIES = gql`
  query technologies($input: TechnologyQueryParams) {
    technologies(input: $input) {
      data {
        id
        name
        provider_id
        technology_categories_id
      }
    }
  }
`;

const TECHNOLOGY_CATEGORIES = gql`
  query technologyCategories($input: QueryParams) {
    technologyCategories(input: $input) {
      data {
        id
        name
      }
    }
  }
`;

const PROCEDURES_AREAS = gql`
  query proceduresAreas($input: QueryParams) {
    proceduresAreas(input: $input) {
      data {
        id
        name
        icon_url
      }
    }
  }
`;

const PROVIDER_SUBSCRIPTIONS = gql`
  query providerSubscriptions($input: QueryParams) {
    providerSubscriptions(input: $input) {
      data {
        id
        sub_plan_id
        provider_id
        free_trial
        sub_product_name
        interval
        session_id
        is_subscribed
        is_trialing
        is_transferred
      }
    }
  }
`;

const GET_CUSTOMER_PORTAL_URL = gql`
  query getCustomerPortalUrl($input: BillingCustomerPortalParams) {
    getCustomerPortalUrl(input: $input) {
      data {
        customer_portal_url
      }
    }
  }
`;

const SUBSCRIPTION_PLANS = gql`
  query subscriptionPlans($input: QueryParams) {
    subscriptionPlans(input: $input) {
      data {
        id
        sub_product_id
        sub_product_name
        interval
      }
    }
  }
`;

const PROVIDER_PAYMENT = gql`
  query providerPayment($session_id: String!) {
    providerPayment(session_id: $session_id) {
      id
      provider_id
      payment_product_item {
        id
        name
        units
        price
      }
      session_id
    }
  }
`;

// Solutions queries

const SOLUTIONS_CATEGORIES = gql`
  query solutionsCategories($input: QueryParams) {
    solutionsCategories(input: $input) {
      data {
        id
        name
        slug
        subcategories {
          id
          name
          slug
        }
      }
    }
  }
`;

const SOLUTIONS_KEYWORDS = gql`
  query solutionsKeywords {
    solutionsKeywords {
      id
      name
      slug
    }
  }
`;

const SOLUTIONS = gql`
  query solutions($input: ProductQueryParams) {
    solutions(input: $input) {
      meta {
        total_pages
        current_page
        per_page
        total_count
      }
      data {
        id
        title
        slug
        categories {
          category {
            id
            name
            slug
          }
          subcategory {
            id
            name
            slug
          }
        }
        user {
          auth_id
          first_name
          last_name
        }
        date_added
        published_date
        status
        is_published
      }
    }
  }
`;

const SOLUTION_PROVIDERS = gql`
  query solutionProviders(
    $input: QueryParams
    $criteria: SolutionProvidersInput!
  ) {
    solutionProviders(input: $input, criteria: $criteria) {
      meta {
        total_count
        per_page
        current_page
      }
      data {
        id
        name
        avatar_url
        address {
          locale {
            country {
              name
            }
            city {
              name
            }
          }
        }
      }
    }
  }
`;

const SOLUTION = gql`
  query solution($id: ID!) {
    solution(id: $id) {
      id
      title
      cover_url
      cover_thumb_url
      meta_title
      slug
      description
      meta_description
      note
      content
      read_time
      user {
        auth_id
        first_name
        last_name
      }
      categories {
        category {
          id
          name
          slug
        }
        subcategory {
          id
          name
          slug
        }
      }
      keywords {
        id
        name
        slug
      }
      treatments {
        id
      }
      technologies {
        id
      }
      products {
        id
      }
      specialties {
        id
      }
      related_solutions {
        id
        title
        slug
      }
      status
      published_date
      is_published
    }
  }
`;

// Stripe connect queries

const PROVIDER_STRIPE_CONNECT = gql`
  query providerStripeConnect($provider_id: ID!) {
    providerStripeConnect(provider_id: $provider_id) {
      id
      provider_id
      connect_id
      payouts_enabled
      details_submitted
    }
  }
`;

const CURRENT_PAYMENT_STATUS = gql`
  query currentPaymentStatus($input: QueryParams) {
    currentPaymentStatus(input: $input) {
      data {
        id
        provider_id
        connect_id
        payouts_enabled
        details_submitted
      }
    }
  }
`;

const GET_SRTIPE_CONNECT_URL = gql`
  query getStripeConnectUrl($input: BillingStripeConnetParams) {
    getStripeConnectUrl(input: $input) {
      data {
        stripe_connect_url
      }
    }
  }
`;

export {
  FLAG_REASONS,
  ME,
  ICON_SETS,
  ALL_AREAS,
  AREA,
  TREATMENTS,
  AREA_TYPE_NAME,
  AREA_NAME,
  ALL_AREA_TYPES,
  AREA_CATEGORIES_TREATMENTS,
  AREA_TYPE_CATEGORY_TREATMENT,
  AREA_TYPE_CATEGORY_TREATMENTS,
  AREA_NAME_AND_AREA_CATEGORIES_TREATMENTS,
  AREA_TYPE_TREATMENTS,
  ACTIVE_COUNTRIES,
  CITIES_BY_COUNTRY_ID,
  SPECIALITIES,
  PROVIDERS,
  MY_PROVIDERS,
  PROVIDER,
  USERS,
  USER,
  ADMIN_STATISTICS,
  VENDOR_STATISTICS,
  ARTICLES,
  ARTICLE,
  ARTICLES_RAW,
  PAGES,
  PAGE,
  COMPONENTS,
  COMPONENT,
  EXPORT_USERS_FILE,
  EXPORT_PROVIDERS,
  JOURNEY_PROVIDER_BY_FLAGS,
  GENERATE_PROVIDER_EXTERNAL_URL,
  SYSTEM_SETTING,
  SYSTEM_SETTINGS,
  VIDEO_CALL_PERMISSION,
  PAYMENTS,
  PAYMENT_PRODUCTS,
  EXPORT_PAYMENTS_FILE,
  PRODUCTS,
  PRODUCT_CATEGORIES,
  TECHNOLOGIES,
  TECHNOLOGY_CATEGORIES,
  PROCEDURES_AREAS,
  PROFILE_URLS,
  PROVIDER_SUBSCRIPTIONS,
  GET_CUSTOMER_PORTAL_URL,
  SUBSCRIPTION_PLANS,
  PROVIDER_PAYMENT,
  SOLUTIONS_CATEGORIES,
  SOLUTIONS_KEYWORDS,
  SOLUTIONS,
  SOLUTION,
  SOLUTION_PROVIDERS,
  PROVIDER_STRIPE_CONNECT,
  CURRENT_PAYMENT_STATUS,
  GET_SRTIPE_CONNECT_URL
};
