<template>
  <section class="section">
    <div class="is-title-bar">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <h1 class="title">Users</h1>
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <b-button @click="exportUsers" class="is-primary">Export</b-button>
          </div>
          <div class="level-item">
            <router-link to="/users/create">
              <b-button class="is-primary">Create User</b-button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="level ">
      <div class="level-left">
        <div class="level-item">
          <DropdownFilter
            class="custom-dropdown"
            title="Filters"
            :items="filterDropdownItems"
            :filters="filters"
            :inputFilters="inputFilters"
            @toggleFilter="toggleFilter"
          />
        </div>
      </div>
      <div class="level-right">
        <div class="level-item custom-level-item">
          <div class="field has-addons">
            <b-datepicker
              @input="onDateRange"
              id="dateRange"
              class="datepicker"
              icon="calendar-today"
              :first-day-of-week="1"
              placeholder="Click to select dates"
              v-model="dates"
              range
            >
            </b-datepicker>
            <div class="control">
              <button @click="clearField" class="button is-primary">
                <span class="icon is-small">
                  <i class="fas fa-times"></i>
                </span>
              </button>
            </div>
          </div>
          <p class="help">Select date range</p>
        </div>
        <div class="level-item">
          <b-dropdown
            hoverable
            right
            aria-role="list"
            :close-on-click="false"
            class="custom-dropdown _right"
          >
            <button class="button is-primary is-outlined" slot="trigger">
              <span>Sort By</span>
              <b-icon icon="menu-down"></b-icon>
            </button>
            <b-dropdown-item
              v-for="item in sortDropdownItems"
              :key="item.name"
              aria-role="listitem"
              :focusable="false"
            >
              <b-collapse
                animation="slide"
                :open="openSortParam == item.name"
                @open="openSortParam = item.name"
              >
                <div slot="trigger" class="item-title" role="button">
                  <span
                    :class="
                      activeSortOptions[item.name] != '' ? 'activeFilter' : ''
                    "
                  >
                    {{ item.title }}
                    <span v-if="activeSortOptions[item.name] != ''">
                      #{{
                        sortOptions.findIndex(e => e.option == item.name) + 1
                      }}
                    </span>
                  </span>
                </div>
                <div class="filterOptions">
                  <span
                    class="filterOption"
                    :class="
                      activeSortOptions[item.name] == 'asc'
                        ? 'activeFilter'
                        : ''
                    "
                    @click="toggleSortOption(item.name, 'asc')"
                  >
                    Ascending
                  </span>
                  <span
                    class="filterOption"
                    :class="
                      activeSortOptions[item.name] == 'desc'
                        ? 'activeFilter'
                        : ''
                    "
                    @click="toggleSortOption(item.name, 'desc')"
                  >
                    Descending
                  </span>
                </div>
              </b-collapse>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
    </div>
    <div>
      <b-table
        v-if="allUsers"
        class="table is-fullwidth"
        :data="allUsers.data"
        :loading="loading"
        paginated
        backend-pagination
        :total="allUsers.meta.total_count"
        :per-page="allUsers.meta.per_page"
        @page-change="onPageChange"
      >
        <template slot-scope="data">
          <b-table-column field="dateCreated" label="Registered">
            {{ new Date(data.row.dateCreated).toLocaleDateString() }}
          </b-table-column>
          <b-table-column field="role" label="Role">
            {{ data.row.role }}
          </b-table-column>
          <b-table-column field="location" label="Location">
            <span>
              {{ data.row.city ? data.row.city : "N/A" }},
              {{ data.row.country ? data.row.country : "N/A" }}
            </span>
          </b-table-column>
          <b-table-column field="email" label="Email">
            {{ data.row.email ? data.row.email : "N/A" }}
          </b-table-column>
          <b-table-column>
            <div class="buttons is-right">
              <router-link
                :to="{
                  name: 'editUser',
                  params: { id: data.row._id, data: data.row }
                }"
              >
                <b-button class="is-primary is-outlined">Edit</b-button>
              </router-link>
              <b-button
                class="is-danger is-outlined"
                @click="openDeleteDialog(data.row)"
                >Delete</b-button
              >
            </div>
          </b-table-column>
        </template>
        <template slot="empty">
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <p>
                <b-icon icon="emoticon-sad" size="is-large"></b-icon>
              </p>
              <p>No users in database.</p>
            </div>
          </section>
        </template>
      </b-table>
    </div>
  </section>
</template>

<script>
import DropdownFilter from "../../base/components/DropdownFilter";
import { USERS, EXPORT_USERS_FILE } from "../../../_graphql/Queries/queries";
import { DELETE_USER } from "../../../_graphql/Mutations/mutations";
import LoadingState from "../../../mixins/LoadingState";
import moment from "moment";

export default {
  mixins: [LoadingState],
  components: { DropdownFilter },
  data() {
    return {
      exportLink: "",
      filterDropdownItems: [
        {
          name: "role",
          title: "Role",
          type: "options",
          options: [
            {
              value: "buyer",
              title: "Buyer"
            },
            {
              value: "vendor",
              title: "Vendor"
            },
            {
              value: "operator",
              title: "Operator"
            },
            {
              value: "moderator",
              title: "Moderator"
            },
            {
              value: "administrator",
              title: "Administrator"
            }


          ]
        },
        {
          name: "gender",
          title: "Gender",
          type: "options",
          options: [
            {
              value: "male",
              title: "Male"
            },
            {
              value: "female",
              title: "Female"
            },
            {
              value: "none",
              title: "None"
            }
          ]
        },
        {
          name: "country",
          title: "Country",
          type: "input"
        },
        {
          name: "city",
          title: "City",
          type: "input"
        },
        {
          name: "firstName",
          title: "First Name",
          type: "input"
        },
        {
          name: "lastName",
          title: "Last Name",
          type: "input"
        },
        {
          name: "email",
          title: "E-Mail",
          type: "input"
        },
        {
          name: "banned",
          title: "Ban status",
          type: "options",
          options: [
            {
              value: "true",
              title: "Banned"
            },
            {
              value: "false",
              title: "Not Banned"
            }
          ]
        }
      ],
      sortDropdownItems: [
        {
          name: "dateCreated",
          title: "Date of Registration"
        },
        {
          name: "dateLastLoggedIn",
          title: "Date of Last Login"
        },
        {
          name: "gender",
          title: "Gender"
        },
        {
          name: "role",
          title: "Role"
        },
        {
          name: "country",
          title: "Country"
        },
        {
          name: "city",
          title: "City"
        }
      ],
      inputFilters: {
        country: "",
        city: "",
        firstName: "",
        lastName: "",
        email: ""
      },
      filters: {
        role: "",
        gender: "",
        country: "",
        city: "",
        firstName: "",
        lastName: "",
        email: "",
        banned: ""
      },
      dates: [],
      openSortParam: "",
      sortOptions: [{ option: "dateCreated", value: "desc" }],
      activeSortOptions: {
        dateCreated: "desc",
        dateLastLoggedIn: "",
        gender: "",
        role: "",
        country: "",
        city: ""
      },
      queryParams: {
        limit: 100,
        offset: 0,
        filter: [],
        order: ["dateCreated:desc"],
        range: {
          start_date: "",
          end_date: ""
        }
      },
      prevIcon: "chevron-left",
      nextIcon: "chevron-right"
    };
  },
  computed: {
    getQueryParams() {
      return {
        limit: this.queryParams.limit,
        offset: this.queryParams.offset,
        filter: this.queryParams.filter.join(",").concat(",deleted:false"),
        order: this.queryParams.order.join(","),
        range: this.queryParams.range
      };
    }
  },
  mounted() {},
  watch: {
    filters: {
      handler(newVal) {
        let newFilter = [];
        for (const filterName in newVal) {
          if (newVal[filterName] != "") {
            newFilter.push(`${filterName}:${newVal[filterName]}`);
          }
        }
        this.queryParams.filter = newFilter;
      },
      deep: true
    },
    sortOptions: {
      handler(newVal) {
        this.queryParams.order = newVal.map(sortOption => {
          return `${sortOption.option}:${sortOption.value}`;
        });
      },
      deep: true
    }
  },
  methods: {
    onPageChange(page) {
      this.queryParams.offset = this.allUsers.meta.per_page * (page - 1);
      this.$apollo.queries.allUsers.refetch();
    },
    toggleFilter(payload) {
      const filterName = payload.filterName;
      const value = payload.optionValue;
      this.filters[filterName] =
        this.filters[filterName] != ""
          ? this.filters[filterName] == value
            ? ""
            : value
          : value;
    },
    toggleSortOption(sortParam, value) {
      const sortOption = this.sortOptions.find(e => e.option == sortParam);
      if (sortOption) {
        if (sortOption.value == value) {
          const removeIndex = this.sortOptions.findIndex(
            e => e.option == sortParam
          );
          this.sortOptions.splice(removeIndex, 1);
          this.activeSortOptions[sortParam] = "";
        } else {
          sortOption.value = value;
          this.activeSortOptions[sortParam] = value;
        }
      } else {
        this.sortOptions.push({ option: sortParam, value: value });
        this.activeSortOptions[sortParam] = value;
      }
    },
    async exportUsers() {
      if (this.allUsers.data.length == 0) {
        return this.$buefy.toast.open({
          message: "There is no data in the table",
          type: "is-danger",
          duration: 3000
        });
      }
      await this.$apollo
        .query({
          query: EXPORT_USERS_FILE,
          variables: {
            input: {
              range: this.getQueryParams.range,
              filter: this.getQueryParams.filter
            }
          },
          update: data => data
        })
        .catch(error => {
          return this.$buefy.toast.open({
            message: `${error}`,
            type: "is-danger"
          });
        })
        .then(({ data: { exportUsersFile } }) => {
          if (exportUsersFile) {
            this.exportLink = exportUsersFile.url;
            const link = document.createElement("a");
            link.setAttribute("href", this.exportLink);
            link.click();
          }
        });
    },
    openDeleteDialog(data) {
      const variables = {
        id: data._id
      };
      this.$buefy.dialog.confirm({
        title: "Confirm Deletion ",
        message:
          "Are you sure you want to <b>delete</b> user with email " +
          data.email +
          " ?",
        confirmText: "Delete",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => {
          this.mutate(DELETE_USER, variables)
            .then(() => {
              this.$apollo.getClient().resetStore();
              this.$buefy.toast.open({
                message: "User deleted successfully.",
                type: "is-success"
              });
            })
            .catch(error => {
              this.$buefy.toast.open({
                message: `${error}`,
                type: "is-danger"
              });
            });
        }
      });
    },
    onDateRange(data) {
      this.queryParams.range.start_date =
        moment(new Date(data[0])).format("YYYY-MM-DD") + " 00:00:00";
      this.queryParams.range.end_date =
        moment(new Date(data[1])).format("YYYY-MM-DD") + " 23:59:59";

      this.$apollo.queries.allUsers.refetch();
    },
    clearField() {
      this.dates = [];
      this.queryParams.range.start_date = "";
      this.queryParams.range.end_date = "";
      this.$apollo.queries.allUsers.refetch();
    }
  },
  apollo: {
    allUsers: {
      query: USERS,
      variables() {
        return {
          input: this.getQueryParams
        };
      }
    }
  }
};
</script>

<style lang="scss">
@media (min-width: 769px) {
  .custom-dropdown {
    margin-bottom: 22px !important;
  }
}
.custom-dropdown._right {
  & .dropdown-menu {
    right: 0;
    left: unset;
  }
}
.customSection {
  padding: 4rem 3rem;
}
.level {
  align-items: flex-start;
}
.level-right {
  align-items: flex-start;
}
.custom-level-item {
  flex-direction: column;
  div.has-addons {
    margin-bottom: 0.25rem;
  }
  p.help {
    margin-top: 0px;
  }
}
</style>
