<template>
  <section>
    <b-modal
      :active.sync="isActive"
      has-modal-card
      trap-focus
      :on-cancel="close"
    >
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title"><strong>Order Areas</strong></p>
        </header>
        <section class="modal-card-body">
          <div class="card-content is-left">
            Click and drag to change the order of areas as they appear on your
            profile.
          </div>
          <ValidationObserver ref="orderProcedureArea">
            <draggable
              class="list-group areas"
              tag="ul"
              :list="areas"
              v-bind="dragOptions"
              @start="drag = true"
              @end="drag = false"
            >
              <transition-group
                class="columns is-multiline is-fullwidth"
                type="transition"
                :name="!drag ? 'flip-list' : null"
              >
                <div
                  class="list-group-item column is-two-fifths"
                  v-for="area in areas"
                  :key="area.id"
                >
                  <div class="box area-item">
                    <img
                      :src="area.procedure_area_icon_url"
                      class="icon item-icon"
                      style=""
                    />
                    <span>
                      {{ area.name }}
                    </span>
                  </div>
                </div>
              </transition-group>
            </draggable>
          </ValidationObserver>
        </section>
        <footer class="modal-card-foot">
          <button class="button" type="button" @click="close">
            Close
          </button>
          <button class="button is-primary" @click="create">Save</button>
        </footer>
      </div>
    </b-modal>
  </section>
</template>

<script>
import draggable from "vuedraggable";
import { UPDATE_PROVIDERS_PROCEDURE_AREAS_POSITIONS } from "../../../_graphql/Mutations/mutations";

export default {
  components: {
    draggable
  },
  data() {
    return {
      isActive: false,
      area_id: "",
      name: "",
      areas: [],
      procAreas: [],
      drag: false
    };
  },
  props: {
    providerId: {
      required: true,
      type: Number
    },
    areasProcedures: {
      default: () => [],
      type: Array
    }
  },
  methods: {
    async create() {
      this.$refs.orderProcedureArea.validate().then(async success => {
        if (!success) {
          this.refName = "orderProcedureArea";
          this.handleErrors();
          return;
        }

        const newPositions = {};
        this.areas.forEach((area, index) => {
          if (area.position !== index + 1) {
            newPositions[area.id] = index + 1;
          }
        });
        const providerId = parseInt(this.$route.params.providerId);

        const variables = {
          input: {
            provider_id: providerId,
            areas_position_list: JSON.stringify(newPositions)
          }
        };

        await this.mutate(UPDATE_PROVIDERS_PROCEDURE_AREAS_POSITIONS, variables)
          .then(({data}) => {

            if (!data.updateProvidersProceduresAreasPositions) {
              return;
            }

            this.$emit("updatedProcedures");
            this.close();
            this.$buefy.toast.open({
              message: "Procedures order updated successfully!",
              type: "is-success"
            });
          })
          .catch(error => {
            this.close();
            this.$buefy.toast.open({
              message: `${error}`,
              type: "is-danger"
            });
          });
      });
    },
    openModal() {
      this.isActive = true;
    },
    close() {
      this.isActive = false;
      this.$refs.orderProcedureArea.reset();
      this.name = "";
      this.area_id = "";
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    },
    getProcAreas: {
      get: function() {
        return this.areasProcedures
          .map(area => {
            return {
              id: area.id,
              name: area.name,
              procedure_area_icon_url: area.url,
              position: area.position
            };
          })
          .sort((a, b) => (a.position - b.position));
      },
      set: function(newVal) {
        this.procAreas = newVal;
      }
    }
  },
  watch: {
    getProcAreas: {
      handler: function() {
        this.areas = this.getProcAreas;
        this.areasProcedures
          .map(area => {
            return {
              id: area.id,
              name: area.name,
              procedure_area_icon_url: area.url,
              position: area.position
            };
          })
          .sort((a, b) => (a.position - b.position));
      },
      immediate: true
    }
  }
};
</script>
<style>
.area-item {
  min-width: 150px;
  max-width: 150px;
  margin: 5px;
  position: relative;
  text-align: center;
}
.area-item img {
  position: absolute;
  top: 15px;
  left: 5px;
}
.area-item span {
  left: 20px;
  position: relative;
}
.areas {
  margin-left: 20%;
  margin-right: 5%;
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.list-group {
  min-height: 100px;
}
.list-group-item {
  cursor: move;
}
.list-group-item i {
  cursor: pointer;
}
.item-icon {
  height: 2rem !important;
  width: 2rem !important;
  margin: 0 0.5rem;
}
</style>
