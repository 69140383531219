<template>
  <section class="section">
    <div class="is-title-bar">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <h1 class="title">
              {{
                showCatBtn && showTreatmentBtn
                  ? "Create Subcategory or Treatment"
                  : !showCatBtn
                  ? getRouteTitle
                  : "Treatments"
              }}
            </h1>
          </div>
        </div>
        <div class="level-right">
          <div class="level-item" v-if="showCatBtn && showTreatmentBtn">
            <router-link
              :to="{
                name: 'areaTypeCat.create',
                params: { id: this.getAreaTypeId }
              }"
            >
              <b-button class="is-primary">
                Create Subcategory
              </b-button>
            </router-link>
          </div>
          <div class="level-item" v-if="showCatBtn && showTreatmentBtn">
            <b-button class="is-primary" @click.prevent="addTreatment">
              Add Treatment
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <router-view />
    <add-treatments
      v-if="areaTypeData.treatments"
      :data="areaTypeData.treatments"
      ref="addTreatments"
      @onUpdate="updatePage"
    ></add-treatments>
  </section>
</template>

<script>
import {
  AREA,
  AREA_CATEGORIES_TREATMENTS
} from "../../../_graphql/Queries/queries";
import LoadingState from "../../../mixins/LoadingState";
import addTreatments from "../components/addTreatments";

export default {
  name: "SubcatTreatmentsWrapper",
  mixins: [LoadingState],

  data() {
    return {
      areaTypeData: {}
    };
  },
  components: {
    addTreatments
  },
  apollo: {
    areaTypeData: {
      query: AREA_CATEGORIES_TREATMENTS,
      variables() {
        return {
          id: this.getAreaTypeId
        };
      },
      update: data => data.areaType
    },
    area: {
      query: AREA,
      variables() {
        return {
          id: this.getAreaId
        };
      }
    }
  },

  computed: {
    showCatBtn() {
      return (
        typeof this.areaTypeData.categories !== "undefined" &&
        this.areaTypeData.categories.length === 0
      );
    },
    showTreatmentBtn() {
      return (
        typeof this.areaTypeData.treatments !== "undefined" &&
        this.areaTypeData.treatments.length === 0
      );
    },
    //check if there are no tratments and categories inside area Type
    checkIsEmpty() {
      return (
        typeof this.areaTypeData.categories !== "undefined" &&
        this.areaTypeData.categories.length > 0 &&
        typeof this.areaTypeData.treatments !== "undefined" &&
        this.areaTypeData.treatments.length > 0
      );
    },
    categories() {
      return (
        typeof this.areaTypeData.categories !== "undefined" &&
        this.areaTypeData.categories.length > 0
      );
    },
    treatments() {
      return (
        typeof this.areaTypeData.treatments !== "undefined" &&
        this.areaTypeData.treatments.length > 0
      );
    }
  },
  methods: {
    updatePage() {
      this.$apollo.queries.areaTypeData.refetch();
    },
    createSubcategory() {
      return this.$refs.createCategory.showModal();
    },
    addTreatment() {
      return this.$refs.addTreatments.openModal();
    }
  },
  watch: {
    showCatBtn() {
      this.$apollo.queries.areaTypeData.refetch();
    }
  }
};
</script>

<style scoped></style>
