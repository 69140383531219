var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('card-component',{attrs:{"title":"Basic Info","icon":"account-circle"}},[_c('ValidationObserver',{ref:"userFormValidator",staticClass:"userForm"},[_c('div',{staticClass:"tile is-ancestor"},[_c('div',{staticClass:"tile is-3 is-parent is-vertical",attrs:{"id":"avatar"}},[_c('label',{staticClass:"label"},[_vm._v("Profile Avatar")]),_c('img',{staticClass:"avatarImage",attrs:{"src":this.form.avatar
              ? this.form.avatar
              : this.origin + '/img/icons/blank_avatar.png'}}),_c('div',{staticClass:"upload-btn-wrapper"},[_c('button',{staticClass:"button is-light avatar-button plus"},[_c('b-icon',{attrs:{"icon":"plus","size":"is-default"}})],1),_c('input',{ref:"avatarInput",attrs:{"type":"file","title":"Upload profile picture","name":"avatar"},on:{"input":_vm.uploadImage}})]),(this.form.avatar)?_c('button',{staticClass:"button is-light avatar-button trash-can",on:{"click":_vm.removeAvatar}},[_c('b-icon',{attrs:{"icon":"trash-alt","pack":"fas","size":"is-small"}})],1):_vm._e()]),_c('section',{staticClass:"tile is-parent is-vertical"},[_c('div',{staticClass:"columns",staticStyle:{"flex-wrap":"wrap"}},[_c('ValidationProvider',{staticClass:"is-11 is-offset-1 column",attrs:{"rules":"required|min:2","name":"First Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
              var valid = ref.valid;
return [_c('b-field',{attrs:{"type":{ 'is-danger': errors[0], 'is-primary': valid },"label":"First Name"}},[_c('b-input',{attrs:{"trim":""},model:{value:(_vm.form.firstName),callback:function ($$v) {_vm.$set(_vm.form, "firstName", $$v)},expression:"form.firstName"}})],1),_vm._l((errors),function(error){return _c('span',{key:error.id,staticClass:"has-text-danger help"},[_vm._v(_vm._s(error))])})]}}])}),_c('ValidationProvider',{staticClass:"is-11 is-offset-1 column",attrs:{"rules":"required|min:2","name":"Last Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
              var valid = ref.valid;
return [_c('b-field',{attrs:{"type":{ 'is-danger': errors[0], 'is-primary': valid },"label":"Last Name"}},[_c('b-input',{attrs:{"trim":""},model:{value:(_vm.form.lastName),callback:function ($$v) {_vm.$set(_vm.form, "lastName", $$v)},expression:"form.lastName"}})],1),_vm._l((errors),function(error){return _c('span',{key:error.id,staticClass:"has-text-danger help"},[_vm._v(_vm._s(error))])})]}}])})],1)]),_c('div',{staticClass:"tile is-parent is-vertical"},[_c('div',{staticClass:"columns",staticStyle:{"flex-wrap":"wrap"}},[_c('ValidationProvider',{staticClass:"is-10 column",attrs:{"name":"Country"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
              var valid = ref.valid;
return [_c('b-field',{attrs:{"type":{ 'is-danger': errors[0], 'is-primary': valid },"label":"Country"}},[_c('b-input',{attrs:{"trim":""},model:{value:(_vm.form.country),callback:function ($$v) {_vm.$set(_vm.form, "country", $$v)},expression:"form.country"}})],1),_vm._l((errors),function(error){return _c('span',{key:error.id,staticClass:"has-text-danger help"},[_vm._v(_vm._s(error))])})]}}])}),_c('ValidationProvider',{staticClass:"is-10 column",attrs:{"name":"City"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
              var valid = ref.valid;
return [_c('b-field',{attrs:{"type":{ 'is-danger': errors[0], 'is-primary': valid },"label":"City"}},[_c('b-input',{attrs:{"trim":""},model:{value:(_vm.form.city),callback:function ($$v) {_vm.$set(_vm.form, "city", $$v)},expression:"form.city"}})],1),_vm._l((errors),function(error){return _c('span',{key:error.id,staticClass:"has-text-danger help"},[_vm._v(_vm._s(error))])})]}}])})],1)])]),_c('div',{staticClass:"columns userFormSubmit"},[_c('div',{staticClass:"column is-offset-9"},[_c('b-button',{staticClass:"is-primary",attrs:{"loading":_vm.savingProfile},on:{"click":_vm.submitForm}},[_vm._v("Save")])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }