const regex = /https?:\/\//;

const prefix = process.env.VUE_APP_CMS_URL ? process.env.VUE_APP_CMS_URL : 'http://cms.dev.metime.com';

const fixImageUrl = function (url) {
  if (regex.test(url)) {
    return url;
  }

  return prefix + url;
}

export default fixImageUrl;
