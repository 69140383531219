<template>
  <section class="customSection">
    <div class="is-title-bar">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <h1 class="title">Edit User</h1>
          </div>
        </div>
      </div>
    </div>
    <UserForm
      v-if="allUsers"
      @formSubmit="editUser"
      :resetSwitch="resetSwitch"
      :editModel="allUsers ? allUsers.data[0] : null"
    />
  </section>
</template>

<script>
import ValidationErrors from "../../../mixins/ValidationErrors";
import UserForm from "../components/UserForm";
import { USER } from "../../../_graphql/Queries/queries";
import { UPDATE_PROFILE } from "../../../_graphql/Mutations/mutations";

export default {
  mixins: [ValidationErrors],
  components: { UserForm },
  data() {
    return {
      resetSwitch: false,
      editModel: null
    };
  },
  computed: {
    singleUserQueryParams() {
      return {
        limit: 1,
        offset: 0,
        filter: `_id:${this.$route.params.id}`,
        order: ""
      };
    }
  },
  methods: {
    editUser(userForm) {
      const mutation = UPDATE_PROFILE;
      const variables = {
        id: userForm._id,
        input: {
          firstName: userForm.firstName,
          lastName: userForm.lastName,
          email: userForm.email,
          country: userForm.country,
          city: userForm.city,
          role: userForm.role,
          age: userForm.age,
          gender: userForm.gender ? userForm.gender : "NONE",
          avatar: userForm.avatar
        }
      };
      if (userForm.password) {
        variables.input.password = userForm.password;
      }
      this.$apollo.provider.defaultClient
        .mutate({
          mutation: mutation,
          variables: variables
        })
        .catch(error => {
          return this.$buefy.toast.open({
            message: `${error}`,
            type: "is-danger",
            duration: 3000
          });
        })
        .then(data => {
          let updateProfile = data.data.updateProfile;
          if (updateProfile.success) {
            this.$buefy.toast.open({
              message: "User updated successfully.",
              type: "is-success",
              duration: 1500
            });
            this.$buefy.toast.open({
              message: "Redirecting...",
              type: "is-info",
              duration: 1500
            });
            setTimeout(() => {
              this.$router.push("/users");
            }, 3000);
          } else {
            this.$buefy.toast.open({
              message: updateProfile.errors.join(", "),
              type: "is-danger",
              duration: 3000
            });
          }
        });
    }
  },
  apollo: {
    allUsers: {
      query: USER,
      variables() {
        return {
          input: this.singleUserQueryParams
        };
      }
    }
  }
};
</script>

<style scoped></style>
