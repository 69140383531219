<template>
  <div>
    <div class="preview-container my-lg-5 mx-auto">
      <div class="container-provider-preview my-3 p-0 ml-auto mr-auto">
        <div
          class="provider-cover-section p-1"
          :style="
            'background-image: url(' +
              provider.cover_image_url +
              '); background-color: unset; border-radius: 0 0 15px 15px; background-repeat: no-repeat; background-position: center; background-size: cover;'
          "
        >
          <!-- <div no logo for now
            class="provider__logo"
            :style="'background-image: url(' + provider.cover_image_url + ')'"
          ></div> -->
        </div>

        <div class="container">
          <div class="text-center">
            <div class="provider__profile py-5 px-4">
              <div
                class="provider__profile-image"
                :style="'background-image: url(' + provider.avatar_url + ')'"
              ></div>
            </div>

            <h5 class="blue-title">{{ provider.name }}</h5>
            <!-- <p class="medium-text mb-0">Part of Venus Medical Global</p> field not yet implemented -->
            <p class="light-text">
              {{
                provider.address
                  ? provider.address.locale.city.name +
                    ", " +
                    provider.address.locale.country.name
                  : "N/A"
              }}
            </p>
          </div>

          <div class="pt-3">
            <p class="healine blue-title bold-text gray-bg rounded-16 mb-3">
              Provider’s Info
            </p>
            <p class="px-3 provider__info">{{ provider.description }}</p>
          </div>

          <div class="pt-3">
            <p class="healine blue-title bold-text gray-bg rounded-16 mb-3">
              Specialties
            </p>
            <div class="pl-3">
              <div
                class="row medium-text mb-2"
                v-for="(specialty, index) in provider.specialties"
                :key="index"
              >
                <div class="col-12">{{ specialty.name }}</div>
                <!-- <div class="col-2 p-0">
                  <a
                    href="#"
                    class="btn-more-info"
                    @click.prevent="openPopup(specialty.description)"
                  ></a>
                </div> -->
              </div>
            </div>
          </div>
        </div>

        <div class="pt-3">
          <div class="container">
            <p class="healine blue-title bold-text gray-bg rounded-16 mb-3">
              Photos
            </p>
          </div>
          <div class="pl-5 carousel-container" style="display: inline-table">
            <div
              v-for="(photo, index) in provider.photos"
              :key="index"
              class="carousel__item ml-1 mb-3"
              :style="'background-image: url(' + photo.url + ')'"
            ></div>
          </div>
        </div>

        <div class="pt-3">
          <div class="container">
            <p class="healine blue-title bold-text gray-bg rounded-16 mb-3">
              Videos
            </p>
          </div>
          <div class="pl-5 carousel-container" style="display: inline-table">
            <div
              v-for="(video, index) in provider.videos"
              :key="index"
              class="carousel__item ml-1 mb-3"
              :style="'background-image: url(' + video.image_url + ')'"
            >
              <div class="carousel__item-video" />
            </div>
          </div>
        </div>

        <div class="container">
          <div class="pt-3" v-if="provider.main_practitioners.length > 0">
            <p class="healine blue-title bold-text gray-bg rounded-16 mb-3">
              Main Practitioners
            </p>

            <div
              class="row pl-3 mb-3"
              v-for="(practitioner, index) in provider.main_practitioners"
              :key="index"
            >
              <div class="col-4">
                <div
                  class="doctor__profile-image"
                  :style="
                    'background-image: url(' + practitioner.avatar_url + ')'
                  "
                ></div>
              </div>

              <div class="col-6 px-lg-0">
                <p class="blue-title bold-text mb-2">{{ practitioner.name }}</p>
                <p class="medium-text">{{ practitioner.specialty }}</p>
              </div>

              <div class="col-2 p-0">
                <a
                  href="#"
                  @click.prevent="openPopup(practitioner.description)"
                  class="btn-more-info"
                ></a>
              </div>
            </div>
          </div>

          <!-- ratings are hidden until after MVP launch -->
          <div class="pt-3" v-if="provider.is_visible_rating && false">
            <p class="healine blue-title bold-text gray-bg rounded-16 mb-3">
              Rating
            </p>
            <div class="plan-container__rating pl-3">
              <span
                v-for="(star, index) in provider.rating"
                :key="index"
                class="star--preview"
              ></span>
            </div>
          </div>

          <div class="pt-3 pb-3">
            <p class="healine blue-title bold-text gray-bg rounded-16 mb-3">
              Experience
            </p>
            <p class="pl-3">{{ provider.experience }}+ years</p>
          </div>
        </div>

        <div class="pop-up" v-if="showPopup">
          <div class="pop-up__overlay-preview">
            <div class="pop-up__container rounded-16 p-4">
              <a
                href="#"
                @click.prevent="closePopup()"
                class="d-block clearfix"
              >
                <span class="d-block btn-close"></span>
              </a>
              <p class="pop-up__text">
                {{ popupText }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProviderPreview",
  props: {
    provider: Object,
    closePopupSwitch: Boolean
  },
  watch: {
    closePopupSwitch() {
      this.closePopup();
    }
  },
  data() {
    return {
      popupText: "",
      showPopup: false
    };
  },
  methods: {
    openPopup(popupText) {
      this.popupText = popupText;
      this.showPopup = true;
    },
    closePopup() {
      this.showPopup = false;
      this.popupText = "";
    }
  }
};
</script>

<style scoped></style>
