import gql from "graphql-tag";

const JOURNEY_PROVIDERS = gql`
  query JourneyProviders($input: QueryParams) {
    journeyProviders(input: $input) {
      meta {
        total_pages
        current_page
        next_page
        per_page
        total_count
      }
      data {
        conversation_id
        status
        id
        seen
        provider {
          id
          uuid
          name
          treatments {
            id
            description
            name
          }
          settings {
            id
            allow_payments
            allow_videocalls
          }
          videocall_free_seconds
          videocall_paid_seconds
        }
        journey {
          id
          address {
            country
            city
          }
          name
          photos {
            url
            thumb_url
          }
          specialties {
            name
          }
          answers {
            options {
              name
            }
          }
          date_added
          areas {
            id
            area_types {
              id
              name
              categories {
                name
                id
                treatments {
                  id
                  name
                }
              }
              treatments {
                id
                name
              }
            }
            name
          }
          gender
          settings_values {
            value
            option {
              min
              max
              value
            }
            type
          }
          user {
            authUser {
              firstName
              lastName
              age
            }
            auth_id
          }
        }
      }
    }
  }
`;

const MY_PROVIDERS = gql`
  query myProviders($input: QueryParams) {
    myProviders(input: $input) {
      code
      errors
      meta {
        current_page
        next_page
        per_page
        total_count
      }
      data {
        id
      }
    }
  }
`;

const MY_JOURNEY_PROVIDERS = gql`
  query myJourneyProviders($input: QueryParams) {
    myJourneyProviders(input: $input) {
      code
      errors
      meta {
        total_pages
        current_page
        next_page
        per_page
        total_count
      }
      data {
        id
        provider {
          id
          uuid
          creator_auth_id
          parent {
            id
          }
          date_added
          address {
            locale {
              country {
                id
                name
              }
              city {
                id
                name
              }
            }
            address
            zip
            longitude
            latitude
          }
          email
          phone
          is_subscribed
          subscription_plan
          name
          cover_image_url
          avatar_url
          description
          experience
          is_visible_rating
          rating
          photos {
            id
            date_added
            width
            height
            url
            thumb_url
          }
          videos {
            id
            date_added
            url
            image_url
            type
          }
          status
          main_practitioners {
            id
            name
            specialty
            avatar_url
            description
          }
          treatments {
            id
            name
            description
          }
          specialties {
            id
            name
            description
          }
          settings {
            id
            allow_payments
            allow_videocalls
          }
          videocall_free_seconds
          videocall_paid_seconds
        }
        journey {
          id
          date_added
          user {
            id
            authUser {
              _id
              username
              email
              firstName
              lastName
              dateCreated
              age
              gender
              avatar
              role
              country
              city
              banned
            }
            auth_id
            role
          }
          name
          gender
          range_radius
          photos {
            id
            date_added
            width
            height
            url
            thumb_url
          }
          specialties {
            id
            name
            description
          }
          areas {
            id
            name
            male_icon_url
            female_icon_url
            area_types {
              id
              name
              gender
              icon_set_id
              icon_set {
                id
                name
                male {
                  id
                  images {
                    id
                    url
                    photo_example_url
                    name
                    description
                    overlay_key
                  }
                }
                female {
                  id
                  images {
                    id
                    url
                    photo_example_url
                    name
                    description
                    overlay_key
                  }
                }
              }
              categories {
                id
                name
                gender
                icon_set_id
                icon_set {
                  id
                  name
                  male {
                    id
                    images {
                      id
                      url
                      photo_example_url
                      name
                      description
                      overlay_key
                    }
                  }
                  female {
                    id
                    images {
                      id
                      url
                      photo_example_url
                      name
                      description
                      overlay_key
                    }
                  }
                }
                treatments {
                  id
                  name
                  description
                }
              }
              treatments {
                id
                name
                description
              }
            }
            icon_set_id
            icon_set {
              id
              name
              male {
                id
                images {
                  id
                  url
                  photo_example_url
                  name
                  description
                  overlay_key
                }
              }
              female {
                id
                images {
                  id
                  url
                  photo_example_url
                  name
                  description
                  overlay_key
                }
              }
            }
          }
          answers {
            id
            options {
              id
              name
            }
          }
          settings_values {
            id
            value
            option {
              id
              value
              min
              max
            }
            type
          }
          address {
            longitude
            latitude
            country
            city
          }
        }
        conversation_id
        is_conversion
        conversion_date
        appointment_date
        status
        flags {
          id
          journey_provider_id
          date_added
          flag_reason {
            id
            reason
            role
          }
          reported_by
          resolved
        }
        seen
        direct_match
      }
    }
  }
`;

export default {
  JOURNEY_PROVIDERS,
  MY_PROVIDERS,
  MY_JOURNEY_PROVIDERS
};
