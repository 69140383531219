<template>
  <div class="journey-container is-fullwidth">
    <h5
      class="subtitle is-full has-text-left has-text-weight-bold"
      style="width: 100%;padding:20px;border-bottom:1px solid #bebebe;color:#2AACD6;font-size:1.10rem"
    >
      <i class="fa fa-map-marked-alt" />
      User journey overview
    </h5>
    <hr />
    <div class="columns tiles is-clearfix" style="padding:0 30px;">
      <div class="column journey-tile">
        <div class="label">{{ journey.name }}</div>
        <div class="data">{{ journey.date_added }}</div>
      </div>
      <div class="column journey-tile ">
        <span class="label">
          {{ journey.user.authUser.firstName }}
          {{ journey.user.authUser.lastName }}
          <i
            v-if="journey.gender == 'MALE'"
            style="font-size:2rem;color:lightblue;vertical-align: middle;margin-left: 5px"
            class="fa fa-mars"
          />
          <i
            v-if="journey.gender == 'FEMALE'"
            style="font-size:2rem;color:lightpink;vertical-align: middle;margin-left: 5px"
            class="fa fa-venus"
          />
          <br />
          Age: {{ journey.user.authUser.age }}
        </span>
      </div>
      <div class="column journey-tile">
        <span class="label">{{ getSetting("currency_code").value }}</span>
        <span class="data">
          Price range:
          {{ currencies[getSetting("currency_code").value]
          }}{{ getSetting("price").value }}
        </span>
      </div>
    </div>
    <div class="columns is-full" style="width: 100%;padding:0 30px;">
      <div class="column has-text-left">
        <span class="label">Treatments</span>
        <span class="data">
          <ul>
            <li :key="treatment" v-for="treatment in treatments">
              &blacktriangleright; {{ treatment }}
            </li>
          </ul>
        </span>
        <br />
        <br />
        <span class="label">Areas</span>
        <span :key="area.id" v-for="area in journey.areas" class="data">
          <b>{{ area.name }}</b>
          <br />
          <span
            style="margin-left:5px;"
            :key="area_type.id"
            v-for="area_type in area.area_types"
          >
            <b>{{ area_type.name }}</b>
            <br />
            <span
              style=""
              :key="category.id"
              v-for="category in area_type.categories"
            >
              {{ category.name }}
              <span
                style="margin-left:15px;"
                :key="treatment.id"
                v-for="treatment in area_type.treatments"
              >
                <br />
                {{ treatment.name }}
              </span>
            </span>
            <br />
          </span>
          <br />
        </span>
        <span v-if="answers.length" class="holder">
          <br />
          <br />
          <span class="label">Answers</span>
          <span class="data"
            ><ul>
              <li :key="answer[0]" v-for="answer in answers">
                &blacktriangleright; {{ answer[0] }}
              </li>
            </ul></span
          >
        </span>
      </div>

      <div class="column has-text-left">
        <span class="label">Country</span>
        <span class="data">{{ journey.address.country }}</span>
        <br />
        <br />
        <span class="label">City</span>
        <span class="data">{{ journey.address.city }}</span>
        <br />
        <br />
        <span v-if="getSetting('days_off')" class="holder">
          <br />
          <span class="label">Available days off</span>
          <span class="data"> {{ getSetting("days_off").value }} </span>
        </span>
        <br />
        <span v-if="getSetting('social_downtime')" class="holder">
          <br />
          <span class="label">Available social days off</span>
          <span class="data">
            {{ getSetting("social_downtime").value }}
          </span>
        </span>
      </div>

      <div class="column has-text-left">
        <span v-if="journey.specialties.length > 0" class="holder">
          <span class="label">Provider's Specialties</span>
          <span class="data">
            <ul>
              <li
                :key="specialty.name"
                v-for="specialty in journey.specialties"
              >
                {{ specialty.name }}
              </li>
            </ul>
          </span>
        </span>
        <span v-if="getSetting('provider_experience')" class="holder">
          <br />
          <br />
          <span class="label">Preferred Provider experience</span>
          <span class="data">
            {{ getSetting("provider_experience").value }} years
          </span>
        </span>
      </div>
    </div>
    <div class="photos" style="padding:0 30px;">
      <div class="label has-text-left">
        Photos
      </div>
      <div class="photos-list">
        <figure
          class="image is-96x96"
          @click="
            () => {
              openModal = true;
              modalImageUrl = photo.url;
            }
          "
          :key="photo.thumb_url"
          v-for="photo in journey.photos"
        >
          <img class="" :src="photo.thumb_url" />
        </figure>
      </div>
    </div>
    <div style="z-index: 100" :class="['modal', openModal ? ' is-active' : '']">
      <div class="modal-background"></div>
      <div class="modal-content">
        <img
          :class="[
            'modal-image',
            modalImageFullscreen ? 'modal-image-full' : '',
            zoomed ? 'zoomed' : ''
          ]"
          @click="zoomed = !zoomed"
          :src="modalImageUrl"
        />
      </div>
      <div class="modal-actions">
        <span
          @click="prevImage(modalImageUrl)"
          class="prev"
          v-if="journey.photos.length > 1 && hasPrev(modalImageUrl)"
        >
          &laquo;
        </span>
        <span
          @click="nextImage(modalImageUrl)"
          class="next"
          v-if="journey.photos.length > 1 && hasNext(modalImageUrl)"
        >
          &raquo;
        </span>
        <span class="close" @click="openModal = !openModal">
          <i class="fa fa-times"></i>
        </span>
      </div>
      <button
        @click="openModal = !openModal"
        class="modal-close is-large"
        aria-label="close"
      ></button>
    </div>
  </div>
</template>

<script>
export default {
  name: "journey-details",
  props: ["journey"],
  data() {
    return {
      currencies: {
        EUR: "€",
        USD: "$",
        GBP: "£"
      },
      openModal: false,
      zoomed: false,
      modalImageUrl: "",
      modalImageFullscreen: false,
      settings: {
        currency_code: "Chosen currency"
      }
    };
  },
  computed: {
    areas() {
      return this.journey.areas
        ? this.journey.areas.map(area => area.name).join(", ")
        : "-";
    },
    treatments() {
      const treatments = new Set();
      this.journey.areas.forEach(area => {
        area.area_types.forEach(areaType => {
          areaType.categories.forEach(category => {
            category.treatments.forEach(treatment => {
              treatments.add(treatment.name);
            });
          });
          areaType.treatments.forEach(treatment => {
            treatments.add(treatment.name);
          });
        });
      });
      return [...treatments];
    },
    answers() {
      return this.journey.answers.length
        ? this.journey.answers.map(answer =>
            answer.options.map(option => option.name)
          )
        : [];
    }
  },
  methods: {
    hasPrev(url) {
      if (this.journey.photos.length == 0) return false;
      return this.journey.photos.findIndex(image => image.url === url) - 1 >= 0;
    },
    hasNext(url) {
      if (this.journey.photos.length == 0) return false;
      return (
        this.journey.photos.findIndex(image => image.url === url) + 1 <
        this.journey.photos.length
      );
    },
    prevImage(url) {
      this.modalImageUrl = this.journey.photos[
        this.journey.photos.findIndex(image => image.url === url) - 1
      ].url;
    },
    nextImage(url) {
      this.modalImageUrl = this.journey.photos[
        this.journey.photos.findIndex(image => image.url === url) + 1
      ].url;
    },

    getSetting(key) {
      const setting = this.journey.settings_values.find(
        setting => setting.type === key
      );
      return setting ? setting : false;
    }
  }
};
</script>
<style scoped>
.modal-actions {
  position: fixed;
  z-index: 500;
}

.modal-actions .prev,
.modal-actions .next,
.modal-actions .close {
  font-size: 72px;
  color: #fff;
  text-shadow: -1px 1px 0px #000;
  position: fixed;
  cursor: pointer;
}

.modal-actions .prev {
  left: 10%;
}

.modal-actions .next {
  right: 10%;
}

.modal-actions .close {
  right: 3%;
  top: 3%;
  font-size: 30px;
}

.image {
  padding: 5px;
  background: none #fff;
  border-radius: 3px;
  cursor: pointer;
  margin: 5px;
  box-shadow: 0px 0px 3px #bebebe;
  transition: all ease 0.2s;
}

.image:hover {
  box-shadow: 0px 0px 5px #999;
}

.image img {
  border-radius: 3px;
  max-height: 100%;
}

.modal-image {
  max-width: 60%;
}

.modal-image.zoomed {
  max-width: 100%;
  width: 100%;
}

.journey-container {
  width: 100%;
  border-top: 1px solid #bebebe;
  margin-top: 5px;
  max-height: 500px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.journey-container::-webkit-scrollbar-track {
  background: none #fff;
}

.journey-container::-webkit-scrollbar {
  background: none #333;
  width: 12px;
  border-radius: 10px;
}

.journey-container::-webkit-scrollbar-thumb {
  background: none #82cee7;
  border-radius: 4px;
}

.tile .label {
  color: rgb(42, 172, 214);
}
.journey-tile {
  background: none #e6eff2;
  padding: 20px;
  border-radius: 10px;
  margin: 0 20px 0 10px;
}

.journey-tile .label {
  display: block;
  width: 100%;
}
.journey-tile .data {
  display: block;
  width: 100%;
  color: #555;
}
.photos-list {
  display: inline-flex;
  flex-direction: row;
  width: 100%;
}
</style>
<style>
.modal-background {
  z-index: 3;
}

.modal-content {
  z-index: 3;
}

aside.aside {
  z-index: 3 !important;
}

.navbar.is-fixed-top {
  z-index: 3 !important;
}
</style>
