<template>
  <div class="section">
    <div class="is-title-bar">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <h1 class="title">
              Flagged Journeys
            </h1>
          </div>
        </div>
      </div>
    </div>
    <flags-table
      v-if="journeyProviderByFlags"
      :table-data="journeyProviderByFlags"
      @updatedFlag="updateFlags"
    ></flags-table>
  </div>
</template>

<script>
import { JOURNEY_PROVIDER_BY_FLAGS } from "../../../_graphql/Queries/queries";
import flagsTable from "../components/FlagsTable";

export default {
  name: "flagged",
  data() {
    return {
      flaggedJourneys: [],
      openedFlags: [],
      queryParams: {
        query: "jpf.resolved:OPEN"
      }
    };
  },
  components: {
    flagsTable
  },
  apollo: {
    journeyProviderByFlags: {
      query: JOURNEY_PROVIDER_BY_FLAGS,
      variables() {
        return {
          input: this.queryParams
        };
      },
      update: data => data.journeyProviderByFlags
    }
  },
  methods: {
    updateFlags() {
      this.$apollo.queries.journeyProviderByFlags.refetch();
      this.$forceUpdate();
    }
  }
};
</script>
