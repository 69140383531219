<template>
  <card-component>
    <ValidationObserver
      ref="articleFormValidator"
      class="userForm"
      id="articleForm"
    >
      <div class="columns is-variable is-7">
        <div class="column is-9">
          <div class="columns is-variable is-5 is-multiline is-mobile">
            <ValidationProvider
              class="column is-6"
              rules="required|min:2|max:200"
              v-slot="{ errors, valid }"
              name="Name"
            >
              <b-field
                :type="{ 'is-danger': errors[0], 'is-primary': valid }"
                label="Name"
              >
                <b-input trim v-model="form.name" />
              </b-field>
              <span
                v-for="error in errors"
                :key="error.id"
                class="has-text-danger help"
                >{{ error }}</span
              >
            </ValidationProvider>
            <ValidationProvider
              class="column is-6"
              rules="required|min:2|max:200"
              v-slot="{ errors, valid }"
              name="Slug"
            >
              <b-field
                :type="{ 'is-danger': errors[0], 'is-primary': valid }"
                label="Slug"
              >
                <b-input
                  placeholder="example-slug-name"
                  trim
                  v-model="form.slug"
                />
              </b-field>
              <span
                v-for="error in errors"
                :key="error.id"
                class="has-text-danger help"
                >{{ error }}</span
              >
            </ValidationProvider>
            <ValidationProvider
              class="column is-6-desktop is-6-tablet"
              rules="required|min:2|max:200"
              v-slot="{ errors, valid }"
              name="Title"
            >
              <b-field
                :type="{ 'is-danger': errors[0], 'is-primary': valid }"
                label="Title"
              >
                <b-input trim v-model="form.title" />
              </b-field>
              <span
                v-for="error in errors"
                :key="error.id"
                class="has-text-danger help"
                >{{ error }}</span
              >
            </ValidationProvider>
          </div>
          <div class="columns is-variable is-5 is-multiline">
            <ValidationProvider
              class="column is-6"
              rules="required|min:2"
              v-slot="{ errors, valid }"
              name="Meta Keywords"
            >
              <b-field
                :type="{ 'is-danger': errors[0], 'is-primary': valid }"
                label="Meta Keywords"
              >
                <b-taginput
                  v-model="form.meta_keywords"
                  ellipsis
                  icon="label"
                  placeholder="Add a keyword"
                  :maxlength="keywordsMaxLength"
                  :before-adding="checkTagsLength"
                ></b-taginput>
              </b-field>
              <span
                v-for="error in errors"
                :key="error.id"
                class="has-text-danger help"
                >{{ error }}</span
              >
            </ValidationProvider>
            <ValidationProvider
              class="column is-6"
              rules="required|min:2|max:200"
              v-slot="{ errors, valid }"
              name="Meta Title"
            >
              <b-field
                :type="{ 'is-danger': errors[0], 'is-primary': valid }"
                label="Meta Title"
              >
                <b-input trim v-model="form.meta_title" />
              </b-field>
              <span
                v-for="error in errors"
                :key="error.id"
                class="has-text-danger help"
                >{{ error }}</span
              >
            </ValidationProvider>
            <ValidationProvider
              class="column is-6"
              rules="required|min:2|max:200"
              v-slot="{ errors, valid }"
              name="Meta Description"
            >
              <b-field
                :type="{ 'is-danger': errors[0], 'is-primary': valid }"
                label="Meta Description"
              >
                <b-input trim v-model="form.meta_description" />
              </b-field>
              <span
                v-for="error in errors"
                :key="error.id"
                class="has-text-danger help"
                >{{ error }}</span
              >
            </ValidationProvider>
            <ValidationProvider
              class="column is-6"
              v-slot="{ errors, valid }"
              name="Read time"
            >
              <b-field
                :type="{ 'is-danger': errors[0], 'is-primary': valid }"
                label="Read time"
              >
                <b-input type="number" trim v-model="form.reading_time" />
              </b-field>
              <span
                v-for="error in errors"
                :key="error.id"
                class="has-text-danger help"
                >{{ error }}</span
              >
            </ValidationProvider>
          </div>
        </div>
        <div class="column is-3">
          <div class="columns is-multiline">
            <!-- <ValidationProvider
            class="column is-12"
            rules=""
            v-slot="{ errors, valid }"
            name="Category"
          >
            <b-field :type="{ 'is-danger': errors[0], 'is-primary': valid }" label="Category">
              <b-input trim v-model="form.category" />
            </b-field>
            <span v-for="error in errors" :key="error.id" class="has-text-danger help">{{ error }}</span>
          </ValidationProvider>
          <ValidationProvider
            class="column is-12"
            rules=""
            v-slot="{ errors, valid }"
            name="Author"
          >
            <b-field :type="{ 'is-danger': errors[0], 'is-primary': valid }" label="Authors">
              <b-taginput
                v-model="form.author"
                ellipsis
                maxtags="1"
                icon="account-circle"
                :data="authors"
                open-on-focus
                autocomplete
                placeholder="Add authors"
              ></b-taginput>
            </b-field>
            <span v-for="error in errors" :key="error.id" class="has-text-danger help">{{ error }}</span>
            </ValidationProvider>-->
          </div>
        </div>
      </div>
      <div class="columns is-variable is-5 is-multiline is-mobile">
        <ValidationProvider
          rules=""
          class="column is-12"
          v-slot="{ errors }"
          name="Blog type"
        >
          <b-field horizontal label="Blog type:" label-for="blogType">
            <b-select
              id="blogType"
              expanded
              placeholder="Select a blog type"
              v-model="form.blog_type"
            >
              <option value="">
                patients
              </option>
              <option value="providers">
                providers
              </option>
            </b-select>
          </b-field>
          <span
            v-for="error in errors"
            :key="error.id"
            class="has-text-danger help"
            >{{ error }}</span
          >
        </ValidationProvider>
      </div>
      <div class="columns is-variable is-5 is-multiline is-mobile">
        <ValidationProvider
          class="column is-12"
          rules="required"
          v-slot="{ errors, valid }"
          name="Content"
          style="padding-bottom: 20px"
        >
          <b-field
            :type="{ 'is-danger': errors[0], 'is-primary': valid }"
            label="Content"
            class="content"
          >
            <ckeditor
              :editor="editor"
              v-model="form.content"
              @input="fixSideTextHeight"
              @ready="fixSideTextHeight"
              :config="editorConfig"
            ></ckeditor>
          </b-field>
          <span
            v-for="error in errors"
            :key="error.id"
            class="has-text-danger help"
            >{{ error }}</span
          >
        </ValidationProvider>
        <ValidationProvider
          class="column is-6"
          :rules="prefill ? '' : 'required'"
          v-slot="{ errors, valid }"
          name="Image"
        >
          <b-field
            :type="{ 'is-danger': errors[0], 'is-primary': valid }"
            label="Image"
          >
            <picture-input
              id="picture-input"
              v-model="form.image"
              :prefill="prefill"
              ref="pictureInput"
              :alertOnError="false"
              @error="notifyError"
              width="600"
              height="300"
              margin="0"
              :crop="false"
              accept="image/jpeg, image/png, image/jpg, image/JPG"
              size="20"
              button-class="button is-primary"
              :custom-strings="{
                upload: '<h1>Oops! Something went wrong.</h1>',
                drag: 'Drop your image here or click to upload',
                change: 'Change Image'
              }"
              @change="onImageChange"
            ></picture-input>
          </b-field>
          <span
            v-for="error in errors"
            :key="error.id"
            class="has-text-danger help"
            >{{ error }}</span
          >
        </ValidationProvider>
        <ValidationProvider
          class="column is-12"
          rules="required"
          v-slot="{ errors, valid }"
          name="Description"
        >
          <b-field
            :type="{ 'is-danger': errors[0], 'is-primary': valid }"
            label="Description"
          >
            <b-input
              v-model="form.description"
              maxlength="500"
              type="textarea"
            ></b-input>
          </b-field>
          <span
            v-for="error in errors"
            :key="error.id"
            class="has-text-danger help"
            >{{ error }}</span
          >
        </ValidationProvider>
      </div>
    </ValidationObserver>
    <div class="level">
      <!-- TODO: Refactor not not rely on level-left -->
      <div class="level-left">
        <div class="level-item"></div>
      </div>
      <div class="level-right">
        <div class="level-item articleFormSubmit">
          <b-button
            class="is-primary"
            :loading="submittingDraft"
            @click="submitForm(true)"
          >
            Save draft
          </b-button>
        </div>

        <div class="level-item articleFormSubmit">
          <b-button
            class="is-primary"
            :loading="submittingPublish"
            @click="submitForm(false)"
          >
            Publish
          </b-button>
        </div>
      </div>
    </div>
  </card-component>
</template>

<script>
import CardComponent from "../../base/components/CardComponent";
import ValidationErrors from "../../../mixins/ValidationErrors";
import fixImageUrl from "../../../_helpers/fixImageUrl";
import ClassicEditor from "@dimulski/ckeditor5-build-classic-cc-mt";
import PictureInput from "vue-picture-input";

export default {
  name: "ArticleForm",
  mixins: [ValidationErrors],
  components: { CardComponent, PictureInput },
  props: {
    editModel: {
      type: Object,
      default: null,
      required: false
    },
    resetSubmitSwitch: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        graphQLUploadAdapter: {
          graphQLUrl:
            process.env.VUE_APP_GRAPHQL_URL ||
            "https://gate.dev.metime.com/graphql",
          authToken: localStorage.getItem("AUTH_TOKEN")
        }
      },
      submittingDraft: false,
      submittingPublish: false,
      prefill: null,
      form: {
        name: "",
        slug: "",
        title: "",
        content: "",
        description: "",
        meta_title: "",
        meta_keywords: [],
        meta_description: "",
        blog_type: null,
        image: null,
        authors: [],
        category: "",
        draft: false,
        reading_time: null
      },
      authors: ["MeTime", "Guest"]
    };
  },
  watch: {
    resetSubmitSwitch() {
      this.submittingDraft = false;
      this.submittingPublish = false;
    }
  },
  created() {
    this.populateEditForm();
  },
  computed: {
    keywordsMaxLength() {
      return 254 - this.form.meta_keywords.join(",").length;
    }
  },
  methods: {
    checkTagsLength() {
      const length = this.keywordsMaxLength;
      return length > 0;
    },
    notifyError(error) {
      this.$buefy.toast.open({
        message:
          error.type === "failedPrefill"
            ? "Something went wrong while trying to load the main picture."
            : error.message,
        type: "is-danger",
        duration: 5000
      });
    },
    fixSideTextHeight() {
      // Sets the minimum height of elements that come after aligned images to the same height as the image
      // and removes those minimum heights if the image is removed or becomes center aligned
      let content = document.getElementsByClassName("ck-content")[0];
      if (content) {
        const imageCaptionHeight = 35;
        content
          .querySelectorAll(
            `figure.float-left img,
                figure.float-right img`
          )
          .forEach(image => {
            if (image.complete) {
              image.parentNode.nextElementSibling.style.minHeight =
                image.clientHeight + imageCaptionHeight + "px";
            } else {
              image.addEventListener(
                "load",
                function() {
                  image.parentNode.nextElementSibling.style.minHeight =
                    image.clientHeight + imageCaptionHeight + "px";
                },
                { once: true }
              );
            }
          });
        content
          .querySelectorAll(":scope > :not(figure)")
          .forEach(directChild => {
            if (directChild.previousElementSibling) {
              if (
                directChild.previousElementSibling.matches(
                  ":not(.float-left)"
                ) &&
                directChild.previousElementSibling.matches(":not(.float-right)")
              ) {
                directChild.style.minHeight = null;
              }
            } else {
              directChild.style.minHeight = null;
            }
          });
      }
    },
    populateEditForm() {
      if (this.editModel) {
        this.form.name = this.editModel.name;
        this.form.slug = this.editModel.slug;
        this.form.title = this.editModel.title;
        this.form.content = this.editModel.content;
        this.form.description = this.editModel.description;
        this.form.reading_time = this.editModel.reading_time;
        this.form.blog_type = this.editModel.blog_type || "";
        this.form.meta_title = this.editModel.meta_title;
        this.form.meta_keywords = this.editModel.meta_keywords
          ? this.editModel.meta_keywords.split(",").filter(kw => kw)
          : [];
        this.form.meta_description = this.editModel.meta_description;
        console.log(this.editModel)
        const imageUrl = this.editModel.img[0].url;
        this.prefill = fixImageUrl(imageUrl);
      }
    },
    onImageChange(image) {
      if (image) {
        this.form.image = this.$refs.pictureInput.file;
      }
    },
    submitForm(isDraft) {
      this.$refs.articleFormValidator.validate().then(success => {
        if (this.form.meta_keywords.join(",").length > 255) {
          return this.$buefy.toast.open({
            message: "Meta Keywords field is too big",
            type: "is-danger",
            duration: 3000
          });
        }
        if (!success) {
          this.refName = "articleFormValidator";
          this.handleErrors();
          return;
        }
        this.form.draft = isDraft;
        this.$emit("formSubmit", this.form);
        this.$apollo.getClient().resetStore(); // to clear allArticles table so it can be refetched with new/edited article. should be refactored to refetch only allArticles
        isDraft
          ? (this.submittingDraft = true)
          : (this.submittingPublish = true);
      });
    }
  }
};
</script>

<style lang="scss">
.articleFormSubmit button {
  margin-top: 20px;
  font-size: 1.15rem;
  width: 120px;
}

#articleForm {
  #picture-input canvas {
    z-index: 0 !important;
  }

  .picture-inner .picture-inner-text {
    font-size: 1em !important;
  }

  .d-table {
    display: table !important;
  }

  .image-style-align-right:not(.image_resized),
  .image-style-align-left:not(.image_resized) {
    max-width: 50%;
  }

  .image-style-align-right {
    margin-left: 2.8rem !important;
  }

  .image-style-align-left {
    margin-right: 2.8rem !important;
  }

  figure {
    margin-top: 0 !important;
    margin-bottom: 10px !important;
  }

  div.ck-content[role="textbox"] {
    min-height: 200px;
    padding-top: 10px;
    text-align: justify !important;
  }

  .content figure figcaption {
    font-style: normal !important;
  }

  .content {
    font-size: 1.25rem;
  }

  h1,
  h2,
  h3 {
    margin-top: 0;
  }

  figure.media.ck-widget {
    width: 80%;
    margin: auto;
  }
}
</style>
