<template>
  <section>
    <b-modal
      :active.sync="isActive"
      has-modal-card
      full-screen
      :on-cancel="closeModal"
    >
      <div class="modal-card">
        <div class="modal-card-body">
          <JourneyProviderDetails :id="journey_provider_id" />
        </div>
        <footer class="modal-card-foot">
          <button
            class="button is-primary is-outlined"
            type="button"
            @click="closeModal"
          >
            Close
          </button>
        </footer>
      </div>
    </b-modal>
  </section>
</template>

<script>
import JourneyProviderDetails from "../../messages/views/JourneyProviderDetails";
export default {
  data() {
    return {
      isActive: false
    };
  },
  props: { journey_provider_id: { required: true } },
  components: { JourneyProviderDetails },
  methods: {
    openModal() {
      this.isActive = true;
    },
    closeModal() {
      this.isActive = false;
    }
  }
};
</script>
