<template>
  <section class="section">
    <div class="is-title-bar">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <h1 class="title">
              Guest Providers
            </h1>
          </div>
        </div>
        <div class="level-right">
            <div v-if="isAdmin" class="level-item">
              <b-button @click="exportGuestProviders" class="is-primary">Export</b-button>
          </div>
          <div class="level-item">
            <router-link to="/providers/new">
              <b-button class="is-primary">
                Add guest provider
              </b-button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="level is-mobile">
      <div class="level-left">
        <div class="level-item">
          <DropdownFilter
            input-placeholder="Min 3 chars"
            title="Filters"
            :items="basicFilterItems"
            :filters="filters"
            :inputFilters="inputFilters"
            @toggleFilter="toggleFilter"
          />
        </div>
      </div>
    </div>

    <guestProvidersTable
      v-if="providers"
      :providers="providers.data"
      :meta="providers.meta"
      @pageChanged="onPageChange"
      @deletedProvider="deletedProvider"
      :meUser="meUser"
      :userRole="{ isAdmin, isOperator, isModerator }"
    ></guestProvidersTable>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import {EXPORT_PROVIDERS, PROVIDERS} from "../../../_graphql/Queries/queries";
import guestProvidersTable from "../components/GuestProvidersTable";
import DropdownFilter from "../../base/components/DropdownFilter";

export default {
  name: "GuestProviders",
  components: {
    guestProvidersTable,
    DropdownFilter
  },
  data() {
    return {
      basicFilterItems: [
        {
          name: "guest_status",
          title: "Status",
          type: "options",
          options: [
            {
              value: "DRAFT",
              title: "Draft"
            },
            {
              value: "READY_FOR_REVIEW",
              title: "Ready for review"
            },
            {
              value: "NEEDS_CORRECTIONS",
              title: "Needs corrections"
            },
            {
              value: "REVIEWED",
              title: "Reviewed"
            },
            {
              value: "INVITED",
              title: "Invited"
            },
            {
              value: "DEMO_REQUESTED",
              title: "Demo requested"
            },
            {
              value: "DEMO_SCHEDULED",
              title: "Demo scheduled"
            },
            {
              value: "REJECTED",
              title: "Rejected"
            }
          ]
        },
        {
          name: "p.name",
          title: "Name",
          type: "input"
        },
        {
          name: "co.name",
          title: "Country",
          type: "input"
        },
        {
          name: "c.name",
          title: "City",
          type: "input"
        }
      ],

      filters: {
        "co.name": "",
        "c.name": "",
        "p.name": "",
        guest_status: ""
      },
      inputFilters: {
        country: "",
        city: "",
        name: ""
      },
      queryParams: {
        limit: 25,
        offset: 0,
        filter: {},
        query: "is_guest:1",
        order: "p.id:desc"
      }
    };
  },
  apollo: {
    providers: {
      query: PROVIDERS,
      variables() {
        return {
          input: this.getQueryParams
        };
      },
      update: data => data.providers
    }
  },
  watch: {
    filters: {
      handler(newVal) {
        let newFilter = {};
        let queryFilter = "is_guest:1";
        Object.keys(newVal).forEach(item => {
          if (newVal[item] != "") {
            if (item == "guest_status") {
              queryFilter += ",guest_status:" + newVal[item];
            } else if (item == "co.name") {
              if (newVal[item].length >= 3) {
                queryFilter += ",co.name%" + newVal[item];
              }
            } else if (item == "c.name") {
              if (newVal[item].length >= 3) {
                queryFilter += ",c.name%" + newVal[item];
              }
            } else if (item == "p.name") {
              if (newVal[item].length >= 3) {
                queryFilter += ",p.name%" + newVal[item];
              }
            } else {
              newFilter[item] = newVal[item];
            }
          }
        });
        this.queryParams.filter = newFilter;
        this.queryParams.query = queryFilter;
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters({
      meUser: "meUser",
      currentPage: "currentGuestProvidersPage"
    }),
    isAdmin() {
      return this.meUser && this.meUser.user.role == "ADMINISTRATOR";
    },
    isOperator() {
      return this.meUser && this.meUser.user.role == "OPERATOR";
    },
    isModerator() {
      return this.meUser && this.meUser.user.role == "MODERATOR";
    },
    getQueryParams() {
      return {
        offset: this.currentPage
          ? 25 * (this.currentPage - 1)
          : this.queryParams.offset,
        limit: this.queryParams.limit,
        filter: this.queryParams.filter,
        query: this.queryParams.query,
        order: this.queryParams.order
      };
    }
  },
  mounted() {
    this.$apollo.queries.providers.refetch();
  },
  methods: {
    onPageChange(offset) {
      this.queryParams.offset = offset;
    },
    toggleFilter(payload) {
      const filterName = payload.filterName;
      const value = payload.optionValue;
      this.filters[filterName] =
        this.filters[filterName] != ""
          ? this.filters[filterName] == value
            ? ""
            : value
          : value;
    },
    async exportGuestProviders() {
      if (this.providers.data.length == 0) {
        return this.$buefy.toast.open({
          message: "There is no data in the table",
          type: "is-danger",
          duration: 3000
        });
      }
      await this.$apollo
          .query({
            query: EXPORT_PROVIDERS,

            variables: {
              input: {
                limit: this.getQueryParams.limit,
                offset: this.getQueryParams.offset,
                query: this.getQueryParams.query,
                order: this.getQueryParams.order,
                filter: this.getQueryParams.filter
              }
            },
            update: data => data
          })
          .catch(error => {
            return this.$buefy.toast.open({
              message: `${error}`,
              type: "is-danger"
            });
          })
          .then(({ data: { exportProviders } }) => {
            if (exportProviders) {
              this.exportLink = exportProviders;
              const link = document.createElement("a");
              link.setAttribute("href", this.exportLink);
              link.click();
            }
          });
    },
    deletedProvider() {
      this.$apollo.queries.providers.refetch();
    }
  }
};
</script>
