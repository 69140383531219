<template>
  <div>
    <b-table
      class="table is-fullwidth is-striped"
      :data="data"
      :paginated="paginated"
      :per-page="perPage"
      :current-page.sync="currentPage"
    >
      <template slot-scope="data">
        <b-table-column field="id" label="ID">
          {{ data.row.id }}
        </b-table-column>
        <b-table-column id="name" label="Name" field="name">
          {{ data.row.name }}
        </b-table-column>
        <b-table-column id="description" label="Description">
          {{ data.row.description }}
        </b-table-column>
      </template>
      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>
              <b-icon icon="emoticon-sad" size="is-large"> </b-icon>
            </p>
            <p>Nothing here.</p>
          </div>
        </section>
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  name: "dataTable",
  data() {
    return {
      perPage: 15,
      paginated: true,
      currentPage: 1,
      selectedItem: {}
    };
  },
  props: ["data"],
  mounted() {
    if (this.data && this.data.length > this.perPage) {
      this.paginated = true;
    }
  }
};
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 769px) {
  #description {
    max-width: 50%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  #name {
    width: 30%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
