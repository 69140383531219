<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">
        <p class="has-text-primary">
          Provider score links
        </p>
      </div>
    </div>
    <div class="card-content">
      <div
        v-for="linksSection in $options.PROVIDER_SCORE_LINKS"
        :key="linksSection.sectionName"
      >
        <div v-if="linksSection.blockName" class="feature-block-title">
          {{ linksSection.blockName }}
        </div>
        <div class="feature-title">{{ linksSection.sectionName }}</div>
        <div class="links-block-wrapper">
          <div
            class="links-block"
            :key="linkItem.name"
            v-for="linkItem in linksSection.sectionInputs"
          >
            <div class="field links-block-item">
              <label
                :for="linkItem.name"
                class="label links-block-label has-text-left"
              >
                {{ linkItem.title }}
              </label>
              <div class="control">
                <input
                  v-model="linkInfo[linkItem.name]"
                  autocomplete="no"
                  :id="linkItem.name"
                  class="input is-primary"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="column is-12">
      <div class="buttons is-right">
        <button @click="saveChanges" type="button" class="button is-primary">
          <span>Save</span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import RouteParamsGetters from "@/mixins/RouteParamsGetters";

const SOCIAL_API_URL =
  process.env.VUE_APP_SOCIAL_LINKS_API_URL || "https://api-staging.metime.com/";

const HTTP = axios.create();

HTTP.interceptors.request.use(req => {
  req.headers.Authorization = `Bearer ${localStorage.getItem("AUTH_TOKEN")}`;
  return req;
});

const PROVIDER_SCORE_INPUTS_NAMES = {
  GOOGLE_REVIEWS: "google-reviews",
  GOOGLE_RATING: "google-rating",
  FACEBOOK_REVIEWS: "facebook-reviews",
  FACEBOOK_RATING: "facebook-rating",
  REALSELF_REVIEWS: "realself-reviews",
  REALSELF_RATING: "realself-rating",
  TRUSTPILOTS_REVIEWS: "trustpilot-reviews",
  TRUSTPILOTS_RATING: "trustpilot-rating",
  RATEMD_REVIEWS: "ratemd-doctors-ranking",
  RATEMD_RATINGS: "ratemd-number-of-doctors",
  SCOPUS_REVIEWS: "scopus-index",
  SCOPUS_RATINGS: "scopus-number-of-docs",
  INSTAGRAM_REVIEWS: "instagram-reviews",
  INSTAGRAM_RATINGS: "instagram-ratings",
  TWITTER_REVIEWS: "twitter-reviews",
  TWITTER_RATINGS: "twitter-ratings"
};

const SOCIAL_TYPES = {
  GOOGLE: "GOOGLE",
  FACEBOOK: "FACEBOOK",
  REALSELF: "REALSELF",
  TRUSTPILOT: "TRUSTPILOT",
  RATEMDS: "RATEMDS",
  SCOPUS: "SCOPUS",
  INSTAGRAM: "INSTAGRAM",
  TWITTER: "TWITTER"
};

const MAX_RATING_TYPES = [
  SOCIAL_TYPES.GOOGLE,
  SOCIAL_TYPES.FACEBOOK,
  SOCIAL_TYPES.REALSELF,
  SOCIAL_TYPES.TRUSTPILOT
];
const MAX_RATING = 5;
const MAX_RATING_ERROR_TEXT = "Maximum rating is 5";

const PROVIDER_SCORE_LINKS = [
  {
    blockName: "Ratings and reviews",
    sectionName: "Google",
    type: SOCIAL_TYPES.GOOGLE,
    sectionInputs: [
      {
        title: "Number of Reviews",
        name: PROVIDER_SCORE_INPUTS_NAMES.GOOGLE_REVIEWS
      },
      {
        title: "Rating",
        name: PROVIDER_SCORE_INPUTS_NAMES.GOOGLE_RATING
      }
    ]
  },
  {
    sectionName: "Facebook",
    type: SOCIAL_TYPES.FACEBOOK,
    sectionInputs: [
      {
        title: "Number of Reviews",
        name: PROVIDER_SCORE_INPUTS_NAMES.FACEBOOK_REVIEWS
      },
      {
        title: "Rating",
        name: PROVIDER_SCORE_INPUTS_NAMES.FACEBOOK_RATING
      }
    ]
  },
  {
    sectionName: "Realself",
    type: SOCIAL_TYPES.REALSELF,
    sectionInputs: [
      {
        title: "Number of Reviews",
        name: PROVIDER_SCORE_INPUTS_NAMES.REALSELF_REVIEWS
      },
      {
        title: "Rating",
        name: PROVIDER_SCORE_INPUTS_NAMES.REALSELF_RATING
      }
    ]
  },
  {
    sectionName: "Trustpilot",
    type: SOCIAL_TYPES.TRUSTPILOT,
    sectionInputs: [
      {
        title: "Number of Reviews",
        name: PROVIDER_SCORE_INPUTS_NAMES.TRUSTPILOTS_REVIEWS
      },
      {
        title: "Rating",
        name: PROVIDER_SCORE_INPUTS_NAMES.TRUSTPILOTS_RATING
      }
    ]
  },
  {
    sectionName: "RateMDs",
    type: SOCIAL_TYPES.RATEMDS,
    sectionInputs: [
      {
        title: "Doctors Ranking",
        name: PROVIDER_SCORE_INPUTS_NAMES.RATEMD_REVIEWS
      },
      {
        title: "Number of doctors in the area",
        name: PROVIDER_SCORE_INPUTS_NAMES.RATEMD_RATINGS
      }
    ]
  },
  {
    blockName: "Scientific contribution",
    sectionName: "Scopus",
    type: SOCIAL_TYPES.SCOPUS,
    sectionInputs: [
      {
        title: "H-index",
        name: PROVIDER_SCORE_INPUTS_NAMES.SCOPUS_REVIEWS
      },
      {
        title: "Number of documents",
        name: PROVIDER_SCORE_INPUTS_NAMES.SCOPUS_RATINGS
      }
    ]
  },
  {
    blockName: "Public education presence",
    sectionName: "Instagram",
    type: SOCIAL_TYPES.INSTAGRAM,
    sectionInputs: [
      {
        title: "Number of Posts",
        name: PROVIDER_SCORE_INPUTS_NAMES.INSTAGRAM_REVIEWS
      },
      {
        title: "Number of followers",
        name: PROVIDER_SCORE_INPUTS_NAMES.INSTAGRAM_RATINGS
      }
    ]
  },
  {
    sectionName: "Twitter",
    type: SOCIAL_TYPES.TWITTER,
    sectionInputs: [
      {
        title: "Number of Posts",
        name: PROVIDER_SCORE_INPUTS_NAMES.TWITTER_REVIEWS
      },
      {
        title: "Number of followers",
        name: PROVIDER_SCORE_INPUTS_NAMES.TWITTER_RATINGS
      }
    ]
  }
];

export default {
  name: "ProviderScoreLinks",
  PROVIDER_SCORE_LINKS,
  mixins: [RouteParamsGetters],
  data() {
    return {
      linkInfo: Object.values(PROVIDER_SCORE_INPUTS_NAMES).reduce(
        (acc, item) => {
          acc[item] = 0;
          return acc;
        },
        {}
      ),
      linksData: []
    };
  },
  async mounted() {
    await this.getSocialLinks();
  },
  methods: {
    async getSocialLinks() {
      const { data } = await HTTP.get(
        SOCIAL_API_URL + `provider-score/get/${this.getProviderId}`
      );
      this.linksData = data;
      this.mapDataToValues();
    },
    mapDataToValues() {
      for (const link of PROVIDER_SCORE_LINKS) {
        const item = this.linksData.find(item => item.type === link.type);
        if (item) {
          const { reviewNum, ratingNum } = item;
          if (MAX_RATING_TYPES.includes(link.type) && ratingNum > MAX_RATING) {
            this.$buefy.toast.open({
              message: MAX_RATING_ERROR_TEXT,
              type: "is-danger"
            });
          }
          this.$set(this.linkInfo, link.sectionInputs[0].name, reviewNum);
          this.$set(this.linkInfo, link.sectionInputs[1].name, ratingNum);
        }
      }
    },
    async sendValues() {
      let isNoErrors = true;
      for (const link of PROVIDER_SCORE_LINKS) {
        const dataObj = {
          type: link.type,
          reviewNum: parseInt(this.linkInfo[link.sectionInputs[0].name]),
          ratingNum: parseInt(this.linkInfo[link.sectionInputs[1].name])
        };
        const scoreLink = this.linksData.find(item => link.type === item.type);
        let response;
        if (scoreLink) {
          dataObj.id = scoreLink.id;
          response = await this.updateLink(dataObj);
        } else {
          response = await this.createLink(dataObj);
        }
        if (response && response.data && response.data.errors) {
          isNoErrors = false;
          response.data.errors.forEach(error => {
            this.$buefy.toast.open({
              message: error,
              type: "is-danger"
            });
          });
        }
      }
      await this.getSocialLinks();
      return isNoErrors;
    },
    createLink(dataObj) {
      return HTTP.post(
        SOCIAL_API_URL + `provider-score/create/${this.getProviderId}`,
        dataObj
      );
    },
    updateLink(dataObj) {
      return HTTP.patch(
        SOCIAL_API_URL +
          `provider-score/patch/${this.getProviderId}/${dataObj.id}`,
        dataObj
      );
    },
    async saveChanges() {
      const isNoErrors = await this.sendValues();
      if (isNoErrors) {
        this.$buefy.toast.open({
          message: "Provider score links updated successfully",
          type: "is-success"
        });
      }
    }
  }
};
</script>
<style scoped lang="scss">
.feature-title {
  text-align: left;
  font-size: 1.25rem;
  color: #242424;
  font-weight: 500;
  margin-bottom: 1.125rem;
}

.feature-block-title {
  text-align: left;
  font-size: 1.5rem;
  color: #242424;
  font-weight: 600;
  margin-bottom: 1.3rem;
}

.links-block {
  display: flex;
  gap: 1.25rem;
  width: 100%;

  &-wrapper {
    display: flex;
    gap: 1.25rem;
    margin-bottom: 1.875rem;
  }
}

@media screen and (max-width: 768px) {
  .links-block-wrapper {
    display: block;
  }
}

.links-block-item {
  width: 100%;
}

.links-block-label {
  font-weight: 500;
}
.card {
  width: 100%;
}
</style>
