<template>
  <div class="card">
    <header class="card-header">
      <div class="card-header-title">
        <p class="has-text-primary">
          <b-icon icon="image" custom-size="default" />
          Photos
        </p>
        <div v-if="showBtns">
          <b-button class="is-primary" @click="addImage">
            Add
          </b-button>
        </div>
      </div>
    </header>
    <div class="card-content">
      <p v-if="!is_subscribed" class="photo-description has-text-left">
        Upload photos to enhance your profile. Unlock up to 10 display photos
        with <a href="/subscriptions" target="_blank">Premium</a> and 20 with
        <a href="/subscriptions" target="_blank">Pro</a>. Simply drag the photos
        to reorder them.
      </p>
      <p
        v-if="is_subscribed && subscriptionPlan === 'Premium'"
        class="photo-description has-text-left"
      >
        Upload up to 20 photos to enhance your profile. Unlock 10 more display
        photos with <a href="/subscriptions" target="_blank">Pro</a>. Simply
        drag the photos to reorder them.
      </p>
      <p
        v-if="is_subscribed && subscriptionPlan === 'Pro'"
        class="photo-description has-text-left"
      >
        Upload and display up to 20 photos. Simply drag the photos to reorder
        them.
      </p>
      <ValidationObserver ref="orderProviderPhotos"> </ValidationObserver>
      <b-field v-if="providerPhotos && providerPhotos.length > 0">
        <draggable
          :list="providerPhotos"
          class="columns is-multiline list-group"
          ghost-class="ghost"
          @start="dragging = true"
          @end="
            () => {
              dragging = false;
              saveOrder();
            }
          "
        >
          <div
            v-for="(photo, index) in providerPhotos"
            :key="photo.id"
            class="list-group-item column is-6-mobile is-6-tablet is-3-desktop photo-column"
          >
            <figure
              v-if="index < getMaxAssetsNumber(subscriptionPlan)"
              class="image"
            >
              <img :src="photo.url" @error="imageLoadError" />
              <a v-if="showBtns" @click.prevent="confirmDelete(photo)" class=""
                ><b-icon icon="delete"
              /></a>
            </figure>
            <div v-else>
              <b-tooltip
                style="
                  display: block;
                  position: relative;"
                label="This image will not be displayed due to the limits of your subscription plan. Upgrade to display"
                multilined
                position="is-left"
              >
                <figure class="image">
                  <img
                    class="image-overlay"
                    :alt="photo.id"
                    :src="photo.url"
                    @error="imageLoadError"
                  />
                  <a
                    v-if="showBtns"
                    @click.prevent="confirmDelete(photo)"
                    class=""
                  >
                    <b-icon icon="delete" />
                  </a>
                </figure>
              </b-tooltip>
            </div>
          </div>
        </draggable>
      </b-field>
    </div>
    <AddImage @saveImage="saveImage" ref="addImage"></AddImage>
  </div>
</template>

<script>
import AddImage from "./AddImage";
import {
  UPDATE_PROVIDER,
  DELETE_PROVIDER_PHOTO
} from "../../../_graphql/Mutations/mutations";
import { PROVIDER } from "../../../_graphql/Queries/queries";
import draggable from "vuedraggable";

export default {
  data() {
    return {
      image: null,
      providerPhotos: [],
      photosList: [],
      defaultImage:
        "https://www.ice-shop.dk/media/catalog/product/cache/1/image/378x380/9df78eab33525d08d6e5fb8d27136e95/placeholder/default/no_image_placeholder_6.png",
      maxNumberPhotos: 20,
      dragging: false
    };
  },
  components: {
    AddImage,
    draggable
  },
  props: {
    photos: {
      default: Array
    },
    is_subscribed: {
      required: true
    },
    userRole: {
      default: ""
    },
    allowUpdate: {
      default: false
    },
    subscriptionPlan: {
      type: String,
      default: "Basic"
    }
  },
  computed: {
    showBtns() {
      return this.allowUpdate;
    },
    getProviderPhotos: {
      get: function() {
        return this.photos
          .map(photo => photo)
          .sort((a, b) => a.position - b.position);
      },
      set: function(newVal) {
        this.photosList = newVal;
      }
    }
  },
  watch: {
    getProviderPhotos: {
      handler: function(newVal) {
        this.providerPhotos = newVal;
      },
      immediate: true
    }
  },
  methods: {
    async saveOrder() {
      this.$refs.orderProviderPhotos.validate().then(async success => {
        if (!success) {
          this.refName = "orderProviderPhotos";
          this.handleErrors();
          return;
        }

        const newPositions = this.providerPhotos.map((photo, index) => {
          return {
            thumb_url: photo.url,
            width: photo.width,
            height: photo.height,
            url: photo.url,
            id: photo.id,
            position: photo.position !== index + 1 ? index + 1 : photo.position
          };
        });

        const mutation = UPDATE_PROVIDER;
        const variables = {
          id: parseInt(this.$route.params.providerId),
          input: {
            photos: newPositions
          }
        };

        const payload = {
          mutation: mutation,
          queryName: "updateProvider"
        };

        this.$store.commit("provider/collection/editModel", variables);
        await this.$store.dispatch("provider/collection/update", payload);
        this.$emit("addedPhoto");
      });
    },
    getMaxAssetsNumber(plan) {
      if (plan === "Pro") {
        return this.maxNumberPhotos;
      }

      if (plan === "Premium") {
        return 10;
      }

      return 3;
    },
    addImage() {
      if (this.photos.length >= this.maxNumberPhotos) {
        this.image = null;
        return this.$buefy.toast.open({
          message: `You can add up to ${this.maxNumberPhotos} images!`,
          type: "is-warning"
        });
      }
      this.$refs.addImage.openModal("image");
    },
    async saveImage(data) {
      const mutation = UPDATE_PROVIDER;
      const query = PROVIDER;
      const variables = {
        id: parseInt(this.$route.params.providerId),
        input: {
          photos: {
            url: data.image.url,
            thumb_url: data.image.url,
            width: data.image.width,
            height: data.image.height
          }
        }
      };
      const payload = {
        mutation: mutation,
        query: query,
        queryName: "updateProvider"
      };
      this.$store.commit("provider/collection/editModel", variables);
      await this.$store.dispatch("provider/collection/update", payload);
      this.$emit("addedPhoto");
      this.$emit("savedChanges");
    },
    addedPhoto() {
      this.$emit("addedPhoto");
    },
    confirmDelete(photo) {
      this.$buefy.dialog.confirm({
        title: "Deleting ",
        message:
          "Are you sure you want to <b>delete</b> this photo?" +
          " This action cannot be undone.",
        confirmText: "Delete",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.deletePhoto(photo)
      });
    },
    async deletePhoto(photo) {
      if (photo) {
        const mutation = DELETE_PROVIDER_PHOTO;
        const variables = {
          id: parseInt(photo.id)
        };

        this.$store.commit("provider/collection/deleteModel", variables);
        await this.$store.dispatch("provider/collection/delete", mutation);
        this.$emit("deletedPhoto");
      }
    },
    imageLoadError(event) {
      event.target.src = this.defaultImage;
    }
  }
};
</script>

<style lang="scss" scoped>
.order-btn {
  margin-right: 10px;
}

.photo-description {
  margin-bottom: 20px;
}

.card-header-title {
  justify-content: space-between;
}

@media (max-width: 575px) {
  .photo-column {
    width: 100% !important;
  }
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.list-group {
  min-height: 100px;
}
.list-group-item {
  cursor: move;
}
.list-group-item i {
  cursor: pointer;
}
.ghost {
  opacity: 0.5;
  background: #e6eff2;
}
.image-overlay {
  opacity: 0.95;
  filter: blur(4px) grayscale(85%);
  -webkit-filter: blur(4px) grayscale(85%);
}

.photo-column {
  img {
    margin-bottom: 0.5rem;
  }
}
</style>
