<template>
  <div class="card">
    <header class="card-header">
      <p class="card-header-title has-text-primary">
        <b-icon icon="link" />Invite Users to connect directly with you
      </p>
    </header>
    <div class="columns card-content is-multiline">
      <b-field v-if="provider.is_guest" class="column is-12 has-text-left">
        <div class="title warning-title is-6">
          This section will be available once the provider is registered.
        </div>
      </b-field>
      <b-field class="column is-12 has-text-left">
        <div class="column is-12-mobile is-12-tablet has-text-left">
          <span
            >By generating the unique link and snippet below, you enable Users
            of MeTime to create a Journey through the app but they will match
            only with your clinic. You can share the URL on any social media
            channel or via email. Use the HTML snippet to add a clickable badge
            to your website or even add it to your clinic email signature.</span
          >
        </div>
      </b-field>
      <b-field class="column is-12 has-text-left customFieldStyles">
        <div class="column is-12-mobile is-12-tablet has-text-left">
          <label>
            Select the image to display when sharing URL to invite users to
            MeTime.
          </label>
          <div class="snippetImagesStyle columns is-multiline mt-3">
            <div
              class=" customRadioStyle card column is-10-mobile is-10-tablet is-10-desktop is-4-fullhd "
              v-for="(img, index) in externalLinkImages"
              :key="index"
            >
              <b-radio
                :disabled="provider.is_guest"
                v-model="external_link"
                name="external_link"
                :native-value="img.src"
              >
                <img :src="img.src" alt="" class="link-image" />
              </b-radio>
            </div>
          </div>
        </div>
      </b-field>
      <b-field class="column is-12 has-text-left">
        <div class="column is-12-mobile is-12-tablet has-text-left">
          <label>
            Copy and paste the URL to invite users to MeTime and create journeys
            that match only with your clinic!
          </label>
          <div class="column button-generate is-12 urlLink">
            <b-tooltip
              :label="tooltipButtons"
              :active="disableButtons"
              multilined
            >
              <b-button
                :disabled="disableButtons"
                class="is-primary"
                @click="GenerateExternalLink('link')"
              >
                Copy URL link
              </b-button>
            </b-tooltip>
          </div>
        </div>
      </b-field>
      <hr class="is-divider hr" />
      <b-field class="column is-12 has-text-left customFieldStyles">
        <div class="column is-12-mobile is-12-tablet has-text-left">
          <label>
            Select the image to display for the HTML snippet.
          </label>
          <div class="snippetImagesStyle columns is-multiline">
            <div
              class="customRadioStyle card column is-10-mobile is-10-tablet is-10-desktop is-4-fullhd "
              v-for="(img, index) in externalSnippetImages"
              :key="index"
            >
              <b-radio
                :disabled="provider.is_guest"
                v-model="external_snippet"
                name="external_snippet"
                :native-value="img.src"
              >
                <sub class="help-image-text">
                  {{
                    img.src.includes(".png")
                      ? "transparent background"
                      : "white background"
                  }}
                </sub>

                <img :src="img.src" alt="" class="snippet-image" />
              </b-radio>
            </div>
          </div>
        </div>
      </b-field>
      <b-field class="column is-12 has-text-left">
        <div class="column is-12-mobile is-12-tablet has-text-left">
          <label>
            Copy and paste the HTML snippet into your webpage or email signature
            to get an image with link that initiates a Journey in MeTime and
            matches directly with your clinic!
          </label>
          <div class="column button-generate is-12 urlLink">
            <b-tooltip
              :label="tooltipButtons"
              multilined
              :active="disableButtons"
            >
              <b-button
                :disabled="disableButtons"
                class="is-primary"
                @click="GenerateExternalLink('snippet')"
              >
                Copy HTML snippet
              </b-button>
            </b-tooltip>
          </div>
        </div>
      </b-field>
    </div>
  </div>
</template>

<script>
import { GENERATE_PROVIDER_EXTERNAL_URL } from "../../../_graphql/Queries/queries";

export default {
  data() {
    return {
      tooltipButtons:
        "Access to this feature is limited until your profile is approved by the administrator",
      external_link: "https://metime.com/share-2.jpg",
      external_snippet: "https://metime.com/here-for-you.jpg",
      externalLinkImages: [
        { src: "https://metime.com/share-2.jpg" },
        { src: "https://metime.com/share-3.jpg" },
        { src: "https://metime.com/share-1.jpg" }
      ],
      externalSnippetImages: [
        { src: "https://metime.com/here-for-you.jpg" },
        { src: "https://metime.com/here-for-you.png" },
        { src: "https://metime.com/here-for-you-2.jpg" }
      ]
    };
  },
  props: ["provider"],
  computed: {
    disableButtons() {
      return (
        this.provider && (this.provider.status !== 1 || this.provider.is_guest)
      );
    }
  },
  methods: {
    doCopy(link) {
      const elem = document.createElement("textarea");
      elem.value = link;
      document.body.appendChild(elem);
      elem.select();
      const copiedUrl = document.execCommand("copy");
      document.body.removeChild(elem);
      if (copiedUrl) {
        this.$buefy.toast.open({
          message: "Matching Url has been copied to the clipboard",
          type: "is-success",
          duration: 2500
        });
      }
    },
    doCopySnippet(link) {
      const copyText =
        '<a href="' +
        link +
        '" target="_self" title="Connect with me on metime"><img src="' +
        this.external_snippet +
        '" alt="Connect with me on metime" width="260"></a>';
      const elem = document.createElement("textarea");
      elem.value = copyText;
      document.body.appendChild(elem);
      elem.select();
      const copiedSnippet = document.execCommand("copy");
      document.body.removeChild(elem);
      if (copiedSnippet) {
        this.$buefy.toast.open({
          message: "Matching HTML snippet has been copied to the clipboard",
          type: "is-success",
          duration: 2500
        });
      }
    },
    GenerateExternalLink(value) {
      if (!this.disableButtons) {
        this.$apollo
          .query({
            query: GENERATE_PROVIDER_EXTERNAL_URL,
            variables: {
              id: this.provider.uuid,
              img_url:
                value == "link" ? this.external_link : this.external_snippet
            }
          })
          .then(({ data }) => {
            let link = data.generateProviderExternalUrl.shortLink;
            value == "link" ? this.doCopy(link) : this.doCopySnippet(link);
          });
      }
    }
  }
};
</script>

<style scoped>
.card-header-title {
  align-items: flex-start;
}

.customFieldStyles {
  margin-bottom: 0;
  padding-bottom: 0;
}

.snippetImagesStyle {
  display: flex;
  justify-content: flex-start;
  margin-top: 0.5rem;
}

.hr {
  width: 100%;
}

.urlLink {
  padding-left: 0px;
}

.link-image {
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.06);
  width: 170px;
  height: 170px;
  object-fit: cover;
}

.snippet-image {
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.06);
  width: 180px;
  height: 170px;
  object-fit: contain;
}

.help-image-text {
  text-align: center;
  position: absolute;
  top: 0.5rem;
  width: 85%;
}

@media (min-width: 1408px) {
  .button-generate {
    text-align: right;
  }
}

.warning-title {
  color: #f18a6c;
  padding-left: 12px;
}

.b-radio.radio[disabled] {
  opacity: 0.7 !important;
}

.button.is-primary {
  width: 9.5rem !important;
}
</style>
