<template>
  <div class="section">
    <notification type="light" v-if="isVendor">
      <template v-slot:icon>
        <img src="@/assets/img/icon-10.png" class="icon is-large" />
      </template>
      <template v-slot:message>
        These are your archived messages. You can view them but you cannot
        unarchive them or chat to a user from archived chats.
      </template>
    </notification>
    <h1 class="title has-text-left">
      <span class="icon">
        <i class="fas fa-inbox" />
      </span>
      Inbox
    </h1>
    <div class="container" style="width:100%;max-width:3400px;">
      <ProvidersListTable />
    </div>
  </div>
</template>

<script>
import ProvidersListTable from "../components/ProvidersListTable";
import Notification from "../../base/components/Notification";
import { createNamespacedHelpers } from "vuex";

const { mapMutations } = createNamespacedHelpers("messages");

export default {
  components: { ProvidersListTable, Notification },
  computed: {
    isVendor() {
      const meUser = this.$store.state.meUser;
      return meUser && meUser.user.role == "VENDOR";
    }
  },
  methods: {
    ...mapMutations(["setMessagesStatusFilter"])
  },
  created() {
    this.setMessagesStatusFilter(
      this.$route.params.status == "active" ? "OPEN" : "!OPEN"
    );
  }
};
</script>

<style lang="scss" scoped>
h1 {
  background: none #e6eff2;
  padding: 20px 25px;
  color: #444;
  text-shadow: -2px 2px 0px #fff;
  border-radius: 10px 10px 0px 0px;
  border-bottom: 1px solid #8c9bad;
  margin: 0 !important;
}
// #noteMessages {
//   background-color: #e6eff2;
//   color: #0ca1d1;
// }
.icon-11 {
  height: 2rem !important;
  width: 2rem !important;
  margin: 0 0.5rem;
}
</style>
