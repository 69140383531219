<template>
  <div>
    <h4 class="title">
      Offer for {{ data.treatments.length }}
      {{ data.treatments.length === 1 ? "treatment" : "treatments" }}:
    </h4>
    <table class="offer">
      <tr :key="treatment.name" v-for="treatment in data.treatments">
        <td class="label">{{ treatment.name }}</td>
        <td class="price">{{ treatment.price }}</td>
      </tr>
      <tr v-if="data.discount > '0.0 €'">
        <td class="label bold">Discount:</td>
        <td class="price">{{ data.discount }}</td>
      </tr>
      <tr>
        <td class="label bold"><b>Price in total:</b></td>
        <td class="price">
          <b>{{ data.total }}</b>
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
export default {
  name: "bubble-offer",
  props: ["data"]
};
</script>
<style scoped>
h4.title {
  font-size: 1rem;
  padding: 15px 0;
  width: 100%;
}
.offer {
  width: 100%;
}

.offer td {
  padding: 3px;
}

.offer tr {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #cecece;
}
.label {
  text-align: left;
  font-weight: 100;
  color: #22b2dc;
}

.label.bold {
  font-weight: bold;
}

.price {
  text-align: right;
  min-width: 20%;
}
</style>
