<template>
  <div>
    <b-loading
      :active.sync="isLoading"
      :is-full-page="false"
      :can-cancel="false"
    >
      <b-icon pack="fas" icon="sync-alt" size="is-large" custom-class="fa-spin">
      </b-icon>
    </b-loading>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ApolloLoader",
  computed: {
    ...mapGetters(["isLoading"])
  },
  props: {
    loading: {
      require: false
    }
  }
};
</script>

<style scoped></style>
