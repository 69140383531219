<template>
  <div>
    <b-table
      class="table is-fullwidth"
      :data="providers"
      paginated
      :current-page="meta.current_page"
      :per-page="meta.per_page"
      default-sort="name"
      :hoverable="true"
      backend-pagination
      :total="meta.total_count"
      @page-change="onPageChange"
    >
      <template slot-scope="data">
        <b-table-column v-if="isAdmin" label="Nr.">
          {{ data.row.id }}
        </b-table-column>
        <b-table-column label="Type" v-if="isAdmin">
          <span :class="{ 'has-text-success': data.row.guest_status }">
            {{ data.row.guest_status ? "Converted guest" : "Regular" }}
          </span>
        </b-table-column>
        <b-table-column label="Name" field="name">
          {{ data.row.name }}
        </b-table-column>
        <b-table-column label="Avatar" field="avatar">
          <div class="container">
            <img
              @error="handleImageError"
              alt="avatar image"
              v-if="data.row.avatar_url"
              id="avatar"
              :src="data.row.avatar_url"
            />
          </div>
        </b-table-column>
        <b-table-column label="Country" field="address.locale.country.name">
          {{ data.row.address.locale.country.name }}
        </b-table-column>
        <b-table-column label="City" field="address.locale.city.name">
          {{ data.row.address.locale.city.name }}
        </b-table-column>
        <b-table-column label="Address" field="address">
          {{ data.row.address.address }}
        </b-table-column>
        <b-table-column id="actions">
          <div class="buttons is-right">
            <router-link :to="'/providers/edit/' + data.row.id">
              <b-button class="is-primary is-outlined">view/edit</b-button>
            </router-link>
            <b-button
              v-if="isAdmin"
              class="is-danger is-outlined"
              @click="confirmDeleteAdmin(data.row)"
              >delete</b-button
            >
            <b-button
              v-else-if="isVendor"
              class="is-danger is-outlined"
              @click="confirmDelete(data.row)"
              >delete</b-button
            >
          </div>
        </b-table-column>
      </template>
      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>
              <b-icon icon="emoticon-sad" size="is-large"> </b-icon>
            </p>
            <p>Nothing here.</p>
          </div>
        </section>
      </template>
    </b-table>
  </div>
</template>

<script>
import {
  DELETE_PROVIDER,
  DELETE_PROVIDER_BY_ADMIN
} from "../../../_graphql/Mutations/mutations";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      selectedItem: {},
      default_avatar: "",
      errorImage: false
    };
  },
  props: {
    providers: {
      default: Array
    },
    meta: {
      default: {}
    }
  },
  computed: {
    ...mapGetters({
      userRole: "userRole",
      currentPage: "currentAllProvidersPage"
    }),
    isVendor() {
      return this.userRole && this.userRole == "VENDOR";
    },
    isAdmin() {
      return this.userRole && this.userRole == "ADMINISTRATOR";
    }
  },
  methods: {
    handleImageError(event) {
      event.target.style = "  box-shadow: unset";
    },
    onPageChange(page) {
      const offset = this.meta.per_page * (page - 1);
      this.$store.state.currentAllProvidersPage = page;
      this.$emit("pageChanged", offset);
    },
    confirmDeleteAdmin(data) {
      this.$buefy.dialog.prompt({
        title: "Deleting provider " + data.name,
        message: `Enter reason for deleting this provider`,
        inputAttrs: {
          placeholder: "Describe the reason for deleting provider",
          type: "textarea",
          min: 10,
          maxlength: 300
        },
        trapFocus: true,
        onConfirm: value =>
          this.deleteProviderAdmin({ id: data.id, reason: value })
      });
    },
    confirmDelete(data) {
      this.$buefy.dialog.confirm({
        title: "Deleting ",
        message:
          "Are you sure you want to <b>delete</b> " +
          data.name +
          "? This action cannot be undone.",
        confirmText: "Delete",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.deleteProvider(data.id)
      });
    },

    async deleteProvider(id) {
      const mutation = DELETE_PROVIDER;
      const variables = {
        id: parseInt(id)
      };
      this.$store.commit("provider/collection/deleteModel", variables);
      await this.$store.dispatch("provider/collection/delete", mutation);
      this.$emit("deletedProvider");
    },
    async deleteProviderAdmin(data) {
      const mutation = DELETE_PROVIDER_BY_ADMIN;
      const variables = {
        id: parseInt(data.id),
        reason: data.reason
      };
      this.$store.commit("provider/collection/deleteModel", variables);
      await this.$store.dispatch("provider/collection/delete", mutation);
      this.$emit("deletedProvider");
    }
  }
};
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 769px) {
  #actions {
    width: 30% !important;
  }
}
.container {
  width: 100px;
  height: 100px;
}
#avatar {
  width: 100px;
  height: 100px;
  object-fit: contain;
  border-radius: 50%;
  text-align: center;
}

img {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.09);
}
</style>
