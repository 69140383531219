<template>
  <div class="hello" ref="chartdiv"></div>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import _ from "lodash";

am4core.useTheme(am4themes_animated);

export default {
  name: "HelloWorld",
  props: {
    chartData: {
      required: true
    },
    series: {
      required: true
    }
  },
  mounted() {
    let chart = am4core.create(this.$refs.chartdiv, am4charts.XYChart);

    chart.logo.visible = false;

    if (this.chartData) {
      chart.data.push(...this.chartData);
    }

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.tooltip.disabled = true;
    valueAxis.renderer.minWidth = 35;

    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.minGridDistance = 50;
    dateAxis.dateFormats.setKey("month", "MMM ");

    dateAxis.periodChangeDateFormats.setKey("minute", "[bold]h:mm a");
    dateAxis.periodChangeDateFormats.setKey("month", "[bold]yyyy ");
    dateAxis.skipEmptyPeriods = true;
    dateAxis.renderer.inside = true;

    function createSeries(name, value, date) {
      let series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.dateX = date;
      series.dataFields.valueY = value;
      series.name = name;
      series.tooltipText = "{name}: [bold]{valueY}[/]";
      series.strokeWidth = 3;
      series.tensionX = 1;
      series.tensionY = 1;
      series.propertyFields.stroke = chart.colors.next();
      series.bullets.push(new am4charts.CircleBullet());

      series.legendSettings.labelText = name;

      chart.cursor = new am4charts.XYCursor();
      let scrollbarX = new am4charts.XYChartScrollbar();
      scrollbarX.series.push(series);
      chart.scrollbarX = scrollbarX;

      return series;
    }

    _.forEach(this.series, series => {
      createSeries(series.name, series.value, series.date);
    });

    let label = chart.createChild(am4core.Label);
    label.text = "Data updates in every 10 min.";
    label.fontSize = 10;
    label.align = "right";
    label.paddingTop = 30;
    label.marginBottom = 0;

    chart.legend = new am4charts.Legend();
    chart.legend.useDefaultMarker = true;
    if (this.chartData.length == 0) {
      let label = chart.createChild(am4core.Label);
      label.text = "The chart contains no data";
      label.fontSize = 10;
      label.align = "center";
      label.textAlign = "middle";
      label.opacity = 50;
      label.paddingTop = -250;
    }
    this.chart = chart;
  },

  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  }
};
</script>

<style scoped>
.hello {
  width: 100%;
  height: 500px;
}
</style>
