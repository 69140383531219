<template>
  <section class="section">
    <title-bar class="edit-article-title">
      Edit Article
      <span class="has-text-primary has-text-weight-normal is-size-5 is-draft">
        {{ article && article.draft ? " (draft)" : " (published)" }}
      </span>
      <router-link
        v-if="isAdmin"
        to="/"
        class="button is-primary"
        slot="button"
      >
        Dashboard
      </router-link>
    </title-bar>

    <ArticleForm
      v-if="article"
      @formSubmit="editArticle"
      :editModel="article ? article : null"
      :resetSubmitSwitch="resetSubmitSwitch"
    />
  </section>
</template>

<script>
import TitleBar from "../../base/components/TitleBar";
import ArticleForm from "../components/ArticleForm";
import { ARTICLE } from "../../../_graphql/Queries/queries";
import { UPDATE_ARTICLE } from "../../../_graphql/Mutations/mutations";
import { mapGetters } from "vuex";

export default {
  name: "EditArticle",
  components: { TitleBar, ArticleForm },
  data() {
    return {
      resetSubmitSwitch: false
    };
  },
  computed: {
    ...mapGetters(["userRole"]),
    isAdmin() {
      return this.userRole && this.userRole == "ADMINISTRATOR";
    }
  },
  methods: {
    editArticle(articleForm) {
      const mutation = UPDATE_ARTICLE;
      const variables = {
        id: this.$route.params.id,
        file: articleForm.image,
        input: {
          name: articleForm.name,
          slug: articleForm.slug,
          title: articleForm.title,
          content: articleForm.content,
          description: articleForm.description,
          meta_title: articleForm.meta_title,
          blog_type: articleForm.blog_type.length
            ? articleForm.blog_type
            : null,
          meta_keywords: articleForm.meta_keywords.join(","),
          meta_description: articleForm.meta_description,
          draft: articleForm.draft,
          reading_time: articleForm.reading_time
        }
      };
      this.$apollo.provider.defaultClient
        .mutate({
          mutation: mutation,
          variables: variables
        })
        .catch(error => {
          return error;
        })
        .then(data => {
          const updateArticle = data.data ? data.data.updateArticle : false;
          if (updateArticle) {
            this.$buefy.toast.open({
              message: "Article updated successfully.",
              type: "is-success",
              duration: 1500
            });
            this.$buefy.toast.open({
              message: "Redirecting...",
              type: "is-info",
              duration: 1500
            });
            setTimeout(() => {
              this.$router.push("/articles");
            }, 3000);
          } else {
            this.$buefy.toast.open({
              message: "Something went wrong.",
              type: "is-danger",
              duration: 2000
            });
            setTimeout(() => {
              this.resetSubmitSwitch = !this.resetSubmitSwitch;
            }, 4500);
          }
        });
    }
  },
  apollo: {
    article: {
      query: ARTICLE,
      variables() {
        return {
          id: this.$route.params.id
        };
      }
    }
  }
};
</script>
