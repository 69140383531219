<template>
  <div>
    <vue-tags-input
      :placeholder="placeholder"
      v-model="tag"
      :separators="separators"
      :tags="tags"
      :avoid-adding-duplicates="avoidDuplicates"
      :allow-edit-tags="allowEditTags"
      :validation="validation"
      :autocomplete-items="filteredItems"
      :autocomplete-min-length="autocompleteMinLength"
      :delete-on-backspace="deleteOnBackspace"
      @saving-duplicate="checkDuplicates"
      @before-adding-tag="createNewTag"
      @before-saving-tag="updateTag"
      @before-editing-tag="isTagEditable"
      @before-deleting-tag="deleteTag"
      :disabled="!allowEditTags"
    />
  </div>
</template>
<script>

export default {
  name: 'TagsInput',
  data() {
    return {
      tag: '',
      isDuplicate: false,
      autocompleteMinLength: 0,
      deleteOnBackspace: false,
      separators: ['-------------'], // ToDo: change with better solution; currently there is no option to disable multiple tag inputs separated by key
      validation: [{
        classes: 'min-length',
        rule: tag => tag.text.length > 255,
        disableAdd: true,
      }]
    };
  },
  props: {
    allowEditTags: {
      type: Boolean,
      required: false,
      default: false
    },
    avoidDuplicates: {
      type: Boolean,
      required: false,
      default: true
    },
    itemTags: {
      type: Array,
      required: false
    },
    autocompleteList: {
      type: Array,
      default: () => [],
      required: false
    },
    placeholder: {
      type: String,
      default: '',
      required: false
    },
    categoryId: {
      type: Number,
      default: null,
      required: false
    },
    createItem: {
      type: Function,
      required: false
    },
    updateItem: {
      type: Function,
      required: false
    },
    deleteItem: {
      type: Function,
      required: false
    }
  },
  computed: {
    tags() {
      return this.processTags(this.itemTags);
    },
    filteredItems() {
      return this.autocompleteList.map(item => {
        return {text: item.name, ...item}
      }).filter(i => {
        return i.text.toLowerCase().startsWith(this.tag.toLowerCase());
      }).sort((a, b) => {
        const aL = a.text.toLowerCase();
        const bL = b.text.toLowerCase();

        if (aL > bL) {
          return 1;
        } else if (aL < bL) {
          return -1;
        } else {
          return 0;
        }
      });
    }
  },
  methods: {
    processTags(itemTags) {
      return itemTags.map(item => {
        const newItem = {
          id: item.id,
          text: item.name,
          name: item.name,
          isSystemOwned: false,
          classes: !this.avoidDuplicates ? 'custom-class-allow-duplicates' : 'custom-class'
        }

        if (item && item.hasOwnProperty('provider_id') && item.provider_id == null) {
          newItem.isSystemOwned = true;
        }

        if (item && item.hasOwnProperty('product_id') && item.product_id != null) {
          newItem.product_id = item.product_id;
        }

        if (item && item.hasOwnProperty('technology_id') && item.technology_id != null) {
          newItem.technology_id = item.technology_id;
        }

        return newItem;
      });
    },
    createNewTag({ tag, addTag }) {
      if (!this.allowEditTags) {
        return;
      }

      if (tag.text.length <= 255) {
        this.createItem(tag, this.categoryId, addTag)

      } else {
        this.$buefy.toast.open({
          message: "Item is not valid. Max length reached.",
          type: "is-danger",
          duration: 1500
        });
      }
    },
    isTagEditable({ tag, editTag }) {
      if (!this.allowEditTags) {
        return;
      }

      if (!tag.isSystemOwned) {
        editTag();
      }
    },
    updateTag({ tag, saveTag }) {
      if (!this.allowEditTags) {
        return;
      }

      if (tag && !tag.hasOwnProperty('id')) {
        return;
      }

      if (tag.text.length <= 255) {
        const updatedTag = {
          id: tag.id,
          name: tag.text
        }

        if (tag && tag.hasOwnProperty('product_id') && tag.product_id != null) {
          updatedTag.product_id = tag.product_id;
        }

        if (tag && tag.hasOwnProperty('technology_id') && tag.technology_id != null) {
          updatedTag.technology_id = tag.technology_id;
        }

        saveTag();

        if (!this.isDuplicate) {
          this.updateItem(updatedTag, this.categoryId)
        } else {
          this.isDuplicate = false;
        }
      } else {
        this.$buefy.toast.open({
          message: "Item is not valid. Max length reached.",
          type: "is-danger",
          duration: 1500
        });
      }
    },
    deleteTag({ tag, deleteTag }) {
      if (!this.allowEditTags) {
        return;
      }

      if (tag && tag.hasOwnProperty('id')) {
        this.deleteItem(tag);
      }
      deleteTag();
    },
    checkDuplicates() {
      this.isDuplicate = true

      this.$buefy.toast.open({
        message: "Duplicate item found",
        type: "is-danger",
        duration: 1500
      });
    },
  }
};
</script>
<style lang="css">
/* style the background and the text color of the input ... */
.vue-tags-input {
  max-width: 100%!important;
}

.vue-tags-input .ti-new-tag-input {
  background: transparent;
  color: #4f4f4f;
}

.vue-tags-input .ti-input {
  padding: 4px 0;
  width: 100%;
  transition: border-bottom 200ms ease;
}

.vue-tags-input .ti-input {
  border: none!important;
}

/* we cange the border color if the user focuses the input */
/*.vue-tags-input.ti-focus .ti-input {*/
/*  border: 1px solid #d10b50;*/
/*}*/

/* some stylings for the autocomplete layer */
.vue-tags-input .ti-autocomplete {
  border: 1px solid #ccc!important;
  position: absolute;
  max-height: 300px!important;
  height: auto;
  background-color: #fff;
  z-index: 20;

  overflow: auto!important;
  overflow-y: scroll!important;;
  scroll-behavior: smooth!important;;
  /*scrollbar-color: #fff!important;*/
}

/* the selected item in the autocomplete layer, should be highlighted */
.vue-tags-input .ti-item.ti-selected-item {
  background: #e6eff2;
  color: #0ba1d1;
}

/* style the placeholders color across all browser */
.vue-tags-input ::-webkit-input-placeholder {
  color: #a4b1b6;
}

.vue-tags-input ::-moz-placeholder {
  color: #a4b1b6;
}

.vue-tags-input :-ms-input-placeholder {
  color: #a4b1b6;
}

.vue-tags-input :-moz-placeholder {
  color: #a4b1b6;
}

/* default styles for all the tags */
.vue-tags-input .ti-tag {
  position: relative;
  background: #e6eff2;
  color: #0ba1d1;
}

.vue-tags-input .ti-tag-input{
  font-size: 16px;
}

/* we defined a custom css class in the data model, now we are using it to style the tag */
.vue-tags-input .ti-tag.ti-valid{
  background: #e6eff2;
  color: #0ba1d1;
  margin-right: 4px;
  border-radius: 4px;
  font-size: 16px;
  font-family: "IBM Plex Sans", sans-serif!important;
  padding: 7px 9px;
}

.ti-tag span.ti-hidden {
  white-space: unset!important;
  line-height: 1.25em!important;
}

/* the styles if a tag is invalid */
.vue-tags-input .ti-tag.ti-invalid {
  margin-right: 4px;
  border-radius: 4px;
  font-size: 16px;
  font-family: "IBM Plex Sans", sans-serif!important;
  padding: 7px 9px;
  background-color: #ff3860;
  color: #fff;
  text-decoration: none;
}

/* if the user input is invalid, the input color should be red */
.vue-tags-input .ti-new-tag-input.ti-invalid {
  color: #e88a74;
}

/* if a tag or the user input is a duplicate, it should be crossed out */
.vue-tags-input .ti-duplicate span
.vue-tags-input .ti-new-tag-input.ti-duplicate {
  text-decoration: none;
}

.vue-tags-input .ti-tag.ti-duplicate.ti-invalid.custom-class-allow-duplicates {
  background: #e6eff2;
  color: #0ba1d1;
}

.vue-tags-input .ti-tag.ti-duplicate.ti-invalid.custom-class-allow-duplicates.ti-deletion-mark {
  background-color: #ff3860!important;
  color: #fff;
}

/* if the user presses backspace, the complete tag should be crossed out, to mark it for deletion */
.vue-tags-input .ti-tag:after {
  transition: transform .2s;
  position: absolute;
  content: '';
  height: 2px;
  width: 108%;
  left: -4%;
  top: calc(50% - 1px);
  /*background-color: #fff;*/
  transform: scaleX(0);
}

.vue-tags-input .ti-tag.ti-valid.ti-deletion-mark {
  background-color: #ff3860!important;
  color: #fff;
}

.vue-tags-input .ti-deletion-mark:after {
  transform: scaleX(1);
}

.ti-autocomplete::-webkit-scrollbar-thumb {
  background: none #82cee7;
  border-radius: 4px;
}
</style>
