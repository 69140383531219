<template>
  <section>
    <b-modal
      :active.sync="isComponentModalActive"
      has-modal-card
      trap-focus
      aria-role="dialog"
      aria-modal
      :on-cancel="close"
    >
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Add Treatments</p>
        </header>
        <section class="modal-card-body menu">
          <div class="columns">
            <div class="column">
              <div class="field">
                <ul class="menu-list is-pulled-left">
                  <li
                    style="text-align: left"
                    v-for="treatment in treatments"
                    :key="treatment.id"
                  >
                    <b-checkbox
                      v-model="selectedTreatments"
                      :native-value="parseInt(treatment.id)"
                    >
                      {{ treatment.id }}. {{ treatment.name }}
                    </b-checkbox>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot">
          <button class="button" type="button" @click="close">
            Close
          </button>
          <button class="button is-primary" @click="addTreatments">Save</button>
        </footer>
      </div>
    </b-modal>
  </section>
</template>

<script>
import { TREATMENTS } from "../../../_graphql/Queries/queries";
import { CREATE_AREA_TYPE_CATEGORY_TREATMENT } from "../../../_graphql/Mutations/mutations";
export default {
  name: "addTreatments",
  props: ["selectedTreatmentsData"],
  data() {
    return {
      isComponentModalActive: false,
      selectedTreatments: []
    };
  },
  apollo: {
    treatments: {
      query: TREATMENTS,
      variables: {
        input: {
          order: "id:asc"
        }
      },
      update: data => data.treatments.data
    }
  },
  methods: {
    openModal() {
      this.isComponentModalActive = true;
    },
    close() {
      this.isComponentModalActive = false;
    },
    addTreatments() {
      const variables = {
        input: {
          ...(this.getAreaTypeCatId && {
            area_type_category_id: this.getAreaTypeCatId
          }),
          ...(this.getAreaTypeId &&
            !this.getAreaTypeCatId && { area_type_id: this.getAreaTypeId }),
          treatments: this.selectedTreatments
        }
      };

      // eslint-disable-next-line no-constant-condition
      this.mutate(CREATE_AREA_TYPE_CATEGORY_TREATMENT, variables)
        .then(() => {
          this.close();
          this.$emit("onUpdate");
          this.$buefy.toast.open({
            message: "Treatments updated successfully!",
            type: "is-success"
          });
        })
        .catch(error => {
          this.close();
          this.$buefy.toast.open({
            message: `${error}`,
            type: "is-danger"
          });
        });
    }
  },
  watch: {
    selectedTreatmentsData() {
      this.selectedTreatments = this.$props.selectedTreatmentsData.map(
        value => {
          return parseInt(value.id);
        }
      );
    }
  }
};
</script>

<style scoped>
  .modal-card-foot {
    justify-content: flex-end;
  }
</style>
