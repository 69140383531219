import Articles from "./views/Articles";
import CreateArticle from "./views/CreateArticle";
import EditArticle from "./views/EditArticle";
import { Role } from "../../_helpers/roles";

const articlesRoute = {
  path: "/articles",
  name: "articles",
  component: Articles,
  meta: { authorize: [Role.Admin, Role.Operator] }
};

const createArticleRoute = {
  path: "/articles/create",
  name: "createArticle",
  component: CreateArticle,
  meta: { authorize: [Role.Admin, Role.Operator] }
};

const editArticleRoute = {
  path: "/articles/edit/:id",
  name: "editArticle",
  component: EditArticle,
  meta: { authorize: [Role.Admin, Role.Operator] }
};

export default [articlesRoute, createArticleRoute, editArticleRoute];
