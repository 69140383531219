<template>
  <div class="userForm">
    <div class="columns is-variable is-12">
      <div class="column is-12">
        <div class="columns is-variable is-12 is-multiline is-mobile">
          <ValidationProvider
            class="column is-12-mobile is-6-tablet"
            v-slot="{ errors, valid }"
            name="Related category"
          >
            <b-field
              id="fieldset-specialty"
              :label="'Related ' + (category ? category.name : 'Solutions')"
              message=""
              :type="{ 'is-primary': valid, 'is-danger': errors[0] }"
            >
              <div
                class="menu-list-container"
                :class="errors[0] ? 'is-danger' : 'is-primary'"
              >
                <ul class="menu-list scrollable" v-if="solutions">
                  <li
                    style="display: flex; justify-content: center; align-items: center;"
                    v-if="solutions.data.length == 0"
                  >
                    <b-icon
                      style="margin-right: 0.25rem;"
                      icon="close-circle-outline"
                    ></b-icon>
                    There are no solutions related to this category
                  </li>
                  <span v-for="solution in solutions.data" :key="solution.id">
                    <li v-if="currentSolutionSlug !== solution.slug">
                      <b-checkbox
                        :disabled="disableFields"
                        v-model="form.related_solutions"
                        :native-value="parseInt(solution.id)"
                        @input="checkForChanges('related_solutions')"
                      >
                        {{ solution.title }}
                      </b-checkbox>
                    </li>
                  </span>
                </ul>
                <div class="menu-list has-text-centered" v-else>
                  Select category from "Solution Info" section to see all
                  related solutions to that category
                </div>
              </div>
            </b-field>
            <span
              v-for="error in errors"
              :key="error.id"
              class="has-text-danger help"
              >{{ error }}</span
            >
          </ValidationProvider>
          <ValidationProvider
            class="column is-12-mobile is-6-tablet"
            v-slot="{ errors, valid }"
            name="Keywords"
          >
            <b-field
              id="keywords-tags-field"
              :type="{
                'is-primary': errors.length == 0,
                'is-danger': errors[0] || invalidKeywords
              }"
              label="Keywords (used for searching)"
            >
              <div
                class="box keywords-box"
                :class="{ 'invalid-keywords': invalidKeywords }"
              >
                <div class="card">
                  <div class="card-body ">
                    <vue-tags-input
                      class="vue-tags-input scrollable "
                      :disabled="disableFields"
                      placeholder="Add keyword"
                      :avoid-adding-duplicates="true"
                      v-model="keyword"
                      :allow-edit-tags="false"
                      :add-from-paste="true"
                      :autocomplete-items="filteredKeywords"
                      :delete-on-backspace="true"
                      :tags="keywords"
                      @tags-changed="checkForChanges('keywords')"
                      :separators="separators"
                      :add-on-key="[13, ' ']"
                      @before-adding-tag="addKeyword"
                      @before-deleting-tag="deleteKeyword"
                      :disable="disableFields"
                      :validation="keywordsValidation"
                    />
                  </div>
                </div>
              </div>
            </b-field>
            <span v-if="invalidKeywords" class="has-text-danger help"
              >The Keywords field is required!</span
            >
            <span v-if="!keywordMatchRegex" class="has-text-danger help"
              >Keywords can only contain numbers and/or Latin letters.</span
            >
          </ValidationProvider>
        </div>
        <div class="columns is-variable is-12 is-multiline is-mobile">
          <ValidationProvider
            class="column is-12-mobile is-6-tablet is-3-desktop"
            v-slot="{ errors, valid }"
            name="Treatments"
            v-if="treatments"
          >
            <b-field
              label="Treatments"
              message=""
              :type="{
                'is-primary': errors.length == 0,
                'is-danger': errors[0] || invalidCriteria
              }"
            >
              <div
                class="menu-list-container"
                :class="errors[0] ? 'is-danger' : 'is-primary'"
              >
                <ul class="menu-list scrollable">
                  <li v-for="treatment in treatments.data" :key="treatment.id">
                    <b-checkbox
                      :disabled="disableFields"
                      v-model="form.treatments"
                      :native-value="parseInt(treatment.id)"
                      @input="checkForChanges('treatments')"
                    >
                      {{ treatment.name }}
                    </b-checkbox>
                  </li>
                </ul>
              </div>
            </b-field>
            <span
              v-for="error in errors"
              :key="error.id"
              class="has-text-danger help"
              >{{ error }}</span
            >
          </ValidationProvider>
          <ValidationProvider
            class="column is-12-mobile is-6-tablet is-3-desktop"
            v-slot="{ errors, valid }"
            name="Products"
            v-if="products"
          >
            <b-field
              label="Products"
              message=""
              :type="{
                'is-primary': errors.length == 0,
                'is-danger': errors[0] || invalidCriteria
              }"
            >
              <div
                class="menu-list-container"
                :class="errors[0] ? 'is-danger' : 'is-primary'"
              >
                <ul class="menu-list scrollable">
                  <li v-for="product in products.data" :key="product.id">
                    <b-checkbox
                      v-model="form.products"
                      :disabled="disableFields"
                      :native-value="parseInt(product.id)"
                      @input="checkForChanges('products')"
                    >
                      {{ product.name }}
                    </b-checkbox>
                  </li>
                </ul>
              </div>
            </b-field>
            <span
              v-for="error in errors"
              :key="error.id"
              class="has-text-danger help"
              >{{ error }}</span
            >
          </ValidationProvider>
          <ValidationProvider
            class="column is-12-mobile is-6-tablet is-3-desktop"
            v-slot="{ errors, valid }"
            name="Technologies"
            v-if="technologies"
          >
            <b-field
              label="Technologies"
              message=""
              :type="{
                'is-primary': errors.length == 0,
                'is-danger': errors[0] || invalidCriteria
              }"
            >
              <div
                class="menu-list-container"
                :class="errors[0] ? 'is-danger' : 'is-primary'"
              >
                <ul class="menu-list scrollable">
                  <li
                    v-for="technology in technologies.data"
                    :key="technology.id"
                  >
                    <b-checkbox
                      :disabled="disableFields"
                      v-model="form.technologies"
                      :native-value="parseInt(technology.id)"
                      @input="checkForChanges('technologies')"
                    >
                      {{ technology.name }}
                    </b-checkbox>
                  </li>
                </ul>
              </div>
            </b-field>
            <span
              v-for="error in errors"
              :key="error.id"
              class="has-text-danger help"
              >{{ error }}</span
            >
          </ValidationProvider>
          <ValidationProvider
            class="column is-12-mobile is-6-tablet is-3-desktop"
            v-slot="{ errors, valid }"
            name="Provider Types"
            v-if="specialties"
          >
            <b-field
              label="Provider Types"
              message=""
              :type="{
                'is-primary': errors.length == 0,
                'is-danger': errors[0] || invalidCriteria
              }"
            >
              <div
                class="menu-list-container"
                :class="errors[0] ? 'is-danger' : 'is-primary'"
              >
                <ul class="menu-list scrollable">
                  <li v-for="specialty in specialties.data" :key="specialty.id">
                    <b-checkbox
                      :disabled="disableFields"
                      v-model="form.specialties"
                      :native-value="parseInt(specialty.id)"
                      @input="checkForChanges('specialties')"
                    >
                      {{ specialty.name }}
                    </b-checkbox>
                  </li>
                </ul>
              </div>
            </b-field>
            <span
              v-for="error in errors"
              :key="error.id"
              class="has-text-danger help"
              >{{ error }}</span
            >
          </ValidationProvider>
        </div>
        <span class="has-text-danger help" v-if="invalidCriteria"
          >You must populate at least one treatment, product, technology or
          provider type</span
        >
        <div class="columns is-variable is-12 is-multiline is-mobile">
          <div class="buttons column is-12">
            <b-button
              id="providersSearchBtn"
              class="is-primary is-fullwidth"
              @click="solutionProvidersResults"
              >See which providers match your criteria</b-button
            >
            <span v-if="providersResultsMessage" class="has-text-danger help"
              >Currently there are no providers that match these criteria.</span
            >
          </div>
        </div>
      </div>
    </div>
    <related-providers
      ref="solutionProvidersResults"
      @pageChanged="onPageChange"
    ></related-providers>
  </div>
</template>

<script>
import {
  PRODUCTS,
  SOLUTION_PROVIDERS,
  SOLUTIONS,
  SOLUTIONS_KEYWORDS,
  SPECIALITIES,
  TECHNOLOGIES,
  TREATMENTS
} from "../../../_graphql/Queries/queries";

import RelatedProviders from "./RelatedProviders";

export default {
  data() {
    return {
      keywordMatchRegex: true,
      specialties: { data: [] },
      suggestions: [],
      providersResultsMessage: false,
      keywords: [],
      keyword: "",
      separators: [" "],
      keywordsValidation: [
        {
          classes: "class",
          rule: /^[a-z0-9]+$/i /* RegExp */,
          disableAdd: true
        }
      ]
    };
  },
  props: [
    "form",
    "category",
    "publishValidation",
    "isPublished",
    "currentSolutionSlug",
    "disableFields"
  ],
  watch: {
    keyword(value) {
      let regex = new RegExp(/^[a-z0-9]+$/i);
      if (this.keyword !== "") {
        this.keywordMatchRegex = regex.test(value);
      } else {
        this.keywordMatchRegex = true;
      }
    }
  },
  components: {
    RelatedProviders
  },
  computed: {
    invalidKeywords() {
      return this.isPublished && this.form.keywords.length == 0;
    },
    invalidCriteria() {
      return (
        this.publishValidation &&
        this.form.treatments.length == 0 &&
        this.form.products.length == 0 &&
        this.form.technologies.length == 0 &&
        this.form.specialties.length == 0
      );
    },
    filteredKeywords() {
      return this.solutionsKeywords
        ? this.solutionsKeywords
            .map(item => {
              return { text: item.slug, ...item };
            })
            .filter(i => {
              return i.text
                .toLowerCase()
                .startsWith(this.keyword.toLowerCase());
            })
            .sort((a, b) => {
              const aL = a.text.toLowerCase();
              const bL = b.text.toLowerCase();

              if (aL > bL) {
                return 1;
              } else if (aL < bL) {
                return -1;
              } else {
                return 0;
              }
            })
        : [];
    },
    keywordsMaxLength() {
      return 600 - this.form.keywords.join(",").length;
    },
    solutionProvidersCriteria() {
      let criteria = {};
      if (this.form.treatments.length > 0) {
        criteria.treatments_ids = this.form.treatments;
      }
      if (this.form.products.length > 0) {
        criteria.products_ids = this.form.products;
      }
      if (this.form.technologies.length > 0) {
        criteria.technologies_ids = this.form.technologies;
      }
      if (this.form.specialties.length > 0) {
        criteria.specialties_ids = this.form.specialties;
      }
      return criteria;
    }
  },
  methods: {
    checkForChanges(value) {
      this.$emit("editedField", value);
    },
    processTags(itemTags) {
      this.keywords = itemTags.map(item => {
        const newItem = {
          text: item,
          name: item,
          classes: "custom-class"
        };

        return newItem;
      });
    },
    onKeywordChange(tag) {
      console.log(13567879, tag);
    },
    addKeyword({ tag, addTag }) {
      if (!tag) {
        return;
      }
      let regex = new RegExp(/^[a-z0-9]+$/i);
      if (regex.test(tag.text)) {
        this.keywordMatchRegex = true;
        if (tag.text.length <= 255) {
          if (!this.form.keywords.includes(tag.text)) {
            this.form.keywords.push(tag.text);
          }
          addTag();
        }
      } else {
        this.keywordMatchRegex = false;
      }
    },
    deleteKeyword({ tag, deleteTag }) {
      if (tag && this.form.keywords.includes(tag.text)) {
        deleteTag();
        this.$emit("deletedKeyword", tag);
      }
    },
    checkTagsLength() {
      const length = this.keywordsMaxLength;
      return length > 0;
    },
    solutionProvidersResults() {
      this.providersResultsMessage = false;
      if (Object.keys(this.solutionProvidersCriteria).length > 0) {
        this.$apollo
          .query({
            query: SOLUTION_PROVIDERS,
            variables: {
              criteria: this.solutionProvidersCriteria
            }
          })
          .then(({ data }) => {
            if (data.solutionProviders) {
              if (
                data.solutionProviders.meta &&
                data.solutionProviders.meta.total_count > 0
              ) {
                this.$refs.solutionProvidersResults.openModal(
                  data.solutionProviders
                );
              } else {
                this.providersResultsMessage = true;
              }
            }
          });
      } else {
        this.providersResultsMessage = true;
      }
    },
    onPageChange(queryParams) {
      this.$apollo
        .query({
          query: SOLUTION_PROVIDERS,
          variables: {
            criteria: this.solutionProvidersCriteria,
            input: queryParams
          }
        })
        .then(({ data: { solutionProviders } }) => {
          if (solutionProviders) {
            this.$refs.solutionProvidersResults.providers = solutionProviders;
          }
        });
    }
  },
  mounted() {
    if (this.form) {
      this.processTags(this.form.keywords);
    }
  },
  apollo: {
    solutions: {
      query: SOLUTIONS,
      variables() {
        return {
          input: {
            query: `sc.slug:${this.category.slug},s.is_published:1`
          }
        };
      },
      skip() {
        return !this.category;
      }
    },
    solutionsKeywords: {
      query: SOLUTIONS_KEYWORDS
    },
    treatments: {
      query: TREATMENTS
    },
    products: {
      query: PRODUCTS
    },
    technologies: {
      query: TECHNOLOGIES
    },
    specialties: {
      query: SPECIALITIES
    }
  }
};
</script>

<style lang="scss" scoped>
.menu-list-container {
  word-break: break-word;

  .menu-list {
    padding: 10px;
    height: 16rem;
    max-height: 16rem;
    overflow: auto;
    text-align: left;
    overflow-y: scroll;
    .b-checkbox {
      align-items: flex-start;
    }
  }
}

.label {
  text-align: left !important;
}

#providersSearchBtn {
  margin-top: 1rem;
}

.keywords-box {
  -webkit-box-shadow: 0 2px 3px rgb(10 10 10 / 10%), 0 0 0 1px rgb(11, 161, 209);
  box-shadow: 0 2px 3px rgb(10 10 10 / 10%), 0 0 0 1px rgb(11, 161, 209);
}

.invalid-keywords {
  -webkit-box-shadow: 0 2px 3px rgb(10 10 10 / 10%), 0 0 0 1px rgb(255, 56, 96);
  box-shadow: 0 2px 3px rgb(10 10 10 / 10%), 0 0 0 1px rgb(255, 56, 96);
}

.vue-tags-input {
  max-height: 226px;
  overflow-y: scroll;
  word-break: break-word;
  padding-bottom: 4rem;
}
</style>

<style lang="scss">
#keywords-tags-field {
  .vue-tags-input .ti-tag.ti-invalid {
    background: #e6eff2;
    color: #0ba1d1;
    margin-right: 4px;
    border-radius: 4px;
    font-size: 16px;
    font-family: "IBM Plex Sans", sans-serif !important;
    padding: 7px 9px;
  }
  .vue-tags-input .ti-new-tag-input.ti-invalid {
    color: #0ba1d1;
  }
}
</style>
