import list from "./views/Dashboard";
import { Role } from "../../_helpers/roles";

const Dashboard = {
  path: "/dashboard",
  name: "dashboard",
  component: list,
  meta: { authorize: [Role.Admin, Role.Vendor] }
};

export default [Dashboard];
