<template>
  <div
    class="columns is-multiline is-mobile"
    style="background: none #fefefe;margin:0"
  >
    <div class="column is-12">
      <div class="buttons">
        <b-upload
          :disabled="
            !isSubscribed ||
              offerMakerIsOpen ||
              appointmentMakerIsOpen ||
              dropFiles != null
          "
          @input="onFileUpload"
          v-model="dropFiles"
          class="file-upload-btn"
        >
          <a
            @click="
              !isSubscribed && showUpgradePlanMessage(shareFileSubscriptionMsg)
            "
            :disabled="
              offerMakerIsOpen || appointmentMakerIsOpen || dropFiles != null
            "
            class="button"
          >
            <img src="@/assets/img/file_upload_icon.svg" />
          </a>
        </b-upload>
        <b-tooltip
            v-if="payoutIsNotCompleted"
            animated
            multilined
            class="tooltip"
            :label="tooltips.payout"
            position="is-top"
            size="is-small"
            >
        <b-button
          v-if="isPaymentActive && showPaymentsButton"
          :disabled="payoutIsNotCompleted || offerMakerIsOpen || uploadFileIsOpen"
          @click="openPaymentRequest"
          class="action-btn"
        >
          Request payment
        </b-button>
        </b-tooltip>
        <b-button
          v-if="isPaymentActive && showPaymentsButton && !payoutIsNotCompleted"
          :disabled="payoutIsNotCompleted || offerMakerIsOpen || uploadFileIsOpen"
          @click="openPaymentRequest"
          class="action-btn"
        >
          Request payment
        </b-button>
        <b-button
          v-if="!offerMakerIsOpen && messagesFilter !== '!OPEN'"
          :disabled="appointmentMakerIsOpen || uploadFileIsOpen"
          @click="makeOffer"
          class="action-btn"
          >Make an offer</b-button
        >
        <b-button
          v-if="offerMakerIsOpen"
          :disabled="appointmentMakerIsOpen || uploadFileIsOpen"
          @click="
            () => {
              offerMakerIsOpen = !offerMakerIsOpen;
              appointmentMakerIsOpen = false;
            }
          "
          class="action-btn"
          >Cancel</b-button
        >
        <b-button
          v-if="!appointmentMakerIsOpen && messagesFilter !== '!OPEN'"
          :disabled="offerMakerIsOpen || uploadFileIsOpen"
          @click="makeAppointment"
          class="action-btn"
          >Make an appointment</b-button
        >
        <b-button
          v-if="appointmentMakerIsOpen"
          @click="
            () => {
              appointmentMakerIsOpen = !appointmentMakerIsOpen;
              offerMakerIsOpen = false;
              showAppointmentSendBtn = false;
            }
          "
          class="action-btn"
          >Cancel</b-button
        >
        <b-button
          v-if="showVideoButton"
          class=" action-btn"
          :class="{ 'has-text-danger': totalSeconds <= 1800 }"
          @click="
            isSubscribed
              ? handleVideoCall()
              : showUpgradePlanMessage(videoCallsSubscriptionMsg)
          "
          :disabled="disableVideoBtn || totalSeconds == 0"
          ><b-tooltip
            v-if="
              !(videoCallPermission && videoCallPermission.allowed) && !isMobile
            "
            animated
            multilined
            class="tooltip"
            :label="tooltips.video"
            position="is-top"
            size="is-small"
            ><b-icon icon="video" :type="'is-primary'"></b-icon
          ></b-tooltip>
          <b-icon v-if="isMobile" icon="video" :type="'is-primary'"></b-icon>
          <b-icon
            v-if="videoCallPermission && videoCallPermission.allowed"
            icon="video"
            :type="'is-success'"
          ></b-icon>
          <span id="minutes"> {{ totalProviderMinutes }} minutes</span>
        </b-button>
        <b-button
          v-if="
            journeyProvider.provider &&
              journeyProvider.provider.is_subscribed &&
              showVideoButton
          "
          class="button is-primary"
          @click="openBuyMinutesModal"
          >Buy more minutes</b-button
        >
      </div>
    </div>
    <div class="columns is-multiline chatArea">
      <div class="column is-12">
        <span v-if="isUpdated" class="edit-message is-pulled-left"
          >[edit message]</span
        >
        <textarea
          v-if="!dropFiles"
          :placeholder="
            messagesFilter == '!OPEN'
              ? 'This chat has been archived.'
              : 'Write your chat here...'
          "
          @keydown.enter.exact.prevent
          @keyup.enter.exact="handleSendMessage"
          @keydown.enter.shift.exact="newline"
          v-model="answer"
          :disabled="
            offerMakerIsOpen ||
              appointmentMakerIsOpen ||
              messagesFilter == '!OPEN'
          "
          class="chatTextarea"
        ></textarea>
        <b-taglist class="taglist" v-if="dropFiles">
          <b-tag
            type="is-light"
            size="is-medium"
            closable
            @close="deleteDropFile"
            >{{ dropFiles.name }}
          </b-tag>
        </b-taglist>
      </div>
      <div class="column is-12 send">
        <button
          v-if="!isUpdated"
          @click="handleSendMessage()"
          class="button is-primary"
          :disabled="
            offerMakerIsOpen ||
              appointmentMakerIsOpen ||
              messagesFilter == '!OPEN' ||
              uploadingFile
          "
        >
          Send
        </button>
        <button
          v-if="isUpdated"
          :disabled="this.answer.length === 0"
          @click="handleUpdateMessage"
          class="button is-primary"
        >
          Update
        </button>
      </div>
    </div>
    <div class="column is-full-mobile is-half-tablet is-12-desktop">
      <offer-maker
        :appointment-maker-is-open="appointmentMakerIsOpen"
        :offer-maker-is-open="offerMakerIsOpen"
        :provider="journeyProvider.provider"
        :journey-provider-id="parseInt(this.journeyProvider.id)"
        @startedConversation="startConversation"
        @offer="handleSendMessage"
        @close="offerMakerIsOpen = !offerMakerIsOpen"
      />
      <div
        ref="appointment"
        id="appointment-maker"
        class="appointment-maker"
        v-show="appointmentMakerIsOpen"
      >
        <br />
        <br />

        <div class="columns">
          <div class="column is-4 is-pulled-right has-text-left">
            <div class="subtitle">
              Choose date and time:
            </div>
            <datetime
              type="datetime"
              class="theme-metime"
              v-model="appointmentDate"
              input-class="input appointment-calendar is-large"
              value-zone="Europe/London"
              zone="Europe/London"
              :format="{
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: 'numeric',
                minute: '2-digit',
                timeZoneName: 'short'
              }"
              hidden-name="Choose date and time"
              :phrases="{ ok: 'OK', cancel: 'Cancel' }"
              :hour-step="1"
              :minute-step="5"
              :min-datetime="minDatetime"
              :week-start="7"
              use24-hour
              auto
            />
          </div>
          <div class="column is-6 is-pulled-left has-text-left">
            <br /><br />
            <span v-if="showAppointmentSendBtn">
              <button
                @click="handleSendAppointment"
                class="button is-primary is-large"
              >
                Send &raquo;
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
    <requestPayment
      :isSubscribed="isSubscribed"
      ref="paymentRequest"
      @requestedPayment="handleSendPaymentRequest"
    ></requestPayment>
    <buy-minutes-modal
      v-if="journeyProvider && journeyProvider.provider"
      ref="buyMinutesModal"
      :provider-id="journeyProvider.provider.id"
      :free-seconds="videocall_free_seconds"
      :paid-seconds="videocall_paid_seconds"
      :stripe="stripe"
    ></buy-minutes-modal>
  </div>
</template>
<script>
import { createNamespacedHelpers } from "vuex";
import _ from "lodash";
import OfferMaker from "./offerMaker";
import requestPayment from "./requestPayment";
import { Datetime } from "vue-datetime";
import PlanStripeConnectHelper from "@/mixins/PlanStripeConnectHelper";
import { SEND_FILE, UPLOAD_IMAGE } from "../../../_graphql/Mutations/mutations";
import {
  VIDEO_CALL_PERMISSION,
  PROVIDER
} from "../../../_graphql/Queries/queries";
import { isMobile } from "mobile-device-detect";
import BuyMinutesModal from "./BuyMinutesModal";

const { mapActions, mapGetters, mapMutations } = createNamespacedHelpers(
  "messages"
);
export default {
  components: { OfferMaker, requestPayment, Datetime, BuyMinutesModal },
  props: ["journeyProvider", "hasVideoCallPermission"],
  mixins: [PlanStripeConnectHelper],
  data() {
    return {
      isMobile: isMobile,
      isPaymentActive: true,
      isUpdated: false,
      showAppointmentSendBtn: false,
      minDatetime: new Date().toISOString(),
      appointmentDate: new Date().toISOString(),
      offerMakerIsOpen: false,
      appointmentMakerIsOpen: false,
      uploadingFile: false,
      answer: "",
      dropFiles: null,
      fileInfo: null,
      imageInfo: null,
      disableVideoBtn: false,
      payoutIsNotCompleted: true,
      validFileExtensions: [
        "rar",
        "zip",
        "jpg",
        "jpeg",
        "png",
        "txt",
        "docx",
        "doc",
        "pdf"
      ],
      videoCallWindow: null,
      tooltips: {
        video:
          "Click to request permission from this user to connect through video. When the user accepts, you'll be able to start a video call by tapping the green video icon.",
        payout:
          "Enable payments first so you can receive payouts."
      },
      shareFileSubscriptionMsg: `<a href="/subscriptions" target="_blank">Upgrade</a> your plan to add share files.`,
      videoCallsSubscriptionMsg: `<a href="/subscriptions" target="_blank">Upgrade</a> your plan to enable video consultations.`,
      videocall_free_seconds: 0,
      videocall_paid_seconds: 0,
      totalSeconds: 0,
      stripe: null
    };
  },
  watch: {
    appointmentDate() {
      this.showAppointmentSendBtn = true;
    },
    messageValue(newVal) {
      this.answer = newVal.payload;
      this.isUpdated = true;
    },
    hasVideoCallPermission(newVal) {
      if (newVal == "permissionAccepted" || newVal == "permissionDenied") {
        this.$apollo.queries.videoCallPermission.refetch();
      }
    },
    dropFiles() {
      if (this.dropFiles) {
        let self = this;
        let onKeyEnter = function(e) {
          if (13 === e.keyCode) {
            self.handleSendMessage();
            document.removeEventListener("keyup", onKeyEnter, true);
          }
        };
        document.addEventListener("keyup", onKeyEnter, true);
      }
    }
  },
  computed: {
    ...mapGetters([
      "treatments",
      "conversationId",
      "messagesFilter",
      "messageValue",
      "isCalling",
      "videoUrl"
    ]),
    isSubscribed() {
      return this.journeyProvider && this.journeyProvider.provider
        ? this.journeyProvider.provider.is_subscribed
        : false;
    },
    showPaymentsButton() {
      return (
        this.journeyProvider &&
        this.journeyProvider.provider &&
        this.journeyProvider.provider.settings &&
        this.journeyProvider.provider.settings.allow_payments
      );
    },
    showVideoButton() {
      return (
        this.journeyProvider &&
        this.journeyProvider.provider &&
        this.journeyProvider.provider.settings &&
        this.journeyProvider.provider.settings.allow_videocalls
      );
    },
    appointmentMetadata() {
      return {
        type: "APPOINTMENT",
        state: "PENDING",
        date: this.appointmentDate
      };
    },
    videoCallPermissionMetadata() {
      return {
        type: "VIDEO_CALL_PERMISSION",
        state: "PENDING",
        providerId: this.journeyProvider.provider.id
      };
    },
    uploadFileIsOpen() {
      return this.dropFiles !== null;
    },
    firstMessage() {
      return this.conversationId === 0;
    },
    videoBtnTooltip() {
      let freeMinutes =
        this.videocall_free_seconds == 0
          ? this.videocall_free_seconds
          : (this.videocall_free_seconds / 60).toFixed(2);
      let paidMinutes =
        this.videocall_paid_seconds == 0
          ? this.videocall_paid_seconds
          : (this.videocall_paid_seconds / 60).toFixed(2);
      return this.videoCallPermission && this.videoCallPermission.allowed
        ? `${freeMinutes} subscription included minutes; ${paidMinutes} extra minutes; Click to request permission from this user to connect through video. When the user accepts, you'll be able to start a video call by tapping the green video icon.`
        : "Click to request permission from this user to connect through video. When the user accepts, you'll be able to start a video call by tapping the green video icon.";
    },
    totalProviderMinutes() {
      let minutes = Math.floor(this.totalSeconds / 60);
      let seconds = this.totalSeconds % 60;

      return minutes + ":" + (seconds <= 9 ? `0${seconds}` : seconds);
    }
  },
  mounted() {
    this.stripe = window.Stripe(process.env.VUE_APP_STRIPE_PK);
  },
  created() {
    this.getTreatments();
    this.getStripeConnectStatus(this.journeyProvider.provider.id).then(
      ({ data: { providerStripeConnect } }) => {
        if (providerStripeConnect != null && providerStripeConnect.connect_id != null && providerStripeConnect.payouts_enabled == 1 && providerStripeConnect.details_submitted == 1) {
          this.payoutIsNotCompleted = false;
        }
      }
    );
  },
  methods: {
    ...mapActions([
      "sendMessage",
      "getTreatments",
      "createConversation",
      "updateMessage"
    ]),
    ...mapMutations(["setConversationId"]),
    onFileUpload(data) {
      const limitFileSize = 20971520;
      if (!_.includes(this.validFileExtensions, data.name.split(".").pop())) {
        this.dropFiles = null;
        return this.$buefy.toast.open({
          message: "File type is not supported",
          type: "is-danger",
          duration: 3000
        });
      }
      if (data.size >= limitFileSize) {
        this.dropFiles = null;
        return this.$buefy.toast.open({
          message: "File is too big. Please select a file less than 20mb",
          type: "is-danger",
          duration: 3000
        });
      }
    },
    deleteDropFile() {
      this.dropFiles = null;
    },
    makeOffer() {
      this.offerMakerIsOpen = true;
      this.appointmentMakerIsOpen = false;

      setTimeout(() => {
        const elmnt = document.getElementById("offer-maker");
        elmnt.scrollIntoView({
          behavior: "smooth"
        });
      }, 500);
    },
    makeAppointment() {
      this.appointmentMakerIsOpen = true;
      this.offerMakerIsOpen = false;
      setTimeout(() => {
        const elmnt = document.getElementById("appointment-maker");
        elmnt.scrollIntoView({
          behavior: "smooth"
        });
      }, 500);
    },
    handleSendPaymentRequest(metadata) {
      this.getStripeConnectStatus(this.journeyProvider.provider.id).then(
        ({ data: { providerStripeConnect } }) => {
          if (providerStripeConnect != null && providerStripeConnect.connect_id != null && providerStripeConnect.payouts_enabled == 1 && providerStripeConnect.details_submitted == 1) {
            metadata.accountId = providerStripeConnect.connect_id;
          }
          if (this.firstMessage) {
            this.startConversation({ message: "payment request", meta: metadata });
          } else {
            this.sendMessage({
              message: "payment request",
              journeyProviderId: parseInt(this.journeyProvider.id),
              conversationId: this.journeyProvider.conversation_id,
              meta: metadata,
              readBy: [
                this.$store.state.meUser ? this.$store.state.meUser.user._id : ""
              ]
            });
          }
        }
      );
    },
    handleSendAppointment() {
      if (this.firstMessage) {
        this.startConversation({
          message: "appointment",
          meta: this.appointmentMetadata
        });
      } else {
        this.sendMessage({
          message: "appointment",
          journeyProviderId: parseInt(this.journeyProvider.id),
          conversationId: this.journeyProvider.conversation_id,
          meta: this.appointmentMetadata,
          readBy: [
            this.$store.state.meUser ? this.$store.state.meUser.user._id : ""
          ]
        }).then(() => this.goToBottom());
      }
      this.appointmentMakerIsOpen = false;
    },
    uploadFileMetadata(data) {
      return {
        type: "FILE",
        file: data
      };
    },
    uploadImageMetadata(data) {
      return {
        type: "PHOTOS",
        photos: [data]
      };
    },
    uploadFile() {
      this.$apollo
        .mutate({
          mutation: SEND_FILE,
          variables: {
            somefile: this.dropFiles
          },
          update: (
            cache,
            {
              data: {
                upload: { data }
              }
            }
          ) => {
            if (data) {
              this.fileInfo = {
                filename: this.dropFiles.name,
                url: data.url,
                type: data.type,
                size: this.dropFiles.size
              };
              this.handleSendFile();
            }
            this.uploadingFile = false;
          },
          error: ({ error }) => {
            this.dropFiles = null;
            this.fileInfo = null;
            this.uploadingFile = false;

            return this.$buefy.toast.open({
              message: error,
              type: "is-danger"
            });
          }
        })
        .catch(errors => {
          this.dropFiles = null;
          this.fileInfo = null;
          this.uploadingFile = false;

          return this.$buefy.toast.open({
            message: `${errors}`,
            type: "is-danger"
          });
        })
        .then(({ data: { upload } }) => {
          if (upload.errors.length > 0) {
            this.dropFiles = null;
            this.fileInfo = null;
            this.uploadingFile = false;

            return this.$buefy.toast.open({
              message: upload.errors[0],
              type: "is-danger"
            });
          }
        });
    },
    uploadImage() {
      let config = {
        header: {
          "Content-Type": "image/*"
        }
      };
      return this.$apollo
        .mutate({
          mutation: UPLOAD_IMAGE,
          variables: {
            file: this.dropFiles,
            input: {
              height: [320]
            }
          },
          context: config,
          update: (
            cache,
            {
              data: {
                resize: { data }
              }
            }
          ) => {
            if (data) {
              this.imageInfo = {
                height: data.height,
                thumb_url: data.sizes.length > 0 ? data.sizes[0].url : data.url,
                url: data.url,
                width: data.width
              };
              this.handleSendFile();
            } else {
              this.uploadingFile = false;
              this.dropFiles = null;
              return this.$buefy.toast.open({
                message: "Something went wrong",
                type: "is-danger"
              });
            }
          },
          error: ({ error }) => {
            this.dropFiles = null;
            this.imageInfo = null;
            this.uploadingFile = false;

            return this.$buefy.toast.open({
              message: error,
              type: "is-danger"
            });
          }
        })
        .catch(errors => {
          this.dropFiles = null;
          this.fileInfo = null;
          this.uploadingFile = false;

          return this.$buefy.toast.open({
            message: `${errors}`,
            type: "is-danger"
          });
        });
    },
    handleSendFile() {
      if (this.fileInfo || this.imageInfo) {
        const name =
          this.$store.state.meUser.user.firstName +
          " " +
          this.$store.state.meUser.user.lastName;
        const message = name + " shared a file: " + this.dropFiles.name;
        const metadata = this.fileInfo
          ? this.uploadFileMetadata(this.fileInfo)
          : this.uploadImageMetadata(this.imageInfo);
        if (this.firstMessage) {
          this.startConversation({
            message: message,
            meta: metadata
          });
        } else {
          this.sendMessage({
            message: message,
            journeyProviderId: parseInt(this.journeyProvider.id),
            conversationId: this.conversationId,
            meta: metadata,
            readBy: [
              this.$store.state.meUser ? this.$store.state.meUser.user._id : ""
            ]
          }).then(() => {
            this.fileInfo = null;
            this.imageInfo = null;
            this.uploadingFile = false;
            this.dropFiles = null;
            this.goToBottom();
          });
        }
      }
    },
    goToBottom() {
      setTimeout(() => {
        const objDiv = document.getElementsByClassName("chat-holder")[0];
        objDiv.scrollTop += objDiv.scrollHeight;
      }, 3000);
    },
    startConversation(data) {
      this.createConversation({
        // typecast to String cuz graphql.
        clientId: this.journeyProvider.journey.user.auth_id + "",
        journeyProviderId: parseInt(this.journeyProvider.id),
        message: data.message,
        meta: data.meta
      }).then(() => {
        // For now, just reload the window in order for the websocket to kick off the subscription.
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      });
    },
    newline() {
      this.answer = `${this.answer}`;
    },
    handleSendMessage() {
      if (this.answer.trim().length === 0 && !this.dropFiles) {
        return;
      }
      if (this.isUpdated) {
        this.handleUpdateMessage();
        return;
      }
      if (this.dropFiles) {
        this.uploadingFile = true;
        if (
          this.dropFiles.type == "image/png" ||
          this.dropFiles.type == "image/jpeg"
        ) {
          this.uploadImage();
        } else {
          this.uploadFile();
        }
      } else {
        if (this.firstMessage) {
          this.startConversation({ message: this.answer });
        } else {
          this.sendMessage({
            message: this.answer,
            journeyProviderId: parseInt(this.journeyProvider.id),
            conversationId: this.conversationId,
            readBy: [
              this.$store.state.meUser ? this.$store.state.meUser.user._id : ""
            ]
          }).then(() => {
            this.answer = "";
            this.offerMakerIsOpen = false;
            this.uploadingFile = false;
            this.goToBottom();
          });
        }
      }
    },
    handleUpdateMessage() {
      this.updateMessage({
        id: this.messageValue.id,
        payload: this.answer
      });

      this.answer = "";
      this.isUpdated = false;
      this.status = "EDITED";
    },
    openPaymentRequest() {
      this.$refs.paymentRequest.openModal();
    },
    async handleVideoCall() {
      this.disableVideoBtn = true;
      this.$apollo.queries.videoCallPermission.refetch();
      if (this.videoCallPermission && !this.videoCallPermission.allowed) {
        const permissionMessage =
          (this.journeyProvider && this.journeyProvider.provider
            ? this.journeyProvider.provider.name
            : "Provider") + " is requesting a video call";
        if (this.firstMessage) {
          this.startConversation({
            message: permissionMessage,
            meta: this.videoCallPermissionMetadata
          });
        } else {
          this.sendMessage({
            message: permissionMessage,
            journeyProviderId: parseInt(this.journeyProvider.id),
            conversationId: this.conversationId,
            meta: this.videoCallPermissionMetadata,
            readBy: [
              this.$store.state.meUser ? this.$store.state.meUser.user._id : ""
            ]
          }).then(() => {
            this.goToBottom();
            this.disableVideoBtn = false;
          });
        }
      } else {
        await this.$apollo.queries.provider.refetch();
        this.videocall_free_seconds = this.provider.videocall_free_seconds;
        this.videocall_paid_seconds = this.provider.videocall_paid_seconds;
        this.totalSeconds =
          this.provider.videocall_free_seconds +
          this.provider.videocall_paid_seconds;
        if (this.totalSeconds > 0) {
          this.openVideoCallWindow();
        }
        this.disableVideoBtn = false;
      }
    },
    openVideoCallWindow() {
      let videoWidth =
        window.outerWidth > 1280
          ? 1280
          : window.outerWidth < 576
          ? window.innerWidth
          : window.innerWidth - 200;
      let videoHeight = window.innerHeight - 100;
      let left = window.outerWidth < 769 ? 0 : 200;
      this.videoCallWindow = window.open(
        this.$route.path + "/videoCall",
        "",
        "toolbar=yes,scrollbars=yes,resizable=yes,top=100,left=" +
          left +
          ",width=" +
          videoWidth +
          ",height=" +
          videoHeight +
          ",titlebar=no"
      );
      if (this.videoCallWindow) {
        this.videoCallWindow.addEventListener("unload", () => {
          this.$apollo.queries.provider.refetch();
        });
      }
    },
    showUpgradePlanMessage(message) {
      this.$buefy.dialog.alert({
        title: "Upgrade plan",
        message: message,
        type: "is-primary",
        ariaRole: "alertdialog",
        ariaModal: true
      });
    },
    openBuyMinutesModal() {
      this.$refs.buyMinutesModal.openModal();
    }
  },
  apollo: {
    videoCallPermission: {
      query: VIDEO_CALL_PERMISSION,
      variables() {
        return {
          buyer_id: this.journeyProvider.journey.user.authUser._id,
          provider_id: parseInt(this.journeyProvider.provider.id)
        };
      },
      update: data => data.videoCallPermission,
      skip() {
        return (
          !this.journeyProvider ||
          !this.journeyProvider.journey ||
          !this.journeyProvider.provider
        );
      },
      result({ data }) {
        if (data.provider) {
          this.videocall_free_seconds = data.provider.videocall_free_seconds;
          this.videocall_paid_seconds = data.provider.videocall_paid_seconds;
          this.totalSeconds =
            data.provider.videocall_free_seconds +
            data.provider.videocall_paid_seconds;
        }
      }
    },
    provider: {
      query: PROVIDER,
      variables() {
        return {
          id: parseInt(this.journeyProvider.provider.id)
        };
      },
      skip() {
        return !this.journeyProvider || !this.journeyProvider.provider;
      },
      result({ data }) {
        this.videocall_free_seconds = data.provider.videocall_free_seconds;
        this.videocall_paid_seconds = data.provider.videocall_paid_seconds;
        this.totalSeconds =
          data.provider.videocall_free_seconds +
          data.provider.videocall_paid_seconds;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.send {
  text-align: end;
  padding-top: 0px;
}
.file-upload-btn {
  .button {
    border-color: #0ba1d1;
    box-shadow: none;
    padding: 6px;
    &:hover {
      background-color: #f5f8fa;
    }
    &:active {
      transform: translate(0.3px, 0.3px);
    }
  }
}

.edit-message {
  font-style: italic;
  color: #555;
}
.chatArea {
  border: 1px solid #0ba1d1;
  width: 100%;
  margin: 0 10px 0 10px;
  border-radius: 8px;

  textarea:focus {
    outline: 0;
  }

  .taglist {
    display: flex;
    align-content: flex-start;
    margin: 0 10px 7px 0;
    padding: 10px;
    min-height: 150px;
    width: 100%;
    font-size: 16px;
  }
}
sub {
  width: 100%;
  text-align: center;
}
.chatTextarea {
  width: 100%;
  font-size: 16px;
  padding: 10px;
  resize: none;
  min-height: 150px;
  border: none;
}

.upload-label {
  font-family: "IBM Plex Sans", sans-serif;
}

.action-btn {
  border: 1px solid #0ba1d1;
  color: #0ba1d1;
  &:hover {
    border-color: #0ba1d1;
    color: #0ba1d1;
    background-color: #f5f8fa;
  }
  &:active {
    transform: translate(0.3px, 0.3px);
  }
  .icon {
    margin-right: 0.25rem !important;
  }
}

.video-tooltip {
  margin-right: 0.5rem;
}

#minutes {
  vertical-align: top;
}
</style>
<style>
.answer {
  border: 1px solid #0ba1d1;
  width: 100%;
  font-size: 16px;
  padding: 10px;
  resize: none;
  min-height: 150px;
  margin: 0 10px 0 0;
  border-radius: 8px;
}

.theme-metime .vdatetime-popup__header,
.theme-metime .vdatetime-calendar__month__day--selected > span > span,
.theme-metime .vdatetime-calendar__month__day--selected:hover > span > span {
  background: #0ba1d1;
}

.appointment-calendar {
  color: #fff;
}

.theme-metime .vdatetime-year-picker__item--selected,
.theme-metime .vdatetime-time-picker__item--selected,
.theme-metime .vdatetime-popup__actions__button {
  color: #0ba1d1;
}
</style>
