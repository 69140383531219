<template>
  <div class="hello" ref="chartdiv"></div>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import _ from "lodash";

am4core.useTheme(am4themes_animated);

export default {
  name: "HelloWorld",
  props: {
    chartData: {
      required: true
    },
    series: {
      required: true
    }
  },
  mounted() {
    let chart = am4core.create(this.$refs.chartdiv, am4charts.XYChart);
    chart.logo.visible = false;

    chart.data = this.chartData;

    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.renderer.minGridDistance = 30;
    dateAxis.renderer.cellStartLocation = 0.2;
    dateAxis.renderer.cellEndLocation = 0.8;
    // dateAxis.zoomToDates(new Date(this.chartData.slice(-5)[0].name));

    dateAxis.periodChangeDateFormats.setKey("month", "[bold]yyyy[/]");

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.tooltip.disabled = true;
    valueAxis.renderer.minWidth = 35;
    chart.colors.list = [am4core.color("#23d160")];
    function createSeries(name, value, date) {
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.dateX = date;
      series.dataFields.valueY = value;
      series.name = name;
      series.tooltipText = "{name}: [bold]{valueY} [/]";
      series.columns.template.width = am4core.percent(100);
      series.legendSettings.labelText = name;

      chart.cursor = new am4charts.XYCursor();

      let scrollbarX = new am4charts.XYChartScrollbar();
      scrollbarX.series.push(series);
      chart.scrollbarX = scrollbarX;

      return series;
    }

    _.forEach(this.series, series => {
      createSeries(series.name, series.value, series.date);
    });

    let label = chart.createChild(am4core.Label);
    label.text = "Data updates in every 10 min.";
    label.fontSize = 10;
    label.align = "right";

    if (this.chartData.length == 0) {
      let label = chart.createChild(am4core.Label);
      label.text = "The chart contains no data";
      label.fontSize = 10;
      label.align = "center";
      label.textAlign = "middle";
      label.opacity = 50;
      label.paddingTop = -225;
    }
    chart.legend = new am4charts.Legend();
    chart.legend.useDefaultMarker = true;
    let markerTemplate = chart.legend.markers.template;
    markerTemplate.width = 30;
    markerTemplate.height = 30;

    this.chart = chart;
  },

  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  }
};
</script>

<style scoped>
.hello {
  width: 100%;
  height: 500px;
}
</style>
