<script>
import {
  CREATE_STRIPE_CONNECT,
  UPDATE_STRIPE_CONNECT
} from "@/_graphql/Mutations/mutations";
import { GET_SRTIPE_CONNECT_URL, PROVIDER_STRIPE_CONNECT } from "@/_graphql/Queries/queries";

export default {
  name: "PlanStripeConnectHelper",
  data() {
    return {
      stripe: null,
      paymentsPlans: []
    };
  },
  created() {},
  computed: {},
  mounted() {
    this.stripe = window.Stripe(process.env.VUE_APP_STRIPE_PK);
  },
  methods: {
    create(provider_id) {
      console.log(provider_id);
      const variables = {
        input: {
          provider_id: parseInt(provider_id)
        }
      };
      this.mutate(CREATE_STRIPE_CONNECT, variables)
        .then(({ data: { createStripeConnect } }) => {
          console.log(createStripeConnect);
        })
        .catch(error => {
          this.$buefy.toast.open({
            message: `${error}`,
            type: "is-danger"
          });
        });
    },
    updateStripeConnectCheckout(providerStripeConnect) {
      const variables = {
        id: parseInt(providerStripeConnect.id),
        input: {
          provider_id: parseInt(providerStripeConnect.provider_id)
        }
      };
      this.mutate(UPDATE_STRIPE_CONNECT, variables)
        .then(({ data: { updateStripeConnect } }) => {
          console.log(updateStripeConnect);
        })
        .catch(error => {
          this.$buefy.toast.open({
            message: `${error}`,
            type: "is-danger"
          });
        });
    },
    getStripeConnectUrl(provider_id, connect_id, stripe_express_dashboard) {
      const filterData = {
        provider_id: parseInt(provider_id),
        stripe_express_dashboard: stripe_express_dashboard
      };
      if (connect_id) {
        filterData['connect_id'] = connect_id;
      }
      return this.$apollo
        .query({
          query: GET_SRTIPE_CONNECT_URL,
          variables: {
            input: {
              filter: filterData
            }
          },
          update: data => data
        })
        .catch(error => {
          return this.$buefy.toast.open({
            message: `${error}`,
            type: "is-danger"
          });
        });
    },
    getStripeConnectStatus(provider_id) {
      return this.$apollo
        .query({
          query: PROVIDER_STRIPE_CONNECT,
          variables: {
            provider_id: provider_id
          },
          update: data => data
        })
        .catch(error => {
          return this.$buefy.toast.open({
            message: `${error}`,
            type: "is-danger"
          });
        });
    }
  }
};
</script>