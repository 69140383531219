<template>
  <div>
    <article data-v-5151c207="" class="light">
      <div data-v-5151c207="" class="media">
        <div data-v-5151c207="" class="media-content">
          <strong>
            <center>
              MeTime partners with stripe to simplify charging customers and
              perform payouts
            </center>
          </strong>
          <br>
          <div>
            <center>
              We'll need to verify some information to get you up and running. 
              This shouldn't take too long, but once your connected account is enabled,
              payments from your patients to you will be seamless and secure. Your 
              customers will be able to pay you through the app directly.
            </center>
            <br>
          </div>
        </div>
      </div>
      <div class="level-item">
        <b-button @click="getEnablePayment" class="is-primary">
          Enable payments
        </b-button>
      </div>
    </article>
    <article data-v-5151c207="" class="light">
      <div data-v-5151c207="" class="media">
        <div data-v-5151c207="" class="media-content">
          <center>
            <i>
              If you have completed configuration already, check back here in a 
              little while. It can take up to 60 minutes to activate your account
            </i>
          </center>
        </div>
      </div>
    </article>
  </div>
</template>
<script>
import { mapState } from "vuex";
import {mapGetters} from "vuex";
import PlanStripeConnectHelper from "@/mixins/PlanStripeConnectHelper";

export default {
  name: "EnablePayment",
  components: { },
  mixins: [PlanStripeConnectHelper],
  data() {
    return {
      stripeConnectLink: null
    };
  },
  computed: {
    ...mapState(["meUser"]),
    ...mapGetters(["myProviders"]),
  },
  methods: {
    async getEnablePayment() {
      this.getStripeConnectUrl(this.myProviders[0]['id'], 0, false).then(
        ({ data: { getStripeConnectUrl } }) => {
          if (getStripeConnectUrl) {
            this.stripeConnectLink =
              getStripeConnectUrl.data.stripe_connect_url;

            const link = document.createElement("a");
            link.setAttribute("href", this.stripeConnectLink);
            link.click();
          }
        }
      );
    }  
  }
};
</script>
