<template>
  <section class="section">
    <title-bar>
      Create Article
      <router-link
        v-if="isAdmin"
        to="/"
        class="button is-primary"
        slot="button"
      >
        Dashboard
      </router-link>
    </title-bar>

    <ArticleForm
      @formSubmit="createArticle"
      :resetSubmitSwitch="resetSubmitSwitch"
    />
  </section>
</template>

<script>
import TitleBar from "../../base/components/TitleBar";
import ArticleForm from "../components/ArticleForm";
import { CREATE_ARTICLE } from "../../../_graphql/Mutations/mutations";
import { mapGetters } from "vuex";

export default {
  name: "CreateArticle",
  data() {
    return {
      resetSubmitSwitch: false
    };
  },
  components: { TitleBar, ArticleForm },
  computed: {
    ...mapGetters(["userRole"]),
    isAdmin() {
      return this.userRole && this.userRole == "ADMINISTRATOR";
    }
  },
  methods: {
    createArticle(articleForm) {
      const mutation = CREATE_ARTICLE;
      const variables = {
        file: articleForm.image,
        input: {
          name: articleForm.name,
          slug: articleForm.slug,
          title: articleForm.title,
          content: articleForm.content,
          description: articleForm.description,
          meta_title: articleForm.meta_title,
          meta_keywords: articleForm.meta_keywords.join(","),
          meta_description: articleForm.meta_description,
          draft: articleForm.draft,
          reading_time: articleForm.reading_time
        }
      };
      this.$apollo.provider.defaultClient
        .mutate({
          mutation: mutation,
          variables: variables
        })
        .catch(error => {
          return error;
        })
        .then(data => {
          let createArticle = data.data ? data.data.createArticle : false;
          if (createArticle) {
            this.$buefy.toast.open({
              message: "Article created successfully.",
              type: "is-success",
              duration: 1500
            });
            this.$buefy.toast.open({
              message: "Redirecting...",
              type: "is-info",
              duration: 1500
            });
            setTimeout(() => {
              this.$router.push("/articles");
            }, 3000);
          } else {
            this.$buefy.toast.open({
              message: "Something went wrong.",
              type: "is-danger",
              duration: 2000
            });
            setTimeout(() => {
              this.resetSubmitSwitch = !this.resetSubmitSwitch;
            }, 4500);
          }
        });
    }
  }
};
</script>
