<template>
  <div>
    You have made a payment request of
    <strong>{{ currencySymbol + data.amount }}</strong> for
    <strong class="payment-reason">{{ data.reason }}</strong>
  </div>
</template>

<script>
export default {
  props: ["data"],
  computed: {
    currencySymbol() {
      let symbol = "";
      switch (this.data.currency) {
        case "EUR":
          symbol = "€";
          break;
        case "GBP":
          symbol = "£";
          break;
        case "USD":
          symbol = "$";
          break;
        default:
          symbol = "€";
          break;
      }
      return symbol;
    }
  }
};
</script>

<style scoped>
.payment-reason {
  word-break: break-word;
}
</style>
