<template>
  <div class="section">
    <notification type="light" v-if="isVendor">
      <template v-slot:icon>
        <img
          src="@/assets/img/icon-11.png"
          class="icon icon-11 is-large"
          style=""
        />
      </template>
      <template v-slot:message>
        To connect, chat, and convert, be sure to log in to your Admin Panel
        frequently to see your new matches.
      </template>
    </notification>

    <notification type="light" v-if="isVendor">
      <template v-slot:icon>
        <img src="@/assets/img/icon-3.png" class="icon is-large" />
      </template>
      <template v-slot:message>
        These are your matches! View a match and click the View Journey tab to
        get more information on the User and their preferences, including photos
        if uploaded.
      </template>
    </notification>
    <notification type="light" v-if="isVendor">
      <template v-slot:icon>
        <img src="@/assets/img/icon-2.png" class="icon is-large" />
      </template>
      <template v-slot:message>
        Once you’ve reviewed the Journey, you can start a chat, make
        recommendations, ask for more information, make an offer, and discuss
        appointments using the calendar feature.
      </template>
    </notification>
    <h1 class="title has-text-left">
      <div>
        <span class="icon">
          <i class="fas fa-inbox" />
        </span>
        Inbox
      </div>
      <DropdownFilter
        position="is-bottom-left"
        input-placeholder="Min 3 chars"
        title="Filters"
        :items="filterDropdownItems"
        :filters="filters"
        :inputFilters="inputFilters"
        @toggleFilter="toggleFilter"
      ></DropdownFilter>
    </h1>
    <div class="container" style="width:100%;max-width:3400px;">
      <ProvidersListTable :filter-params="queryParams.query" />
    </div>
  </div>
</template>

<script>
import ProvidersListTable from "../components/ProvidersListTable";
import Notification from "../../base/components/Notification";
import { createNamespacedHelpers } from "vuex";
import DropdownFilter from "../../base/components/DropdownFilter";

const { mapMutations } = createNamespacedHelpers("messages");

export default {
  data() {
    return {
      filterDropdownItems: [
        {
          name: "u.first_name",
          title: "First name",
          type: "input"
        },
        {
          name: "u.last_name",
          title: "Last name",
          type: "input"
        }
      ],
      filters: {
        "u.first_name": "",
        "u.last_name": ""
      },
      inputFilters: {
        "u.first_name": "",
        "u.last_name": ""
      },
      queryParams: {
        filter: {},
        query: ""
      }
    };
  },
  components: { ProvidersListTable, Notification, DropdownFilter },
  computed: {
    isVendor() {
      const meUser = this.$store.state.meUser;
      return meUser && meUser.user.role == "VENDOR";
    }
  },
  methods: {
    ...mapMutations(["setMessagesStatusFilter"]),
    toggleFilter(payload) {
      const filterName = payload.filterName;
      const value = payload.optionValue;
      this.filters[filterName] =
        this.filters[filterName] != ""
          ? this.filters[filterName] == value
            ? ""
            : value
          : value;
    }
  },
  created() {
    this.setMessagesStatusFilter(
      this.$route.params.status == "active" ? "OPEN" : "!OPEN"
    );
  },
  watch: {
    filters: {
      handler(newVal) {
        let newFilter = {};
        let queryFilter = "";
        Object.keys(newVal).forEach(item => {
          if (item == "u.first_name") {
            if (newVal[item].length >= 3) {
              queryFilter += ",u.first_name%" + newVal[item];
            }
          } else if (item == "u.last_name") {
            if (newVal[item].length >= 3) {
              queryFilter += ",u.last_name%" + newVal[item];
            }
          } else {
            newFilter[item] = newVal[item];
          }
        });
        this.queryParams.filter = newFilter;
        this.queryParams.query = queryFilter;
      },
      deep: true
    }
  }
};
</script>

<style lang="scss" scoped>
.title {
  display: flex;
  justify-content: space-between;
}
h1 {
  background: none #e6eff2;
  padding: 20px 25px;
  color: #444;
  text-shadow: -2px 2px 0px #fff;
  border-radius: 10px 10px 0px 0px;
  border-bottom: 1px solid #8c9bad;
  margin: 0 !important;
}
// #noteMessages {
//   background-color: #e6eff2;
//   color: #0ca1d1;
// }
.icon-11 {
  height: 2rem !important;
  width: 2rem !important;
  margin: 0 0.5rem;
}
</style>
