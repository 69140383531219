<template>
  <section class="customSection">
    <div class="is-title-bar">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <h1 class="title">Create User</h1>
          </div>
        </div>
      </div>
    </div>
    <UserForm
      submitText="Create User"
      @formSubmit="createUser"
      :resetSwitch="resetSwitch"
    />
  </section>
</template>

<script>
import ValidationErrors from "../../../mixins/ValidationErrors"; // TODO: remove this once certain it's not needed outside UserForm
import UserForm from "../components/UserForm";
import { CREATE_USER } from "../../../_graphql/Mutations/mutations";

export default {
  mixins: [ValidationErrors],
  components: { UserForm },
  data() {
    return {
      resetSwitch: false
    };
  },
  methods: {
    createUser(userForm) {
      const mutation = CREATE_USER;
      const variables = {
        userInput: {
          firstName: userForm.firstName,
          lastName: userForm.lastName,
          email: userForm.email,
          password: userForm.password,
          country: userForm.country ? userForm.country.name : null,
          city: userForm.city ? userForm.city.name : null,
          role: userForm.role,
          age: userForm.age,
          gender: userForm.gender ? userForm.gender : "NONE",
          avatar: userForm.avatar
        }
      };
      this.$apollo.provider.defaultClient
        .mutate({
          mutation: mutation,
          variables: variables
        })
        .catch(error => {
          return this.$buefy.toast.open({
            message: `${error}`,
            type: "is-danger",
            duration: 3000
          });
        })
        .then(data => {
          let createUser = data.data.createUser;
          if (createUser.success) {
            this.$buefy.toast.open({
              message: "User created successfully.",
              type: "is-success",
              duration: 1500
            });
            this.$buefy.toast.open({
              message: "Redirecting...",
              type: "is-info",
              duration: 1500
            });
            setTimeout(() => {
              this.$router.push("/users");
            }, 3000);
          } else {
            this.$buefy.toast.open({
              message: createUser.errors.join(", "),
              type: "is-danger",
              duration: 3000
            });
          }
        });
    }
  }
};
</script>

<style scoped></style>
