import Payments from "./views/Payments";
import { Role } from "../../_helpers/roles";

const profileRoute = {
  path: "/payments",
  name: "Payments",
  component: Payments,
  meta: { authorize: [Role.Vendor, Role.Admin] }
};

export default [profileRoute];