<template>

  <section class="section">
    <div class="card-content">
      <div class="is-title-bar">
        <div class="level">
          <div class="level-left">
            <div class="level-item">
              <h1 class="title">Welcome, {{ meUser.user.username }}!</h1>
            </div>
          </div>
        </div>
      </div>
      <notification v-if="isVendor" type="light">
        <template v-slot:icon>
          <img src="@/assets/img/icon-1.png" class="icon is-large" />
        </template>
        <template v-slot:message>
          Here you can monitor how many clients match with your practice based
          on their preferences and the services you provide. These are total
          <strong>matches. Active conversations</strong> are the number of chats
          that are ongoing with clients or patients.
          <strong>Unique leads</strong> are the number of actual clients rather
          than the number of chats. When clients you chat with accept an offer
          within a chat, they are shown here as <strong>conversions</strong>.
        </template>
      </notification>

      <tiles v-if="isAdmin && administratorStats">
        <cardWidget
          id="registrations"
          class="tile is-child is-multiline"
          type="is-primary"
          icon="account-multiple"
          size="is-large"
          :number="administratorStats.data.total_providers_current_month.value"
          label="Provider Registrations"
          subLabel="in current month"
        />
        <cardWidget
          id="acceptedOffer"
          class="tile is-child"
          type="is-success"
          size="is-large"
          icon="chart-timeline-variant"
          :number="
            administratorStats.data.total_accept_offers_current_month.value
          "
          label="Conversions (Accepted Offers)"
          subLabel="in current month"
        />
      </tiles>
      <div v-if="isAdmin && administratorStats" class="columns is-multiline">
        <div class="column is-12">
          <div class="card">
            <header class="card-header">
              <p class="card-header-title">
                Total Provider Registrations per Country
              </p>
            </header>
            <div class="card-content columns is-multiline">
              <MapChart
                style="height: 400px;"
                class="column is-10"
                :countriesData="
                  administratorStats.data.total_providers_per_country
                "
              ></MapChart>
              <div class="column is-2">
                <div class="level-item has-widget-icon">
                  <div class="is-widget-icon">
                    <b-icon
                      icon="account-multiple"
                      size="is-large"
                      type="is-primary"
                    />
                  </div>
                </div>
                <div class="title is-3 has-text-primary">
                  {{ administratorStats.data.total_providers.value }}
                </div>
                <div class="title is-6 has-text-primary">
                  Total Registrations
                </div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-12">
          <div class="card">
            <header class="card-header">
              <p class="card-header-title">
                Provider Registrations
              </p>
            </header>
            <div class="card-content">
              <line-chart
                :series="AdminRegsChartData"
                :chart-data="administratorStats.data.total_providers_per_month"
                style="padding-bottom: 3.5rem;"
              ></line-chart>
            </div>
          </div>
        </div>
        <div class="column is-12">
          <div class="card">
            <header class="card-header">
              <p class="card-header-title">
                Unique leads
              </p>
            </header>
            <div class="card-content">
              <line-chart
                :series="AdminLeadsChartData"
                :chart-data="administratorStats.data.total_leads_per_month"
                style="padding-bottom: 3.5rem;"
              ></line-chart>
              <div class="subtitle is-5">
                Total: {{ administratorStats.data.total_leads.value }}
              </div>
            </div>
          </div>
        </div>
        <div class="column is-9">
          <div class="card line-card">
            <header class="card-header">
              <p class="card-header-title">Total matches</p>
            </header>
            <div class="card-content">
              <line-chart
                :series="AdminConversationsChartData"
                :chart-data="
                  administratorStats.data.total_conversations_per_month
                "
              ></line-chart>
            </div>
          </div>
        </div>
        <div class="column is-3 is-grouped">
          <div class="tile is-child is-vertical">
            <cardWidget
              class="tile is-child customWidgetSizeStyle"
              icon="chart-timeline-variant"
              :number="administratorStats.data.total_conversations.inactive"
              label="Provider matches"
              type="is-primary"
              subLabel="total"
            />
            <cardWidget
              class="tile is-child customWidgetIconStyle customWidgetSizeStyle"
              icon="chart-timeline-variant"
              :number="administratorStats.data.total_conversations.active"
              label="Active Conversations"
              subLabel="total"
            />
          </div>
        </div>
        <div class="column is-12">
          <div class="card">
            <header class="card-header">
              <p class="card-header-title">
                Conversions (accepted offers)
              </p>
            </header>
            <div class="card-content">
              <bar-chart
                :chart-data="
                  administratorStats.data.total_accept_offers_per_month
                "
                :series="AdminConversionsChartData"
                style="padding-bottom: 3.5rem;"
              ></bar-chart>
              <div class="subtitle is-5">
                Total: {{ administratorStats.data.total_accept_offers.value }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-else-if="isVendor && vendorStats" class="columns is-multiline">
        <div class="column is-12">
          <div class="card">
            <header class="card-header">
              <p class="card-header-title">
                Conversions (accepted offers)
              </p>
            </header>
            <div class="card-content">
              <line-chart
                :series="VendorConversionsChartData"
                :chart-data="vendorStats.data.total_accept_offers_per_month"
              ></line-chart>
              <div class="subtitle is-5">
                Total: {{ vendorStats.data.total_accept_offers.value }}
              </div>
            </div>
          </div>
        </div>
        <div class="column is-12">
          <div class="card">
            <header class="card-header">
              <p class="card-header-title">
                Unique leads
              </p>
            </header>
            <div class="card-content">
              <line-chart
                :series="VendorLeadsChartData"
                :chart-data="vendorStats.data.total_leads_per_month"
              ></line-chart>
              <div class="subtitle is-5">
                Total: {{ vendorStats.data.total_leads.value }}
              </div>
            </div>
          </div>
        </div>
        <div class="column is-9">
          <div class="card line-card">
            <header class="card-header">
              <p class="card-header-title">My total matches</p>
            </header>
            <div class="card-content">
              <line-chart
                :series="VendorMatchesChartData"
                :chart-data="vendorStats.data.total_conversations_per_month"
              ></line-chart>
            </div>
          </div>
        </div>
        <div class="column is-3 is-grouped">
          <div class="tile is-child is-vertical">
            <cardWidget
              class="tile is-child customWidgetSizeStyle"
              type="is-primary"
              icon="chart-timeline-variant"
              :number="vendorStats.data.total_conversations.inactive"
              label="My matches"
              subLabel="total"
            />
            <cardWidget
              class="tile is-child customWidgetIconStyle customWidgetSizeStyle"
              icon="chart-timeline-variant"
              :number="vendorStats.data.total_conversations.active"
              label="Active Conversations"
              subLabel="total"
            />
          </div>
        </div>
      </div>
    </div>
    <b-loading
      :active.sync="isLoading"
      :is-full-page="false"
      :can-cancel="false"
    >
      <b-icon pack="fas" icon="sync-alt" size="is-large" custom-class="fa-spin">
      </b-icon>
    </b-loading>
  </section>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import tiles from "../../base/components/Tiles";
import cardWidget from "../components/CardWidget";
import BarChart from "../components/Charts/BarChart";
import MapChart from "../components/Charts/MapChart";
import LineChart from "../components/Charts/LineChart";
import Notification from "../../base/components/Notification";
import {
  ADMIN_STATISTICS,
  VENDOR_STATISTICS
} from "../../../_graphql/Queries/queries";
import LoadingState from "../../../mixins/LoadingState";

export default {
  name: "Dashboard",
  data() {
    return {
      AdminRegsChartData: [
        {
          date: "name",
          value: "value",
          name: "Registrations"
        }
      ],
      AdminLeadsChartData: [
        {
          date: "name",
          value: "value",
          name: "Leads"
        }
      ],
      AdminConversationsChartData: [
        {
          date: "name",
          value: "inactive",
          name: "Provider Matches"
        },
        {
          date: "name",
          value: "active",
          name: "Active Conversations"
        }
      ],
      AdminConversionsChartData: [
        {
          date: "name",
          value: "value",
          name: "Conversions"
        }
      ],
      VendorMatchesChartData: [
        {
          date: "name",
          value: "inactive",
          name: "Matches"
        },
        {
          date: "name",
          value: "active",
          name: "Active Conversations"
        }
      ],
      VendorConversionsChartData: [
        {
          name: "Conversions",
          date: "name",
          value: "value"
        }
      ],
      VendorLeadsChartData: [
        {
          name: "Leads",
          date: "name",
          value: "value"
        }
      ]
    };
  },
  mixins: [LoadingState],
  apollo: {
    administratorStats: {
      query: ADMIN_STATISTICS,
      update: data => data.administratorStats,
      skip() {
        return !this.isAdmin;
      }
    },
    vendorStats: {
      query: VENDOR_STATISTICS,
      update: data => data.vendorStats,
      skip() {
        return !this.isVendor;
      }
    }
  },
  components: {
    tiles,
    cardWidget,
    BarChart,
    MapChart,
    LineChart,
    Notification
  },
  beforeRouteEnter (to, from, next) {
    next(async () => {
      if (from.path === '/authenticate') {
        window.location.reload()
        // const data = await vm.$apollo.query({ query: MY_PROVIDERS })
        // const myProviders = _.get(data, "data.myProviders.data", []);
        // vm.$store.commit("setMyProviders", myProviders);
        // if (!vm.myProviders?.length) {
        //   await vm.$router.push("/providers/new")
        //   return false
        // }
      }
    })
  },
  computed: {
    ...mapState(["meUser"]),
    ...mapGetters(["isLoading", "myProviders"]),
    isVendor() {
      return this.meUser && this.meUser.user.role == "VENDOR";
    },
    isAdmin() {
      return this.meUser && this.meUser.user.role == "ADMINISTRATOR";
    }
  }
};
</script>

<style lang="scss" scoped>
.presentation-banner {
  width: 100%;
  margin: 0 auto;
  border-radius: 6px;
  overflow: hidden;
  height: 100%;
  display: flex;
  justify-content: center;
}

.presentation-banner-img {
  object-fit: cover;
}
.vue-world-map,
.is-widget-icon {
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.09);
}
.title.is-3 {
  padding-top: 15px;
  margin-bottom: 5px;
}
.title.is-6 {
  margin-top: 0px;
}

.card {
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.09);
  .card-header {
    background-color: #e6eff2;
    .card-header-title {
      color: #0ca1d1;
    }
  }
}

.notification.is-info {
  background-color: #0ba1d1 !important;
}
</style>
