<template>
  <section class="section">
    <title-bar>
      Edit Page
      <router-link
        v-if="isAdmin"
        to="/"
        class="button is-primary"
        slot="button"
      >
        Dashboard
      </router-link>
    </title-bar>

    <PageForm
      v-if="page"
      @formSubmit="editPage"
      :editModel="page ? page : null"
      :resetSubmitSwitch="resetSubmitSwitch"
    />
  </section>
</template>

<script>
import TitleBar from "../../base/components/TitleBar";
import PageForm from "../components/PageForm";
import { PAGE } from "../../../_graphql/Queries/queries";
import { UPDATE_PAGE } from "../../../_graphql/Mutations/mutations";
import { mapGetters } from "vuex";

export default {
  name: "EditPage",
  components: { TitleBar, PageForm },
  data() {
    return {
      resetSubmitSwitch: false
    };
  },
  computed: {
    ...mapGetters(["userRole"]),
    isAdmin() {
      return this.userRole && this.userRole == "ADMINISTRATOR";
    }
  },
  methods: {
    editPage(pageForm) {
      const mutation = UPDATE_PAGE;
      const variables = {
        id: this.$route.params.id,
        input: {
          name: pageForm.name,
          slug: pageForm.slug,
          title: pageForm.title,
          content: pageForm.content,
          status: pageForm.status,
          meta_title: pageForm.meta_title,
          meta_keywords: pageForm.meta_keywords.join(", "),
          meta_description: pageForm.meta_description,
          cms_keywords: pageForm.cms_keywords,
          content_json: JSON.stringify(pageForm.content_json)
        }
      };
      this.$apollo.provider.defaultClient
        .mutate({
          mutation: mutation,
          variables: variables
        })
        .catch(error => {
          return error;
        })
        .then(data => {
          const updatePage = data.data ? data.data.updatePage : false;
          if (updatePage) {
            this.$buefy.toast.open({
              message: "Page updated successfully.",
              type: "is-success",
              duration: 1500
            });
            this.$buefy.toast.open({
              message: "Redirecting...",
              type: "is-info",
              duration: 1500
            });
            setTimeout(() => {
              this.$router.push("/pages");
            }, 3000);
          } else {
            this.$buefy.toast.open({
              message: "Something went wrong.",
              type: "is-danger",
              duration: 2000
            });
            setTimeout(() => {
              this.resetSubmitSwitch = !this.resetSubmitSwitch;
            }, 4500);
          }
        });
    }
  },
  apollo: {
    page: {
      query: PAGE,
      variables() {
        return {
          id: this.$route.params.id
        };
      }
    }
  }
};
</script>
