<template>
  <section>
    <b-modal
        :active.sync="isActive"
        has-modal-card
        trap-focus
        :on-cancel="close"
    >
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title"><strong>Add Procedure</strong></p>
        </header>
        <section class="modal-card-body">
          <ValidationObserver ref="addProcedure">
            <b-field horizontal label="Area">
              <div class="control">
                <ValidationProvider
                    rules="required|max:50"
                    v-slot="{ errors }"
                    name="Area"
                >
                  <b-select
                      id="area_id"
                      placeholder="Select area"
                      v-model="area_id"
                      required
                      expanded
                  >
                    <option
                        v-for="proceduresArea in proceduresAreas.data"
                        :value="proceduresArea.id"
                        :key="proceduresArea.id"
                    >
                      {{ proceduresArea.name }}
                    </option>
                  </b-select>
                  <span
                      v-for="error in errors"
                      :key="error.id"
                      class="has-text-danger help"
                  >{{ error }}</span
                  >
                </ValidationProvider>
              </div>
            </b-field>
            <b-field horizontal label="Name" label-for="name">
              <div class="control">
                <ValidationProvider
                    rules="required|max:50"
                    v-slot="{ errors }"
                    name="Name"
                >
                  <b-input
                      id="name"
                      placeholder="Name"
                      v-model="name"
                      :value="name"
                  />
                  <span
                      v-for="error in errors"
                      :key="error.id"
                      class="has-text-danger help"
                  >{{ error }}</span
                  >
                </ValidationProvider>
              </div>
            </b-field>
          </ValidationObserver>
        </section>
        <footer class="modal-card-foot">
          <button class="button" type="button" @click="close">
            Close
          </button>
          <button class="button is-primary" @click="create">Create</button>
        </footer>
      </div>
    </b-modal>
  </section>
</template>

<script>
import {UPDATE_PROVIDER,} from "../../../_graphql/Mutations/mutations";
import {PROCEDURES_AREAS} from "../../../_graphql/Queries/queries";
import ValidationErrors from "@/mixins/ValidationErrors";

export default {
  data() {
    return {
      isActive: false,
      area_id: "",
      name: "",
      proceduresAreas: [],
    };
  },
  mixins: [ValidationErrors],
  methods: {
    openModal() {
      this.isActive = true;
    },
    async create() {
      this.$refs.addProcedure.validate().then(async success => {
        if (!success) {
          this.refName = "addProcedure";
          this.handleErrors();
          return;
        }

        const createdProcedure = {
          procedures: {
            name: this.name,
            procedures_areas_id: parseInt(this.area_id),
          }
        };
        const providerId = parseInt(this.$route.params.providerId);
        const payload = {
          mutation: UPDATE_PROVIDER,
          queryName: "updateProvider"
        };

        const variables = {
          id: providerId,
          input: createdProcedure
        };

        this.$store.commit("provider/collection/editModel", variables);
        await this.$store.dispatch("provider/collection/update", payload);
        this.close();
        this.$emit("updatedProcedures");
      });
    },
    close() {
      this.isActive = false;
      this.$refs.addProcedure.reset();
      this.name = "";
      this.area_id = "";
    },
  },
  apollo: {
    proceduresAreas: {
      query: PROCEDURES_AREAS,
      variables: {
        update: data => data.proceduresAreas
      }
    }
  }
};
</script>
