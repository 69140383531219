var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('card-component',{attrs:{"title":"Change Password","icon":"lock"}},[(_vm.me.user.role != 'VENDOR')?_c('div',{staticClass:"card-message"},[_vm._v(" To change your password, please contact technical support. ")]):(_vm.me.provider != 'LOCAL')?_c('div',{staticClass:"card-message"},[_vm._v(" Your MeTime account is signed up through "+_vm._s(_vm.me.provider[0] + _vm.me.provider.slice(1).toLowerCase())+". "),_c('br'),_vm._v(" To change your password, please "),_c('strong',[_c('a',{attrs:{"href":"https://id.metime.com/signup"}},[_vm._v("register")])]),_vm._v(" with a different email. ")]):_c('ValidationObserver',{ref:"changePasswordFormValidator",staticClass:"userForm"},[_c('div',{staticClass:"tile is-ancestor"},[_c('section',{staticClass:"tile is-parent is-9 is-vertical"},[_c('div',{staticClass:"columns",staticStyle:{"flex-wrap":"wrap"}},[_c('ValidationProvider',{staticClass:"is-11 column",attrs:{"rules":"required|min:2","name":"Current Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"type":{ 'is-danger': errors[0], 'is-primary': valid },"label":"Current Password"}},[_c('b-input',{attrs:{"type":"password","password-reveal":"","trim":""},model:{value:(_vm.form.currentPassword),callback:function ($$v) {_vm.$set(_vm.form, "currentPassword", $$v)},expression:"form.currentPassword"}})],1),_vm._l((errors),function(error){return _c('span',{key:error.id,staticClass:"has-text-danger help"},[_vm._v(_vm._s(error))])})]}}])}),_c('ValidationProvider',{staticClass:"is-11 column",attrs:{"rules":{
              required: true,
              passRegex: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/
            },"name":"New Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
            var valid = ref.valid;
return [_c('b-field',{attrs:{"type":{ 'is-danger': errors[0], 'is-primary': valid },"label":"New Password"}},[_c('b-input',{attrs:{"type":"password","password-reveal":"","trim":""},model:{value:(_vm.form.newPassword),callback:function ($$v) {_vm.$set(_vm.form, "newPassword", $$v)},expression:"form.newPassword"}})],1),_vm._l((errors),function(error){return _c('span',{key:error.id,staticClass:"has-text-danger help"},[_vm._v(_vm._s(error))])})]}}])}),_c('ValidationProvider',{staticClass:"is-11 column",attrs:{"rules":"required|confirmed:New Password","name":"Confirm New Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
            var valid = ref.valid;
return [_c('b-field',{attrs:{"type":{ 'is-danger': errors[0], 'is-primary': valid },"label":"Confirm New Password"}},[_c('b-input',{attrs:{"type":"password","password-reveal":"","trim":""},model:{value:(_vm.form.confirmNewPassword),callback:function ($$v) {_vm.$set(_vm.form, "confirmNewPassword", $$v)},expression:"form.confirmNewPassword"}})],1),_vm._l((errors),function(error){return _c('span',{key:error.id,staticClass:"has-text-danger help"},[_vm._v(_vm._s(error))])})]}}])})],1)])]),_c('div',{staticClass:"level",staticStyle:{"padding-top":"20px"}},[_c('div',{staticClass:"level-left"},[_c('div',{staticClass:"level-item"},[_c('b-button',{staticClass:"is-primary",attrs:{"loading":_vm.sendingForm},on:{"click":_vm.submitForm}},[_vm._v("Submit")])],1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }