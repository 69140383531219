<template>
  <div class="card">
    <div class="card-header">
      <div style="background-color: #e6eff2;" class="card-header-title">
        <div style=" color: #0ca1d1" class="title is-6">
          {{ label }}
        </div>
      </div>
    </div>
    <div class="card-content">
      <div class="level">
        <div class="level-left">
          <div v-if="icon" class="level-item has-widget-icon">
            <div class="is-widget-icon">
              <b-icon :icon="icon" :size="size" :type="type" />
            </div>
          </div>
        </div>
        <div class="level-right column is-8">
          <div id="level-item-right" class="level-item ">
            <div class="cardWidgetContent">
              <h3 class="title">
                <GrowingNumber
                  :value="number"
                  :prefix="prefix"
                  :suffix="suffix"
                />
              </h3>
              <h5>
                <div class="is-spaced">
                  {{ subLabel }}
                </div>
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GrowingNumber from "../components/GrowingNumber";
export default {
  name: "CardWidget",
  components: { GrowingNumber },
  props: {
    title: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: null
    },
    size: {
      type: String,
      default: ""
    },
    number: {
      type: Number,
      default: 0
    },
    prefix: {
      type: String,
      default: null
    },
    suffix: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    subLabel: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: null
    }
  }
};
</script>
<style lang="scss" scoped>
.cardWidgetContent {
  padding-top: 15px;
}

#level-item-right {
  padding-bottom: 15px;
}

.is-widget-icon {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
}

.tile.is-child {
  border: 1px solid #e6eff2;
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.1);
}
</style>
