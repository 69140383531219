<template>
  <div v-if="isVendor && hasProviders" class="section subscriptions-padding">
    <div class="card">
      <div class="card-header title is-5 has-text-primary">
        Subscription Plans
      </div>
      <div class="card-content card-content-header-padding">
        <div class="has-text-left">
          <div
            v-if="showSubscriptionPageHeaderMessages(providerSubscription)"
            class="title is-6 has-text-primary"
          >
            Enjoy MeTime Premium for FREE for 30 days
          </div>
          <div class="content">
            <p v-if="showSubscriptionPageHeaderMessages(providerSubscription)">
              A MeTime Premium subscription offers an enhanced profile to
              showcase your practice or clinic with MeTime users.
            </p>
            <p
              v-if="
                providerSubscription &&
                  providerSubscription.is_subscribed &&
                  providerSubscription.is_trialing &&
                  providerSubscription.sub_product_name === PRODUCT_TYPE_PREMIUM
              "
            >
              You are currently on the MeTime Premium 30 days FREE trial.
            </p>
            <p
              v-if="providerSubscription && providerSubscription.is_subscribed"
            >
              To view and manage your subscription
              <a @click="upgradePlan(null)">click here</a>
            </p>
          </div>
        </div>
        <div class="card-content">
          <div class="switch-field-center">
            <b-field expanded>
              <div class="has-text-primary switch-field-label">Yearly</div>
              <b-switch
                v-model="isMonthly"
                passive-type="is-primary"
                type="is-primary"
              >
              </b-switch>
              <div class="has-text-primary">Monthly</div>
            </b-field>
          </div>
        </div>
        <div id="cardPlans" class="tile">
          <div class="tile is-parent">
            <div class="tile">
              <div class="card">
                <div class="card-header">
                  <div class="card-header-title card-header-padding">
                    <div class="title is-5 has-text-primary">
                      {{ PRODUCT_TYPE_BASIC.toUpperCase() }}
                    </div>
                  </div>
                </div>
                <div class="card-content has-text-centered">
                  <div class="title is-5">Free</div>
                  <div class="subtitle is-6 has-text-primary">
                    Upgrade any time to premium
                  </div>
                  <PlanInfo
                    :class="getFeaturesListClass(PRODUCT_TYPE_BASIC)"
                    :plan="basicPlan"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="tile is-parent">
            <div class="tile">
              <div class="card">
                <div class="card-header card-header-padding">
                  <div class="card-header-title card-header-padding">
                    <div class="title is-5 has-text-primary">
                      {{ PRODUCT_TYPE_PREMIUM.toUpperCase() }}
                    </div>
                  </div>
                </div>
                <div class="card-content has-text-centered">
                  <div v-if="!isMonthly" class="title is-4">
                    €69
                    <span class="title is-6">
                      or £63/$82
                    </span>
                  </div>
                  <div v-if="!isMonthly" class="subtitle is-6 has-text-primary">
                    /month billed annually
                  </div>

                  <div v-if="isMonthly" class="title is-4">
                    €79
                    <span class="title is-6">
                      or £72/$92
                    </span>
                  </div>
                  <div v-if="isMonthly" class="subtitle is-6 has-text-primary">
                    /month billed monthly
                  </div>
                  <div class="mb-5">
                    <b-button
                      v-if="showUpgradeButtons(this.PRODUCT_TYPE_PREMIUM)"
                      type="is-primary"
                      @click="upgradePlan(PRODUCT_TYPE_PREMIUM)"
                      >{{ planButtonName }}</b-button
                    >
                  </div>
                  <PlanInfo
                    :class="getFeaturesListClass(PRODUCT_TYPE_PREMIUM)"
                    :plan="premiumPlan"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="tile is-parent">
            <div class="tile ">
              <div class="card">
                <div class="card-header">
                  <div class="card-header-title card-header-padding">
                    <div class="title is-5 has-text-primary">
                      {{ PRODUCT_TYPE_PRO.toUpperCase() }}
                    </div>
                  </div>
                </div>
                <div class="card-content has-text-centered">
                  <div v-if="!isMonthly" class="title is-4">
                    €139
                    <span class="title is-6">
                      or £125/$163
                    </span>
                  </div>
                  <div v-if="!isMonthly" class="subtitle is-6 has-text-primary">
                    /month billed annually
                  </div>
                  <div v-if="isMonthly" class="title is-4">
                    €159
                    <span class="title is-6">
                      or £144/$187
                    </span>
                  </div>
                  <div v-if="isMonthly" class="subtitle is-6 has-text-primary">
                    /month billed monthly
                  </div>
                  <div class="mb-5">
                    <b-button
                      v-if="showUpgradeButtons(this.PRODUCT_TYPE_PRO)"
                      type="is-primary"
                      @click="upgradePlan(PRODUCT_TYPE_PRO)"
                    >
                      {{ planButtonName }}
                    </b-button>
                  </div>
                  <PlanInfo
                    :class="getFeaturesListClass(PRODUCT_TYPE_PRO)"
                    :plan="proPlan"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="content">
          Relevant taxes (VAT) will be charged in addition to the above fees.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { PROVIDER_SUBSCRIPTIONS } from "@/_graphql/Queries/queries";
import PlanSubscriptionsHelper from "@/mixins/PlanSubscriptionsHelper";
import PlanInfo from "@/modules/base/components/PlanInfo";
import moment from "moment";
import { SYSTEM_SETTINGS } from "../../../_graphql/Queries/queries";

export default {
  components: { PlanInfo },
  mixins: [PlanSubscriptionsHelper],
  data() {
    return {
      isMonthly: false,
      providerSubscription: null,
      customerPortalLink: null,
      currentPlan: null,
      basicPlan: [
        "Provider’s Info",
        "3 Photos",
        "Specialties",
        "Experience",
        "Chat with clients and patients"
      ],
      bonusMinutesPro: null,
      bonusMinutesPremium: null
    };
  },
  created() {
    this.PRODUCT_TYPE_BASIC = "Basic";
    this.PRODUCT_TYPE_PREMIUM = "Premium";
    this.PRODUCT_TYPE_PRO = "Pro";

    this.PLAN_INTERVAL_MONTHLY = "Monthly";
    this.PLAN_INTERVAL_YEARLY = "Yearly";

    if (this.providerSubscription) {
      this.isMonthly =
        this.providerSubscription.interval === this.PLAN_INTERVAL_MONTHLY;
    }
  },
  computed: {
    ...mapState(["meUser"]),
    ...mapGetters(["myProviders"]),
    isVendor() {
      return this.meUser && this.meUser.user.role == "VENDOR";
    },
    hasProviders() {
      return this.myProviders.length > 0;
    },
    planButtonName() {
      if (
        this.providerSubscription &&
        this.providerSubscription.is_transferred
      ) {
        return "Add payment method";
      }

      return "Upgrade";
    },
    isOldProvider() {
      if (this.myProviders.length > 0) {
        const dateAdded = this.myProviders[0].date_added || null;

        return moment(dateAdded).isBefore("2021-02-24");
      }

      return false;
    },
    premiumPlan() {
      return [
        "Basic features PLUS",
        "10 Photos",
        "5 Videos",
        "Video consultations (includes " +
          this.bonusMinutesPremium +
          " min/mth)",
        "Video messages",
        "Main practitioners",
        "File share",
        "Premium support",
        "Payments"
      ];
    },
    proPlan() {
      return [
        "Premium features PLUS",
        "20 Photos",
        "10 Videos",
        "Video consultations (includes " + this.bonusMinutesPro + " min/mth)",
        "Pro support"
      ];
    }
  },
  methods: {
    upgradePlan(planName) {
      const planId = this.getPlanId(planName, this.isMonthly);
      const currentPlan = this.getCurrentPlan(this.providerSubscription);

      if (currentPlan === this.PRODUCT_TYPE_BASIC && planId !== null) {
        if (
          this.providerSubscription &&
          (this.providerSubscription.session_id ||
            !this.providerSubscription.is_subscribed)
        ) {
          this.updateSubscriptionCheckout(planId, this.providerSubscription);
        } else {
          this.create(planId);
        }
      } else {
        this.getCustomerPortalUrl(
          this.providerSubscription.provider_id,
          planId
        ).then(({ data: { getCustomerPortalUrl } }) => {
          if (getCustomerPortalUrl) {
            this.customerPortalLink =
              getCustomerPortalUrl.data.customer_portal_url;

            const link = document.createElement("a");
            link.setAttribute("href", this.customerPortalLink);
            link.click();
          }
        });
      }
    },
    showUpgradeButtons(productType) {
      if (
        !this.providerSubscription ||
        !this.providerSubscription.is_subscribed
      ) {
        return true;
      }

      if (
        productType === this.PRODUCT_TYPE_PREMIUM &&
        this.providerSubscription.is_transferred
      ) {
        return true;
      }

      const premiumProductIds = this.getPlanIds(this.PRODUCT_TYPE_PREMIUM);

      return (
        this.providerSubscription.hasOwnProperty("sub_plan_id") &&
        premiumProductIds.includes(this.providerSubscription.sub_plan_id) &&
        productType === this.PRODUCT_TYPE_PRO
      );
    },
    showSubscriptionPageHeaderMessages(providerSubscription) {
      if (this.isOldProvider) {
        return false;
      }

      if (
        !providerSubscription ||
        (!providerSubscription.is_subscribed &&
          !providerSubscription.free_trial)
      ) {
        return true;
      }

      return !providerSubscription.free_trial;
    },
    getFeaturesListClass(currentListPlan) {
      const showUpgradeButtonPro = this.showUpgradeButtons(
        this.PRODUCT_TYPE_PRO
      );
      const showUpgradeButtonPremium = this.showUpgradeButtons(
        this.PRODUCT_TYPE_PREMIUM
      );

      if (
        currentListPlan === this.PRODUCT_TYPE_BASIC &&
        (showUpgradeButtonPremium || showUpgradeButtonPro)
      ) {
        return "basic-features-list";
      }

      if (
        currentListPlan === this.PRODUCT_TYPE_PREMIUM &&
        this.showUpgradeButtons(this.PRODUCT_TYPE_PREMIUM)
      ) {
        return "with-button-features-list";
      }

      if (
        currentListPlan === this.PRODUCT_TYPE_PREMIUM &&
        this.showUpgradeButtons(this.PRODUCT_TYPE_PRO)
      ) {
        return "basic-features-list";
      }

      if (
        currentListPlan === this.PRODUCT_TYPE_PRO &&
        this.showUpgradeButtons(this.PRODUCT_TYPE_PRO)
      ) {
        return "with-button-features-list";
      }

      return null;
    }
  },
  apollo: {
    providerSubscriptions: {
      query: PROVIDER_SUBSCRIPTIONS,
      result({
        data: {
          providerSubscriptions: { data }
        }
      }) {
        this.providerSubscription = data.length > 0 ? data[0] : null;
      }
    },
    systemSettings: {
      query: SYSTEM_SETTINGS,
      variables() {
        return {
          input: {
            query: "key_name^BONUS_MINUTES_PRO;BONUS_MINUTES_PREMIUM"
          }
        };
      },
      result({ data: { systemSettings } }) {
        if (systemSettings) {
          systemSettings.data.forEach(setting => {
            if (setting.key_name == "BONUS_MINUTES_PRO") {
              this.bonusMinutesPro = setting.value;
            }
            if (setting.key_name == "BONUS_MINUTES_PREMIUM") {
              this.bonusMinutesPremium = setting.value;
            }
          });
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.subscriptions-padding {
  padding: 10px 24px;
}
.card {
  width: 100%;
  border-radius: 15px !important;
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.09);

  .card-content-header-padding {
    padding: 0 24px;
  }

  .card-header-padding {
    padding: 0 16px;
  }

  .card-header {
    padding: 1rem;
    text-align: center !important;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.04);
    border-radius: 5px !important;
  }

  .switch-field-center {
    display: inline-block;
  }

  .switch-field-label {
    margin-right: 8px;
  }
}
.basic-features-list {
  margin-top: 85px;
}

.with-button-features-list {
  margin-top: 20px;
}

ul {
  list-style: none;
  padding-left: 1rem;
}
li:before {
  content: "✔" !important;
  padding-right: 0.5rem;
  font-weight: bolder;
  color: #e29174;
}
.icon-color {
  color: #0ba1d1;
}

#cardPlans {
  .card {
    background-color: rgba(235, 239, 242, 0.25);
  }
  .title {
    margin-left: auto;
    margin-right: auto;
  }
  .premium-price {
    text-decoration: line-through;
  }
  .subtitle-position {
    margin-top: 20px;
  }
}
</style>
