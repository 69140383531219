<template>
  <div>
    <nav class="list-item breadcrumb" aria-label="breadcrumbs">
      <ul>
        <li
          v-for="(breadcrumb, idx) in breadcrumbs"
          :key="idx"
          @click="routeTo(idx)"
          :class="{ 'is-active': !breadcrumb.link }"
        >
          <router-link class="is-active" to="#">
            {{ breadcrumb.name }}
          </router-link>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
// import { AREA_NAME } from "../../_graphql/Queries/queries";
import { mapGetters } from "vuex";

export default {
  name: "Breadcrumb",
  data() {
    return {
      breadcrumbList: []
    };
  },
  mounted() {
    this.updateList();
  },
  watch: {
    $route() {
      this.updateList();
    }
  },
  computed: {
    ...mapGetters(["breadcrumbs"])
  },
  methods: {
    routeTo(pRouteTo) {
      if (this.breadcrumbs[pRouteTo].link) {
        this.$router.push(this.breadcrumbs[pRouteTo].link);
        pRouteTo === 0
          ? this.$store.commit("emptyBreadcrumbs")
          : this.$store.commit("popBreadcrumbs");
      }
    },
    updateList() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
    }
  }
};
</script>
