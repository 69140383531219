<template>
  <section>
    <b-modal
      :active.sync="isActive"
      has-modal-card
      trap-focus
      :on-cancel="closeModal"
    >
      <div class="modal-card">
        <section class="modal-card-body">
          <b-table
            v-if="providers && providers.meta"
            class="table is-fullwidth"
            :data="providers.data"
            default-sort="name"
            paginated
            backend-pagination
            :total="providers.meta.total_count"
            :per-page="providers.meta.per_page"
            :current-page="providers.meta.current_page"
            @page-change="onPageChange"
          >
            <template slot-scope="data">
              <b-table-column label="Avatar" field="avatar">
                <img
                  class="related-provider-avatar"
                  :src="data.row.avatar_url"
                  alt="Avatar"
                />
              </b-table-column>
              <b-table-column label="Provider Name" field="name">
                {{ data.row.name }}
              </b-table-column>
              <b-table-column label="Location" field="location">
                {{
                  data.row.address.locale.city.name +
                    ", " +
                    data.row.address.locale.country.name
                }}
              </b-table-column>
            </template>
          </b-table>
        </section>
        <footer class="modal-card-foot">
          <button class="button" type="button" @click="closeModal">
            Close
          </button>
        </footer>
      </div>
    </b-modal>
  </section>
</template>

<script>
export default {
  data() {
    return {
      isActive: false,
      providers: {},
      queryParams: {
        limit: 25,
        offset: 0
      }
    };
  },
  methods: {
    openModal(data) {
      this.providers = data;
      this.isActive = true;
    },
    closeModal() {
      this.isActive = false;
      this.providers = {};
    },
    onPageChange(page) {
      this.queryParams.offset = this.providers.meta.per_page * (page - 1);
      this.$emit("pageChanged", this.queryParams);
    }
  }
};
</script>

<style lang="scss" scoped>
.related-provider-avatar {
  width: 100px;
  height: 100px;
  object-fit: contain;
  border-radius: 50%;
}
.modal-card-foot {
  border-top: 1px solid whitesmoke;
}
</style>
