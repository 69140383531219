var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('card-component',[(_vm.form.slug === 'footer')?_c('h2',[_vm._v("For All pages")]):_vm._e(),(_vm.form.slug === 'get-started-providers')?_c('h2',[_vm._v("For pages: medical-professionals/articles/list")]):_vm._e(),(_vm.form.slug === 'get-started-patients')?_c('h2',[_vm._v("For pages: patients/app, patients/blog/list, patients/solutions, contact, our-mission")]):_vm._e(),_c('ValidationObserver',{ref:"componentFormValidator",staticClass:"userForm"},[_c('div',{staticClass:"columns is-variable is-7"},[_c('div',{staticClass:"column is-9"},[_c('div',{staticClass:"columns is-variable is-5 is-multiline is-mobile"},[_c('ValidationProvider',{staticClass:"column is-6",attrs:{"rules":"min:2","name":"Title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"type":{ 'is-danger': errors[0], 'is-primary': valid },"label":"Title"}},[_c('b-input',{attrs:{"trim":""},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}})],1),_vm._l((errors),function(error){return _c('span',{key:error.id,staticClass:"has-text-danger help"},[_vm._v(_vm._s(error))])})]}}])}),_c('ValidationProvider',{staticClass:"column is-6",attrs:{"rules":"required|min:2","name":"Slug"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"type":{ 'is-danger': errors[0], 'is-primary': valid },"label":"Slug"}},[_c('b-input',{attrs:{"placeholder":"example-slug-name","trim":""},model:{value:(_vm.form.slug),callback:function ($$v) {_vm.$set(_vm.form, "slug", $$v)},expression:"form.slug"}})],1),_vm._l((errors),function(error){return _c('span',{key:error.id,staticClass:"has-text-danger help"},[_vm._v(_vm._s(error))])})]}}])}),_c('ValidationProvider',{staticClass:"column is-6",attrs:{"rules":"min:2","name":"Page"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"type":{ 'is-danger': errors[0], 'is-primary': valid },"label":"Page"}},[_c('b-input',{attrs:{"trim":""},model:{value:(_vm.form.page),callback:function ($$v) {_vm.$set(_vm.form, "page", $$v)},expression:"form.page"}})],1),_vm._l((errors),function(error){return _c('span',{key:error.id,staticClass:"has-text-danger help"},[_vm._v(_vm._s(error))])})]}}])})],1)])]),(!!_vm.contentFromKeywords.length)?_c('div',{staticClass:"columns is-variable is-5 is-multiline is-mobile"},_vm._l((_vm.contentFromKeywords),function(key,index){return _c('div',{key:index,staticClass:"column is-12"},[_c('ValidationProvider',{staticClass:"column is-12",attrs:{"rules":"","name":key},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"type":{ 'is-danger': errors[0], 'is-primary': valid },"label":key}},[_c('b-input',{attrs:{"type":"textarea"},model:{value:(_vm.form.content_json[key]),callback:function ($$v) {_vm.$set(_vm.form.content_json, key, $$v)},expression:"form.content_json[key]"}})],1),_vm._l((errors),function(error){return _c('span',{key:error.id,staticClass:"has-text-danger help"},[_vm._v(_vm._s(error))])})]}}],null,true)})],1)}),0):_vm._e()]),_c('div',{staticClass:"level"},[_c('div',{staticClass:"level-left"},[_c('div',{staticClass:"level-item"})]),_c('div',{staticClass:"level-right"},[_c('div',{staticClass:"level-item componentFormSubmit"},[_c('b-button',{staticClass:"is-primary",attrs:{"loading":_vm.submittingForm},on:{"click":_vm.submitForm}},[_vm._v(" Save ")])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }