<template>
  <section>
    <b-modal
      :active.sync="isComponentModalActive"
      has-modal-card
      trap-focus
      aria-role="dialog"
      aria-modal
      :on-cancel="close"
    >
      <div class="modal-card">
        <header class="modal-card-head"></header>
        <section class="modal-card-body">
          <ValidationObserver ref="editForm">
            <ValidationProvider
              rules="required"
              v-slot="{ errors, valid }"
              name="Name"
            >
              <b-field
                horizontal
                label="Name"
                label-for="name"
                :type="{ 'is-danger': errors[0], 'is-primary': valid }"
              >
                <div class="control" style="margin-bottom: 0.75rem;">
                  <b-input
                    id="name"
                    placeholder="Name"
                    v-model="localData.name"
                    :value="localData.name"
                  />
                  <span class="has-text-danger help">{{ errors[0] }}</span>
                </div>
              </b-field>
            </ValidationProvider>
            <b-field
              v-if="getRouteFirstName !== 'treatments'"
              id="iconSet"
              horizontal
              label="Icon set"
              label-for="selectIconSet"
            >
              <div class="control ">
                <div class="select is-primary">
                  <b-select
                    id="selectIconSet"
                    placeholder="Select a icon set"
                    required
                    v-model="localData.icon_set_id"
                  >
                    <option
                      v-for="iconSet in iconSets.data"
                      :key="iconSet.id"
                      :value="iconSet.id"
                      >{{ iconSet.name }}</option
                    >
                  </b-select>
                </div>
              </div>
            </b-field>
            <b-field
              v-if="getRouteFirstName == 'treatments'"
              label="Description"
              label-for="desecription"
              horizontal
            >
              <div class="control">
                <ValidationProvider
                  rules="required|max:500"
                  v-slot="{ errors }"
                  name="Description"
                >
                  <b-input
                    placeholder="Description"
                    id="description"
                    v-model="localData.description"
                    type="textarea"
                    maxlength="500"
                    pattern="[a-zA-Z0-9]*"
                    message="This field can contain up to 500 symbols"
                  />
                  <span
                    v-for="error in errors"
                    :key="error.id"
                    class="has-text-danger help"
                    >{{ error }}</span
                  >
                </ValidationProvider>
              </div>
            </b-field>
          </ValidationObserver>
        </section>
        <footer class="modal-card-foot">
          <button class="button" type="button" @click="close">
            Close
          </button>
          <button class="button is-primary" @click="edit">Edit</button>
        </footer>
      </div>
    </b-modal>
  </section>
</template>

<script>
import {
  UPDATE_AREA,
  UPDATE_AREA_TYPE,
  UPDATE_AREA_TYPE_CATEGORY,
  UPDATE_TREATMENT
} from "../../../_graphql/Mutations/mutations";
import LocalStateData from "../../../mixins/LocalStateData";
import { ICON_SETS } from "../../../_graphql/Queries/queries";
import ValidationErrors from "../../../mixins/ValidationErrors";

export default {
  created() {
    this.isComponentModalActive = true;
  },
  data() {
    return {
      isComponentModalActive: false,
      id: null,
      name: "",
      iconSets: [],
      description: ""
    };
  },
  apollo: {
    iconSets: {
      query: ICON_SETS,
      variables: {
        update: data => data.iconSets
      }
    }
  },
  methods: {
    close() {
      this.$router.go(-1);
      this.$refs.editForm.reset();
      this.$emit("close");
    },
    edit() {
      this.$refs.editForm.validate().then(success => {
        if (!success) {
          this.refName = "editForm";
          this.handleErrors();
        }
        let mutation;
        let payload = {};
        const id = parseInt(this.$route.params.id);
        let variables = {
          id: id,
          input: {
            name: this.localData.name,
            icon_set_id: parseInt(this.localData.icon_set_id)
          }
        };
        switch (this.getRouteFirstName) {
          case "areas":
            mutation = UPDATE_AREA;
            payload.apolloQuery = "areas";
            break;
          case "areaTypes":
            mutation = UPDATE_AREA_TYPE;
            payload.apolloQuery = "area";
            break;
          case "areaTypeCat":
            mutation = UPDATE_AREA_TYPE_CATEGORY;
            payload.apolloQuery = "aData";
            // code block
            break;
          case "treatments":
            mutation = UPDATE_TREATMENT;
            payload.apolloQuery = "treatments";
            variables = {
              id: id,
              input: {
                name: this.localData.name,
                description: this.localData.description
              }
            };
            // code block
            break;
          default:
            this.close();
          // code block
        }
        payload.componentName = this.getRouteFirstName;
        this.mutate(mutation, variables)
          .then(() => {
            this.$store.commit("refetchComponent", payload);
            this.close();
            this.$buefy.toast.open({
              message: "Data updated successfully!",
              type: "is-success"
            });
          })
          .catch(error => {
            this.$buefy.toast.open({
              message: `${error}`,
              type: "is-danger"
            });
            this.close();
          });
      });
    }
  },
  mixins: [LocalStateData, ValidationErrors],
  mounted() {
    if (!this.$route.params.data) {
      return this.close();
    }
  }
};
</script>

<style scoped>
  .modal-card-foot {
    justify-content: flex-end;
  }
</style>
