<template>
  <card-component>
    <ValidationObserver ref="pageFormValidator" class="userForm pageForm">
      <div class="columns is-variable is-7">
        <div class="column is-9">
          <div class="columns is-variable is-5 is-multiline is-mobile">
            <ValidationProvider
              class="column is-6"
              rules="required|min:2"
              v-slot="{ errors, valid }"
              name="Name"
            >
              <b-field
                :type="{ 'is-danger': errors[0], 'is-primary': valid }"
                label="Name"
              >
                <b-input trim v-model="form.name" />
              </b-field>
              <span
                v-for="error in errors"
                :key="error.id"
                class="has-text-danger help"
                >{{ error }}</span
              >
            </ValidationProvider>
            <!--            <ValidationProvider-->
            <!--              class="column is-6"-->
            <!--              rules="required|min:2"-->
            <!--              v-slot="{ errors, valid }"-->
            <!--              name="Slug"-->
            <!--            >-->
            <!--              <b-field-->
            <!--                :type="{ 'is-danger': errors[0], 'is-primary': valid }"-->
            <!--                label="Slug"-->
            <!--              >-->
            <!--                <b-input-->
            <!--                  placeholder="example-slug-name"-->
            <!--                  trim-->
            <!--                  v-model="form.slug"-->
            <!--                />-->
            <!--              </b-field>-->
            <!--              <span-->
            <!--                v-for="error in errors"-->
            <!--                :key="error.id"-->
            <!--                class="has-text-danger help"-->
            <!--                >{{ error }}</span-->
            <!--              >-->
            <!--            </ValidationProvider>-->
            <ValidationProvider
              class="column is-6-desktop is-6-tablet"
              rules="min:2"
              v-slot="{ errors, valid }"
              name="Title"
            >
              <b-field
                :type="{ 'is-danger': errors[0], 'is-primary': valid }"
                label="Title"
              >
                <b-input trim v-model="form.title" />
              </b-field>
              <span
                v-for="error in errors"
                :key="error.id"
                class="has-text-danger help"
                >{{ error }}</span
              >
            </ValidationProvider>
            <ValidationProvider
              class="column is-6-desktop is-6-tablet"
              rules=""
              v-slot="{ errors, valid }"
              name="Status"
            >
              <b-field
                :type="{ 'is-danger': errors[0], 'is-primary': valid }"
                label="Status"
              >
                <b-switch v-model="form.status" class="is-pulled-left">
                  {{ form.status ? "Enabled" : "Disabled" }}
                </b-switch>
              </b-field>
              <span
                v-for="error in errors"
                :key="error.id"
                class="has-text-danger help"
                >{{ error }}</span
              >
            </ValidationProvider>
          </div>
          <div class="columns is-variable is-5 is-multiline">
            <ValidationProvider
              class="column is-6"
              rules="min:2"
              v-slot="{ errors, valid }"
              name="Meta Keywords"
            >
              <b-field
                :type="{ 'is-danger': errors[0], 'is-primary': valid }"
                label="Meta Keywords"
              >
                <b-taginput
                  v-model="form.meta_keywords"
                  ellipsis
                  icon="label"
                  placeholder="Add a keyword"
                ></b-taginput>
              </b-field>
              <span
                v-for="error in errors"
                :key="error.id"
                class="has-text-danger help"
                >{{ error }}</span
              >
            </ValidationProvider>
          </div>
          <ValidationProvider
            class="column is-6"
            rules="min:2"
            v-slot="{ errors, valid }"
            name="Meta Title"
          >
            <b-field
              :type="{ 'is-danger': errors[0], 'is-primary': valid }"
              label="Meta Title"
            >
              <b-input trim v-model="form.meta_title" />
            </b-field>
            <span
              v-for="error in errors"
              :key="error.id"
              class="has-text-danger help"
              >{{ error }}</span
            >
          </ValidationProvider>
          <ValidationProvider
            class="column is-6"
            rules="min:2"
            v-slot="{ errors, valid }"
            name="Meta Description"
          >
            <b-field
              :type="{ 'is-danger': errors[0], 'is-primary': valid }"
              label="Meta Description"
            >
              <b-input trim v-model="form.meta_description" />
            </b-field>
            <span
              v-for="error in errors"
              :key="error.id"
              class="has-text-danger help"
              >{{ error }}</span
            >
          </ValidationProvider>
        </div>
      </div>
      <!--      <div class="columns is-variable is-5 is-multiline is-mobile">-->
      <!--        <ValidationProvider-->
      <!--          class="column is-12"-->
      <!--          rules=""-->
      <!--          v-slot="{ errors, valid }"-->
      <!--          name="Content"-->
      <!--        >-->
      <!--          <b-field-->
      <!--            :type="{ 'is-danger': errors[0], 'is-primary': valid }"-->
      <!--            label="Content"-->
      <!--          >-->
      <!--            <b-input v-model="form.content" type="textarea"></b-input>-->
      <!--          </b-field>-->
      <!--          <span-->
      <!--            v-for="error in errors"-->
      <!--            :key="error.id"-->
      <!--            class="has-text-danger help"-->
      <!--            >{{ error }}</span-->
      <!--          >-->
      <!--        </ValidationProvider>-->
      <!--      </div>-->
      <!--      <div class="columns is-variable is-5 is-multiline is-mobile">-->
      <!--        <ValidationProvider-->
      <!--          class="column is-12"-->
      <!--          rules=""-->
      <!--          v-slot="{ errors, valid }"-->
      <!--          name="CMS keywords"-->
      <!--        >-->
      <!--          <b-field-->
      <!--            :type="{ 'is-danger': errors[0], 'is-primary': valid }"-->
      <!--            label="CMS keywords (For developers only)"-->
      <!--          >-->
      <!--            <b-input v-model="form.cms_keywords" trim></b-input>-->
      <!--          </b-field>-->
      <!--          <span-->
      <!--            v-for="error in errors"-->
      <!--            :key="error.id"-->
      <!--            class="has-text-danger help"-->
      <!--            >{{ error }}</span-->
      <!--          >-->
      <!--        </ValidationProvider>-->
      <!--      </div>-->
      <div
        class="columns is-variable is-5 is-multiline is-mobile"
        v-if="!!contentFromKeywords.length"
      >
        <div
          v-for="(key, index) in contentFromKeywords"
          :key="index"
          class="column is-12"
        >
          <ValidationProvider
            class="column is-12"
            rules=""
            v-slot="{ errors, valid }"
            :name="key.slice(0, key.indexOf('['))"
            v-if="key.includes('[') && key.includes(']')"
          >
            <b-button class="is-primary" @click="addNewItem(key)">
              Add {{ key.slice(0, key.indexOf("[")) }}
            </b-button>
            <div v-if="form.content_json[key.slice(0, key.indexOf('['))]">
              <div
                v-for="(deepKey, index) in form.content_json[
                  key.slice(0, key.indexOf('['))
                ]"
                :key="deepKey"
              >
                <b-field
                  v-for="innerKey in Object.keys(deepKey)"
                  :key="innerKey"
                  :type="{ 'is-danger': errors[0], 'is-primary': valid }"
                  :label="innerKey"
                >
                  <b-input
                    v-if="innerKey"
                    type="textarea"
                    v-model="
                      form.content_json[key.slice(0, key.indexOf('['))][index][
                        innerKey
                      ]
                    "
                    trim
                  ></b-input>
                </b-field>
                <b-button class="is-primary" @click="deleteItem(key, index)">
                  Delete {{ key.slice(0, key.indexOf("[")) }} - {{ index + 1 }}
                </b-button>
              </div>
              <span
                v-for="error in errors"
                :key="error.id"
                class="has-text-danger help"
                >{{ error }}</span
              >
            </div>
          </ValidationProvider>
          <ValidationProvider
            class="column is-12"
            rules=""
            v-slot="{ errors, valid }"
            :name="key"
            v-else
          >
            <b-field
              :type="{ 'is-danger': errors[0], 'is-primary': valid }"
              :label="key"
            >
              <b-input
                v-model="form.content_json[key]"
                type="textarea"
              ></b-input>
            </b-field>
            <span
              v-for="error in errors"
              :key="error.id"
              class="has-text-danger help"
              >{{ error }}</span
            >
          </ValidationProvider>
        </div>
      </div>
    </ValidationObserver>
    <div class="level">
      <!-- TODO: Refactor not not rely on level-left -->
      <div class="level-left">
        <div class="level-item"></div>
      </div>
      <div class="level-right">
        <div class="level-item pageFormSubmit">
          <b-button
            class="is-primary"
            :loading="submittingForm"
            @click="submitForm"
          >
            Save
          </b-button>
        </div>
      </div>
    </div>
  </card-component>
</template>

<script>
import CardComponent from "../../base/components/CardComponent";
import ValidationErrors from "../../../mixins/ValidationErrors";

export default {
  name: "PageForm",
  mixins: [ValidationErrors],
  components: { CardComponent },
  props: {
    editModel: {
      type: Object,
      default: null,
      required: false
    },
    resetSubmitSwitch: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      submittingForm: false,
      form: {
        name: "",
        slug: "",
        title: "",
        content: "",
        status: true,
        meta_title: "",
        meta_keywords: [],
        meta_description: "",
        cms_keywords: "",
        content_json: {}
      }
    };
  },
  computed: {
    contentFromKeywords() {
      return (
        this.form.cms_keywords &&
        !!this.form.cms_keywords.length &&
        this.form.cms_keywords.split(" ")
      );
    }
  },
  watch: {
    resetSubmitSwitch() {
      this.submittingForm = false;
    }
  },
  created() {
    this.populateEditForm();
  },
  methods: {
    addNewItem: function(key) {
      const keyName = key.slice(0, key.indexOf("["));
      let keys = key.slice(key.indexOf("[") + 1, key.indexOf("]")).split(",");
      const newObj = keys.reduce((acc, curr) => ((acc[curr] = ""), acc), {});
      const copyOfObject = { ...this.form.content_json };
      copyOfObject[keyName] =
        copyOfObject[keyName] !== undefined
          ? [...copyOfObject[keyName], newObj]
          : [newObj];
      this.form.content_json = copyOfObject;
    },
    deleteItem: function(key, index) {
      const keyName = key.slice(0, key.indexOf("["));
      const copyOfObject = { ...this.form.content_json };
      copyOfObject[keyName] = copyOfObject[keyName].filter(
        (obj, objIndex) => index !== objIndex
      );
      this.form.content_json = copyOfObject;
    },
    populateEditForm() {
      if (this.editModel) {
        this.form.name = this.editModel.name;
        this.form.slug = this.editModel.slug;
        this.form.title = this.editModel.title;
        this.form.content = this.editModel.content;
        this.form.status = this.editModel.status;
        this.form.meta_title = this.editModel.meta_title;
        this.form.meta_keywords = this.editModel.meta_keywords
          ? this.editModel.meta_keywords.split(",").filter(kw => kw)
          : [];
        this.form.meta_description = this.editModel.meta_description;
        this.form.cms_keywords = this.editModel.cms_keywords || "";
        this.form.content_json = this.editModel.content_json
          ? JSON.parse(this.editModel.content_json)
          : {};
      }
    },
    submitForm() {
      this.$refs.pageFormValidator.validate().then(success => {
        if (!success) {
          this.refName = "pageFormValidator";
          this.handleErrors();
          return;
        }
        this.$emit("formSubmit", this.form);
        this.$apollo.getClient().resetStore(); // to clear allPages table so it can be refetched with new/edited pages. should be refactored to refetch only allPages
        this.submittingForm = true;
      });
    }
  }
};
</script>
<style>
.pageFormSubmit button {
  margin-top: 40px;
  font-size: 1.15rem;
  width: 120px;
}

.pageForm .switch {
  width: 100%;
  padding-top: 5px;
  padding-bottom: 18px;
}
</style>
