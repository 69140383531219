<template>
  <li
    :class="[
      isDropdownActive || menuItemIsOpen || isCurrentHref ? 'is-active' : ''
    ]"
  >
    <component
      :is="componentIs"
      :to="itemTo"
      :href="itemHref"
      @click="menuClick"
      exact-active-class="is-active"
      :class="{
        'has-icon': !!item.icon,
        'is-active': menuItemIsOpen || isCurrentHref,
        submenu: item.submenu
      }"
    >
      <b-icon
        v-if="item.icon"
        :icon="item.icon"
        :class="{ 'has-update-mark': item.updateMark }"
        custom-size="default"
      />
      <span v-if="item.label" :class="{ 'menu-item-label': !!item.icon }">{{
        item.label
      }}</span>
    </component>
    <aside-menu-list
      v-if="hasDropdown"
      :menu="item.menu"
      :isSubmenuList="true"
    />
  </li>
</template>

<script>
import _ from "lodash";
export default {
  name: "AsideMenuItem",
  data() {
    return {
      isDropdownActive: false
    };
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  methods: {
    menuClick() {
      this.$emit("menu-click", this.item);

      if (this.hasDropdown) {
        this.isDropdownActive = !this.isDropdownActive;
      }
    }
  },
  computed: {
    isCurrentHref() {
      return this.item && this.item.to === this.$route.path;
    },
    menuItemIsOpen() {
      return (
        this.hasDropdown &&
        !_.isEmpty(this.item.menu.find(item => item.href === this.$route.path))
      );
    },
    componentIs() {
      return this.item.to ? "router-link" : "a";
    },
    hasDropdown() {
      return !!this.item.menu;
    },
    itemTo() {
      return this.item.to ? this.item.to : null;
    },
    itemHref() {
      return this.item.href ? this.item.href : null;
    }
  }
};
</script>

<style lang="scss" scoped>
.submenu {
  padding-left: 3rem !important;
}
</style>
