import newProvider from "./views/new";
import list from "./views/list";
import { Role } from "../../_helpers/roles";
import edit from "./views/edit";
import providersForApproval from "./views/ProvidersForApproval";
import guestProviders from "./views/guestProviders";

const ProviderNewRoute = {
  path: "/providers/new",
  name: "ProviderNew",
  component: newProvider,
  meta: { authorize: [Role.Admin, Role.Vendor, Role.Operator, Role.Moderator] }
};

const ProvidersListRoute = {
  path: "/providers/list",
  name: "list",
  component: list,
  meta: { authorize: [Role.Admin, Role.Vendor] }
};

const ProvidersEditRoute = {
  path: "/providers/edit/:providerId",
  name: "edit",
  component: edit,
  meta: { authorize: [Role.Admin, Role.Vendor, Role.Operator, Role.Moderator] }
};

const ProvidersApprovalRoute = {
  path: "/providers/approve",
  name: "ProvidersApproval",
  component: providersForApproval,
  meta: { authorize: [Role.Admin] }
};

const GuestProvidersRoute = {
  path: "/providers/guest",
  name: "GuestProviders",
  component: guestProviders,
  meta: { authorize: [Role.Admin, Role.Operator, Role.Moderator] }
};

export default [
  ProviderNewRoute,
  ProvidersListRoute,
  ProvidersEditRoute,
  ProvidersApprovalRoute,
  GuestProvidersRoute
];
