<template>
  <section class="section">
    <title-bar>
      Create Page
      <router-link
        v-if="isAdmin"
        to="/"
        class="button is-primary"
        slot="button"
      >
        Dashboard
      </router-link>
    </title-bar>

    <PageForm @formSubmit="createPage" :resetSubmitSwitch="resetSubmitSwitch" />
  </section>
</template>

<script>
import TitleBar from "../../base/components/TitleBar";
import PageForm from "../components/PageForm";
import { CREATE_PAGE } from "../../../_graphql/Mutations/mutations";
import { mapGetters } from "vuex";

export default {
  name: "CreatePage",
  components: { TitleBar, PageForm },
  data() {
    return {
      resetSubmitSwitch: false
    };
  },
  computed: {
    ...mapGetters(["userRole"]),
    isAdmin() {
      return this.userRole && this.userRole == "ADMINISTRATOR";
    }
  },
  methods: {
    createPage(pageForm) {
      const mutation = CREATE_PAGE;
      const variables = {
        input: {
          name: pageForm.name,
          slug: pageForm.slug,
          title: pageForm.title,
          content: pageForm.content,
          status: pageForm.status,
          meta_title: pageForm.meta_title,
          meta_keywords: pageForm.meta_keywords.join(", "),
          meta_description: pageForm.meta_description
        }
      };
      this.$apollo.provider.defaultClient
        .mutate({
          mutation: mutation,
          variables: variables
        })
        .catch(error => {
          return error;
        })
        .then(data => {
          const createPage = data.data ? data.data.createPage : false;
          if (createPage) {
            this.$buefy.toast.open({
              message: "Page created successfully.",
              type: "is-success",
              duration: 1500
            });
            this.$buefy.toast.open({
              message: "Redirecting...",
              type: "is-info",
              duration: 1500
            });
            setTimeout(() => {
              this.$router.push("/pages");
            }, 3000);
          } else {
            this.$buefy.toast.open({
              message: "Something went wrong.",
              type: "is-danger",
              duration: 2000
            });
            setTimeout(() => {
              this.resetSubmitSwitch = !this.resetSubmitSwitch;
            }, 4500);
          }
        });
    }
  }
};
</script>
