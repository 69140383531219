<template>
  <div class="card">
    <header class="card-header">
      <p class="card-header-title has-text-primary">
        <b-icon icon="eye" />
        Visibility and Accessibility
      </p>
    </header>
    <div class="card-content has-text-left">
      <div class="columns is-multiline is-mobile">
        <span class="column is-12 switch-column">
          <b-tooltip
            :active="disableButtons"
            :label="tooltipButtons"
            multilined
          >
            <b-switch
              class="has-text-weight-bold switchClass has-text-left"
              @input="activateProfile"
              :value="isMatchActive"
              :disabled="disableButtons"
            >
              Allow buyers to match with me
            </b-switch>
          </b-tooltip>
          <p class="helpText">
            Turn off matching if you don't want to appear in matches of people
            using the app. This won't affect existing matches and chats and does
            not prevent people matching with you when you share your unique
            MeTime URL.
          </p>
        </span>
        <span class="column is-12 switch-column">
          <b-tooltip
            :active="disableButtons"
            :label="tooltipButtons"
            multilined
          >
            <b-switch
              class="switchClass has-text-left has-text-weight-bold"
              @input="publishProfile"
              :value="isPublishActive"
              :disabled="disableButtons"
            >
              Publish my profile on MeTime directory
            </b-switch>
          </b-tooltip>
          <p class="helpText">
            Turn off this option if you don't want to appear in MeTime providers
            directory. This won't affect existing matches and chats and does not
            prevent people matching with you when you share your unique MeTime
            URL or when using regular search within the app.
          </p>
        </span>
        <div class="column is-12">
          <div class="field">
            <label class="label has-text-left">My profile's URL</label>
            <div class="control">
              <span class="profile_url"
                >https://metime.com/provider/{{ provider.profile_url }}</span
              >
              <b-button @click="editUrl" class="is-primary btn-edit"
                >Edit</b-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <EditProviderProfileUrl
      ref="editUrl"
      :provider="{ id: provider.id, profile_url: provider.profile_url }"
      @providerProfileUpdated="$emit('updatedInfo')"
    ></EditProviderProfileUrl>
  </div>
</template>

<script>
import {
  MATCHABLE_PROVIDER,
  UNMATCHABLE_PROVIDER,
  UPDATE_PROVIDER
} from "../../../_graphql/Mutations/mutations";
import EditProviderProfileUrl from "./EditProviderProfileUrl";

export default {
  data() {
    return {
      tooltipButtons:
        "Access to this feature is limited until your profile is approved by the administrator",
      isMatchActive: true,
      isPublishActive: true
    };
  },
  props: ["provider"],
  mounted() {
    if (this.provider) {
      this.isMatchActive = this.provider.is_matchable;
      this.isPublishActive = this.provider.is_public;
    }
  },
  components: {
    EditProviderProfileUrl
  },
  computed: {
    disableButtons() {
      return (
        this.provider && (this.provider.status !== 1 || this.provider.is_guest)
      );
    }
  },
  methods: {
    activateProfile(value) {
      if (!this.disableButtons) {
        let mutationName = value ? MATCHABLE_PROVIDER : UNMATCHABLE_PROVIDER;
        this.$apollo.mutate({
          mutation: mutationName,
          variables: {
            id: parseInt(this.provider.id)
          },
          update: (cache, { data }) => {
            let mutationData = value
              ? "matchableProvider"
              : "unmatchableProvider";
            if (data[mutationData]) {
              this.isMatchActive = data[mutationData].is_matchable;
              this.$buefy.toast.open({
                message: "Updated successfully",
                type: "is-success"
              });
            }
          },
          error: ({ error }) => {
            this.$buefy.toast.open({
              message: error,
              type: "is-danger",
              duration: 2000
            });
            this.isMatchActive = this.provider.is_matchable;
          }
        });
      }
    },
    publishProfile(value) {
      if (!this.disableButtons) {
        this.$apollo.mutate({
          mutation: UPDATE_PROVIDER,
          variables: {
            id: parseInt(this.provider.id),
            input: {
              is_public: value
            }
          },
          update: (cache, { data }) => {
            if (data.updateProvider) {
              this.$buefy.toast.open({
                message: "Updated successfully",
                type: "is-success"
              });
              this.isPublishActive = data.updateProvider.is_public;
            }
          },
          error: ({ error }) => {
            this.$buefy.toast.open({
              message: error,
              type: "is-danger",
              duration: 2000
            });
            this.isPublishActive = this.provider.is_public;
          }
        });
      }
    },
    editUrl() {
      this.$refs.editUrl.openModal();
    }
  }
};
</script>

<style lang="scss" scoped>
.switchClass {
  display: flex;
  padding-bottom: 0.5rem;
}

.helpText {
  margin-left: 3.25rem;
  margin-right: 1.75rem;
  padding-bottom: 1rem;
  text-align: justify;
}

.field {
  margin-left: 3.25rem;
  .label {
    display: flex;
    margin-bottom: 0.5em !important;
  }
  .control {
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    word-break: break-word;
    .profile_url {
      max-height: 8rem;
      overflow-y: auto;
    }

    .button {
      margin-left: 1rem;
    }
  }
  @media (max-width: 576px) {
    .control {
      flex-direction: column;
      align-items: flex-start;
      .button {
        margin-left: 0;
        margin-top: 0.5rem;
      }
    }
  }
}

.btn-edit {
  margin-right: 1.75rem;
}
</style>
