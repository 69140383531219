import Pages from "./views/Pages";
import CreatePage from "./views/CreatePage";
import EditPage from "./views/EditPage";
import { Role } from "../../_helpers/roles";

const pagesRoute = {
  path: "/pages",
  name: "pages",
  component: Pages,
  meta: { authorize: [Role.Admin] }
};

const createPageRoute = {
  path: "/pages/create",
  name: "createPage",
  component: CreatePage,
  meta: { authorize: [Role.Admin] }
};

const editPageRoute = {
  path: "/pages/edit/:id",
  name: "editPage",
  component: EditPage,
  meta: { authorize: [Role.Admin] }
};

export default [pagesRoute, createPageRoute, editPageRoute];
