var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"add-image-section"},[_c('b-modal',{attrs:{"active":_vm.isActive,"has-modal-card":"","aria-modal":"","aria-role":"dialog","trap-focus":"","on-cancel":_vm.closeModal,"id":"modal"},on:{"update:active":function($event){_vm.isActive=$event}}},[_c('div',{staticClass:"modal-card",style:('width: ' +
          _vm.modalWidth +
          'px; height: ' +
          _vm.modalHeight +
          'px; max-width: 960px;')},[_c('header',{staticClass:"modal-card-head"},[_c('p',{staticClass:"modal-card-title has-text-weight-bold"},[_vm._v("Add Image")])]),_c('section',{staticClass:"modal-card-body"},[_c('b-field',{staticClass:"info-field"},[(
              _vm.type &&
                (_vm.type == 'cover_image' || _vm.type == 'solution_cover_image')
            )?_c('notification',{attrs:{"type":"light"},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('img',{staticClass:"icon is-large",attrs:{"src":require("@/assets/img/frame.svg")}})]},proxy:true},{key:"message",fn:function(){return [_vm._v(" Use the cropping tool to specify how you'd like your cover image to appear on different devices. The larger rectangle shows how your cover image will be displayed in mobile and tablet view, while the smaller, highlighted one shows how it will appear in desktop view. ")]},proxy:true}],null,false,2676299591)}):_vm._e()],1),_c('ValidationObserver',{ref:"validateImage"},[_c('ValidationProvider',{attrs:{"rules":"required|ext:jpg,jpeg,png","name":"Image"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
            var valid = ref.valid;
return [_c('b-field',{staticClass:"upload-field ",attrs:{"type":{ 'is-danger': errors[0], 'is-primary': valid }}},[_c('b-upload',{staticClass:"file is-right is-primary upload-image is-mobile",attrs:{"type":"is-primary","label":"Upload Image"},on:{"input":function($event){return _vm.changeAvatar($event)}},model:{value:(_vm.image),callback:function ($$v) {_vm.image=$$v},expression:"image"}},[_c('span',{staticClass:"file-cta"},[_c('span',{staticClass:"file-icon"},[_c('i',{staticClass:"fas fa-upload"})]),_c('span',{staticClass:"file-label"},[_vm._v(" Click to upload ")])])])],1),_vm._l((errors),function(error){return _c('span',{key:error.id,staticClass:"has-text-danger help"},[_vm._v(_vm._s(error))])})]}}])}),_c('b-field',{staticClass:"cropper-field"},[(_vm.type)?_c('cropper',{ref:"cropper",staticClass:"cropper",style:('maxHeight: ' + _vm.cropperHeight + 'px;'),attrs:{"stencil-component":_vm.stencilComponent[_vm.type],"stencil-props":_vm.stencilProps[_vm.type],"size-restrictions-algorithm":_vm.pixelsRestriction,"min-height":_vm.imageSizes[_vm.type].minHeight,"min-width":_vm.imageSizes[_vm.type].minWidth,"src":_vm.crop}}):_vm._e()],1)],1)],1),_c('footer',{staticClass:"modal-card-foot"},[_c('button',{staticClass:"button",attrs:{"type":"button"},on:{"click":_vm.closeModal}},[_vm._v(" Close ")]),_c('button',{staticClass:"button is-primary",attrs:{"disabled":_vm.isSaveBtnDisabled},on:{"click":_vm.cropImage}},[_vm._v(" Save ")])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }