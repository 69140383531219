import gql from "graphql-tag";

const CONVERSATION_WITH_MESSAGES = gql`
  query conversationMessages($conversationId: ID!, $pageParams: QueryParams) {
    conversationMessages(
      conversationId: $conversationId
      pageParams: $pageParams
    ) {
      code
      success
      errors
      meta {
        total_pages
        current_page
        next_page
        per_page
        total_count
      }
      data {
        id
        fromId
        fromUser {
          firstName
          lastName
        }
        payload
        readBy
        metadata
        status
        createdDate
        updatedDate
      }
    }
  }
`;

const CONVERSATION_MESSAGES_BEFORE = gql`
  query ConversationMessagesBefore(
    $conversationId: ID!
    $createdDate: Date!
    $queryParams: QueryParams
  ) {
    conversationMessagesBefore(
      conversationId: $conversationId
      createdDate: $createdDate
      pageParams: $queryParams
    ) {
      code
      success
      errors
      meta {
        total_pages
        current_page
        next_page
        per_page
        total_count
      }
      data {
        id
        fromId
        fromUser {
          firstName
          lastName
        }
        payload
        conversationId
        readBy
        metadata
        status
        createdDate
        updatedDate
      }
    }
  }
`;

const CONVERSATION = gql`
  query conversation($id: ID!) {
    conversation(id: $id) {
      data {
        id
        participantIds
        metadata
        createdDate
        journeyProvider {
          provider {
            id
          }
          journey {
            date_added
            name
            gender
            range_radius
            specialties {
              name
              description
            }
            areas {
              area_types {
                name
              }
              name
            }
            photos {
              id
            }
            user {
              id
              auth_id
            }
          }
        }
      }
      meta {
        total_pages
        total_count
        per_page
      }
    }
  }
`;

const CONVERSATION_WITH_USER = gql`
  query conversationWithUser {
    conversationsWithUser(pageParams: { offset: 0, limit: 25 }) {
      data {
        id
        participantIds
        metadata
        createdDate

        journeyProvider {
          provider {
            id
          }
          journey {
            date_added
            name
            gender
            range_radius
            settings_values {
              value
              option {
                value
                min
                max
              }
              type
            }
            photos {
              id
              url
            }
            specialties {
              name
              description
            }
            areas {
              area_types {
                name
              }
              name
            }
            photos {
              id
            }
            user {
              id
              auth_id
            }
          }
        }
      }
      meta {
        total_pages
        total_count
        per_page
      }
    }
  }
`;

const CREATE_CONVERSATION = gql`
  mutation createConversation($data: ConversationInput!) {
    createConversation(data: $data) {
      data {
        id
      }
    }
  }
`;

const ARCHIVE_CONVERSATION = gql`
  mutation archiveConversation($id: ID!) {
    archiveConversation(id: $id) {
      code
      success
      errors
      data {
        id
      }
    }
  }
`;

export default {
  CONVERSATION,
  CONVERSATION_WITH_MESSAGES,
  CONVERSATION_MESSAGES_BEFORE,
  CONVERSATION_WITH_USER,
  CREATE_CONVERSATION,
  ARCHIVE_CONVERSATION
};
