<template>
  <div class="userForm" id="solution-form">
    <div class="columns is-variable is-12">
      <div class="column is-12">
        <div class="columns is-variable is-12 is-multiline is-mobile">
          <ValidationProvider
            class="column is-12-mobile is-6-tablet"
            v-slot="{ errors, valid }"
            name="Title"
            rules="required"
          >
            <b-field
              :type="errors[0] ? 'is-danger' : 'is-primary'"
              label="Title *"
            >
              <b-input
                trim
                v-model="form.title"
                :disabled="disableFields"
                @blur="checkForChanges('title')"
              />
            </b-field>
            <span
              v-for="error in errors"
              :key="error.id"
              class="has-text-danger help"
              >{{ error }}</span
            >
          </ValidationProvider>
          <ValidationProvider
            class="column is-12-mobile is-6-tablet"
            v-slot="{ errors, valid }"
            name="Meta title"
            :rules="{ required: publishValidation }"
          >
            <b-field
              :type="{ 'is-danger': errors[0], 'is-primary': valid }"
              label="Meta title"
            >
              <b-input
                trim
                v-model="form.meta_title"
                :disabled="disableFields"
                @blur="checkForChanges('meta_title')"
              />
            </b-field>
            <span
              v-for="error in errors"
              :key="error.id"
              class="has-text-danger help"
              >{{ error }}</span
            >
          </ValidationProvider>
        </div>
        <div class="columns is-variable is-12 is-multiline is-mobile">
          <ValidationProvider
            class="column is-12-mobile is-6-tablet"
            v-slot="{ errors, valid }"
            name="Slug"
            :rules="{
              regex: /^[a-z0-9](-?[a-z0-9])*$/,
              required: publishValidation
            }"
          >
            <b-field
              :type="{ 'is-danger': errors[0], 'is-primary': valid }"
              label="Slug"
            >
              <b-input
                placeholder="example-slug-name"
                trim
                v-model="form.slug"
                :disabled="disableFields"
                @blur="checkForChanges('slug')"
              />
            </b-field>
            <span
              v-for="error in errors"
              :key="error.id"
              class="has-text-danger help"
              >{{ error }}</span
            >
          </ValidationProvider>
        </div>
        <div class="columns is-variable is-12 is-multiline is-mobile">
          <ValidationProvider
            class="column is-12-mobile is-6-tablet"
            v-slot="{ errors, valid }"
            name="Category"
            rules="required"
            v-if="solutionsCategories"
          >
            <b-field
              :type="errors[0] ? 'is-danger' : 'is-primary'"
              label="Category *"
            >
              <b-select
                class="is-primary"
                v-model="form.category.id"
                @input="selectCategory"
                @blur="checkForChanges('category')"
                expanded
                :disabled="disableFields"
              >
                <option
                  :value="category.id"
                  v-for="category in solutionsCategories.data"
                  :key="category.id"
                  >{{ category.name }}</option
                >
              </b-select>
            </b-field>
            <span
              v-for="error in errors"
              :key="error.id"
              class="has-text-danger help"
              >{{ error }}</span
            >
          </ValidationProvider>
          <ValidationProvider
            class="column is-12-mobile is-6-tablet"
            v-slot="{ errors, valid }"
            name="Subcategory"
            rules="required"
          >
            <b-field
              label="Subcategory *"
              :type="errors[0] ? 'is-danger' : 'is-primary'"
            >
              <b-autocomplete
                type="search"
                expanded
                :disabled="disableFields"
                v-model="form.subcategory.name"
                :data="subcategories"
                :open-on-focus="true"
                :check-infinite-scroll="true"
                @typing="handleInput"
                @select="suggestionClick"
                @blur="checkForChanges('subcategory')"
              ></b-autocomplete>
            </b-field>
            <span
              v-for="error in errors"
              :key="error.id"
              class="has-text-danger help"
              >{{ error }}</span
            >
          </ValidationProvider>
        </div>
        <div class="columns is-variable is-12 is-multiline is-mobile">
          <ValidationProvider
            class="column is-12-mobile is-6-tablet"
            v-slot="{ errors, valid }"
            name="Description"
            :rules="{ required: publishValidation }"
          >
            <b-field
              :type="{ 'is-danger': errors[0], 'is-primary': valid }"
              label="Description"
            >
              <b-input
                v-model="form.description"
                type="textarea"
                :disabled="disableFields"
                @blur="checkForChanges('description')"
              />
            </b-field>
            <span
              v-for="error in errors"
              :key="error.id"
              class="has-text-danger help"
              >{{ error }}</span
            >
          </ValidationProvider>
          <ValidationProvider
            class="column is-12-mobile is-6-tablet"
            v-slot="{ errors, valid }"
            name="Meta description"
            :rules="{ required: publishValidation }"
          >
            <b-field
              :type="{ 'is-danger': errors[0], 'is-primary': valid }"
              label="Meta description"
            >
              <b-input
                v-model="form.meta_description"
                type="textarea"
                :disabled="disableFields"
                @blur="checkForChanges('meta_description')"
              />
            </b-field>
            <span
              v-for="error in errors"
              :key="error.id"
              class="has-text-danger help"
              >{{ error }}</span
            >
          </ValidationProvider>
        </div>
        <div class="columns is-variable is-12 is-multiline is-mobile">
          <ValidationProvider
            class="column is-12"
            v-slot="{ errors, valid }"
            name="Content"
            style="padding-bottom: 20px"
            :rules="{ required: publishValidation }"
          >
            <b-field
              :type="{ 'is-danger': errors[0], 'is-primary': valid }"
              label="Content"
              class="content"
            >
              <ckeditor
                :disabled="disableFields"
                :editor="editor"
                v-model="form.content"
                @input="fixSideTextHeight"
                @ready="fixSideTextHeight"
                @blur="checkForChanges('content')"
                :config="editorConfig"
              ></ckeditor>
            </b-field>
            <span
              v-for="error in errors"
              :key="error.id"
              class="has-text-danger help"
              >{{ error }}</span
            >
          </ValidationProvider>
        </div>
        <div
          id="read-time-column"
          class="columns is-variable is-12 is-multiline is-mobile"
        >
          <ValidationProvider
            class="column is-12-mobile is-6-desktop"
            v-slot="{ errors, valid }"
            name="Time to read"
            :rules="{
              min_value: 1,
              required: publishValidation,
              integer: true
            }"
          >
            <b-field
              :type="{ 'is-danger': errors[0], 'is-primary': valid }"
              label="Time to read (in minutes)"
              class="is-horizontal"
              id="read-time-field"
            >
              <b-input
                id="read_time"
                type="number"
                v-model="form.read_time"
                :disabled="disableFields"
                @blur="checkForChanges('read_time')"
              />
            </b-field>
            <span
              v-for="error in errors"
              :key="error.id"
              class="has-text-danger help read-time-help"
              >{{ error }}</span
            >
          </ValidationProvider>
        </div>
        <div class="columns is-variable is-12 is-multiline is-mobile">
          <ValidationProvider
            class="column is-12-mobile is-6-desktop is-5-fullhd"
            v-slot="{ errors, valid }"
            name="Cover image"
            :rules="{ required: publishValidation }"
          >
            <div id="cover-image-field" class="field">
              <label class="label has-text-left"
                ><span>Cover image</span>
                <b-button
                  :disabled="disableFields"
                  class="is-primary"
                  @click="uploadImage('solution_cover_image')"
                  >Upload image</b-button
                >
              </label>
              <div class="control">
                <b-field>
                  <b-upload
                    v-model="form.coverImageDropFiles"
                    :disabled="disableFields"
                    drag-drop
                    expanded
                    @input="droppedImage($event, 'solution_cover_image')"
                  >
                    <img
                      v-if="form.cover_url"
                      :src="form.cover_url"
                      alt="Cover image"
                      style="width: 100%; object-fit: contain"
                    />

                    <section class="section">
                      <div class="content has-text-centered">
                        <p>
                          <b-icon icon="upload" size="is-large"></b-icon>
                        </p>
                        <p>Drop your file here or click to upload</p>
                      </div>
                    </section>
                  </b-upload>
                </b-field>
              </div>
              <span
                v-for="error in errors"
                :key="error.id"
                class="has-text-danger help"
                >{{ error }}</span
              >
            </div>
          </ValidationProvider>
          <ValidationProvider
            class="column is-12-mobile is-6-desktop is-offset-2-fullhd is-5-fullhd"
            v-slot="{ errors, valid }"
            name="Thumbnail image"
            :rules="{ required: publishValidation }"
          >
            <div id="thumbnail-field" class="field">
              <label class="label has-text-left"
                ><span>Thumbnail image</span>
                <b-button
                  :disabled="disableFields"
                  class="is-primary"
                  @click="uploadImage('solution_thumbnail_image')"
                  >Upload image</b-button
                >
              </label>
              <div class="control">
                <b-field>
                  <b-upload
                    v-model="form.thumbnailDropFiles"
                    drag-drop
                    expanded
                    @input="droppedImage($event, 'solution_thumbnail_image')"
                    :disabled="disableFields"
                  >
                    <img
                      v-if="form.cover_thumb_url"
                      :src="form.cover_thumb_url"
                      alt="Thumbnail"
                      style="width: 100%; object-fit: contain"
                    />

                    <section class="section">
                      <div class="content has-text-centered">
                        <p>
                          <b-icon icon="upload" size="is-large"></b-icon>
                        </p>
                        <p>Drop your file here or click to upload</p>
                      </div>
                    </section>
                  </b-upload>
                </b-field>
              </div>
              <span
                v-for="error in errors"
                :key="error.id"
                class="has-text-danger help"
                >{{ error }}</span
              >
            </div>
          </ValidationProvider>
        </div>
      </div>
    </div>
    <add-image ref="addImage" @saveImage="saveImage"></add-image>
  </div>
</template>

<script>
import ClassicEditor from "@dimulski/ckeditor5-build-classic-cc-mt";
import AddImage from "../../providers/components/AddImage";
import { SOLUTIONS_CATEGORIES } from "../../../_graphql/Queries/queries";

export default {
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        graphQLUploadAdapter: {
          graphQLUrl:
            process.env.VUE_APP_GRAPHQL_URL ||
            "https://192.168.0.104:4001/graphql",
          authToken: localStorage.getItem("AUTH_TOKEN")
        }
      },
      categories: [
        {
          name: "Procedures",
          value: "Procedures"
        },
        {
          name: "Technologies",
          value: "Technologies"
        },
        {
          name: "Products",
          value: "Products"
        },
        {
          name: "Provider types",
          value: "Provider types"
        }
      ],
      subcategories: []
    };
  },
  props: ["form", "publishValidation", "disableFields"],
  components: {
    AddImage
  },
  computed: {
    suggestions() {
      let suggestions = [];
      if (this.form.category) {
        let category = this.solutionsCategories.data.find(c => {
          return c.id == this.form.category.id;
        });
        if (category) {
          suggestions = category.subcategories.map(subCat => subCat.name);
        }
      }
      return suggestions;
    }
  },
  methods: {
    checkForChanges(value) {
      this.$emit("editedField", value);
    },
    selectCategory(id) {
      let category = this.solutionsCategories.data.find(c => {
        return c.id == id;
      });
      this.form.category.slug = category.slug;
      this.form.category.name = category.name;
      this.form.subcategory.name = "";
      this.$emit("categoryChanged");
    },
    isActive(index) {
      return index === this.current;
    },
    suggestionClick(item) {
      if (item) {
        this.form.subcategory.name = item.name;
      }
    },
    handleInput(text) {
      this.subcategories = this.suggestions.filter(filter => {
        return filter.toLowerCase().startsWith(text.toLowerCase());
      });
    },
    fixSideTextHeight() {
      // Sets the minimum height of elements that come after aligned images to the same height as the image
      // and removes those minimum heights if the image is removed or becomes center aligned
      let content = document.getElementsByClassName("ck-content")[0];
      if (content) {
        const imageCaptionHeight = 35;
        content
          .querySelectorAll(
            `figure.float-left img,
          figure.float-right img`
          )
          .forEach(image => {
            if (image.complete) {
              if (image.parentNode.nextElementSibling) {
                image.parentNode.nextElementSibling.style.minHeight =
                  image.clientHeight + imageCaptionHeight + "px";
              }
            } else {
              image.addEventListener(
                "load",
                function() {
                  if (image.parentNode.nextElementSibling) {
                    image.parentNode.nextElementSibling.style.minHeight =
                      image.clientHeight + imageCaptionHeight + "px";
                  }
                },
                { once: true }
              );
            }
          });
        content
          .querySelectorAll(":scope > :not(figure)")
          .forEach(directChild => {
            if (directChild.previousElementSibling) {
              if (
                directChild.previousElementSibling.matches(
                  ":not(.float-left)"
                ) &&
                directChild.previousElementSibling.matches(":not(.float-right)")
              ) {
                directChild.style.minHeight = null;
              }
            } else {
              directChild.style.minHeight = null;
            }
          });
      }
    },
    droppedImage(event, type) {
      this.$refs.addImage.openModal(type);
      this.$refs.addImage.onDropImage(
        event,
        type == "solution_cover_image"
          ? this.form.coverImageDropFiles
          : this.form.thumbnailDropFiles
      );
      this.$emit(
        "editedField",
        type == "solution_cover_image" ? "cover_url" : "cover_tumb_url"
      );
    },
    uploadImage(type) {
      this.$refs.addImage.openModal(type);
    },
    saveImage(data) {
      if (data.type == "solution_cover_image") {
        this.form.cover_url = data.image.url;
        this.form.coverImageDropFiles = [data.image.url];
      } else {
        this.form.cover_thumb_url = data.image.url;
        this.form.thumbnailDropFiles = [data.image.url];
      }
    }
  },
  apollo: {
    solutionsCategories: {
      query: SOLUTIONS_CATEGORIES
    }
  }
};
</script>

<style lang="scss">
#solution-form {
  #picture-input canvas {
    z-index: 0 !important;
  }
  .picture-inner .picture-inner-text {
    font-size: 1em !important;
  }
  .d-table {
    display: table !important;
  }
  .image-style-align-right:not(.image_resized),
  .image-style-align-left:not(.image_resized) {
    max-width: 50%;
  }
  .image-style-align-right {
    margin-left: 2.8rem !important;
  }
  .image-style-align-left {
    margin-right: 2.8rem !important;
  }
  figure {
    margin-top: 0 !important;
    margin-bottom: 10px !important;
  }
  div.ck-content[role="textbox"] {
    min-height: 200px;
    padding-top: 10px;
    text-align: justify !important;
  }
  .content figure figcaption {
    font-style: normal !important;
  }
  .content {
    font-size: 1.25rem;
  }
  h1,
  h2,
  h3 {
    margin-top: 0;
  }
  figure.media.ck-widget {
    width: 80%;
    margin: auto;
  }
}
</style>

<style lang="scss" scoped>
#cover-image-field,
#thumbnail-field {
  label {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>

<style lang="scss">
#solution-form {
  #read-time-column {
    #read-time-field {
      width: 340px;
      @media (min-width: 768px) {
        align-items: flex-end;
      }
      .control {
        width: 150px;
      }
      span.has-text-danger {
        @media (min-width: 769px) {
          padding-left: 2rem;
        }
      }
      #read_time {
        width: 150px !important;
        @media (min-width: 769px) {
          margin-left: 1rem !important;
        }
      }
    }
  }
  .read-time-help {
    width: 340px;
    margin-left: 0.5rem;
    text-align: left;
    @media (min-width: 769px) {
      text-align: right;
    }
  }
  .upload-draggable {
    width: 100%;

    img {
      width: auto;
      height: 300px;
      display: block;
      margin: 0 auto;
    }
  }
}
</style>
