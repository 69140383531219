<template>
  <div>
    <div class="section" style="margin: 0px;padding: 0;">
      <div class="container" style="width:100%;max-width: 3400px;">
        <chat-window
          id="chat-window"
          :style="'height:' + windowHeight + 'px;'"
          :embedded="isEmbedded"
          v-if="journeyProvider"
          :journey="journeyProvider.journey"
          :provider="journeyProvider.provider"
          @permissionStatusUpdated="videocallPermissionUpdated"
        ></chat-window>
        <hr
          class="is-divider is-12-desktop"
          style="border-top:3px solid #f1f1f1;margin:0;"
        />
        <write-area
          v-if="isVendor && journeyProvider.status == 'OPEN'"
          :journey-provider="journeyProvider"
          :hasVideoCallPermission="hasVideoCallPermission"
        ></write-area>
      </div>
    </div>
  </div>
</template>

<script>
import chatWindow from "../components/chatWindow";
import writeArea from "../components/writeArea";
import _ from "lodash";
import { createNamespacedHelpers } from "vuex";
import { UPDATE_JOURNEY_PROVIDER } from "../../../_graphql/Mutations/mutations";
import queries from "../queries";
import { query } from "../../../vue-apollo";

const { mapGetters, mapActions, mapMutations } = createNamespacedHelpers(
  "messages"
);

export default {
  components: { chatWindow, writeArea },
  props: ["id"],
  data() {
    return {
      windowHeight: null,
      isEmbedded: false,
      journeyProvider: {},
      hasVideoCallPermission: ""
    };
  },
  computed: {
    ...mapGetters(["conversation", "journeyProviders"]),
    isVendor() {
      const meUser = this.$store.state.meUser;
      return meUser && meUser.user.role == "VENDOR";
    }
  },
  methods: {
    ...mapActions(["fetchConversation", "fetchJourneyProvider"]),
    ...mapMutations(["setConversation", "setConversationId"]),
    async init(source) {
      // Having id prop means this component was loaded from Flagged Journeys table.
      // Needs to be changed to always look at the url id param for starters.
      if (this.id) {
        this.isEmbedded = true;
        await query(queries.JOURNEY_PROVIDER, { id: this.id }, result => {
          const journeyProvider = result.journeyProvider;
          if (journeyProvider) {
            this.journeyProvider = journeyProvider;
          } else {
            this.$buefy.toast.open({
              message:
                "Something went wrong. Could not load the Journey Preview.",
              type: "is-danger"
            });
          }
        });
      } else {
        const journeyProviderVariables = {
          limit: 1,
          offset: 0,
          query: `jp.id:${source.params.journeyProviderId || this.id}`,
          order: ""
        };
        await query(
          queries.MY_JOURNEY_PROVIDERS,
          { input: journeyProviderVariables },
          result => {
            const myJourneyProvider = result.myJourneyProviders;
            if (myJourneyProvider) {
              if (myJourneyProvider.errors.length) {
                this.$buefy.toast.open({
                  message: myJourneyProvider.errors.join(", "),
                  type: "is-danger"
                });
              } else if (myJourneyProvider.data.length > 0) {
                this.journeyProvider = myJourneyProvider.data[0];
              } else {
                this.$router.push("/*");
              }
            }
          }
        );
      }

      if (
        !this.journeyProvider.seen &&
        !_.isEmpty(this.journeyProvider) &&
        !this.id
      ) {
        this.$apollo.provider.defaultClient.mutate({
          mutation: UPDATE_JOURNEY_PROVIDER,
          variables: {
            id: parseInt(this.$route.params.journeyProviderId || this.id),
            input: {
              seen: true
            }
          }
        });
      }
      if (
        !_.isEmpty(this.journeyProvider) &&
        this.journeyProvider.conversation_id
      ) {
        this.fetchConversation(this.journeyProvider.conversation_id);
      } else {
        this.setConversation([]);
        this.setConversationId(0);
      }
    },
    videocallPermissionUpdated(value) {
      this.hasVideoCallPermission = value;
    },
    chatWindowHeight() {
      let height = window.innerHeight;
      this.windowHeight = height - 382;
    }
  },
  created() {
    this.chatWindowHeight();
  },
  mounted() {
    this.init(this.$route);
    this.$nextTick(() => {
      window.addEventListener("resize", this.chatWindowHeight);
    });
  },
  beforeRouteUpdate(to) {
    this.init(to);
  }
};
</script>
<style>
html {
  scroll-behavior: smooth;
}
</style>

<style lang="scss" scoped></style>
