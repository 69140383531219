import COLLECTION_MODULE from "@/store/lib/collectionModule";
const NEW_PROVIDER = {
  name: "",
  email: "",
  country: null,
  city: null,
  address: "",
  zip: "",
  experience: null,
  phone: "",
  description: "",
  treatment_ids: [],
  specialty_ids: [],
  cover_image_url: "",
  avatar_url: "",
  photos: [],
  videos: [],
  practitioners: []
};

// Provider Vuex module definition
export default {
  namespaced: true,
  modules: {
    collection: COLLECTION_MODULE({ NEW_MODEL: NEW_PROVIDER })
  }
};
