<template>
  <section>
    <b-modal
      v-if="info"
      :active.sync="isActive"
      has-modal-card
      :on-cancel="closeModal"
    >
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">{{ info.title }}</p>
        </header>
        <section class="modal-card-body has-text-left">
          <b-field class="columns is-multiline">
            <div class="column is-12-mobile is-6-tablet is-6-desktop">
              <label class="title is-5">Welcome to MeTime</label>
              <p class="subtitle is-6">
                &#60;<a :href="'mailto:' + info.email">{{ info.email }}</a
                >&#62;
              </p>
            </div>
            <div class="column is-12-mobile is-6-tablet is-6-desktop">
              <b-button @click="sendEmail" class="is-primary send-btn"
                >Send Email</b-button
              >
            </div>
            <div class="column is-12 to">
              <strong>To: </strong> {{ info.name }}
            </div>
            <div class="column is-12-mobile is-12 subject">
              <strong>Subject: </strong>
              {{
                info.type == "invitation"
                  ? "View your profile on MeTime"
                  : "Approval reminder"
              }}
            </div>
          </b-field>
          <b-field class="">
            <ValidationObserver ref="emailTemplates">
              <InvitationEmailTemplate
                :data="emailTemplateData"
                ref="invitationEmail"
                v-if="info.type == 'invitation'"
              ></InvitationEmailTemplate>
              <ReminderEmailTemplate
                :data="emailTemplateData"
                ref="reminderEmail"
                v-else
              ></ReminderEmailTemplate>
            </ValidationObserver>
          </b-field>
        </section>
        <footer class="modal-card-foot">
          <b-button class="is-outlined is-primary" @click="closeModal">
            Close
          </b-button>
        </footer>
      </div>
    </b-modal>
  </section>
</template>

<script>
import {
  SEND_PROVIDER_GUEST_INVITATION_EMAIL,
  SEND_PROVIDER_GUEST_REMINDER_EMAIL
} from "../../../_graphql/Mutations/mutations";
import InvitationEmailTemplate from "./InvitationEmailTemplate";
import ReminderEmailTemplate from "./ReminderEmailTemplate";
import ValidationErrors from "../../../mixins/ValidationErrors";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      info: null,
      isActive: false
    };
  },
  mixins: [ValidationErrors],
  components: { InvitationEmailTemplate, ReminderEmailTemplate },
  computed: {
    ...mapGetters(["userRole"]),
    isModerator() {
      return this.userRole && this.userRole == "MODERATOR";
    },
    isAdmin() {
      return this.userRole && this.userRole == "ADMINISTRATOR";
    },
    emailTemplateData() {
      return {
        name: this.info.name,
        email: this.info.email,
        profile_url: this.info.profileUrl,
        allowUpdate: this.isAdmin || this.isModerator
      };
    }
  },
  methods: {
    openModal(data) {
      this.info = data;
      this.isActive = true;
    },
    closeModal() {
      this.isActive = false;
      this.info = null;
    },
    sendEmail() {
      this.$refs.emailTemplates.validate().then(success => {
        if (!success) {
          this.refName = "emailTemplates";
          this.handleErrors();
          return;
        }

        if (this.isAdmin || this.isModerator) {
          let mutation, name, greeting;
          if (this.info && this.info.type == "invitation") {
            mutation = SEND_PROVIDER_GUEST_INVITATION_EMAIL;
            name = "sendProviderGuestInvitationEmail";
            greeting = this.$refs.invitationEmail.greeting;
          } else {
            mutation = SEND_PROVIDER_GUEST_REMINDER_EMAIL;
            name = "sendProviderGuestReminderEmail";
            greeting = this.$refs.reminderEmail.greeting;
          }

          this.$apollo
            .mutate({
              mutation: mutation,
              variables: {
                id: this.info.providerId,
                name: greeting
              },
              error: ({ error }) => {
                return this.$buefy.toast.open({
                  message: error,
                  type: "is-danger"
                });
              }
            })
            .catch(error => {
              return this.$buefy.toast.open({
                message: error,
                type: "is-danger"
              });
            })
            .then(({ data }) => {
              if (data[name]) {
                this.$buefy.toast.open({
                  message: "Email was sent successfully",
                  type: "is-success"
                });
              }
            });
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-card {
  width: 100%;
}
.modal-card-body {
  font-family: "Montserrat", DejaVu Sans, Verdana, "sans-serif";

  .subtitle {
    margin-top: 0 !important;
  }

  .send-btn {
    float: left;

    @media (min-width: 769px) {
      float: right;
    }
  }
  .to {
    padding-bottom: 0;
  }
  .subject {
    padding-top: 0;
  }
  .content {
    word-break: break-word;
    margin-bottom: 0 !important;

    ul {
      margin-left: 1rem;
    }
  }

  .list-content {
    margin-top: 0;
  }
}
</style>
