<template>
  <div class="card notifications">
    <header class="card-header">
      <p class="card-header-title has-text-primary">
        <b-icon icon="bell-outline" />Notifications
      </p>
    </header>
    <div class="card-content columns is-multiline">
      <p class="has-text-left main-description">
        Manage your email notifications easily. Place the cursor over each
        option to see a description of what you’ll get by selecting it. Don’t
        forget to Save your changes when you’re ready!
      </p>
      <div class="column is-full">
        <label for="new-messages" class="label has-text-left"
          >New message notifications</label
        >
        <p class="has-text-left description">
          Send me email notifications for my unread messages
        </p>
        <div id="new-messages" class="columns">
          <div class="field">
            <b-radio
              v-model="new_message_notify"
              @input="checkForChanges('new_message_notify')"
              class="column notification-options"
              native-value="0"
            >
              <b-tooltip
                animated
                multilined
                class="tooltip"
                label="You won’t receive any message related notifications."
                position="is-top"
                >Never</b-tooltip
              >
            </b-radio>
          </div>
          <div class="field">
            <b-radio
              v-model="new_message_notify"
              @input="checkForChanges('new_message_notify')"
              class="column notification-options"
              native-value="1"
            >
              <b-tooltip
                animated
                multilined
                class="tooltip"
                label="If you didn’t get the chance to check your MeTime Inbox, at the end of the day you’ll receive a summary containing the number of new messages you received during the day."
                position="is-top"
                >Every day</b-tooltip
              >
            </b-radio>
          </div>
        </div>
      </div>
      <div class="column is-full">
        <label for="new-matches" class="label has-text-left"
          >New match notifications</label
        >
        <p class="has-text-left description">
          Send me email notifications for my new matches
        </p>
        <div id="new-matches" class="columns">
          <div class="field">
            <b-radio
              v-model="new_match_notify"
              @input="checkForChanges('new_match_notify')"
              class="column notification-options"
              native-value="0"
            >
              <b-tooltip
                animated
                multilined
                class="tooltip"
                label="You won’t receive any match related notifications."
                position="is-top"
                >Never</b-tooltip
              >
            </b-radio>
          </div>
          <div class="field">
            <b-radio
              v-model="new_match_notify"
              @input="checkForChanges('new_match_notify')"
              class="column notification-options"
              native-value="1"
            >
              <b-tooltip
                animated
                multilined
                class="tooltip"
                label="If you didn’t get the chance to check your MeTime Inbox, at the end of the day you’ll receive a summary containing the number of people who have matched with you during the day."
                position="is-top"
                >Every day</b-tooltip
              >
            </b-radio>
          </div>
          <div class="field">
            <b-radio
              v-model="new_match_notify"
              @input="checkForChanges('new_match_notify')"
              class="column notification-options"
              native-value="2"
            >
              <b-tooltip
                animated
                multilined
                class="tooltip"
                label="You will receive an email immediately after someone matches with you."
                position="is-top"
                >Immediately</b-tooltip
              >
            </b-radio>
          </div>
        </div>
      </div>
      <div class="column is-full">
        <label for="new-direct-matches" class="label has-text-left"
          >New direct match notifications</label
        >
        <p class="has-text-left description">
          Send me email notifications for my new direct link matches
        </p>
        <div id="new-direct-matches" class="columns">
          <div class="field">
            <b-radio
              v-model="new_direct_match_notify"
              @input="checkForChanges('new_direct_match_notify')"
              class="column notification-options"
              native-value="0"
            >
              <b-tooltip
                animated
                multilined
                class="tooltip"
                label="You won’t receive any direct link match related notifications."
                position="is-top"
                >Never</b-tooltip
              >
            </b-radio>
          </div>
          <div class="field">
            <b-radio
              v-model="new_direct_match_notify"
              @input="checkForChanges('new_direct_match_notify')"
              class="column notification-options"
              native-value="1"
            >
              <b-tooltip
                animated
                multilined
                class="tooltip"
                label="If you didn’t get the chance to check your MeTime Inbox, at the end of the day you’ll receive a summary containing the number of people who have matched directly with you during the day."
                position="is-top"
                >Every day</b-tooltip
              >
            </b-radio>
          </div>
          <div class="field">
            <b-radio
              v-model="new_direct_match_notify"
              @input="checkForChanges('new_direct_match_notify')"
              class="column notification-options"
              native-value="2"
            >
              <b-tooltip
                animated
                multilined
                class="tooltip"
                label="You will receive an email immediately after someone matches directly with you."
                position="is-top"
                >Immediately</b-tooltip
              >
            </b-radio>
          </div>
        </div>
      </div>
      <div v-if="!disableFields" class="column is-12">
        <div class="buttons is-right">
          <b-button class="is-primary" @click="saveInfo">Save</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { UPDATE_PROVIDER_SETTINGS } from "../../../_graphql/Mutations/mutations";
export default {
  data() {
    return {
      new_message_notify: "",
      new_match_notify: "",
      new_direct_match_notify: ""
    };
  },
  props: ["provider", "allowUpdate"],
  created() {
    if (this.provider) {
      this.new_message_notify = this.provider.settings.new_message_notify;
      this.new_match_notify = this.provider.settings.new_match_notify;
      this.new_direct_match_notify = this.provider.settings.new_direct_match_notify;
    }
  },
  computed: {
    disableFields() {
      return !this.allowUpdate;
    }
  },
  methods: {
    checkForChanges(value) {
      if (this[value] !== this.provider.settings[value]) {
        this.$emit("editedField");
      }
    },
    saveInfo() {
      if (this.provider) {
        let variables = {
          id: this.provider.settings.id,
          input: {
            new_message_notify: parseInt(this.new_message_notify),
            new_match_notify: parseInt(this.new_match_notify),
            new_direct_match_notify: parseInt(this.new_direct_match_notify)
          }
        };
        this.updateProviderSettings(variables);
      }
    },
    updateProviderSettings(data) {
      this.$apollo.mutate({
        mutation: UPDATE_PROVIDER_SETTINGS,
        variables: data,
        update: (cache, { data }) => {
          if (data.updateProviderSettings) {
            this.$emit("updatedProviderSettings");
            this.$emit("savedChanges");
            this.$buefy.toast.open({
              message: "Provider settings updated successfully",
              type: "is-success"
            });
          }
        },
        error: ({ error }) => {
          this.$buefy.toast.open({
            message: error,
            type: "is-danger"
          });
        }
      });
    }
  }
};
</script>

<style scoped>
.main-description {
  margin: 10px;
  font-size: 1.05em;
}

.notification-options {
  margin-top: 15px;
  margin-bottom: 10px;
}
</style>
