<template>
  <div class="socials-section">
    <div class="column is-12">
      <label class="label has-text-left">Social links</label>
    </div>
    <div
      class="column is-12 links-column"
      v-for="(item, index) in socialLinks"
      :key="index"
    >
      <div v-if="!item.id && !disableFields">
        <a @click="openModal(item)" class="has-text-left add-link"
          ><b-icon class="add-icon" icon="plus-circle-outline" /><span
            class="has-text-weight-bold"
            >Add {{ item.label }} link</span
          ></a
        >
      </div>
      <div v-else class="has-link">
        <div class="link">
          <b-icon
            class="link-icon"
            :class="item.icon"
            type="is-primary"
          ></b-icon>
          <div class="link-info">
            <span class="link-value scrollable has-text-weight-bold"
              ><a target="_blank" :href="item.value">{{ item.value }}</a></span
            >
            <span class="link-type help">{{ item.label }}</span>
          </div>
        </div>
        <div v-if="!disableFields" class="action-buttons">
          <b-button @click="openModal(item)"
            ><b-icon type="is-primary" icon="pencil-circle"></b-icon
          ></b-button>

          <b-button v-if="item.id" @click="confirmDelete(item)"
            ><b-icon type="is-primary" icon="delete-circle"></b-icon
          ></b-button>
        </div>
      </div>
      <hr />
    </div>
    <AddSocialLinkModal
      @socialLinkUpdate="$emit('updateSocial')"
      ref="linkModal"
    ></AddSocialLinkModal>
  </div>
</template>

<script>
import { DELETE_PROVIDER_SOCIAL } from "../../../_graphql/Mutations/mutations";
import AddSocialLinkModal from "./AddSocialLinkModal";
export default {
  data() {
    return {
      socialLinks: {
        facebook: {
          label: "Facebook",
          placeholder: "Facebook URL",
          type: "facebook",
          icon: "mdi mdi-facebook-box",
          value: "",
          id: null
        },
        instagram: {
          label: "Instagram",
          placeholder: "Instagram URL",
          type: "instagram",
          icon: "mdi mdi-instagram",
          value: "",
          id: null
        },
        twitter: {
          label: "Twitter",
          placeholder: "Twitter URL",
          type: "twitter",
          icon: "mdi mdi-twitter-box",
          value: "",
          id: null
        },
        linkedin: {
          label: "LinkedIn",
          placeholder: "LinkedIn URL",
          type: "linkedin",
          icon: "mdi mdi-linkedin-box",
          value: "",
          id: null
        },
        youtube: {
          label: "YouTube",
          placeholder: "YouTube URL",
          type: "youtube",
          icon: "mdi mdi-youtube",
          value: "",
          id: null
        },
        website: {
          label: "Website",
          placeholder: "Website URL",
          type: "website",
          icon: "mdi mdi-link-variant",
          value: "",
          id: null
        }
      }
    };
  },
  components: {
    AddSocialLinkModal
  },
  props: ["disableFields", "socials"],
  watch: {
    socials(newVal) {
      if (newVal) {
        this.populateFields(newVal);
      }
    }
  },
  methods: {
    openModal(item) {
      this.$refs.linkModal.openModal(item);
    },
    confirmDelete(item) {
      if (item.id) {
        this.$buefy.dialog.confirm({
          title: "Deleting ",
          message:
            "Are you sure you want to <b>delete </b>" + item.label + " url?",
          confirmText: "Delete",
          type: "is-danger",
          hasIcon: true,
          onConfirm: () => this.deleteSocial(item.id)
        });
      }
    },
    deleteSocial(id) {
      this.$apollo
        .mutate({
          mutation: DELETE_PROVIDER_SOCIAL,
          variables: {
            id: id
          }
        })
        .then(({ data }) => {
          if (data.deleteProviderSocial) {
            let result = data.deleteProviderSocial;
            this.socialLinks[result.type].value = "";
            this.socialLinks[result.type].id = null;
            this.$emit("deletedSocial");
            this.$buefy.toast.open({
              message: "Deleted successfully",
              type: "is-success"
            });
          }
        });
    },
    populateFields(socials) {
      socials.map(social => {
        if (this.socialLinks[social.type]) {
          this.socialLinks[social.type].value = social.name;
          this.socialLinks[social.type].id = social.id;
        }
      });
    }
  },
  created() {
    if (this.socials) {
      this.populateFields(this.socials);
    }
  }
};
</script>

<style lang="scss" scoped>
.socials-section {
  width: 100%;
  hr {
    margin: 1rem 0;
  }
  .field-content {
    .input-content {
      width: 100%;
    }
    display: flex;
  }

  .button {
    border: none;

    &:focus:not(:active) {
      box-shadow: none !important;
    }
  }

  .links-column {
    padding-bottom: 0;
    padding-top: 0;

    .has-link {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      .link {
        align-items: flex-start;
        display: flex;
        .link-info {
          display: flex;
          flex-direction: column;
          text-align: left;

          .link-value {
            margin-bottom: 0;
            word-break: break-word;
            max-height: 6rem;
            overflow-y: auto;
          }
        }
        .link-icon {
          margin: 0 0.5rem 0 0rem;
          font-size: 2rem !important;
        }
      }
      .action-buttons {
        min-width: 4.5rem;
      }
    }

    .add-link {
      display: flex;
      align-items: center;

      .add-icon {
        margin: 0 0.5rem 0 0rem;
      }
    }
  }
}
</style>
