<template>
  <section class="section">
    <div class="is-title-bar">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <h1 class="title">
              Treatments
            </h1>
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <router-link :to="{ name: `treatments.create` }">
              <b-button class="is-primary">Create Treatment </b-button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <data-table v-if="treatments" :data="treatments.data"></data-table>

    <!--  Modal for editing or creating a treatment  -->
  </section>
</template>

<script>
import { TREATMENTS } from "../../../_graphql/Queries/queries";
import LoadingState from "../../../mixins/LoadingState";
import SubscribeForFetch from "../../../mixins/SubscribeForRefetch";
import dataTable from "../components/DataTableTreatments";

export default {
  name: "AllTreatments",
  components: { dataTable },
  mixins: [LoadingState, SubscribeForFetch],
  apollo: {
    treatments: {
      query: TREATMENTS,
      variables: {
        input: {
          order: "id:asc"
        },
        update: data => data.treatments
      }
    }
  }
};
</script>

<style scoped>
.description {
  width: 40% !important;
}
</style>
