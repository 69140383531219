<template>
  <div class="section">
    <apollo-loader />
    <router-view name="dialog"></router-view>
    <router-view />
  </div>
</template>

<script>
export default {
  name: "EmptyRouterView"
};
</script>

<style scoped></style>
