<template>
  <div>
    <ul>
      <li :key="photo.url" v-for="photo in data.photos">
        <img
          alt="Photo from a user"
          class="image is-thumb is-128x128"
          :src="photo.thumb_url"
          @click="
            () => {
              openModal = !openModal;
              modalImageUrl = photo.url;
            }
          "
        />
      </li>
    </ul>
    <div :class="['modal', openModal ? ' is-active' : '']">
      <div class="modal-background"></div>
      <div class="modal-content">
        <img
          @click="modalImageFullscreen = !modalImageFullscreen"
          :class="[modalImageFullscreen ? 'modal-image-full' : 'modal-image']"
          :src="modalImageUrl"
        />
      </div>
      <button
        @click="
          () => {
            openModal = !openModal;
            modalImageFullscreen = false;
          }
        "
        class="modal-close is-large"
        aria-label="close"
      ></button>
    </div>
  </div>
</template>
<script>
export default {
  name: "bubble-gallery",
  props: ["data"],
  data() {
    return {
      openModal: false,
      modalImageUrl: "",
      modalImageFullscreen: false
    };
  }
};
</script>
<style scoped>
.image {
  cursor: pointer;
}
.modal-image {
  width: auto;
  margin: auto;
  display: block;
  cursor: pointer;
  max-height: 100%;
}
.modal-image-full {
  width: auto;
  margin: auto;
  display: block;
}
</style>
