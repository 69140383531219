<script>
export default {
  name: "GraphQLHelper",
  data() {
    return {
      authorized: false,
      authenticatedUser: null
    };
  },
  methods: {
    async mutate(mutation, variables) {
      return await this.$apollo.mutate({
        mutation: mutation,
        variables: variables,
        update: (store, { data }) => {
          return data;
        },
        error: ({ error }) => {
          return error;
        }
      });
    }
  }
};
</script>
