import gql from "graphql-tag";

const FLAG_JOURNEY = gql`
  mutation createJourneyProviderFlag($input: JourneyProviderFlagCreate!) {
    createJourneyProviderFlag(input: $input) {
      id
      journey_provider_id
      date_added
      flag_reason {
        id
        reason
        role
      }
      reported_by
      resolved
    }
  }
`;

const BAN_USER = gql`
  mutation banUser($id: ID!) {
    banUser(id: $id) {
      code
      success
      errors
    }
  }
`;
const UPDATE_AREA = gql`
  mutation updateArea($id: Int!, $input: AreaInputUpdate) {
    updateArea(id: $id, input: $input) {
      id
      name
    }
  }
`;
const CREATE_AREA = gql`
  mutation createArea($input: AreaInputCreate!) {
    createArea(input: $input) {
      id
      name
      male_icon_url
      female_icon_url
    }
  }
`;
const UPDATE_AREA_TYPE = gql`
  mutation updateAreaType($id: Int!, $input: AreaTypeInputUpdate) {
    updateAreaType(id: $id, input: $input) {
      id
      name
    }
  }
`;
const CREATE_AREA_TYPE = gql`
  mutation createAreaType($input: AreaTypeInputCreate!) {
    createAreaType(input: $input) {
      id
      name
    }
  }
`;

const UPDATE_AREA_TYPE_CATEGORY = gql`
  mutation updateAreaTypeCategory(
    $id: Int!
    $input: AreaTypeCategoryInputUpdate
  ) {
    updateAreaTypeCategory(id: $id, input: $input) {
      id
      name
    }
  }
`;

const CREATE_AREA_TYPE_CATEGORY = gql`
  mutation createAreaTypeCategory($input: AreaTypeCategoryInputCreate!) {
    createAreaTypeCategory(input: $input) {
      id
      name
    }
  }
`;

const UPDATE_AREA_TYPE_CATEGORY_TREATMENT = gql`
  mutation updateAreaTypeCategoryTreatment(
    $id: Int!
    $input: AreaTypeCategoryTreatmentInputUpdate
  ) {
    updateAreaTypeCategoryTreatment(id: $id, input: $input) {
      id
      name
      description
    }
  }
`;

const CREATE_AREA_TYPE_CATEGORY_TREATMENT = gql`
  mutation createAreaTypeCategoryTreatment(
    $input: AreaTypeCategoryTreatmentInputCreate!
  ) {
    createAreaTypeCategoryTreatment(input: $input) {
      id
      name
      description
    }
  }
`;

const UPDATE_TREATMENT = gql`
  mutation updateTreatment($id: Int!, $input: TreatmentsInputUpdate) {
    updateTreatment(id: $id, input: $input) {
      id
      name
      description
    }
  }
`;

const CREATE_TREATMENT = gql`
  mutation createTreatment($input: TreatmentsInputCreate!) {
    createTreatment(input: $input) {
      id
      name
      description
    }
  }
`;

const CREATE_TECHNOLOGY = gql`
  mutation createTechnology($input: TechnologyInputCreate!) {
    createTechnology(input: $input) {
      id
      name
      provider_id
      technology_categories_id
    }
  }
`;

const UPDATE_TECHNOLOGY = gql`
  mutation updateTechnology($id: Int!, $input: TechnologyInputUpdate!) {
    updateTechnology(id: $id, input: $input) {
      id
      name
      provider_id
      technology_categories_id
    }
  }
`;

const DELETE_TECHNOLOGY = gql`
  mutation deleteTechnology($id: Int!) {
    deleteTechnology(id: $id) {
      id
      name
      provider_id
      technology_categories_id
    }
  }
`;

const DELETE_PROVIDER_TECHNOLOGY = gql`
  mutation deleteProviderTechnology($id: Int!) {
    deleteProviderTechnology(id: $id) {
      id
      technology_id
      name
      provider_id
      technology_categories_id
    }
  }
`;

const CREATE_PROVIDER = gql`
  mutation createProvider($input: ProviderInputCreate!) {
    createProvider(input: $input) {
      id
      date_added
      email
      phone
      is_subscribed
      subscription_plan
      name
      cover_image_url
      avatar_url
      description
      experience
      is_visible_rating
    }
  }
`;

const UPDATE_PROVIDER = gql`
  mutation updateProvider($id: Int!, $input: ProviderInputUpdate) {
    updateProvider(id: $id, input: $input) {
      id
      date_added
      email
      phone
      is_subscribed
      subscription_plan
      name
      cover_image_url
      avatar_url
      description
      experience
      is_visible_rating
      is_public
      profile_url
      photos {
        url
        thumb_url
        height
        width
        position
      }
      videos {
        url
        position
      }
      main_practitioners {
        id
        name
        description
        avatar_url
        degree
      }
      guest_status
      note
      clinic
      educations {
        id
        name
      }
      trainings {
        id
        name
      }
      awards {
        id
        name
      }
      insurances {
        id
        name
      }
      privileges {
        id
        name
      }
      socials {
        id
        name
        type
      }
      technologies {
        id
        technology_id
        name
      }
      products {
        id
        product_id
        name
      }
      procedures {
        id
        name
      }
    }
  }
`;

const DELETE_PROVIDER = gql`
  mutation deleteProvider($id: ID!) {
    deleteProvider(id: $id) {
      id
      date_added
      email
      phone
      is_subscribed
      subscription_plan
      name
      cover_image_url
      avatar_url
      description
      experience
      is_visible_rating
    }
  }
`;

const DELETE_PROVIDER_BY_ADMIN = gql`
  mutation deleteProviderByAdmin($id: ID!, $reason: String!) {
    deleteProviderByAdmin(id: $id, reason: $reason) {
      id
      date_added
      email
      phone
      is_subscribed
      subscription_plan
      name
      cover_image_url
      avatar_url
      description
      experience
      is_visible_rating
    }
  }
`;

const UPDATE_PRACTITIONER = gql`
  mutation updateProviderPractitioners(
    $id: ID!
    $input: ProviderPractitionerUpdate
  ) {
    updateProviderPractitioners(id: $id, input: $input) {
      id
      name
      specialty
      avatar_url
      description
    }
  }
`;

const DELETE_PRACTITIONER = gql`
  mutation deleteProviderPractitioners($id: ID!) {
    deleteProviderPractitioners(id: $id) {
      id
      name
      specialty
      avatar_url
      description
    }
  }
`;

const DELETE_PROVIDER_PHOTO = gql`
  mutation providerPhotoDelete($id: ID!) {
    providerPhotoDelete(id: $id) {
      id
      date_added
      width
      height
      url
      thumb_url
    }
  }
`;

const DELETE_PROVIDER_VIDEO = gql`
  mutation providerVideoDelete($id: ID!) {
    providerVideoDelete(id: $id) {
      id
      date_added
      url
      image_url
      type
    }
  }
`;

const APPROVE_PROVIDER = gql`
  mutation approveProvider($id: ID!) {
    approveProvider(id: $id) {
      id
      date_added
      email
      phone
      is_subscribed
      subscription_plan
      name
      cover_image_url
      avatar_url
      description
      experience
      is_visible_rating
    }
  }
`;

const DISAPPROVE_PROVIDER = gql`
  mutation disapproveProvider($id: ID!, $reason: String!) {
    disapproveProvider(id: $id, reason: $reason) {
      id
      date_added
      email
      phone
      is_subscribed
      subscription_plan
      name
      cover_image_url
      avatar_url
      description
      experience
      is_visible_rating
    }
  }
`;

const UPLOAD_IMAGE = gql`
  mutation resize($file: Upload!, $input: ResizeInput) {
    resize(file: $file, input: $input) {
      data {
        url
        width
        height
        sizes {
          url
          width
          height
        }
      }
    }
  }
`;

const CROP_IMAGE = gql`
  mutation crop($file: Upload!, $input: CropInput!) {
    crop(file: $file, input: $input) {
      data {
        url
        width
        height
      }
    }
  }
`;

const CREATE_USER = gql`
  mutation createUser($userInput: createUserInput) {
    createUser(userInput: $userInput) {
      code
      success
      errors
      user {
        _id
        username
      }
      notificationPreferences {
        messages
        updates
      }
    }
  }
`;

const UPDATE_PROFILE = gql`
  mutation updateProfile($id: ID!, $input: UserUpdateInput!) {
    updateProfile(id: $id, input: $input) {
      code
      success
      errors
      user {
        _id
        username
      }
      notificationPreferences {
        messages
        updates
      }
    }
  }
`;

const CHANGE_EMAIL = gql`
  mutation changeEmail($currentPassword: String!, $email: String!) {
    changeEmail(currentPassword: $currentPassword, email: $email) {
      code
      success
      errors
    }
  }
`;

const CHANGE_PASSWORD = gql`
  mutation changePassword($currentPassword: String!, $newPassword: String!) {
    changePassword(
      currentPassword: $currentPassword
      newPassword: $newPassword
    ) {
      success
      errors
    }
  }
`;

const DELETE_USER = gql`
  mutation deleteUser($id: ID!) {
    deleteUser(id: $id) {
      code
      success
      errors
    }
  }
`;

const UPDATE_ME = gql`
  mutation updateMe($input: UserUpdateMeInput!) {
    updateMe(input: $input) {
      success
      errors
      user {
        _id
        username
        email
        firstName
        lastName
        avatar
        role
        country
        city
      }
    }
  }
`;

const CREATE_ARTICLE = gql`
  mutation createArticle($file: Upload!, $input: createArticle) {
    createArticle(file: $file, input: $input) {
      id
      name
      slug
      title
      meta_keywords
      meta_description
      meta_title
      content
      description
      blog_type
      reading_time
      img {
        name
        hash
      }
    }
  }
`;

const UPDATE_ARTICLE = gql`
  mutation updateArticle($id: ID!, $file: Upload, $input: updateArticle) {
    updateArticle(id: $id, file: $file, input: $input) {
      id
      name
      slug
      title
      meta_keywords
      meta_description
      meta_title
      content
      blog_type
      description
      reading_time
      img {
        name
        hash
      }
      draft
    }
  }
`;

const DELETE_ARTICLE = gql`
  mutation deleteArticle($id: ID!) {
    deleteArticle(id: $id) {
      id
      name
      title
    }
  }
`;

const CREATE_PAGE = gql`
  mutation createPage($input: createPage) {
    createPage(input: $input) {
      id
      title
      content
      slug
      meta_title
      meta_description
      meta_keywords
      status
      url
      created_at
      updated_at
      cms_keywords
      content_json
      name
    }
  }
`;

const UPDATE_PAGE = gql`
  mutation updatePage($id: ID!, $input: updatePage) {
    updatePage(id: $id, input: $input) {
      id
      title
      content
      slug
      meta_title
      meta_description
      meta_keywords
      status
      url
      created_at
      updated_at
      cms_keywords
      content_json
      name
    }
  }
`;

const DELETE_PAGE = gql`
  mutation deletePage($id: ID!) {
    deletePage(id: $id) {
      id
      name
      slug
    }
  }
`;

const CREATE_COMPONENT = gql`
  mutation createComponent($input: createComponent) {
    createComponent(input: $input) {
      id
      content
      page
      slug
      created_at
      updated_at
      title
      cms_keywords
      content_json
    }
  }
`;

const UPDATE_COMPONENT = gql`
  mutation updateComponent($id: ID!, $input: updateComponent) {
    updateComponent(id: $id, input: $input) {
      id
      content
      page
      slug
      created_at
      updated_at
      title
      cms_keywords
      content_json
    }
  }
`;

const DELETE_COMPONENT = gql`
  mutation deleteComponent($id: ID!) {
    deleteComponent(id: $id) {
      content
      page
      slug
      created_at
      updated_at
      title
    }
  }
`;

const UPDATE_JOURNEY_PROVIDER_FLAG = gql`
  mutation updateJourneyProviderFlag(
    $id: Int!
    $input: JourneyProviderFlagUpdate
  ) {
    updateJourneyProviderFlag(id: $id, input: $input) {
      id
      journey_provider_id
      date_added
      flag_reason {
        id
        reason
      }
      reported_by
      resolved
    }
  }
`;

const UPDATE_JOURNEY_PROVIDER = gql`
  mutation updateJourneyProvider($id: Int!, $input: JourneyProviderUpdate) {
    updateJourneyProvider(id: $id, input: $input) {
      id
      seen
    }
  }
`;

const SEND_FILE = gql`
  mutation($somefile: Upload!) {
    upload(file: $somefile) {
      errors
      data {
        url
        type
      }
    }
  }
`;

const MATCHABLE_PROVIDER = gql`
  mutation matchableProvider($id: ID!) {
    matchableProvider(id: $id) {
      id
      is_matchable
    }
  }
`;

const UNMATCHABLE_PROVIDER = gql`
  mutation unmatchableProvider($id: ID!) {
    unmatchableProvider(id: $id) {
      id
      is_matchable
    }
  }
`;

const UPDATE_SYSTEM_SETTING = gql`
  mutation updateSystemSetting($id: ID!, $value: String!) {
    updateSystemSetting(id: $id, value: $value) {
      id
      key_name
      value
      type
      comment
    }
  }
`;

const UPDATE_PAYMENT = gql`
  mutation updatePayment($id: Int!, $input: PaymentInputUpdate!) {
    updatePayment(id: $id, input: $input) {
      id
      payin_id
      provider_name
      buyer_email
      buyer_first_name
      buyer_last_name
      description
      currency
      amount
      fee_percentage
      fee_amount
      status
      payout_status
      date_added
    }
  }
`;

const UPDATE_PROVIDER_SETTINGS = gql`
  mutation updateProviderSettings($id: ID!, $input: ProviderSettingsUpdate) {
    updateProviderSettings(id: $id, input: $input) {
      id
      allow_payments
      allow_videocalls
      new_message_notify
      new_match_notify
      new_direct_match_notify
    }
  }
`;

const CREATE_PROVIDER_NOTE = gql`
  mutation createProviderNote($input: ProviderNoteCreate!) {
    createProviderNote(input: $input) {
      id
      date_added
      user {
        role
        first_name
        last_name
      }
      text
    }
  }
`;

const TRANSFER_PROVIDER = gql`
  mutation transferProvider($token: ID!) {
    transferProvider(token: $token) {
      is_guest
    }
  }
`;

const SEND_PROVIDER_GUEST_INVITATION_EMAIL = gql`
  mutation sendProviderGuestInvitationEmail($id: ID!, $name: String!) {
    sendProviderGuestInvitationEmail(id: $id, name: $name) {
      name
      email
    }
  }
`;

const SEND_PROVIDER_GUEST_REMINDER_EMAIL = gql`
  mutation sendProviderGuestReminderEmail($id: ID!, $name: String!) {
    sendProviderGuestReminderEmail(id: $id, name: $name) {
      name
      email
    }
  }
`;

const UPDATE_PROVIDER_PRIVILEGE = gql`
  mutation updateProviderPrivilege($id: ID!, $input: PrivilegesInput) {
    updateProviderPrivilege(id: $id, input: $input) {
      id
      name
    }
  }
`;

const DELETE_PROVIDER_PRIVILEGE = gql`
  mutation deleteProviderPrivilege($id: ID!) {
    deleteProviderPrivilege(id: $id) {
      id
      name
    }
  }
`;

const UPDATE_PROVIDER_EDUCATION = gql`
  mutation updateProviderEducation($id: ID!, $input: EducationsInput) {
    updateProviderEducation(id: $id, input: $input) {
      id
      name
    }
  }
`;

const DELETE_PROVIDER_EDUCATION = gql`
  mutation deleteProviderEducation($id: ID!) {
    deleteProviderEducation(id: $id) {
      id
      name
    }
  }
`;

const UPDATE_PROVIDER_TRAINING = gql`
  mutation updateProviderTraining($id: ID!, $input: TrainingsInput) {
    updateProviderTraining(id: $id, input: $input) {
      id
      name
    }
  }
`;

const DELETE_PROVIDER_TRAINING = gql`
  mutation deleteProviderTraining($id: ID!) {
    deleteProviderTraining(id: $id) {
      id
      name
    }
  }
`;

const UPDATE_PROVIDER_AWARD = gql`
  mutation updateProviderAward($id: ID!, $input: AwardsInput) {
    updateProviderAward(id: $id, input: $input) {
      id
      name
    }
  }
`;

const DELETE_PROVIDER_AWARD = gql`
  mutation deleteProviderAward($id: ID!) {
    deleteProviderAward(id: $id) {
      id
      name
    }
  }
`;

const UPDATE_PROVIDER_INSURANCE = gql`
  mutation updateProviderInsurance($id: ID!, $input: InsurancesInput) {
    updateProviderInsurance(id: $id, input: $input) {
      id
      name
    }
  }
`;

const DELETE_PROVIDER_INSURANCE = gql`
  mutation deleteProviderInsurance($id: ID!) {
    deleteProviderInsurance(id: $id) {
      id
      name
    }
  }
`;

const UPDATE_PROVIDER_SOCIAL = gql`
  mutation updateProviderSocial($id: ID!, $input: SocialsInput) {
    updateProviderSocial(id: $id, input: $input) {
      id
      name
      type
    }
  }
`;

const DELETE_PROVIDER_SOCIAL = gql`
  mutation deleteProviderSocial($id: ID!) {
    deleteProviderSocial(id: $id) {
      id
      name
      type
    }
  }
`;

const CREATE_PRODUCT = gql`
  mutation createProduct($input: ProductInputCreate!) {
    createProduct(input: $input) {
      id
      name
      provider_id
      product_categories_id
    }
  }
`;

const UPDATE_PRODUCT = gql`
  mutation updateProduct($id: Int!, $input: ProductInputUpdate!) {
    updateProduct(id: $id, input: $input) {
      id
      name
      provider_id
      product_categories_id
    }
  }
`;

const DELETE_PRODUCT = gql`
  mutation deleteProduct($id: Int!) {
    deleteProduct(id: $id) {
      id
      name
      provider_id
      product_categories_id
    }
  }
`;

const DELETE_PROVIDER_PRODUCT = gql`
  mutation deleteProviderProduct($id: Int!) {
    deleteProviderProduct(id: $id) {
      id
      product_id
      name
      provider_id
      product_categories_id
    }
  }
`;

const CREATE_PROCEDURE = gql`
  mutation createProvidersProcedure($input: ProvidersProcedureInputCreate!) {
    createProvidersProcedure(input: $input) {
      id
      name
      provider_id
      procedure_area_id
    }
  }
`;

const DELETE_PROCEDURE = gql`
  mutation deleteProvidersProcedure($id: Int!) {
    deleteProvidersProcedure(id: $id) {
      id
      name
      procedure_area_id
    }
  }
`;

const UPDATE_PROCEDURE = gql`
  mutation updateProvidersProcedure(
    $id: Int!
    $input: ProvidersProcedureInputUpdate!
  ) {
    updateProvidersProcedure(id: $id, input: $input) {
      id
      name
      procedure_area_id
    }
  }
`;

const UPDATE_PROVIDERS_PROCEDURE_AREAS_POSITIONS = gql`
  mutation updateProvidersProceduresAreasPositions(
    $input: ProceduresAreasPositionsInput!
  ) {
    updateProvidersProceduresAreasPositions(input: $input) {
      data {
        id
        procedure_area_id
        procedure_area_name
        procedure_area_icon_url
        position
      }
    }
  }
`;

const CREATE_PROVIDER_SUBSCRIPTION = gql`
  mutation createProviderSubscription($input: ProviderSubscriptionCreate!) {
    createProviderSubscription(input: $input) {
      id
      sub_plan_id
      provider_id
      session_id
    }
  }
`;

const UPDATE_PROVIDER_SUBSCRIPTION = gql`
  mutation updateProviderSubscription(
    $id: Int!
    $input: ProviderSubscriptionUpdate!
  ) {
    updateProviderSubscription(id: $id, input: $input) {
      id
      sub_plan_id
      provider_id
      session_id
    }
  }
`;
const CREATE_AREAS_SPECIALTIES = gql`
  mutation createAreasSpecialties($input: AreasSpecialtiesCreate) {
    createAreasSpecialties(input: $input) {
      data {
        success
      }
    }
  }
`;

const CREATE_PROVIDER_PAYMENT = gql`
  mutation createProviderPayment($input: ProviderPaymentInputCreate!) {
    createProviderPayment(input: $input) {
      id
      session_id
    }
  }
`;

// Solutions mutations

const CREATE_SOLUTION = gql`
  mutation createSolution($input: SolutionInputCreate!) {
    createSolution(input: $input) {
      id
      title
    }
  }
`;

const UPDATE_SOLUTION = gql`
  mutation updateSolution($id: Int!, $input: SolutionInputUpdate!) {
    updateSolution(id: $id, input: $input) {
      id
      title
      is_published
    }
  }
`;

const DELETE_SOLUTION = gql`
  mutation deleteSolution($id: Int!) {
    deleteSolution(id: $id) {
      id
      title
    }
  }
`;

// Stripe connect mutations

const CREATE_STRIPE_CONNECT = gql`
  mutation createStripeConnect($input: StripeConnectCreate!) {
    createStripeConnect(input: $input) {
      id
      provider_id
      connect_id
      payouts_enabled
      details_submitted
    }
  }
`;

const UPDATE_STRIPE_CONNECT = gql`
  mutation updateStripeConnect($id: Int!, $input: StripeConnectInputUpdate) {
    updateStripeConnect(id: $id, input: $input) {
      id
      provider_id
      connect_id
      payouts_enabled
      details_submitted
    }
  }
`;
export {
  FLAG_JOURNEY,
  BAN_USER,
  UPDATE_AREA,
  CREATE_AREA,
  UPDATE_AREA_TYPE,
  CREATE_AREA_TYPE,
  UPDATE_AREA_TYPE_CATEGORY,
  CREATE_AREA_TYPE_CATEGORY,
  UPDATE_AREA_TYPE_CATEGORY_TREATMENT,
  CREATE_AREA_TYPE_CATEGORY_TREATMENT,
  UPDATE_TREATMENT,
  CREATE_TREATMENT,
  CREATE_PROVIDER,
  UPDATE_PROVIDER,
  DELETE_PROVIDER,
  APPROVE_PROVIDER,
  DISAPPROVE_PROVIDER,
  UPDATE_PRACTITIONER,
  DELETE_PRACTITIONER,
  DELETE_PROVIDER_PHOTO,
  DELETE_PROVIDER_VIDEO,
  DELETE_PROVIDER_BY_ADMIN,
  UPLOAD_IMAGE,
  CROP_IMAGE,
  CREATE_USER,
  UPDATE_PROFILE,
  CHANGE_EMAIL,
  CHANGE_PASSWORD,
  DELETE_USER,
  UPDATE_ME,
  CREATE_ARTICLE,
  DELETE_ARTICLE,
  UPDATE_ARTICLE,
  CREATE_PAGE,
  UPDATE_PAGE,
  DELETE_PAGE,
  CREATE_COMPONENT,
  UPDATE_COMPONENT,
  DELETE_COMPONENT,
  UPDATE_JOURNEY_PROVIDER_FLAG,
  UPDATE_JOURNEY_PROVIDER,
  SEND_FILE,
  MATCHABLE_PROVIDER,
  UNMATCHABLE_PROVIDER,
  UPDATE_SYSTEM_SETTING,
  UPDATE_PAYMENT,
  UPDATE_PROVIDER_SETTINGS,
  CREATE_PROVIDER_NOTE,
  TRANSFER_PROVIDER,
  SEND_PROVIDER_GUEST_INVITATION_EMAIL,
  SEND_PROVIDER_GUEST_REMINDER_EMAIL,
  UPDATE_PROVIDER_PRIVILEGE,
  DELETE_PROVIDER_PRIVILEGE,
  UPDATE_PROVIDER_EDUCATION,
  DELETE_PROVIDER_EDUCATION,
  UPDATE_PROVIDER_TRAINING,
  DELETE_PROVIDER_TRAINING,
  UPDATE_PROVIDER_AWARD,
  DELETE_PROVIDER_AWARD,
  UPDATE_PROVIDER_INSURANCE,
  DELETE_PROVIDER_INSURANCE,
  UPDATE_PROVIDER_SOCIAL,
  DELETE_PROVIDER_SOCIAL,
  CREATE_PRODUCT,
  DELETE_PRODUCT,
  UPDATE_PRODUCT,
  CREATE_TECHNOLOGY,
  UPDATE_TECHNOLOGY,
  DELETE_TECHNOLOGY,
  DELETE_PROCEDURE,
  UPDATE_PROCEDURE,
  CREATE_PROCEDURE,
  UPDATE_PROVIDERS_PROCEDURE_AREAS_POSITIONS,
  DELETE_PROVIDER_PRODUCT,
  DELETE_PROVIDER_TECHNOLOGY,
  CREATE_PROVIDER_SUBSCRIPTION,
  UPDATE_PROVIDER_SUBSCRIPTION,
  CREATE_PROVIDER_PAYMENT,
  CREATE_SOLUTION,
  UPDATE_SOLUTION,
  DELETE_SOLUTION,
  CREATE_AREAS_SPECIALTIES,
  CREATE_STRIPE_CONNECT,
  UPDATE_STRIPE_CONNECT
};
