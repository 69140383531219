<template>
  <div>
    <b-table
      class="table is-fullwidth"
      paginated
      :data="tableData.data"
      :current-page.sync="current_page"
      :per-page="per_page"
      default-sort="name"
      :hoverable="true"
    >
      <template slot-scope="data">
        <b-table-column label="Journey Name" field="journey_name">
          {{ data.row.journey.name }}
        </b-table-column>
        <b-table-column label="Date added" field="date_added">
          {{ new Date(data.row.journey.date_added).toLocaleDateString() }}
        </b-table-column>
        <b-table-column label="Reported by">
          {{ data.row | getNames() }}
        </b-table-column>
        <b-table-column>
          <div class="buttons is-right">
            <b-button class="is-primary" @click="viewJourney(data.row.id)"
              >View</b-button
            >
            <b-button class="is-warning" @click="showDetails(data.row)"
              >Flags</b-button
            >
          </div>
        </b-table-column>
      </template>
      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>
              <b-icon icon="emoticon-sad" size="is-large"> </b-icon>
            </p>
            <p>Nothing here.</p>
          </div>
        </section>
      </template>
    </b-table>
    <flags-details
      @updatedFlag="updatedFlag"
      :flag-data="flagData"
      ref="flagDetails"
    ></flags-details>
    <journey-details
      ref="journeyDetails"
      :journey_provider_id="journey_provider_id"
    ></journey-details>
  </div>
</template>

<script>
import FlagsDetails from "./FlagsDetails";
import JourneyDetails from "./JourneyDetails";
import _ from "lodash";

export default {
  data() {
    return {
      per_page: 25,
      current_page: 1,
      flagData: {},
      journey_provider_id: null
    };
  },
  props: {
    tableData: {
      required: true
    }
  },
  components: {
    FlagsDetails,
    JourneyDetails
  },
  filters: {
    getNames(values) {
      let name = "";
      if (
        _.find(values.flags, ["reported_by", "BUYER"]) &&
        _.find(values.flags, ["reported_by", "VENDOR"])
      ) {
        name =
          values.journey.user.authUser.firstName +
          " " +
          values.journey.user.authUser.lastName +
          ", " +
          values.provider.name;
      } else if (_.find(values.flags, ["reported_by", "BUYER"])) {
        name =
          values.journey.user.authUser.firstName +
          " " +
          values.journey.user.authUser.lastName;
      } else if (_.find(values.flags, ["reported_by", "VENDOR"])) {
        name = values.provider.name;
      }
      return name;
    }
  },
  methods: {
    updatedFlag() {
      this.$emit("updatedFlag");
    },
    showDetails(data) {
      this.flagData = data;
      this.$refs.flagDetails.openModal();
    },
    viewJourney(id) {
      this.journey_provider_id = id;
      this.$refs.journeyDetails.openModal();
    }
  }
};
</script>
