import Components from "./views/Components";
import CreateComponent from "./views/CreateComponent";
import EditComponent from "./views/EditComponent";
import { Role } from "../../_helpers/roles";

const componentsRoute = {
  path: "/components",
  name: "components",
  component: Components,
  meta: { authorize: [Role.Admin] }
};

const createComponentRoute = {
  path: "/components/create",
  name: "createComponent",
  component: CreateComponent,
  meta: { authorize: [Role.Admin] }
};

const editComponentRoute = {
  path: "/components/edit/:id",
  name: "editComponent",
  component: EditComponent,
  meta: { authorize: [Role.Admin] }
};

export default [componentsRoute, createComponentRoute, editComponentRoute];
