<script>
export default {
  name: "RouteParamsGetters",
  computed: {
    getAreaTypeCatId() {
      return parseInt(this.$route.params.areaTypeCatId);
    },
    getAreaTypeId() {
      return parseInt(this.$route.params.areaTypeId);
    },
    getAreaId() {
      return parseInt(this.$route.params.areaId);
    },
    getProviderId() {
      return parseInt(this.$route.params.providerId);
    },

    getRouteTitle() {
      return this.$route.meta.title;
    },
    getRouteName() {
      return this.$route.name;
    },
    getRouteFirstName() {
      return this.getRouteName.split(".")[0];
    },
    getAddTitle() {
      return this.$route.meta.addTitle;
    },
    getRoutePath() {
      return this.$route.path;
    }
  }
};
</script>
