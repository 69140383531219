<template>
  <section class="section">
    <title-bar>
      Pages
      <router-link to="/pages/create" class="button is-primary" slot="button">
        Create Page
      </router-link>
    </title-bar>

    <b-table
      v-if="getFilteredPages"
      class="table is-fullwidth"
      :data="getFilteredPages"
      :loading="false"
      paginated
      default-sort="created_at"
      default-sort-direction="desc"
      :per-page="20"
    >
      <template slot-scope="data">
        <b-table-column field="id" label="ID">{{ data.row.id }}</b-table-column>
        <b-table-column field="name" label="Name" sortable>
          {{ data.row.name }}
        </b-table-column>
        <b-table-column field="slug" label="Slug" sortable>
          {{ data.row.slug }}
        </b-table-column>
        <b-table-column field="created_at" label="Created" sortable>
          {{ new Date(data.row.created_at).toLocaleDateString() }}
        </b-table-column>
        <b-table-column field="updated_at" label="Updated" sortable>
          {{ new Date(data.row.updated_at).toLocaleDateString() }}
        </b-table-column>
        <b-table-column>
          <div class="buttons is-right">
            <router-link
              :to="{
                name: 'editPage',
                params: { id: data.row.id, data: data.row }
              }"
            >
              <b-button class="is-primary is-outlined">Edit</b-button>
            </router-link>
            <b-button
              class="is-danger is-outlined"
              @click="openDeleteDialog(data.row)"
              >Delete
            </b-button>
          </div>
        </b-table-column>
      </template>
      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>
              <b-icon icon="information" size="is-large"></b-icon>
            </p>
            <p>No pages in database.</p>
          </div>
        </section>
      </template>
    </b-table>
  </section>
</template>

<script>
import TitleBar from "../../base/components/TitleBar";
import { PAGES } from "../../../_graphql/Queries/queries";
import { DELETE_PAGE } from "../../../_graphql/Mutations/mutations";

export default {
  name: "Pages",
  components: { TitleBar },
  data() {
    return {};
  },
  computed: {
    getFilteredPages() {
      const pagesList = [
        "home",
        "benefits",
        "plans",
        "articles",
        "request-demo",
        "metime-app",
        "our-mission",
        "contact-us"
      ];
      if (this.pages) {
        return this.pages.filter(page => pagesList.includes(page.slug));
      }
      return null;
    }
  },
  methods: {
    openDeleteDialog(data) {
      const variables = {
        id: data.id
      };
      this.$buefy.dialog.confirm({
        title: "Confirm Deletion ",
        message: `Are you sure you want to <b>delete</b> ${data.name} ?`,
        confirmText: "Delete",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => {
          this.mutate(DELETE_PAGE, variables)
            .then(() => {
              this.$apollo.getClient().resetStore();
              this.$buefy.toast.open({
                message: "Page deleted successfully.",
                type: "is-success"
              });
            })
            .catch(error => {
              this.$buefy.toast.open({
                message: `${error}`,
                type: "is-danger"
              });
            });
        }
      });
    }
  },
  apollo: {
    pages: {
      query: PAGES
    }
  }
};
</script>
