<template>
  <div id="app">
    <nav-bar v-if="showMenus" />
    <aside-menu v-if="showMenus" />
    <div id="app-content">
      <router-view />
    </div>
    <!--    <footer-bar />-->
  </div>
</template>

<script>
import AsideMenu from "./modules/base/components/AsideMenu";
import NavBar from "./modules/base/components/NavBar";
import { mapState } from "vuex";
import { MY_PROVIDERS } from "@/_graphql/Queries/queries";
import _ from "lodash";
export default {
  components: { AsideMenu, NavBar },
  computed: {
    ...mapState(["meUser"]),
    showMenus() {
      return this.$route && !this.$route.path.endsWith("videoCall");
    },
    isVendor() {
      return this.meUser && this.meUser.user.role == "VENDOR";
    }
  },
  methods: {
    initIntercom(currentUser) {
      this.$intercom.boot({
        userId: currentUser._id,
        name: `${currentUser.firstName} ${currentUser.lastName}`,
        email: currentUser.email
      });
    }
  },
  watch: {
    $route() {
      this.$intercom.update();
    },
    meUser: {
      handler: function(val) {
        if (val.user) {
          const currentUser = val.user;
          this.initIntercom(currentUser);
        }
      }
    }
  },
  mounted() {
    if (this.meUser && this.meUser.user) {
      if (!this.$route.path.includes("/videoCall")) {
        this.initIntercom(this.meUser.user);
      }
      if (this.isVendor) {
        this.$apollo
          .query({
            query: MY_PROVIDERS
          })
          .then(data => {
            const myProviders = _.get(data, "data.myProviders.data", []);
            this.$store.commit("setMyProviders", myProviders);
          });
      }
    }
  }
};
</script>

<style lang="scss">
#app-content {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
