<template>
  <section class="add-image-section">
    <b-modal
      :active.sync="isActive"
      has-modal-card
      aria-modal
      aria-role="dialog"
      trap-focus
      :on-cancel="closeModal"
      id="modal"
    >
      <div
        class="modal-card"
        :style="
          'width: ' +
            modalWidth +
            'px; height: ' +
            modalHeight +
            'px; max-width: 960px;'
        "
      >
        <header class="modal-card-head">
          <p class="modal-card-title has-text-weight-bold">Add Image</p>
        </header>
        <section class="modal-card-body">
          <b-field class="info-field">
            <notification
              v-if="
                type &&
                  (type == 'cover_image' || type == 'solution_cover_image')
              "
              type="light"
            >
              <template v-slot:icon>
                <img src="@/assets/img/frame.svg" class="icon is-large" />
              </template>
              <template v-slot:message>
                Use the cropping tool to specify how you'd like your cover image
                to appear on different devices. The larger rectangle shows how
                your cover image will be displayed in mobile and tablet view,
                while the smaller, highlighted one shows how it will appear in
                desktop view.
              </template>
            </notification>
          </b-field>

          <ValidationObserver ref="validateImage">
            <ValidationProvider
              rules="required|ext:jpg,jpeg,png"
              v-slot="{ errors, valid }"
              name="Image"
            >
              <b-field
                class="upload-field "
                :type="{ 'is-danger': errors[0], 'is-primary': valid }"
              >
                <b-upload
                  type="is-primary"
                  class="file is-right is-primary upload-image is-mobile"
                  @input="changeAvatar($event)"
                  v-model="image"
                  label="Upload Image"
                >
                  <span class="file-cta">
                    <span class="file-icon">
                      <i class="fas fa-upload"></i>
                    </span>
                    <span class="file-label">
                      Click to upload
                    </span>
                  </span>
                </b-upload>
              </b-field>

              <span
                v-for="error in errors"
                :key="error.id"
                class="has-text-danger help"
                >{{ error }}</span
              >
            </ValidationProvider>
            <b-field class="cropper-field">
              <cropper
                v-if="type"
                :style="'maxHeight: ' + cropperHeight + 'px;'"
                :stencil-component="stencilComponent[type]"
                :stencil-props="stencilProps[type]"
                :size-restrictions-algorithm="pixelsRestriction"
                :min-height="imageSizes[type].minHeight"
                :min-width="imageSizes[type].minWidth"
                class="cropper"
                ref="cropper"
                :src="crop"
              />
            </b-field>
          </ValidationObserver>
        </section>
        <footer class="modal-card-foot">
          <button class="button" type="button" @click="closeModal">
            Close
          </button>
          <button
            :disabled="isSaveBtnDisabled"
            class="button is-primary"
            @click="cropImage"
          >
            Save
          </button>
        </footer>
      </div>
    </b-modal>
  </section>
</template>

<script>
import { Cropper } from "vue-advanced-cropper";
import ValidationErrors from "../../../mixins/ValidationErrors";
import { UPLOAD_IMAGE } from "../../../_graphql/Mutations/mutations";
import Notification from "../../base/components/Notification";

export default {
  data() {
    return {
      isSaveBtnDisabled: false,
      isActive: false,
      image: null,
      crop: null,
      upload: null,
      cropperHeight: null,
      type: "",
      modalWidth: null,
      modalHeight: null,
      stencilProps: {
        cover_image: {
          resizable: false,
          aspectRatio: 16 / 9,
          previewClass: "preview"
        },
        avatar: {
          resizable: false
        },
        practitionerAvatar: {
          resizable: false
        },
        image: {
          resizable: false,
          aspectRatio: 4 / 3
        },
        solution_thumbnail_image: {
          resizable: false,
          aspectRatio: 3 / 2
        },
        solution_cover_image: {
          resizable: false,
          aspectRatio: 4 / 1,
          previewClass: "solution_preview"
        }
      },
      imageSizes: {
        cover_image: {
          minHeight: 430,
          minWidth: 760
        },
        avatar: {
          minHeight: 0,
          minWidth: 0
        },
        practitionerAvatar: {
          minHeight: 0,
          minWidth: 0
        },
        image: {
          minHeight: 0,
          minWidth: 0
        },
        solution_thumbnail_image: {
          minHeight: 160,
          minWidth: 240,
          maxHeight: 400,
          maxWidth: 1600
        },
        solution_cover_image: {
          minHeight: 430,
          minWidth: 760,
          maxHeight: 300,
          maxWidth: 450
        }
      },
      stencilComponent: {
        cover_image: "rectangle-stencil",
        thumbnail_image: "rectangle-stencil",
        image: "rectangle-stencil",
        avatar: "circle-stencil",
        practitionerAvatar: "circle-stencil"
      }
    };
  },
  mounted() {},
  components: {
    Cropper,
    Notification
  },
  mixins: [ValidationErrors],
  methods: {
    pixelsRestriction({ minWidth, minHeight, maxWidth, maxHeight }) {
      return {
        minWidth: minWidth,
        minHeight: minHeight,
        maxWidth: maxWidth,
        maxHeight: maxHeight
      };
    },
    openModal(type) {
      this.isActive = true;
      this.type = type;
      let windowHeight = window.innerHeight;
      let windowWidth = window.innerWidth;
      this.modalHeight = windowHeight - 150;
      this.modalWidth = windowWidth - 250;
      this.cropperHeight =
        type && (type == "cover_image" || type == "solution_cover_image")
          ? this.modalHeight - 370
          : this.modalHeight - 250;
      document.documentElement.style.setProperty(
        "customHeight",
        this.cropperHeight
      );
    },
    closeModal() {
      this.image = null;
      this.upload = null;
      this.crop = null;
      this.isActive = false;
      this.type = "";
      this.isSaveBtnDisabled = false;
    },
    changeAvatar(event) {
      if (event.type == "image/jpeg" || event.type == "image/png") {
        let input = event;
        if (input) {
          let reader = new FileReader();
          reader.onload = e => {
            this.crop = e.target.result;
          };
          reader.readAsDataURL(input);
        }
      } else {
        this.image = null;
        this.upload = null;
        this.crop = null;
      }
    },
    cropImage() {
      this.$refs.validateImage.validate().then(success => {
        if (!success) {
          this.refName = "validateImage";
          this.handleErrors();
          return;
        }
        this.isSaveBtnDisabled = true;

        const result = this.$refs.cropper.getResult();
        if (result.canvas) {
          let img = result.canvas.toDataURL(this.image.type);
          this.dataURItoBlob(img);
        } else {
          this.isSaveBtnDisabled = false;

          return this.$buefy.toast.open({
            message: "Error while uploading image",
            type: "is-danger"
          });
        }
      });
    },
    dataURItoBlob(dataURI) {
      // convert base64/URLEncoded data component to raw binary data held in a string
      let byteString;
      if (dataURI.split(",")[0].indexOf("base64") >= 0) {
        byteString = atob(dataURI.split(",")[1]);
      } else {
        byteString = unescape(dataURI.split(",")[1]);
      }
      // separate out the mime component
      let mimeString = dataURI
        .split(",")[0]
        .split(":")[1]
        .split(";")[0];
      // write the bytes of the string to a typed array
      let ia = new Uint8Array(byteString.length);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      let blob = new Blob([ia], { type: mimeString });

      let file = new File([blob], this.image.name, { type: this.image.type });

      this.uploadImage(file);
    },
    uploadImage(file) {
      let config = {
        header: {
          "Content-Type": "image/*"
        }
      };
      let variables = {
        file: file
      };
      if (this.type == "cover_image") {
        variables.input = {
          width: [760]
        };
      } else if (this.type == "image") {
        variables.input = {
          width: [950]
        };
      } else if (this.type == "solution_thumbnail_image") {
        variables.input = {
          width: [450]
        };
      } else if (this.type == "solution_cover_image") {
        variables.input = {
          width: [1600]
        };
      }

      this.$apollo
        .mutate({
          mutation: UPLOAD_IMAGE,
          variables: variables,
          context: config,
          update: (cache, { data: { resize } }) => {
            if (resize && resize.data) {
              this.upload =
                resize.data.sizes.length > 0
                  ? resize.data.sizes[0]
                  : resize.data;

              this.saveImage(this.upload);
            } else {
              this.isSaveBtnDisabled = false;
              return this.$buefy.toast.open({
                message: "Error while uploading image",
                type: "is-danger"
              });
            }
          },
          error: ({ error }) => {
            this.isSaveBtnDisabled = false;
            return this.$buefy.toast.open({
              message: error,
              type: "is-danger"
            });
          }
        })
        .catch(error => {
          this.isSaveBtnDisabled = false;
          return this.$buefy.toast.open({
            message: error,
            type: "is-danger"
          });
        });
    },
    saveImage(image) {
      this.$emit("saveImage", {
        type: this.type,
        image: image
      });
      this.closeModal();
    },
    onDropImage(event, image) {
      this.image = image;
      this.changeAvatar(event);
    }
  }
};
</script>

<style lang="scss" scoped>
.add-image-section {
  .upload-field {
    display: flex;
    justify-content: center;
    .upload-image {
      text-align: right;
    }
  }

  .modal-card-foot {
    border-top: 1px solid whitesmoke;
  }

  .cropper-field {
    margin-top: 1rem;
  }

  @media (max-width: 768px) {
    .modal-card {
      width: 100% !important;
    }
  }
}
</style>
<style lang="scss">
.add-image-section {
  .vue-advanced-cropper {
    overflow: hidden;
    max-height: var(customHeight) !important;

    img {
      object-fit: contain !important;
    }

    .preview {
      border: solid 1px rgba(255, 255, 255, 0.25);
      height: 36%;
      top: 32%;
    }

    .solution_preview {
      border: solid 1px rgba(255, 255, 255, 0.25);
      /*height: 40%;*/
      /*top: 30%;*/
      width: 32%;
      left: 34%;
    }
  }
}
</style>
