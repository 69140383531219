<template>
  <div class="card contact-section">
    <header class="card-header">
      <p class="card-header-title has-text-primary">
        <b-icon icon="cellphone-link" custom-size="default" />
        Contact
      </p>
    </header>
    <div class="card-content contact-content">
      <div class="columns is-multiline is-mobile">
        <div class="column is-12">
          <ValidationProvider
            :rules="{
              required: true,
              valid_phone: !isPhoneValid
            }"
            v-slot="{ errors, valid }"
            name="Phone"
          >
            <div class="field" id="fieldset-phone">
              <label for="phone-input" class="label has-text-left">Phone</label>
              <span class="field-content"
                ><vue-tel-input
                  v-bind="phoneProps"
                  :disabled="disableFields"
                  id="phone-input"
                  class="input is-primary"
                  v-model="phone"
                  type="tel"
                  :validCharactersOnly="true"
                  @validate="validate"
                  @blur="onBlur"
                  @input="handleInputChange"
                  :class="{ 'is-danger': errors[0], 'is-primary': valid }"
                />
              </span>
            </div>
            <span
              v-for="error in errors"
              :key="error.id"
              class="has-text-danger help"
              >{{ error }}</span
            >
          </ValidationProvider>
        </div>
        <SocialLinksBox
          :socials="provider.socials"
          :disableFields="disableFields"
          @updateSocial="$emit('updatedInfo')"
          @deletedSocial="$emit('updatedInfo')"
        ></SocialLinksBox>
      </div>
    </div>
  </div>
</template>

<script>
import { UPDATE_PROVIDER } from "../../../_graphql/Mutations/mutations";
import SocialLinksBox from "./SocialLinksBox";

export default {
  data() {
    return {
      isPhoneValid: false,
      isInputChanged: false,
      phone: "",
      link: "",
      socialService: null,
      phoneProps: {
        mode: "international",
        placeholder: "Enter a phone number",
        dropdownOptions: {
          disabledDialCode: false
        },
        enabledCountryCode: false
      }
    };
  },
  props: ["provider", "allowUpdate"],
  components: {
    SocialLinksBox
  },
  computed: {
    disableFields() {
      return !this.allowUpdate;
    }
  },
  created() {
    if (this.provider) {
      this.phone = this.provider.phone;
    }
  },
  methods: {
    handleInputChange() {
      this.isInputChanged = !!(
        this.provider && this.provider.phone !== this.phone
      );
    },
    onBlur() {
      this.updateInfo();
      this.isInputChanged = false;
    },
    validate({ valid }) {
      this.isPhoneValid = valid;
    },
    populateFields(item) {
      this.link = item.name;
      this.socialService = item.type;
    },
    async updateInfo() {
      if (!this.isInputChanged) {
        return;
      }

      if (this.phone === "") {
        return this.$buefy.toast.open({
          message: "Phone field is required!",
          type: "is-danger"
        });
      }

      if (!this.isPhoneValid) {
        return this.$buefy.toast.open({
          message: "Provided phone is not valid!",
          type: "is-danger"
        });
      }

      const providerId = parseInt(this.$route.params.providerId);
      const mutation = UPDATE_PROVIDER;
      const payload = {
        mutation: mutation,
        queryName: "updateProvider"
      };

      const variables = {
        id: providerId,
        input: {
          phone: this.phone
        }
      };
      this.$store.commit("provider/collection/editModel", variables);
      await this.$store.dispatch("provider/collection/update", payload);
      this.$emit("updatedInfo");
    }
  }
};
</script>

<style lang="scss" scoped>
.new-info-box {
  display: flex;
  flex-direction: column;
  .box {
    flex: 1;
  }

  .custom-add-section {
    flex-direction: column;
  }
}
.button {
  border: none;
  outline: none !important;

  &:focus:not(:active) {
    box-shadow: none !important;
  }
}

.field-content {
  display: flex;
  .input-content {
    width: 100%;
  }
}
</style>
