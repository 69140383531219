<template>
  <section>
    <b-modal
      :active.sync="isActive"
      has-modal-card
      trap-focus
      :on-cancel="closeModal"
    >
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title has-text-weight-bold">Add Video</p>
        </header>
        <section class="modal-card-body">
          <ValidationObserver ref="addVideos">
            <b-field horizontal label="Video's url" label-for="videoUrl">
              <div class="control">
                <ValidationProvider
                  rules="required"
                  v-slot="{ errors }"
                  name="Video url"
                >
                  <b-input
                    id="videoUrl"
                    type="video"
                    placeholder="Add video's url"
                    v-model="videoUrl"
                    @input="editUrl"
                  />
                  <span
                    v-for="error in errors"
                    :key="error.id"
                    class="has-text-danger help"
                    >{{ error }}</span
                  >
                  <figure v-if="newUrl" class="image">
                    <iframe
                      id="video"
                      class="has-ratio column"
                      width="440"
                      height="360"
                      :src="newUrl"
                      frameborder="3"
                      allowfullscreen
                      target="_parent"
                    ></iframe>
                  </figure>
                </ValidationProvider>
              </div>
            </b-field>
          </ValidationObserver>
        </section>
        <footer class="modal-card-foot">
          <button class="button" type="button" @click="closeModal">
            Close
          </button>
          <button :disabled="isSaveBtnDisabled" class="button is-primary" @click="addVideo">Add</button>
        </footer>
      </div>
    </b-modal>
  </section>
</template>

<script>
import { UPDATE_PROVIDER } from "../../../_graphql/Mutations/mutations";
import ValidationErrors from "../../../mixins/ValidationErrors";

export default {
  data() {
    return {
      isSaveBtnDisabled: false,
      isActive: false,
      videoUrl: null,
      newUrl: ""
    };
  },
  mixins: [ValidationErrors],
  methods: {
    editUrl(url) {
      this.newUrl = "";
      if (url.match("youtube.com")) {
        this.newUrl =
          "https://www.youtube.com/embed/" +
          url.split("watch?v=")[1].split("&")[0];
      }
      if (url.match("youtu.be")) {
        this.newUrl =
          "https://www.youtube.com/embed/" + url.split("youtu.be/")[1];
      }
      if (url.match("vimeo")) {
        this.newUrl =
          "https://player.vimeo.com/video/" +
          url
            .split("/")
            .slice(-1)
            .pop();
      }
    },
    openModal() {
      this.isActive = true;
      this.videoUrl = null;
      this.newUrl = "";
    },
    closeModal() {
      this.videoUrl = null;
      this.isActive = false;
      this.newUrl = "";
      this.isSaveBtnDisabled = false;
    },
    async addVideo() {
      this.$refs.addVideos.validate().then(async success => {
        if (!success) {
          this.refName = "addVideos";
          this.handleErrors();
          return;
        }

        this.isSaveBtnDisabled = true;

        const mutation = UPDATE_PROVIDER;
        const variables = {
          id: parseInt(this.$route.params.providerId),
          input: {
            videos: {
              url: this.newUrl
            }
          }
        };
        const payload = {
          mutation: mutation,
          queryName: "updateProvider"
        };
        this.$store.commit("provider/collection/editModel", variables);
        await this.$store.dispatch("provider/collection/update", payload);
        this.$emit("addedVideo");
        this.closeModal();
      });
    }
  }
};
</script>
