<template>
  <b-dropdown
    hoverable
    aria-role="list"
    :close-on-click="false"
    :position="position"
  >
    <button class="button is-primary is-outlined" slot="trigger">
      <span>{{ title }}</span>
      <b-icon icon="menu-down"></b-icon>
    </button>
    <b-dropdown-item
      v-for="item in items"
      :key="item.name"
      aria-role="listitem"
      :focusable="false"
      :class="item.type == 'input' ? 'inputDropdownItem' : ''"
    >
      <b-collapse
        animation="slide"
        :open="openFilter == item.title"
        @open="openFilter = item.title"
      >
        <div slot="trigger" class="item-title" role="button">
          <span :class="filters[item.name] != '' ? 'activeFilter' : ''">{{
            item.title
          }}</span>
        </div>
        <div
          v-if="item.type == 'options' || item.type == 'boolean'"
          class="filterOptions"
        >
          <span
            v-for="filterOption in item.options"
            :key="filterOption.title"
            class="filterOption"
            :class="
              filters[item.name] == filterOption.value ? 'activeFilter' : ''
            "
            @click="emitToggleFilter(item.name, filterOption.value)"
            >{{ filterOption.title }}</span
          >
        </div>
        <b-field
          v-else-if="item.type == 'input'"
          class="inputOption"
          :class="filters[item.name] != '' ? 'activeInput' : ''"
        >
          <b-input
            :placeholder="inputPlaceholder"
            type="text"
            v-model="inputFilters[item.name]"
            v-on:input="debounceInput($event, item.name)"
          />
          <button class="button is-white" @click="clearFilter(item.name)">
            <i class="fas fa-eraser" />
          </button>
        </b-field>
      </b-collapse>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
export default {
  props: {
    inputPlaceholder: String,
    title: String,
    items: Array,
    filters: Object,
    inputFilters: Object,
    position: {
      type: String,
      default: null // leave default for bottom-right
    }
  },
  data() {
    return {
      openFilter: "",
      debounce: null
    };
  },
  methods: {
    emitToggleFilter(filterName, optionValue) {
      this.$emit("toggleFilter", { filterName, optionValue });
    },
    debounceInput(event, filterName) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.filters[filterName] = event;
      }, 600);
    },
    clearFilter(filterName) {
      this.filters[filterName] = "";
      this.inputFilters[filterName] = "";
    }
  }
};
</script>

<style lang="scss">
.inputDropdownItem {
  padding-right: 10px !important;
}
.dropdown-content {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
}
.dropdown-item {
  cursor: default;
}
.filterOption {
  color: #4a4a4a;
  margin-top: 4px;
  margin-bottom: 4px;
  cursor: pointer;
}
.filterOptions {
  display: grid;
  margin-left: 10px;
  margin-top: 4px;
}
.dropdown-item:hover .filterOption,
.dropdown-item:hover .item-title {
  background-color: unset;
  color: #4a4a4a;
}
.dropdown-item:hover .filterOption:hover {
  background-color: whitesmoke;
  color: #0a0a0a;
}
.dropdown-item:hover .item-title:hover {
  background-color: whitesmoke;
  color: #0a0a0a;
}
.inputOption {
  margin-top: 10px;
  margin-left: 5px;
}
.inputOption input {
  border-color: #4a4a4a;
  border-radius: 5px !important;
}
.inputOption input:hover,
.inputOption input:focus {
  border-color: #0a0a0a;
  box-shadow: none;
}
.inputOption button {
  padding: 6px;
  margin-left: 5px;
  cursor: default;
}
.inputOption svg {
  width: 21px !important;
  height: 21px !important;
  color: white;
}
.activeFilter:hover {
  color: #0a8eb9 !important;
}
.activeInput input {
  border-color: #0ba1d1 !important;
}
.activeInput input:hover {
  border-color: #0a8eb9 !important;
}
.activeFilter,
.activeInput button svg {
  color: #0ba1d1 !important;
}
.activeInput button {
  cursor: pointer;
}
</style>
